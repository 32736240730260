import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RP_TYPE } from "../config/constant/unityConstants";
import { errorToast } from "../helpers/toastHelper";
import { toggleSidebarSection, selectIs2DMode } from "../redux/slicers/admin/curatorSlicer";
import { projectCameraAngleSelector } from "../redux/slicers/camera/projectCameraAngleSlicer";
import { selectPopupType, setOpen } from "../redux/slicers/camera/renderPreviewPoppupSlicer";
import { getAppState } from "../redux/store";

export const useRenderPreviewPopup = () => {
  const dispatch = useDispatch();

  const openPopup = (type) => {
    const is2DMode = selectIs2DMode(getAppState())

    if (is2DMode) {
      if (type === RP_TYPE.PREVIEW) {
        errorToast('Please switch to 3d view for creating previews', {
          toastId: 'SWITCH_TO_3D_VIEW_FOR_PREVIEW',
        })
      } else {
        errorToast('Please switch to 3d view for creating render', {
          toastId: 'SWITCH_TO_3D_VIEW_FOR_RENDER',
        })
      }
      
      return;
    }

    dispatch(setOpen(type))
    dispatch(toggleSidebarSection(null))
  }

  const closePopup = () => {
    dispatch(setOpen(false))
  }

  const togglePopup = (type) => {
    const popupType = selectPopupType(getAppState())
    
    if (popupType === type) {
      closePopup();
    } else {
      openPopup(type)
    }
  }

  return {
    openPopup,
    closePopup,
    togglePopup,
  }
}