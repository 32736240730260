import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Breadcrumbs, CommonGrid, TopContainer } from '../layouts';
import {
  TopButtonArea,
  ActionFilters,
  GlobalFilter,
  SharePopup,
  BigButton,
} from '../common';
import _, { isEmpty } from 'lodash';
import { useScreenSettings } from '../helpers/configureHelper';
import constants, { MODEL_TYPES } from '../constants/constants';
import { getNewDesignPath, openCuratorRoom } from '../helpers/urlHelper';
import { getProjectTypeLabel } from '../helpers/dataHelper';
import { useRooms } from '../hooks/useRooms';
import { useFilterContext } from '../contexts/FilterContext';
import { MY_PROJECTS_ROUTE, NEW_PROJECTS_ROUTE } from '../constants/routePathConstants';
import { usePageTitle } from '../hooks/usePageTitle';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import { ThumbnailContainerV2 } from '../common/ThumbnailContainerV2';
import AddToCollectionPopup from '../common/AddToCollectionPopup';
import CollectionIcon from '../components/icons/CollectionIcon';
const { PROJECT_TYPE_OPTIONS } = constants;

export default function MyRooms({
  title,
  pageTitle,
  cardType = 'templates',
  apiConfig = {},
  breadcrumbs,
  useGlobalSearch,
  noDataText = 'No data found',
  cardProps,
}) {
  const navigate = useNavigate();
  const filterCtx = useFilterContext();
  // const location = useLocation();
  const routeParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let roomType; // templates do not have room type while create new project/rooms does
  if (routeParams) {
    roomType = _.startCase(routeParams.id);
  }
  let project_type = _.find(PROJECT_TYPE_OPTIONS, (t) => {
    return t.label === roomType;
  });
  // const pathname = parseBasePathname(location);
  // cardType = cardType || _.replace(pathname, '/', '');

  let defaultFilterBy;
  const filterRoomType = searchParams.get('roomType');
  if (project_type) {
    defaultFilterBy = project_type;
  } else if (!isEmpty(filterRoomType)) {
    defaultFilterBy = {
      label: getProjectTypeLabel(filterRoomType),
      value: filterRoomType,
    };
  }

  const pageSettings = useScreenSettings(cardType);
  const { sortOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter, filterOptions } =
    pageSettings;

  const isRooms = cardType === 'rooms';
  title = title || (isRooms ? 'Select a room to start your new project' : _.startCase(cardType));
  const browserTabTitle = pageTitle || (isRooms ? 'My Rooms' : 'Templates');

  usePageTitle(browserTabTitle);

  const { defaultCreatedBy, skipBrandFilter } = apiConfig;
  const {
    list,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,

    anySelected,
    allSelected,
    selected,
    selectedList,
    sortBy,
    filterBy,
    onSortChange,
    onFilterByChange,
    toggleAll,
    toggleItem,
    addToCollection,
    resetSelection,
  } = useRooms({
    filterByKey: 'room_type',
    defaultFilterBy,
    defaultBrands: apiConfig?.defaultBrands,
    defaultSort: defaultSortOption,
    useGlobalSearch,
    defaultCreatedBy,
    skipBrandFilter
  });

  // TODO: refactor breadcrumbs
  useEffect(() => {
    let breadcrumbs = roomType
      ? [
          {
            link: MY_PROJECTS_ROUTE,
            label: 'My projects',
          },
          {
            link: _.replace(NEW_PROJECTS_ROUTE, ':projectId', -1), // why it's -1 :(
            label: 'Create new project',
          },
          {
            link: null,
            label: roomType,
          },
        ]
      : null;
    filterCtx.setBreadcrumbs(breadcrumbs);
  }, [roomType]);

  const onEdit = (id) => {
    openCuratorRoom(id);
  };

  const handleFilterByChange = (option) => {
    if (roomType) {
      const roomType = option.value;
      navigate(getNewDesignPath(roomType));
    }

    onFilterByChange(option);
  };

  return (
    <>
      {breadcrumbs && !useGlobalSearch && <Breadcrumbs fullWidth list={breadcrumbs} />}
      <CommonGrid
        noDataText={noDataText}
        topContainer={
          <TopContainer
            title={
              !useGlobalSearch && (
                <>
                  {title} {total >= 1 ? <small>({total})</small> : null}
                </>
              )
            }
            item={cardType}
            hasData={hasData}
            buttonArea={
              !useGlobalSearch && (
                <TopButtonArea
                  hasData={hasData}
                  item={cardType}
                  topButtonList={
                    roomType
                      ? null
                      : [
                          {
                            element: BigButton,
                            bigBtnClass: 'lf-btn-2',
                            iconBefore: <CollectionIcon />,
                            buttonTitle: 'Add To Collection',
                            onClick: addToCollection,
                          },
                          {
                            element: SharePopup,
                            bigButton: true,
                            bigBtnClass: 'lf-btn-2',
                            selectedList,
                            modelType: 3,
                          },
                        ]
                  }
                  anySelected={anySelected}
                  selectedList={selectedList}
                />
              )
            }
          />
        }
        item={cardType}
        hasData={list.length > 0 || loading}
        list={list}
        filterArea={
          !useGlobalSearch && (
            <>
              <ActionFilters
                sortProps={{
                  value: sortBy,
                  data: sortOptions,
                  onChange: onSortChange,
                }}
                filterProps={{
                  value: filterBy,
                  data: filterOptions,
                  onChange: handleFilterByChange,
                }}
                selectedProps={
                  roomType
                    ? null
                    : {
                        allSelected: allSelected,
                        onSelectAll: () => toggleAll(),
                      }
                }
                showTeamMemberFilter={showTeamMemberFilter}
              />
              <GlobalFilter />
            </>
          )
        }>
        <InfiniteScroll
          dataLength={list.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loading={loading}
          hasChildren={hasData}
          scrollableTarget="infiniteScroll">
          <ThumbnailContainerV2
            items={list}
            modelType={MODEL_TYPES.TEMPLATES}
            selected={selected}
            cardProps={{
              fullWidth: true,
              onSelect: toggleItem,
              ...cardProps,
            }}
          />
          {hasMore && <div id="whiteShadow" />}
        </InfiniteScroll>
      </CommonGrid>
      <AddToCollectionPopup onSuccess={resetSelection} />
    </>
  );
}
