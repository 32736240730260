import { DECIMAL_NUM_COUNT, MAX_RESOLUTION, MIN_RESOLUTION } from "../config/constant/unityConstants";
import { MAX_UPLOAD_HEIGHT_PIXELS, MAX_UPLOAD_WIDTH_PIXELS } from "../constants/textureConstants";
import { getJson } from "./axiosHelper";
import { getImageDetails } from "./jsHelper";
import { errorToast } from "./toastHelper";

export const inchesToUnitySize = (inches) => {
  if (inches === 0) return 0;

  const feet = inches / 12;
  return 10 / feet;
}

export const unitySizeToInches = (unitySize) => {
  const feet = 10 / unitySize;
  return parseFloat((feet * 12).toFixed(DECIMAL_NUM_COUNT));
}

export const sizeDecimals = (size) => parseFloat(size.toFixed(2));
export const toUnityFixed = (size) => parseFloat(parseFloat(size).toFixed(2));
export const parseELSize = (size) => {
  const floatSize = parseFloat(size);
  if (Number.isNaN(floatSize)) return floatSize;

  return parseFloat(floatSize.toFixed(2))
};



window.unitySizeToInches = unitySizeToInches
window.inchesToUnitySize = inchesToUnitySize

export const normalizeResSize = (size) => {
  return size > MAX_RESOLUTION
    ? MAX_RESOLUTION
    : size < MIN_RESOLUTION
      ? MIN_RESOLUTION
      : size;
}

const lightDataCache = {}
export const getPropLightList = async (prop) => {
  let lightArr = [];
  if (prop.is_light_available && prop.light_data) {
    let lightData = lightDataCache[prop.id] || await getJson(prop.light_data);
    lightDataCache[prop.id] = lightData;
    lightArr = lightData?.lights || [];
  }

  return lightArr;
}

export const getPropMetadata = (prop) => {
  return prop.is_metadata && prop.product_meta_info
  ? { preDefinedMetadata: prop.product_meta_info }
  : {}
}


export const prepareTextureForUpload = async ({ file, fileName, isMap = false, projectId, filteroptions, name, oldTextureId, version }) => {
  const formData = new FormData();
  formData.append("texture_file", file, fileName || file.name);
  formData.append('is_map', isMap);
  
  if (projectId) {
    formData.append('project', projectId);
  }

  if (oldTextureId) {
    formData.append('old_texture_id', oldTextureId);
  }

  if (version) {
    formData.append('version', version);
  }

  if (name) {
    formData.append('name', name);
  }

  if (filteroptions) {
    formData.append('filteroptions', filteroptions);
  }

  try {
    const { width, height, dpi } = await getImageDetails(file);

    if (width >= MAX_UPLOAD_WIDTH_PIXELS || height >= MAX_UPLOAD_HEIGHT_PIXELS) {
      errorToast('Size of image uploaded must be below 16000 x 16000 pixels');
      return;
    }

    formData.append('width', toUnityFixed(width / dpi));
    formData.append('height', toUnityFixed(height / dpi));

  } catch(error) {
    errorToast('Unable to get image details, please try again');
    return;
  }

  return {
    formData,
  }
}