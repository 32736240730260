import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { icons } from '../assets';
import FooterButton from './FooterButton';
import { useDownloadEntity } from '../hooks/useDownloadEntity';

const { DownloadIcon } = icons;

export default function Download(props) {
  const [downloading, setDownloading] = useState(false);
  const { download } = useDownloadEntity();

  async function handleDownload() {
    download({ id: props.id, type: props.cardType })
  }

  if (props.footerButton || !props.footerButton) return <FooterButton icon={DownloadIcon} {...props} />;

  if (props.miniButton)
    return (
      <li title={props.buttonTitle || props.title}>
        <div onClick={handleDownload} style={{ width: '16px' }}>
          <img className="img-fluid" src={props.iconSrc} alt={props.buttonTitle} />
        </div>
      </li>
    );

  return (
    <Button
      className="btn-warning"
      // variant={props.disabled ? 'outline-secondary' : 'outline-dark'}
      size={props.size || 'lg'}
      onClick={handleDownload}
      type="button"
      disabled={props.disabled}>
      <DownloadIcon />
      Download
    </Button>
  );
}
