import cx from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { animateScroll } from 'react-scroll';

import ArrowDownIcon from '../../components/icons/ArrowDownIcon';
import CloseCircleIcon from '../../components/icons/closeCircleIcon';
import styles from './ScrollableTagList.module.scss';
import { capitalize } from 'lodash';

export const ScrollableTagList = ({
  tagList,
  scrollContainerId,
  onDelete,
  onClear,
  className,
  theme = 'light',
  size = 'default',
}) => {
  const [arrowsVisible, setArrowsVisible] = useState(false);
  const containerRef = useRef(null);

  const intersectionLeftRef = useRef(null);
  const intersectionLeft = useIntersection(intersectionLeftRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  const intersectionRightRef = useRef(null);
  const intersectionRight = useIntersection(intersectionRightRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  useEffect(() => {
    if (!intersectionRight || !intersectionLeft) return;

    const visible =
      intersectionRight.intersectionRatio !== 1 || intersectionLeft.intersectionRatio !== 1;
    setArrowsVisible(visible);
  }, [intersectionRight?.intersectionRatio, intersectionLeft?.intersectionRatio]);

  const handleNextClick = () => {
    const visibleItems = containerRef.current.querySelectorAll('[data-visible="true"]');
    const lastVisibleItem = visibleItems[visibleItems.length - 1];
    const nextItem = lastVisibleItem.nextSibling;

    if (nextItem && nextItem.dataset.visible === 'false') {
      const container = document.getElementById(scrollContainerId);
      const width = nextItem.clientWidth;
      const scrollToPx = container.scrollLeft + width;

      animateScroll.scrollTo(scrollToPx, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    } else {
      animateScroll.scrollTo(10000, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    }
  };

  const handlePrevClick = () => {
    const visibleItems = containerRef.current.querySelectorAll('[data-visible="true"]');
    const lastVisibleItem = visibleItems[0];
    const prevItem = lastVisibleItem.previousSibling;

    if (prevItem && prevItem.dataset.visible === 'false') {
      const container = document.getElementById(scrollContainerId);
      const width = prevItem.clientWidth;
      const scrollToPx = container.scrollLeft - width;

      animateScroll.scrollTo(scrollToPx, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    } else {
      animateScroll.scrollTo(0, {
        horizontal: true,
        containerId: scrollContainerId,
        duration: 200,
      });
    }
  };

  if (tagList.length === 0) return null;

  const themeClassName = styles[`theme${capitalize(theme)}`];
  const sizeClassName = styles[`size${capitalize(size)}`];

  return (
    <div className={cx(className, themeClassName, sizeClassName, arrowsVisible && styles.withArrows)}>
      <div className={styles.sliderContainer}>
        {arrowsVisible && (
          <button className={styles.prevButton} onClick={handlePrevClick}>
            <ArrowDownIcon />
          </button>
        )}

        <div
          id={scrollContainerId}
          className={cx(styles.scrollableContainer, arrowsVisible && styles.withArrows)}
          ref={containerRef}>
          <div className={styles.observableDiv} ref={intersectionLeftRef} />

          {tagList.map((tag) => {
            return <Item value={tag.value} label={tag.label} key={tag.value} onDelete={onDelete} />;
          })}

          <div className={styles.observableDiv} ref={intersectionRightRef} />
        </div>
        {arrowsVisible && (
          <button className={styles.nextButton} onClick={handleNextClick}>
            <ArrowDownIcon />
          </button>
        )}

        {onClear && (
          <button type="button" className={styles.clearButton} onClick={onClear}>
            Clear all
          </button>
        )}
      </div>
    </div>
  );
};

export const Item = ({ value, label, onDelete }) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  return (
    <TagUI
      ref={intersectionRef}
      onDelete={onDelete}
      value={value}
      label={label}
      data-visible={intersection?.intersectionRatio === 1 ? 'true' : 'false'}
    />
  );
};

export const TagUI = forwardRef(({ label, onDelete, value, size, ...rest }, ref) => {
  return (
    <div ref={ref} className={cx(styles.selectedFilter, size === 'large' && styles.sizeLarge)} {...rest}>
      {label}
      <span className={styles.deleteButton} onClick={() => onDelete(value)}>
        <CloseCircleIcon />
      </span>
    </div>
  );
});
