import _ from 'lodash';

export default function TopContainer({ title, subtitle, buttonArea }) {

  if (!title && !subtitle && !buttonArea) return null

  return (
    <div className="topContainer header-name-of-my-project">
      <div className="col card-grid px-0 container-fluid button-header-upload-project justify-content-between">
        {title && <h2 className="pg-title mb-0">{title}</h2>}
        {buttonArea}
      </div>
      {subtitle ? <div className="font16">{subtitle}</div> : null}
    </div>
  );
}
