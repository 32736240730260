import BinIcon2 from '../components/icons/BinIcon2';
import { RestoreIcon } from '../components/icons/RestoreIcon';
import { ARCHIVE_ICON } from '../constants/assetConstants';
import { RENDER_STATUS } from '../constants/constants';
import { RENDER_DETAILS } from '../constants/routePathConstants';
import { getCuratorRenderPreviewPath } from '../helpers/urlHelper';
import { useRenderPreviewsActions } from '../hooks/useRenderPreviewsActions';
import ThumbnailCardUI, { ACTION } from './ThumbnailCardUI';
import styles from './ThumbnailCardUI.module.scss';
import { icons } from '../assets';
import { Link } from 'react-router-dom';
const { RetryIcon, HeadsetIcon } = icons;

export const ThumbnailCardRenderPreviews = ({
  data,
  size,
  hideFavourite,
  onEdit,
  onDelete,
  to = RENDER_DETAILS,
  customActions,
  ...rest
}) => {
  const {
    onDelete: _onDelete,
    onFavouriteToggle,
    favouriteLoading,
    onShare,
    onDownloadClick,
    downloadLoading,
    addToCollection,
  } = useRenderPreviewsActions({
    item: data,
  });

  const url = getCuratorRenderPreviewPath(data.id);
  let handleEdit;
  if (!data.is_upload) {
    handleEdit = onEdit || (() => window.open(url, '_blank'));
  }

  const handleDelete = onDelete || _onDelete;

  const highlight =
    data.status === RENDER_STATUS.STATUS_FAILED
      ? 'error'
      : data.status === RENDER_STATUS.STATUS_DONE
      ? 'success'
      : undefined;

  const current = data.images?.filter((a) => a.status === RENDER_STATUS.STATUS_DONE).length
  const max = data.images?.length || 0;

  
  const bottomRightText = `Status: ${current}/${max}`
  let actions = customActions;
  if (!actions) {
    actions = data.is_main_render 
      ? { [ACTION.DELETE]: { icon: <img src={ARCHIVE_ICON} alt="Archive" /> }} 
      : undefined
  }

  const onRetry = () => {
    console.log("onRetry");
  }
  const onRaiseTicket = () => {
    console.log("onRaiseTicket");
  }
  const onRestore = () => {
    console.log("onRestore");
  }
  
  let showActionsText = false;
  if(data?.status === RENDER_STATUS.STATUS_FAILED) {
    actions = {
      [ACTION.RETRY]: { label: 'Retry', icon: <RetryIcon />, onClick: onRetry },
      [ACTION.RAISE_TICKET]: { label: 'Raise Ticket', icon: <HeadsetIcon />, onClick: onRaiseTicket },
      [ACTION.DOWNLOAD]: {onClick: null},
      [ACTION.EDIT]: {onClick: null},
      [ACTION.SHARE]: {onClick: null},
      [ACTION.ADD_TO_COLLECTION]: {onClick: null},
      [ACTION.DELETE]: {onClick: null}
    }
    showActionsText = true;
  }

  // under review status is not done yet
  // if(data?.status === RENDER_STATUS.STATUS_UNDER_REVIEW) {
  //   actions = {
  //     [ACTION.RETRY]: { onClick: null },
  //     [ACTION.RAISE_TICKET]: { onClick: null },
  //     [ACTION.UNDER_REVIEW]: { label: 'Under Review', icon: <HeadsetIcon />, onClick: null },
  //     [ACTION.DOWNLOAD]: {onClick: null},
  //     [ACTION.EDIT]: {onClick: null},
  //     [ACTION.SHARE]: {onClick: null},
  //     [ACTION.ADD_TO_COLLECTION]: {onClick: null},
  //     [ACTION.DELETE]: {onClick: null}
  //   }
  //  showActionsText = true;
  // }

  
  if(data?.is_upload) {
    actions = {
      [ACTION.RETRY]: {onClick: null},
      [ACTION.RAISE_TICKET]: {onClick: null},
      [ACTION.RESTORE]: {onClick: null},
      [ACTION.EDIT]: {onClick: null},
    }
    showActionsText = false;
  }
  
  if(data?.is_archived) {
    actions = {
      [ACTION.RETRY]: {onClick: null},
      [ACTION.RAISE_TICKET]: {onClick: null},
      [ACTION.RESTORE]: { label: 'Restore', icon: <RestoreIcon />, onClick: onRestore },
      [ACTION.DOWNLOAD]: {onClick: null},
      [ACTION.EDIT]: {onClick: null},
      [ACTION.SHARE]: {onClick: null},
      [ACTION.ADD_TO_COLLECTION]: {onClick: null},
      [ACTION.DELETE]: {onClick: null}
    }
    showActionsText = true;
  }

  let titleProps;
  if(data?.is_archived) {
    titleProps = {}
  } else if(data?.is_upload) {
    titleProps = { as: Link, target: '_blank', to: to.replace(":id", data?.id), }
  }else {
    titleProps = { as: Link, target: '_blank', to: url, }
  }

  return (
    <ThumbnailCardUI
      key={data.id}
      id={data.id}
      to={to}
      onEdit={(data?.is_archived || data?.is_upload) ? (e) => {} : handleEdit}
      thumbnail={data?.thumbnail}
      images={data?.images}
      name={data.name || data.render_name}
      isFavourite={data.is_favourite}
      favouriteLoading={favouriteLoading}
      date={data.updated_at}
      onDelete={handleDelete}
      bottomRightText={bottomRightText}
      onShare={onShare}
      customActions={actions}
      onFavouriteChange={onFavouriteToggle(data)}
      size={size}
      titleProps={titleProps}
      downloadLoading={downloadLoading}
      // imageFit="contain"
      onAddToCollection={addToCollection}
      onDownload={!data.is_preview && onDownloadClick}
      hideFavourite={hideFavourite}
      highlight={highlight}
      actionsListClassName={data?.is_archived ? styles.actionListSpaceAround : styles.actionListSpaceBetween}
      showActionsText={showActionsText}
      data={data}
      {...rest}
    />
  );
};
