import { useMemo, useState } from 'react';
import {
  COLLECTION_ICON,
  DELETE_ICON,
  DOWNLOAD_ICON,
  EDIT_ICON,
  SHARE_ICON,
} from '../constants/assetConstants';
import { getCuratorProjectPath } from '../helpers/urlHelper';
import AddToCollections from './AddToCollections';
import DeactivatePopup from './DeactivatePopup';
import Download from './Download';
import MiniButton from './MiniButton';
import SharePopup from './SharePopup';
import _ from 'lodash';
import { apiDeleteProjects} from '../helpers/api';
import GridSectionNew from './GridSectionNew';
import { EditProjectPopup } from './EditProjectPopup/EditProjectPopup';
import { useUserContext } from '../contexts/UserContext';
import useProjects from '../pages/admin/myprojects/useProjects';
import { icons } from '../assets';
const { InfoIcon } = icons;

export default function GridProjectsSection({
  title,
  seeAllLink,
  modelType,
  emptyDisplay,
}) {
  const [editItem, setEditItem] = useState(null);
  const { currentUser } = useUserContext();
  const currentMemberId = currentUser.id;
  const requestParams = useMemo(() => ({ created_by: currentMemberId }), [currentMemberId])
  const { list, loading, refetchList } = useProjects({ requestParams });


  const onEdit = async (id) => {
    let itemToEdit = list.find(p => p.id === id)
    setEditItem(itemToEdit);
  };

  const handleProjectUpdateSuccess = (newData) => {
    refetchList();
    setEditItem(newData);
  }

  const customActions = { 
    EDIT: { label: 'Info', icon: <InfoIcon />, onClick: onEdit }
  }

  return (
    <>
      <GridSectionNew
        title={title}
        seeAllLink={seeAllLink}
        carouselData={list}
        modelType={modelType}
        onEdit={onEdit}
        loading={loading}
        emptyDisplay={emptyDisplay}
        customActions={customActions}
      />
      <EditProjectPopup
        data={editItem}
        show={Boolean(editItem)}
        onClose={() => setEditItem(null)}
        onUpdateSuccess={handleProjectUpdateSuccess}
      />
    </>
  );
}
