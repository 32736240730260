// this is thumbnail component for product, room, projects, props, etc
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
// import 'react-stacked-carousel/dist/index.css';
import { Card } from 'react-bootstrap';
import cx from 'classnames';
import stackedCards from '../assets/images/stackedCards.png';
// need to move to scss directory
// import './_thumbnailCard.scss';
import useToggle from 'react-use/lib/useToggle';
import { Image } from './';
// clean up to use new icons index.js
import { NOT_FAVOURITE_ICON, FAVOURITE_ICON, ACTION_DOTS_ICON } from '../constants/assetConstants';
import constants, { MODULE_TYPES, GET_MODULE_KEY_BY_ID } from '../constants/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { paginationApi } from '../api/globalapi';
import { apiToggleFavorite } from '../helpers/api';
import pluralize from 'pluralize';
import moment from 'moment';
import _ from 'lodash';
import { successToast } from '../helpers/toastHelper';
import { ThumbnailCardContext } from '../contexts';
import Loader from './Loader';
const { RENDER_STATUS, SHARED_FILES_CARD_BANNER } = constants;

export default function ThumbnailCard(props) {
  const navigate = useNavigate();
  let {
    thumbnailData,
    cardType,
    last = {},
    arr = [],
    id,
    itemObj,
    cardButtonList,
    clickUrl,
    showFavoriteIcon,
    onSuccess,
    capitalize = true,
    selectedItem,
  } = props;

  const {
    thumbnail,
    name,
    tag,
    created_at,
    created_by,
    is_favourite,
    images,
    selected,
    owner_organization_detail,
    subtitle,
    user_details,
    data,
    model_type,
  } = thumbnailData;

  const [isFavourite, setIsFavourite] = useState(is_favourite);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [userListOpen, toggleUserListOpen] = useToggle(false);
  const [favoriteLoading, setFavouriteLoading] = useState(false);
  const location = useLocation();

  const caRef = useRef();
  const { active, setActive } = useContext(ThumbnailCardContext);

  function handleShowActionBar() {
    if (id === active) {
      setActive(null);
      return;
    }

    setActive(id);
  }

  function handleHideActionBar(e) {
    if (caRef.current && !caRef.current.contains(e.target) && id === active) setActive(null);
  }

  useEffect(() => {
    window.addEventListener('click', handleHideActionBar);

    return () => {
      window.removeEventListener('click', handleHideActionBar);
    };
  }, [active]);

  useEffect(() => {
    if (is_favourite === true) {
      setIsFavourite(true);
    } else {
      setIsFavourite(false);
    }
  }, [is_favourite]);

  const handleCardClick = () => {
    if (clickUrl) {
      if (props?.newTab) {
        window.open(clickUrl, '_blank');
      } else {
        navigate(clickUrl, { state: thumbnailData });
      }
    }
    if (_.isFunction(props?.onClick)) {
      props?.onClick(id, cardType);
    }
  };

  const observer = useRef();
  const lastele = useCallback((node) => {
    let loading;
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        let link = arr?.next_link;
        if (link) {
          paginationApi(link, cardType, arr);
        }
      }
    });
    if (node) observer.current.observe(node);
  });

  const handleFavourite = () => {
    let newValue = !isFavourite;
    setFavouriteLoading(true);

    if (cardType) {
      let uId = id;
      let uCardType = cardType;
      if (uCardType === 'collection') {
        uId = thumbnailData?.data?.id;
        uCardType = GET_MODULE_KEY_BY_ID(thumbnailData?.model_type).type;
      }

      apiToggleFavorite(uId, newValue, uCardType)
        .then((res) => {
          if (res) {
            setIsFavourite(newValue);
            successToast(
              `This ${pluralize.singular(uCardType)} has been ${
                newValue ? 'favorited' : 'unfavorited'
              }.`
            );
            if (_.isFunction(onSuccess)) {
              onSuccess(uId);
            }
          }
        })
        .finally(() => setFavouriteLoading(false));
    } else {
      console.log('A MISSING MODEL TO ADD TO FAVORITES... ');
    }
  };

  const preventPropagation = (e) => {
    e.stopPropagation();
  };

  const convertTimeStamp = (date) => {
    return moment.utc(date).local().fromNow();

    // outputs: "00:39:30"
  };
  // TODO: handle case with no image
  const thumbnailSrc =
    thumbnail || images?.[0]?.thumbnail || data?.thumbnail || data?.images?.[0]?.thumbnail || '';

  return (
    <Card
      className={cx(
        'thumbnail-card mb-0',
        props?.fullWidth && ' fullWidth',
        cardType === 'teams' && thumbnailData?.is_active === false && 'opacity-50',
        props?.className
      )}
      id={`thumbnail-card-${id}`}
      ref={last?.id === id ? lastele : null}>
      <div role="button" onClick={handleCardClick} className="thumbnail-card-top-section">
        <div className="thumbnail-img">
          <div
            className={cx(
              'thumbnail-action',
              selected && 'thumbnail-action--selected',
              isFavourite && 'thumbnail-action--favourite'
            )}>
            <ul className="action-list">
              <li className="thumbnail-action-checkbox">
                {_.isFunction(props?.onSelect) && (
                  <>
                    <input
                      type="checkbox"
                      name="selectCard"
                      id={`selectCard-${id}`}
                      className="selectCard"
                      value={id}
                      onChange={() => props?.onSelect(id)}
                      checked={selected || false}
                      onClick={preventPropagation}
                    />
                    <label htmlFor={`selectCard-${id}`} onClick={preventPropagation}></label>
                  </>
                )}
              </li>
              {showFavoriteIcon && (
                <li className="thumbnail-action-favourite">
                  <input
                    type="checkbox"
                    name="favouriteCard"
                    id={`favouriteCard-${id}`}
                    className="favouriteCard"
                    onChange={handleFavourite}
                    checked={isFavourite}
                    onClick={preventPropagation}
                  />
                  <label htmlFor={`favouriteCard-${id}`} onClick={preventPropagation}>
                    {favoriteLoading ? (
                      <Loader loading={favoriteLoading} size={14} thickness={1} />
                    ) : (
                      <img
                        src={isFavourite ? FAVOURITE_ICON : NOT_FAVOURITE_ICON}
                        width="13px"
                        alt="favourite"
                      />
                    )}
                  </label>
                </li>
              )}
            </ul>
          </div>
          <Image
            src={props?.stackedCardImages ? stackedCards : thumbnailSrc}
            placeholderWhileLoading
            ratio={1.259}
          />
          {/* )} */}
          {props?.stackedCardImages && (
            <div
              className="itemCount"
              style={{
                userSelect: 'none',
                cursor: 'pointer',
              }}>{`${thumbnailData?.item_count} items`}</div>
          )}
          <div className="main-item-toggel">
            {userListOpen &&
              user_details?.map((item, index) => (
                <div className="inner-item-part" key={index}>
                  <div className="item-image-text">
                    <img src={item?.shared_to_detail?.user?.profile_image || stackedCards} />
                    <span>{item?.shared_to_detail?.organization_detail?.name}</span>
                  </div>
                  <div className="item-hours-part">{convertTimeStamp(item?.updated_at)}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Card.Body
        ref={caRef}
        className={cx('thumbnail-info render-status', {
          camSelected: props?.activeCameraId === thumbnailData?.id,
          renderSelected: selected || selectedItem?.id === thumbnailData?.id,
          renderFailed: (cardType === 'renders-previews' || location?.pathname.includes("render"))  && thumbnailData?.is_custom && thumbnailData?.status && thumbnailData?.status === RENDER_STATUS.STATUS_FAILED,
          renderDone: (cardType === 'renders-previews' || location?.pathname.includes("render")) && thumbnailData?.status && thumbnailData?.status === RENDER_STATUS.STATUS_DONE,
          teamsPageCard: cardType === 'teams',
          inactive: cardType === 'teams' && thumbnailData?.is_active === false,
        })}>
        <Card.Title className="inner-info">
          {cardType === 'teams' ? (
            <div
              className={cx(
                'teamsPageCard__title',
                thumbnailData?.is_online !== undefined &&
                  thumbnailData?.is_active === true &&
                  'position-relative ps-3'
              )}>
              {thumbnailData?.is_active === true && (
                <span
                  style={{ backgroundColor: thumbnailData?.is_online ? '#1BB75E' : '#E9E9E9' }}
                  className="TeamMainCardStatus"></span>
              )}
              <p>
                {capitalize ? _.startCase(_.toLower(name)) : name}{' '}
                <span className={cx('teamsPageCard__role', !thumbnailData?.is_active && 'd-none')}>
                  {thumbnailData?.role.name !== 'Member' && `(${thumbnailData?.role.name})`}
                </span>
              </p>
            </div>
          ) : (
            <h2
              className={cx(
                'thumbnail-title',
                (cardType === 'templates' || cardType === 'rooms') && 'link'
              )}
              onClick={() =>
                (cardType === 'templates' || cardType === 'rooms') && props?.onEdit(id)
              }>
              {capitalize ? _.startCase(_.toLower(name)) : name}
            </h2>
          )}

          {/* TODO -- once we have user images uploads, need to add to card here if they have one */}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {thumbnailData?.member_details ? (
              <>
                <div className="custom-imges" style={{ marginRight: '5px' }}>
                  <span style={{ cursor: 'pointer', display: 'flex' }}>
                    <span className="avatar" style={{ height: 25, width: 25 }}>
                      <img
                        src={
                          thumbnailData?.member_details?.profile_image ||
                          'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
                        }
                      />
                    </span>
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
            {user_details && user_details?.length ? (
              <>
                <div className="custom-imges" style={{ marginRight: '5px' }}>
                  <span style={{ cursor: 'pointer', display: 'flex' }} onClick={toggleUserListOpen}>
                    {user_details?.map((item, index) =>
                      index < 2 ? (
                        <span className="avatar" key={index}>
                          <img src={item?.shared_to_detail?.user?.profile_image || stackedCards} />
                        </span>
                      ) : null
                    )}
                    {user_details?.length > 2 ? (
                      <span className="avatar avtar-count-part ">+{user_details?.length - 2}</span>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </>
            ) : (
              ''
            )}
            {created_at && (
              <h5 className="thumbnail-etime mt-1 d-flex w-100 justify-content-between pe-2">
                <span>{`${moment.utc(created_at).local().fromNow()}`}</span>
                {thumbnailData?.status >= 0 && cardType === 'renders-previews' ? (
                  <span>
                    Status: {images?.filter((a) => a.status === RENDER_STATUS.STATUS_DONE).length}/
                    {images?.length}
                  </span>
                ) : null}
              </h5>
            )}
          </div>
          {owner_organization_detail && (
            <h5 className="thumbnail-etime fontSize85">
              <span>{owner_organization_detail?.name}</span>
            </h5>
          )}
        
          <h5 className={cx('thumbnail-etime', 'fontSize85')}>
            {
              subtitle || (cardType === 'teams' ? <>&nbsp;</> : <></>)
            }
          </h5>
        </Card.Title>
        {cardButtonList && cardButtonList?.length > 0 && (
          <div className="inner-action">
            <div className="card-action" onClick={handleShowActionBar}>
              <img src={ACTION_DOTS_ICON} alt="more" />
            </div>
            {id === active && (
              <ul
                className="action-list ced-action"
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                {_.map(cardButtonList, (item, index) => {
                  const { element, ...cardProps } = item;
                  const Component = element;
                  return (
                    <Component
                      key={`cardElement_${cardType}${id}${index}}`}
                      id={id}
                      sharedFile={{
                        id: data?.id,
                        cardType: SHARED_FILES_CARD_BANNER?.[model_type]?.cardType,
                        model_type,
                      }}
                      {...cardProps}
                      selectedList={selected}
                      cardType={cardType}
                    />
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
