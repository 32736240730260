import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';

const AuthGuard = ({ children }) => {
  const userCtx = useUserContext();
  if (!(userCtx.token && userCtx.refreshToken && userCtx.emailVerified && userCtx.currentUser)) {
    return <Outlet />;
  } else {
    return <Navigate to={'/dashboard'} />;
  }
};

export default AuthGuard;
