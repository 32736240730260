import _ from 'lodash';

export default function MiniButton(props) {
  const {
    id,
    cardType,
    title,
    clickFunction,
    showModalFunction,
    alt,
    iconSrc,
    inPreview,
    sharedFile,
    isLoading = false,
  } = props;

  function handleClickEvent(e) {
    if (_.isFunction(clickFunction)) {
      clickFunction(id, cardType, sharedFile);
    } else if (_.isFunction(showModalFunction)) {
      showModalFunction(true);
    }
  }

  return (
    <li title={title} className={props.buttonLabel ? 'has-text' : 'no-text'}>
      {isLoading ? (
        <div className="spinner-border text-warning" role="status" style={{ width: '16px' }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div onClick={handleClickEvent}>
          <img className="img-fluid" src={iconSrc} alt={alt || title} />
          {props.buttonLabel}
        </div>
      )}
    </li>
  );
}
