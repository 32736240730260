import React, { memo, useState } from 'react';
import cx from 'classnames';
import styles from './NotificationsPanel.module.scss'
import moment from 'moment';
import { Loader } from '../';
import { icons } from '../../assets';
import { NOTIFICATION_MODEL_TYPE } from '../../constants/constants';
import { Link } from 'react-router-dom';
import { RENDER_DETAILS, SHARED_FILES_ROUTE } from '../../constants/routePathConstants';

const {
  GreenTickIcon,
  RedCrossIcon
} = icons;


function NameLink({type, name, target_object_id}) {

  const title = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

  if(!type && !name && !target_object_id) return null;
  if(NOTIFICATION_MODEL_TYPE.RenderStatus === type) {
    return <Link to={RENDER_DETAILS.replace(':id', target_object_id )} target="_blank" onClick={(e) =>  e.stopPropagation()}>{title}</Link>;
  }else if(NOTIFICATION_MODEL_TYPE.Preview === type) {
    return <Link to={RENDER_DETAILS.replace(':id', target_object_id )} target="_blank" onClick={(e) =>  e.stopPropagation()}>{title}</Link>;
  }else if(NOTIFICATION_MODEL_TYPE.SharedFiles === type) {
    return <Link to={SHARED_FILES_ROUTE} target="_blank" onClick={(e) =>  e.stopPropagation()}>{title}</Link>;
  }else {
    return null;
  }

}

const NotificationsPanelCard = memo(({ data: { id, level, verb, timestamp, unread, is_muted, target_object_id, name, type }, onClick }) => {

  const NOTIFICATION_PLACEHOLDER = "{LabelLinkPlaceholder}".toLowerCase();

  const title = name !== null && name?.toLowerCase();
  const message = verb !== null && verb?.toLowerCase();
  
  const [loading, setLoading] = useState(false);
  const getClickedId = async (id) => {
    await onClick(id);
    setLoading(false);
  }

  if(loading) return <Loader loading={loading} position="relative" backdrop={true} className="scroll-loader" />;
  return (
    <div key={id} className={
      cx(
        styles.notificationCard,
        unread ? styles.unread : '',
        is_muted ? styles.muted : ''
        )
      }
      title={unread ? 'Click to mark as read.' : ''}
      onClick={ unread ? (e) => { setLoading(true); getClickedId(id); } : () => {} }
      >
      <h5>
        {
          level === "success"
          ?
          <GreenTickIcon />
          :
            (level === "error" || level === "failed" || level === "disabled") 
            ? <RedCrossIcon /> 
            : ''
        }
        {
          message.includes(NOTIFICATION_PLACEHOLDER)
          ?
            (type && title && target_object_id)
            &&
            <>
              {
                message.indexOf(NOTIFICATION_PLACEHOLDER) !== 0
                ?
                  message.slice(0,1).toUpperCase() + message.slice(1, message.indexOf(NOTIFICATION_PLACEHOLDER))
                :
                message.slice(0, message.indexOf(NOTIFICATION_PLACEHOLDER))
              }
              <NameLink type={type} name={title} target_object_id={target_object_id} />
              {message.slice(message.indexOf(NOTIFICATION_PLACEHOLDER) + NOTIFICATION_PLACEHOLDER.length)}
            </>
          :
          message.replace(NOTIFICATION_PLACEHOLDER, title).slice(0,1).toUpperCase() + message.replace(NOTIFICATION_PLACEHOLDER, title).slice(1).toLowerCase()
        }
        
      </h5>
      <p>{moment.utc(timestamp).local().fromNow()}</p>
    </div>
  );
});

export default NotificationsPanelCard;
