import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAllMyOrgs } from '../api/settings';
import { BLACK_DOWN_ARROW } from '../constants/assetConstants';
import OrgLogoPlaceholder from '../assets/images/organisation.svg';
import { apiCreateOrganization } from '../helpers/api';
import { searchSelector } from '../redux/slicers/search/searchSlicer';
import { warningToast } from '../helpers/toastHelper';
import { useSwitchToOrganization } from '../hooks/useSwitchToOrganization';
import { useUserContext } from '../contexts/UserContext';
import Button from '../components/Button/Button';
import { useClickAway } from 'react-use';

export const OrganizationSwitcher = () => {
  const dispatch = useDispatch();

  const { allMyOrg } = useSelector(searchSelector);
  const { switchToOrganization } = useSwitchToOrganization();
  const userCtx = useUserContext();
  const location = useLocation();
  const [showOrgDropdown, setShowOrgDropdown] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    setShowOrgDropdown(false);
  });

  useEffect(() => {
    getOrgs();
  }, []);

  useEffect(() => {
    setShowOrgDropdown(false);
  }, [location]);

  async function getOrgs() {
    dispatch(getAllMyOrgs());
  }

  function handleOrgClick(orgId) {
    switchToOrganization(orgId, {
      onSuccess: () => {
        setShowInput(false);
        setShowOrgDropdown(false);
      },
    });
  }

  const handleToggle = () => {
    setShowOrgDropdown(!showOrgDropdown);
  };

  async function handleSave() {
    if (!newOrgName) {
      warningToast('Please provide the organisation name.');
      return;
    }

    setLoading(true);
    const res = await apiCreateOrganization(newOrgName, userCtx.currentUser.id);
    getOrgs();
    setShowInput(false);
    setNewOrgName('');
    setLoading(false);
  }

  const handleCancel = () => {
    setShowInput(false);
  };

  return (
    <header className="organisation fullWidth" ref={ref}>
      <a className="organization-toggle" onClick={() => handleToggle()}>
        <img
          className="orgImg"
          src={userCtx.currentUser?.organization_detail?.thumbnail || OrgLogoPlaceholder}
          alt=""
        />
        <span className="menu-label" title={userCtx.currentUser?.organization_detail?.name}>{userCtx.currentUser?.organization_detail?.name}</span>
        <span role="button" className="downarrow">
          <img
            src={BLACK_DOWN_ARROW}
            alt=""
            style={showOrgDropdown ? { transform: 'rotate(180deg)' } : null}
            width={16}
            height={10}
          />
        </span>
      </a>
      <nav className="menu org-switch" style={{ display: showOrgDropdown ? 'block' : 'none' }}>
        <h5>
          <span className="text-muted">{userCtx.currentUser?.organization_detail?.name || 'Organization'}</span>
        </h5>
        <ul>
          {showInput ? (
            <li className="createOrgForm">
              <Form.Control
                type="text"
                name="orgname"
                value={newOrgName}
                maxLength={50}
                onChange={(e) => setNewOrgName(e.target.value)}
                placeholder="Enter name..."
              />
              <div className="d-flex align-items-center mt-2 gap-2">
                <Button disabled={loading} className="cancel" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  color="warning"
                  disabled={loading}
                  loading={loading}
                  className="save"
                  onClick={handleSave}>
                  Save
                </Button>
              </div>
            </li>
          ) : (
            <li role="button" onClick={() => setShowInput(true)}>
              <span className="text-action">+ Add Organization</span>
            </li>
          )}
          {allMyOrg?.results?.map((a, i) => {

            if(userCtx.currentUser?.organization_detail?.name !== a?.name) {
              return (
                <li key={`orgItem${i}`} role="button" onClick={() => handleOrgClick(a.id)}>
                  <span
                    className={
                      userCtx.currentUser?.organization_detail?.name === a.name ? 'active' : ''
                    }
                    title={a.name}>
                    {a.name}
                  </span>
                </li>
              )
            }
          })}
        </ul>
      </nav>
    </header>
  );
};
