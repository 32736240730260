import MyProps, { EDIT_TYPE } from './MyProps';
import { useUserContext } from '../contexts/UserContext';
import { MODEL_TYPES } from '../constants/constants';

export default function MyProducts3dModel({
  cardType = 'products',
  useGlobalSearch,
  noDataText = `You don't have any 3D Models.`,
  apiConfig,
  cardProps,
}) {
  const { currentUser } = useUserContext();
  const userCurrentOrg = currentUser?.organization;

  return (
    <MyProps
      title="3D Models"
      pageTitle="3d Models - My Products"
      cardType={cardType}
      editType={EDIT_TYPE.MODEL_3D}
      modelType={MODEL_TYPES.THREEDMODELS}
      noDataText={noDataText}
      useGlobalSearch={useGlobalSearch}
      cardProps={cardProps}
      apiConfig={{
        defaultBrands: userCurrentOrg,
        ...apiConfig,
      }}
    />
  );
}
