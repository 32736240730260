import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';
import {
  AXIS,
  CURSOR_MODE,
  DIMENSION,
  MEASURE_UNITY_NAME_MAP,
  MOVE_MODE,
  ROTATE_MODE,
  TRANSFORM_SPACE_MODE,
  UNITY_2D_VIEW_MODE,
} from '../../../config/constant/unityConstants';
import { ROOM_TYPE } from '../../../constants/projectConstants';
import { CURATOR_MENU_SECTION } from './curatorMenuPreferencesSlicer';

export const SAVE_PROJECT_MODE = {
  SAVE: 'SAVE',
  NEW: 'NEW',
  CLOSE_CURATOR: 'CLOSE_CURATOR',
  SAVE_ON_LOCAL: 'SAVE_ON_LOCAL',
};

export const SETTINGS_SECTION = {
  RESOLUTION_PRESETS: 'RESOLUTION_PRESETS',
  CAMERA_SETTINGS: 'CAMERA_SETTINGS',
  GRAPHIC_QUALITY: 'GRAPHIC_QUALITY',
  DEFAULT_MEASURE_UNITS: 'DEFAULT_MEASURE_UNITS',
  METADATA_SETTINGS: 'METADATA_SETTINGS',
};

export const initialState = {
  uuid: uuidv4(), // unique tab uuid for curator
  calculatedRoomId: null,
  curatorInitialized: process.env.REACT_APP_CURATOR_INITIALIZED === 'true',
  sidebarSection: null, //CURATOR_MENU_SECTION.CAMERAS,
  sidebarExpanded: false,
  preventProjectInitialization: false,
  filterOpen: null, // 'product', 'texture', 'material',
  layoutData: {},
  roomData: {},
  propsData: [],
  unityData: {},
  saveProjectDialogOpen: false,
  projectData: [],
  unityTrigger: false,
  projectJson: {},
  settingsNotSaved: false,
  individualProjectData: {},
  unityLoaded: false,
  allPropsLoaded: false,
  roomLoadedCompletely: false,
  curatorLoader: false,
  saveProjectMode: SAVE_PROJECT_MODE.SAVE,
  unity2DViewMode: UNITY_2D_VIEW_MODE.TOP,
  dimension: DIMENSION._3D,
  cursorMode: CURSOR_MODE.MOVE,
  selectedAxis: AXIS.ALL,
  saveProjectLoading: false,
  transformSpaceMode: TRANSFORM_SPACE_MODE.GLOBAL,
  // rotateMode: null, // ROTATE_MODE.GLOBAL
  settingData: {},
  oldSettingData: {},
  defaultDetaData: [],
  textureVersionSelectorDialogOpen: false,
  importProjectTexturesConflictList: [],
  settingsSection: null,
};

const curator = createSlice({
  name: 'curator',
  initialState,
  reducers: {
    resetState: (state) => {
      return {
        ...initialState,
        settingData: state.settingData,
        oldSettingData: state.oldSettingData,
      };
    },
    toggleSidebarSection: (state, { payload }) => {
      state.sidebarSection = state.sidebarSection === payload ? null : payload;
    },
    setSidebarSection: (state, { payload }) => {
      state.sidebarSection = payload;
    },
    setLayoutData: (state, { payload }) => {
      state.layoutData = payload;
    },
    setRoomData: (state, { payload }) => {
      state.roomData = payload;
    },
    setPropsData: (state, { payload }) => {
      state.propsData = payload;
    },
    setUnityData: (state, { payload }) => {
      state.unityData = payload;
    },
    setSaveProjectDialogOpen: (state, { payload }) => {
      state.saveProjectDialogOpen = payload;
    },
    setProjectData: (state, { payload }) => {
      state.projectData = payload;
    },
    setUnityTrigger: (state, { payload }) => {
      state.setUnityTrigger = payload;
    },
    setProjectJson: (state, { payload }) => {
      state.projectJson = payload;
    },
    setIndividualProjectData: (state, { payload }) => {
      state.individualProjectData = payload;
    },
    setUnityLoaded: (state, { payload }) => {
      state.unityLoaded = payload;
    },
    setAllPropsLoaded: (state, { payload }) => {
      state.allPropsLoaded = payload;
    },
    setRoomLoadedCompletely: (state, { payload }) => {
      state.roomLoadedCompletely = payload;
    },
    setCuratorLoader: (state, { payload }) => {
      state.curatorLoader = payload;
    },
    getCuratorSettingData: (state, { payload }) => {
      state.oldSettingData = payload;
      state.settingData = payload;
    },
    setCuratorSettingData: (state, { payload }) => {
      state.settingData = payload;
    },
    updateCameraSettings: (state, { payload }) => {
      const { settingsKey, value } = payload;
      state.settingData = {
        ...state.settingData,
        json_data: {
          ...state.settingData?.json_data,
          cameraSetting: {
            ...state.settingData?.json_data?.cameraSetting,
            [settingsKey]: parseFloat(value),
          },
        },
      };
    },
    setSelectedGraphicQuality: (state, { payload }) => {
      const { id } = payload;
      const updatedGraphicQuality = [...state.settingData.json_data.graphicQuality].map((item) =>
        item.id === id ? { ...item, isSelected: true } : { ...item, isSelected: false }
      );
      state.settingData.json_data.graphicQuality = updatedGraphicQuality;
    },
    setSelectedMeasureUnit: (state, { payload }) => {
      const { id } = payload;
      const updatedMeasureUnit = [...state.settingData.json_data.measureUnit].map((item) =>
        item.id === id ? { ...item, isSelected: true } : { ...item, isSelected: false }
      );
      state.settingData.json_data.measureUnit = updatedMeasureUnit;
      state.settingsNotSaved = true;

      state.oldSettingData.json_data.measureUnit = updatedMeasureUnit;
    },
    setMetadataIsRender: (state, { payload }) => {
      const { id, isRender } = payload;

      state.settingData.json_data.metaData = state.settingData.json_data.metaData.map((item) => {
        return id === item.id ? { ...item, is_render: isRender } : item;
      });
    },
    setSelectedResolutionPreset: (state, { payload }) => {
      state.settingData.json_data.resolutionPresent = [
        ...state.settingData.json_data.resolutionPresent,
      ].map((resolutionPreset) => {
        return { ...resolutionPreset, isSelected: resolutionPreset.id === payload };
      });
    },
    updateResolutionPreset: (state, { payload }) => {
      state.settingData.json_data.resolutionPresent =
        state.settingData.json_data.resolutionPresent.map((preset) => {
          return preset.id === payload.id ? { ...preset, ...payload } : preset;
        });
    },
    editCuratorSettingData: (state, { payload }) => {
      state.oldSettingData = { ...state.oldSettingData, ...payload };
      state.settingData = { ...state.settingData, ...payload };
    },
    setDefaultMetaData: (state, { payload }) => {
      state.defaultDetaData = payload;
    },
    toggleDimension: (state, { payload }) => {
      state.dimension = state.dimension === DIMENSION._2D ? DIMENSION._3D : DIMENSION._2D;
    },
    setUnity2DViewMode: (state, { payload }) => {
      state.unity2DViewMode = payload;
    },
    syncLightFromUnity: (state, { payload }) => {
      const exist = Boolean(state.unityData.roomLightData.find((l) => l.id === payload.id));
      if (exist) {
        state.unityData.roomLightData = state.unityData.roomLightData.map((l) => {
          return l.id === payload.id ? payload : l;
        });
      } else {
        state.unityData.roomLightData.unshift(payload);
      }
    },
    updateLight: (state, { payload }) => {
      state.unityData.roomLightData = state.unityData.roomLightData.map((light) => {
        return light.id === payload.id ? payload : light;
      });
    },
    deleteLight: (state, { payload }) => {
      state.unityData.roomLightData = state.unityData.roomLightData.filter(
        (light) => light.id !== payload
      );
    },
    toggleSidebarExpanded: (state, { payload }) => {
      state.sidebarExpanded = typeof payload === 'boolean' ? payload : !state.sidebarExpanded;
    },
    setSidebarExpanded: (state, { payload }) => {
      state.sidebarExpanded = payload;
    },
    toggleFilterOpen: (state, { payload }) => {
      if (!payload) {
        state.filterOpen = null;
      }
      state.filterOpen = state.filterOpen === payload ? null : payload;
    },
    setCursorMode: (state, { payload }) => {
      state.cursorMode = payload;
    },
    setTransformSpaceMode: (state, { payload }) => {
      state.transformSpaceMode = payload;
    },
    toggleSelectedAxis: (state, { payload }) => {
      state.selectedAxis = state.selectedAxis === payload ? AXIS.ALL : payload;
    },
    resetCursorModeConfig: (state, { payload }) => {
      state.selectedAxis = AXIS.ALL;
      state.transformSpaceMode = TRANSFORM_SPACE_MODE.GLOBAL;
    },
    setCalculatedRoomId: (state, { payload }) => {
      state.calculatedRoomId = payload;
    },
    setSaveProjectMode: (state, { payload }) => {
      state.saveProjectMode = payload;
    },
    setSaveProjectLoading: (state, { payload }) => {
      state.saveProjectLoading = payload;
    },
    setCuratorInitialized: (state, { payload }) => {
      state.curatorInitialized = payload;
    },
    setRoomLightData: (state, { payload }) => {
      state.unityData.roomLightData = payload;
    },
    setTextureVersionSelectorDialogOpen: (state, { payload }) => {
      state.textureVersionSelectorDialogOpen = payload;
    },
    setImportProjectTexturesConflictList: (state, { payload }) => {
      state.importProjectTexturesConflictList = payload;
    },
    setSettingsSection: (state, { payload }) => {
      state.settingsSection = payload;
    },
    toggleSettingsSection: (state, { payload }) => {
      state.settingsSection = payload && payload === state.settingsSection ? null : payload;
    },
    openSettingsSection: (state, { payload }) => {
      state.settingsSection = payload;
    },
    setSettingsSaved: (state, { payload }) => {
      state.settingsNotSaved = payload;
    },
    saveAsNew: (state, { payload }) => {
      const { project } = payload;
      state.preventProjectInitialization = true;
      state.individualProjectData = project;
      state.roomData = initialState.roomData;
    },
    unblockProjectInitialization: (state) => {
      state.preventProjectInitialization = false;
    },
  },
});

export const {
  toggleSidebarSection,
  setCuratorLoader,
  setIndividualProjectData,
  setProjectJson,
  setUnityTrigger,
  setProjectData,
  setLayoutData,
  setRoomData,
  setPropsData,
  setUnityData,
  setSettingsSaved,
  setCuratorSettingData,
  editCuratorSettingData,
  getCuratorSettingData,
  updateCameraSettings,
  setMetadataIsRender,
  setSelectedGraphicQuality,
  setSelectedMeasureUnit,
  setDefaultMetaData,
  syncLightFromUnity,
  updateLight,
  toggleSidebarExpanded,
  setSidebarExpanded,
  toggleFilterOpen,
  toggleDimension,
  setUnity2DViewMode,
  setCursorMode,
  setTransformSpaceMode,
  setSelectedAxis,
  toggleSelectedAxis,
  setSaveProjectDialogOpen,
  setSaveProjectMode,
  setSaveProjectLoading,
  resetCursorModeConfig,
  setUnityLoaded,
  setAllPropsLoaded,
  setRoomLoadedCompletely,
  setCuratorInitialized,
  setSidebarSection,
  setSelectedResolutionPreset,
  updateResolutionPreset,
  setRoomLightData,
  setTextureVersionSelectorDialogOpen,
  setImportProjectTexturesConflictList,
  setSettingsSection,
  toggleSettingsSection,
  openSettingsSection,
  saveAsNew,
  unblockProjectInitialization,
  setCalculatedRoomId,
} = curator.actions;
export const curatorActions = curator.actions;

export const curatorSelector = (state) => state.curator;
export const curatorReducer = curator.reducer;
export default curatorReducer;

export const selectUuid = (state) => curatorSelector(state).uuid;
export const selectIsLoading = (state) => !curatorSelector(state).curatorLoader;
export const selectUnityLoaded = (state) => curatorSelector(state).unityLoaded;
export const selectAllPropsLoaded = (state) => curatorSelector(state).allPropsLoaded;
export const selectRoomLoadedCompletely = (state) => curatorSelector(state).roomLoadedCompletely;
export const selectUnityData = (state) => curatorSelector(state).unityData;
export const selectDimension = (state) => curatorSelector(state).dimension;
export const selectIs2DMode = (state) => curatorSelector(state).dimension === DIMENSION._2D;
export const selectIs3DMode = (state) => curatorSelector(state).dimension === DIMENSION._3D;
export const selectCursorMode = (state) => curatorSelector(state).cursorMode;
export const selectIsStyleOnlyMode = (state) =>
  curatorSelector(state).cursorMode === CURSOR_MODE.STYLE_ONLY;
export const selectSelectedAxis = (state) => curatorSelector(state).selectedAxis;
export const selectIsOnlyOneAxisSelected = (state) =>
  curatorSelector(state).selectedAxis !== AXIS.ALL;
export const selectTransformSpaceMode = (state) => curatorSelector(state).transformSpaceMode;
export const selectUnity2DViewMode = (state) => curatorSelector(state).unity2DViewMode;
export const selectSaveProjectDialogOpen = (state) => curatorSelector(state).saveProjectDialogOpen;
export const selectSaveProjectMode = (state) => curatorSelector(state).saveProjectMode;
export const selectSaveProjectLoading = (state) => curatorSelector(state).saveProjectLoading;
export const selectSettingData = (state) => curatorSelector(state).settingData;
export const selectOldSettingData = (state) => curatorSelector(state).oldSettingData;
export const selectMeasureUnitList = (state) => curatorSelector(state).oldSettingData?.json_data?.measureUnit
export const selectSelectedMeasureUnits = (state) =>
  curatorSelector(state).oldSettingData?.json_data?.measureUnit?.find(
    (item) => item?.isSelected === true
  );
export const selectActiveMeasureUnitsName = (state) => {
  const selected = selectSelectedMeasureUnits(state);
  return MEASURE_UNITY_NAME_MAP[selected.id];
}
export const selectMeasureUnitById = (state, id) => {
  const list = selectMeasureUnitList;
  return list.find(item => item.id === id);
}
export const selectSettingsNotSaved = (state) => curatorSelector(state).settingsNotSaved;
export const selectResolutionPresets = (state) =>
  curatorSelector(state).settingData?.json_data?.resolutionPresent;
export const selectSidebarExpanded = (state) => state.curator.sidebarExpanded;

export const selectCuratorInitialized = (state) => curatorSelector(state).curatorInitialized;
export const curatorActionsDisabled = (state) => !curatorSelector(state).curatorInitialized;
export const selecteIsSidebarOpen = (state) => Boolean(state.curator.sidebarSection);
export const selectSidebarSection = (state) => state.curator.sidebarSection;
export const selecteIsFilterOpen = (state) => Boolean(state.curator.filterOpen);
export const selectRoomId = (state) => state.curator.roomData?.id;
export const selectRoomName = (state) => state.curator.roomData?.name;
export const selectMeasureUnit = (state) =>
  state.curator.settingData?.json_data?.measureUnit?.find((m) => m.isSelected);
export const selectIsSiloRoom = (state) =>
  state.curator.roomData?.room_type === ROOM_TYPE.SILO ||
  state.curator.individualProjectData?.project_type === ROOM_TYPE.SILO;
export const selectPreventProjectInitialization = (state) =>
  state.curator.preventProjectInitialization;
export const selectCalculatedRoomId = (state) => state.curator.calculatedRoomId; // state.curator.individualProjectData?.roomId || state.curator.roomData?.id

export const selectIndividualProjectData = (state) => state.curator.individualProjectData;
export const selectProjectOwner = (state) => state.curator.individualProjectData?.member_details;
export const selectRoomData = (state) => state.curator.roomData;
export const selectProjectName = (state) => state.curator.individualProjectData?.name;
export const selectTextureVersionSelectorDialogOpen = (state) =>
  state.curator.textureVersionSelectorDialogOpen;
export const selectImportProjectTexturesConflictList = (state) =>
  state.curator.importProjectTexturesConflictList;
export const selectSettingsSection = (state) => state.curator.settingsSection;
export const selectProjectJsonUrl = (state) => selectIndividualProjectData(state)?.data?.json_file;
export const selectLightById = (state, id) => {
  return state.curator.unityData.roomLightData.find((light) => light.id === id);
};

export const selectProjectType = (state) => {
  const roomData = selectRoomData(state);
  const individualProjectData = selectIndividualProjectData(state);

  return individualProjectData?.project_type || roomData.room_type;
};

export const selectMetadataSettings = (state) => state.curator.settingData?.json_data?.metaData;
export const selectLightList = (state) => curatorSelector(state).unityData.roomLightData;

export const selectAxisAvailability = (state) => {
  const is3D = selectIs3DMode(state);
  const unity2DViewMode = selectUnity2DViewMode(state);
  const isTopView = unity2DViewMode === UNITY_2D_VIEW_MODE.TOP;
  const isBackView = unity2DViewMode === UNITY_2D_VIEW_MODE.BACK;
  const isFrontView = unity2DViewMode === UNITY_2D_VIEW_MODE.FRONT;
  const isLeftView = unity2DViewMode === UNITY_2D_VIEW_MODE.LEFT;
  const isRightView = unity2DViewMode === UNITY_2D_VIEW_MODE.RIGHT;
  const cursorMode = selectCursorMode(state);

  const isAxisVisible = (axis) => {
    if (is3D) return true;
    if (cursorMode === CURSOR_MODE.ROTATE) return true;

    switch (axis) {
      case AXIS.X:
        return isTopView || isBackView || isFrontView;
      case AXIS.Y:
        return !isTopView;
      case AXIS.Z:
        return isTopView || isLeftView || isRightView;
      default:
        throw new Error('Not supported axis: ' + axis);
    }
  };

  return {
    [AXIS.X]: isAxisVisible(AXIS.X),
    [AXIS.Y]: isAxisVisible(AXIS.Y),
    [AXIS.Z]: isAxisVisible(AXIS.Z),
  };
};
