import { useThumbnailCardDataList } from "../../../hooks/useDataList";
import { getRenderPreviewListApi } from "../../../api/renderPreview";
import { myRenderPreviewSelector, myRenderPreviewActions } from "../../../redux/slicers/render_preview/myRenderPreviewSlice";

export const useRendersPreviews = ({ requestParams, filterByKey, defaultSort = '-created_at', useGlobalSearch, defaultFilterBy }) => {
  const data = useThumbnailCardDataList({
    selector: myRenderPreviewSelector,
    resetAction: myRenderPreviewActions.resetList,
    getAction: getRenderPreviewListApi,
    itemDeleteText: "Are you sure you want to delete this preview?",
    itemsDeleteText: (selectedIds) =>
      `Are you sure you want to delete ${selectedIds.length} previews?`,
    requestParams,
    filterByKey,
    defaultSort,
    useGlobalFilter: true,
    useGlobalSearch,
    defaultFilterBy,
  });

  return data
};

