import download from 'downloadjs';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteTextureAPI, updateTextureApi } from '../api/textures';
import { MODEL_TYPES } from '../constants/constants';
import { PERMISSION } from '../constants/permissionConstants';
import {
  ADMIN_MY_PRODUCTS_TEXTURE_EDIT,
  ADMIN_MY_PRODUCTS_UPLOADS_MOVE_TO_TEXTURE,
} from '../constants/routePathConstants';
import { MODE_EDIT } from "../constants/textureConstants";
import { apiGetDownloadTextures } from '../helpers/api';
import { apiErrorHandler } from '../helpers/axiosHelper';
import { textureDetailsModalActions } from '../redux/slicers/textureDetailsModalSlicer';
import { texturesSelectors } from '../redux/slicers/texturesSlicer';
import { useModelActions } from './useModelActions';
import { usePermissionsHandler } from './usePermissions';

export const useTextureActions = ({ list = [], item, modelType } = {}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handlePermissionEvent } = usePermissionsHandler()

  const { addToCollection, favouriteLoading, onDelete, onFavouriteToggle, onShare } =
    useModelActions({
      modelType: MODEL_TYPES.TEXTURE,
      item: item,
      selectors: texturesSelectors,
      updateApi: updateTextureApi,
      deleteConfig: {
        title: 'Are you sure you want to delete this texture?',
        deleteApi: deleteTextureAPI,
      },
    });

  const [loading, setLoading] = useState(false);

  function handleCardClick(id) {
    const texture = item ? item : list.find((item) => item.id === id);
    dispatch(textureDetailsModalActions.show(texture));
  }

  const handleEditClick = (idOrList) => {
    let textures;
    if (item) {
      textures = [item];
    } else {
      textures = Array.isArray(idOrList) ? idOrList : [list.find((p) => p.id === idOrList)];
    }

    if (isEmpty(textures)) {
      throw new Error('textures are missing');
    }
    navigate(ADMIN_MY_PRODUCTS_TEXTURE_EDIT, {
      state: { textures, mode: MODE_EDIT },
    });
  };

  function handleDownloadClick(idOrList) {
    let query = '';
    if (Array.isArray(idOrList)) {
      if (idOrList.length < 1) return;
      const idsStr = idOrList.map((i) => i.id).join(',');
      query = `ids[]=${idsStr}`;
    } else query = `ids[]=${idOrList}`;
    setLoading(true);
    apiGetDownloadTextures(query)
      .then((res) => {
        if (res === undefined)
          throw new Error('An error occured while downloading requested items');

        download(new Blob([res]), (`${item.name}.zip`));
      })
      .catch(apiErrorHandler)
      .then(() => setLoading(false));
  }

  const handleUploadEditClick = handlePermissionEvent(
    PERMISSION.UPLOAD_TEXTURE,
    async (idOrList) => {
      let textures;
      if (item) {
        textures = [item];
      } else {
        textures = Array.isArray(idOrList) ? idOrList : [list.find((p) => p.id === idOrList)];
      }
      navigate(ADMIN_MY_PRODUCTS_UPLOADS_MOVE_TO_TEXTURE, {
        state: { textures, mode: 'move-to-textures' },
      });
    }
  )

  const onEdit = modelType === MODEL_TYPES.UPLOADS ? handleUploadEditClick : handleEditClick;

  return {
    loading,
    handleEditClick,
    handleDownloadClick,
    handleCardClick,
    handleUploadEditClick,
    onEdit,

    addToCollection,
    favouriteLoading,
    onDelete,
    onFavouriteToggle,
    onShare,
  };
};
