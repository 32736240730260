import { Fragment, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Magnifier from "react-magnifier";

import { SEE_ALL } from "../../constants/projectConstants";
import { TEMPLATES_ROUTE } from "../../constants/routePathConstants";
import { apiGetRooms } from "../../helpers/api";
import { getProjectTypeLabel } from "../../helpers/dataHelper";
import { getCuratorRoomPath } from "../../helpers/urlHelper";
import { useApiList } from "../../hooks/useApiList";
import { useRoomTypeOptions } from "../../hooks/useRoomTypes";
import { propDetailsPopupActions, selectPropDetailsPopupData } from "../../redux/slicers/propDetailsPopupSlicer";
import Image from "../Image";
import RightSidebar from "../RightSidebar";
import styles from './PropDetailsPopup.module.scss';

export const PropDetailsPopup = () => {
  const dispatch = useDispatch()
  const editItem = useSelector(selectPropDetailsPopupData)
  const { roomTypeOptions } = useRoomTypeOptions();

  const onClose = () => {
    dispatch(propDetailsPopupActions.close());
  }

  const capName = editItem?.name.slice(0,1).toUpperCase() + editItem?.name.slice(1).toLowerCase();

  return (
    <RightSidebar
      backdropClassName="propsBackdrop"
      className="rightSidebar props"
      initialShowOpen={Boolean(editItem)}
      onClose={onClose}
      title={
        <>
          <div className="heading-user-title">
            <h4 className="m-0 ellipsis">{capName}</h4>
            <label className="fullWidth ellipsis light">
              { editItem?.sku_id && <>Sku ID - <span>{editItem?.sku_id}</span></> }
            </label>
          </div>
        </>
      }
      body={
        <>
          {/* <Image src={editItem?.thumbnail} rounded className="mb-4" /> */}
          <Magnifier src={editItem?.thumbnail} className={`${styles.imgPreviewContainer} mb-4`} />

          {roomTypeOptions.map((option) => {
            return <RoomTypeRow key={option.value} roomType={option.value} />;
          })}
        </>
      }
    />
  );
};


const RoomTypeRow = ({ roomType }) => {
  const params = useMemo(() => ({ room_type: roomType }), []);

  const { list: roomList } = useApiList({
    api: apiGetRooms,
    params,
    defaultPerPage: 3,
  });

  const roomLabel = getProjectTypeLabel(roomType);

  if (!roomList.length) return null;

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-end">
        <h6 className={styles.roomSectionTitle}>Create {roomLabel}</h6>
        <Link to={`${TEMPLATES_ROUTE}?roomType=${roomType}`} className={styles.roomSectionLink}>
          {SEE_ALL}
        </Link>
      </div>
      <div className={styles.imageList}>
        {roomList.map((room) => (
          <a
            href={getCuratorRoomPath(room.id)}
            key={room.id}
            className={styles.imageContainer}
            target="_blank"
            rel="noreferrer">
            <Image ratio={1} src={room.thumbnail} rounded placeholderWhileLoading Icon={null} />
          </a>
        ))}
      </div>
    </Fragment>
  );
};
