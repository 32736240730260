import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ThumbnailCard } from './';
import _ from 'lodash';
import constants, { CARD_TYPE, GET_MODULE_KEY_BY_ID } from '../constants/constants';
import { ThumbnailCardProject } from './ThumbnailCardProject';
const { SHARED_FILES_CARD_BANNER } = constants;

export default function ThumbnailContainer(props) {
  const { data, cardType, sideCard = false, containerClassName } = props;

  const lastEle = _.tail(data);

  if (!data || data.length < 1) return null;

  return (
    <Container
      id="infiniteScroll"
      className={`card-grid px-0 custom-card-section ${sideCard && 'justify-content-center'} ${
        containerClassName || ''
      }`}
      fluid>
      {/* <Row> */}
      {_.map(data, (item, i) => {
        let clickUrl = props.clickUrl ? props.clickUrl.replace(`:id`, item?.id) : null;
        let cardProps = { ...props };
        if (cardType === 'sharedFiles') {
          clickUrl = SHARED_FILES_CARD_BANNER?.[item?.model_type]?.clickUrl(item?.data?.id);
          cardProps = {
            ...cardProps,
            cardButtonList: props?.cardButtonList.filter(
              (button) =>
                SHARED_FILES_CARD_BANNER?.[item?.model_type]?.button.includes(
                  button?.id || button?.btnId
                ) === true
            ),
          };
        } else if (cardType === "collection") {
            clickUrl = GET_MODULE_KEY_BY_ID(item?.model_type).clickUrl(item?.data?.id);
        }

        return (
          <ThumbnailCard
            key={`thumbnailCard${cardType}${item?.id}${i}`}
            id={item?.id}
            selectedList={props?.selectedList} // not needed
            stackedCardImages={props?.stackedCardImages}
            thumbnailData={item}
            cardType={cardType}
            last={lastEle} // not needed
            arr={data} // not needed
            newTab={props.newTab || false} // not needed
            onClick={
              props?.setActiveIndex && props?.setActiveCameraId
                ? () => {
                    props.setActiveIndex(i); // not needed
                    props.setActiveCameraId(item?.id); // not needed
                  }
                : props?.onClick
            }
            {...cardProps}
            clickUrl={clickUrl}
          />
        );
      })}
      {/* </Row> */}
    </Container>
  );
}

