import React, { useEffect, useRef, useState } from 'react';
import { ModalButtonArea } from './';
import { icons } from '../assets';
import {
  // apiGetMembersList,
  // apiGetOrganization,
  apiGetPermissionForShare,
  apiShareModel,
  apiShareMultipleModelType,
  getSharedFileToMemberList,
  removeUserFromSharedFile,
} from '../helpers/api';
// import closeIcon from '../assets/images/close-btn.png';
import { Button, Form, Modal, ModalTitle } from 'react-bootstrap';
import { CURRENT_USER } from '../constants/projectConstants';
import { devConsoleLog, getLocalStorage } from '../helpers/jsHelper';
const { ShareIcon, SearchIcon, CloseIcon, WarningIcon, RoundcloseIcon, ShareIconNew } = icons;
import '../assets/scss/modules/_sharepopup.scss';
import CircularProgress from './CircularProgress';
import { MODEL_TYPES } from '../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { searchSelector } from '../redux/slicers/search/searchSlicer';
import { GetOrgMembersList, GetSearchMembersList } from '../api/share/share';
import { ModalArea } from './ModalButtonArea';
import { ShareLinkInput } from './ShareLinkInput/ShareLinkInput';
import { PRODUCT_WEB_EXPERIENCE_PUBLIC } from '../constants/routePathConstants';
import Switch from '../components/admin/switch';
import Loader from './Loader';
import { errorToast } from '../helpers/toastHelper';
const MODEL_TYPES_FOR_SHARE_COLLECTION_ITEM = { 
    "collection": {
        key: "id",
        type: {
            "1": "product",
            "2": "product",
            "3": "textures",
            "4": "texture",
            "5": "room",
            "6": "room",
            "7": "projectrender",
            "8": "projectrender",
            "9": "project",
            "10": "material" // not able to share
        }
    },
    "sharedFiles": {
        key: "data.id",
        type: {
            1: "product",
            2: "texture",
            3: "room",
            4: "project",
            5: "collection",
            6: "projectrender"
        } 
    }
}

export default function SharePopup(props) {
  const modelType =
    props?.modelType || props?.sharedFile?.model_type;
  let title = 'Share files with';
  const [showModal, setShowModal] = useState(false);

  return (
    <ModalButtonArea
      button={props.button}
      bigButton={props.bigButton}
      bigBtnClass={props?.bigBtnClass}
      hideButtonText={props.hideButtonText}
      miniButton={props.miniButton}
      footerButton={props.footerButton}
      variant={props.disabled ? 'outline-secondary' : 'secondary'}
      buttonIcon={props.icon || ShareIcon}
      buttonTitle="Share"
      disabled={props.disabled || false}
      title={props.title || title}
      // subtitle={subtitle}
      size="lg"
      body={
        <Body
          selectedModels={
            props.selectedList === undefined || typeof props.selectedList === 'boolean'
              ? [{id: props?.shareId || props.id}]
              : props.selectedList
          }
          modelType={modelType}
          setShowModal={setShowModal}
          onSuccess={props.onSuccess}
        />
      }
      iconSrc={props.iconSrc}
      showModal={showModal}
      setShowModal={setShowModal}
      buttonLabel={props.buttonLabel}
    />
  );
}

export const SharePopupONLY = ({
  show,
  toggleModal,
  className,
  title,
  subtitle,
  modelType,
  onSuccess,
  centered,
  promptButton,
  includeCancelButton,
  animation,
  selectedModels, // remove this 
  shareData,
  onPublicToggle,
  publicToggleLoading,
}) => {
  return (
    <ModalArea
      showModal={show}
      setShowModal={toggleModal}
      className={className}
      title={title}
      subtitle={subtitle}
      animation={animation}
      includeCancelButton={includeCancelButton}
      promptButton={promptButton}
      size="lg"
      centered={centered}
      body={
        <Body
          selectedModels={selectedModels} // remove this
          shareData={shareData}
          modelType={modelType}
          setShowModal={toggleModal}
          onSuccess={onSuccess}
          onPublicToggle={onPublicToggle}
          publicToggleLoading={publicToggleLoading}
        />
      }
    />
  );
};

export const Body = ({
  selectedModels, // remove this
  shareData,
  modelType,
  setShowModal,
  onSuccess,
  onPublicToggle,
  publicToggleLoading,
}) => {
  const dispatch = useDispatch();
  const userInfo = getLocalStorage(CURRENT_USER, true);
  const searchRef = useRef(null)
  const { searchUserResult, searchOrgResult } = useSelector(searchSelector);
  const isExperienceModel = modelType === MODEL_TYPES.WEBEXPERIENCE;

  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('users');
  const [searchText, setSearchText] = useState('');
  const [messageText, setMessageText] = useState('');
  const [selectedUser, setSelectedUser] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [sharedModelDetails, setSharedModelDetails] = useState();
  const [warningPopup, setWarningPopup] = useState({
    title: '',
    subtitle: '',
    visible: false,
    btnClick: () => {},
    btnTitle: 'Share',
  });

  const handleSelect = (value) => {
    setSearchText('');
    setIsSearchFocused(false)
    if (selectedUser.filter((item) => item?.id === value?.id).length) {
      if (value.warning) {
        setErrors(errors.filter((item) => item?.id !== value?.id));
      }
      setSelectedUser(selectedUser.filter((item) => item?.id !== value?.id));
    } else {
      if (value.warning) {
        setErrors([...errors, value]);
      }
      setSelectedUser([...selectedUser, value]);
    }
  };

  const onEnterPress = ({ key }) => {
    if (key === 'Enter' && searchText) {
      if (selectedTab === 'users') {
        const selectUser = searchUserResult?.results.filter((user) => !selectedUser.some((item) => item?.id === user?.id))?.[0]
        handleSelect(selectUser ? {
          id: selectUser?.id,
          member_profile: selectUser?.member_profile,
          member_name: selectUser?.member_name,
          warning: false,
          is_my_org: selectUser?.is_my_org,
          is_org: false,
        } : {
          id: Math.random(),
          member_name: searchText,
          member_profile: 'put here warning icon',
          warning: true,
          is_org: false
        })
      } else {
        const selectOrg = searchOrgResult?.results.filter((user) => !selectedUser.some((item) => item?.id === user?.id))?.[0]
        handleSelect(selectOrg ? {
          id: selectOrg?.id,
          member_profile: selectOrg?.organization_owner_profile,
          member_name: selectOrg?.name,
          warning: false,
          is_my_org: selectOrg?.is_my_org,
          is_org: true,
        } : {
          id: Math.random(),
          member_name: searchText,
          member_profile: 'put here warning icon',
          warning: true,
          is_org: true
        })
      }
      setSearchText('');
    }
  };

  const requestPermission = async () => {

    const formData = new FormData();
    formData.append('model_type', modelType);
    formData.append('model_ids', selectedModels?.map((item) => item?.id).join(','));
    if (selectedUser?.filter((item) => item?.is_org === false)?.map((item) => item?.id)?.length) {
      formData.append(
        'shared_to_list',
        selectedUser
          ?.filter((item) => item?.is_org === false)
          ?.map((item) => item?.id)
          .join(',')
      );
    }

    if (selectedUser?.filter((item) => item?.is_org === true)?.map((item) => item?.id)?.length) {
      formData.append(
        'shared_to_org_list',
        selectedUser
          ?.filter((item) => item?.is_org === true)
          ?.map((item) => item?.id)
          .join(',')
      );
    }
    formData.append('message', messageText);
    await apiGetPermissionForShare(formData);
    setWarningPopup({
      title: '',
      subtitle: '',
      visible: false,
      btnClick: () => {},
      btnTitle: 'Share',
    });
  };

  const handleShare = async () => {
    const model_ids = selectedModels.map((item) => item?.id).join(",")

    if (errors.length || !selectedUser?.length || !model_ids || !modelType) {
      if (errors.length) {
        errorToast("Please select valid user or organization");
      } else if(!selectedUser?.length) {
        errorToast("Please select atleast one user or organization");
      } else if(!model_ids) {
        errorToast("Please select atleast one file")
      } else if (!modelType) {
        errorToast("Please provide model type")
      }
      return;
    };

    const onShareClick = async () => {
      setWarningPopup({
        title: '',
        subtitle: '',
        visible: false,
        btnClick: () => {},
        btnTitle: 'Share',
      });
      const formData = new FormData();
      //model type 0 for mix files
      if (modelType === "collection" || modelType === "sharedFiles") {
        const shareModels = {};
        selectedModels.forEach((item) => {
            shareModels[MODEL_TYPES_FOR_SHARE_COLLECTION_ITEM?.[modelType]?.type?.[item?.model_type]] = 
                shareModels[MODEL_TYPES_FOR_SHARE_COLLECTION_ITEM?.[modelType]?.type?.[item?.model_type]] 
                    ? `${shareModels[MODEL_TYPES_FOR_SHARE_COLLECTION_ITEM?.[modelType]?.type?.[item?.model_type]]},${item?.[MODEL_TYPES_FOR_SHARE_COLLECTION_ITEM?.[modelType]?.key]}`
                    : `${item?.id}`
        })
        Object.keys(shareModels).forEach((key) => {
            formData.append(key,shareModels?.[key]);
        })
      } else {
          formData.append('model_type', modelType);
          formData.append('model_id', model_ids);
      }
      if (selectedUser?.filter((item) => item?.is_org === false)?.map((item) => item?.id).length) {
        formData.append(
          'shared_to',
          selectedUser
            ?.filter((item) => item?.is_org === false)
            ?.map((item) => item?.id)
            .join(',')
        );
      }
      if (selectedUser?.filter((item) => item?.is_org === true)?.map((item) => item?.id).length) {
        formData.append(
          'shared_to_org',
          selectedUser
            ?.filter((item) => item?.is_org === true)
            ?.map((item) => item?.id)
            .join(',')
        );
      }
      formData.append('message', messageText);

      if (modelType === "collection" || modelType === "sharedFiles") {
        await apiShareMultipleModelType(formData, (response) => {
            if (response?.status === true) {
            setShowModal(false);
            } else {
            setWarningPopup({
                title: response?.message,
                subtitle: '',
                visible: true,
                btnClick: requestPermission,
                btnTitle: 'Request',
            });
            }
        });
      } else {
        await apiShareModel(formData, (response) => {
            if (response?.status === true) {
            setShowModal(false);
            } else {
            setWarningPopup({
                title: response?.message,
                subtitle: '',
                visible: true,
                btnClick: requestPermission,
                btnTitle: 'Request',
            });
            }
        });
      }
      
    };

    if (selectedUser.findIndex((item) => item?.is_my_org === false) !== -1) {
      if (selectedModels?.findIndex((item) => item?.owner_organization !== null) !== -1) {
        setWarningPopup({
          title: 'This file contains exclusive data like  3d models or rooms',
          subtitle: 'Are you sure you want to share the files?',
          visible: true,
          btnClick: () => onShareClick(),
          btnTitle: 'Share',
        });
      } else {
        setWarningPopup({
          title: 'You are about to share files with people outside of your organization',
          subtitle: 'Are you sure you want to share the files?',
          visible: true,
          btnClick: () => onShareClick(),
          btnTitle: 'Share',
        });
      }
    } else {
      onShareClick();
    }
  };


  const shareButton = (
    <button className="btn" onClick={handleShare}>
      Share
    </button>
  );

  const handleCloseSearchPanel = () => {
    setIsSearchFocused(false)
  }

  const handleUnshareModel = async (id) => {
    const formData = new FormData();
    formData.append("model_type", modelType);
    formData.append("model_id", selectedModels?.[0]?.id)
    formData.append("member_id", id);

    await removeUserFromSharedFile(formData);
    setSharedModelDetails({
      ...sharedModelDetails,
      results: sharedModelDetails?.results?.filter((item) => item?.id !== id)
    })
  }

  useEffect(async () => {
    setLoading(true);

    if (selectedTab === 'users') {
      dispatch(GetSearchMembersList(searchText));
      setLoading(false);
    } else {
      if (searchText) {
        dispatch(GetOrgMembersList(searchText));
      }
      setLoading(false);
    }
  }, [selectedTab]);

  useEffect(() => {
    setLoading(true);
    searchText && setIsSearchFocused(true);
    if (selectedTab === 'users') {
      dispatch(GetSearchMembersList(searchText));
      setLoading(false);
    } else {
      searchText && dispatch(GetOrgMembersList(searchText));
      setLoading(false);
    }
  }, [searchText])

//   useEffect(() => {
//     if (!isSearchFocused) return;

//     document.getElementById("pop-up-body")?.addEventListener("click", handleCloseSearchPanel);
//     console.log("document.getElementById", document.getElementsByClassName("modal-content")[0])

//     return () => {
//       document.getElementById("pop-up-body")?.[0]?.removeEventListener("click", handleCloseSearchPanel);
//     }
//   }, [isSearchFocused])

  useEffect(async () => {
    if (selectedModels.length === 1) {
        try {
            setLoading(true);
            const result = await getSharedFileToMemberList(modelType, selectedModels?.[0]?.id )
            setSharedModelDetails(result)
        } catch(error) {
            console.error(error)
        } finally {
            setLoading(false);
        }
    }
  }, [modelType, selectedModels])

  return (
    <div className="shared-model-inner-part" onClick={() => setIsSearchFocused(false)}>
      <div className="d-flex justify-content-center share-tab-btn">
        <button
          className={`btn ${selectedTab === 'users' ? 'btn-active' : 'btn-inactive'}`}
          onClick={() => setSelectedTab('users')}>
          Members
        </button>
        <button
          className={`btn ${selectedTab === 'organizations' ? 'btn-active' : 'btn-inactive'}`}
          onClick={() => setSelectedTab('organizations')}>
          Organizations
        </button>
      </div>
      <div className="d-flex flex-column position-relative">
        <div 
          className={`main-search-part position-relative ${searchText || selectedUser?.length ? "active-searchbar" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            setIsSearchFocused(true);
            searchRef?.current?.focus();
          }}>
            <div className="d-flex flex-wrap">
              {!selectedUser?.length && !isSearchFocused && !searchText ? (
                <div className="search-icon">
                  <SearchIcon />
                </div>
              ) : (
                ''
              )}
              {selectedUser?.map((item) => (
                <span className={`search-selected-item ${item?.warning ? 'text-danger' : ''}`}>
                  {item?.warning ? (
                    <WarningIcon />
                  ) : (
                    <img
                      className="search-user-icon"
                      src={
                        item?.member_profile ||
                        'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
                      }
                    />
                  )}
                  <span className="search-selected-text">{item?.member_name}</span>
                  <span onClick={() => handleSelect(item)} className="round-close-btn" style={{cursor: "pointer"}}>
                    <RoundcloseIcon />
                  </span>
                </span>
              ))}
              <input
                ref={searchRef}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={selectedUser?.length || isSearchFocused ? "" : 'Email, comma separated'}
                onKeyDown={onEnterPress}
              /> 
          </div>
          {searchText ? 
            <span 
              className="round-close-btn" 
              style={{cursor: "pointer"}} 
              onClick={() => setSearchText("")} >
              <RoundcloseIcon />
            </span> 
            : ""}         
        </div>
        <div className="filteredDataList">
        {selectedTab === 'users' ? (
            <>
              {isSearchFocused && searchUserResult?.results.filter((user) => !selectedUser.some((item) => item?.id === user?.id))?.length ? (
                <div className="users-list-box popup-userlist-box">
                  {loading === true ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 280,
                      }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    searchUserResult?.results.filter((user) => !selectedUser.some((item) => item?.id === user?.id)).map((user) => (
                      <div
                        key={user?.id}
                        className="d-flex align-items-center justify-content-between users-inner-list"
                        onClick={(e) =>{
                          e.stopPropagation();
                          handleSelect({
                            id: user?.id,
                            member_profile: user?.member_profile,
                            member_name: user?.member_name,
                            warning: false,
                            is_my_org: user?.is_my_org,
                            is_org: false,
                          })}
                        }>
                        <div className="d-flex align-items-center">
                          <img
                            className="users-inner-image"
                            src={
                              user?.member_profile ||
                              'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
                            }
                          />
                          <div className="users-inner-text">
                            <div className="user-inner-name">{user?.member_name}</div>
                            <div className="user-inner-email">{user?.member_email}</div>
                          </div>
                        </div>
                        {selectedUser.filter((item) => item?.id === user?.id).length ? (
                          <span
                            onClick={() =>
                              handleSelect({
                                id: user?.id,
                                member_profile: user?.member_profile,
                                member_name: user?.member_name,
                                warning: false,
                                is_my_org: user?.is_my_org,
                                is_org: false,
                              })
                            }
                            className="round-close-btn">
                            <RoundcloseIcon />
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    ))
                  )}
                  {/* {!loading && searchUserResult?.results?.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150,
                      }}>
                      No User Found
                    </div>
                  ) : (
                    ''
                  )} */}
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            <>
              {searchOrgResult?.results.filter((user) => !selectedUser.some((item) => item?.id === user?.id)).length && searchText ? (
                <div className="users-list-box popup-userlist-box position-relative">
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 280,
                      }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    searchOrgResult?.results.filter((user) => !selectedUser.some((item) => item?.id === user?.id)).map((org) => (
                      <div
                        key={org?.id}
                        className="d-flex align-items-center justify-content-between users-inner-list"
                        style={{ cursor: 'pointer' }}
                        onClick={(e) =>{
                          e.stopPropagation()
                          handleSelect({
                            id: org?.id,
                            member_profile: org?.organization_owner_profile,
                            member_name: org?.name,
                            warning: false,
                            is_my_org: userInfo?.organization_detail?.id === org?.id,
                            is_org: true,
                          })}
                        }>
                        <div className="d-flex align-items-center">
                          <img
                            className="users-inner-image"
                            src={
                              org?.organization_owner_profile ||
                              'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
                            }
                          />
                          <div className="users-inner-text">
                            <div className="user-inner-name">{org?.organization_owner_email}</div>
                            <div className="user-inner-email">organization: {org?.name}</div>
                          </div>
                        </div>
                        {selectedUser.filter((item) => item?.id === org?.id).length ? (
                          <span
                            onClick={() =>
                              handleSelect({
                                id: org?.id,
                                member_profile: org?.thumbnail,
                                member_name: org?.name,
                                warning: false,
                                is_my_org: userInfo?.organization_detail?.id === org?.id,
                                is_org: true,
                              })
                            }
                            className="text-danger">
                            {/* className="round-close-btn"> */}
                            <RoundcloseIcon />
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    ))
                  )}
                  {/* {!loading && searchOrgResult?.results?.length === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 150,
                      }}>
                      No Organization Found
                    </div>
                  ) : (
                    ''
                  )} */}
                </div>
              ) : null}
            </>
          )}
        </div>
        <div>
          {!isSearchFocused && !selectedUser.length && (sharedModelDetails?.owner?.member_email || sharedModelDetails?.results?.length ) ? (
            <div className="users-list-box">
              {loading && !searchText ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 200,
                  }}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                    {sharedModelDetails?.owner?.member_email ? <div
                        className="d-flex align-items-center justify-content-between users-inner-list align-items-center">
                        <div className="d-flex align-items-center">
                        <img
                            className="users-inner-image"
                            src={
                            sharedModelDetails?.owner?.member_profile ||
                            'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
                            }
                        />
                        <div className="users-inner-text">
                            <div className="user-inner-name">{sharedModelDetails?.owner?.member_name}</div>
                            <div className="user-inner-email">{sharedModelDetails?.owner?.member_email}</div>
                        </div>
                        </div>
                        <span>
                        Owner
                        </span>
                  </div> : "" }
                  {sharedModelDetails?.results.map((user, index) => (
                    <div
                      className="d-flex align-items-center justify-content-between users-inner-list align-items-center">
                      <div className="d-flex align-items-center">
                        <img
                          className="users-inner-image"
                          src={
                            user?.member_profile ||
                            'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
                          }
                        />
                        <div className="users-inner-text">
                          <div className="user-inner-name">{user?.member_name}</div>
                          <div className="user-inner-email">{user?.member_email}</div>
                        </div>
                      </div>
                      <span className="remove-btn" onClick={() => handleUnshareModel(user?.id)}>
                        Remove Member
                      </span>
                    </div>
                  ))}
                </>
              )}
              
            </div>
          ) : null}
          {!isExperienceModel && !isSearchFocused &&  selectedUser.length ? (
            <textarea
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              className={`user-list-text-area w-100`}
              placeholder="Add Message"
            />
          ) : ""}
          
        </div>
       
        
        <div className="fotter-part-model">
          {errors?.length ? (
            <div className="warning-text-part">
              <WarningIcon width={27} height={27} />
              <span className="text-danger">
                {
                    console.log("errors?.[errors?.length - 1]?.is_org", errors?.[errors?.length - 1])
                }
                {errors?.[errors?.length - 1]?.is_org ? 'No Organization Found' : 'No Member Found'}
              </span>
            </div>
          ) : (
            ''
          )}

          {!isExperienceModel && !isSearchFocused &&  selectedUser.length ? shareButton  : ""}

          {isExperienceModel && (
            <div className="w-100">
              <hr className="mt-4" style={{ backgroundColor: 'var(--neutral40-color)' }} />
              <div className="d-flex gap-3 justify-content-between w-100 align-items-end">
                <ShareLinkSection
                  item={selectedModels?.[0]}
                  onPublicToggle={onPublicToggle}
                  publicToggleLoading={publicToggleLoading}
                />
                {shareButton}
              </div>
            </div>
          )}
        </div>

        <WarningModal
          title={warningPopup?.title}
          subtitle={warningPopup?.subtitle}
          showModal={warningPopup?.visible}
          btnTitle={warningPopup?.btnTitle}
          animation={true}
          btnClick={warningPopup.btnClick}
          handleClose={() =>
            setWarningPopup({
              title: '',
              subtitle: '',
              visible: false,
              btnClick: () => {},
              btnTitle: 'Share',
            })
          }
        />
      </div>
    </div>
  );
};

const WarningModal = ({
  showModal,
  animation = true,
  handleClose,
  title,
  subtitle,
  btnTitle = 'Share',
  btnClick,
}) => {
  return (
    <Modal
      show={showModal}
      animation={animation || false}
      onHide={handleClose}
      centered
      dialogClassName={'new-item-modal'}>
      <Modal.Header className="commonPopupHeader justify-content-end pb-0">
        <Button onClick={handleClose} className="modalCloseButton">
          <CloseIcon />
        </Button>
      </Modal.Header>
      {title && (
        <ModalTitle className="text-center">
          <h2 className="appH2">{title}</h2>
        </ModalTitle>
      )}
      {subtitle && <h3 className="text-center appH3">{subtitle}</h3>}
      <Modal.Body>
        <div className="d-flex justify-content-around">
          <button className="btn btn-light btn" onClick={handleClose}>
            Cancel
          </button>
          <button onClick={btnClick} className="btn btn-warning">
            {btnTitle}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const ShareLinkSection = ({ item, onPublicToggle, publicToggleLoading }) => {
  if (!item) return null;

  const link = window.location.origin + PRODUCT_WEB_EXPERIENCE_PUBLIC.replace(':id', item.id);

  return (
    <div className="w-100 align-self-center">
      <Form.Group className="d-flex align-items-center">
        <Form.Label className="me-3 mb-0">Public Sharing</Form.Label>
        {publicToggleLoading ? (
          <Loader
            loading
            position="relative"
            size={25}
            thickness={2}
            style={{ width: 50, height: 25 }}
          />
        ) : (
          <Switch checked={item.is_public} onToggle={(e) => onPublicToggle(e.target.checked)} />
        )}
      </Form.Group>

      {item.is_public && <ShareLinkInput link={link} className="mt-4" />}
      
    </div>
  );
};
