import {
  AUTH_TOKEN,
} from '../constants/projectConstants';
import {
  getLocalStorage,
} from '../helpers/jsHelper';
import {
  ADMIN_DASHBOARD,
} from '../constants/routePathConstants';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center align-items-center flex-column h-100">
      <h1 className="m-3" >Page Not Found</h1>
      <Button variant="warning" onClick={() => navigate(ADMIN_DASHBOARD)}>Go back</Button>
    </div>
  );
}
