import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Button from '../../components/Button/Button';
import { InputUI } from '../../components/hookForm/InputController';
import InfoDialogUI from '../../components/InfoDialog/InfoDialogUI';
import { TRASH_ICON } from '../../constants/assetConstants';
import { MAX_RENDER_IMAGE_FILE_SIZE } from '../../constants/validationConstants';
import { useUserContext } from '../../contexts/UserContext';
import { apiGetPropsV2, apiUploadRenderImages } from '../../helpers/api';
import { errorToast } from '../../helpers/toastHelper';
import { useNameInputValue } from '../../hooks/useNameInputValue';
import { useRoomTypeOptions } from '../../hooks/useRoomTypes';
import { OptionImage, SelectFormGroupV2 } from '../SelectFormGroup';
import styles from './UploadImagesDialog.module.scss';

export const UploadImagesDialog = ({
  show,
  onSucces,
  onClose,
  defaultRoomType,
  forcedProductId,
}) => {
  return (
    <InfoDialogUI
      show={show}
      title="Upload Images"
      bodyClassName={styles.dialogBody}
      description="Add files [ jpeg/png - max. 50 mb] "
      onClose={onClose}>
      {show && (
        <Content
          onSucces={onSucces}
          defaultRoomType={defaultRoomType}
          forcedProductId={forcedProductId}
        />
      )}
    </InfoDialogUI>
  );
};

export const Content = ({ onSucces, defaultRoomType, forcedProductId }) => {
  const { currentUser } = useUserContext();
  const userCurrentOrg = currentUser?.organization;
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productError, setProductError] = useState('');
  const [product, setProduct] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [renderName, onRenderNameChange] = useNameInputValue();
  const [renderNameError, setRenderNameError] = useState('');
  const { roomTypeOptions } = useRoomTypeOptions();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/png,image/jpeg',
    onDropAccepted: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    maxSize: MAX_RENDER_IMAGE_FILE_SIZE,
    onDropRejected: () => {
      errorToast('Accepted formats are jpeg/png. Max file size 50 mb');
    },
  });

  useEffect(() => {
    if (!roomTypeOptions.length && !imageType) return;

    if (defaultRoomType) {
      const option = roomTypeOptions.find((opt) => opt.value === defaultRoomType);
      setImageType(option);
      return;
    }

    setImageType(roomTypeOptions[0]);
  }, [roomTypeOptions]);

  function handleFileDelete(file) {
    const filtered = files.filter((f) => f !== file);
    setFiles(filtered);

    if (filtered.length === 0) {
      setRenderNameError('')
      setProductError('')
    }
  }

  async function handleUploadClick() {
    if (loading) return;

    if (!product && !forcedProductId) {
      setProductError('Please select product');
      return;
    }

    if (!renderName.trim()) {
      setRenderNameError('Please add render name');
      return;
    }

    setLoading(true);
    const fd = new FormData();

    if (forcedProductId) {
      fd.append('products', forcedProductId);
    } else {
      fd.append('products', product.id);
    }
    fd.append('type', imageType.value);
    fd.append('name', renderName);
    files.forEach((f) => fd.append('file', f));
    const res = await apiUploadRenderImages(fd);

    setLoading(false);

    if (res?.data?.id) {
      onSucces?.(res.data);
    }
  }

  const fetchProducts = async (search, loadedOptions, { page }) => {
    const response = await apiGetPropsV2({ search, brands: [userCurrentOrg], page });

    return {
      options: response.results.map((item) => ({ ...item, label: item.name, value: item.id })),
      hasMore: Boolean(response.next_link),
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
      <div
        {...getRootProps({
          className: 'dropzone render-dropzone p-5 text-center',
        })}>
        <input {...getInputProps()} />
        <p className="text-primary">Select jpeg/png file</p>
        <p className="text-muted">
          <small>or drop jpeg/png here</small>
        </p>
      </div>
      {files.length > 0 && (
        <>
          <div className="mt-4">
            <ul className="render-upload-list">
              {files.map((file) => (
                <li key={file.path} className="d-flex justify-content-between align-items-center">
                  {file.name}
                  <div role="button" onClick={() => handleFileDelete(file)}>
                    <img src={TRASH_ICON} />
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <InputUI
            hasError={Boolean(renderNameError)}
            error={{ message: renderNameError }}
            value={renderName}
            type="text"
            placeholder="Enter render name"
            onChange={(e) => {
              setRenderNameError('')
              onRenderNameChange(e)
            }}
          />

          {!forcedProductId && (
            <div className="render-upload-group pt-2">
              <SelectFormGroupV2
                label="Select product name"
                error={productError}
                value={product}
                loadOptions={fetchProducts}
                theme="white"
                additional={{
                  page: 1,
                }}
                onChange={setProduct}
                placeholder="Enter product name..."
                menuPortalTarget={null}
                onBlur={() => setProductError('')}
                onFocus={() => setProductError('')}
                components={{ Option: OptionImage }}
              />
            </div>
          )}
          <div className="pt-3 pb-3 mb-3">
            <SelectFormGroupV2
              label="Type of image"
              options={roomTypeOptions}
              value={imageType}
              theme="white"
              onChange={setImageType}
              placeholder="Type of image"
              menuPortalTarget={null}
            />
          </div>
          <Button
            type="button"
            color="warning"
            fullWidth
            onClick={handleUploadClick}
            loading={loading}
            size="xlg">
            Import
          </Button>
        </>
      )}
    </>
  );
};
