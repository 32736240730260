import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SEE_ALL } from '../constants/projectConstants';
import HorizontalHomeCarousel from './HorizontalHomeCarousel';
import NoDataFound from './NoDataFound';
import { Loader } from '../common';

const GridSectionNew = ({
  title,
  seeAllLink,
  carouselData,
  modelType,
  onClick,
  showFavoriteIcon = false,
  emptyDisplay,
  onSuccess,
  className,
  hasMore,
  fetchMoreData,
  fetchMoreLoading,
  onEdit,
  loading,
  capitalize,
  customActions,
}) => {
  const prevDataRef = useRef(carouselData);

  if (carouselData.length === 0 && loading) {
    prevDataRef.current = prevDataRef.current;
  } else {
    prevDataRef.current = carouselData;
  }

  const data = carouselData.length === 0 ? prevDataRef.current : carouselData;

  const hasData = carouselData.length !== 0;

  const [randomw] = useState(Math.random());

  const showNewCaoursel = !loading && hasData;
  const showOldCarousel = loading && data;
  const showCarousel = showNewCaoursel || showOldCarousel;

  return (
    <div className={`px-0 grid-section ${className || ''}`} fluid="true">
      {title && (
        <div className="carousel-card-header">
          <h5 className="mb-0 head-h5">{title}</h5>
          {seeAllLink && carouselData?.length !== 0 && (
            <Link className="see_all_link" to={seeAllLink}>
              {SEE_ALL}
            </Link>
          )}
        </div>
      )}
      <div className="w-100" style={{ position: 'relative', minHeight: 272 }}>
        {loading && <Loader loading={loading} position="absolute" backdrop={true} />}
        {!loading && !hasData && (
          <div className="empty-area">{emptyDisplay ?? <NoDataFound title={title} />}</div>
        )}

        {showCarousel && (
          <HorizontalHomeCarousel
            data={data}
            onClick={onClick}
            onEdit={onEdit}
            showFavoriteIcon={showFavoriteIcon}
            onSuccess={onSuccess}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            loading={fetchMoreLoading}
            modelType={modelType}
            capitalize={capitalize}
            customActions={customActions}
          />
        )}
      </div>
    </div>
  );
};

export default GridSectionNew;
