import * as yup from 'yup';
import { InputController } from '../../components/hookForm/InputController';
import {
  MAX_PRODUCT_DESCRIPTION_LENGTH,
  MAX_PRODUCT_DIMENSION,
  MAX_PRODUCT_NAME_LENGTH,
  MAX_PRODUCT_PRICE,
  MIN_PRODUCT_DESCRIPTION_LENGTH,
  MIN_PRODUCT_DIMENSION,
  MIN_PRODUCT_NAME_LENGTH,
  MIN_PRODUCT_PRICE,
} from '../../constants/validationConstants';

export const nameSchema = yup.object({
  name: yup.string().min(MIN_PRODUCT_NAME_LENGTH).max(MAX_PRODUCT_NAME_LENGTH).required(),
});

export const getNameFields = (data) => [
  {
    name: 'name',
    placeholder: 'Product name',
    value: data.name,
  },
];

export const skuSchema = yup.object({
  sku_id: yup.string().min(MIN_PRODUCT_NAME_LENGTH).max(MAX_PRODUCT_NAME_LENGTH).required(),
});

export const getSkuFields = (data) => [
  {
    name: 'sku_id',
    placeholder: 'Product Sku ID',
    value: data.sku_id,
  },
];

export const priceSchema = yup.object({
  price: yup
    .number()
    .typeError('Invalid price')
    .min(MIN_PRODUCT_PRICE, `Min price - ${MIN_PRODUCT_PRICE}`)
    .max(MAX_PRODUCT_PRICE, `Max price - ${MAX_PRODUCT_PRICE}`)
    .required('Invalid price'),
});

export const getPriceFields = (data) => [
  {
    name: 'price',
    placeholder: 'Price',
    value: data.price,
  },
];

export const descriptionSchema = yup.object({
  description: yup.string().min(MIN_PRODUCT_DESCRIPTION_LENGTH).max(MAX_PRODUCT_DESCRIPTION_LENGTH),
});

export const getDescriptionFields = (data) => [
  {
    name: 'description',
    placeholder: 'Description',
    value: data.description,
    Component: ({ control }) => {
      return (
        <InputController
          placeholder="Description"
          name="description"
          className="mb-3"
          multiline
          control={control}
          noResize
          height={172}
        />
      );
    },
  },
];

export const dimensionsSchema = yup.object({
  dimensions: yup.string().min(MIN_PRODUCT_NAME_LENGTH).max(MAX_PRODUCT_NAME_LENGTH).required(),
});

export const getDimensionsFields = (data) => [
  {
    name: 'dimensions',
    placeholder: 'Dimensions',
    value: data.dimensions || '',
  },
];

export const getCategoryFields = (data, filter, categoryValue) => {
  const name = `filter_${filter.id}`;

  const schema = yup.object({
    [name]: yup
      .array()
      .typeError(`${filter.label_name} is required`)
      .test('missing filter', `${filter.label_name} is required`, (arr) => arr && arr.length !== 0),
  });

  return {
    schema,
    fieldsConfig: [
      {
        name: name,
        placeholder: filter.label_name,
        type: 'select',
        value: categoryValue,

        selectProps: {
          options: filter.options,
          isSearchable: true,
          isMulti: true,
          creatable: true,
          components: {
            DropdownIndicator: null,
          },
        },
      },
    ],
  };
};
