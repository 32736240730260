import { getDefaultTextureImageUrl } from '../../components/admin/curator/stylesSection/PropertiesTab/useGetDefaultObjectMaps';
import {
  CHANGED_MAP_IDS__TEXTURE_MAP,
  CHANGED_MAP_PATHS__TEXTURE_MAP,
  TEXTURE_MAP__TEXTURE_NAME_KEY,
} from '../../config/constant/unityConstants';
import { getMaterial, getTexture } from '../idb';

export const addDefaultTexturesToObject = async (objectData) => {
  const updatedObject = {
    ...objectData,
    changedMapsPath: { ...objectData.changedMapsPath },
  };

  const promiseList = Object.entries(CHANGED_MAP_IDS__TEXTURE_MAP).map(async ([mapType, idKey]) => {
    const textureId = parseInt(updatedObject.changedMapsPath[idKey]);
    const pathKey = CHANGED_MAP_PATHS__TEXTURE_MAP[mapType];

    if (textureId) {
      const texture = await getTexture(textureId);
      updatedObject.changedMapsPath[pathKey] = texture.file;
      return;
    }

    // check if object has default texture
    const textureNameKey = TEXTURE_MAP__TEXTURE_NAME_KEY[mapType];
    const textureName = objectData[textureNameKey];
    const imageUrl = await getDefaultTextureImageUrl({
      textureName,
      materialId: objectData.loadedMaterialId,
    })

    if (imageUrl) {
      updatedObject.changedMapsPath[pathKey] = imageUrl;
    }
  });


  if (updatedObject.loadedMaterialId) {
    const updateMaterialPath = async () => {
      const materialId = parseInt(updatedObject.loadedMaterialId);
      const material = await getMaterial(materialId);
      updatedObject.loadedMaterialPath = material.file;
    };

    promiseList.push(updateMaterialPath());
  }

  await Promise.all(promiseList);

  return updatedObject;
};
