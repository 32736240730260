import React, { useEffect, useMemo } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Table from 'react-bootstrap/Table';
import { DropdownButton } from 'react-bootstrap';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import { ADD_CREDITS_ROUTE } from '../../config/constant/routePathConstant';
import {
  INVOICE_ICON,
  PAYMENT_NOTE_ICON,
  DOWNLOAD_ICON,
} from '../../config/constant/assetConstant';

import {
  apiDownloadTransactionHistory,
  apiGetOrganizationBalance,
  apiGetPreviewAndMainRenders,
} from '../../helpers/api';

import DownloadIcon from '../../components/icons/DownloadIcon';
import PlusIcon from '../../components/icons/PlusIcon';
import Button from '../../components/Button/Button';
import styles from './TransactionHistory.module.scss';
import ArrowDownIcon from '../../components/icons/ArrowDownIcon';
import CloseCircleIcon from '../../components/icons/closeCircleIcon';
import Loader from '../../components/Loader/Loader';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useTransactionsHistory } from '../../hooks/useTransactionsHistory';
import format from 'date-fns/format';
import { Pagination } from '../Pagination/Pagination';
import { Placeholder } from './Placeholder';
import { useUserContext } from '../../contexts/UserContext';
import { CalendarDropdownContent } from './CalendarDropdownContent';
import { useTransactionsHistoryEmailList } from '../../hooks/useTransactionsHistoryEmailList';
import { errorToast } from '../../helpers/toastHelper';
import RenderDetails from '../../screens/RenderDetails';
import { TransactionNoteDialog } from './TransactionNoteDialog';
import download from 'downloadjs';
import { usePermissionsHandler } from '../../hooks/usePermissions';
import { PERMISSION } from '../../constants/permissionConstants';
import { isAdminOrOwner } from '../../helpers/userHelper';

const TRANSACTION_TYPE = {
  ALL: '',
  AMOUNT_DEDUCTED: 'DED',
  AMOUNT_ADDED: 'ADD',
  AMOUNT_BORROWED: 'BORROWED',
  RENDERED_IMAGES: 'RENDERED_IMAGES',
  REQUESTS: 'REQUESTS',
  CREDIT_PURCHASED: 'CREDIT_PURCHASED',
  REFUND: 'REFUND',
};

const POSITIVE_TRANSACTIONS = [
  TRANSACTION_TYPE.AMOUNT_ADDED,
  TRANSACTION_TYPE.CREDIT_PURCHASED,
  TRANSACTION_TYPE.REFUND,
  TRANSACTION_TYPE.AMOUNT_BORROWED,
];
const PER_PAGE_OPTIONS = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 500];

const placeholderList = [...Array(7)];

const transactionTypeOptionList = [
  { label: 'All transactions', value: '' },
  { label: 'Rendered images', value: TRANSACTION_TYPE.RENDERED_IMAGES },
  { label: 'Requests', value: TRANSACTION_TYPE.REQUESTS },
  { label: 'Credit purchased', value: TRANSACTION_TYPE.CREDIT_PURCHASED },
  { label: 'Refund', value: TRANSACTION_TYPE.REFUND },
  { label: 'Deducted', value: TRANSACTION_TYPE.AMOUNT_DEDUCTED },
  { label: 'Added', value: TRANSACTION_TYPE.AMOUNT_ADDED },
  { label: 'Borrowed', value: TRANSACTION_TYPE.AMOUNT_BORROWED },
];

const transactionLabelMap = transactionTypeOptionList
  .filter((o) => Boolean(o.value))
  .reduce((acc, option) => {
    acc[option.value] = option.label;
    return acc;
  }, {});

export const TransactionHistory = () => {
  const navigate = useNavigate();
  const { currentUser } = useUserContext();
  const organisationId = currentUser.organization;
  const [balance, setBalance] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [transactionType, setTransactionType] = useState(TRANSACTION_TYPE.ALL);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [renderData, setRenderData] = useState(null);
  const [historyDownloading, setHistoryDownloading] = useState(false);
  const { handlePermissionEvent } = usePermissionsHandler();

  const requestParams = useMemo(() => {
    const params = {
      ...(transactionType && { trans_type: transactionType }),
      ...(startDate && { created_at__gte: startDate.toISOString() }),
      ...(endDate && { created_at__lte: endDate.toISOString() }),
    };

    return {
      ...params,
      ...(selectedEmail && { created_by_email: selectedEmail }),
    };
  }, [transactionType, selectedEmail, startDate, endDate, organisationId]);

  const {
    list: transactionList,
    prevList,
    loading,
    total,
    perPage,
    page,
    onPageChange,
    onPerPageChange,
    dataLoadedOnce,
    hasData,
  } = useTransactionsHistory({ requestParams });

  useEffect(() => {
    apiGetOrganizationBalance().then((res) => {
      setBalance(res);
    });
  }, [organisationId]);

  const handleDownloadClick = async () => {
    setHistoryDownloading(true);
    const result = await apiDownloadTransactionHistory(requestParams);

    if (!result?.file) {
      errorToast('Unable to generate transaction history, please try again');
      setHistoryDownloading(false);
      return;
    }

    download(result.file)
    setHistoryDownloading(false);
  };

  const toggleTransactionType = (type) => {
    transactionType === type ? setTransactionType(null) : setTransactionType(type);
  };

  const toggleSelectedEmail = (email) => {
    selectedEmail === email ? setSelectedEmail(null) : setSelectedEmail(email);
  };

  const onDateChagne = ({ name, value }) => {
    name === 'startDate' ? setStartDate(value) : setEndDate(value);
  };

  const transactionTypeOption = transactionTypeOptionList.find((t) => t.value === transactionType);

  const dateFilterLabel = useMemo(() => {
    const longFormat = 'dd MMM, yyyy';

    if (startDate && endDate) {
      const start = format(startDate, 'dd MMM');
      const end = format(endDate, longFormat);
      return `${start} - ${end}`;
    } else if (startDate) {
      return `${format(startDate, longFormat)} - ...`;
    } else if (endDate) {
      return `... - ${format(endDate, longFormat)}`;
    }
  }, [startDate, endDate]);

  const filtersActive = Boolean(transactionType || selectedEmail || dateFilterLabel);
  const showPlaceholders = loading || !transactionList.length;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h2>Your historical </h2>
        <div className="d-flex gap-3">
          {isAdminOrOwner(currentUser) && (
            <Button
              color="warning"
              size="xlg"
              iconBefore={<PlusIcon />}
              onClick={handlePermissionEvent(PERMISSION.PURCHASE_CREDIT, () => {
                navigate(ADD_CREDITS_ROUTE)
              })}
            >
              Add Credits
            </Button>
          )} 

          <Button
            color="warning"
            size="xlg"
            iconBefore={<DownloadIcon />}
            onClick={handleDownloadClick}
            loading={historyDownloading}>
            Download
          </Button>
        </div>
      </div>

      <div className={styles.cardList}>
        <div className={styles.card}>
          <span>Balance</span>
          <h1>$ {balance?.amount}</h1>
        </div>
        {balance?.borrowed_credit > 0 && (
          <div className={styles.card}>
            <span>Borrowed Credits</span>
            <h1>$ {balance?.borrowed_credit}</h1>
          </div>
        )}
        {balance?.borrowed_credit > 0 && balance?.effective_balance > 0 && (
          <div className={styles.card}>
            <span>Effective Balance</span>
            <h1>$ {balance?.effective_balance}</h1>
          </div>
        )}
      </div>

      <Table striped bordered className={`basic-table ${styles.table}`}>
        <thead className="basic-table-head">
          <tr>
            <th>
              <CustomDropdown
                title="Transaction Type"
                options={transactionTypeOptionList}
                onChange={toggleTransactionType}
                selected={transactionType}
              />
              {filtersActive && (
                <div className="basic-table-specification">{transactionTypeOption?.label}</div>
              )}
            </th>
            <th>
              <div>Name</div>
              {filtersActive && <div className="basic-table-specification"></div>}
            </th>
            <th>
              <EmailDropdown onChange={toggleSelectedEmail} selected={selectedEmail} />
              {filtersActive && <div className="basic-table-specification">{selectedEmail}</div>}
            </th>

            <th>
              <div>
                <CustomDropdown
                  title="Date & Time"
                  type="calendar"
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onDateChagne}
                />
              </div>
              {filtersActive && <div className="basic-table-specification">{dateFilterLabel}</div>}
            </th>
            <th>
              <div>Amount</div>
              {filtersActive && <div className="basic-table-specification"></div>}
            </th>
            <th>
              <div>Balance</div>
              {filtersActive && <div className="basic-table-specification"></div>}
            </th>
            <th style={{ textAlign: 'right' }}>
              <div>Details</div>
              {filtersActive && <div className="basic-table-specification"></div>}
            </th>
          </tr>
        </thead>

        {/* <div className="basic-table-border"> */}
        <tbody className={styles.tableBody}>
          <Loader
            loading={loading}
            backdrop
            position="absolute"
            backdropClassName={styles.backdrop}
          />

          {showPlaceholders &&
            placeholderList.map((_, idx) => (
              <tr key={idx}>
                <td className={styles.tableColumn}>
                  <Placeholder maxWidth={132} />
                </td>
                <td className={styles.tableColumn}>
                  <Placeholder maxWidth={80} />
                </td>
                <td className={styles.tableColumn}>
                  <Placeholder maxWidth={162} />
                </td>
                <td className={styles.tableColumn}>
                  <div className="d-flex gap-2">
                    <Placeholder maxWidth={121} />
                    <Placeholder maxWidth={30} />
                  </div>
                </td>
                <td className={styles.tableColumn}>
                  <Placeholder maxWidth={41} />
                </td>
                <td className={styles.tableColumn}>
                  <Placeholder maxWidth={54} />
                </td>
                <td className={styles.tableColumn}>
                  <Placeholder
                    className="ms-auto"
                    maxWidth={24}
                    backgroundColor="var(--neutral20-color)"
                  />
                </td>
              </tr>
            ))}
          {showPlaceholders && !loading && (
            <div className={styles.noTransactionsText}>No transactions found</div>
          )}

          {transactionList.map((transaction, idx) => {
            const date = new Date(transaction.created_at);
            const formattedDate = format(date, 'dd MMM, yyyy  - HH:mm');
            const transactionAmountColor =
              transaction.amount === 0
                ? undefined
                : POSITIVE_TRANSACTIONS.includes(transaction.trans_type)
                ? 'black'
                : 'red';

            const transactionSign =
              transaction.amount === 0
                ? ''
                : POSITIVE_TRANSACTIONS.includes(transaction.trans_type)
                ? '+'
                : '-';

            return (
              <tr key={transaction.id}>
                <td className={styles.tableColumn}>
                  {transactionLabelMap[transaction.trans_type]}
                </td>
                <td className={styles.tableColumn}>{transaction.name}</td>
                <td className={styles.tableColumn}>{transaction.created_by_email}</td>
                <td className={styles.tableColumn}>{formattedDate}</td>
                <td className={styles.tableColumn} style={{ color: transactionAmountColor }}>
                  {transactionSign}
                  {transaction.amount} $
                </td>
                <td
                  className={styles.tableColumn}
                  style={{ color: transaction.remaining_amount < 0 ? 'red' : 'black' }}>
                  {transaction.remaining_amount} $
                </td>
                <td className={styles.tableColumn}>
                  <TransactionIcon transaction={transaction} setRenderData={setRenderData} />
                </td>
              </tr>
            );
          })}
        </tbody>
        {/* </div> */}
      </Table>

      <Pagination
        perPage={perPage}
        currentPage={page}
        totalItems={total}
        onPageChange={onPageChange}
        perPageOptions={PER_PAGE_OPTIONS}
        onPerPageChange={onPerPageChange}
      />

      {renderData && (
        <RenderDetails
          id={renderData.id}
          onClose={() => setRenderData(null)}
          onDeleteSuccess={() => setRenderData(null)}
          previewProps={{ className: styles.renderBody }}
          sidebarProps={{ className: styles.renderSidebar }}
        />
      )}
    </div>
  );
};

const TransactionIcon = ({ transaction, setRenderData }) => {
  const [loading, setLoading] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);

  if (transaction.payment_note) {
    return (
      <>
        <span onClick={() => setNoteOpen(true)} className={styles.icon}>
          <img src={PAYMENT_NOTE_ICON} />
        </span>
        {noteOpen && (
          <TransactionNoteDialog
            onClose={() => setNoteOpen(false)}
            note={transaction.payment_note}
          />
        )}
      </>
    );
  }

  if (transaction.receipt_url) {
    return (
      <a href={transaction.receipt_url} target="_blank" rel="noreferrer" className={styles.icon}>
        <img src={INVOICE_ICON} />
      </a>
    );
  }

  if (transaction.render) {
    const handleRenderClick = async () => {
      setLoading(true);
      const result = await apiGetPreviewAndMainRenders(
        `sort=-created_at&main_render=${transaction.render}`
      );

      const render = result?.results?.[0];
      if (!render?.id) {
        errorToast('Unable to get render details, please try again');
        setLoading(false);
        return;
      }

      // navigate(getRenderDetailPath(render.id));
      setRenderData(render);
      setLoading(false);
    };

    return (
      <span onClick={handleRenderClick} className={styles.icon}>
        {loading ? (
          <Loader loading position="static" size={16} thickness={2} />
        ) : (
          <img src={DOWNLOAD_ICON} width={19.2} />
        )}
      </span>
    );
  }

  return null;
};

const CustomDropdown = ({
  title,
  options,
  selected,
  onChange,
  loading,
  hasMore,
  fetchMoreData,
  startDate,
  endDate,
  type,
}) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: fetchMoreData,
    rootMargin: '0px 0px 120px 0px',
  });

  const renderTitle = (title) => {
    return (
      <>
        {title}
        <span className={styles.arrow}>
          <ArrowDownIcon />
        </span>
      </>
    );
  };

  const isCalendar = type === 'calendar';

  return (
    <DropdownButton className={styles.tableDropdown} title={renderTitle(title)}>
      {isCalendar && (
        <CalendarDropdownContent onChange={onChange} startDate={startDate} endDate={endDate} />
      )}

      {!isCalendar &&
        options.map((option) => {
          const active = selected === option.value;

          return (
            <Dropdown.Item
              key={option.value}
              active={active}
              onClick={active ? undefined : () => onChange(option.value)}>
              {option.label}
              {active && (
                <CloseCircleIcon
                  onClick={() => onChange(option.value)}
                  className={styles.closeIcon}
                />
              )}
            </Dropdown.Item>
          );
        })}
      {loading && (
        <Dropdown.Item
          style={{
            pointerEvents: 'none',
            position: 'relative',
            paddingBottom: '2rem',
            transform: 'translateY(-7px)',
          }}>
          <Loader loading size={20} thickness={2} />
        </Dropdown.Item>
      )}
      {hasMore && <div ref={sentryRef}></div>}
    </DropdownButton>
  );
};

const EmailDropdown = ({ onChange, selected }) => {
  const {
    list: memberList,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,
  } = useTransactionsHistoryEmailList();

  const options = useMemo(() => {
    return memberList.map((member) => ({
      label: member.user.email,
      value: member.user.email,
    }));
  }, [memberList]);

  return (
    <CustomDropdown
      title="Email"
      options={options}
      onChange={onChange}
      selected={selected}
      loading={loading}
      hasMore={hasMore}
      fetchMoreData={fetchMoreData}
    />
  );
};
