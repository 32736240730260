import {
  API_ROOT_URL,
  GET_DOWNLOAD_RENDER_IMAGES,
  GET_RENDER_PREVIEW,
} from '../../config/constant/apiConstant';
import api from '../../helpers/axiosHelper';
import { setMyRenderDownloadData } from '../../redux/slicers/render_preview/myRenderDownloadSlice';
import {
  myRenderPreviewActions,
  setMyRenderPreviewData,
} from '../../redux/slicers/render_preview/myRenderPreviewSlice';
import { createDeleteApiAction, createGetListAction, createUpdateApiAction } from '../products';

// main render & preview api//
export const getRenderPreviewApi = (arr, type) => (dispatch) => {
  let sort = arr ? arr : '';
  api()
    .root(API_ROOT_URL)
    .get(`${GET_RENDER_PREVIEW}?${sort}`)
    .success((data) => {
      console.log('getRenderPreviewApi', data);
      dispatch(myRenderPreviewActions(data));
    })
    .error(() => {})
    .send();
};

export const getRenderPreviewListApi = createGetListAction({
  endpoint: GET_RENDER_PREVIEW,
  actions: myRenderPreviewActions,
  setGlobalSidebarFilters: true,
})

export const updateRenderPreviewApi = createUpdateApiAction({
  actions: myRenderPreviewActions,
  endpointUrl: GET_RENDER_PREVIEW,
})

export const deleteRenderPreviewAPI = createDeleteApiAction({
  actions: myRenderPreviewActions,
  endpointUrl: GET_RENDER_PREVIEW,
  successMessage: 'Deleted',
})

// for render upload data //
export const getUploadRenderImages = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_DOWNLOAD_RENDER_IMAGES)
    .success((data) => {
      dispatch(setMyRenderDownloadData(data));
    })
    .error(() => {})
    .send();
};
// export const myRendersdata
