import { createSlice } from '@reduxjs/toolkit';
import { With_Me_Value } from '../../../constants/filters';

const filter = createSlice({
  name: 'filter',
  initialState: {
    filterData: {},
    loading: {
      // '1__Style': true,
    }, // map of `filterType_filterName`: boolean
    members: [],
    // share files member filter
    sharedTo: With_Me_Value,
    selectedMembersTo: [],
    selectedMembersBy: [],
  },
  reducers: {
    setFilterData: (state, { payload }) => {
      state.filterData = payload;
    },
    updateFilterLoading: (state, { payload }) => {
      const { filterType, filterName } = payload;
      const key = `${filterType}__${filterName}`;
      state.loading[key] = payload.loading;
    },
    updateFilter: (state, { payload }) => {
      state.filterData.results = state.filterData.results.map((f) => {
        if (f.id === payload.id) {
          return {
            ...f,
            ...payload,
          };
        }

        return f;
      });
    },
    setMembers: (state, { payload }) => {
      state.members = payload;
    },
    toggleMember: (state, { payload }) => {
      state.members = state.members.includes(payload.id)
        ? state.members.filter((id) => id !== payload.id)
        : [...state.members, payload.id];
    },
    resetMembers: (state) => {
      state.members = [];
    },
    setSharedTo: (state, { payload }) => {
      state.sharedTo = payload;
    },
    setSelectedMembersTo: (state, { payload }) => {
      state.selectedMembersTo = payload;
    },
    setSelectedMembersBy: (state, { payload }) => {
      state.selectedMembersBy = payload;
    },
    resetSharedFilter: (state) => {
      return {
        ...state,
        sharedTo: With_Me_Value,
        selectedMembersTo: [],
        selectedMembersBy: [],
      }
    }
  },
});

export const {
  setFilterData,
  setMembers,
  toggleMember,
  updateFilter,
  updateFilterLoading,
  resetMembers,
  setSharedTo,
  setSelectedMembersTo,
  setSelectedMembersBy,
  resetSharedFilter,
} = filter.actions;
export const filterSelector = (state) => state.filter;
export const filterReducer = filter.reducer;
export default filterReducer;

export const selectFilterUpdateLoading = (filterType, filterName) => (state) =>
  Boolean(state.filter.loading[`${filterType}__${filterName}`]);
export const anyMemberSelected = (state) => state.filter.members.length > 0;
export const selectSharedTo = (state) => state.filter.sharedTo;
export const selectSelectedMembersTo = (state) => state.filter.selectedMembersTo;
export const selectSelectedMembersBy = (state) => state.filter.selectedMembersBy;
