import { COLLECTION_ID } from '../constants/routePathConstants';
import ThumbnailCardUI from './ThumbnailCardUI';
import stackedCards from '../assets/images/stackedCards.png';
import { useCollectionActions } from '../hooks/useCollectionActions';
import styles from './ThumbnailCardUI.module.scss';

export const ThumbnailCardCollection = ({
  data,
  size,
  fullWidth,
  onSelect,
  selected,
  to = COLLECTION_ID,
  hideFavourite,
  onEdit,
  onDelete,
  ...rest
}) => {
  const { favouriteLoading, onFavouriteToggle, onShare, onDelete: _onDelete } = useCollectionActions({
    item: data,
  });

  const handleDelete = onDelete || _onDelete;

  return (
    <ThumbnailCardUI
      key={data.id}
      id={data.id}
      to={to}
      thumbnail={stackedCards}
      name={data.name}
      isFavourite={data.is_favourite}
      favouriteLoading={favouriteLoading}
      date={data.created_at}
      onShare={onShare}
      onFavouriteChange={onFavouriteToggle(data)}
      size={size}
      fullWidth={fullWidth}
      onSelect={onSelect}
      selected={selected}
      onDelete={handleDelete}
      hideFavourite={hideFavourite}
      imageText={`${data.item_count} items`}
      actionsListClassName={styles.actionListSpaceBetween}
      showActionsText
      data={data}
      {...rest}
    />
  );
};