import { useEffect } from 'react';
import PubSub from 'pubsub-js';
import rgbHex from 'rgb-hex';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  CHANGED_MAP_IDS__TEXTURE_MAP,
  CHANGED_MAP_PATHS_MAP,
  GENERATE_MAP_TYPE,
  REVERSE_TILING_MAP,
  TEXTURE_MAP,
  TEXTURE_MAP_TO_TILING_TYPE,
  TEXTURE_MAP__TEXTURE_NAME_KEY,
  TEXTURE_NAME_KEY,
  TEXTURE_NAME_KEY__TEXTURE_MAP,
  TILING_MAP,
  TILING_NAME_MAP,
  TILING_TEXTURE_MAP,
  TILING_TYPE,
  TILING_TYPE_LABEL,
} from '../../../../config/constant/unityConstants';
import {
  inchesToUnitySize,
  sizeDecimals,
  unitySizeToInches,
} from '../../../../helpers/unityHelper';
import {
  curatorSelector,
  selectIndividualProjectData,
  setCuratorLoader,
} from '../../../../redux/slicers/admin/curatorSlicer';
import {
  curatorStylesSelector,
  selectApplyTextureInProgress,
  selectMaterial,
  selectSelectedTexture,
  selectTexture,
  setActiveTab,
  setApplyTextureInProgress,
  setSelectedColor,
  setSelectedTextureId,
  setSelectMapMode,
  TAB_NAME,
} from '../../../../redux/slicers/admin/curatorStylesSlicer';
import {
  curatorUnityObjectSelector,
  selectDataByTilingType,
  selectParsedObject,
  selectSelectedObjectName,
  selectUnitySelectedObjectInfo,
  selectUnitySelectedObjectInfoRaw,
  setTextureMap,
  setUnitySelectedObjectInfo,
  updateRawObject,
  updateUnityObject,
  updateUnityObjectByTextureName,
  updateUnityObjectByTilingType,
} from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { useUnityContext } from '../../../container/unityContainer';
import { getMaterial, getTexture, updateDbLowTexture } from '../../../../helpers/idb';
import { getAppState } from '../../../../redux/store';
import { errorToast } from '../../../../helpers/toastHelper';
import {
  base64ToBlob,
  decimals,
  getImageDetails,
  isValidNumber,
} from '../../../../helpers/jsHelper';
import { apiTextureIsMapCheck, apiUploadTextureMap } from '../../../../helpers/api';
import { AppEvent, UnityEvent, waitForEventOnce } from '../../../../helpers/unityPubSub';
import { undoRedo } from '../UndoRedo/UndoRedo';
import { isEqual } from 'lodash';
import {
  selectGenerateFromDesignLoading,
  selectPasteMaterialLoading,
  setGenerateFromDesignLoading,
} from '../../../../redux/slicers/admin/curatorLoaderSlicer';
import {
  cacheDefaultTexture,
  getDefaultTextureImageUrl,
} from './PropertiesTab/useGetDefaultObjectMaps';

export const useUnityColors = () => {
  const dispatch = useDispatch();
  const unity = useUnityContext();

  const changeColor = (color) => {
    const hex = typeof color === 'string' ? color : color.hex;
    // console.log('onChange, changeCOlor', { hex, hsv })
    unity.materialModule.OnUpdateColorReceiver(hex);
    dispatch(setSelectedColor(color));
    dispatch(
      updateRawObject({
        isColor: true,
        isTextureChanged: false,
      })
    );
  };

  return {
    changeColor,
  };
};

export const useUnityMaterial = () => {
  const dispatch = useDispatch();
  const unity = useUnityContext();

  const applyMaterial = async ({ material, switchToProperties = false, skipHistory = false }) => {
    if (selectApplyTextureInProgress(getAppState())) {
      errorToast('Please wait, previous texture is still in progress', {
        toastId: 'TEXTURE_APPLY_IN_PROGRESS',
      });
      return;
    }

    const prevTiling = selectDataByTilingType(getAppState(), TILING_TYPE.MATERIAL);
    if (prevTiling.materialId === material.id) {
      errorToast('This material is already applied');
      return;
    }

    if (!skipHistory) {
      undoRedo.objectProperties.applyMaterial({ material });
    }

    dispatch(setApplyTextureInProgress(true));
    dispatch(setCuratorLoader(false));
    dispatch(selectMaterial(material));
    await unity.materialModule.selectMaterial({ material });

    if (switchToProperties) {
      dispatch(setActiveTab(TAB_NAME.PROPERTIES));
    }

    dispatch(setCuratorLoader(true));
  };

  return {
    applyMaterial,
  };
};

export const useUnityRedux = () => {
  const dispatch = useDispatch();
  const unity = useUnityContext();
  const selectMapMode = useSelector((state) => curatorStylesSelector(state).selectMapMode);

  const isApplyTextureInProgress = () => {
    return selectApplyTextureInProgress(getAppState());
  };

  const applyTexture = async ({
    texture,
    mapType = TEXTURE_MAP.DIFFUSE,
    switchToProperties = false,
    skipHistory = false,
  }) => {
    if (isApplyTextureInProgress()) {
      errorToast('Please wait, previous texture is still in progress', {
        toastId: 'TEXTURE_APPLY_IN_PROGRESS',
      });
      return false;
    }

    const tilingOptionType = TEXTURE_MAP_TO_TILING_TYPE[mapType];
    const prevTiling = getDataByTilingOptionType(tilingOptionType);

    // if (parseInt(prevTiling?.materialId) === parseInt(texture.id)) {
    //   const errorMessage =
    //     mapType === TEXTURE_MAP.DIFFUSE
    //       ? 'This texture is already applied'
    //       : 'This custom map is already applied';
    //   errorToast(errorMessage, { toastId: 'APPLY_TEXTURE_CUSTOM_MAP' });
    //   return false;
    // }

    dispatch(setApplyTextureInProgress(true));
    dispatch(setCuratorLoader(false));
    console.log('SAHA APPLY TEXTURE !!!', { texture, mapType });

    dispatch(setSelectedTextureId(parseInt(texture.id)));
    const result = await unity.materialModule.selectTexture({ texture, mapType });
    if (result === false) {
      dispatch(setCuratorLoader(true));
      errorToast('The texture is not available. Please contact support. LF_89983');
      return;
    }

    if (switchToProperties) {
      dispatch(setActiveTab(TAB_NAME.PROPERTIES));
    }

    dispatch(setCuratorLoader(true));

    if (!skipHistory) {
      undoRedo.objectProperties.applyTexture({
        prevTiling,
        nextTexture: texture,
        mapType,
      });
    }
  };

  const getUpdateObject = (tilingOptionType, props) => {
    const parsedObject = selectParsedObject(getAppState());
    const key = TILING_NAME_MAP[tilingOptionType];
    const result = Object.entries(props).reduce((acc, [propKey, value]) => {
      if (propKey === 'width' || propKey === 'height') {
        if (typeof value === 'string' && value.trim() === '') {
          acc[propKey] = '';
          return acc;
        }
      }

      acc[propKey] = typeof value !== 'undefined' ? parseFloat(value) : parsedObject[key][propKey];
      return acc;
    }, {});

    return result;
  };

  const update = (tilingType, data) => {
    dispatch(
      updateUnityObject({
        [TILING_NAME_MAP[tilingType]]: data,
      })
    );
  };

  const reset = async () => {
    const texture = getDataByTilingOptionType(TILING_TYPE.TEXTURE);

    if (!texture.materialId) return;

    const dbTexture = await getTexture(texture.materialId);
    let width = parseFloat(dbTexture.width);
    let height = parseFloat(dbTexture.height);

    if (!width || !height) {
      const imageDetails = await getImageDetails(dbTexture.file);
      width = imageDetails.width / imageDetails.dpi;
      height = imageDetails.height / imageDetails.dpi;
    }

    if (texture.width === width && texture.height === height) {
      return;
    }

    const resetData = { width, height };

    changeSize({
      ...resetData,
      tilingOptionType: TILING_TYPE.TEXTURE,
    });

    undoRedo.objectProperties.changeSize({
      prevValue: {
        width: texture.width,
        height: texture.height,
      },
      nextValue: resetData,
      data: {
        tilingOptionType: TILING_TYPE.TEXTURE,
      },
    });
  };

  const changeSize = ({ width, height, tilingOptionType }) => {
    if (typeof height === 'number') {
      height = sizeDecimals(height);
    }

    if (typeof width === 'number') {
      width = sizeDecimals(width);
    }

    const upd = getUpdateObject(tilingOptionType, { width, height });

    unity.materialModule.OnUpdateTilingSizeReceiver({
      ...upd,
      tilingOptionType,
    });

    update(tilingOptionType, upd);
  };

  const changePosition = ({ x, y, tilingOptionType }) => {
    const upd = getUpdateObject(tilingOptionType, {
      x,
      y,
    });

    upd.x = sizeDecimals(upd.x);
    upd.y = sizeDecimals(upd.y);

    unity.materialModule.OnUpdateTilingOffsetReceiver({
      ...upd,
      tilingOptionType,
    });

    update(tilingOptionType, upd);
  };

  const changeRotation = ({ width, height, x, y, rotation, tilingOptionType }) => {
    const parsedObject = selectParsedObject(getAppState());
    const key = TILING_NAME_MAP[tilingOptionType];
    const currentRotation = parsedObject[key].rotation;
    const upd = getUpdateObject(tilingOptionType, { width, height, x, y, rotation });

    if (
      !isValidNumber(upd.width) ||
      !isValidNumber(upd.height) ||
      !isValidNumber(upd.x) ||
      !isValidNumber(upd.y)
    ) {
      errorToast('Please provide data before rotating an object');
      return;
    }

    if (
      (currentRotation % 20 === 0 && rotation % 20 !== 0) ||
      (currentRotation % 20 !== 0 && rotation % 20 === 0)
    ) {
      // switch data
      const tempUpd = { ...upd };
      upd.width = tempUpd.height;
      upd.height = tempUpd.width;
      upd.x = tempUpd.y;
      upd.y = tempUpd.x;
    }

    unity.materialModule.OnUpdateRotationReceiver({
      ...upd,
      tilingOptionType,
    });

    update(tilingOptionType, upd);
  };

  const setFit = (tilingType) => {
    unity.materialModule.OnSetFitReceiver(tilingType);
  };

  const setLock = (tilingType, value) => {
    console.log('set lock ', tilingType, value);
    unity.materialModule.OnLockTilingReceiver(tilingType);
    dispatch(
      updateUnityObject({
        [TILING_NAME_MAP[tilingType]]: {
          lock: value,
        },
      })
    );
  };

  const glossiness = {
    changeIntensity: unity.materialModule.OnUpdateRoughnessValueReceiver,
    invertMap: unity.materialModule.InvertRoughnessToggleReceiver,
    useCustomMap: unity.materialModule.UseCustomRoughnessMapToggleReceiver,
  };

  const normal = {
    changeIntensity: unity.materialModule.OnUpdateNormalValueReceiver,
    invertMap: unity.materialModule.InvertNormalToggleReceiver,
    useCustomMap: unity.materialModule.UseCustomNormalMapToggleReceiver,
    // gereateFromDesign: unity.materialModule.UseGenerateNormalMapToggleReceiver,
  };

  const transparency = {
    changeIntensity: unity.materialModule.OnUpdateTransparencyValueReceiver,
    invertMap: unity.materialModule.InvertTransparencyToggleReceiver,
    useCustomMap: unity.materialModule.UseCustomTransparencyMapToggleReceiver,
    textureAlpha: unity.materialModule.SetTransparencyFromDesignReceiver,
  };

  const ao = {
    changeIntensity: unity.materialModule.OnUpdateAoValueReceiver,
    useCustomMap: unity.materialModule.UseCustomAOMapToggleReceiver,
    invertMap: unity.materialModule.InvertAoToggleReceiver,
  };

  const metallic = {
    changeIntensity: unity.materialModule.OnUpdateMetallicValueReceiver,
    useCustomMap: unity.materialModule.UseCustomMetallicMapToggleReceiver,
  };

  const emission = {
    changeIntensity: unity.materialModule.OnUpdateEmissionValueReceiver,
  };

  const updaters = {
    [TILING_TYPE.GLOSSY]: glossiness,
    [TILING_TYPE.DEPTH]: normal,
    [TILING_TYPE.TRANSPARENCY]: transparency,
    [TILING_TYPE.AO]: ao,
    [TILING_TYPE.METALLIC]: metallic,
    [TILING_TYPE.EMISSION]: emission,
  };

  const changeIntensity = (tilingOptionType, intensity) => {
    const label = TILING_TYPE_LABEL[tilingOptionType];
    const parsedObject = selectParsedObject(getAppState());
    const { normal, ao } = parsedObject;

    if (tilingOptionType === TILING_TYPE.AO && !ao.applied) {
      errorToast(`Please add a custom ${label} map`, {
        toastId: 'ADD_CUSTOM_MAP_AO_ERROR',
      });
      return;
    }

    if (tilingOptionType === TILING_TYPE.DEPTH && !normal.applied) {
      errorToast(`Please add a custom ${label} map`, {
        toastId: 'ADD_CUSTOM_MAP_DEPTH_ERROR',
      });
      return;
    }

    updaters[tilingOptionType].changeIntensity(intensity);
    update(tilingOptionType, { intensity });
  };

  const toggleCustomMap = (tilingOptionType, active, { skipHistory = false } = {}) => {
    console.log('sasha toggleCustomMap');

    if (!skipHistory) {
      undoRedo.objectProperties.disableCustomMap({
        tilingOptionType,
        prevValue: !active,
        nextValue: active,
      });
    }

    updaters[tilingOptionType].useCustomMap(active);
    update(tilingOptionType, {
      customMap: active,
      ...(tilingOptionType === TILING_TYPE.DEPTH && { gereateFromDesign: false, intensity: 0 }),
      ...(tilingOptionType === TILING_TYPE.TRANSPARENCY && { textureAlpha: false }),
      ...(!active && { mapImage: null, hasMapImage: false, materialId: null }),
    });

    if (!active) {
      // toggleSeparateTiling(tilingOptionType, false);

      const t = TILING_NAME_MAP[tilingOptionType];
      const idKey = CHANGED_MAP_PATHS_MAP[t];
      if (idKey) {
        dispatch(
          updateRawObject({
            changedMapsPath: {
              [idKey]: '',
            },
          })
        );
      }
    }
  };

  const getDataByTilingOptionType = (tilingOptionType) => {
    return selectDataByTilingType(getAppState(), tilingOptionType);
  };

  const toggleInvertMap = (tilingOptionType, { skipHistory = false } = {}) => {
    const data = getDataByTilingOptionType(tilingOptionType);
    const active = !data.invertMap;
    updaters[tilingOptionType].invertMap(active);
    update(tilingOptionType, { invertMap: active });

    if (!skipHistory) {
      undoRedo.objectProperties.updateObject({
        name: 'invertMap',
        prevValue: data.invertMap,
        nextValue: active,
        data: { tilingOptionType },
      });
    }
  };

  const toggleSeparateTiling = (tilingOptionType, active) => {
    console.log('sasha toggleSeparateTiling');
    debugger;
    unity.materialModule.AddRemoveSeparateTilingOptionReceiver({
      status: active,
      tilingOptionType,
    });
    // redux data will be updated on unity callback also Mat37 -> ReceiveSeparateTilingProperties
    update(tilingOptionType, { useSeparateTiling: active });
  };

  // const toggleGenerateFromDesign = (active) => {
  //   console.log('sasha toggleGenerateFromDesign')
  //   normal.gereateFromDesign(active)
  //   update(TILING_TYPE.DEPTH, { gereateFromDesign: active })
  // }

  const toggleTextureAlpha = (active) => {
    transparency.textureAlpha(active);
    update(TILING_TYPE.TRANSPARENCY, { textureAlpha: active });
  };

  const toggleEffectLightColorBounce = (active) => {
    console.log('sasha toggleEffectLightColorBounce');
    unity.materialModule.OnEffectLightColorBounceEnableDisableReceiver(active);
    update(TILING_TYPE.OTHER, { effectLightColorBounce: active });
  };

  const toggleMapSelection = (tilingOptionType) => {
    dispatch(setSelectMapMode(tilingOptionType));
  };

  const cancelMapSelection = () => {
    dispatch(setSelectMapMode(null));
    dispatch(setActiveTab(TAB_NAME.PROPERTIES));
  };

  const applyCustomMapAction = (texture) => {
    if (isApplyTextureInProgress()) {
      errorToast('Please wait, texture or custom map is still in progress');
      return;
    }

    const mapType = TILING_TEXTURE_MAP[selectMapMode];
    toggleMapSelection(null);

    applyTexture({
      texture,
      mapType,
    });
  };

  const generateFromDesign = async (tilingOptionType, active) => {
    if (selectGenerateFromDesignLoading(getAppState())) {
      errorToast('Please wait, previous action is still in progress');
      return;
    }

    const normalData = getDataByTilingOptionType(tilingOptionType);
    const textureData = getDataByTilingOptionType(TILING_TYPE.TEXTURE);
    const projectData = selectIndividualProjectData(getAppState());

    if (!active) {
      const rawObject = selectUnitySelectedObjectInfoRaw(getAppState());
      const generateFromDesignVisible = rawObject.isTexture && rawObject.isTextureChanged;

      unity.materialModule.UseGenerateNormalMapToggleReceiver(normalData.mapId, active);
      update(TILING_TYPE.DEPTH, {
        generateFromDesign: active,
        mapImage: null,
        generateFromDesignVisible,
        hasMapImage: false,
      });
      return;
    }

    setGenerateFromDesignLoading(true);
    // TODO: add check in index db before sending request
    const result = await apiTextureIsMapCheck({ textureId: textureData.materialId });

    if (result) {
      const mapType = TILING_TEXTURE_MAP[tilingOptionType];
      await unity.materialModule.selectLowTexture({
        texture: result.data,
        mapType,
        isGeneratedMap: true,
      });
      setGenerateFromDesignLoading(false);
      return;
    }

    // map is missing -> generate new map
    const type = GENERATE_MAP_TYPE[tilingOptionType];
    const unityImage = await waitForEventOnce(UnityEvent.ReceiveBase64StringGenerateMap, {
      triggerFunc: () => unity.materialModule.GenerateMapReceiver(type),
    });

    const textureBlob = base64ToBlob({
      imageString: unityImage.imageInfo,
    });

    const texture = await getTexture(textureData.materialId);

    // TODO: we need here width/height???
    const uploadResult = await apiUploadTextureMap({
      file: textureBlob,
      filterOptions: 82, // TODO: update it if we'll use differnet maps
      name: `${texture.name}_normal`,
      originalTextureId: textureData.materialId,
      project: projectData?.id,
    });

    if (uploadResult?.data?.id) {
      // TODO: store data in indexdb
      const lowTexture = await updateDbLowTexture(uploadResult.data, { IS_GENERATED_MAP: true });
      unity.materialModule.UseGenerateNormalMapToggleReceiver(uploadResult.data.id, active);
      update(TILING_TYPE.DEPTH, {
        generateFromDesign: active,
        mapImage: lowTexture.file || lowTexture.low_texture_file,
      });
    } else {
      errorToast('Unable to upload generated map, please try again');
    }

    update(TILING_TYPE.DEPTH, { gereateFromDesign: active });
    setGenerateFromDesignLoading(false);
  };

  const handleWidthHeightChange = (tilingOptionType, { width, height }) => {
    changeSize({
      tilingOptionType,
      width,
      height,
    });
  };

  const modifyTiling = (tilingOptionType, name, value, { skipHistory } = {}) => {
    switch (name) {
      case 'x':
      case 'y':
        return changePosition({ [name]: Number(value), tilingOptionType });
      case 'width':
      case 'height':
        return changeSize({ [name]: Number(value), tilingOptionType });
      case 'intensity':
        return changeIntensity(tilingOptionType, Number(value));
      case 'invertMap':
        return toggleInvertMap(tilingOptionType, { skipHistory });
      case 'customMap':
        if (value) {
          return toggleMapSelection(tilingOptionType);
        }
        return toggleCustomMap(tilingOptionType, value);
      case 'lock':
        return setLock(tilingOptionType, value);
      case 'useSeparateTiling':
        return toggleSeparateTiling(tilingOptionType, value);
      // case 'generateFromDesign':
      //   return toggleGenerateFromDesign(value)
      case 'textureAlpha':
        return toggleTextureAlpha(value);
      case 'effectLightColorBounce':
        return toggleEffectLightColorBounce(value);
      case 'generateFromDesign':
        return generateFromDesign(tilingOptionType, value);

      case 'rotation':
        return changeRotation({ [name]: parseInt(value), tilingOptionType });

      default:
        throw new Error('Property change handler is missing');
    }
  };

  const onChange = (tilingOptionType) => (event) => {
    let { name, value, type, checked } = event.target;
    value = type === 'checkbox' ? checked : value;
    modifyTiling(tilingOptionType, name, value);
  };

  window.changeRotation = changeRotation;

  // {
  //   xSize: 0.5,
  //   ySize: 0.5,
  //   xPosition: 0.5,
  //   yPosition: 0.5,
  //   rotation: 90,
  //   tilingOptionType: 2,
  // }

  return {
    applyTexture,
    changePosition,
    changeRotation,
    setFit,
    setLock,
    changeSize,
    onChange,
    reset,
    toggleMapSelection,
    applyCustomMapAction,
    cancelMapSelection,
    handleWidthHeightChange,
    modifyTiling,
    toggleCustomMap,
  };
};

export const useProperties = () => {
  const { parsed, raw } = useSelector(selectUnitySelectedObjectInfo);

  let texture = useSelector(selectSelectedTexture);

  // if (properties?.textureId !== texture?.id) {
  //   texture = null
  // }

  return {
    objectName: raw?.objectName,
    properties: raw,
    propertiesData: parsed,
    textureThumbnail: texture?.thumbnail,
    selectedTextureId: texture?.id,
    texture,
  };
};

export const useUnityEvents = () => {
  const dispatch = useDispatch();

  // TOOD: replace it with helper
  const update = (tilingType, data) => {
    dispatch(
      updateUnityObject({
        [TILING_NAME_MAP[tilingType]]: data,
      })
    );
  };

  useEffect(() => {
    async function ReceiveSelectedObjectInfo(eventName, data) {
      try {
        const t0 = performance.now();
        const raw = JSON.parse(data);
        console.log(`Call to doSomething JSON.parse ${performance.now() - t0} milliseconds.`);
        const parsed = await parseUnityObject(raw);
        console.log(`Call to doSomething parseUnityObject ${performance.now() - t0} milliseconds.`);
        console.log('sasha received object from unity ReceiveSelectedObjectInfo', raw);

        const d = {
          parsed,
          raw,
        };

        dispatch(setSelectedTextureId(parseInt(parsed.texture.materialId) || null));
        dispatch(setUnitySelectedObjectInfo(data ? d : null));

        const { colorValue } = raw;
        // let [r, g, b] = colorValue.split("|")
        // const color = rgbHex(parseInt(r), parseInt(g), parseInt(b));
        dispatch(setSelectedColor(colorValue));
        const t1 = performance.now();
        console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);
      } catch (error) {
        // TODO: error logger here
        dispatch(setUnitySelectedObjectInfo({}));
        dispatch(setSelectedTextureId(null));
        // console.error('Unable to parse ReceiveSelectedObjectInfo data', error);
      } finally {
        if (selectApplyTextureInProgress(getAppState())) {
          dispatch(setApplyTextureInProgress(false));
        }

        PubSub.publish(AppEvent.ReceiveSelectedObjectInfoHandled);
      }
    }

    PubSub.subscribe(UnityEvent.ReceiveSelectedObjectInfo, ReceiveSelectedObjectInfo);

    window.ReceiveBase64String = async (
      materialId,
      objectName,
      mapType,
      imageData,
      textureName
    ) => {
      const { imageUrl } = await cacheDefaultTexture({
        materialId,
        imageData,
        textureName,
      });

      console.log('sasha texture name', textureName);
      console.log('sasha ReceiveBase64String', materialId, objectName, mapType);
      // dispatch(setBase64Data({ objectName, mapType, image: imageUrl }));

      const currentStyleableObject = selectSelectedObjectName(getAppState());
      const parsedData = selectParsedObject(getAppState());
      const rawData = selectUnitySelectedObjectInfoRaw(getAppState());
      if (currentStyleableObject === objectName) {
        // update current object with new images

        // find a texture name key
        const textureNameKey = Object.values(TEXTURE_NAME_KEY).find((key) => {
          return rawData[key] === textureName;
        });

        const mapType = TEXTURE_NAME_KEY__TEXTURE_MAP[textureNameKey];
        const tilingType = TEXTURE_MAP_TO_TILING_TYPE[mapType];
        const key = TILING_NAME_MAP[tilingType];

        const tiling = parsedData[key];

        if (!tiling.materialId) {
          // if material id is missing we have default texture
          dispatch(
            updateUnityObjectByTextureName({
              textureName,
              data: {
                mapImage: imageUrl,
              },
            })
          );
        }
      }

      // undo/redo for copy/paste material related logic
      const pasteMaterialLoading = selectPasteMaterialLoading(getAppState());
      if (pasteMaterialLoading) {
        undoRedo.objectProperties.addCopyPasteData({ materialId, objectName });
      }
    };

    window.ReceiveSeparateTilingProperties = (data) => {
      try {
        const { keyName, ...rest } = JSON.parse(data);
        const tilingType = REVERSE_TILING_MAP[keyName];
        const newData = convertTilingtypeData(rest);
        console.log('sasha updateUnityObject before', tilingType, newData);

        // save data to undo/redo
        const currentData = selectDataByTilingType(getAppState(), tilingType);
        const current = {
          height: currentData.height,
          rotation: currentData.rotation,
          width: currentData.width,
          x: currentData.x,
          y: currentData.y,
        };

        update(tilingType, newData);

        if (!isEqual(current, newData)) {
          undoRedo.objectProperties.changeTilingProperties({
            prevValue: current,
            nextValue: newData,
            data: {
              tilingOptionType: parseInt(tilingType),
            },
          });
        }
      } catch (error) {
        errorToast('There was an issue while updating separate tiling');
      }
    };

    return () => {
      PubSub.unsubscribe(UnityEvent.ReceiveSelectedObjectInfo, ReceiveSelectedObjectInfo);
    };
  }, []);
};

const convertTilingtypeData = (data) => ({
  width: unitySizeToInches(data.xMatTiling),
  height: unitySizeToInches(data.yMatTiling),
  x: data.xMatOffset,
  y: data.yMatOffset,
  rotation: data.rotation,
});

export const isNormalGeneratedFromDesign = (data) => {
  return data.isNormalMapChanged && !data.isCustomNormalMap;
};

const parseUnityObject = async (data) => {
  const {
    isTexture,
    isTextureChanged,
    isNormal,
    isNormalMapChanged,
    isRoughnessMap,
    isRoughnessMapChanged,
    isAOMap,
    isAOMapChanged,
    isMetallicMap,
    isMetallicMapChanged,
    isTransparency,
    isTransparencyMapChanged,
  } = data;

  const normalGeneratedFromDesign = isNormalGeneratedFromDesign(data);
  const getTilingOption = (tilingType) => {
    return data.tilingOptions.find((o) => o.tilingOptionType === tilingType);
  };

  const getMaterialId = (idKey) => {
    return parseInt(data.changedMapsPath[idKey]) || undefined;
  };

  const getMapImageById = async (idKey) => {
    const id = data.changedMapsPath[idKey];
    if (!id) return;

    // if (idKey === CHANGED_MAP_PATHS_MAP.normal && normalGeneratedFromDesign) {
    //   // TODO: work on this one
    //   return '';
    // }

    const texture = await getTexture(parseInt(id));
    return texture?.file;
  };

  const getTilingProperties = (tilingType) => {
    const key = TILING_MAP[tilingType];

    if (!key || !data.tilingOffsetValue[key]) return {};

    return convertTilingtypeData(data.tilingOffsetValue[key]);
  };

  const getUst = (tilingType) => {
    const key = TILING_MAP[tilingType];
    if (!key || !data.tilingOffsetValue[key]) return false;
    return !data.materialTilingKeys?.includes(key);
  };

  const materialTilingType = REVERSE_TILING_MAP[data.materialTilingKeys[0]];
  const materialTilingOption = getTilingOption(TILING_TYPE.MATERIAL);
  const textureTilingOption = getTilingOption(TILING_TYPE.TEXTURE);

  const getUrl = async (textureName) => {
    return await getDefaultTextureImageUrl({
      textureName,
      materialId: data.loadedMaterialId,
    });
  };

  const getMapImage = async (mapType, applied, changed) => {
    if (!applied)
      return {
        hasMap: false,
      };

    if (changed) {
      const idKey = CHANGED_MAP_IDS__TEXTURE_MAP[mapType];
      return {
        hasMap: true,
        mapUrl: await getMapImageById(idKey),
      };
    } else {
      const textureKey = TEXTURE_MAP__TEXTURE_NAME_KEY[mapType];
      const textureName = data[textureKey];
      const imageUrl = await getUrl(textureName);
      return {
        hasMap: true,
        mapUrl: imageUrl,
      };
    }
  };

  const [
    diffuseMapImage,
    roughnessMapImage,
    normalMapImage,
    transparencyMapImage,
    aoMapImage,
    metallicMapImage,
  ] = await Promise.all([
    getMapImage(TEXTURE_MAP.DIFFUSE, isTexture, isTextureChanged),
    getMapImage(TEXTURE_MAP.ROUGHNESS, isRoughnessMap, isRoughnessMapChanged),
    getMapImage(TEXTURE_MAP.NORMAL, isNormal, isNormalMapChanged),
    getMapImage(TEXTURE_MAP.TRANSPARENCY, isTransparency, isTransparencyMapChanged),
    getMapImage(TEXTURE_MAP.AMBIENTOCCLUSION, isAOMap, isAOMapChanged),
    getMapImage(TEXTURE_MAP.METALLIC, isMetallicMap, isMetallicMapChanged),
  ]);

  const getMaterialImage = async () => {
    const diffuseId = getMaterialId('diffuseId');
    if (data.loadedMaterialId) {
      const material = await getMaterial(parseInt(data.loadedMaterialId));
      return material?.thumbnail;
    } else if (diffuseId) {
      const texture = await getTexture(parseInt(diffuseId));
      return texture?.file;
    }
  };

  const hasDefaultDiffuseTexture = data.isDiffuse && !data.isDiffuseMapChanged;
  const hasDefaultNormalTexture = data.isNormal && !data.isNormalMapChanged;
  const hasDefaultRoughnessTexture = data.isRoughnessMap && !data.isRoughnessMapChanged;
  const hasDefaultAOTexture = data.isAOMap && !data.isAOMapChanged;
  const hasDefaultMetallicTexture = data.isMetallicMap && !data.isMetallicMapChanged;
  const hasDefaultTransparencyTexture =
    data.isTransparency && !data.isTransparencyMapChanged && Boolean(data.transparencyMapName);

  return {
    isTexture: data.isTexture, // to check if  texture is applied or not on the object
    colorValue: data.colorValue,

    material: {
      hideUI: !materialTilingOption || !materialTilingType, // hide if material is missing
      mapImage: await getMaterialImage(),
      hasMapImage: Boolean(data.loadedMaterialId),
      tilingType: TILING_TYPE.MATERIAL,
      materialId: parseInt(data.loadedMaterialId),
      ...getTilingProperties(materialTilingType),
      lock: materialTilingOption?.isLocked || false,
    },
    texture: {
      hideUI: !textureTilingOption, // hide if material is missing
      mapImage: diffuseMapImage.mapUrl,
      hasMapImage: diffuseMapImage.hasMap,
      materialId: getMaterialId('diffuseId'),
      tilingType: TILING_TYPE.TEXTURE,
      ...getTilingProperties(TILING_TYPE.TEXTURE),
      lock: textureTilingOption?.isLocked || false,
    },

    // check materialTilingKeys -> to know if "Use separate tiliing is enabled"
    glossiness: {
      mapImage: roughnessMapImage.mapUrl,
      hasMapImage: roughnessMapImage.hasMap,
      materialId: getMaterialId('roughnessId'),
      tilingType: TILING_TYPE.GLOSSY,
      applicable: true,
      applied: isRoughnessMap,
      intensity: decimals(data.roughnessIntensity, 2),
      showSliderWhenExpanded: data.roughnessIntensity > 0,
      invertMap: data.isRoughnessInvert,
      customMap: data.isCustomRoughnessMap || hasDefaultRoughnessTexture, //  -> use custom map is enabled when it's true
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.GLOSSY),
      lock: getTilingOption(TILING_TYPE.GLOSSY)?.isLocked || false,
      texture: null,
      // TODO: separateTiling????

      ...getTilingProperties(TILING_TYPE.GLOSSY),
    },

    normal: {
      mapImage: normalMapImage.mapUrl,
      hasMapImage: normalMapImage.hasMap,
      materialId: getMaterialId('normalId'),
      tilingType: TILING_TYPE.DEPTH,
      applicable: true, // data.isNormal && data.normalIntensity > 0,
      applied: isNormal,
      intensity: decimals(data.normalIntensity, 2),
      showSliderWhenExpanded: data.normalIntensity > 0,
      invertMap: data.isNormalInvert,
      customMap: data.isCustomNormalMap || hasDefaultNormalTexture,
      generateFromDesignVisible:
        (data.isTexture && data.isTextureChanged) || normalGeneratedFromDesign,
      // TODO: when user selects object -> get generateFromDesign image based on texture id
      generateFromDesign: normalGeneratedFromDesign,
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.DEPTH),
      lock: getTilingOption(TILING_TYPE.DEPTH)?.isLocked || false,
      texture: null,
      // separateTiling:
      // TODO: separateTiling????
      // TODO; generate from design??? -> isNormal === true && isCustomNormalMap === false
      // can not be customMap && generate from design at the same time -> it's radio button

      // use seaparate tiling should be disabled until custom map or generate from design isenabled

      ...getTilingProperties(TILING_TYPE.DEPTH),
    },

    transparency: {
      mapImage: transparencyMapImage.mapUrl,
      hasMapImage: transparencyMapImage.hasMap,
      materialId: getMaterialId('transparencyId'),
      tilingType: TILING_TYPE.TRANSPARENCY,
      applicable: data.transparencyIntensity > 0,
      applied: isTransparency,
      intensity: decimals(data.transparencyIntensity, 2),
      showSliderWhenExpanded: data.transparencyIntensity > 0,
      invertMap: data.isTransparencyInvert,
      textureAlpha: data.isTransparencyFromAlbedo,
      customMap: data.isCustomTransparencyMap || hasDefaultTransparencyTexture,
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.TRANSPARENCY),
      lock: getTilingOption(TILING_TYPE.TRANSPARENCY)?.isLocked || false,
      texture: null,
      ...getTilingProperties(TILING_TYPE.TRANSPARENCY),
    },

    ao: {
      mapImage: aoMapImage.mapUrl,
      hasMapImage: aoMapImage.hasMap,
      materialId: getMaterialId('AOId'),
      tilingType: TILING_TYPE.AO,
      applicable: data.isAOMap && data.aoIntensity > 0,
      applied: isAOMap,
      intensity: decimals(data.aoIntensity, 2),
      showSliderWhenExpanded: data.aoIntensity > 0,
      customMap: data.isCustomAOMap || hasDefaultAOTexture,
      invertMap: data.isAOInvert,
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.AO),
      lock: getTilingOption(TILING_TYPE.AO)?.isLocked || false,
      ...getTilingProperties(TILING_TYPE.AO),
    },

    metallic: {
      mapImage: metallicMapImage.mapUrl,
      hasMapImage: metallicMapImage.hasMap,
      materialId: getMaterialId('metallicId'),
      tilingType: TILING_TYPE.METALLIC,
      applicable: data.metalIntensity > 0,
      applied: isMetallicMap,
      intensity: decimals(data.metalIntensity, 2),
      showSliderWhenExpanded: data.metalIntensity > 0,
      customMap: data.isCustomMetallicMap || hasDefaultMetallicTexture,
      customMapApplicable: true,
      useSeparateTiling: getUst(TILING_TYPE.METALLIC),
      lock: getTilingOption(TILING_TYPE.METALLIC)?.isLocked || false,

      ...getTilingProperties(TILING_TYPE.METALLIC),
    },

    emission: {
      tilingType: TILING_TYPE.EMISSION,
      intensity: decimals(data.emissionIntensity, 2),
      showSliderWhenExpanded: data.emissionIntensity > 0,
    },

    other: {
      tilingType: TILING_TYPE.OTHER,
      effectLightColorBounce: data.effectLightColorBounce,
    },
  };
};
