import { useSelector } from 'react-redux';
import { MODEL_TYPES } from '../constants/constants';
import { ADMIN_MY_PRODUCTS_3DMODAL_EACH } from '../constants/routePathConstants';
import { useProductActions } from '../hooks/useProductActions';
import { selectPropDetailsPopupData } from '../redux/slicers/propDetailsPopupSlicer';
import ThumbnailCardUI from './ThumbnailCardUI';

export const ThumbnailCardProduct = ({
  data,
  size,
  fullWidth,
  onSelect,
  selected,
  to,
  hideFavourite,
  onEdit,
  modelType = MODEL_TYPES.PRODUCT,
  ...rest
}) => {
  const { favouriteLoading, onFavouriteToggle, onShare, addToCollection, onEdit: _onEdit } = useProductActions({
    item: data,
    modelType,
  });

  const handleEdit = onEdit || _onEdit;

  const propsClicked = useSelector(selectPropDetailsPopupData);
  const highlight = data.id === propsClicked?.id ? "info" : undefined;
  const secondLine = data?.owner_organization_details?.name || "";

  return (
    <ThumbnailCardUI
      key={data.id}
      id={data.id}
      to={to}
      onEdit={handleEdit}
      onCardImageClick={handleEdit}
      thumbnail={data?.thumbnail}
      images={data?.images}
      name={data.name}
      isFavourite={data.is_favourite}
      favouriteLoading={favouriteLoading}
      onShare={onShare}
      titleProps={{
        onClick: handleEdit,
      }}
      onFavouriteChange={onFavouriteToggle(data)}
      size={size}
      fullWidth={fullWidth}
      onSelect={onSelect}
      selected={selected}
      hideFavourite={hideFavourite}
      onAddToCollection={addToCollection}
      data={data}
      highlight={highlight}
      secondLine={secondLine}
      {...rest}
    />
  );
};

export const ThumbnailCardModel3D = (props) => {
  return <ThumbnailCardProduct {...props} modelType={MODEL_TYPES.THREEDMODELS} to={props.to || ADMIN_MY_PRODUCTS_3DMODAL_EACH} />;
};
