import { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { CommonMenu, Sidemenu, Breadcrumbs } from '.';
import cx from 'classnames';
import { useContext } from 'react';
import { FilterContext } from '../contexts';
import _ from 'lodash';
import { icons } from '../assets';
import { selectSearchActive } from '../redux/slicers/search/searchSlicer';
import { useSelector } from 'react-redux';
import SearchResult from '../components/container/SearchResult';
import { Loader } from '../common';
import { useUserContext } from '../contexts/UserContext';

// common layout for screens with logged into a session
export default function LayoutWrapper({
  nav = true,
  sidenav = true,
  token,
  refreshToken,
  isEmailVerified,
  currentUser,
  fullScreen,
}) {
  const userCtx = useUserContext();
  const searchActive = useSelector(selectSearchActive);
  const calculatedFullScreen = fullScreen && !searchActive;

  const filterCtx = useContext(FilterContext);
  const pageWrapper = useRef();
  const visualViewportHeight = window.visualViewport
    ? window.visualViewport.height
    : window.innerHeight;
  const heightMinusHeader = visualViewportHeight - 64;
  const [contentHeight, setContentHeight] = useState(heightMinusHeader);
  const [showInfoArea, setShowInfoArea] = useState(false);
  useEffect(() => {
    if (pageWrapper && pageWrapper.current && token) {
      const newHeight = _.ceil(pageWrapper.current.offsetHeight, contentHeight, heightMinusHeader);
      if (newHeight > contentHeight) {
        setContentHeight(newHeight);
      }
    }
  }, [pageWrapper, contentHeight, heightMinusHeader, token]);


  if (!token || !refreshToken || !isEmailVerified || !currentUser) return <Outlet />; // token here

  const sidebarActive = sidenav || searchActive;

  return (
    <div>
      {userCtx.logoutLoading && <Loader loading backdrop={true} />}
      {nav && <CommonMenu />}
      <section className="body-of-main">
        {sidebarActive && <Sidemenu height={`${contentHeight}px`} />}
        <Container
          fluid
          className={cx(
            {
              'page-wrapper': !calculatedFullScreen,
              'full-screeen-page-wrapper': calculatedFullScreen,
            },
            'p-0',
            !sidebarActive && 'no-sidebar'
          )}
          ref={pageWrapper}>
          {filterCtx && filterCtx.breadcrumbs && <Breadcrumbs list={filterCtx.breadcrumbs} />}
          {searchActive && nav ? <SearchResult /> : <Outlet />}
        </Container>
      </section>
    </div>
  );
}
