import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import {
  REVERSE_PROPERTY_MAP,
  REVERSE_TILING_MAP,
  SELECTED_OBJECT_COUNT_STR,
  TEXTURE_MAP_TO_TILING_TYPE,
  TEXTURE_NAME_KEY,
  TEXTURE_NAME_KEY__TEXTURE_MAP,
  TILING_NAME_MAP,
  TILING_TYPE_LABEL,
  UNITY_OBJECT_TYPE,
} from '../../../config/constant/unityConstants';

// type TObjectConfig = {
//   expertMode: Boolean;
// }

const initialState = {
  selectedObjectsCountStr: SELECTED_OBJECT_COUNT_STR.NONE,
  unitySelectedObjectInfo: {},
  selectedObjectList: [],
  objectPositionAlongAxis: {}, // position here is in meters -> so keep it always meters
  copiedObjectProperties: null, // store proepties of the object when someone clicked "Copy Material" from the context menu
  objectConfigMap: {}, // map of { objectName: TObjectConfig }
  // objectPositionAlongAxis: {
  //   value: 22,
  //   transformType: 'Move',
  // },
};

const curatorUnityObjectSlicer = createSlice({
  name: 'curatorUnityObject',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setUnitySelectedObjectInfo: (state, { payload }) => {
      state.unitySelectedObjectInfo = payload;
    },
    setTextureMap: (state, { payload }) => {
      state.unitySelectedObjectInfo.parsed[payload.tilingTypeName].texture = payload.texture;
    },
    updateRawObject: (state, { payload }) => {
      state.unitySelectedObjectInfo.raw = merge({}, state.unitySelectedObjectInfo.raw, payload);
    },
    updateUnityObject: (state, { payload }) => {
      state.unitySelectedObjectInfo.parsed = merge(
        {},
        state.unitySelectedObjectInfo.parsed,
        payload
      );
    },
    updateUnityObjectByTilingType: (state, { payload }) => {
      const keyName = TILING_NAME_MAP[payload.tilingType];
      state.unitySelectedObjectInfo.parsed = merge({}, state.unitySelectedObjectInfo.parsed, {
        [keyName]: payload.data,
      });
    },
    updateUnityObjectByTextureName: (state, { payload }) => {
      const { textureName, data } = payload;

      Object.values(TEXTURE_NAME_KEY).forEach(key => {
        if (state.unitySelectedObjectInfo.raw[key] !== textureName) return;

        const mapType = TEXTURE_NAME_KEY__TEXTURE_MAP[key];
        const tilingType  = TEXTURE_MAP_TO_TILING_TYPE[mapType];
        const keyName = TILING_NAME_MAP[tilingType]

        state.unitySelectedObjectInfo.parsed = merge({}, state.unitySelectedObjectInfo.parsed, {
          [keyName]: data,
        });
      })
      
    },
    setSelectedObjectsCountStr: (state, { payload }) => {
      state.selectedObjectsCountStr = payload;
    },
    setSelectedObjectList: (state, { payload }) => {
      state.selectedObjectList = payload;
    },
    setObjectPositionAlongAxis: (state, { payload }) => {
      state.objectPositionAlongAxis = {
        value: payload.value,
        transformType: payload.transformType,
      };
    },
    setObjectPositionAlongAxisValue: (state, { payload }) => {
      state.objectPositionAlongAxis.value = payload;
    },
    resetObjectPositionAlongAxis: (state, { payload }) => {
      state.objectPositionAlongAxis = initialState.objectPositionAlongAxis;
    },
    setCopiedObjectProperties: (state, { payload }) => {
      state.copiedObjectProperties = payload;
    },
    setExpertMode: (state, { payload }) => {
      const { objectName, enabled } = payload
      if (!state.objectConfigMap[objectName]) {
        state.objectConfigMap[objectName] = {}
      }

      state.objectConfigMap[objectName].expertMode = enabled;

      if (!enabled) {
        // recalculate if sliders should be visible when expert mode is off
        Object.values(TILING_NAME_MAP).forEach(key => {
          const show = state.unitySelectedObjectInfo.parsed[key].intensity > 0;
          state.unitySelectedObjectInfo.parsed[key].showSliderWhenExpanded = show;
        })
      }
    },
    // handleDeletedObjects: (state, { paylaod }) => {
    //   if (!state.unitySelectedObjectInfo.raw) return;
    //   payload.objectList.forEach(object => {
    //     if (object.objectName)
    //   })
    // }
  },
});

export const {
  // setBase64Data,
  setUnitySelectedObjectInfo,
  updateUnityObject,
  setTextureMap,
  updateRawObject,
  setSelectedObjectsCountStr,
  setSelectedObjectList,
  setObjectPositionAlongAxis,
  resetObjectPositionAlongAxis,
  updateUnityObjectByTextureName,
  setObjectPositionAlongAxisValue,
  setCopiedObjectProperties,
  updateUnityObjectByTilingType,
  setExpertMode,
} = curatorUnityObjectSlicer.actions;
export const curatorUnityObjectActions = curatorUnityObjectSlicer.actions;

export const curatorUnityObjectSelector = (state) => state.curatorUnityObject;
export const curatorUnityObjectReducer = curatorUnityObjectSlicer.reducer;
export default curatorUnityObjectReducer;

export const selectUnitySelectedObjectInfo = (state) =>
  state.curatorUnityObject.unitySelectedObjectInfo;
export const selectUnitySelectedObjectInfoRaw = (state) =>
  state.curatorUnityObject.unitySelectedObjectInfo?.raw;
export const selectIsAnyObjectSelected = (state) =>
  state.curatorUnityObject.selectedObjectsCountStr !== SELECTED_OBJECT_COUNT_STR.NONE;
export const selectSelectedObjectName = (state) =>
  state.curatorUnityObject.unitySelectedObjectInfo?.raw?.objectName;
export const selectSelectedObjectMaterialId = (state) =>
  state.curatorUnityObject.unitySelectedObjectInfo?.raw?.loadedMaterialId;
export const selectHasSelectedObjectInfo = (state) =>
  Boolean(state.curatorUnityObject.unitySelectedObjectInfo?.raw?.objectName);
export const selectIsStyleableObject = (state) =>
  Boolean(state.curatorUnityObject.unitySelectedObjectInfo?.raw?.objectName);
export const selectSelectedObjectCountStr = (state) =>
  Boolean(state.curatorUnityObject.selectedObjectsCountStr);
export const selectIsOneObjectSelected = (state) =>
  Boolean(state.curatorUnityObject.selectedObjectsCountStr === SELECTED_OBJECT_COUNT_STR.SINGLE);
export const selectCanReplaceProp = (state) =>
  Boolean(
    state.curatorUnityObject.selectedObjectList.find((o) => o.type === UNITY_OBJECT_TYPE.PROP)
  );
export const selectSelectedObjectList = (state) => state.curatorUnityObject.selectedObjectList;
export const selectReplaceProp = (state) => selectSelectedObjectList(state)?.[0] || null;
export const selectIsSingleLightObjectSelected = (state) =>
  Boolean(
    state.curatorUnityObject.selectedObjectList.find((o) => o.type === UNITY_OBJECT_TYPE.LIGHT)
  ) && state.curatorUnityObject.selectedObjectList.length === 1;
export const selectParsedObject = (state) =>
  state.curatorUnityObject.unitySelectedObjectInfo.parsed;
export const selectObjectPositionAlongAxis = (state) =>
  state.curatorUnityObject.objectPositionAlongAxis;
export const selectCopiedObjectProperties = (state) =>
  state.curatorUnityObject.copiedObjectProperties;
export const selectExpertMode = (state, objectName) => {
  objectName = objectName || selectSelectedObjectName(state);
  return Boolean(state.curatorUnityObject.objectConfigMap[objectName]?.expertMode)
}

export const selectSelectedLightObject = (state) =>
  state.curatorUnityObject.selectedObjectList.find((o) => o.type === UNITY_OBJECT_TYPE.LIGHT);

export const selectMainTilingType = (state) => {
  if (!state.curatorUnityObject.unitySelectedObjectInfo?.parsed) return null;

  const { texture, material } = state.curatorUnityObject.unitySelectedObjectInfo?.parsed;
  return texture.hideUI ? material : texture;
};

export const selectDataByTilingType = (state, tilingOptionType) => {
  const objectInfo = selectUnitySelectedObjectInfo(state);
  const key = TILING_NAME_MAP[tilingOptionType];
  return objectInfo.parsed?.[key];
};
