import cx from 'classnames';
import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import styles from '../assets/scss/modules/_previewUI.module.scss';
import { InfoIcon } from '../components/icons/InfoIcon';
import { KEEP_PREVIEW_FOR_DAYS } from '../constants/projectConstants';
import { ThumbnailCard } from './';
import ThumbnailContainer from './ThumbnailContainer';
import differenceInDays from 'date-fns/differenceInDays';
import NoDataFound from './NoDataFound';
import { Loader } from './';

export default function PreviewSidebar(props) {

  const {
    theme,
    title,
    userName,
    updatedAt,
    created_at,
    cards,
    isFullscreen,
    className,
    classNameFullscreen,
    activeIndex,
    setActiveIndex,
    activeCameraId,
    setActiveCameraId,
    memberDetails,
    loading,
    hideAutoEraseWarning,
  } = props;

  // console.log("cards: ",cards);

  // console.log(theme,
  //   title,
  //   userName,
  //   updatedAt,
  //   created_at,
  //   cards,
  //   isFullscreen,
  //   className,
  //   classNameFullscreen,
  //   activeIndex,
  //   setActiveIndex,
  //   activeCameraId,
  //   setActiveCameraId,
  //   memberDetails,
  //   hideAutoEraseWarning);
  
  let daysLeft = KEEP_PREVIEW_FOR_DAYS;
  if(created_at){
    const finalDate = new Date(created_at);
    finalDate.setDate(finalDate.getDate() + KEEP_PREVIEW_FOR_DAYS);
    daysLeft = finalDate.getTime() <= Date.now() ? 0 : differenceInDays(finalDate, Date.now()) + 1;
  }

  return (
    <div
      className={cx(
        styles.sidebar,
        className,
        isFullscreen && styles.fullscreen,
        isFullscreen && classNameFullscreen,
        styles[theme]
      )}>
      {/* Top block */}

      {/* for organisation rooms */}
      {
        props?.currentOrgDetails
        &&
        <div className={styles.sidebarTop}>
          {
            props.currentOrgDetails?.thumbnail
            &&
            <img src={props.currentOrgDetails?.thumbnail} alt="Avatar" className={styles.avatar} />
          }
          
          <div className={styles.textContainer}>
            <div className={styles.textContainerTitle} title={title}>{title}</div>
            <div className={styles.editedInfo}>
              {
                props.currentOrgDetails?.thumbnail
                ?
                props.currentOrgDetails?.name
                :
                `Created by: ${props.currentOrgDetails?.name}`
              }
            </div>
          </div>
        </div>
      }

      {/* for renders/previews */}
      {
        memberDetails
        &&
        <div className={styles.sidebarTop}>
          <img src={memberDetails?.profile_image} alt="Avatar" className={styles.avatar} />
        
          <div className={styles.textContainer}>
            <div className={styles.textContainerTitle} title={title}>{title}</div>
            <div className={styles.editedInfo}>
              {memberDetails?.first_name ? memberDetails?.first_name : memberDetails?.name}
              {' · '}
              <span className={styles.timeAgo}>
                {updatedAt && <ReactTimeAgo date={updatedAt} />}
              </span>
            </div>
          </div>
        </div>
      }
      
      {/* End Top Block */}
      <div className={cx(`px-3 pb-3`, 
        styles.sidebarCardContainer,
        (cards?.length > 0 && cards[0]?.is_preview) && styles.notPreview
      )}>
        {
          loading
          ?
          <Loader key={'loading'} loading={loading} position="relative" backdrop={false} className="scroll-loader" />
          :
            cards?.length > 0
            ?
            <div className="preview-image-side-section">
            <ThumbnailContainer 
              data={cards} 
              setActiveIndex={setActiveIndex} 
              setActiveCameraId={setActiveCameraId} 
              activeCameraId={activeCameraId}
            />
            </div>
            :
            <NoDataFound title={'Camera Angles'} />
        }
      </div>
      {
        (cards?.length > 0 && cards[0]?.is_preview) && !hideAutoEraseWarning
        &&
        <div className={styles.sidebarPreviewNote}>
          <InfoIcon/><p>Auto erase in {daysLeft} Days </p>
        </div>
      }
    </div>
  );
}
