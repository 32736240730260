import { createSlice } from '@reduxjs/toolkit';

const sharePopup = createSlice({
  name: 'sharePopup',
  initialState: {
    data: {}, // it's a map where key is MODEL_TYPES and value is an array of shared items
  },
  reducers: {
    share: (state, { payload }) => {
      state.data = payload;
    },
    clear: (state) => {
      state.data = {}
    },
  },
});

export const sharePopupActions = sharePopup.actions;
export const sharePopupSelector = (state) => state.sharePopup;
export const sharePopupReducer = sharePopup.reducer;
export default sharePopupReducer;

export const selectShareData = (state) => state.sharePopup.data