import cx from 'classnames';
import styles from './SearchBar.module.scss';

const SearchBar = ({ onFilterClick, filterActive, placeholder, className, onChange, value }) => {
  return (
    <form
      className={cx('d-flex', styles.root, className)}
      onSubmit={(e) => {
        e.preventDefault();
      }}>
      <input
        className={cx(styles.input, 'form-control')}
        type="text"
        placeholder={placeholder}
        aria-label="Search"
        onChange={onChange}
        value={value}
      />
      <span>
        <div className={styles.searchIcon}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3543 17.8359C7.73596 17.8359 4.79688 14.9145 4.79688 11.3179C4.79688 7.72124 7.73596 4.7998 11.3543 4.7998C14.9727 4.7998 17.9118 7.72124 17.9118 11.3179C17.9118 14.9145 14.9727 17.8359 11.3543 17.8359ZM11.3543 5.80258C8.29419 5.80258 5.80571 8.27611 5.80571 11.3179C5.80571 14.3596 8.29419 16.8332 11.3543 16.8332C14.4145 16.8332 16.903 14.3596 16.903 11.3179C16.903 8.27611 14.4145 5.80258 11.3543 5.80258Z"
              fill="#5A5C61"
            />
            <path
              d="M17.5134 19.1998C17.4596 19.1998 17.4058 19.1931 17.3587 19.1864C17.0426 19.1463 16.4709 18.9324 16.1481 17.9764C15.98 17.475 16.0405 16.9736 16.3162 16.5925C16.592 16.2115 17.056 15.9976 17.5874 15.9976C18.2734 15.9976 18.8114 16.2583 19.0536 16.7196C19.2957 17.1808 19.2284 17.7691 18.8451 18.3374C18.3675 19.0527 17.8497 19.1998 17.5134 19.1998ZM17.1099 17.6622C17.2242 18.0098 17.3856 18.1836 17.4932 18.197C17.6008 18.2104 17.8026 18.0833 18.0111 17.7825C18.2061 17.495 18.2196 17.2878 18.1725 17.1942C18.1254 17.1006 17.9371 17.0003 17.5874 17.0003C17.3789 17.0003 17.2242 17.0672 17.1368 17.1808C17.056 17.2945 17.0426 17.4683 17.1099 17.6622Z"
              fill="#5A5C61"
            />
          </svg>
        </div>
        {onFilterClick && (
          <div
            className={cx(styles.filterButton, filterActive && styles.filterButtonActive)}
            onClick={onFilterClick}>
            {/* <!-- <img src="./image/Frame 1063.png" className="filter w-auto h-auto" alt="" /> --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5969 7.2H11.3969C11.0655 7.2 10.7969 7.46863 10.7969 7.8V9C10.7969 9.33137 11.0655 9.6 11.3969 9.6H15.5969C15.9282 9.6 16.1969 9.33137 16.1969 9V8.4V7.8C16.1969 7.46863 15.9282 7.2 15.5969 7.2ZM18.5969 9H17.3969C17.3969 9.99411 16.591 10.8 15.5969 10.8H11.3969C10.4028 10.8 9.59688 9.99411 9.59688 9H5.39687C5.0655 9 4.79688 8.73137 4.79688 8.4C4.79688 8.06863 5.0655 7.8 5.39688 7.8H9.59688C9.59688 6.80589 10.4028 6 11.3969 6H15.5969C16.591 6 17.3969 6.80589 17.3969 7.8H18.5969C18.9282 7.8 19.1969 8.06863 19.1969 8.4C19.1969 8.73137 18.9282 9 18.5969 9ZM4.79688 15.6C4.79688 15.2686 5.0655 15 5.39688 15H6.59688C6.59688 14.0059 7.40276 13.2 8.39688 13.2H12.5969C13.591 13.2 14.3969 14.0059 14.3969 15H18.5969C18.9282 15 19.1969 15.2686 19.1969 15.6C19.1969 15.9314 18.9282 16.2 18.5969 16.2H14.3969C14.3969 17.1941 13.591 18 12.5969 18H8.39688C7.40276 18 6.59688 17.1941 6.59688 16.2H5.39687C5.0655 16.2 4.79688 15.9314 4.79688 15.6ZM7.79688 15.6V16.2C7.79688 16.5314 8.0655 16.8 8.39688 16.8H12.5969C12.9282 16.8 13.1969 16.5314 13.1969 16.2V15C13.1969 14.6686 12.9282 14.4 12.5969 14.4H8.39688C8.0655 14.4 7.79688 14.6686 7.79688 15V15.6Z"
                fill="currentColor"
              />
            </svg>
          </div>
        )}
      </span>
    </form>
  );
};

export default SearchBar;
