import { useEffect, useState } from 'react';
import { PERMISSIONS } from '../constants/permissionConstants';
import { apiCheckMemberPermissions } from '../helpers/api';
import { errorToast } from '../helpers/toastHelper';

export const usePermissions = ({ permissions, onDenied }) => {
  // currently we support check for single permission only
  const _permissions = permissions.map(p => PERMISSIONS[p]);
  const permissionConfig = _permissions[0];
  const [loading, setLoading] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  

  if (!permissionConfig?.codename) {
    throw new Error('Permission list is required');
  }

  const checkMemberPermission = async () => {
    const res = await apiCheckMemberPermissions(permissionConfig.codename);
    if (res) {
      setIsAllowed(true);
    } else {
      setIsAllowed(false);
      errorToast(permissionConfig.warningMessage)
      onDenied?.()
    }
    setLoading(false);
  };

  useEffect(checkMemberPermission, []);

  return { isAllowed, loading };
};

export const usePermissionsHandler = () => {
  const handlePermissionEvent = (permissionKey, callback) => async (e, ...rest) => {
    const { codename, warningMessage } = PERMISSIONS[permissionKey];
    const result = await apiCheckMemberPermissions(codename);

    if (!result) {
      errorToast(warningMessage, { toastId: `PERMISSION_FAILED_${codename}` });
      e?.preventDefault?.();
      return;
    }
    
    return callback?.(e, ...rest)
  }

  return {
    handlePermissionEvent,
  }
}