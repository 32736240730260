import { useState } from 'react';
import _ from 'lodash';
import CircularProgress from '../components/CircularProgress/CircularProgress';
import Loader from './Loader';

export default function FooterButton(props) {
  const {
    id,
    cardType,
    title,
    clickFunction,
    showModalFunction,
    loading = false,
    alt,
    icon,
    inPreview,
    isDisabled,
    isDisabledCallback,
  } = props;

  function handleClickEvent(e) {
    if (_.isFunction(clickFunction)) {
      clickFunction(e);
    } else if (_.isFunction(showModalFunction)) {
      showModalFunction(true);
    }
  }

  const Icon = icon;

  const style = {
    fontSize: '0.75rem',
  };

  if (!props.footerButton) return <></>;

  return (
    <li title={title}>
      <div
        role="button"
        onClick={isDisabled ? isDisabledCallback : handleClickEvent}
        className={`text-light d-flex flex-column justify-content-center align-items-center ${isDisabled ? 'opacity-50' : ''}`}>
        {!loading && <Icon />}
        {loading && <div style={{ width: 24, height: 24, position: 'relative' }}><Loader loading size={14} thickness={1} /></div>}

        <div className="text-center text-nowrap" style={style}>
          {props.title}
        </div>
      </div>
    </li>
  );
}
