import { useState, useEffect } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { Table } from 'react-bootstrap';
import { Image } from './';
import { icons } from '../assets';
import { Checkbox } from './';
const { UserIcon } = icons;

export default function SelectableTableList(props) {
  const [allSelected, setAllSelected] = useState(false);
  const { options, setSelectedOption, selectedOption } = props;

  useEffect(() => {
    const anyFalse = _.some(options, (opt) => !opt.selected);
    setAllSelected(!anyFalse);
  }, [options]);

  function handleAllSelect() {
    let newValue = !allSelected;
    setAllSelected(newValue);
    if (_.isFunction(props.onSelectAll)) {
      props.onSelectAll(newValue);
    }
  }

  function handleSelect(item) {
    let change = item.selected ? false : true;
    props.onSelect(item, change);
  }
  return (
    <div className="selectableTable add-collection-model">
      <Table className={props.tableClassName || ''}>
        {props.showHeaderLabel && (
          <thead className="selectableThead">
            <tr
              onClick={props.onHeaderClick}
              className={cx(props.className || 'text-primary', 'selectableRow')}>
              <th role="button" className="px-3 py-4 fw-normal">
                {props.showHeaderLabel}
              </th>
            </tr>
          </thead>
        )}
        {props.onSelectAll && (
          <thead className="selectableTheadAll">
            <tr
              onClick={handleAllSelect}
              className={cx(props.className || 'text-light', 'selectableRow')}>
              <th className="p-2">
                <Checkbox
                  name={'Select All'}
                  onChange={() => { }}
                  label={`Select All (${options.length} ${props.item}s)`}
                  selected={allSelected}
                />
              </th>
            </tr>
          </thead>
        )}
        {options && options.length > 0 ? (
          <>
            <tbody className="selectableTableBody">
              {_.map(options, (item, index) => {
                return (
                  <tr
                    key={`selectableList${props.id}${index}}`}
                    className={`selectableRow ${props.textColor || ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelect(item);
                    }}>
                    <div
                      className="d-flex align-items-center justify-content-between users-inner-list"
                      style={{ height: '72px', maxHeight: '72px' }}>
                      <div className="d-flex align-items-center" style={{ width: '100%' }}>
                        <img
                          className="users-inner-image"
                          src={
                            'https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png'
                          }
                        />
                        {/* <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}> */}
                          <div className="users-inner-text">
                            <div className="user-inner-name">{item?.name}</div>
                          </div>
                        {/* </div> */}
                      </div>
                        
                          <Checkbox
                            name={`sideFilter-${item.value}`}
                            onChange={() => {
                              if (!selectedOption.some((val1) => val1?.value === item?.value)) {
                                setSelectedOption([
                                  ...selectedOption,
                                  { value: item.value, name: item.label },
                                ]);
                              } else {
                                setSelectedOption(
                                  selectedOption.filter((val) => val.value !== item.value)
                                );
                              }
                            }}
                            selected={selectedOption.some((val1) => val1?.value === item?.value)}
                            className={`form-check-input selectableCheckbox float-${props.checkboxLeft ? 'start' : 'end'
                              } `}
                            icon={
                              props.showIcon ? (
                                <div className="selectIconDiv d-inline p-0">
                                  {item.thumbnail ? <Image src={item.thumbnail} /> : <UserIcon />}
                                </div>
                              ) : null
                            }
                          />
                          {props.showDimensions && (
                            <div className="text-secondary float-end">
                              {item.resX} x {item.resY}
                            </div>
                          )}
                    </div>
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : null}
      </Table>
    </div>
  );
}
