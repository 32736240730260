import { ADMIN_CURATOR, PROJECT_RENDER } from '../config/constant/routePathConstant';
import pluralize from 'pluralize';

import {
  ADMIN_SETTINGS,
  MY_PROJECTS_ROUTE,
  NEW_DESIGN_ROUTE,
  RENDER_DETAILS,
} from '../constants/routePathConstants';
import { OPEN_PROJECT_ID } from '../config/constant/curatorConstants';
import { ROOM_TYPE__URL_SLUG_MAP } from '../constants/filters';
import { SETINGS_TAB } from '../constants/constants';

export const getProjectPath = ({ id }) => {
  return PROJECT_RENDER.replace(':projectId', id);
};

export const getCuratorPath = (id, type, cameraName) => {
  let item = pluralize.singular(type);
  let path = `${ADMIN_CURATOR}/${item}/${id}`;
  if (cameraName) {
    path += `?cameraName=${cameraName}`;
  }
  return path;
};

export const getCuratorRoomPath = (id, cameraName) => getCuratorPath(id, 'room', cameraName);
export const getCuratorProjectPath = (id) => getCuratorPath(id, 'project');

export const getCuratorRenderPreviewPath = (renderId, cameraName) => {
  let query = `?renderId=${renderId}`;
  if (cameraName) {
    query += `&cameraName=${cameraName}`;
  }
  return getCuratorPath(OPEN_PROJECT_ID, 'room') + query;
};

export function removeParams(path) {
  return path
    .split('/')
    .filter((x) => x.charAt(0) !== ':')
    .join('/');
}

export const getProjectsPath = () => {
  return MY_PROJECTS_ROUTE;
};

export const openCuratorRoom = (roomId, cameraName) => {
  window.open(getCuratorRoomPath(roomId, cameraName), '_blank');
};

export const getNewDesignPath = (roomType) => {
  const slug = ROOM_TYPE__URL_SLUG_MAP[roomType];
  return NEW_DESIGN_ROUTE.replace(':id', slug);
};

export const getRenderDetailPath = (renderId) => {
  return RENDER_DETAILS.replace(':id', renderId);
};

export const getTransactionsPath = () => `${ADMIN_SETTINGS}?activeTab=${SETINGS_TAB.TRANSACTIONS}`;
