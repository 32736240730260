import { createSlice } from '@reduxjs/toolkit';

const cameraEdit = createSlice({
  name: 'cameraEdit',
  initialState: {
    data: null,
  },
  reducers: {
    setCameraData: (state, { payload }) => {
      state.data = payload;
    },
    updateCameraData: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      }
    }
  },
});

export const {
  setCameraData,
  updateCameraData,
} = cameraEdit.actions;
export const cameraEditSelector = (state) => state.cameraEdit;
export const cameraEditReducer = cameraEdit.reducer;
export default cameraEditReducer;

export const selectCameraEditId = (state) => state.cameraEdit.data?.id;
export const selectCameraEditData = (state) => state.cameraEdit.data;