import _ from 'lodash';
import { useMemo, useState } from 'react';
import { Stack } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons } from '../assets';
import {
  ActionFilters,
  AddToCollections,
  BigButton,
  DeactivatePopup,
  MiniButton,
  ModalButtonArea,
  SharePopup,
  ThumbnailContainer,
  TopButtonArea,
} from '../common';
import AddToCollectionPopup from '../common/AddToCollectionPopup';
import { TextureDetailsModal } from '../common/TextureDetailsModal';
import { ThumbnailContainerV2 } from '../common/ThumbnailContainerV2';
// import CollectionIcon from '../components/icons/CollectionIcon';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import MyDropzone from '../components/MyDropzone';
import {
  EDIT_ICON,
  SHARE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  TRASH_ICON,
  MOVE_TO_TEXTURE_ICON,
} from '../constants/assetConstants';
import { MODEL_TYPES } from '../constants/constants';
import {
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
  MY_PRODUCTS_ROUTE,
} from '../constants/routePathConstants';
import {
  MAX_UPLOAD_HEIGHT_PIXELS,
  MAX_UPLOAD_WIDTH_PIXELS,
  MODE_EDIT,
  MODE_UPLOAD,
} from '../constants/textureConstants';
import { useUserContext } from '../contexts/UserContext';
import { apiBulkDeleteTextures, parseProductTypePathname } from '../helpers/api';
import { useScreenSettings } from '../helpers/configureHelper';
import { getImageDetails, roundToTwo } from '../helpers/jsHelper';
import { errorToast } from '../helpers/toastHelper';
import { usePageTitle } from '../hooks/usePageTitle';
import { useTextureActions } from '../hooks/useTextureActions';
import { useTextures } from '../hooks/useTextures';
import { Breadcrumbs, CommonGrid, TopContainer } from '../layouts';
import { getUploadButtons } from './MyProductsUploads';

const {
  BinIcon,
  FileUploadIcon,
  CollectionIcon,
  EditIcon,
  UploadIcon,
  DownloadIcon,
  MoveToTextureIcon,
  CloseIcon,
} = icons;

// TODO: move it to separate component
export const UploadTextureModal = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedFiles, setSelectedFiles] = useState([]);
  function isFilePresent(file) {
    if (selectedFiles.length === 0) return Promise.resolve(false);

    return Promise.all(selectedFiles.map((sf) => compare(sf.file, file))).then((res) => {
      const x = res.every((r) => r === false);
      return !x;
    });
  }

  function compare(file1, file2) {
    if (file1.size !== file2.size) return Promise.resolve(false);
    return Promise.all([readAsArrayBuffer(file1), readAsArrayBuffer(file2)]).then(
      ([buf1, buf2]) => {
        const arr1 = new Uint8Array(buf1);
        const arr2 = new Uint8Array(buf2);
        return !arr1.some(
          (val, i) => arr2[i] !== val // search for diffs
        );
      }
    );
  }

  function readAsArrayBuffer(file) {
    return new Response(file).arrayBuffer();
  }

  const handleFileChange = (files) => {
    files.forEach((file) => {
      isFilePresent(file).then((res) => {
        if (res === true) {
          errorToast('This file has already been uploaded');
          return;
        }

        var fileName = file.name.replace(/\.[^/.]+$/, '');
        const src = URL.createObjectURL(file);
        getImageDetails(src)
          .then((res) => {
            const { width, height, dpi } = res;
            if (width >= MAX_UPLOAD_WIDTH_PIXELS || height >= MAX_UPLOAD_HEIGHT_PIXELS) {
              URL.revokeObjectURL(src);
              errorToast('Size of image uploaded must be below 16000 x 16000 pixels');
              return;
            }

            const uploadFile = {
              id: `id-${new Date().getTime()}`,
              name: fileName,
              sku_id: '',
              width: roundToTwo(width / dpi),
              height: roundToTwo(height / dpi),
              rotation: 0,
              description: '',
              filteroptions: [],
              file: file,
              texture_file: src,
            };

            setSelectedFiles((prevFiles) => [uploadFile, ...prevFiles]);
          })
          .catch(() => errorToast(`Error while computing dimensions of image ${fileName}`));
      });
    });
  };

  const handleFileDelete = (fileId) => {
    const updatedFiles = selectedFiles.filter((x) => x.id !== fileId);
    setSelectedFiles(updatedFiles);
  };

  const handleFileImport = () => {
    navigate(ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD, {
      state: {
        textures: [...(location?.state?.textures || []), ...selectedFiles],
        mode: MODE_UPLOAD,
      },
    });
    setSelectedFiles([]);
    props?.setShowModal(false);
  };

  return (
    <ModalButtonArea
      {...props}
      size="md"
      centered={false}
      bigButton={true}
      variant="warning"
      buttonTitle={props.buttonTitle}
      iconBefore={props.iconBefore}
      onClose={() => setSelectedFiles([])}
      checkForPermission="UPLOAD_TEXTURE"
      className="texture-upload-modal"
      title={
        <>
          <p className="title">Import Textures</p>
          <p className="sub-title">Add files [ jpg/png &amp; max resolution 16k] </p>
        </>
      }
      body={
        <>
          <MyDropzone onDrop={handleFileChange} />
          {selectedFiles.length > 0 && (
            <Stack>
              <div
                style={{
                  maxHeight: 214,
                  overflowY: 'auto',
                  marginTop: '1.5rem',
                  marginBottom: '2rem',
                }}>
                <table className="bordered">
                  <tbody>
                    {selectedFiles.map((file, index) => (
                      <tr key={index}>
                        <td>
                          <span>{file.name}</span>
                          <span
                            onClick={() => handleFileDelete(file.id)}
                            style={{ cursor: 'pointer' }}>
                            <BinIcon />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <BigButton className="lf-btn-1" buttonTitle="Import" onClick={handleFileImport} />
            </Stack>
          )}
        </>
      }
    />
  );
};

const MyProductsTextures = ({
  cardType = 'textures',
  breadcrumbs,
  title = 'Textures',
  pageTitle = 'Textures - My Products',
  apiConfig,
  type = 'textures',
  modelType = MODEL_TYPES.TEXTURE,
  useGlobalSearch,
  noDataText,
  cardProps,
}) => {
  usePageTitle(pageTitle);

  const userCtx = useUserContext();
  const currentUser = userCtx && userCtx.currentUser;
  const userCurrentOrg = currentUser && currentUser.organization;
  const currentMemberId = currentUser && currentUser.id;

  const pageSettings = useScreenSettings(cardType);
  const { sortOptions, showFavoriteIcon, defaultSortOption, showTeamMemberFilter } = pageSettings;

  const requestParams = useMemo(
    () => ({
      created_by: currentMemberId,
      is_map: false,
      ...apiConfig?.requestParams,
    }),
    [currentMemberId, apiConfig?.requestParams]
  );

  const {
    list,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,

    selected,
    anySelected,
    allSelected,
    selectedList,
    sortBy,
    onSortChange,
    toggleAll,
    toggleItem,
    resetSelection,
    addToCollection,
    refetchList,
  } = useTextures({
    requestParams,
    defaultBrands: userCurrentOrg,
    defaultSort: defaultSortOption,
    useGlobalSearch,
    modelType,
  });

  const {
    loading: isLoading,
    handleEditClick,
    handleDownloadClick,
    handleUploadEditClick,
  } = useTextureActions({ list, modelType });

  // upload buttons
  const uploadsTopButtons = [
    {
      element: DeactivatePopup,
      bigButton: true,
      bigBtnClass: 'lf-btn-2',
      isPlural: true,
      item: cardType,
      actionWord: 'delete',
      deleteAction: apiBulkDeleteTextures,
      onSuccess: refetchList,
    },
    {
      // Import movetotexture icon here instead of MoveToTextureIcon
      element: BigButton,
      bigBtnClass: 'lf-btn-2',
      variant: 'secondary',
      buttonTitle: 'Move to textures',
      iconBefore: <MoveToTextureIcon />,
      onClick: () => handleUploadEditClick(selectedList),
    },
    {
      element: BigButton,
      bigBtnClass: 'lf-btn-1',
      buttonTitle: 'Download',
      iconBefore: <DownloadIcon />,
      onClick: () => handleDownloadClick(selectedList),
      isLoading: isLoading,
    },
  ];

  // textures top buttons
  const texturesTopButtons = [
    {
      element: DeactivatePopup,
      bigButton: true,
      bigBtnClass: 'lf-btn-3',
      isPlural: true,
      item: cardType,
      actionWord: 'delete',
      deleteAction: apiBulkDeleteTextures,
      onSuccess: refetchList,
    },
    {
      element: BigButton,
      bigBtnClass: 'lf-btn-2',
      variant: 'secondary',
      buttonTitle: 'Edit',
      iconBefore: <EditIcon />,
      onClick: () => handleEditClick(selectedList),
    },
    {
      element: BigButton,
      bigBtnClass: 'lf-btn-2',
      iconBefore: <CollectionIcon />,
      buttonTitle: 'Add To Collection',
      onClick: addToCollection,
    },
    {
      element: SharePopup,
      bigButton: true,
      bigBtnClass: 'lf-btn-2',
      onSuccess: refetchList,
      modelType: 2,
      selectedList,
    },
    {
      element: BigButton,
      bigBtnClass: 'lf-btn-1',
      buttonTitle: 'Download',
      iconBefore: <DownloadIcon />,
      onClick: () => handleDownloadClick(selectedList),
      isLoading: isLoading,
    },
  ];

  const topButtons = type === 'uploads' ? uploadsTopButtons : texturesTopButtons;

  breadcrumbs = breadcrumbs || [
    {
      link: MY_PRODUCTS_ROUTE,
      label: 'My products',
    },
    {
      label: `Textures`,
    },
  ];

  return (
    <>
      {!useGlobalSearch && <Breadcrumbs fullWidth list={breadcrumbs} />}
      <CommonGrid
        noDataText={noDataText}
        topContainer={
          !useGlobalSearch && (
            <TopContainer
              title={title}
              item={cardType}
              hasData={hasData}
              buttonArea={
                <TopButtonArea
                  hasData={hasData}
                  item={cardType}
                  topButtonList={topButtons}
                  addNewButtonArea={
                    type === 'uploads' ? null : (
                      <UploadTextureModal
                        buttonTitle="Upload Textures"
                        iconBefore={<UploadIcon />}
                      />
                    )
                  }
                  anySelected={anySelected}
                  selectedList={selectedList}
                />
              }
            />
          )
        }
        item={cardType}
        hasData={list.length > 0 || loading}
        list={list}
        filterArea={
          !useGlobalSearch && (
            <ActionFilters
              sortProps={{
                value: sortBy,
                data: sortOptions,
                onChange: onSortChange,
              }}
              selectedProps={{
                allSelected: allSelected,
                onSelectAll: () => toggleAll(),
              }}
              showTeamMemberFilter={showTeamMemberFilter}
            />
          )
        }>
        <InfiniteScroll
          dataLength={list.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loading={loading}
          hasChildren={hasData}
          scrollableTarget="infiniteScroll">
          <ThumbnailContainerV2
            items={list}
            modelType={modelType}
            selected={selected}
            cardProps={{
              fullWidth: true,
              onSelect: toggleItem,
              ...cardProps,
            }}
          />
        </InfiniteScroll>
      </CommonGrid>
      <AddToCollectionPopup onSuccess={resetSelection} />

      <TextureDetailsModal />
    </>
  );
};

export default MyProductsTextures;
