import { getProjectsApiV2 } from '../../../api/myprojects';
import {
  myprojectsActions,
  myprojectsSelector,
} from '../../../redux/slicers/my-projects/myProjectsSlicer';
import { useThumbnailCardDataList } from '../../../hooks/useDataList';
import { MODEL_TYPES } from '../../../constants/constants';

const useProjects = ({
  requestParams,
  filterByKey,
  defaultSort = '-created_at',
  useGlobalSearch,
  modelType = MODEL_TYPES.PROJECTS,
}) => {
  const data = useThumbnailCardDataList({
    selector: myprojectsSelector,
    resetAction: myprojectsActions.resetList,
    getAction: getProjectsApiV2,
    itemDeleteText: 'Are you sure you want to delete this project?',
    itemsDeleteText: (selectedIds) =>
      `Are you sure you want to delete ${selectedIds.length} projects?`,
    defaultSort,
    requestParams,
    filterByKey,
    useGlobalFilter: true,
    useGlobalSearch,
    hookName: 'useProjects',
    modelType,
  });

  return data;
};

export default useProjects;
