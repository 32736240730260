import { COLLECTION_MODEL_TYPE__MODEL_TYPE__MAP, MODEL_TYPES } from '../constants/constants';
import { ThumbnailCardCollection } from './ThumbnailCardCollection';
import { ThumbnailCardModel3D, ThumbnailCardProduct } from './ThumbnailCardProduct';
import { ThumbnailCardProject } from './ThumbnailCardProject';
import { ThumbnailCardRenderPreviews } from './ThumbnailCardRenderPreviews';
import { ThumbnailCardRoom, ThumbnailCardTemplate } from './ThumbnailCardTemplate';
import { ThumbnailCardTexture, ThumbnailCardUpload } from './ThumbnailCardTexture';
import styles from './ThumbnailContainerV2.module.scss';

export const CARD_COMPONENT_MAP = {
  [MODEL_TYPES.PROJECTS]: ThumbnailCardProject,
  [MODEL_TYPES.TEMPLATES]: ThumbnailCardTemplate,
  [MODEL_TYPES.ROOM]: ThumbnailCardRoom,
  [MODEL_TYPES.PRODUCT]: ThumbnailCardProduct,
  [MODEL_TYPES.THREEDMODELS]: ThumbnailCardModel3D,
  [MODEL_TYPES.TEXTURE]: ThumbnailCardTexture,
  [MODEL_TYPES.UPLOADS]: ThumbnailCardUpload,
  [MODEL_TYPES.COLLECTION]: ThumbnailCardCollection,
  [MODEL_TYPES.PREVIEWS]: ThumbnailCardRenderPreviews,
  [MODEL_TYPES.PROJECTRENDER]: ThumbnailCardRenderPreviews,
};

export const ThumbnailContainerV2 = ({
  items,
  modelType,
  selected = [],
  cardProps: { fullWidth, onSelect, onEdit, hideFavourite, customActions, onDelete } = {},
  onlyItems,
  Component,
  isCollectionItem, // for collection model types different from all other model types :( -> we need to map them to normal model types
}) => {
  // if (!Component) throw new Error('Card type is not supoprted');

  const renderedList = items.map((item) => {
    let itemModelType;
    if (isCollectionItem) {
      itemModelType = COLLECTION_MODEL_TYPE__MODEL_TYPE__MAP[item.model_type];
    } else {
      itemModelType = modelType || item.model_type // // model_type will be if we have shared files
    }

    let ItemComponent = Component || CARD_COMPONENT_MAP[itemModelType];

    if (!ItemComponent) return null;
    
    const data = item.model_type ? item.data : item;
    const key = item.model_type ? item.id : data.id
    let additionalData;
    if (item.data) {
      additionalData = { date: item.created_at };
    }

    return (
      <ItemComponent
        key={key}
        data={data}
        originalData={item}
        additionalData={additionalData}
        selected={selected.includes(item.id)}
        fullWidth={fullWidth}
        onSelect={onSelect}
        onEdit={onEdit}
        hideFavourite={hideFavourite}
        customActions={customActions}
        onDelete={onDelete}
      />
    );
  })

  if (onlyItems) {
    return renderedList
  }

  return (
    <div className={styles.grid}>
      {renderedList}
    </div>
  );
};
