import { createPaginatedSlice } from "../../helpers/reduxHelper";

const transactionHistory = createPaginatedSlice('transactionHistory', {
  pagination: 'normal',
});

export const transactionHistoryActions = transactionHistory.actions
export const transactionHistorySelector = state => state.transactionHistory;
export const transactionHistoryReducer = transactionHistory.reducer;
export default transactionHistoryReducer

export const transactionHistorySelectors = transactionHistory.LF.selectors