import { isCameraValid } from '../components/admin/curator/components/camera/useCameraActions';
import { CURSOR_MODE } from '../config/constant/unityConstants';
import { CURATOR_MENU_SECTION } from '../redux/slicers/admin/curatorMenuPreferencesSlicer';
import {
  curatorActions,
  selectCursorMode,
  selectIs2DMode,
  selectSidebarSection,
  setSelectedResolutionPreset,
} from '../redux/slicers/admin/curatorSlicer';
import {
  selectExpertMode,
  selectIsAnyObjectSelected,
  selectIsStyleableObject,
  selectSelectedObjectName,
  setExpertMode,
} from '../redux/slicers/admin/curatorUnityObjectSlicer';
import {
  selectPopupOpen,
  shortcutsModuleActions,
} from '../redux/slicers/admin/shortcutsModuleSlicer';
import { selectCameraEditData } from '../redux/slicers/camera/cameraEditSlicer';
import { setGlobalHeight, setGlobalSafeCameraFlag, setGlobalWidth } from '../redux/slicers/camera/cameraSettings';
import { selectIsCameraEditing } from '../redux/slicers/camera/projectCameraAngleSlicer';
import { getAppState } from '../redux/store';
import { dispatcher } from './projectHelper';
import { errorToast } from './toastHelper';

export const isShortcutsOpen = () => selectPopupOpen(getAppState());

export const isCurrentCameraValid = ({ showToasts = true } = {}) => {
  const isCameraEditing = selectIsCameraEditing(getAppState());
  if (isCameraEditing) {
    const camera = selectCameraEditData(getAppState());
    return isCameraValid(camera, { showToasts });
  }

  return true;
};

export const toggleSidebarSection = (sectionName) => {
  if (!isCurrentCameraValid()) return false;

  // check if style section can be opened
  if (sectionName === CURATOR_MENU_SECTION.STYLE) {
    const valid = validateStyleable();
    if (!valid) return false;
  }

  // check if camera is possible to open
  if (sectionName === CURATOR_MENU_SECTION.CAMERAS && selectIs2DMode(getAppState())) {
    errorToast('Please switch to 3d mode to use cameras');
    return false;
  }

  dispatcher(curatorActions.toggleSidebarSection(sectionName));

  if (sectionName && isShortcutsOpen()) {
    dispatcher(shortcutsModuleActions.setPopupOpen(false));
  }

  return true;
};

export const openSidebarSection = (sectionName) => {
  const currentSection = selectSidebarSection(getAppState());
  if (currentSection !== sectionName) {
    return toggleSidebarSection(sectionName);
  }
};

export const clearUI = () => {
  dispatcher(shortcutsModuleActions.setPopupOpen(false));
  if (isCurrentCameraValid({ showToasts: false })) {
    dispatcher(curatorActions.toggleSidebarSection(null));
  }
};

export const validateStyleable = () => {
  const isStyleable = selectIsStyleableObject(getAppState());
  const anyObjectSelected = selectIsAnyObjectSelected(getAppState());

  if (!anyObjectSelected) {
    errorToast('Please select an object', { toastId: 'OBJECT_SELECTION_MISSING' });
    return false;
  }

  if (!isStyleable) {
    errorToast('You can not style this object', { toastId: 'NOT_STYLEABLE_OBJECT' });
    return false;
  }

  return true;
};

export const toggleExpertMode = ({ enabled, objectName } = {}) => {
  if (!objectName) {
    objectName = selectSelectedObjectName(getAppState());
  }

  if (typeof enabled === 'undefined') {
    const expertMode = selectExpertMode(getAppState(), objectName);
    enabled = !expertMode;
  }

  dispatcher(
    setExpertMode({
      objectName,
      enabled,
    })
  );
};

export const canChangeSelectedAxis = (state, { showWarnings } = {}) => {
  state = state || getAppState();
  const cursorMode = selectCursorMode(state);
  const anyObjectSelected = selectIsAnyObjectSelected(state);

  if (cursorMode === CURSOR_MODE.STYLE_ONLY) {
    showWarnings &&
      errorToast('It is not possible to change selected axis while being in style only mode');
    return false;
  }

  if (!anyObjectSelected) {
    showWarnings && errorToast('Please select an object');
    return false;
  }

  return true;
};

export const selectSettingsResolutionPreset = (presetItem, enableSafeCameraFrame = false) => {
  dispatcher(setSelectedResolutionPreset(presetItem.id));
  dispatcher(setGlobalWidth(presetItem.width));
  dispatcher(setGlobalHeight(presetItem.height));

  if (enableSafeCameraFrame) {
    dispatcher(setGlobalSafeCameraFlag(true));
  }
};

