import _ from 'lodash';
import { DeactivatePopup, MiniButton } from '../common';
import GridSection from '../common/GridSectionNew';
import { ADMIN_MY_PRODUCTS_UPLOADS, MY_PRODUCTS_ROUTE } from '../constants/routePathConstants';
import { apiBulkDeleteTextures, parseBasePathname, parseProductTypePathname } from '../helpers/api';
import { DOWNLOAD_ICON, TRASH_ICON, MOVE_TO_TEXTURE_ICON } from '../constants/assetConstants';
import MyProductsTextures from './MyProductsTextures';
import { useTextureActions } from '../hooks/useTextureActions';
import { useUploads } from '../hooks/useTextures';
import { MODEL_TYPES } from '../constants/constants';

const requestParams = {
  is_map: true,
};

const MyProductsUploads = ({
  cardType = 'uploads',
  carouselView,
  title,
  useGlobalSearch,
  noDataText,
  modelType = MODEL_TYPES.UPLOADS,
  cardProps,
}) => {
  const breadcrumbs = [
    {
      link: MY_PRODUCTS_ROUTE,
      label: 'My products',
    },
    {
      label: `Uploads`,
    },
  ];

  if (carouselView) {
    return <CarouselView title={title} />;
  }

  return (
    <MyProductsTextures
      cardType={cardType}
      breadcrumbs={breadcrumbs}
      title="Uploads"
      pageTitle="Uploads"
      type="uploads"
      useGlobalSearch={useGlobalSearch}
      noDataText={noDataText}
      modelType={modelType}
      cardProps={cardProps}
      apiConfig={{
        requestParams,
      }}
    />
  );
};

const CarouselView = ({ title }) => {
  const { list, loading } = useUploads();

  return (
    <GridSection
      title={title}
      seeAllLink={ADMIN_MY_PRODUCTS_UPLOADS}
      carouselData={list}
      modelType={MODEL_TYPES.UPLOADS}
      loading={loading}
      emptyDisplay={<h4>Find curator maps here.</h4>}
    />
  );
};

export default MyProductsUploads;
