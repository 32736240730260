import { useLocation } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { CommonGrid, TopContainer } from '../layouts';
import {
  BigButton,
  TopButtonArea,
  ModalButtonArea,
  DesignTemplates,
  ActionFilters,
  GlobalFilter,
  MiniButton,
  Download,
  SharePopup,
  DeactivatePopup,
} from '../common';
import _ from 'lodash';
import { icons } from '../assets';
import { handleDownloadURL, setTitle } from '../helpers/jsHelper';
import { apiDeleteProjects, apiGetDownloadProjectData, apiGetDownloadProject } from '../helpers/api';
import { useScreenSettings } from '../helpers/configureHelper';
import {
  EDIT_ICON,
  SHARE_ICON,
  DELETE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
} from '../constants/assetConstants';
import { getCuratorPath } from '../helpers/urlHelper';
import Button from '../components/Button/Button';
import FileInput from '../components/FileInput/FileInput';
import { useOpenProjectFromFile } from '../components/admin/curator/CuratorHeader/useOpenProjectFromFile';
import useProjects from '../pages/admin/myprojects/useProjects';
import { useUserContext } from '../contexts/UserContext';
import download from 'downloadjs';
import { useFilterContext } from '../contexts/FilterContext';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import { EditProjectPopup } from '../common/EditProjectPopup/EditProjectPopup';
import { myprojectsActions } from '../redux/slicers/my-projects/myProjectsSlicer';
import { useDispatch } from 'react-redux';
import { errorToast } from "../helpers/toastHelper";
import { CARD_TYPE, MODEL_TYPES } from '../constants/constants';
import { ThumbnailContainerV2 } from '../common/ThumbnailContainerV2';
import AddToCollectionPopup from '../common/AddToCollectionPopup';
// import { COLLECTIONNEW_ICON } from '../config/constant/assetConstant';
// import CollectionIcon from '../components/icons/CollectionIcon';
// import { addToCollectionActions } from '../redux/slicers/addToCollectionPopupSlicer';

const { ProjectIcon, CollectionIcon, DownloadIcon, InfoIcon } = icons;
let title = 'My projects';

export default function MyProjects({
  cardType = CARD_TYPE.PROJECT,
  productId = '',
  useGlobalSearch,
  noDataText,
  cardProps,
}) {
  const dispatch = useDispatch();
  const filterCtx = useFilterContext();
  const { currentUser } = useUserContext();
  const currentMemberId = currentUser.id;
  const [editItem, setEditItem] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false)

  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    useScreenSettings(cardType);

  useEffect(() => {
    setTitle(title);
    filterCtx.setBreadcrumbs(null);
  }, []);

  const requestParams = useMemo(
    () => ({
      created_by: currentMemberId,
      ...(productId && { product: productId }),
    }),
    [currentMemberId, productId]
  );

  const {
    list,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,

    anySelected,
    allSelected,
    selectedList,
    sortBy,
    filterBy,
    selected,
    onSortChange,
    onFilterByChange,
    refetchList,
    toggleAll,
    toggleItem,
    resetSelection,
    addToCollection,
  } = useProjects({
    requestParams,
    filterByKey: 'project_type',
    defaultSort: defaultSortOption,
    useGlobalSearch,
  });

  const onEdit = async (id) => {
    const itemToEdit = list.find((p) => p.id === id);
    setEditItem(itemToEdit);
  };

  const customActions = { 
    EDIT: { label: 'Info', icon: <InfoIcon />, onClick: onEdit }
  }

  async function handleDownloadClick(id) {
    setIsDownloading(true)
    const formData = new FormData();
    if (id) {
        formData.append("id", id);
    } else {
        selected.forEach((id) => {
            formData.append("id", id);
        })
    }
    const res = await apiGetDownloadProject(formData);
    console.log("res?.data?.zip_file", res)
    if (res.status) {
        res?.data?.results?.forEach((item) => {
            handleDownloadURL(item?.zip_url, item?.name);
        });
    } else {
        errorToast(res?.message);
    }
    setIsDownloading(false)
  }

  const handleProjectUpdate = (newData) => {
    dispatch(myprojectsActions.update(newData));
    setEditItem(newData);
  };

  return (
    <CommonGrid
      noDataText={noDataText}
      topContainer={
        <TopContainer
          title={
            !useGlobalSearch && <>
              {title} {total >= 1 ? <small>({total})</small> : null}
            </>
          }
          item={cardType}
          hasData={hasData}
          buttonArea={
            !useGlobalSearch && (
              <TopButtonArea
                hasData={hasData}
                item={cardType}
                topButtonList={[
                  {
                    element: DeactivatePopup,
                    bigButton: true,
                    bigBtnClass: 'lf-btn-3',
                    isPlural: true,
                    iconSrc: DELETE_ICON,
                    deleteAction: apiDeleteProjects,
                    item: cardType,
                    onSuccess: refetchList,
                    actionWord: 'delete',
                  },
                  {
                    element: BigButton,
                    bigBtnClass: 'lf-btn-2',
                    iconBefore:  <CollectionIcon />,
                    buttonTitle: 'Add To Collection',
                    onClick: addToCollection,
                  },
                  {
                    element: SharePopup,
                    bigButton: true,
                    bigBtnClass: 'lf-btn-2',
                    onSuccess: refetchList,
                    selectedList,
                    modelType: 4,
                  },
                  {
                    element: BigButton,
                    bigBtnClass: 'lf-btn-1',
                    isLoading: isDownloading,
                    buttonTitle: 'Download',
                    iconBefore: <DownloadIcon />,
                    onClick: handleDownloadClick,
                  },
                ]}
                addNewButtonArea={
                  !useGlobalSearch && (
                    <ModalButtonArea
                      bigButton={true}
                      bigBtnClass="lf-btn-1"
                      variant="warning"
                      buttonTitle="New Project"
                      className="new-project"
                      buttonIcon={ProjectIcon}
                      title={
                        <>
                          <h2 className="pg-title">What type of project do you want to design?</h2>
                          <h5>Select one of the ones you see down below.</h5>
                        </>
                      }
                      size="lg"
                      body={<NewProjectModalBody />}
                    />
                  )
                }
                anySelected={anySelected}
                selectedList={selectedList}
              />
            )
          }
        />
      }
      item={cardType}
      hasData={list.length > 0 || loading}
      list={list}
      filterArea={
        !useGlobalSearch && (
          <>
            <ActionFilters
              hasData={hasData}
              filterProps={{
                value: filterBy,
                data: filterOptions,
                onChange: onFilterByChange,
              }}
              sortProps={{
                value: sortBy,
                data: sortOptions,
                onChange: onSortChange,
              }}
              selectedProps={{
                allSelected: allSelected,
                onSelectAll: () => toggleAll(),
              }}
              showTeamMemberFilter={showTeamMemberFilter}
            />
            <GlobalFilter />
          </>
        )
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainerV2 
          items={list}
          modelType={MODEL_TYPES.PROJECTS}
          selected={selected}
          cardProps={{
            fullWidth: true,
            onSelect: toggleItem,
            customActions,
            ...cardProps,
          }}
        />
        {hasMore && <div id="whiteShadow" />}
      </InfiniteScroll>

      <EditProjectPopup
        data={editItem}
        show={Boolean(editItem)}
        onClose={() => setEditItem(null)}
        onUpdateSuccess={handleProjectUpdate}
      />
      <AddToCollectionPopup onSuccess={resetSelection} />
    </CommonGrid>
  );
}

const NewProjectModalBody = () => {
  const { openProjectFromFile } = useOpenProjectFromFile();

  return (
    <>
      <div className="d-flex spaceEvenly fullWidth pb-2 mb-0">
        <FileInput
          Component={Button}
          componentProps={{
            as: 'label',
          }}
          size="xlg"
          color="warning"
          onFileSelect={(file) => openProjectFromFile(file)}
          accept=".zip">
          Open Project
        </FileInput>
      </div>
      <DesignTemplates center />
    </>
  );
};
