import { deleteCollectionAPI, updateCollectionApi } from '../api/collections';
import { MODEL_TYPES } from '../constants/constants';
import { collectionsSelectors } from '../redux/slicers/collectionsSlicer';
import { useModelActions } from './useModelActions';

export const useCollectionActions = ({ item }) => {
  const { favouriteLoading, onFavouriteToggle, onShare, onDelete } = useModelActions({
    item,
    modelType: MODEL_TYPES.COLLECTION,
    selectors: collectionsSelectors,
    updateApi: updateCollectionApi,
    deleteConfig: {
      title: 'Are you sure you want to delete this collection?',
      deleteApi: deleteCollectionAPI,
    }
  });

  return {
    favouriteLoading,
    onFavouriteToggle,
    onShare,
    onDelete,
  };
};


