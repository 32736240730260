import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from 'react-use';
import {
  TEXTURE_MAP_TO_TILING_TYPE,
  TILING_MAP,
} from '../../../../../config/constant/unityConstants';
import { addDefaultMaterial, getDefaultMaterial } from '../../../../../helpers/idb';
import { base64ToBlob } from '../../../../../helpers/jsHelper';
import { setUnityObjectImagesProcessing } from '../../../../../redux/slicers/admin/curatorLoaderSlicer';
import { initialState, selectUuid } from '../../../../../redux/slicers/admin/curatorSlicer';
import {
  selectIsStyleableObject,
  selectSelectedObjectMaterialId,
  selectSelectedObjectName,
  selectUnitySelectedObjectInfoRaw,
} from '../../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { getAppState } from '../../../../../redux/store';
import { useUnityContext } from '../../../../container/unityContainer';

export const useLoadDefaultTextures = () => {
  const { materialModule } = useUnityContext();

  const loadDefaultTextures = async ({ object, materialId }) => {
    const textureNameList = [
      object.diffuseName,
      object.normalMapName,
      object.aoMapName,
      object.roughnessMapName,
      object.metallicMapName,
      object.transparencyMapName,
    ].filter(Boolean);


    const textureDataList = textureNameList.map(textureName => {
      const key = getDefaultTextureKey({ materialId, textureName });
      const textureData = defaultTexures[key];
      
      if (!textureData) return;
      
      return { key, textureName, data: textureData };
    }).filter(Boolean)

    const hasDataForAllTextures = textureDataList.length === textureNameList.length

    if (!hasDataForAllTextures) {
      // load data from unity
      setUnityObjectImagesProcessing(true);
      setTimeout(materialModule.OnCallBase64ImageDataReceiver, 10); // small timeout as without it unity blocks javascript and loader does not have time to appear
      return;
    }

    // load data from cache -> simulates loading data from unity
    await Promise.all(
      textureDataList.map(async ({ key, textureName, data }) => {
        window.ReceiveBase64String(
          data.materialId,
          object.objectName,
          'TRIGGERED_FROM_REACT', // data.mapType,
          '', // this should be imageUrl but for now let's not send it as it seems to be working without it
          textureName,
        );
      })
    );
  };

  return loadDefaultTextures;
};

export const useGetDefaultObjectMaps = ({ active }) => {
  // requests default object images from unity, we should fetch it before applying any map/texture
  const isStyleableObject = useSelector(selectIsStyleableObject);
  const objectName = useSelector(selectSelectedObjectName);
  const materialId = useSelector(selectSelectedObjectMaterialId);
  const loadDefaultTextures = useLoadDefaultTextures();
  const prevObjectName = usePrevious(objectName);

  useEffect(() => {
    if (!prevObjectName) return;
    // delte object urls to free memory -> next time we'll get it from indexdb
    const keyBase = getDefaultTextureKeyBase(prevObjectName);
    const keys = Object.keys(defaultTexures).filter((key) => key.startsWith(keyBase));

    keys.forEach((key) => {
      const data = defaultTexures[key];
      URL.revokeObjectURL(data.imageUrl);
      defaultTexures[key].imageUrl = undefined;
    });
  }, [objectName]);

  useEffect(() => {
    if (!active) return;
    if (!isStyleableObject) return;
    const object = selectUnitySelectedObjectInfoRaw(getAppState())
    loadDefaultTextures({ object, materialId });
  }, [objectName, materialId, active]);
};

export const defaultTexures = {}; // map { [`objectName_mapType`]: [...args] }
window.defaultTexures = defaultTexures;

export const getDefaultTextureFromIdb = ({ textureName, materialId }) => {
  const key = getDefaultTextureKey({ textureName, materialId });
  return getDefaultMaterial(key);
}

export const getDefaultTexture = async ({ textureName, materialId }) => {
  const key = getDefaultTextureKey({ textureName, materialId });

  if (defaultTexures[key] && !defaultTexures[key]?.imageUrl) {
    const imageData = await getDefaultMaterial(key);
    defaultTexures[key].imageUrl = URL.createObjectURL(imageData.file);
  }

  return defaultTexures[key];
};

export const getDefaultTextureImageUrl = async ({
  textureName,
  materialId,
}) => {
  const data = await getDefaultTexture({ textureName, materialId });
  return data?.imageUrl;
};

export const cacheDefaultTexture = async ({
  materialId,
  textureName,
  imageData,
}) => {
  const key = getDefaultTextureKey({ materialId, textureName });

  let imageUrl;
  if (!defaultTexures[key]) {
    const imageBlob = base64ToBlob({
      imageString: imageData,
    });

    await addDefaultMaterial({
      file: imageBlob,
      key,
      materialId,
      textureName,
    });
    imageUrl = URL.createObjectURL(imageBlob);
  } else {
    const imageData = await getDefaultMaterial(key);
    imageUrl = URL.createObjectURL(imageData.file);
  }

  defaultTexures[key] = {
    imageUrl,
    textureName,
    materialId,
  }; // store data, we will use it next time instead of calling unity as unity blocks thread when it generates images

  return defaultTexures[key];
};

export const getDefaultTextureKey = ({ materialId, textureName }) => {
  const prefix = getDefaultTextureKeyPrefix({ materialId });
  const key = `${prefix}__${textureName || 'NONAME'}`;
  return key;
};

export const getDefaultTextureKeyPrefix = ({ materialId }) => {
  const base = getDefaultTextureKeyBase();
  materialId = materialId || 'DEFAULT';
  return `${base}__${materialId}`;
};

export const getDefaultTextureKeyBase = () => {
  const uuid = initialState.uuid;
  return `${uuid}__`;
};

export const getKeysByUuid = (uuid) => {
  return Object.keys(defaultTexures).filter((key) => key.startsWith(uuid));
};
