import { useThumbnailCardDataList } from './useDataList';
import {
  sharedFilesPageActions,
  sharedFilesPageSelector,
} from '../redux/slicers/sharedFilesPageSlicer';
import { getSharedFilesPageListApi } from '../api/share/share';
import { useEffect, useMemo } from 'react';
import { With_Me_Value, With_Organisation_Value } from '../constants/filters';
import { useUserContext } from '../contexts/UserContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSharedFilter,
  selectSelectedMembersBy,
  selectSelectedMembersTo,
  selectSharedTo,
} from '../redux/slicers/filters/filterSlicer';
import { isAdminOrOwner } from '../helpers/userHelper';

export const useSharedFiles = ({
  requestParams,
  filterByKey,
  defaultBrands,
  defaultSort = '-created_at',
  defaultFilterBy,
  defaultCreatedBy,
  useGlobalSearch,
  api,
} = {}) => {
  const dispatch = useDispatch();
  const { currentUser } = useUserContext();
  const sharedTo = useSelector(selectSharedTo);
  const selectedMembersBy = useSelector(selectSelectedMembersBy);
  const selectedMembersTo = useSelector(selectSelectedMembersTo);

  useEffect(() => {
    return () => {
      dispatch(resetSharedFilter())
    }
  }, [])
  

  const requestParamsCombined = useMemo(() => {
    let shared_to = '';
    let shared_to_org = '';

    if (sharedTo === With_Me_Value) {
      shared_to = isAdminOrOwner(currentUser)
        ? selectedMembersTo?.map((item) => item?.id)?.join(',')
        : currentUser.id;
    } else if (sharedTo === With_Organisation_Value) {
      shared_to_org = currentUser.organization;
    }

    return {
      shared_to,
      shared_to_org,
      shared_by: selectedMembersBy.map((item) => item.id).join(','),
      ...requestParams,
    };
  }, [requestParams, sharedTo, selectedMembersBy, selectedMembersTo]);

  const data = useThumbnailCardDataList({
    selector: sharedFilesPageSelector,
    resetAction: sharedFilesPageActions.resetList,
    getAction: api || getSharedFilesPageListApi,
    requestParams: requestParamsCombined,
    filterByKey,
    defaultBrands,
    defaultSort,
    defaultFilterBy,
    useGlobalFilter: true,
    hookName: 'useRooms',
    defaultCreatedBy,
    useGlobalSearch,
  });

  return data;
};
