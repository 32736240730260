import axios from 'axios';
import { AUTH_TOKEN, NETWORK_ERROR } from '../constants/projectConstants';
import { devConsoleLog, getLocalStorage } from './jsHelper';
import { sessionDestroy } from './projectHelper.js';
import { errorToast, infoToast, warningToast } from './toastHelper.js';
import { API_ROOT_URL, GET_GLOBAL_TAGS } from '../helpers/api';
import _ from 'lodash';

class ApiClass {
  _url = '';
  _data = {};
  _method = '';
  _badRequest = null;
  _authFail = null;
  _accessDenied = null;
  _notFound = null;
  _serverError = null;
  _success = null;
  _error = null;
  _query = null;
  _progress = null;
  _api_root = null;
  _headers = {
    'Content-Type': 'application/json',
  };
  _responseType = null;
  setHeaders = (header) => {
    this._headers = { ...this._headers, ...header };
    return this;
  };
  root = (root) => {
    this._api_root = root;
    return this;
  };
  get = (path) => {
    this._method = 'GET';
    this._url = this._api_root + path;
    return this;
  };

  getBlob = (path) => {
    this._method = 'GET';
    this._url = this._api_root + path;
    this._headers = { 'Content-type': 'text/plain' };
    this._responseType = 'arraybuffer';
    this._data = undefined;
    this._progress = undefined;
    return this;
  };

  postBlob = (path, payload) => {
    this._method = 'POST';
    this._url = this._api_root + path;
    this._headers = { 'Content-type': 'application/json' };
    this._responseType = 'arraybuffer';
    this._data = payload;
    this._progress = undefined;
    return this;
  };

  getForm = (path) => {
    this._method = 'GET';
    this._url = this._api_root + path;
    this._headers = { 'Content-Type': 'application/json' };
    return this;
  };

  post = (path) => {
    this._method = 'POST';
    this._url = this._api_root + path;
    return this;
  };
  put = (path) => {
    this._method = 'PUT';
    this._url = this._api_root + path;
    return this;
  };
  patch = (path) => {
    this._method = 'PATCH';
    this._url = this._api_root + path;
    return this;
  };
  onUploadProgress = (callback = null) => {
    this._progress = callback;
    return this;
  };
  delete = (path) => {
    this._method = 'DELETE';
    this._url = this._api_root + path;
    return this;
  };
  success = (callback = null) => {
    this._success = callback;
    return this;
  };
  error = (callback = null) => {
    this._error = callback;
    return this;
  };

  finally = (callback = null) => {
    this._finally = callback;
    return this;
  };

  badRequest400 = (callback = null) => {
    this._badRequest = callback;
    return this;
  };

  authFail401 = (callback = null) => {
    this._authFail = callback;
    return this;
  };

  accessDenied403 = (callback = null) => {
    this._accessDenied = callback;
    return this;
  };

  notFound404 = (callback = null) => {
    this._notFound = callback;
    return this;
  };

  serverErr500 = (callback = null) => {
    this._serverError = callback;
    return this;
  };

  data = (a) => {
    if (this._query) {
      this._data['variables'] = a;
    } else {
      this._data = a;
    }
    return this;
  };
  upload = (callback = null) => {
    this._headers = {
      'Content-type': 'multipart/form-data',
    };
    return this.send(callback);
  };
  send = async (callback = null) => {
    if (!this._api_root) {
      throw new Error('root path missing');
    }
    const token = getLocalStorage(AUTH_TOKEN);
    let res = null;
    let err = null;
    const result = await axios({
      method: this._method,
      url: this._url,
      data: this._data,
      responseType: this._responseType,
      headers: { ...this._headers, Authorization: `Bearer ${token}` },
      onUploadProgress: this._progress,
    })
      .then((r) => {
        res = r;
        if (r?.data?.status || r?.data?.status === undefined) {
          this._success?.call(this, res.data);
        } else {
          // throw { response: { data: res.data } }; TODO: THIS LINE IS SCREWING UP MY API CALLS WE HAVE TO FIND A BETTER WAY TO HANDLE THIS
        }
        return res.data;
      })
      .catch((e) => {
        err = e;
        if (!err?.response && err.toString().includes(NETWORK_ERROR)) {
          infoToast('Network Error', { toastId: 'NETWORK_ERROR' });
          devConsoleLog('Network error. Please check you internet and try once again');
          return;
        }
        const data = err?.response?.data ?? {};
        const { message: msg = '' } = data;
        const { status } = e?.response ?? {};
        let errorExec = true;

        switch (status) {
          case 400:
            this._badRequest?.call(this, data);
            if (!this._badRequest) {
              try {
                if (_.isString(msg)) {
                  errorToast(msg);
                  break;
                } else {
                  const { non_field_errors } = msg;
                  if (_.isString(non_field_errors)) {
                    errorToast(non_field_errors);
                    break;
                  }
                  console.log('may need to add additional destructured keys to 400 requests', msg);
                }
              } catch {
                console.log('need to add additional destructured keys to 400 requests', msg);
              }
            }
            break;
          case 401: //session fail or expiry
            this._authFail?.call(this, data);
            if (!this._authFail) {
              errorToast('Authentication failed', { toastId: '401_ERROR' });
              sessionDestroy();
              errorExec = false;
            }
            break;
          case 403: //session ok but access prevent
            this._accessDenied?.call(this, data);
            if (!this._accessDenied) {
              warningToast('Permission Denied', { toastId: '403_ERROR' });
            }
            errorExec = false;
            break;
          case 404: //not found path
            this._notFound?.call(this, data);
            if (!this._notFound) {
              errorToast('Request Not Found', { toastId: '404_ERROR' });
            }
            errorExec = false;
            break;
          case 500: //internal server error
            this._serverError?.call(this, data);
            if (!this._serverError) {
              errorToast('Internal Server Error', { toastId: '500_ERROR' });
              this._error?.call(this, err?.response?.data ?? {});
            }
            errorExec = false;
            break;
          default:
            break;
        }

        if (this._error && errorExec) {
          this._error?.call(this, err?.response?.data ?? {});
        }
      })
      .finally(() => {
        this._finally?.();
      });
    if (callback && (res || err?.response)) {
      callback?.call(this, err?.response, err?.response?.status, res);
    }
    return result;
  };
}
const api = () => new ApiClass();

const MAX_CACHE_SERVE_COUNT = 20;
let cache = {
  global_tags: { list: null, serve_count: 0 },
};

function cachedList(urlPath, key = null) {
  if (key === null) key = urlPath;
  if (cache[key].list !== null && cache[key].serve_count < MAX_CACHE_SERVE_COUNT) {
    cache[key].serve_count += 1;
    return new Promise((resolve, reject) => {
      resolve(cache[key].list);
    });
  }
  return apiFetch(urlPath)
    .send()
    .then((response) => {
      cache[key].list = response.results;
      cache[key].serve_count = 0;
      return new Promise((resolve, reject) => {
        resolve(cache[key].list);
      });
    });
}

export const getJson = async (url) => {
  const res = await fetch(url);
  return res.json();
};

export const apiFetch = (url) => {
  return api().root(API_ROOT_URL).get(url);
};
export const apiPost = (url, payload) => {
  return api().root(API_ROOT_URL).post(url).data(payload);
};
export const apiGetCachedList = async (key) => {
  let URL = '';
  switch (key) {
    case 'global_tags':
      URL = GET_GLOBAL_TAGS;
      break;
    default:
      break;
  }
  let results = cachedList(URL, key);
  // double checking it's not an unresolved promise since these are somewhat nested
  if (results && _.isFunction(results.then)) {
    await results.then((res) => {
      results = res;
    });
  }
  results = _.map(results, (l) => {
    l.value = l.id;
    l.label = l.name;
    return l;
  });
  return results;
};
export const apiErrorHandler = (error, label = null) => {
  const { message } = error;
  console.log(label ? `Error at ${label}:` : 'apiErrorHandler:', error);
  errorToast(typeof(message) === "string" ? message : message?.detail)
};
export default api;
