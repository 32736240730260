import { ROOM_TYPE } from "../constants/roomConstants"

export const getProjectTypeLabel = (projectType) => {
  const map = {
    [ROOM_TYPE.ROOM]: 'Room Template',
    [ROOM_TYPE.LIFESTYLE]: 'Lifestyle',
    [ROOM_TYPE.SILO]: 'Silo',
    [ROOM_TYPE.VIGNETTE]: 'Vignette',
    [ROOM_TYPE.THREESIXTY]: '360 Web Viewer',
    [ROOM_TYPE.VIDEO]: 'Video',
    [ROOM_TYPE.CONFIGURATOR]: 'Configurator', 
    [ROOM_TYPE.AUGMENTED_REALITY]: 'Augumented Reality',
  }
  return map[projectType]
}