import { Link } from 'react-router-dom';
import { getCuratorProjectPath } from '../helpers/urlHelper';
import { useProjectActions } from '../hooks/useProjectActions';
import ThumbnailCardUI from './ThumbnailCardUI';

export const ThumbnailCardProject = ({ data, size, fullWidth, onSelect, selected, hideFavourite, onEdit, onDelete, ...rest }) => {
  const {
    onDelete: _onDelete,
    onFavouriteToggle,
    favouriteLoading,
    downloadProject,
    downloadLoading,
    onShare,
    addToCollection,
    customActions,
  } = useProjectActions({
    item: data,
  });

  const handleDelete = onDelete || _onDelete;

  const url = getCuratorProjectPath(data.id);
  return (
    <ThumbnailCardUI
      key={data.id}
      id={data.id}
      // onEdit={onEdit || (() => window.open(url, '_blank'))}
      to={url}
      target="_blank"
      thumbnail={data?.thumbnail}
      images={data?.images}
      name={data.name}
      isFavourite={data.is_favourite}
      favouriteLoading={favouriteLoading}
      date={data.updated_at}
      onDelete={handleDelete}
      onShare={onShare}
      onFavouriteChange={onFavouriteToggle(data)}
      customActions={customActions}
      size={size}
      titleProps={{
        as: Link,
        target: '_blank',
        to: url,
      }}
      onDownload={downloadProject}
      downloadLoading={downloadLoading}
      fullWidth={fullWidth}
      onSelect={onSelect}
      selected={selected}
      onAddToCollection={addToCollection}
      hideFavourite={hideFavourite}
      data={data}
      {...rest}
    />
  );
};
