import React, { useEffect, useState } from 'react';
import { warningToast } from '../helpers/toastHelper.js';
import _, { isEmpty } from 'lodash';
import { apiAddToCollection } from '../helpers/api';
import { MODEL_TYPE__COLLECTION_KEY } from '../constants/constants';
import { apiErrorHandler } from '../helpers/axiosHelper';
import { CreateCollectionForm } from './CreateCollectionForm';
import { ItemSelectListUI } from './OrgSection/ItemSelectListUI';
import PlusIcon from '../components/icons/PlusIcon';
import Button from '../components/Button/Button';
import { useCollectionList } from '../hooks/useCollectionList';
import ReactSelect from 'react-select';
import { reactSelectStyles } from '../helpers/reactSelectStyles';
import SearchBar from '../components/admin/curator/SearchBar/SearchBar';
import { useDispatch, useSelector } from 'react-redux';
import { afterAddToCollection } from '../redux/slicers/admin/sidebarSlicer';
import { ModalArea } from './ModalButtonArea.js';
import {
  addToCollectionActions,
  selectAddToCollectionItems,
  selectAddToCollectionModelType,
} from '../redux/slicers/addToCollectionPopupSlicer.js';
import { usePrevious } from 'react-use';

export default function AddToCollectionPopup({ onSuccess, className }) {
  const dispatch = useDispatch();
  const selectedList = useSelector(selectAddToCollectionItems);
  const modelType = useSelector(selectAddToCollectionModelType);
  const modalOpen = selectedList.length > 0;
  const prevModalOpen = usePrevious(modalOpen);

  const [showNew, setShowNew] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedCollectionList, setSelectedCollectionList] = useState([]);

  const {
    list: collectionList,
    fetchMoreData,
    loading,
    search,
    onSearchChange,
    hasData,
    hasMore,
  } = useCollectionList({
    skip: !modalOpen,
  });

  useEffect(() => {
    if (prevModalOpen && !modalOpen) {
      onSearchChange('');
    }
  }, [modalOpen])

  let title = showNew ? 'Create collection' : 'Add items to collection';
  let subtitle = showNew
    ? 'Please add a name and description for the collection'
    : 'Select the collection where you want to add the items or create a new one';

  function saveAddCollections() {
    if (!isEmpty(setSelectedCollectionList)) {
      warningToast('Please selection at least one collection in which to add these items');
      return;
    }

    if (!processing) {
      setProcessing(true);
      const payload = new FormData();
      const field = MODEL_TYPE__COLLECTION_KEY[modelType];

      if (!field) {
        warningToast('This area does not support adding to collections. Please contact support.');
        setProcessing(false);
        return;
      }

      selectedList.forEach((item) => {
        payload.append(field, item.id);
      });

      selectedCollectionList.forEach((collection) => {
        payload.append('collection', collection.id);
      });

      apiAddToCollection(
        payload,
        `Successfully added to collections`,
        `There was an error adding to collections`
      )
        .then((res) => {
          if (res && res.status) {
            const collectionWithitems = selectedCollectionList.map((c) => ({
              ...c,
              item_count: selectedList.length,
            }));
            dispatch(afterAddToCollection(collectionWithitems));
            onSuccess?.();
            onClose();
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setProcessing(false));
    }
  }

  const onCollectionCreateSuccess = (newCollection) => {
    setShowNew(false);
    setSelectedCollectionList([...selectedCollectionList, newCollection]);
  };

  function onClose() {
    setShowNew(false);
    setProcessing(false);
    setSelectedCollectionList([]);
    dispatch(addToCollectionActions.clear());
  }

  const hasSelectedItems = selectedCollectionList.length > 0;
  const searInputItems = selectedCollectionList.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  const handleSelectChange = (data, eventInfo) => {
    if (eventInfo.action !== 'remove-value') return;
    const id = eventInfo.removedValue.value;
    const updateList = selectedCollectionList.filter((c) => c.id !== id);
    setSelectedCollectionList(updateList);
  };

  return (
    <ModalArea
      showModal={modalOpen}
      setShowModal={onClose}
      className={className}
      title={title}
      subtitle={subtitle}
      size="md"
      modalClassName="add-to-collection-dialog"
      centered={true}
      body={
        <>
          {showNew ? (
            <CreateCollectionForm
              onCancel={() => setShowNew(false)}
              onSuccess={onCollectionCreateSuccess}
            />
          ) : (
            <div>
              {!hasSelectedItems && (
                <SearchBar
                  placeholder="Search collection"
                  className="mb-3 add-to-collection-search"
                  value={search}
                  onChange={onSearchChange}
                />
              )}
              {hasSelectedItems && (
                <ReactSelect
                  className="mb-4"
                  escapeClearsValue={false}
                  value={searInputItems}
                  components={{
                    Menu: () => null,
                  }}
                  isMulti
                  inputValue={search}
                  onInputChange={(e, info) => {
                    if (info.action === 'menu-close') return;
                    if (info.action === 'input-blur') return;

                    console.log('info', info);
                    onSearchChange(e);
                  }}
                  options={[]}
                  onChange={handleSelectChange}
                  isClearable={false}
                  placeholder={false}
                  styles={{
                    ...reactSelectStyles,
                    control: (styles) => ({
                      ...reactSelectStyles?.control?.(styles),
                      border: '1px solid #000000',
                    }),
                    indicatorsContainer: () => ({ display: 'none' }),
                    valueContainer: (styles) => ({
                      ...styles,
                      maxHeight: 90,
                      overflow: 'auto',
                      '::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }),
                  }}
                />
              )}
              <ItemSelectListUI
                dataType="object"
                onButtonClick={() => setShowNew(true)}
                buttonText={
                  <>
                    <PlusIcon />
                    Create collection
                  </>
                }
                className="mb-4"
                formatData={(collection) => {
                  return {
                    id: collection.id,
                    // image: collection.member_profile,
                    title: collection.name,
                  };
                }}
                items={collectionList}
                selected={selectedCollectionList}
                onChange={(list) => setSelectedCollectionList(list)}
                loading={loading}
                hasData={hasData}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                variant="checkbox"
                minHeight={290}
                placeholder
              />

              <Button
                onClick={saveAddCollections}
                disabled={processing || isEmpty(selectedCollectionList)}
                loading={processing}
                size="xlg"
                color="warning"
                fullWidth>
                Save
              </Button>
            </div>
          )}
        </>
      }
    />
  );
}
