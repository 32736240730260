import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { icons } from '../assets';
const { SelectArrowDownIcon } = icons;

// TODO add the sub options under the line in FIGMA,
// like Archived Filter on Renders & Previews
export default function StyledSelect(props) {

  const Placeholder = props => {
    return <components.Placeholder {...props} />;
  };
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SelectArrowDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <Select
      // menuIsOpen={true}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder || 'Select'}
      classNamePrefix="lfSelect"
      makeAnimated={makeAnimated}
      components={{ 
        Placeholder, 
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      isMulti={props.isMulti}
      isDisabled={props.disabled}
    />
  );
}
