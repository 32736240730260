import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { SelectController } from '../../components/hookForm/SelectController';
import { reactSelectStyles } from '../../helpers/reactSelectStyles';
import { useEffect, useMemo } from 'react';
import { FormButtons } from './FormButtons';

export const EditForm = ({ onCancel, onSubmit, schema, fields, disabled }) => {
  const defaultValues = useMemo(() => {
    return fields.reduce((acc, field) => {
      if (!field.name) return acc;

      acc[field.name] = field.value;
      return acc;
    }, {});
  }, [fields]);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors, isSubmitting },
    clearErrors,
  } = useForm({
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues,
  });

  const resetValues = () => {
    Object.keys(defaultValues).forEach(key => {
      setValue(key, defaultValues[key]);
    })
    clearErrors();
  }

  useEffect(() => {
   resetValues();
  }, [fields])

  const handleCancelClick = () => {
    resetValues();
    onCancel();
  }

  console.log('isSubmitting', isSubmitting)

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((field) => {
        if (field.Component) {
          return field.Component({ control });
        }

        if (field.hidden) return null;

        if (field.type === 'select') {
          return (
            <SelectController
              key={field.name}
              name={field.name}
              control={control}
              selectStyles={reactSelectStyles}
              isClearable={false}
              placeholder={field.placeholder}
              containerClassName="mb-3"
              isDisabled={disabled}
              {...field.selectProps}
            />
          );
        }

        return (
          <Form.Group className="mb-3" controlId="formGroupEmail" key={field.name}>
            <Form.Control
              type="text"
              {...register(field.name)}
              placeholder={field.placeholder}
              className={`form-control ${errors[field.name] ? 'is-invalid' : ''}`}
              disabled={disabled}
            />
            <div className="invalid-feedback">{errors[field.name]?.message}</div>
          </Form.Group>
        );
      })}

      {!disabled && <FormButtons onCancel={handleCancelClick} loading={isSubmitting} />}
    </Form>
  );
};
