
import { useSimpleDataList } from "./useDataList";
import { searchMembersActions, searchMembersSelector } from "../redux/slicers/searchMembersSlicer";
import { getMembersApi } from "../api/teams";

export const useSearchMemberList = () => {
  const data = useSimpleDataList({
    selector: searchMembersSelector,
    resetAction: searchMembersActions.resetList,
    actions: searchMembersActions,
    getAction: getMembersApi,
  });

  return data;
};
