import _ from 'lodash';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { setTitle } from '../helpers/jsHelper';

export default function CommonGrid({
  title,
  item,
  className,
  containerStyle,
  filterArea,
  children,
  hasData,
  topContainer,
  noDataText,
  disableNoDataText,
  sticky = true,
}) {
  useEffect(() => {
    setTitle(title);
  }, []);

  const [headerClass, setHeaderClass] = useState('');
  let scrollPos = 0;

  noDataText = noDataText || `You don't have any ${item}.`;

  const scrollHeaderFixed = () => {

    if (document.body.getBoundingClientRect().top > scrollPos) {
      setHeaderClass('sticky');
    } else {
      setHeaderClass('');
    }
    scrollPos = document.body.getBoundingClientRect().top;
    if (scrollPos >= -170) {
      setHeaderClass('');
    }
  };

  useEffect(() => {
    if (!sticky) return;

    window.addEventListener('scroll', scrollHeaderFixed);

    return () => window.removeEventListener('scroll', scrollHeaderFixed);
  }, []);

  return (
    <div className={`w-100 main-container ${className || ''}`} id="body">
      <div style={containerStyle}>
        <header id="pageHeader" className={`pageHeader ${headerClass}`}>
          {topContainer}
          {filterArea}
        </header>
        {children}
        {!hasData && !disableNoDataText && <h1 className="noDataText">{noDataText}</h1>}
      </div>
    </div>
  );
}
