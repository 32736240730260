import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetFilters } from '../redux/slicers/admin/sidebarSlicer';
import { useInfoDialogaActions } from './useInfoDialogaActions';

export const useDataListActions = ({
  items,
  itemDeleteText,
  itemsDeleteText,
  deleteAction,
  bulkDeleteAction,
  defaultSort,
  defaultFilterBy,
  useGlobalFilter,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [sortBy, setSortBy] = useState(defaultSort);
  const [filterBy, setFilterBy] = useState(defaultFilterBy || undefined);
  const [search, setSearch] = useState('');

  useEffect(() => {
    // TODO:  remove selected items from the list if they are not in the new items list
  }, [items]);

  const { openInfoDialog } = useInfoDialogaActions();

  const onDelete = (id) => {
    const ids = id ? [id] : selected;

    if (!ids.length) return;

    const dialogContent = ids.length === 1 ? itemDeleteText : itemsDeleteText(ids);

    // TODO: handle multiple deletions
    openInfoDialog({
      title: 'Delete',
      description: dialogContent,
      cancelButtonText: 'No',
      buttonText: 'Yes',
      onButtonClick: () => {
        if (ids.length > 1 && bulkDeleteAction) {
          dispatch(bulkDeleteAction(ids));
          return;
        }

        ids.forEach((id) => {
          dispatch(deleteAction(id));
        });
      },
    });
  };

  const toggleAll = () => {
    const allSelected = selected.length === items.length;
    allSelected ? setSelected([]) : setSelected(items.map((item) => item.id));
  };

  const toggleItem = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((selectedId) => selectedId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const resetSelection = () => setSelected([]);

  const onSortChange = (sortBy) => {
    setSortBy(sortBy);
  };

  const onFilterByChange = (filterBy) => {
    setFilterBy(filterBy);

    if (useGlobalFilter) {
      dispatch(resetFilters());
    }
  };

  const onSearchChange = (value) => {
    const val = typeof value === 'string' ? value : value.target.value;
    setSearch(val);
  };

  return {
    selected,
    sortBy,
    filterBy,
    search,
    anySelected: selected.length > 0,

    onDelete,
    toggleAll,
    toggleItem,
    resetSelection,
    hasSelected: selected.length > 0,
    deleteDisabled: selected.length === 0,
    allSelected: items.length > 0 && selected.length === items.length, // TODO: work on this logic, it's better to compare map of ids
    onSortChange,
    onFilterByChange,
    onSearchChange,
  };
};
