/* eslint-disable no-debugger */
import { dispatcher } from '../../../../helpers/projectHelper';
import { selectSelectedLightId } from '../../../../redux/slicers/admin/curatorLightSlicer';
import { selectLightById, updateLight } from '../../../../redux/slicers/admin/curatorSlicer';
import { getAppState } from '../../../../redux/store';

export class UndoRedoLight {
  constructor({ undoRedoInstance, unityLight }) {
    this.unityLight = unityLight;
    this.undoRedo = undoRedoInstance;

    this.init();
  }

  _getLight = (id) => {
    return selectLightById(getAppState(), id);
  };

  _getUpdatedLight = (id, data) => {
    const light = selectLightById(getAppState(), id);
    return { ...light, ...data };
  };

  init = () => {

    this.addLight = this.undoRedo.createAction(({ name, lightId }) => {
      return {
        name, 
        lightId,
        undo: () => {
          this.unityLight.forceDeleteLight(lightId)
        },
        redo: () => {
          this.unityLight.addLight(name, { skipHistory: true })
        },
      };
    });

    // light on/off
    this.toggleLight = this.undoRedo.createAction(({ id, enabled }) => {
      return {
        undo: () => {
          const light = selectLightById(getAppState(), id);
          this.unityLight.setLightEnabled(light, !enabled);
        },
        redo: () => {
          const light = selectLightById(getAppState(), id);
          console.log('redo toggleLight ', id, light, enabled);
          this.unityLight.setLightEnabled(light, enabled);
        },
      };
    });

    // light name
    this.changeLightName = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      const lightId = selectSelectedLightId(getAppState());

      return {
        undo: () => {
          const updatedLight = this._getUpdatedLight(lightId, { lightName: prevValue });
          dispatcher(updateLight(updatedLight));
          this.undoRedo.unityContext.lightModule.UpdateLightNameReceiver(prevValue);
        },
        redo: () => {
          const updatedLight = this._getUpdatedLight(lightId, { lightName: nextValue });
          dispatcher(updateLight(updatedLight));
          this.undoRedo.unityContext.lightModule.UpdateLightNameReceiver(nextValue);
        },
      };
    });

    // light details 
    this.updateLight = this.undoRedo.createAction(({ name, prevValue, nextValue }) => {
      // TODO: parse light values here -> float/int etc
      const lightId = selectSelectedLightId(getAppState());
      const light = this._getLight(lightId);

      return {
        undo: () => {
          this.unityLight.modifyLight(light, name, prevValue);
        },
        redo: () => {
          this.unityLight.modifyLight(light, name, nextValue);
        }
      }
    })
    
  };
}
