import _ from 'lodash';
import { useRooms } from '../hooks/useRooms';
import { getInspirationsPageListApi } from '../api/myprojects';
import GridSectionNew from './GridSectionNew';

export default function GridRoomsSection({ title, seeAllLink, modelType, emptyDisplay }) {
  const { list, loading } = useRooms({ api: getInspirationsPageListApi });

  return (
    <>
      <GridSectionNew
        title={title}
        seeAllLink={seeAllLink}
        carouselData={list}
        modelType={modelType}
        loading={loading}
        emptyDisplay={emptyDisplay}
      />
    </>
  );
}
