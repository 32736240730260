export const MAX_PROJECT_NAME_LENGTH = 255;
export const MIN_PROJECT_NAME_LENGTH = 1;

export const MIN_COLLECTION_NAME_LENGTH = 1;
export const MAX_COLLECTION_NAME_LENGTH = 255;

export const MIN_PREVIEW_NAME_LENGTH = 1;
export const MAX_PREVIEW_NAME_LENGTH = 255;

export const LIGHT_HDRI_FILE_MAX_SIZE = 100000000000000; // TODO: confirm about this
