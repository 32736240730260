import { uniqueId } from 'lodash';
import { API_ROOT_URL, PROPS_API, PROPS_REPLACE_API } from '../../helpers/api';
import api from '../../helpers/axiosHelper';
import { objectToFormData, objectToSearchParams } from '../../helpers/jsHelper';
import { LOADING_KEY } from '../../helpers/reduxHelper';
import { errorToast, successToast } from '../../helpers/toastHelper';
import { paginatedProductActions } from '../../redux/slicers/admin/paginatedProductSlicer';
import { setLables } from '../../redux/slicers/admin/sidebarSlicer';
import { collectionItemsActions } from '../../redux/slicers/collectionItemsSlicer';
import { collectionsActions } from '../../redux/slicers/collectionsSlicer';

export const createGetListAction =
  ({ endpoint, actions, defaultSort = 'name', setGlobalSidebarFilters, skipBrandFilter, dataFormattter }) =>
  ({ searchConfig, page, perPage, skipBrandFilter: requestSkipBrandFilter, sort = defaultSort, ...rest }) =>
  (dispatch) => {
    const obj = {
      ...rest,
      page: typeof rest.page === 'number' ? rest.page : page,
      per_page: typeof rest.perPage === 'number' ? rest.perPage : perPage,
      sort: typeof rest.sort === 'string' ? rest.sort : sort,
    }

    const searchParams = objectToSearchParams(obj);
    const API_URL = `${endpoint}?${searchParams}`;
    const requestId = uniqueId();

    const start = searchConfig ? actions.searchStart : actions.fetchListStart;
    const success = searchConfig ? actions.searchSuccess : actions.fetchListSuccess;
    const failed = searchConfig ? actions.searchFailed : actions.fetchListFailed;

    dispatch(start({ requestId }));

    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        data.results = dataFormattter ? data.results.map(dataFormattter) : data.results;

        dispatch(
          success({
            page: page,
            data,
            requestId,
          })
        );

        const sidefilter = {...data?.sidefilter};
        if (skipBrandFilter || requestSkipBrandFilter) {
          sidefilter.brands = [];
        }

        if (!searchConfig) {
          dispatch(actions.setFilter(sidefilter));
        }

        if (setGlobalSidebarFilters && page === 1) {
          dispatch(setLables(sidefilter));
        }
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        dispatch(failed({ error, requestId }));
      })
      .send();
  };

export const getProductListApi = createGetListAction({
  endpoint: PROPS_API,
  actions: paginatedProductActions,
  setGlobalSidebarFilters: true,
});

export const getMy3dModelListApi = createGetListAction({
  endpoint: PROPS_API,
  skipBrandFilter: true,
  actions: paginatedProductActions,
  setGlobalSidebarFilters: true,
});

export const getProductReplaceListApi = createGetListAction({
  endpoint: PROPS_REPLACE_API,
  actions: paginatedProductActions,
});

export const createUpdateApiAction =
  ({ actions, loadingKey = LOADING_KEY.FAVOURITE, endpointUrl }) =>
  ({ id, ...rest }) =>
  (dispatch) => {
    const formData = objectToFormData(rest);

    const isFavouriteToggleAction =
      Object.keys(rest).length === 1 && typeof rest.is_favourite === 'boolean';
    if (isFavouriteToggleAction) {
      // we're toggling favourite
      dispatch(
        actions.setItemLoading({
          id,
          key: loadingKey,
          value: true,
        })
      );
    }

    api()
      .root(API_ROOT_URL)
      .patch(`${endpointUrl}${id}/`)
      .data(formData)
      .success((d) => {
        const data = d?.id ? d : d?.data
        dispatch(actions.update(data));
        dispatch(collectionItemsActions.updateItemInCollection({ data }))

        if (isFavouriteToggleAction) {
          if (data.is_favourite) {
            successToast('Added To Favourite');
          } else {
            successToast('Removed From Favourite');
          }
        }
      })
      .error((e) => {
        console.error(e)
        errorToast(e.message);
      })
      .finally(() => {
        if (isFavouriteToggleAction) {
          dispatch(
            actions.setItemLoading({
              id,
              key: loadingKey,
              value: false,
            })
          );
        }
      })
      .send();
  };

export const ProductEditApi = createUpdateApiAction({
  actions: paginatedProductActions,
  endpointUrl: PROPS_API,
  loadingKey: LOADING_KEY.FAVOURITE,
});

export const createDeleteApiAction =
  ({ actions, endpointUrl, successMessage }) =>
  ({ id, onSuccess }) =>
  (dispatch) => {
    dispatch(
      actions.deleteStart({
        itemId: id,
      })
    );

    successMessage && successToast(successMessage);

    api()
      .root(API_ROOT_URL)
      .delete(`${endpointUrl}${id}/`)
      .success((res) => {
        dispatch(
          actions.deleteSuccess({
            itemId: id,
          })
        );
        onSuccess?.();
      })
      .error((e) => {
        errorToast('Some error occured');
        // TODO: work on this -> API never calls error if it's a network error or some other specific error
        dispatch(
          actions.deleteFailed({
            itemId: id,
            error: e,
          })
        );
      })
      .send();
  };
