import { MODEL_TYPES } from '../constants/constants';
import { useTextureActions } from '../hooks/useTextureActions';
import ThumbnailCardUI from './ThumbnailCardUI';

export const ThumbnailCardTexture = ({ data, size, fullWidth, onSelect, selected, modelType, onDelete, onEdit, hideFavourite, ...rest }) => {
  const {
    onDelete: _onDelete,
    onFavouriteToggle,
    favouriteLoading,
    onShare,
    addToCollection,
    
    loading,
    handleDownloadClick,
    onEdit: _onEdit,
    handleUploadEditClick,
    handleCardClick,
  } = useTextureActions({
    item: data,
    modelType,
  });

  const isUpload = modelType === MODEL_TYPES.UPLOADS
  const handleDelete = onDelete || _onDelete;
  let handleEdit = onEdit || _onEdit;
  if (isUpload) {
    handleEdit = undefined
  }

  return (
    <ThumbnailCardUI
      key={data.id}
      id={data.id}
      onEdit={handleEdit}
      onMoveToTextures={isUpload ? handleUploadEditClick : undefined}
      thumbnail={data?.thumbnail}
      images={data?.images}
      name={data.name}
      isFavourite={data.is_favourite}
      favouriteLoading={favouriteLoading}
      date={data.created_at}
      onDelete={handleDelete}
      onShare={!isUpload ? onShare : undefined}
      onFavouriteChange={onFavouriteToggle(data)}
      size={size}
      data={data}
      titleProps={{
        onClick: handleCardClick,
      }}
      onDownload={handleDownloadClick}
      downloadLoading={loading}
      fullWidth={fullWidth}
      onSelect={onSelect}
      selected={selected}
      onAddToCollection={!isUpload ? addToCollection : undefined}
      onCardImageClick={handleCardClick}
      hideFavourite={hideFavourite}
      {...rest}
    />
  );
};

export const ThumbnailCardUpload = (props) => {
  return (
    <ThumbnailCardTexture {...props} modelType={MODEL_TYPES.UPLOADS} />
  )
}