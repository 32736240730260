import cx from 'classnames';
import { FormCheck, FormControl } from 'react-bootstrap';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { usePrevious } from 'react-use';
import Checkbox, { StatelessCheckbox } from '../Checkbox';
import Image from '../Image';
import Loader from '../Loader';
import styles from './ItemSelectListUI.module.scss';

export const ItemSelectListUI = ({
  items,
  selected,
  className,
  onChange,
  loading,
  hasData,
  hasMore,
  fetchMoreData,
  noDataMessage = 'No results',
  onButtonClick,
  buttonText,
  minHeight,
  formatData,
  dataType,
  itemClassName,
  variant = 'default',
  placeholder, // show image placeholder
}) => {
  const prevItems = usePrevious(items);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: fetchMoreData,
    rootMargin: '0px 0px 120px 0px',
  });

  const handleClick = (item) => () => {
    // TODO: support for multiple selection
    if (Array.isArray(selected)) {
      let updatedList;

      if (dataType === 'object') {
        updatedList = selected.find((arrItem) => arrItem.id === item.id)
          ? selected.filter((arrItem) => arrItem.id !== item.id)
          : [...selected, item];
      } else {
        updatedList = selected.includes(item.id)
          ? selected.filter((id) => id !== item.id)
          : [...selected, item.id];
      }

      onChange(updatedList, item);
    } else {
      onChange(dataType === 'object' ? item : item.id);
    }
  };

  const itemList = loading && items.length === 0 ? prevItems : items;
  const placeholdersVisible = !hasData || !itemList.length;
  const hasButton = typeof buttonText !== 'undefined';

  return (
    <div
      className={cx(
        styles.root,
        className,
        hasButton && styles.hasButton,
        variant === 'checkbox' && styles.variantCheckbox
      )}
      style={{
        minHeight,
      }}>
      {hasButton && (
        <div className={cx(styles.itemButton)} onClick={onButtonClick}>
          {buttonText}
        </div>
      )}

      <Loader loading={loading} position="sticky" className={styles.loader} />

      {placeholdersVisible && (
        <>
          {!loading && <div className={styles.noDataMessage}>{noDataMessage}</div>}
          <div className={cx(styles.item)}></div>
          <div className={cx(styles.item)}></div>
          <div className={cx(styles.item)}></div>
          {!hasButton && <div className={cx(styles.item)}></div>}
        </>
      )}

      <div className={styles.listContainer}>
        {itemList.map((item) => {
          const formattedItem = formatData ? formatData(item) : item;
          const { image, title, description, id } = formattedItem;

          let isSelected = false;

          if (dataType === 'object') {
            isSelected = Array.isArray(selected)
              ? Boolean(selected.find(i => i.id === id))
              : id === selected.id;
          } else {
            isSelected = Array.isArray(selected)
              ? selected.includes(id)
              : id === selected;
          }

          return (
            <div
              key={formattedItem.id}
              className={cx(styles.item, styles.normalItem, itemClassName, isSelected && styles.selected)}
              onClick={handleClick(item)}>
              {(image || placeholder) && (
                <div className={styles.imageContainer}>
                  <Image
                    src={image}
                    ratio={1}
                    placeholderWhileLoading
                    hideWithNoSrc
                    rounded
                  />
                </div>
              )}

              <div className={styles.contentContainer}>
                <div className={styles.title}>{title}</div>
                <div className={styles.description}>{description}</div>
              </div>

              {variant === 'checkbox' && <StatelessCheckbox type="checkbox" checked={isSelected} />}
            </div>
          );
        })}
      </div>

      {hasMore && <div ref={sentryRef}></div>}
    </div>
  );
};
