import _ from 'lodash';
import { Button } from 'react-bootstrap';
import cx from 'classnames';
import { Checkbox, StyledSelect } from '.';
import SearchInput from './SearchInput';
import { TeamMembersFilter } from '../components/TeamMembersFilter/TeamMembersFilter';
import { TeamMembersSharedFilesFilter } from './TeamMembersSharedFilesFilter';

export default function ActionFilters({
  className,
  sortProps,
  filterProps,
  selectedProps,
  showTeamMemberFilter,
  searchBar,
  showSharedFilter,
  filterSelected,
  userFilter,
}) {
  return (
    <div className={cx('filter_container action-filters', className)}>
      <div className="fc-left d-flex">
        {((filterProps && filterProps.data && filterProps.data.length > 0) || filterSelected) && (
          <div className="minWidth165">
            <StyledSelect
              value={filterProps.value}
              onChange={filterProps.onChange}
              options={filterProps.data}
              placeholder={'Filter by'}
            />
          </div>
        )}
        {sortProps && sortProps.data && sortProps.data.length > 0 && (
          <div className="minWidth165">
            <StyledSelect
              value={sortProps.value}
              onChange={sortProps.onChange}
              options={sortProps.data}
              placeholder={'Sort by'}
            />
          </div>
        )}
        {userFilter && (
          <div className="filter layout">
            <Button
              className={cx(userFilter?.selected === 'is_internal' ? 'active' : '', 'btn-table')}
              onClick={() => userFilter?.setSelected('is_internal')}
              variant={userFilter?.selected === 'is_internal' ? 'primary' : ''}>
              Internal users
            </Button>
            <Button
              className={cx(userFilter?.selected === 'is_external' ? 'active' : '', 'btn-grid')}
              onClick={() => userFilter?.setSelected('is_external')}
              variant={userFilter?.selected === 'is_external' ? 'primary' : ''}>
              External users
            </Button>
          </div>
        )}
      </div>
      <div className="fc-right d-flex gap-3 align-items-center">
        {searchBar && (
          <SearchInput value={searchBar.value} placeholder="Search" onChange={searchBar.onChange} />
        )}
        {selectedProps && (
          <div className="d-flex gap-1">
            <Checkbox
              selected={selectedProps.allSelected}
              onChange={() => selectedProps.onSelectAll(!selectedProps.allSelected)}
              className="selectAll"
              label="Select All"
            />

            {selectedProps.selectedList?.length > 0 && (
              <span className="text-muted">( {selectedProps.selectedList.length} )</span>
            )}
          </div>
        )}
        {showTeamMemberFilter && <TeamMembersFilter />}
        {showSharedFilter && <TeamMembersSharedFilesFilter />}
      </div>
    </div>
  );
}
