import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { Pagination } from '../Pagination/Pagination';
import { CommonGrid, TopContainer } from '../../layouts';
import { paginatedProductSelectors } from '../../redux/slicers/admin/paginatedProductSlicer';
import { paginatedRoomsSelectors } from '../../redux/slicers/admin/paginatedRooms';
import { texturesSelectors } from '../../redux/slicers/texturesSlicer';
import { myprojectsSelectors } from '../../redux/slicers/my-projects/myProjectsSlicer';
import { collectionsSelectors } from '../../redux/slicers/collectionsSlicer';
import { myRenderPreviewSelectors } from '../../redux/slicers/render_preview/myRenderPreviewSlice';
import styles from './SearchResult.module.scss';
import { TeamMembersFilter } from '../TeamMembersFilter/TeamMembersFilter';
import { previewsFilter, rendersFilter } from '../../helpers/configureHelper';
import { useLocation, useParams } from 'react-router-dom';
import {
  ADMIN_MY_PRODUCTS_3DMODAL,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_ROOMS,
  ADMIN_MY_PRODUCTS_ROOMS_DETAILS,
  ADMIN_MY_PRODUCTS_TEXTURE,
  ADMIN_MY_PRODUCTS_TEXTURE_EDIT,
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
  ADMIN_MY_PRODUCTS_UPLOADS,
  ADMIN_MY_PRODUCTS_UPLOADS_MOVE_TO_TEXTURE,
  ADMIN_MY_PROJECTS,
  ADMIN_MY_PROPS,
  ADMIN_MY_ROOMS_PARAMS,
  ADMIN_NEW_PROJECT,
  COLLECTIONS_ROUTE,
  COLLECTION_ID,
  INSPIRATION_ROUTE,
  MY_PRODUCTS_ROUTE,
  MY_PROJECTS_ROUTE,
  MY_TEAM_TEXTURE,
  NEW_DESIGN_ROUTE,
  NEW_PROJECTS_ROUTE,
  PRODUCT_RENDER,
  PRODUCT_WEB_EXPERIENCE_PUBLIC,
  PROJECT_RENDER,
  PROPS_ROUTE,
  RECENT_DESIGNS_ROUTE,
  RENDERS_PREVIEWS_ROUTE,
  RENDER_DETAILS,
  ROOMS_ROUTE,
  ROOM_RENDER,
  SHARED_FILES_ROUTE,
  TEMPLATES_DETAILS,
  TEMPLATES_ROUTE,
} from '../../constants/routePathConstants';
import { useUserContext } from '../../contexts/UserContext';
import { sharedFilesPageSelectors } from '../../redux/slicers/sharedFilesPageSlicer';
import { TeamMembersSharedFilesFilter } from '../../common/TeamMembersSharedFilesFilter';
import MyProducts3dModel from '../../screens/MyProducts3dModel';
import MyRooms from '../../screens/MyRooms';
import MyProductsTextures from '../../screens/MyProductsTextures';
import MyProductsUploads from '../../screens/MyProductsUploads';
import MyProjects from '../../screens/MyProjects';
import RendersPreviews from '../../screens/RendersPreviews';
import MyProps from '../../screens/MyProps';
import Collections from '../../screens/Collections';
import SharedFiles from '../../screens/SharedFiles';

const TAB = {
  MODEL_3D: 'MODEL_3D',
  ROOM: 'ROOM',
  TEXTURE: 'TEXTURE',
  UPLOAD: 'UPLOAD',
  PROJECT: 'PROJECT',
  RENDER: 'RENDER',
  PREVIEW: 'PREVIEW',
  TEMPLATE: 'TEMPLATE',
  PROP: 'PROP',
  COLLECTION: 'COLLECTION',
  SHARED: 'SHARED',
};

const getDefaultTab = (pathname, params) => {
  const map = {
    [MY_PRODUCTS_ROUTE]: TAB.MODEL_3D,
    [MY_PROJECTS_ROUTE]: TAB.PROJECT,
    [RENDERS_PREVIEWS_ROUTE]: TAB.RENDER,
    [NEW_DESIGN_ROUTE]: TAB.RENDER,
    [ROOMS_ROUTE]: TAB.ROOM,
    [TEMPLATES_ROUTE]: TAB.TEMPLATE,
    [TEMPLATES_DETAILS]: TAB.TEMPLATE,
    [PROPS_ROUTE]: TAB.PROP,
    [COLLECTIONS_ROUTE]: TAB.COLLECTION,
    [COLLECTION_ID]: TAB.COLLECTION,
    [RENDER_DETAILS]: TAB.RENDER,
    [ROOM_RENDER]: TAB.ROOM,
    [NEW_PROJECTS_ROUTE]: TAB.ROOM,
    [PRODUCT_RENDER]: TAB.PROP,
    [ADMIN_MY_PROJECTS]: TAB.PROJECT,
    [ADMIN_NEW_PROJECT]: TAB.PROJECT,
    [ADMIN_MY_PROPS]: TAB.PROP,
    [ADMIN_MY_PRODUCTS_3DMODAL]: TAB.MODEL_3D,
    [ADMIN_MY_PRODUCTS_ROOMS]: TAB.ROOM,
    [ADMIN_MY_PRODUCTS_ROOMS_DETAILS]: TAB.ROOM,
    [ADMIN_MY_PRODUCTS_TEXTURE]: TAB.TEXTURE,
    [ADMIN_MY_PRODUCTS_TEXTURE_EDIT]: TAB.TEXTURE,
    [ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD]: TAB.TEXTURE,
    [ADMIN_MY_PRODUCTS_UPLOADS]: TAB.UPLOAD,
    [ADMIN_MY_PRODUCTS_UPLOADS_MOVE_TO_TEXTURE]: TAB.UPLOAD,
    [ADMIN_MY_PRODUCTS_3DMODAL_EACH]: TAB.MODEL_3D,
    [INSPIRATION_ROUTE]: TAB.TEMPLATE,
    [RECENT_DESIGNS_ROUTE]: TAB.PROJECT,
    [MY_TEAM_TEXTURE]: TAB.TEXTURE,
    [SHARED_FILES_ROUTE]: TAB.SHARED,
  };

  const key = Object.keys(map).find((route) => {
    let routeUrl = route;
    Object.keys(params).forEach(key => {
      routeUrl = routeUrl.replace(`:${key}`, params[key])
    })
    
    return routeUrl === pathname;
  })

  return map[key] || TAB.MODEL_3D;
};

const TAB_CONFIG = {
  [TAB.MODEL_3D]: {
    Component: MyProducts3dModel,
    selectors: paginatedProductSelectors,
    name: '3d Models',
  },
  [TAB.ROOM]: {
    Component: MyRooms,
    selectors: paginatedRoomsSelectors,
    name: 'Rooms',
  },
  [TAB.TEXTURE]: {
    Component: MyProductsTextures,
    selectors: texturesSelectors,
    name: 'Textures',
  },
  [TAB.UPLOAD]: {
    Component: MyProductsUploads,
    selectors: texturesSelectors,
    name: 'Uploads',
  },
  [TAB.PROJECT]: {
    Component: MyProjects,
    selectors: myprojectsSelectors,
    name: 'Projects',
  },
  [TAB.RENDER]: {
    Component: RendersPreviews,
    selectors: myRenderPreviewSelectors,
    name: 'Renders',
    props: {
      defaultFilterBy: rendersFilter,
    },
  },
  [TAB.PREVIEW]: {
    Component: RendersPreviews,
    selectors: myRenderPreviewSelectors,
    name: 'Previews',
    props: {
      defaultFilterBy: previewsFilter,
    },
  },
  [TAB.TEMPLATE]: {
    Component: MyRooms,
    selectors: paginatedRoomsSelectors,
    name: 'Templates',
  },
  [TAB.PROP]: {
    Component: MyProps,
    selectors: paginatedProductSelectors,
    name: 'Props',
  },
  [TAB.COLLECTION]: {
    Component: Collections,
    selectors: collectionsSelectors,
    name: 'Collections',
  },
  [TAB.SHARED]: {
    Component: SharedFiles,
    name: 'Shared',
    selectors: sharedFilesPageSelectors,
  },
};

export default function SearchResult() {
  const location = useLocation();
  const params = useParams();
  const { currentUser } = useUserContext();

  const defaultTab = useMemo(() => {
    return getDefaultTab(location.pathname, params)
  }, [])
  const [activeTab, setActiveTab] = useState(defaultTab);


  const tabConfig = TAB_CONFIG[activeTab];
  const { Component, selectors, props } = tabConfig;

  const loading = useSelector(selectors.selectLoading);
  const total = useSelector(selectors.selectTotal);
  const list = useSelector(selectors.selectList);

  const isGenericTab = activeTab !== TAB.PROP && activeTab !== TAB.TEMPLATE;
  const isSharedFilesTab = activeTab === TAB.SHARED;

  return (
    <>
      <CommonGrid
        className="searchPgHeader pb-0"
        disableNoDataText
        hasData={list.length > 0 || loading}
        topContainer={
          <TopContainer
            title={<>Search results {total > 0 && <small>({total})</small>}</>}
            buttonArea={
              <div className="filter_container">
                {isSharedFilesTab ? null : <TeamMembersFilter />}
              </div>
            }
          />
        }>
        <Pagination fullWidth className={styles.tabs}>
          {Object.keys(TAB).map((key) => {
            const config = TAB_CONFIG[key];
            return (
              <Pagination.Item
                onClick={() => setActiveTab(TAB[key])}
                active={activeTab === TAB[key]}
                key={key}>
                {config.name}
              </Pagination.Item>
            );
          })}
        </Pagination>
      </CommonGrid>

      <Component
        key={activeTab}
        useGlobalSearch
        noDataText={'Ooops, no results found'}
        cardProps={{ onSelect: null }}
        apiConfig={{
          defaultCreatedBy: isGenericTab ? currentUser.id : undefined,
          defaultBrands: isGenericTab ? currentUser.organization : undefined,
        }}
        {...props}
      />
    </>
  );
}
