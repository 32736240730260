import { deleteMyRenderPreviewAPI, updateMyRenderPreviewApi } from "../api/commonApi";
import { MODEL_TYPES } from "../constants/constants"
import { myRenderPreviewSelectors } from "../redux/slicers/render_preview/myRenderPreviewSlice";
import { useDownloadEntity } from "./useDownloadEntity"
import { useModelActions } from "./useModelActions";

export const useRenderPreviewsActions = ({ item }) => {
  const { download, loading: downloadLoading } = useDownloadEntity();

  const label = item.is_upload 
    ? 'upload'
    : item.is_preview
      ? 'preview'
      : 'render';

  const deleteTitle = item.is_main_render
    ? 'Are you sure you want to archive this render?'
    :  `Are you sure you want to delete this ${label}?`;

  const deleteDescription = item.is_main_render
    ? 'You can find archived renders by filtering "Archived"'
    : undefined;
  
  const { favouriteLoading, onFavouriteToggle, onDelete, onShare, addToCollection } = useModelActions({
    item,
    modelType: MODEL_TYPES.PROJECTRENDER,
    selectors: myRenderPreviewSelectors,
    updateApi: updateMyRenderPreviewApi,
    deleteConfig: {
      title: deleteTitle,
      description: deleteDescription,
      deleteApi: deleteMyRenderPreviewAPI,
    },
  });


  // const onLinkClick = () => {
  //   if (item.images.length !== 0) {
  //     dispatch(setRenderPreviewExpand(item));
  //   } else {
  //     errorToast("This Preview does not have a camera image.");
  //   }
  // }

  // const onDelete = (id) => {
  //   openInfoDialog({
  //     title: "Are you sure you want to archive this render?",
  //     description: `You can view the archived renders by clicking on the left pane of this screen`,
  //     cancelButtonText: "No",
  //     buttonText: "Yes",
  //     onButtonClick: () => {
  //       dispatch(deleteRenderPreviewAPI({ id }));
  //     },
  //   });

    
  // }


  const onDownloadClick = () => {
    download({ id: item.id, type: 'renders-previews' })
  }
  
  return {
    // onLinkClick,
    downloadLoading,
    onDownloadClick,
    favouriteLoading, 
    onFavouriteToggle, 
    onDelete, 
    onShare, 
    addToCollection,
  }
}