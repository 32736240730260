import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUserContext } from '../../contexts/UserContext';
import { useMembers } from '../../hooks/useMembers';
import {
  anyMemberSelected,
  filterSelector,
  resetMembers,
  setMembers,
  toggleMember,
} from '../../redux/slicers/filters/filterSlicer';
import { selectTeamMembersFilterOpen, setRightOffcanvasOpen } from '../../redux/slicers/uiSlicer';
import UserGroupIcon from '../icons/UserGroupIcon';
import UserIcon from '../icons/UserIcon';
import { MemberOverlay } from '../MemberOverlay';

export const TeamMembersFilter = () => {
  const dispatch = useDispatch();
  const show = useSelector(selectTeamMembersFilterOpen);
  const [keepMounted, setKeepMounted] = useState(false);
  const teamSelected = useSelector(anyMemberSelected);
  const selectedMembers = useSelector((state) => filterSelector(state).members);

  const handleClose = () => {
    dispatch(setRightOffcanvasOpen(false));
  };
  const handleShow = () => {
    setKeepMounted(true);
    dispatch(setRightOffcanvasOpen(true));
  };

  const handleMeClick = () => {
    dispatch(resetMembers());
    dispatch(setRightOffcanvasOpen(false));
  };

  const handleChange = (memberIds) => {
    dispatch(setMembers(memberIds));
  };

  useEffect(() => {
    return () => {
      dispatch(resetMembers());
    };
  }, []);

  return (
    <>
      <div className="filter layout">
        <button
          className={!show && !teamSelected ? 'btn-table active' : 'btn-table'}
          onClick={handleMeClick}>
          <UserIcon /> <span>Me</span>
        </button>
        <button
          className={show || teamSelected ? 'btn-grid active' : 'btn-grid'}
          onClick={() => handleShow(false)}>
          <UserGroupIcon /> <span>Team</span>
        </button>
      </div>
      {(show || keepMounted) && (
        <TeamMembersFilterOverlayController
          show={show}
          onClose={handleClose}
          onChange={handleChange}
          selectedMembers={selectedMembers}
        />
      )}
    </>
  );
};

const TeamMembersFilterOverlayController = (props) => {
  const { currentUser } = useUserContext();
  const {
    list: memberList,
    loading,
    hasMore,
    search,
    dataLoadedOnce,
    onSearchChange,
    loadAllPages,
  } = useMembers();

  const listWithCurrentUserAsFirst = memberList.filter(m => m.id !== currentUser.id);
  listWithCurrentUserAsFirst.unshift(currentUser)

  useEffect(() => {
    if (dataLoadedOnce) {
      loadAllPages()
    }
  }, [dataLoadedOnce]);
  

  const sections = [
    {
      noItemsText: 'No user found!',
      fetchMoreData: () => {},
      loading,
      name: 'INSIDE_MEMBERS',
      hasMore,
      items: listWithCurrentUserAsFirst.map((member) => {
        let name = member.user.name;

        if (!name || !name.trim()) {
          name = member.user.first_name
            ? `${member.user.first_name} ${member.user.last_name || ''}`
            : member.user.email;
        }

        return {
          id: member.id,
          name: name,
          avatar: member.user.profile_image,
        };
      }),
    },
  ];

  return (
    <TeamMembersFilterOverlayUI
      {...props}
      sections={sections}
      search={search}
      onSearchChange={onSearchChange}
    />
  );
};

export const TeamMembersFilterOverlayUI = ({
  onClose,
  show,
  onChange,
  selectedMembers,
  sections,
  search,
  onSearchChange,
}) => {
  const handleSelectAll = () => {
    const section  = sections.find(s => s.name === 'INSIDE_MEMBERS')

    const allIds = section.items.map((member) => member.id);
    const selectedIds = selectedMembers.map((m) => m.id);
    const allSelected = allIds.every((id) => selectedIds.includes(id));

    const newValue = allSelected ? [] : section.items;
    onChange(newValue);
  };

  const handleSelect = (member) => {
    const newValue = selectedMembers.find((m) => m.id === member.id)
      ? selectedMembers.filter((m) => m.id !== member.id)
      : [...selectedMembers, member];

    onChange(newValue);
  };

  return (
    <MemberOverlay
      sections={sections}
      onSelectAll={handleSelectAll}
      onSelect={handleSelect}
      selected={selectedMembers}
      search={search}
      onSearchChange={onSearchChange}
      show={show}
      onClose={onClose}
    />
  );
};
