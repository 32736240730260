import React from 'react';
import { CommonGrid, TopContainer } from '../layouts';
import _ from 'lodash';
import { TopButtonArea, ActionFilters, GlobalFilter, SharePopup, BigButton } from '../common';
import { useScreenSettings } from '../helpers/configureHelper';
import { useProps } from '../hooks/useProps';
import { usePageTitle } from '../hooks/usePageTitle';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import { My3dModelDetailsPopupController } from '../common/My3dModelDetailsPopup/My3dModelDetailsPopup';
import { paginatedProductActions } from '../redux/slicers/admin/paginatedProductSlicer';
import { useDispatch } from 'react-redux';
import { ThumbnailContainerV2 } from '../common/ThumbnailContainerV2';
import { MODEL_TYPES } from '../constants/constants';
import AddToCollectionPopup from '../common/AddToCollectionPopup';
import { PropDetailsPopup } from '../common/PropDetailsPopup/PropDetailsPopup';
import CollectionIcon from '../components/icons/CollectionIcon';

export const EDIT_TYPE = {
  PRODUCT: 'PRODUCT',
  MODEL_3D: 'MODEL_3D',
};

export default function MyProps({
  title = 'Props',
  pageTitle = 'Props',
  cardType = 'props',
  apiConfig = {},
  modelType = MODEL_TYPES.PRODUCT,
  noDataText = 'No data found',
  useGlobalSearch,
  cardProps,
}) {
  const dispatch = useDispatch();
  const pageSettings = useScreenSettings(cardType);
  const { sortOptions, showTeamMemberFilter, defaultSortOption } = pageSettings;
  usePageTitle(pageTitle);

  const { defaultBrands, defaultCreatedBy } = apiConfig;
  const {
    list,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,

    selected,
    anySelected,
    allSelected,
    selectedList,
    sortBy,
    onSortChange,
    toggleAll,
    toggleItem,
    resetSelection,
    addToCollection,
  } = useProps({
    defaultSort: defaultSortOption,
    defaultBrands,
    defaultCreatedBy,
    useGlobalSearch,
    modelType,
  });

  const handleUpdateModelSuccess = (id, { name }) => {
    dispatch(paginatedProductActions.update({ id, name }));
  };

  return (
    <CommonGrid
      item={title}
      noDataText={noDataText}
      topContainer={
        useGlobalSearch ? null : (
          <TopContainer
            title={
              <>
                {title} {total => 1 && <small>({total})</small>}
              </>
            }
            item={cardType}
            hasData={hasData}
            buttonArea={
              <TopButtonArea
                hasData={hasData}
                item={cardType}
                topButtonList={[
                  {
                    element: BigButton,
                    bigBtnClass: 'lf-btn-2',
                    iconBefore: <CollectionIcon />,
                    buttonTitle: 'Add To Collection',
                    onClick: addToCollection,
                  },
                  {
                    element: SharePopup,
                    bigBtnClass: 'lf-btn-2',
                    bigButton: true,
                    selectedList,
                    modelType: 1,
                  },
                ]}
                anySelected={anySelected}
                selectedList={selectedList}
              />
            }
          />
        )
      }
      hasData={list.length > 0 || loading}
      list={list}
      filterArea={
        !useGlobalSearch && (
          <>
            <ActionFilters
              sortProps={{
                value: sortBy,
                data: sortOptions,
                onChange: onSortChange,
              }}
              selectedProps={{
                allSelected: allSelected,
                onSelectAll: () => toggleAll(),
              }}
              showTeamMemberFilter={showTeamMemberFilter}
            />
            <GlobalFilter />
          </>
        )
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainerV2
          items={list}
          modelType={modelType}
          selected={selected}
          cardProps={{
            fullWidth: true,
            capitalize: false,
            onSelect: toggleItem,
            ...cardProps,
          }}
        />
        {hasMore && <div id="whiteShadow" />}
      </InfiniteScroll>

      <PropDetailsPopup />
      <My3dModelDetailsPopupController onUpdateSuccess={handleUpdateModelSuccess} />
      <AddToCollectionPopup onSuccess={resetSelection} />
    </CommonGrid>
  );
}
