
import { Offcanvas } from 'react-bootstrap';
import Loader from '../../components/Loader/Loader';
import { CLOSE_ICON } from '../../constants/assetConstants';
import Image from '../Image';
import styles from './EditPopupUI.module.scss'

export const EditPopupUI = ({ show, onClose, loading, thumbnail, title, subtitle, children  }) => {
  return (
    <Offcanvas show={show} placement="end" className={styles.root} onClose={onClose}>
      <button className={styles.closeButton} onClick={onClose}>
        <Image src={CLOSE_ICON} />
      </button>

      <Offcanvas.Body>
        <Loader loading={loading} />

        <header className={styles.header}>
          <Image
            src={thumbnail}
            containerProps={{ className: styles.thumbnail }}
            placeholderWhileLoading
            ratio={1}
            rounded
          />

          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.headerInfo}>{subtitle}</div>
          </div>
        </header>
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
