export const isAdmin = (user) => user.role.is_admin;
export const isOwner = (user) => user.role.is_owner;
export const isNormalUser = (user) => user.role.is_staff;
export const isAdminOrOwner = (user) => isAdmin(user) || isOwner(user);

export const getUserDisplayName = (user) => {
  let name = user.name;

  if (!name || !name.trim()) {
    name = user.first_name
      ? `${user.first_name} ${user.last_name || ''}`
      : user.email;
  }

  return name;
};
