// this component is handling the search form on the header
import {
  selectSearchValue,
  setSearchValue,
} from '../../redux/slicers/search/searchSlicer';
import { SearchInput } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styles from './UniversalSearchForm.module.scss';

export default function UniversalSearchForm() {
  const dispatch = useDispatch();
  const searchVal = useSelector(selectSearchValue);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('s')) return;
    dispatch(setSearchValue(''))
  }, [location])

  const handleChange = (evt) => {
    const value = evt.target.value;
    dispatch(setSearchValue(value))
    const urlParams = new URLSearchParams();
    urlParams.set('s', value )
    setSearchParams(urlParams, { replace: true })
  };

  const handleClear = () => {
    dispatch(setSearchValue(''))
  }

  return (
    <SearchInput
      inputClassName={searchVal ? styles.inputNotEmpty : styles.input}
      value={searchVal}
      placeholder="Search"
      onChange={handleChange}
      onClear={handleClear}
    />
  );
}
