import cx from 'classnames';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DEFAULT_COLUMNS,
  MIN_COLUMNS,
  selectColumnsCount,
  setColumnCount,
} from '../../../../redux/slicers/admin/curatorLayoutSlicer';
import { CURATOR_MENU_SECTION } from '../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import {
  curatorSelector,
  selecteIsFilterOpen,
  selectSidebarExpanded,
  selectSidebarSection,
  setSidebarExpanded,
  toggleSidebarExpanded,
  toggleSidebarSection,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { selectActiveTab, selectIsSelectMapModeActive, TAB_NAME } from '../../../../redux/slicers/admin/curatorStylesSlicer';
import { getAppState } from '../../../../redux/store';
import ArrowDownIcon from '../../../icons/ArrowDownIcon';
import styles from './CuratorSidebarLayout.module.scss';

const useExpandedData = () => {
  const sidebarActiveSection = useSelector(selectSidebarSection);
  const styleActiveTab = useSelector(selectActiveTab);
  const isSelectMapModeActive = useSelector(selectIsSelectMapModeActive)
  const columnsCount = useSelector((state) => selectColumnsCount(state, sidebarActiveSection));

  const isProductsSection = sidebarActiveSection === CURATOR_MENU_SECTION.PRODUCTS;
  const isStyleTextures =
    sidebarActiveSection === CURATOR_MENU_SECTION.STYLE && styleActiveTab === TAB_NAME.TEXTURES;
  const isStyleMaterials =
    sidebarActiveSection === CURATOR_MENU_SECTION.STYLE && styleActiveTab === TAB_NAME.MATERIALS;
  const isCustomMapSelection =
    sidebarActiveSection === CURATOR_MENU_SECTION.STYLE && styleActiveTab === TAB_NAME.PROPERTIES && isSelectMapModeActive;
  const isExpandableSection = isProductsSection || isStyleTextures || isStyleMaterials || isCustomMapSelection;

  return {
    isExpandableSection,
    columnsCount: isExpandableSection ? columnsCount : DEFAULT_COLUMNS,
  };
};

export const CuratorSidebarLayout = ({ sectionName, inside, className, fixed, ...rest }) => {
  // const expanded = useSelector((state) => curatorSelector(state).sidebarExpanded);
  const { columnsCount } = useExpandedData();

  return (
    <div
      {...rest}
      id={`curatorSidebarLayout__${sectionName}`}
      style={{
        width: calculateSidebarWidth(columnsCount),
      }}
      className={cx(
        styles.root,
        className,
        // expanded && styles.expanded,
        inside && styles.inside,
        fixed && styles.fixed
      )}></div>
  );
};

CuratorSidebarLayout.Inner = (props) => {
  return (
    <div
      {...props}
      className={cx(styles.inner, props.className)}
      id={`CuratorSidebarLayoutScrollableContainer_${props.name}`}
    />
  );
};

CuratorSidebarLayout.FixedHeader = (props) => {
  return <div {...props} className={cx(styles.fixedHeader, props.className)}></div>;
};

CuratorSidebarLayout.Filter = function Filter({ className, ...rest }) {
  // const expanded = useSelector((state) => curatorSelector(state).sidebarExpanded);
  const activeSection = useSelector(selectSidebarSection);
  const columnsCount = useSelector((state) => selectColumnsCount(state, activeSection));

  const sidebarWidth = calculateSidebarWidth(columnsCount);
  return (
    <div
      {...rest}
      id="curatorSidebarLayoutFilter"
      style={{
        transform: `translateX(${sidebarWidth - defaultSidebarWidth}px)`,
        width: filterWidth,
      }}
      className={cx(
        styles.filter,
        className
        // expanded && styles.filterExpanded
      )}></div>
  );
};

CuratorSidebarLayout.Button = function Button({ location, ...rest }) {
  const dispatch = useDispatch();
  const filterOpen = useSelector(selecteIsFilterOpen);
  const { isExpandableSection } = useExpandedData();

  const { handleExpandClick, handleCollapseClick, handleMouseDown, handleMouseUp } =
    useSidebarResize();

  if (filterOpen && location === 'sidebar') return null;

  const handleButtonClick = () => {
    if (isExpandableSection) return;
    dispatch(toggleSidebarSection(null));
  };

  if (!isExpandableSection) {
    return <button
      type="button"
      {...rest}
      className={cx(styles.button, location === 'filter' && styles.butonFilterLocation)}
      onClick={handleButtonClick}>
      <ArrowDownIcon className={cx(styles.imgCollapse)} onClick={handleCollapseClick} />
    </button>;
  }

  return (
    <button
      type="button"
      {...rest}
      className={cx(styles.button, location === 'filter' && styles.butonFilterLocation)}
      onClick={handleButtonClick}>
      <ArrowDownIcon
        className={styles.imgExpand}
        onClick={handleExpandClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
      <ArrowDownIcon
        className={cx(styles.imgCollapse, styles.translated)}
        onClick={handleCollapseClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
    </button>
  );
};

const minWidth = 416;
const spacing = 8;
const cardWidth = 188;
const padding = 24; // padding 32 - one card padding
const cardWithSpacing = cardWidth + spacing;
const sidebarWidth = 96; // TODO: calculate it
const filterWidth = 231; // TODO: calculate it
const defaultSidebarWidth = padding + 2 * cardWithSpacing;

const calculateSidebarWidth = (columnsCount) => {
  return padding + columnsCount * cardWithSpacing;
};

const useSidebarResize = () => {
  const dispatch = useDispatch();
  const activeSection = useSelector(selectSidebarSection);
  const resizeRef = useRef({
    resized: false,
    mouseDown: false,
    initialClientX: null,
    sidebarElement: null,
    initialSidebarWidth: null,
    maxWidth: 1000,
    filter: null,
  });

  const getMaxWidth = () => {
    const availableSpace = window.innerWidth - sidebarWidth - filterWidth - 50; // 50 for spacing
    const columnsCount = Math.floor((availableSpace - padding) / cardWithSpacing);
    const maxWidth = padding + columnsCount * cardWithSpacing;
    return { maxWidth, columnsCount };
  };

  const handleExpandClick = () => {
    if (resizeRef.current.resized) return;
    const columnsCount = selectColumnsCount(getAppState(), activeSection);
    const maxColumnsCount = getMaxWidth().columnsCount;
    const newColumnsCount = columnsCount + 1;

    if (newColumnsCount <= maxColumnsCount) {
      dispatch(setColumnCount({ sectionName: activeSection, columnsCount: newColumnsCount }));
    }
  };
  const handleCollapseClick = () => {
    if (resizeRef.current.resized) return;
    const columnsCount = selectColumnsCount(getAppState(), activeSection);
    const newColumnsCount = columnsCount - 1;

    if (newColumnsCount >= MIN_COLUMNS) {
      dispatch(setColumnCount({ sectionName: activeSection, columnsCount: newColumnsCount }));
    } else {
      dispatch(toggleSidebarSection(null));
    }
  };

  const updateFilterPosition = (width) => {
    const { filter } = resizeRef.current;
    if (!filter) return;
    const translate = width - defaultSidebarWidth;
    filter.style.transform = `translateX(${translate}px)`;
  };

  const handleMouseMove = (e) => {
    const { initialClientX, mouseDown, sidebarElement, initialSidebarWidth, maxWidth } =
      resizeRef.current;
    if (!mouseDown) return;

    const delta = e.clientX - initialClientX;
    let width = initialSidebarWidth + delta;

    if (width < minWidth) {
      width = minWidth;
    }

    if (width > maxWidth) {
      width = maxWidth;
    }

    sidebarElement.style.width = `${width}px`;
    updateFilterPosition(width);
    resizeRef.current.resized = true;
  };

  const handleMouseUp = (e) => {
    const { sidebarElement, maxWidth } = resizeRef.current;

    resizeRef.current.mouseDown = false;
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);

    // TODO: calculate width
    const currentWidth = sidebarElement.clientWidth;
    const contentWidth = currentWidth - padding;
    let columnsCount = Math.round(contentWidth / cardWithSpacing);
    let width = calculateSidebarWidth(columnsCount);

    if (width > maxWidth) {
      // minus one is enough as we don't allow to resize more
      width = width - cardWithSpacing;
      columnsCount -= 1;
    }

    if (width < minWidth) {
      width = minWidth;
      columnsCount += 1;
    }

    width = calculateSidebarWidth(columnsCount);
    sidebarElement.style.width = `${width}px`;
    updateFilterPosition(width);
    dispatch(
      setColumnCount({
        sectionName: activeSection,
        columnsCount,
      })
    );
  };

  const handleMouseDown = (e) => {
    const sectionId = `curatorSidebarLayout__${activeSection}`;
    resizeRef.current.mouseDown = true;
    resizeRef.current.resized = false;
    resizeRef.current.initialClientX = e.clientX;
    resizeRef.current.sidebarElement = document.getElementById(sectionId);
    resizeRef.current.initialSidebarWidth = document.getElementById(sectionId).clientWidth;
    resizeRef.current.filter = document.getElementById('curatorSidebarLayoutFilter');

    // calculate max width
    resizeRef.current.maxWidth = getMaxWidth().maxWidth;

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  return {
    handleExpandClick,
    handleCollapseClick,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  };
};
