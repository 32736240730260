import { Carousel } from 'react-bootstrap';
import cx from 'classnames'
import styles from './PublicCarousel.module.scss'

export default function PublicCarousel({className, data, time = 3000 }) {
  return (
    <div className={cx(styles.carouselContainer, styles[className])}>
      <Carousel
        slideshowSpeed={time}
        indicators={false}
        nextIcon={false}
        prevIcon={false}
        fade
      >
        {data &&
          data?.images?.map((item, index) => (
            <Carousel.Item interval={time} key={item.id}>
              <img src={item.image_file} alt="slide img" className={styles.carouselImg} />
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}
