import { createContext, useContext } from "react";

const FilterContext = createContext({
  hideSidemenu: false,
  showDrilldownMenu: false,
  toggleFavorite: () => {},
  breadcrumbs: null,
  setBreadCrumbs: () => {},
  addCollections: () => {},

  setPageToDefault: () => {}
});

export default FilterContext;

export const useFilterContext = () => useContext(FilterContext)