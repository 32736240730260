import { createSlice } from "@reduxjs/toolkit";
import { paginatedTexturesSelector } from '../textures/paginatedTexturesSlicer'

export const TAB_NAME = {
  TEXTURES: 'TEXTURES',
  MATERIALS: 'MATERIALS',
  COLORS: 'COLORS',
  PROPERTIES: 'PROPERTIES',
}

const initialState = {
  selectedTexture: null, // this one is not needed, refactor and try to remove it
  selectedTextureId: null,
  selectedMaterial: null,
  textureFilterOpen: false,
  materialFilterOpen: false,
  activeTab: TAB_NAME.TEXTURES,
  textureFilterValue: [],
  selectedColor: '#ff0000',
  selectMapMode: null,
  textureInfoOpen: false,
  lastColorChangeMs: null,

  applyTextureInProgress: false,
}

const curatorStylesSlicer = createSlice({
  name: "curatorStyles",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    selectTexture: (state, { payload }) => {
      state.selectedTexture = payload;
    },
    setSelectedTextureId: (state, { payload }) => {
      state.selectedTextureId = payload;
    },
    selectMaterial: (state, { payload }) => {
      state.selectedTexture = payload;
    },
    setSelectMapMode: (state,{ payload}) => {
      state.selectMapMode = payload;
    },
    changeTextureFilterValue: (state, { payload }) => {
      state.textureFilterValue = state.textureFilterValue.includes(payload)
        ? state.textureFilterValue.filter((x) => x !== payload)
        : [...state.textureFilterValue, payload];
    },
    toggleTextureFilterOpen: (state, { payload }) => {
      state.textureFilterOpen =
        typeof payload !== "undefined" ? payload : !state.textureFilterOpen;
    },
    toggleMaterialFilterOpen: (state, { payload }) => {
      state.materialFilterOpen =
        typeof payload !== "undefined" ? payload : !state.textureFilterOpen;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setSelectedColor: (state, { payload }) => {
      state.selectedColor = payload;
    },
    setTextureInfoOpen: (state, { payload }) => {
      state.textureInfoOpen = payload
    },
    setApplyTextureInProgress: (state, { payload }) => {
      state.applyTextureInProgress = payload
    }
  },
});

export const {
  selectTexture,
  selectMaterial,
  setActiveTab,
  toggleTextureFilterOpen,
  toggleMaterialFilterOpen,
  changeTextureFilterValue,
  setSelectedColor,
  setSelectMapMode,
  setTextureInfoOpen,
  setSelectedTextureId,
  setApplyTextureInProgress,
} = curatorStylesSlicer.actions;
export const curatorStyleActions = curatorStylesSlicer.actions;

export const curatorStylesSelector = (state) => state.curatorStyles;
export const curatorStylesReducer = curatorStylesSlicer.reducer;
export default curatorStylesReducer;


export const selectTextureInfoOpen = (state) => state.curatorStyles.textureInfoOpen
export const selectActiveTab = (state) => state.curatorStyles.activeTab
export const selectSelectMapMode = (state) => state.curatorStyles.selectMapMode
export const selectLastColorChangeMs = (state) => state.curatorStyles.lastColorChangeMs
export const selectApplyTextureInProgress = (state) => state.curatorStyles.applyTextureInProgress
export const selectIsSelectMapModeActive = (state) => Boolean(state.curatorStyles.selectMapMode);

export const selectSelectedTexture = (state) => {
  const { selectedTexture, selectedTextureId } = state.curatorStyles

  if (!selectedTexture && !selectedTextureId) return null;
  
  if (selectedTextureId) {
    const { list } = paginatedTexturesSelector(state);
    return list.find(t => t.id === selectedTextureId) || null;
  }
  
  return selectedTexture;
}