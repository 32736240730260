import { createPaginatedSlice } from "../../../helpers/reduxHelper";

const paginatedColor = createPaginatedSlice('paginatedColor', {
  newItemPosition: 'end',
  defaultState: {
    perPage: 10000,
  }
});

export const paginatedColorActions = paginatedColor.actions
export const paginatedColorSelector = state => state.paginatedColor;
export const paginatedColorReducer = paginatedColor.reducer;
export default paginatedColorReducer
