import { useState } from "react"
import { NAME_SPECIAL_CHARS, NAME_SPECIAL_CHARS_V2 } from "../constants/validationConstants";

export const useSelectInputValue = ({ maxLength = Infinity } = {}) => {
  const [value, setValue] = useState('');

  const onChange = (newValue) => {
    if (newValue.length > maxLength) return;

    const normalizedValue = newValue.replace(NAME_SPECIAL_CHARS_V2, '');
    setValue(normalizedValue);
  }

  return [value, onChange]
}