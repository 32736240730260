import { useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { objectToSearchParams } from '../helpers/jsHelper';

export const MODE = {
  QUERY: 'QUERY',
  PARAMS: 'PARAMS',
};

export const useApiList = (
  {
    api,
    params = {},
    mode = MODE.QUERY,
    query = '',
    defaultPerPage = 20,
    skip = false,
    dataFormatter,
  },
  deps = []
) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [hasMore, setHasMore] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [total, setTotal] = useState(0);
  const prevSkip = usePrevious(skip);
  const listRef = useRef([]);
  const totalRef = useRef(0);
  listRef.current = list;
  totalRef.current = total;

  useEffect(() => {
    if (prevSkip && !skip) {
      fetchList(1);
    }
  }, [skip]);

  useEffect(() => {
    fetchList(1);
  }, [...Object.values(params), query, ...deps]);

  useEffect(() => {
    if (totalRef.current === 0) {
      setHasData(false);
    } else {
      setHasData(true);
    }
  }, [totalRef.current]);

  const fetchList = async (nextPage) => {
    if (skip) return;

    setLoading(true);

    let res;
    if (mode === MODE.QUERY) {
      const paramsStr = objectToSearchParams(params);
      res = await api(`&page=${nextPage}&per_page=${perPage}&${paramsStr}&${query}`);
    } else {
      res = await api({ page: nextPage, perPage, ...params });
    }

    if (res?.results) {
      const formattedData = dataFormatter ? res.results.map(dataFormatter) : res.results;

      nextPage === 1 ? setList(formattedData) : setList([...list, ...formattedData]);
      setHasMore(Boolean(res.next_link));

      if (nextPage === 1) {
        setTotal(res.count);
      }
    }

    setLoading(false);
    setPage(nextPage);
  };

  const fetchNextPage = () => {
    fetchList(page + 1);
  };

  const refetch = () => {
    fetchList(1);
  };

  const add = (items) => {
    setList([...items, ...listRef.current]);
    setTotal(totalRef.current + items.length);
  };

  return {
    list,
    loading,
    page,
    hasMore,
    hasData,
    refetch,
    fetchNextPage,
    total,
    add,
  };
};
