import { useEffect, useState } from 'react';
import { CommonGrid, TopContainer } from '../layouts';
import 'react-phone-input-2/lib/style.css';
import {
  TopButtonArea,
  SharePopup,
  ActionFilters,
  GlobalFilter,
  RightSidebar,
  Image,
  Input,
  SelectFormGroup,
} from '../common';
import _ from 'lodash';
import {
  apiEditProject,
} from '../helpers/api';
import { useScreenSettings } from '../helpers/configureHelper';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import ReactTimeAgo from 'react-time-ago';
import constants, { CATEGORY_FILTER_NAME, CATEGORY_FILTER_TYPE } from '../constants/constants';
import { apiErrorHandler, apiGetCachedList } from '../helpers/axiosHelper';
import { useSharedFiles } from '../hooks/useSharedFiles';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import { useUserContext } from '../contexts/UserContext';
import { useFilterDropdownOptions } from '../hooks/useFilterDropdownOptions';
import { TextureDetailsModal } from '../common/TextureDetailsModal';
import { ThumbnailContainerV2 } from '../common/ThumbnailContainerV2';
import { PropDetailsPopup } from '../common/PropDetailsPopup/PropDetailsPopup';
import { My3dModelDetailsPopupController } from '../common/My3dModelDetailsPopup/My3dModelDetailsPopup';
import AddToCollectionPopup from '../common/AddToCollectionPopup';
import { setTitle } from '../helpers/jsHelper';
const { PROJECT_TYPE_OPTIONS } = constants;

export default function SharedFiles({ cardType = 'sharedFiles', useGlobalSearch, noDataText, cardProps }) {
  
  let title = 'Shared Files';
  const userCtx = useUserContext();
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && currentUser.name;

  const [editProject, setEditProject] = useState(null);
  const [savingProject, setSavingProject] = useState(false);
  const [globalTags, setGlobalTags] = useState([]);

  const categoryOptions = useFilterDropdownOptions({
    filterName: CATEGORY_FILTER_NAME,
    filterType: CATEGORY_FILTER_TYPE,
  });

  const pageSettings = useScreenSettings(cardType);
  const {
    filterOptions,
    sortOptions,
    defaultSortOption,
    showSharedFilter,
  } = pageSettings;

  const {
    list,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,
    filterBy,
    sortBy,


    selected,
    anySelected,
    allSelected,
    selectedList,
    onFilterByChange,
    onSortChange,
    toggleAll,
    toggleItem,
    refetchList,
  } = useSharedFiles({
    filterByKey: 'type',
    // requestParams, 
    defaultSort: defaultSortOption, 
    useGlobalSearch,
  })

  function onProjectChange(field, value) {
    let item = { ...editProject };
    item[field] = value;
    setEditProject(item);
  }

  const saveProject = async () => {
    if (!editProject) return;
    setSavingProject(true);
    const { name, project_type, tags, filteroptions } = editProject;
    let payload = {
      name: name,
      project_type: project_type?.value,
      // file: filejson,
      // is_favourite: editItem.is_favourite,
      // thumb: thumbnailfile
    };
    if (filteroptions && filteroptions.length > 0) {
      payload.filteroptions = _.chain(filteroptions)
        .map((o) => o.value)
        .join(',')
        .value();
    }
    if (tags && tags.length > 0) {
      payload.tags = _.chain(tags)
        .map((o) => o.label)
        .toArray()
        .value();
    }

    await new Promise((resolve, reject) => {
      apiEditProject(editProject.id, payload)
        .then((res) => {})
        .catch(apiErrorHandler)
        .finally(() => {
          setSavingProject(false);
          setEditProject(null);
        });
    });
  };

  useEffect(async () => {
    setTitle(title);
    let tagList = await apiGetCachedList('global_tags');
    if (tagList && tagList.length > 0) {
      setGlobalTags(tagList);
    }
  }, []);


  return (
    <CommonGrid
      noDataText={noDataText}
      topContainer={
        <TopContainer
          title={
           !useGlobalSearch &&  <>
              {title} {total > 0 ? <small>({total})</small> : null}
            </>
          }
          item={cardType}
          loading={loading}
          hasData={hasData}
          buttonArea={
            !useGlobalSearch && <TopButtonArea
              hasData={hasData}
              item={cardType}
              addNewButtonArea={null}
              loading={loading}
              selectedList={selectedList}
              topButtonList={[
                {
                    element: SharePopup,
                    bigButton: true,
                    bigBtnClass: 'lf-btn-2',
                    onSuccess: refetchList,
                    //model type 0 for mix files
                    modelType: "sharedFiles",
                },
              ]}
              anySelected={anySelected}
            />
          }
        />
      }
      hasData={list.length > 0 || loading}
      item={cardType}
      list={list}
      filterArea={
        !useGlobalSearch && <>
          <ActionFilters
            filterProps={{
              value: filterBy,
              data: filterOptions,
              onChange: onFilterByChange,
            }}
            sortProps={{
              value: sortBy,
              data: sortOptions,
              onChange: onSortChange,
            }}
            showSharedFilter={showSharedFilter}
          />
          <GlobalFilter />
        </>
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
        hasChildren={hasData}
      >
          <ThumbnailContainerV2 
            items={list}
            selected={selected}
            cardProps={{
              fullWidth: true,
              hideFavourite: true,
              onSelect: (id, data, originalData) => toggleItem(originalData.id),
              ...cardProps,
            }}
            // TODO!!! deleteSharedModel: apiDeleteSharedDetails,
          />
        {hasMore && (
          <div id="whiteShadow" />
        )}
      </InfiniteScroll>


      <TextureDetailsModal />
      <PropDetailsPopup />
      <My3dModelDetailsPopupController />
      <AddToCollectionPopup />


      <RightSidebar
        className="rightSidebar"
        initialShowOpen={!_.isNil(editProject)}
        onClose={() => setEditProject(null)}
        title={
          <header className="header">
            {editProject?.src && (
              <Image src={editProject.src} alt="Avatar" className="avatar" rounded />
            )}

            <div>
              <div className="title">Edit {editProject?.name}</div>
              <div className="headerInfo">
                {`${userName ? `${userName} · ` : ''}`}
                {editProject?.updated_at && (
                  <ReactTimeAgo date={Date.parse(editProject?.updated_at)} />
                )}
              </div>
            </div>
          </header>
        }
        body={
          <>
            <Form className="d-block">
              <Form.Group className="search-form-groups">
                <Input
                  label="Name"
                  name="name"
                  onChange={(e) => onProjectChange(e.name, e.value)}
                  value={editProject?.name || ''}
                  placeholder="Project Name"
                />
              </Form.Group>
              <hr className="solid" />
              <SelectFormGroup
                // error // TO DO
                fullWidth
                label="Project Type"
                id="project_type"
                name="project_type"
                onChangeCallback={(e) => onProjectChange('project_type', e)}
                value={editProject?.project_type}
                options={PROJECT_TYPE_OPTIONS}
                selectLabel="project type"
              />
              <hr className="solid" />
              <SelectFormGroup
                fullWidth
                label="Categories"
                id="filteroptions"
                name="filteroptions"
                onChangeCallback={(e) => onProjectChange('filteroptions', e)}
                value={editProject?.filteroptions}
                // TODO: work on category options here
                options={categoryOptions} 
                selectLabel="category"
                allowCreatable
                isMulti
              />
              <hr className="solid" />
              <SelectFormGroup
                fullWidth
                label="Styles"
                id="tags"
                name="tags"
                onChangeCallback={(e) => onProjectChange('tags', e)}
                value={editProject?.tags}
                options={globalTags}
                selectLabel="style"
                isMulti
                allowCreatable
              />
              <Row>
                <Col>
                  <div className="float-end">
                    <Button
                      variant="secondary"
                      className="m-2"
                      onClick={() => {
                        setEditProject(null);
                        setSavingProject(false);
                      }}>
                      Cancel
                    </Button>
                    <Button
                      onClick={saveProject}
                      variant="primary"
                      className="my-2"
                      disabled={savingProject}>
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        }
      />

    </CommonGrid>
  );
}
