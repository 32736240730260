import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Switch from '../components/admin/switch/index';

export default function ToggleRow({ name, toggle1, toggle2, index }) {
  return (
    <tr key={index}>
      <td colSpan={3} className="notif-row">
        <Row>
          <Col>{name}</Col>
          <Col>
            {toggle1 && (
              <Switch
                checked={toggle1.checked}
                onToggle={(checked) => toggle1.onChange(toggle1.tag, checked)}
                toggleId={toggle1.toggleId}
              />
            )}
            {toggle2 && (
              <Switch
                checked={toggle2.checked}
                onToggle={(checked) => toggle2.onChange(toggle2.tag, checked)}
                toggleId={toggle2.toggleId}
              />
            )}
          </Col>
        </Row>
      </td>
    </tr>
  );
}
