import { roomUpdateApi } from '../api/myprojects';
import { MODEL_TYPES } from '../constants/constants';
import { paginatedRoomsSelectors } from '../redux/slicers/admin/paginatedRooms';
import { useModelActions } from './useModelActions';

export const useTemplateActions = ({ item }) => {
  const { favouriteLoading, onFavouriteToggle, onShare, addToCollection } = useModelActions({
    item,
    modelType: MODEL_TYPES.ROOM,
    selectors: paginatedRoomsSelectors,
    updateApi: roomUpdateApi,
  });

  return {
    favouriteLoading,
    onFavouriteToggle,
    onShare,
    addToCollection,
  };
};


