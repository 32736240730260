import { createPaginatedSlice } from '../../helpers/reduxHelper';

const collections = createPaginatedSlice('collections');

export const collectionsActions = collections.actions;
export const collectionsSelector = (state) => state.collections;
export const collectionsReducer = collections.reducer;
export default collectionsReducer;

export const collectionsSelectors = collections.LF.selectors;
