import { dispatcher } from '../../../../helpers/projectHelper';
import { setGlobalFov } from '../../../../redux/slicers/camera/cameraSettings';
import { UndoRedoCamera } from './UndoRedoCamera';
import { UndoRedoCuratorSettings } from './UndoRedoCuratorSettings';
import { UndoRedoEnvironmentLight } from './UndoRedoEnvironmentLight';
import { UndoRedoLight } from './UndoRedoLight';
import { UndoRedoMetadata } from './UndoRedoMetadata';
import { UndoRedoObjectProperties } from './UndoRedoObjectProperties';
import { UndoRedoSafeCameraResolution } from './UndoRedoSafeCameraResolution';
import { UndoRedoUnityObject } from './UndoRedoUnityObject';

const ACTION = {
  SELECT_OBJECT: 'SELECT_OBJECT',
  DELETE_OBJECT: 'DELETE_OBJECT',
  CHANGE_FOV: 'CHANGE_FOV',
};

export class UndoRedo {
  constructor() {
    this.history = new LinkedList();
    this.currentItem = 'tail';
    this.lastAction = null;
  }

  setUnityContext = (unityContext) => {
    this.unityContext = unityContext;
  };

  setUnityLight = (unityLight) => {
    this.unityLight = unityLight;
  };

  setUnityHDRI = (unityHDRI) => {
    this.unityHDRI = unityHDRI;
  };

  setUnityRedux = (unityRedux) => {
    this.unityRedux = unityRedux;
  };

  setUnityColor = (unityColor) => {
    this.unityColor = unityColor;
  };

  setUnityCameraFields = (unityCameraFields) => {
    this.unityCameraFields = unityCameraFields;
  }

  setUnityMaterial = (unityMaterial) => {
    this.unityMaterial = unityMaterial;
  }

  setUnityShortcuts = (unityShortcuts) => {
    this.unityShortcuts = unityShortcuts;
  }

  setUnityMetadata = (unityMetadata) => {
    this.unityMetadata = unityMetadata;
  }

  resetHistory = () => {
    this.history = new LinkedList();
    this.currentItem = 'tail';
    this.lastAction = null;
    this.objectProperties?.unsubscribe();
    this.unityObject?.unsubscribe();
    this.init()
  }

  pause = () => {
    this.paused = true;
  }

  resume = () => {
    this.paused = false;
  }

  undo = async () => {
    if (!this.currentItem) return;
    if (this.currentItem === 'head') return;

    const item = this.currentItem === 'tail' ? this.history.tail : this.currentItem;

    if (!item) {
      // this is case when history is empty
      return;
    }

    if (window.DEBUG_UNDO_REDO) {
      const ACTION = item.value.__ACTION__;
      // eslint-disable-next-line
      debugger;
    }

    if (item.value.__PAUSE) this.pause(); // pause history before action
    await item.value.undo();
    if (item.value.__PAUSE) this.resume(); // resume history before action

    this.currentItem = item.prev || 'head';
    this.lastAction = 'undo';
  };

  redo = async () => {
    // console.log('UNDOREDO redo', this)
    if (!this.currentItem) return;
    if (this.currentItem === 'tail') return;


    const item = this.currentItem === 'head' ? this.history.head : this.currentItem.next;

    if (!item) {
      this.currentItem = 'tail';
      return;
    }

    if (window.DEBUG_UNDO_REDO) {
      const ACTION = item.value.__ACTION__;
      // eslint-disable-next-line
      debugger;
    }

    if (item.value.__PAUSE) this.pause(); // pause history before action
    await item.value.redo();
    if (item.value.__PAUSE) this.resume(); // resume history before action
    
    this.currentItem = item;
    this.lastAction = 'redo';
  };

  getCurrentNode = () => {
    if (this.currentItem === 'head') {
      // it means that we're currently undone the first item, so current item is null
      return null;
    }
    if (this.currentItem === 'tail') return this.history.tail;

    if (this.lastAction === 'undo') {
      return this.currentItem;
    }

    if (this.lastAction === 'redo') {
      return this.currentItem;
    }

    throw new Error('Unable to get current history node');
  };

  createAction =
    (func, { pause } = {}) =>
    async (...args) => {
      if (this.paused) return;

      const newHistoryValue = await func(...args);
      if (!newHistoryValue) return;

      // TODO: before `if (!newHistoryValue) return;` when all undo/redo methods will be using pause
      if (pause) {
        newHistoryValue.__PAUSE = true;
      }

      // TODO: add debug configuration for this console.log
      console.log("CREATE_ACTION", newHistoryValue)

      const currentNode = this.getCurrentNode();
      this.history.pushValue(newHistoryValue, currentNode);
      this.currentItem = 'tail';
      this.lastAction = null;
    };

  createSimpleAction = ({ changeStateAction }) => {

    return this.createAction(({ prevValue, nextValue } = {}) => {
      return {
        undo: () => {
          changeStateAction(prevValue);
        },
        redo: () => {
          changeStateAction(nextValue);
        },
      }; 
    })
  }

  changeFOV = this.createAction(({ fov, prevFov }) => {
    const historyValue = {
      data: {
        fov,
        prevFov,
      },
      undo: () => {
        dispatcher(setGlobalFov(prevFov));
        this.unityContext.handleCameraFov(prevFov);
      },
      redo: () => {
        dispatcher(setGlobalFov(fov));
        this.unityContext.handleCameraFov(fov);
      },
    };
    return historyValue;
  });

  init = () => {
    this.light = new UndoRedoLight({ undoRedoInstance: this, unityLight: this.unityLight });
    this.environmentLight = new UndoRedoEnvironmentLight({ undoRedoInstance: this, unityHDRI: this.unityHDRI });
    this.safeCameraResolution = new UndoRedoSafeCameraResolution({ undoRedoInstance: this });
    this.objectProperties = new UndoRedoObjectProperties({
      undoRedoInstance: this,
      unityObjectProperties: this.unityRedux,
      unityColor: this.unityColor,
      unityContext: this.unityContext,
      unityMaterial: this.unityMaterial,
    });
    this.unityObject = new UndoRedoUnityObject({
      undoRedoInstance: this,
      unityContext: this.unityContext,
      unityShortcuts: this.unityShortcuts,
    });
    this.camera = new UndoRedoCamera({ 
      undoRedoInstance: this, 
      unityCameraFields: this.unityCameraFields,
      unityContext: this.unityContext,
    })
    this.metadata = new UndoRedoMetadata({ undoRedoInstance: this, unityMetadata: this.unityMetadata })
    this.settings = new UndoRedoCuratorSettings({ undoRedoInstance: this })
  };
}

class Node {
  constructor(value, prev, next = null) {
    this.value = value;
    this.prev = prev;
    this.next = next;
  }
}

class LinkedList {
  // this linked list will work only for undo/redo, do not use it in different places

  constructor() {
    this.head = null;
    this.tail = null;
  }

  pushValue = (value, afterNode) => {
    const prev = afterNode;
    const newNode = new Node(value, prev);

    if (prev) {
      prev.next = newNode;
    }

    if (prev === null) {
      this.head = newNode;
    }

    this.tail = newNode;
  };
}

export const undoRedo = new UndoRedo();

window.undoRedo = undoRedo;
