import { uniqueId } from 'lodash';
import {
  API_ROOT_URL,
  GET_MEMBERS_API,
  SEND_INVITE_API,
  GET_PENDING_LIST,
  DELETE_PENDING_INVITATION,
} from '../config/constant/apiConstant';
import api from '../helpers/axiosHelper';
import { objectToSearchParams } from '../helpers/jsHelper';
import {
  setMembers,
  setMemberUpdateStatus,
  setPendingInviteList,
} from '../redux/slicers/team/teamSlicer';
import { paginatedTeamActions } from '../redux/slicers/team/paginatedTeamSlicer';
import { createGetListAction } from './products';
import { GET_MEMBERS_LIST_API } from '../helpers/api';
import { searchMembersActions } from '../redux/slicers/searchMembersSlicer';

export const MembersApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_MEMBERS_API)
    .success(({ results }) => {
      dispatch(setMembers(results.map((items) => ({ ...items, state: 'false' }))));
    })
    .error(() => {})
    .send();
};

export const getMembersApi = createGetListAction({
  //   endpoint: GET_MEMBERS_LIST_API,
  endpoint: GET_MEMBERS_API,
  actions: searchMembersActions,
  defaultSort: 'name',
});

export const SendInviteApi = (formData) => (dispatch) => {
  console.log(formData);
  api()
    .root(API_ROOT_URL)
    .post(SEND_INVITE_API)
    .data(formData)
    .success(({ data }) => {})
    .error(() => {})
    .send();
};

export const PendingInviteListApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_PENDING_LIST)
    .success(({ results }) => {
      dispatch(setPendingInviteList(results.map((items) => ({ ...items, state: 'false' }))));
    })
    .error(() => {})
    .send();
};

export const DeletePendingInvite = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .delete(DELETE_PENDING_INVITATION + id + '/')
    .success((res) => {})
    .error(() => {})
    .send();
};

export const MemberStatusApi = (id, formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .patch(GET_MEMBERS_API + id + '/')
    .data(formData)
    .success((res) => {
      dispatch(setMemberUpdateStatus(res));
    })
    .error(() => {})
    .send();
};

export const getTeamMemberListApi =
  ({ page, perPage, sort, project_type, ...rest }) =>
  (dispatch) => {
    const searchParams = objectToSearchParams({ page, per_page: perPage, sort, ...rest });
    const API_URL = `${GET_MEMBERS_API}?${searchParams}`;
    const requestId = uniqueId();

    dispatch(paginatedTeamActions.fetchListStart({ requestId }));

    return api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        dispatch(
          paginatedTeamActions.fetchListSuccess({
            page: page,
            data,
            requestId,
          })
        );
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        dispatch(paginatedTeamActions.fetchListFailed({ error, requestId }));
      })
      .send();
  };
