import {createSlice} from "@reduxjs/toolkit";

const rendersPreviewsExp = createSlice({
    name: 'rendersPreviewsExp',
    initialState: {
        
        renderPreviewCurrentCamera: {},
        renderPreviewExpand: {},
        renderData: [],
        previewData: []
    },
    reducers: {
        setRenderPreviewCurrentCamera: (state, {payload}) => {
            state.renderPreviewCurrentCamera = payload
        },
        setRenderPreviewExpand: (state, {payload}) => {
            state.renderPreviewExpand = payload
        },
        setRenderData: (state, {payload}) => {
            state.renderData = payload
        },
        setPreviewData: (state, {payload}) => {
            state.previewData = payload
        }
    }
})

export const {
    renderPreviewCurrentCamera, setRenderPreviewCurrentCamera,
    renderPreviewExpand, setRenderPreviewExpand,
    renderData, setRenderData,
    previewData, setPreviewData,
 } = rendersPreviewsExp.actions;
export const rendersPreviewsExpSelector = state => state.rendersPreviewsExp;
export const rendersPreviewsExpReducer = rendersPreviewsExp.reducer;
export default rendersPreviewsExpReducer


export const selectRenderPreviewDetailsDialogOpen = (state) => Boolean(state.rendersPreviewsExp?.renderPreviewExpand?.id); 