import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { FILTER } from '../components/admin/curator/CuratorFilter/CuratorFilter';
import { changeSideFilter, resetFilters, sidebarSelector } from '../redux/slicers/admin/sidebarSlicer';
import { ClickablePill } from './';

const defaultLabels = {
  [FILTER.MY_FAVOURITES]: 'My Favourites',
};

export default function GlobalFilter() {
  const dispatch = useDispatch();
  const { filtersData, filterValues } = useSelector(sidebarSelector);

  if (filterValues.length === 0) return null;

  // create filter tags based on selected values
  const selectedFilterList = filterValues.map((value) => {
    let label = defaultLabels[value];

    if (!label && value.startsWith('brand')) {
      const [, id] = value.split('_');
      label = filtersData.brands.find((b) => b.id === parseInt(id))?.name;
    }

    if (!label && value.startsWith('collection_')) {
      const [, id] = value.split('_');
      label = filtersData.collections.find((c) => c.id === parseInt(id))?.name;
    }

    if (!label) {
      const option = filtersData.filteroptions.find(
        (option) => `option_${option.filter_label}_${option.id}` === value
      );
      label = option?.name;
    }

    return {
      value,
      label,
    };
  });

  const handleFilterClick = (value) => {
    dispatch(changeSideFilter(value));
  };

  const settings = {
    arrows: true,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: false,
  }

  return (
    <div className="container-fluid px-0">
      <div className="pb-4 header-name-of-my-projectbtns">
        <div className="d-flex align-items-center justify-content-between">
          <div className="selectedGlobalFilter scl">
            {
              <Slider {...settings}>
              {selectedFilterList.map((filter, i) => {
                return (
                  <ClickablePill
                    key={filter.key}
                    label={filter.label}
                    onClick={() => handleFilterClick(filter.value)}
                  />
                );
              })}
              </Slider>
            }
          </div>

          <button role="button" className="clrbtn" onClick={() => dispatch(resetFilters())}>
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
}
