import { React, useState, useEffect, useRef } from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import {
  apiGetPlatforms,
  apiGetProfessions,
  apiGetIndustries,
  apiGetIndustriesPreferences,
  apiGetUserDetails,
  apiCreateProfession,
  apiCreatePlatform,
} from '../helpers/api';
import { SelectFormGroup } from '../common';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import _ from 'lodash';
import { addValueLabelToList } from '../helpers/jsHelper';
import { useCountryStateCity } from '../hooks/useCountryStateCity';
import { SelectFormGroupV2 } from './SelectFormGroup';

const emptyUserDetails = {
  industry: [],
  country: null,
  usecase: [],
  profession: {},
  city: null,
  mobile_number: '',
  state: null,
};

export default function UserInfo({
  //setIndustriesPreference,
  countryMessage,
  setCountryMessage,
  stateMessage,
  setStateMessage,
  cityMessage,
  setCityMessage,
  contactNumberMessage,
  setContactNumberMessage,
  professionMessage,
  setProfessionMessage,
  //industryMessage,
  //setIndustryMessage,
  platformMessage,
  setPlatformMessage,
  onUserPreferenceDataChange,
  inSettings,
  userPreferenceData = {},
}) {
  const { Country, State, City, loading: locationListLoading } = useCountryStateCity();

  const [createProfessionInProgress, setCreateProfessionInProgress] = useState(false);
  const [createPlatformInProgress, setCreatePlatformInProgress] = useState(false);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [platformOptions, setPlatformOptions] = useState([]);
  const countries = Country.getAllCountries()?.map((country) => ({
    label: country.name,
    value: country.name,
    ...country,
  }));
  const [updatedStates, setUpdatedStates] = useState([]);
  const [dataReady, setDataReady] = useState(inSettings ? false : true);
  const [updatedCities, setUpdatedCities] = useState([]);
  const [userDetails, setUserDetails] = useState(
    Object.assign(emptyUserDetails, userPreferenceData)
  );
  const isMounted = useRef(false);

  useEffect(() => {
    apiGetProfessions().then((res) => {
      if (res) {
        setProfessionOptions(addValueLabelToList(res.results));
      }
    });
    apiGetIndustries().then((res) => {
      if (res) {
        const { results } = res;
        let industries = [];
        results.forEach((item) => {
          industries.push({ label: item.name, value: item.id });
        });

        setIndustryOptions(industries);
      }
    });

    apiGetPlatforms().then((res) => {
      if (res) {
        const { results } = res;
        let platforms = [];
        results.forEach((item) => {
          platforms.push({ label: item.name, value: item.id });
        });
        setPlatformOptions(platforms);
      }
    });
  }, []);

  useEffect(() => {
    if (!inSettings && dataReady) {
      if (_.isFunction(onUserPreferenceDataChange)) {
        onUserPreferenceDataChange(userDetails);
      }
    }
  }, [userDetails, dataReady]);

  useEffect(() => {
    if (inSettings) {
      if (isMounted.current) {
        if (userDetails) {
          onUserPreferenceDataChange(userDetails);
        }
      } else {
        isMounted.current = true;
      }
    }
  }, [userDetails]);

  // useEffect(() => {
  //   updateCityList(); ///////////////////////////////// MERGE CHECK
  // }, [userDetails.country]);
  useEffect(() => {
    if (
      inSettings &&
      countries.length > 0 &&
      !dataReady &&
      platformOptions?.length > 0 &&
      professionOptions?.length > 0
    ) {
      setDataReady(true);
      apiGetUserDetails().then((response) => {
        let country = _.find(
          countries,
          (opt) => _.capitalize(opt.label) === _.capitalize(response.country)
        );
        let state = _.find(
          updateStates(country?.isoCode),
          (opt) => _.capitalize(opt.label) === _.capitalize(response.state)
        );
        let city = _.find(
          updateCities(country?.isoCode, state?.isoCode),
          (opt) => _.capitalize(opt.label) === _.capitalize(response.city)
        );
        let profession = _.find(professionOptions, { value: response.profession[0] });
        let usecase = _.chain(response.usecase)
          .map((x) => _.find(platformOptions, { value: x }))
          .filter((x) => x)
          .value();
        let mobileNumber = response.mobile_number;
        response.country = country;
        response.state = state;
        response.city = city;
        response.profession = profession;
        response.usecase = usecase;
        response.mobile_number = mobileNumber;
        setUserDetails(response);
      });
    }
  }, [professionOptions, platformOptions, countries, dataReady]);

  // useEffect(() => {
  //   updateStateList();
  // }, [userDetails.country])

  const changeUserDetails = (key, value) => {
    let userDetailsTemp = Object.assign({}, userDetails);
    userDetailsTemp[key] = value;
    setUserDetails(userDetailsTemp);
  };

  const handleLocationChange = (obj) => {
    let userDetailsTemp = Object.assign({}, userDetails);
    userDetailsTemp = {
      ...userDetailsTemp,
      ...obj,
    };
    setUserDetails(userDetailsTemp);
  };

  // const handleIndustryChange = (e) => {
  //   changeUserDetails('industry', e);
  //   const industryIds = Array.isArray(e) ? e.map((x) => x.value) : [];

  //   apiGetIndustriesPreferences(industryIds).then((res) => {
  //     if (res) {
  //       let { results } = res;
  //       setIndustriesPreference(results);

  //     }
  //   })
  // };

  const handlePlatformChange = (e) => {
    changeUserDetails('usecase', e);
  };

  const handleProfessionChange = (obj) => {
    changeUserDetails('profession', obj);
  };

  const handleCountryChange = (selection) => {
    if (selection) {
      //selection.value = '';
      //changeUserDetails('country', selection);
    }

    if (selection) {
      selection.value = '';
      handleLocationChange({
        country: selection,
        state: null,
        city: null,
      });
    }
  };

  const handleStateChange = (selection) => {
    selection.value = '';
    if (selection) {
      handleLocationChange({
        state: selection,
        city: null,
      });
    }
  };

  const handleCityChange = (selection) => {
    selection.value = '';
    changeUserDetails('city', selection);
  };

  const handleContactInput = (contact) => {
    const re = /^[0-9\b]+$/;
    if (contact && re.test(contact)) {
      changeUserDetails('mobile_number', contact);
    }
  };

  // states of a country
  const updateStates = (countryId) => {
    return State.getStatesOfCountry(countryId).map((state) => ({
      label: state.name,
      value: state.name,
      ...state,
    }));
  };

  const updateCities = (countryId, stateId) => {
    if (stateId) {
      return City.getCitiesOfState(countryId, stateId).map((city) => ({
        label: city.name,
        value: city.name,
        ...city,
      }));
    } else {
      return (City.getCitiesOfCountry(countryId) || []).map((city) => ({
        label: city.name,
        value: city.name,
        ...city,
      }));
    }
  };

  const countryIsoCode = userDetails?.country?.isoCode || null;
  const stateNoOptionsMessage = countryIsoCode ? undefined : () => 'Please select a country';
  const cityNoOptionsMessage = stateNoOptionsMessage;
  const stateList = updateStates(countryIsoCode);
  const cityList = updateCities(
    userDetails?.country ? userDetails.country.isoCode : null,
    userDetails?.state ? userDetails.state.isoCode : null
  );

  const handleCreateProfession = async (inputValue) => {
    const val = inputValue.trim();
    setCreateProfessionInProgress(true);
    const result = await apiCreateProfession({ name: val });
    setCreateProfessionInProgress(false);
    if (!result?.data?.id) return;
    const data = result.data;
    const newProfession = { ...data, label: data.name, value: data.id };
    changeUserDetails('profession', newProfession);
  };

  const handleCreatePlatform = async (inputValue) => {
    const val = inputValue.trim();
    setCreatePlatformInProgress(true);
    const result = await apiCreatePlatform({ name: val });
    setCreatePlatformInProgress(false);
    if (!result?.data?.id) return;
    const data = result.data;
    const newPlatform = { ...data, label: data.name, value: data.id };
    handlePlatformChange([...userDetails.usecase, newPlatform]);
  };

  let commonArea = (
    <div className="user-info-form">
      <div className="user-info-form-row-container">
        <Row className="py-3">
          <Col md={6}>
            <SelectFormGroupV2
              error={countryMessage}
              label="Country"
              options={countries}
              value={userDetails.country}
              onChange={handleCountryChange}
              onBlur={() => setCountryMessage('')}
              onFocus={() => setCountryMessage('')}
              placeholder="Choose your country"
              isLoading={locationListLoading}
            />
          </Col>
          <Col md={6}>
            <SelectFormGroupV2
              error={stateMessage}
              label="State/Province"
              //options={updatedStates}
              options={stateList}
              value={userDetails.state}
              onChange={handleStateChange}
              onBlur={() => setStateMessage('')}
              onFocus={() => setStateMessage('')}
              placeholder="Choose your state"
              isLoading={locationListLoading}
              noOptionsMessage={stateNoOptionsMessage}
            />
          </Col>
        </Row>
      </div>
      <div className="user-info-form-row-container">
        <Row className="py-3">
          <Col md={6}>
            <SelectFormGroupV2
              error={cityMessage}
              label="City"
              options={cityList}
              value={userDetails.city}
              onChange={handleCityChange}
              onBlur={() => setCityMessage('')}
              onFocus={() => setCityMessage('')}
              placeholder="Choose your city"
              isLoading={locationListLoading}
              noOptionsMessage={cityNoOptionsMessage}
            />
          </Col>
          <Col md={6}>
            <Form.Group className="form-outline">
              <Form.Label className={'title-color float-left'}>
                Contact number (optional)
              </Form.Label>
              <PhoneInput
                name="contact"
                placeholder="Type your contact number"
                country={userDetails?.country?.isoCode?.toLowerCase() || 'us'}
                value={userDetails?.mobile_number}
                onChange={(contact) => handleContactInput(contact)}
                onBlur={() => setContactNumberMessage('')}
                inputClass={'phoneInput'}
                style={{ width: '100%' }}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="user-info-form-row-container">
        <Row className="py-3">
          <Col md={6}>
            <SelectFormGroupV2
              error={professionMessage}
              label="What's your profession?"
              value={userDetails?.profession?.id ? userDetails?.profession : ''}
              onChange={handleProfessionChange}
              placeholder="Choose your profession"
              options={professionOptions}
              onCreateOption={handleCreateProfession}
              isLoading={createProfessionInProgress}
              onBlur={() => setProfessionMessage('')}
              onFocus={() => setProfessionMessage('')}
            />
          </Col>

          <Col md={6}>
            <SelectFormGroupV2
              error={platformMessage}
              label="What are you using the platform for?"
              value={userDetails?.usecase}
              onChange={handlePlatformChange}
              placeholder="Choose your use case"
              options={platformOptions}
              onCreateOption={handleCreatePlatform}
              isLoading={createPlatformInProgress}
              isMulti
              onBlur={() => setPlatformMessage('')}
              onFocus={() => setPlatformMessage('')}
            />
          </Col>
        </Row>
      </div>
    </div>
  );

  return !inSettings ? (
    <Card className="border-0 container-fluid" style={{ height: '100%', padding: '18px 35px' }}>
      <h2 className="text-center fw-bold" style={{ marginBottom: 40 }}>
        Customize your experience
      </h2>
      {commonArea}
    </Card>
  ) : (
    <>{commonArea}</>
  );
}
