import api from '../helpers/axiosHelper';
import {
  GET_USER_DETAILS,
  API_ROOT_URL,
  GET_USER_BALANCE,
  PAYMENT_INTENT,
  TRANSACTION_HISTORY,
  CHANGE_PASSWORD,
  NOTIFICATION_SETTINGS,
} from '../config/constant/apiConstant';
import {
  setClientSecret,
  setNotificationSettings,
  setTransactionHistory,
  setUserBalance,
  setUserDetails,
} from '../redux/slicers/admin/settingsSlicer';
import { setAllOrgResult } from '../redux/slicers/search/searchSlicer';
import { GET_MY_ORGANIZATIONS, USER_PREFERENCE_API } from '../helpers/api';
import { profilePreferencesActions } from '../redux/slicers/profilePreferencesSlicer';
import { createGetListAction } from './products';

export const getUserDetailsApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_USER_DETAILS)
    .success((data) => {
      console.log(data);
      dispatch(setUserDetails(data));
    })
    .error(() => {})
    .send();
};

export const updateUserDetailsApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .patch(GET_USER_DETAILS)
    .data(formData)
    .success((data) => {
      console.log(data);
      dispatch(setUserDetails(data));
    })
    .error(() => {})
    .send();
};

//<----------------- Transaction Details API-------------------->//

export const getUserBalance = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_USER_BALANCE)
    .success(({ results }) => {
      console.log(results);
      dispatch(setUserBalance(...results));
    })
    .error(() => {})
    .send();
};

export const paymentIntentApi = (amount) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .post(PAYMENT_INTENT + `?amount=${amount}&payment_method_types=card`)
    .success((results) => {
      console.log(results.clientSecret);
      dispatch(setClientSecret(results.clientSecret));
    })
    .error(() => {})
    .send();
};

export const transactionHistoryApi = (transactionType, email, date) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(
      TRANSACTION_HISTORY + `?trans_type=${transactionType}&created_by_email=${email}&date=${date}`
    )
    .success(({ results }) => {
      console.log(results);
      dispatch(setTransactionHistory(results));
    })
    .error(() => {})
    .send();
};

export const changePasswordApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .put(CHANGE_PASSWORD)
    .data(formData)
    .success(({ success }) => {
      console.log(success);
      dispatch();
    })
    .error(() => {})
    .send();
};

//<---------------------------Notification Settings-------------------------------------> */

export const notificationSettingsApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(NOTIFICATION_SETTINGS)
    .success(({ data }) => {
      console.log(data);
      dispatch(setNotificationSettings(data));
    })
    .error(() => {})
    .send();
};
export const updateNotificationSettingsApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .put(NOTIFICATION_SETTINGS)
    .data(formData)
    .success(({ data }) => {
      console.log(data);
      dispatch(setNotificationSettings(data));
    })
    .error(() => {})
    .send();
};

export const getAllMyOrgs = (callback) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(GET_MY_ORGANIZATIONS)
    .success((data) => {
      dispatch(setAllOrgResult(data));
      callback && callback(false)
    })
    .error(() => {
        callback && callback(false)
    })
    .send();
};



export const getPreferencesListApi = createGetListAction({
  endpoint: USER_PREFERENCE_API,
  actions: profilePreferencesActions,
  // defaultSort: 'name',
});
