export const API_ROOT_URL = process.env.REACT_APP_API_BASE_URL;

//login
export const LOGIN_API = '/users/api/v3/login/';
export const GOOGLE_LOGIN_API = '/users/api/v3/login/social/';
export const CARROUSEL_API = '/users/api/v3/images-carrousel/';
export const REGISTER_API = '/users/api/v3/register/';
export const VERIFY_OTP_API = '/users/api/v3/verify-email-otp/';
export const RESEND_EMAIL_API = '/users/api/v3/resend-email-for-verification/';
export const FORGOT_API = '/users/api/v3/forgot-password/';
export const PROFESSION_API = '/users/api/v3/profession/';
export const INDUSTRY_API = '/users/api/v3/industry/';
export const PLATFORM_API = '/users/api/v3/usecase/';
export const TERMS_API = '/tnc/latest/';
export const USER_PREFERENCE_API = '/users/api/v3/user-preferences/';
export const ACCOUNT_STATUS_API = '/users/api/v3/check-account-status/';
export const LINK_VERIFY_API = '/users/api/v3/verify-email-with-link/';
export const CREATE_PASSWORD_API = '/users/api/v3/reset-password-confirmation/';
export const UPDATE_USER_PROFILE_API = '/users/api/v3/user-details/';
export const GET_ACCESS_REFRESH_TOKEN = '/users/v3/api/login/refresh/';
export const GLOBAL_SEARCH_API = '/organization/api/v3/search-api/';

//curator
export const ROOM_LAYOUT_API = '/rooms/api/v3/layout-detail/';
export const PROPS_API = '/assetbundles/api/v3/products/detail/';
export const ROOM_DETAILS_API = '/rooms/api/v3/room-detail/';
export const ROOM_TYPE_API = '/rooms/api/v3/room-type/';
export const CREATE_ROOM = '/rooms/api/v3/room-detail/';
export const TEXTURE_DETAILS_API = '/texture/api/v3/texture/detail/';
export const GET_SETTING_API = '/users/api/v3/get-user-curator-settings/';
export const EDIT_SETTING_API = '/users/api/v3/user-curator-settings-detail/';
export const CREATE_SETTINGS_API = '/users/api/v3/user-curator-settings-detail/';
export const GET_DEFAULT_METADATA = '/users/api/v3/default-meta-data-detail/';
export const LIGHT_API = '/organization/api/v3/environment-light-detail/';

export const ORGANISATION_DETAILS_API = '/organization/api/v3/get-all-organization/';

//projects
export const GET_PROJECT_API = '/organization/api/v3/projects/details/';
export const GET_ROOMS_API = '/rooms/api/v3/room-detail/';
export const GET_PROPS_API = '/assetbundles/api/v3/products/detail/';
export const PROJECT_UPDATE = '/organization/api/v3/projects/details/:projectId/';

//teamsPage
export const GET_MEMBERS_API = '/organization/api/v3/members/details/';
export const SEND_INVITE_API = '/organization/api/v3/send-invite/';
export const GET_PENDING_LIST = '/organization/api/v3/invitation-pendding-list/';
export const DELETE_PENDING_INVITATION = '/organization/api/v3/delete-invitation-pendding-list/';

//settings
export const GET_USER_DETAILS = '/users/api/v3/user-details/';

// favourite
export const ADD_FAVOURITE_PROJECTS = '/organization/api/v3/my-projects/details/';
export const ADD_FAVOURITE_ROOM = '/rooms/api/v3/room-detail/';

// delete
export const PROJECT_DELETE = '/organization/api/v3/projects/details/';
export const ROOM_DELETE = '/organization/api/v3/room-detail/';

// material
// texture
export const GET_MATERIAL = '/organization/api/v3/material/detail/';
export const MATERIAL_API = '/material/api/v3/material/detail/';
export const MATERIAL_COLOR_CODE_API = '/material/api/v3/material-color-code-detail/';

// texture
export const GET_TEXTURE = '/organization/api/v3/texture/detail/';
export const TEXTURE_API = '/texture/api/v3/texture/detail/';
export const TEXTURE_BULK_UPLOAD = '/texture/api/v3/texture-buld-create/';
export const TEXTURE_BULK_UPDATE = '/texture/api/v3/texture/update/';
export const TEXTURE_BULK_DELETE = '/texture/api/v3/delete-textures/';
export const TEXTURE_DOWNLOAD = '/texture/api/v3/texture/download/';

// preset camera (curator)
export const PRESET_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/';
export const PRESET_CAMERA_IMPORT = '/organization/api/v3/import_camera_angle/';
// export const GET_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const CREATE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const UPDATE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const DELETE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'

// project camera (curator)
export const PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const GET_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const CREATE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const UPDATE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const DELETE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';

// images (curator) - render

// rendering-system/api/v3/images-render-detail/?parent_id=148
export const DOWNLOAD_RENDER = '/rendering-system/api/v3/download-renders/';
export const GET_RENDER_PREVIEW_DATA = '/rendering-system/api/v3/project-render-images/';
export const UPDATE_RENDER_PREVIEW = '/rendering-system/api/v3/project-render-images/';
// rendering-system/api/v3/images-render-detail/?parent_id=148
export const GET_CAMERA_INFO = '/rendering-system/api/v3/images-render-detail/';
export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/';
export const DELETE_RENDER = '/rendering-system/api/v3/images-render-detail/';
export const DOWNLOAD_RENDER_IMAGE = '/rendering-system/api/v3/download-renders/';
// export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/'
// export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/'

// render & preview//
export const GET_RENDER_PREVIEW = '/rendering-system/api/v3/project-render-images/';
export const GET_DOWNLOAD_RENDER_IMAGES = '/rendering-system/api/v3/download-renders/';
export const GET_RENDER_DATA = '/rendering-system/api/v3/get-render/';
// Filters
export const GET_FILTERS = '/users/api/v3/filters/';
export const CREATE_FILTER_OPTION = '/users/api/v3/filters/';

export const GET_GLOBAL_TAGS = '/organization/api/v3/global-tags/';

// share
export const CREATE_SHARE = '/share/api/v3/sharingmodels/';

//Transaction
export const GET_USER_BALANCE = '/images/api/organization-balance/';
export const PAYMENT_INTENT = '/images/api/create-payment-intent/';
export const TRANSACTION_HISTORY = '/images/api/organization-transaction-history/';
export const CHANGE_PASSWORD = '/users/api/v3/change-password/';

//Notification Settings
export const NOTIFICATION_SETTINGS = '/notifications/settings/';

// collection
export const COLLECTION_DETAILS_API = '/organization/api/v3/collections/details/';

// organization
export const GET_MY_ORGANIZATIONS = '/organization/api/v3/my-orgs/';
