import { paginatedTeamActions, paginatedTeamSelector } from "../redux/slicers/team/paginatedTeamSlicer";
import { useSimpleDataList } from "./useDataList";
import { getTeamMemberListApi } from "../api/teams";

export const useMembers = () => {
  const data = useSimpleDataList({
    selector: paginatedTeamSelector,
    resetAction: paginatedTeamActions.resetList,
    getAction: getTeamMemberListApi,
    // sort: 'name',
    itemDeleteText: "Are you sure you want to delete this team member?",
    itemsDeleteText: (selectedIds) =>
      `Are you sure you want to delete ${selectedIds.length} team members?`,
  });

  return data;
};
