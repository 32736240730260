import _, { merge } from 'lodash';
import { useEffect, useState, createContext, useContext, memo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AUTH_TOKEN,
  CURRENT_USER,
  EMAIL_VERIFIED,
  LATEST_TNC_ACCEPTED,
  MEMBER_ID,
  REFRESH_TOKEN,
} from '../constants/projectConstants';
import { SIGN_IN_ROUTE } from '../constants/routePathConstants';
import {
  apiGetUserDetails, apiPostLogout,
} from '../helpers/api';
import { warningToast } from '../helpers/toastHelper.js';
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from '../helpers/jsHelper';

const UserContext = createContext({
  authorized: null,
  currentUser: {},
  setCurrentUser: () => {},
  token: null,
  setToken: () => {},
  refreshToken: null,
  setRefreshToken: () => {},
  verificationToken: null,
  setVerificationToken: () => {},
  onLogin: () => {},
  logout: () => {},
  latestTNCAccepted: false,
  setLatestTNCAccepted: () => {},
  emailVerified: false,
  isInternalUser: false,
  canAccessLiveOnApp: () => {},
  setEmailVerified: () => {},
  changeProfilePic: () => {},
  changeOrgProfilePic: () => {},
  logoutLoading: false,
});


const UserContextProvider = memo(({ children }) => {
  const location = useLocation();
  const pathname = location?.pathname;
  const authToken = getLocalStorage(AUTH_TOKEN);
  const refresh_token = getLocalStorage(REFRESH_TOKEN);
  const email_verified = getLocalStorage(EMAIL_VERIFIED);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const latest_tnc_accepted = getLocalStorage(LATEST_TNC_ACCEPTED);

  const [token, setToken] = useState(authToken || null);
  const user = token ? getLocalStorage(CURRENT_USER, true) : null;
  const [refreshToken, setRefreshToken] = useState(refresh_token || null);
  const [emailVerified, setEmailVerified] = useState(email_verified || false);
  const [latestTNCAccepted, setLatestTNCAccepted] = useState(latest_tnc_accepted || false);
  const [currentUser, setCurrentUser] = useState(user);
  const [verificationToken, setVerificationToken] = useState(null);

  // current user
  // const requiredAuthKeys = token && refreshToken && emailVerified;

  // when user reload the page- it will set the organisation & user thumbnail
  useEffect(async () => {
    if (currentUser) {
      const res = await apiGetUserDetails();
      if (Object.entries(res).length > 0) {
        const {
          profile_image,
          organization_details: { thumbnail },
        } = res;
        // console.log(profile_image, thumbnail);
        changeProfilePic(profile_image);
        changeOrgProfilePic(thumbnail);
        // console.log("user res: ", res);
      }
    }
  }, []);

  async function logout() {
    setLogoutLoading(true)
    await apiPostLogout()
    clearLocalStorage();
    window.location.reload();
  }

  function onUserChanges(user) {
    setCurrentUser(user);
    setLocalStorage(CURRENT_USER, user);
    setLocalStorage(MEMBER_ID, user.id);
  }

  function onLogin(
    user,
    token,
    refresh_token,
    is_email_verified = false,
    latest_tnc_accepted = false
  ) {
    if (user) onUserChanges(user);

    if (token) {
      setToken(token);
      setLocalStorage(AUTH_TOKEN, token);
    }

    if (refresh_token) {
      setRefreshToken(refresh_token);
      setLocalStorage(REFRESH_TOKEN, refresh_token);
    }

    setEmailVerified(is_email_verified);
    setLocalStorage(EMAIL_VERIFIED, is_email_verified);

    setLatestTNCAccepted(latest_tnc_accepted);
    setLocalStorage(LATEST_TNC_ACCEPTED, latest_tnc_accepted);
  }


  function changeProfilePic(image) {
    onUserChanges({
      ...currentUser,
      user: { ...currentUser.user, profile_image: image },
    })
  }

  function changeOrgProfilePic(image) {
    onUserChanges({
      ...currentUser,
      organization_detail: {
        ...currentUser.organization_detail,
        thumbnail: image,
      }
    })
  }

  const updateCurrentUser = (data) => {
    setCurrentUser(merge({}, currentUser, data));
  };

  const canAccessLiveOnApp = () => {
    return Boolean(currentUser?.role?.is_staff && currentUser?.is_live_on_app);
  }

  const userInfo = {
    // authorized: requiredAuthKeys, // it's based on old implementation, try to get rid of it
    currentUser: currentUser,
    setCurrentUser: setCurrentUser,
    updateCurrentUser: updateCurrentUser,
    token: token,
    setToken: setToken,
    refreshToken: refreshToken,
    setRefreshToken: setRefreshToken,
    verificationToken: verificationToken,
    setVerificationToken: setVerificationToken,
    onLogin: onLogin,
    logout: logout,
    isAdmin: currentUser?.role?.is_admin,
    isOwner: currentUser?.role?.is_owner,
    isNormalUser: currentUser?.role?.is_staff,
    isInternalUser: Boolean(currentUser?.role?.is_internal_member),
    canAccessLiveOnApp: canAccessLiveOnApp,
    latestTNCAccepted: latestTNCAccepted,
    setLatestTNCAccepted: setLatestTNCAccepted,
    emailVerified: emailVerified,
    setEmailVerified: setEmailVerified,
    changeProfilePic: changeProfilePic,
    changeOrgProfilePic: changeOrgProfilePic,
    logoutLoading,
  };

  return (
    <UserContext.Provider value={userInfo}>
      {children}
    </UserContext.Provider>
  )
})


const useUserContext = () => useContext(UserContext);
export { UserContext, UserContextProvider, useUserContext };
