import React, { useState, useEffect, useContext } from 'react';
import { CONVERT_IN_METER, UNITY_REF } from '../../config/constant/projectConstant';
import Unity, { UnityContext, WebGLInput } from 'react-unity-webgl';
import { unitycontext } from '../../hooks/useUnityContext';
import { useDispatch, useSelector } from 'react-redux';
import { curatorSelector, selectMeasureUnitById, setCuratorLoader } from '../../redux/slicers/admin/curatorSlicer';
import { getPropLightList, inchesToUnitySize } from '../../helpers/unityHelper';
import {
  updateDbLowTexture,
  updateDbMaterial,
  updateDbProp,
  updateDbTexture,
} from '../../helpers/idb';
import { toast } from 'react-toastify';
import {
  setGlobalHeight,
  setGlobalSafeCameraFlag,
  setGlobalWidth,
} from '../../redux/slicers/camera/cameraSettings';
import { getAppState } from '../../redux/store';
import { errorToast } from '../../helpers/toastHelper';
import { pubSubState } from '../../helpers/unityPubSub';
import { addDefaultTexturesToObject } from '../../helpers/unity/addDefaultTexturesToObject';
import { undoRedo } from '../admin/curator/UndoRedo/UndoRedo';
import { dispatcher } from '../../helpers/projectHelper';
import { setDraggableProductImage } from '../../redux/slicers/admin/curatorProductsSlicer';

const unityContext = new UnityContext(UNITY_REF);
window.unityContext = unityContext;

function UnityContainer({ children }) {
  const dispatch = useDispatch();

  // const sendUnityEvent = unityContext.send;
  // uncomment for debugging;
  const sendUnityEvent = (...args) => {
    // console.log('sasha unity event !!!')
    unityContext.send(...args)
  }

  const handleLoad = (data) => {
    const { roomData } = curatorSelector(getAppState());
    if (roomData?.is_custom) {
      handleLoadProject(data);
    } else {
      sendUnityEvent('RoomModule', 'CreateRoomReceiver', JSON.stringify(data));
    }
  };
  const handleLoadProject = (data) => {
    sendUnityEvent('RoomModule', 'CreateProjectReceiver', JSON.stringify(data));
  };
  const handlePropsLoad = async (product, x, y) => {
    try {
      dispatch(setCuratorLoader(false));
      dispatch(setDraggableProductImage({
        image: product.thumbnail,
        x,
        y,
      }));
      const productDbData = await updateDbProp(product);
      const lightList = await getPropLightList(product);

      const data = {
        type: 'PropLibrary',
        id: product.id,
        propName: product.name,
        locationUrl: productDbData?.file || product.product_file,
        preDefinedMetadata: product.product_meta_info,
        roomLightDatas: lightList,
      };

      //  sendUnityEvent("RoomModule", "InstantiatePropReciever", JSON.stringify(data));
      sendUnityEvent('RoomModule', 'InstantiatePropFromLibrary', JSON.stringify(data));
    } catch (e) {
      console.error(e);
      errorToast('Error happened while loading product');
    } finally {
      dispatch(setCuratorLoader(true));
    }
  };

  const handleMovement = (data) => {
    sendUnityEvent('MainCameraPrefab(Clone)', 'SetTransformType', JSON.stringify(data));
  };

  const convertCameraToUnityModel = (camera) => {
    return {
      cameraId: camera.id,
      distanceToSet: camera.distanceToSet,
      fov: parseInt(camera.fov),
      xTransRot: camera.xTransRot,
      yTransRot: camera.yTransRot,
      zTransRot: camera.zTransRot,
      centerObjectxPos: camera.centerObjectxPos,
      centerObjectyPos: camera.centerObjectyPos,
      centerObjectzPos: camera.centerObjectzPos,
      isCameraCorrection: camera.isCameraCorrection,
      lensShiftY: camera.lensShiftY,
      cameraHeight: camera.cameraHeight,
      cameraRotX: camera.cameraRotX,
      cameraRotZ: camera.cameraRotZ,
      focalLength: camera.focalLength,
      isdepthOfField: camera.isdepthOfField,
      selectedObjectName: camera.selectedObjectName,
      blurIntensity: camera.blurIntensity,
      xPos: camera.xPos,
      yPos: camera.yPos,
      zPos: camera.zPos,
      // resoX: camera.resoX,
      // resoY: camera.resoY,
      cameraName: camera.cameraName,
      isPredefinedCamera: camera.isPredefinedCamera,
    };
  };

  // create camera functions
  const handleSelectedCamera = (camera) => {
    const unityCamera = convertCameraToUnityModel(camera);
    console.log('handleSelectedCamera', { camera, unityCamera });
    sendUnityEvent('CameraModule', 'SelectedCameraReceiver', JSON.stringify(unityCamera));

    dispatch(setGlobalHeight(camera.resoY));
    dispatch(setGlobalWidth(camera.resoX));
    dispatch(setGlobalSafeCameraFlag(true));
  };
  const handleCreateCamera = () => {
    sendUnityEvent('CameraModule', 'CreateCameraAngleReceiver');
  };
  const handleCameraFov = (a) => {
    console.log('OnUpdateCameraFovReceiver a ', a);
    sendUnityEvent('CameraModule', 'OnUpdateCameraFovReceiver', a);
  };
  const handleCameraCorrection = (a) => {
    sendUnityEvent('CameraModule', 'OnCameraVerticalTiltReceiver', a);
    console.log('OnCameraVerticalTiltReceiver: ', a);
  };
  const handleCameraHeight = (a) => {
    // const
    // const meter = a / CONVERT_IN_METER
    sendUnityEvent('CameraModule', 'UpdateCameraHeightReceiver', a);
  };
  const handleDepthOfField = (a) => {
    sendUnityEvent('CameraModule', 'OnSelectDepthOfFieldReceiver', a);
  };
  const handleBlurIntensity = (a) => {
    sendUnityEvent('CameraModule', 'OnUpdateDepthOfFieldValueReceiver', a);
  };
  const handleCameraScreenshot = (cameraList) => {
    sendUnityEvent('CameraModule', 'TakeScreenShotForRender', JSON.stringify(cameraList));
  };
  const handleCameraPopupClose = () => {
    sendUnityEvent('CameraModule', 'CloseCameraPopupReceiver');
  };

  const booleanToText = (val) => (val ? 'true' : 'false');

  // material module
  const materialModule = {
    /**
     * @param {{
     *  id: number;
     *  textureUrl: string;
     *  mapType: number;
     * }} data
     * @returns void
     */
    OnSelectTextureReceiver(data) {
      console.log('OnSelectTextureReceiver', data);
      sendUnityEvent('MaterialModule', 'OnSelectTextureReceiver', JSON.stringify(data));
    },

    OnSelectDefaultTextureReceiver({ textureUrl, mapType }) {
      sendUnityEvent(
        'MaterialModule',
        'OnSelectDefaultTextureReceiver',
        JSON.stringify({ textureUrl, mapType })
      );
    },

    /**
     * @param {{
     *  id: number;
     *  materialUrl: string;
     * }} data
     * @returns void
     */
    OnSelectMaterialReceiver(data) {
      console.log('OnSelectMaterialReceiver', data)
      sendUnityEvent('MaterialModule', 'OnSelectMaterialReceiver', JSON.stringify(data));
    },

    /**
     * @param {{
     *  width: number;
     *  height: number;
     *  tilingOptionType: number;
     * }} data
     * @returns void
     */
    OnUpdateTilingSizeReceiver({ width, height, tilingOptionType }) {
      console.log('OnUpdateTilingSizeReceiver', {
        width,
        height,
        tilingOptionType,
        unityWidth: inchesToUnitySize(width),
        unityHeight: inchesToUnitySize(height),
      });

      const isWidthValid = typeof width === 'number' && !Number.isNaN(width);
      const isHeightValid = typeof height === 'number' && !Number.isNaN(height);

      if (!isWidthValid || !isHeightValid) {
        console.error('OnUpdateTilingSizeReceiver height or width not number ');
        return;
      }

      sendUnityEvent(
        'MaterialModule',
        'OnUpdateTilingSizeReceiver',
        JSON.stringify({
          tilingOptionType,
          xValue: inchesToUnitySize(width),
          yValue: inchesToUnitySize(height),
        })
      );
    },

    /**
     * @param {{
     *  x: number;
     *  y: number;
     *  tilingOptionType: number;
     * }} data
     * @returns void
     */
    OnUpdateTilingOffsetReceiver(data) {
      sendUnityEvent(
        'MaterialModule',
        'OnUpdateTilingOffsetReceiver',
        JSON.stringify({
          ...data,
          xValue: parseFloat(data.x),
          yValue: parseFloat(data.y),
        })
      );
    },

    /**
     * @param {{
     *  width: number;
     *  height: number;
     *  x: number;
     *  y: number;
     *  rotation: number;
     *  tilingOptionType: number;
     * }} data
     * @returns void
     */
    OnUpdateRotationReceiver({ width, height, x, y, rotation, tilingOptionType }) {
      console.log('OnUpdateRotationReceiver', {
        xSize: inchesToUnitySize(width),
        ySize: inchesToUnitySize(height),
        xPosition: x,
        yPosition: y,
        rotation,
        tilingOptionType,
      });

      sendUnityEvent(
        'MaterialModule',
        'OnUpdateRotationReceiver',
        JSON.stringify({
          xSize: inchesToUnitySize(width),
          ySize: inchesToUnitySize(height),
          xPosition: x,
          yPosition: y,
          rotation,
          tilingOptionType,
        })
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateRoughnessValueReceiver(value) {
      sendUnityEvent('MaterialModule', 'OnUpdateRoughnessValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    InvertRoughnessToggleReceiver(value) {
      sendUnityEvent('MaterialModule', 'InvertRoughnessToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomRoughnessMapToggleReceiver(value) {
      console.log('sasha toggle UseCustomRoughnessMapToggleReceiver', value);

      sendUnityEvent(
        'MaterialModule',
        'UseCustomRoughnessMapToggleReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param status: boolean;
     * @param tilingOptionType: string;
     * @returns void
     */
    AddRemoveSeparateTilingOptionReceiver({ status, tilingOptionType }) {
      console.log('AddRemoveSeparateTilingOptionReceiver', {
        status: booleanToText(status),
        tilingOptionType,
      });

      sendUnityEvent(
        'MaterialModule',
        'AddRemoveSeparateTilingOptionReceiver',
        JSON.stringify({
          status: booleanToText(status),
          tilingOptionType,
        })
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateNormalValueReceiver(value) {
      sendUnityEvent('MaterialModule', 'OnUpdateNormalValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    InvertNormalToggleReceiver(value) {
      sendUnityEvent('MaterialModule', 'InvertNormalToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomNormalMapToggleReceiver(value) {
      sendUnityEvent('MaterialModule', 'UseCustomNormalMapToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseGenerateNormalMapToggleReceiver(mapId, active) {
      sendUnityEvent(
        'MaterialModule',
        'UseGenerateNormalMapToggleReceiver',
        JSON.stringify({
          mapId,
          status: active,
        })
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateTransparencyValueReceiver(value) {
      sendUnityEvent('MaterialModule', 'OnUpdateTransparencyValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    InvertTransparencyToggleReceiver(value) {
      sendUnityEvent('MaterialModule', 'InvertTransparencyToggleReceiver', booleanToText(value));
    },

    InvertAoToggleReceiver(active) {
      sendUnityEvent('MaterialModule', 'InvertAoToggleReceiver', booleanToText(active));
    },

    GenerateMapReceiver(mapType) {
      console.log('GenerateMapReceiver', mapType);
      sendUnityEvent('MaterialModule', 'GenerateMapReceiver', mapType);
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    SetTransparencyFromDesignReceiver(value) {
      sendUnityEvent(
        'MaterialModule',
        'SetTransparencyFromDesignReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomTransparencyMapToggleReceiver(value) {
      sendUnityEvent(
        'MaterialModule',
        'UseCustomTransparencyMapToggleReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateAoValueReceiver(value) {
      sendUnityEvent('MaterialModule', 'OnUpdateAoValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomAOMapToggleReceiver(value) {
      sendUnityEvent('MaterialModule', 'UseCustomAOMapToggleReceiver', booleanToText(value));
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateMetallicValueReceiver(value) {
      sendUnityEvent('MaterialModule', 'OnUpdateMetallicValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    UseCustomMetallicMapToggleReceiver(value) {
      sendUnityEvent(
        'MaterialModule',
        'UseCustomMetallicMapToggleReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param value: number;
     * @returns void
     */
    OnUpdateEmissionValueReceiver(value) {
      sendUnityEvent('MaterialModule', 'OnUpdateEmissionValueReceiver', parseFloat(value));
    },

    /**
     * @param value: boolean;
     * @returns void
     */
    OnEffectLightColorBounceEnableDisableReceiver(value) {
      sendUnityEvent(
        'MaterialModule',
        'OnEffectLightColorBounceEnableDisableReceiver',
        booleanToText(value)
      );
    },

    /**
     * @param tilingType: number; // 0 | 1
     * @returns void
     */
    OnSetFitReceiver(tilingType) {
      console.log('sasha OnSetFitReceiver', tilingType);
      sendUnityEvent('MaterialModule', 'OnSetFitReceiver', JSON.stringify(tilingType));
    },

    /**
     * @param tilingType: number;
     * @returns void
     */
    OnLockTilingReceiver(tilingType) {
      console.log(
        'before lock send ',
        'MaterialModule',
        'OnLockTilingReceiver',
        JSON.stringify(tilingType)
      );
      sendUnityEvent('MaterialModule', 'OnLockTilingReceiver', JSON.stringify(tilingType));
    },

    /**
     * @param color: string;
     * @returns void
     */
    OnUpdateColorReceiver(color) {
      sendUnityEvent('MaterialModule', 'OnUpdateColorReceiver', color);

      // sendUnityEvent('MaterialModule', 'OnUpdateColorReceiver', JSON.stringify({
      //   status: false,
      //   value: color,
      // }));
    },

    OnCallBase64ImageDataReceiver() {
      // gets bas64 images of current selected object
      window.tOnCallBase64ImageDataReceiver = performance.now();
      sendUnityEvent('MaterialModule', 'OnCallBase64ImageDataReceiver');
    },

    async SetMaterialPropertiesOnObjectReceiver(objectData) {
      const objectWithDefaultTextures = await addDefaultTexturesToObject(objectData);

      console.log('objectWithDefaultTextures', objectWithDefaultTextures)
      // debugger;
      sendUnityEvent('MaterialModule', 'SetMaterialPropertiesOnObjectReceiver', JSON.stringify(objectWithDefaultTextures));
    },


    async selectTexture({ texture, mapType }) {
      const textureDbData = await updateDbTexture(texture);
      const textureUrl = textureDbData?.file || texture.texture_file || texture.low_texture_file; // doesn't make sense to use texture file url if its outdated (it's valid only 1 hour)

      if (!textureUrl) return false;

      this.OnSelectTextureReceiver({
        id: texture.id,
        textureUrl: textureDbData?.file || texture.texture_file,
        mapType,
        version: texture.version,
        skuId: texture.sku_id,
        content: texture.description,
      });
      return true;
    },

    async selectLowTexture({ texture, mapType, isGeneratedMap }) {
      // discuss if we need to store it in different idb instance
      const textureDbData = await updateDbLowTexture(texture, { IS_GENERATED_MAP: true });

      this.OnSelectTextureReceiver({
        id: texture.id,
        textureUrl: textureDbData?.file || texture.low_texture_file,
        mapType,
        version: texture.version,
        skuId: texture.sku_id,
        content: texture.description,
        isGeneratedMap,
      });
    },

    async selectMaterial({ material }) {
      const materialDbData = await updateDbMaterial(material);
      this.OnSelectMaterialReceiver({
        id: material.id,
        materialUrl: materialDbData?.file || material.material_image,
      });
    },
  };

  window.materialModule = materialModule;

  const metadataModule = {
    GetRoomMetadataList() {
      sendUnityEvent('MetadataModule', 'GetRoomMetadataList');
    },
    GetRoomMetadataListReceiver() {
      sendUnityEvent('MetadataModule', 'GetRoomMetadataListReceiver');
    },
    AddMetadataReceiver({ sequenceNo, metadataName, objectName }, loadedProp = false) {
      console.log('AddMetadataReceiver', metadataName, objectName )

      sendUnityEvent(
        'MetadataModule',
        'AddMetadataReceiver',
        JSON.stringify({ metadataName, objectName }),
        loadedProp
      ); // TODO: work on this false
    },
    OnAddMetadataButtonClickReceiver() {
      // call it to know if meta data can be applied to the object
      sendUnityEvent('MetadataModule', 'OnAddMetadataButtonClickReceiver');
    },
    DeleteMetadataReceiver(title) {
      sendUnityEvent('MetadataModule', 'DeleteMetadataReceiver', title);
    },
    SelectMetadataReceiver(title) {
      console.log('sasha SelectMetadataReceiver', title);
      sendUnityEvent('MetadataModule', 'SelectMetadataReceiver', title);
    },
    EditMetadataReceiver(oldMetadataTitle, newMetadataTitle) {
      sendUnityEvent(
        'MetadataModule',
        'EditMetadataReceiver',
        JSON.stringify({
          oldMetadataName: oldMetadataTitle,
          newMetadataName: newMetadataTitle,
        })
      );
    },
    UpdateSequenceReceiver(title, newIndex, oldIndex) {
      console.log('sasha metadata sequence no update', {
        metadataTitle: title,
        newIndex,
        oldIndex,
      });
      sendUnityEvent(
        'MetadataModule',
        'UpdateSequenceReceiver',
        JSON.stringify({
          metadataTitle: title,
          newIndex,
          oldIndex,
        })
      );
    },
    UpdateMetadataStatusReceiver(title, enabled) {
      sendUnityEvent(
        'MetadataModule',
        'UpdateMetadataStatusReceiver',
        JSON.stringify({
          metadataTitle: title,
          status: enabled,
        })
      );
    },
    CloseFocusObjectReceiver() {
      sendUnityEvent('MetadataModule', 'CloseFocusObjectReceiver');
    },
    AddMetadataObjectReceiver(metadataObj) {
      console.log('AddMetadataObjectReceiver', metadataObj)
      sendUnityEvent('MetadataModule', 'AddMetadataObjectReceiver', JSON.stringify(metadataObj));
    }
  };

  const lightModule = {
    GenerateLightReceiver(lightName) {
      sendUnityEvent('LightModule', 'GenerateLightReceiver', lightName);
    },
    SelectLightObjectReceiver(lightId, syncLightData) {
      sendUnityEvent(
        'LightModule',
        'SelectLightObjectReceiver',
        JSON.stringify({
          id: lightId,
          syncLightData,
        })
      );
    },
    UpdateLightTypeReceiver(light) {
      sendUnityEvent('LightModule', 'UpdateLightTypeReceiver', JSON.stringify(light));
    },
    UpdateColorReceiver(color) {
      sendUnityEvent('LightModule', 'UpdateColorReceiver', color);
    },
    UpdateIntensityReceiver(intensity) {
      sendUnityEvent('LightModule', 'UpdateIntensityReceiver', intensity);
    },
    UpdateSizeReceiver(size) {
      sendUnityEvent('LightModule', 'UpdateSizeReceiver', size);
    },
    UpdateVisibleInReflectionReceiver(active) {
      sendUnityEvent('LightModule', 'UpdateVisibleInReflectionReceiver', booleanToText(active));
    },
    UpdateSpotAngleReceiver(spotAngle) {
      sendUnityEvent('LightModule', 'UpdateSpotAngleReceiver', spotAngle);
    },
    UpdateBlendSpotReceiver(blendSpot) {
      sendUnityEvent('LightModule', 'UpdateBlendSpotReceiver', blendSpot);
    },
    OnDeleteLightReceiver(lightId) {
      sendUnityEvent('LightModule', 'OnDeleteLightReceiver', lightId);
    },
    UpdateEnvironmentColorReceiver(color) {
      // toggle color
      sendUnityEvent('LightModule', 'UpdateEnvironmentColorReceiver', color);
    },
    UpdateColorIntensityReceiver(intensity) {
      sendUnityEvent('LightModule', 'UpdateColorIntensityReceiver', parseFloat(intensity));
    },
    UpdateEnvironmentSkyboxReceiver({ url, rotation, intensity }) {
      // toggle hdri
      sendUnityEvent(
        'LightModule',
        'UpdateEnvironmentSkyboxReceiver',
        JSON.stringify({ url, rotation, intensity })
      );
    },
    UpdateSkyboxRotationReceiver(rotation) {
      sendUnityEvent('LightModule', 'UpdateSkyboxRotationReceiver', rotation);
    },
    UpdateEnableInRenderingReceiver(id, active) {
      sendUnityEvent(
        'LightModule',
        'UpdateEnableInRenderingReceiver',
        JSON.stringify({
          lightId: `${id}`,
          status: active,
        })
      );
    },
    UpdateAreaWidthReceiver(width) {
      sendUnityEvent('LightModule', 'UpdateAreaWidthReceiver', parseFloat(width));
    },
    UpdateAreaHeightReceiver(width) {
      sendUnityEvent('LightModule', 'UpdateAreaHeightReceiver', parseFloat(width));
    },
    UpdateSkyboxIntensityReceiver(intensity) {
      // update hdri intenstiy
      sendUnityEvent('LightModule', 'UpdateSkyboxIntensityReceiver', parseFloat(intensity));
    },
    EnableDisableHDRSkyboxReceiver(enabled) {
      sendUnityEvent('LightModule', 'EnableDisableHDRSkyboxReceiver', booleanToText(enabled));
    },
    UpdateLightNameReceiver(lightName) {
      sendUnityEvent('LightModule', 'UpdateLightNameReceiver', lightName);
    },
    GetLightDatReceiver() {
      sendUnityEvent('LightModule', 'GetLightDatReceiver');
    },
  };

  const shortcutsModule = {
    Switch3d2DViewReceiver() {
      sendUnityEvent('ShortcutsModule', 'Switch3d2DViewReceiver');
    },
    OnSetup2DViewsReceiver(viewMode) {
      sendUnityEvent('ShortcutsModule', 'OnSetup2DViewsReceiver', viewMode);
    },
    TransformTypeMoveReceiver() {
      sendUnityEvent('ShortcutsModule', 'TransformTypeMoveReceiver');
    },
    TransformTypeRotateReceiver() {
      sendUnityEvent('ShortcutsModule', 'TransformTypeRotateReceiver');
    },
    TransformTypeScaleReceiver() {
      sendUnityEvent('ShortcutsModule', 'TransformTypeScaleReceiver');
    },
    StyleOnlyReceiver(active) {
      sendUnityEvent('ShortcutsModule', 'StyleOnlyReceiver', booleanToText(active));
    },
    TransformSpaceReceiver(mode) {
      sendUnityEvent('ShortcutsModule', 'TransformSpaceReceiver', parseInt(mode));
    },
    OnClickSelectAxisButtonReceiver(axis) {
      sendUnityEvent('ShortcutsModule', 'OnClickSelectAxisButtonReceiver', axis);
    },
    DuplicatePropReceiver() {
      pubSubState.isDuplicate = true;
      sendUnityEvent('ShortcutsModule', 'DuplicatePropReceiver');
    },
    DeleteObjectReceiver() {
      sendUnityEvent('ShortcutsModule', 'DeleteObjectReceiver');
    },
    copyMaterial() {
      sendUnityEvent('ShortcutsModule', 'CopyMaterialReceiver');
    },
    pasteMaterial(defaultTextureList) {
      undoRedo.objectProperties.addPasteMaterialParams(defaultTextureList)

      const params = defaultTextureList 
        ? JSON.stringify({ defaultTextureDatas: defaultTextureList } )
        : '';
      console.log('PasteMaterialReceiver', params);
      sendUnityEvent('ShortcutsModule', 'PasteMaterialReceiver', params);
    },
    focusObject() {
      sendUnityEvent('ShortcutsModule', 'FocusObjectReceiver');
    },
    toggleHighlightSelected(active) {
      sendUnityEvent('ShortcutsModule', 'HeightlightOnOffReceiver', booleanToText(active));
    },
    toggleDimensions(measureUnit) {
      sendUnityEvent('MeasureModule', 'OnSelectMeasurementToolReceiver', measureUnit);
    },
    toggleLightsVisibility(active) {
      sendUnityEvent('ShortcutsModule', 'LightsEnableDisableReceiver', booleanToText(active));
    },
    focusEverything() {
      sendUnityEvent('ShortcutsModule', 'FocusEverythingReceiver');
    },
    snapObject(snap) {
      sendUnityEvent('ShortcutsModule', 'SnapObjectReceiver', booleanToText(snap));
    },
    removeSelection(clicked) {
      sendUnityEvent('ShortcutsModule', 'RemoveSelectionReceiver', booleanToText(clicked));
    },
    addSelection(clicked) {
      sendUnityEvent('ShortcutsModule', 'AddSelectionReceiver', booleanToText(clicked));
    },
    SingleObjectSelectionReceiver(clicked) {
      sendUnityEvent('ShortcutsModule', 'SingleObjectSelectionReceiver', booleanToText(clicked));
    },
    SetPositionAlongAxis({ value, transformType }) {
      sendUnityEvent(
        'ShortcutsModule',
        'SetPositionAlongAxis',
        JSON.stringify({
          value,
          type: transformType,
        })
      );
    },
  };

  const handleGraphicQualityChange = (index) => {
    sendUnityEvent('SettingsModule', 'SetRoomQualityReceiver', index);
  };

  const handleVerticalRotationSpeed = (speed) => {
    sendUnityEvent(
      'SettingsModule',
      'OnUpdateVerticalRotationSpeedReceiver',
      parseFloat(speed) || 0
    );
  };

  const handleHorizontalRotationSpeed = (speed) => {
    sendUnityEvent(
      'SettingsModule',
      'OnUpdateHorizontalRotationSpeedReceiver',
      parseFloat(speed) || 0
    );
  };

  const handleMeasureUnitChange = (index) => {
    console.log('handle measure unity sasha ', index);
    sendUnityEvent('MeasureModule', 'OnChangeSizeUnitReceviever', index);
  };

  const unityTigg = () => {
    sendUnityEvent('RoomModule', 'GetRoomInfo');
  };

  const roomModule = {
    GetRoomInfo() {
      // triggers get room info -> room info will come back in the callback -> GetRoomInfo
      sendUnityEvent('RoomModule', 'GetRoomInfo');
    },
    resetAll() {
      sendUnityEvent('RoomModule', 'ResetAllReceiver');
    },
    resetPosition() {
      sendUnityEvent('RoomModule', 'ResetPositionReceiver');
    },
    resetRotation() {
      sendUnityEvent('RoomModule', 'ResetRotationReceiver');
    },
    resetScale() {
      sendUnityEvent('RoomModule', 'ResetScaleReceiver');
    },
    ReplaceProp({ propId, objectName, newPropId, newPropUrl, newPropName, isMatch, roomLightDatas, preDefinedMetadata }) {
      sendUnityEvent(
        'RoomModule',
        'ReplaceProp',
        JSON.stringify({
          propIdToReplace: propId,
          objectNameToReplace: objectName,
          propIdToReplaceWith: newPropId,
          propToReplaceWithLocationUrl: newPropUrl,
          propName: newPropName,
          isMatch,
          preDefinedMetadata,
          roomLightDatas,
        })
      );
    },
    InstantiatePropReceiever(prop) {
      console.log('send props data', prop);
      sendUnityEvent('RoomModule', 'InstantiatePropReceiever', JSON.stringify(prop));
    },
  };

  const projectModule = {
    ApplyMaterialReceiver(materailData) {
      console.log('ApplyMaterialReceiver', materailData);
      sendUnityEvent('ProjectModule', 'ApplyMaterialReceiver', JSON.stringify(materailData));
    },
  };

  const cameraModule = {
    OnSelectDepthOfFieldReceiver(enabled) {
      sendUnityEvent('CameraModule', 'OnSelectDepthOfFieldReceiver', booleanToText(enabled));
    },
    OnCameraVerticalTiltReceiver(enabled) {
      sendUnityEvent('CameraModule', 'OnCameraVerticalTiltReceiver', booleanToText(enabled));
    },
    UpdateCameraHeightReceiver(height) {
      sendUnityEvent('CameraModule', 'UpdateCameraHeightReceiver', height);
    },
    OnUpdateCameraFovReceiver(fovVale) {
      sendUnityEvent('CameraModule', 'OnUpdateCameraFovReceiver', fovVale);
    },
    OnUpdateDepthOfFieldValueReceiver(intensity) {
      // update blur intensity
      sendUnityEvent('CameraModule', 'OnUpdateDepthOfFieldValueReceiver', intensity);
    },

    OnSelectFocusObjectReceiver() {
      sendUnityEvent('CameraModule', 'OnSelectFocusObjectReceiver');
    },
    ClearSelectedObjectReceiver() {
      sendUnityEvent('CameraModule', 'ClearSelectedObjectReceiver');
    },
    importPredefinedCameraFromPresets(cameraList) {
      // const unityCameraList = cameraList.map(convertCameraToUnityModel);
      sendUnityEvent(
        'CameraModule',
        'OnImportPrefinedCamAsNormalCamReceiver',
        JSON.stringify(cameraList)
      );
    },
    OnUpdateCameraResoYReceiver(resoY) {
      console.log('OnUpdateCameraResoYReceiver', resoY);
      sendUnityEvent('CameraModule', 'OnUpdateCameraResoYReceiver', resoY);
    },
    OnUpdateCameraResoXReceiver(resoX) {
      console.log('OnUpdateCameraResoXReceiver', resoX);
      sendUnityEvent('CameraModule', 'OnUpdateCameraResoXReceiver', resoX);
    },
    CloseFocusObjectReceiver() {
      console.log('CloseFocusObjectReceiver')
      sendUnityEvent('CameraModule', 'CloseFocusObjectReceiver');
    },
    OnUpdateLockReceiver(lock) {
      sendUnityEvent('CameraModule', 'OnUpdateLockReceiver', booleanToText(lock));
    },
    GetCurrentCameraScreenshotReceiver() {
      sendUnityEvent('CameraModule', 'GetCurrentCameraScreenshotReceiver');
    },
    SetFocusObjectReceiver({ selectedObjectName, blurIntensity }) {
      sendUnityEvent('CameraModule', 'SetFocusObjectReceiver', JSON.stringify({
        selectedObjectName,
        blurIntensity,
      }));
    },
    OnUpdateCameraMoveSpeed(speed) {
      sendUnityEvent('CameraModule', 'OnUpdateCameraMoveSpeed', speed);
    },
    OnUpdateCameraZoomSpeed(speed) {
      sendUnityEvent('CameraModule', 'OnUpdateCameraZoomSpeed', speed);
    },
    CurrentViewCameraAngleReceiver() {
      sendUnityEvent('CameraModule', 'CurrentViewCameraAngleReceiver');
    },
  };

  const mainController = {
    SetTransformation(transformData) {
      sendUnityEvent('MainController', 'SetTransformation', JSON.stringify(transformData));
    },
    EnableGameObject(unityObjectData) {
      console.log('EnableGameObject', unityObjectData);
      sendUnityEvent('MainController', 'EnableGameObject', JSON.stringify(unityObjectData));
    },
    DisableGameObject(config) {
      console.log('DisableGameObject', config);
      sendUnityEvent('MainController', 'DisableGameObject', JSON.stringify(config));
    },
    SelectObjectReceiver(objectList) {
      console.log('SelectObjectReceiver', objectList);
      sendUnityEvent('MainController', 'SelectObjectReceiver', JSON.stringify(objectList));
    },
  };

  window.roomModule = roomModule;
  window.metadataModule = metadataModule;

  window.DisableLogInUnity = (enabled) => {
    sendUnityEvent('MainController', 'DisableLogInUnity', JSON.stringify(enabled));
  }

  const contextValue = {
    unityTigg,
    handleMovement,
    handleLoad,
    handleLoadProject,
    unityContext,
    handlePropsLoad,
    handleSelectedCamera,
    handleCreateCamera,
    handleCameraFov,
    handleCameraCorrection,
    handleCameraHeight,
    handleDepthOfField,
    handleBlurIntensity,
    handleCameraScreenshot,
    handleCameraPopupClose,
    handleGraphicQualityChange,
    handleVerticalRotationSpeed,
    handleHorizontalRotationSpeed,
    handleMeasureUnitChange,

    materialModule,
    metadataModule,
    lightModule,
    shortcutsModule,
    roomModule,
    projectModule,
    cameraModule,
    mainController,
  }

  window.unityContext = {
    ...contextValue,
    unityContext
  };

  return (
    <unitycontext.Provider
      value={contextValue}>
      {children}
    </unitycontext.Provider>
  );
}

export const useUnityContext = () => useContext(unitycontext);

export default UnityContainer;
