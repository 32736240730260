/* eslint-disable */
import cx from 'classnames';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useToggleListItem } from '../../../../hooks/useToggleListItem';
import { CameraList } from './CameraList';

import styles from './RenderPreviewDialog.module.scss';
import { PreviewRenderForm } from '../components/camera/PreviewRenderForm';
import { icons } from '../../../../assets';
// import { CloseIconSmall } from '../../../icons/CloseIcon';

import { LockIcon } from '../../../icons/LockIcon';
import { RP_TYPE } from '../../../../config/constant/unityConstants';
import { errorToast } from '../../../../helpers/toastHelper';
const { CloseIconSmall } = icons;


export const RenderPreviewDialogUI = ({
  dialogClassName,
  contentClassName,
  title,
  onClose,
  show,
  cameraList,
  popupType,
  onSubmit,
  onCameraResolutionChange,
  onCameraClick,
  loading,
  isSiloRoom,
  hasMetadata,
}) => {
  const {
    ids: selectedCameraIds,
    toggleItem,
    toggleAll,
    resetSelectedItems,
    allSelected,
  } = useToggleListItem({ items: cameraList });
  const [selectedCameraId, setSelectedCameraId] = useState(null);

  const selectedCamera = cameraList.find((c) => c.id === selectedCameraId);
  const isPreview = popupType === RP_TYPE.PREVIEW;
  const isRender = popupType === RP_TYPE.RENDER;

  const handleCameraClick = (camera) => {
    setSelectedCameraId(camera.id);
    onCameraClick?.(camera)
  };

  const handleResolutionChange = (e) => {
    const { name, value } = e.target;
    const valueInt = parseInt(value) || 0;
    onCameraResolutionChange(name, valueInt, selectedCamera.id);
  };

  const handleResolutionBlur = (e) => {
    const { name, value } = e.target;
    const valueInt = parseInt(value);
    const isPositiveInteger = valueInt > 0;
    if (!isPositiveInteger) {
      onCameraResolutionChange(name, 1, selectedCamera.id);
    }
  };

  const resetValues = () => {
    resetSelectedItems();
    resetValues();
  }

  const handleSubmit = (values) => {
    onSubmit?.(values, selectedCameraIds, resetValues);
  };

  const handleClose = () => {
    if (loading) {
      errorToast('Please wait...')
      return;
    }

    onClose();
  }

  return (
    <Modal
      show={show}
      backdrop={true}
      onHide={handleClose}
      backdropClassName={styles.backdrop}
      onBackdropClick={handleClose}
      dialogClassName={cx(styles.modalDialog, dialogClassName, isPreview && styles.small)}
      contentClassName={cx(styles.modalContent, contentClassName)}>
      <Modal.Body>
        <h5 className={cx(styles.title, 'mb-3')}>{title}</h5>
        <button className={cx(styles.closeButton, isRender ? styles.btn1 : styles.btn2)} onClick={handleClose}>
          <CloseIconSmall />
        </button>

        <div className={cx(styles.container, isPreview && styles.containerColumn)}>
          <div className={isRender ? styles.renderCameraListContainer : styles.previewCameraListContainer}>
            <CameraList
              className={isRender ? styles.renderCameraList : styles.previewCameraList}
              cameraList={cameraList}
              selected={selectedCameraIds}
              allSelected={allSelected}
              onToggleAll={toggleAll}
              onToggle={toggleItem}
              onCameraClick={handleCameraClick}
            />
          </div>

          <div style={{ width: isRender ? '311px' : '100%', maxHeight: '320px' }}>
            {isRender && (
              <div className={styles.resolutionContainer}>
                <div className={`${styles.resolutionInput} mb-3`}>
                  <label className="form-label">Width (px)</label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    step={1}
                    disabled={!selectedCamera}
                    value={selectedCamera?.resoX || ''}
                    name="resoX"
                    onChange={handleResolutionChange}
                    onBlur={handleResolutionBlur}
                  />
                </div>
                <div className="lock-icon pt-3 d-flex align-items-center justify-content-center">
                  <LockIcon style={{ color: 'var(--neutral60-color)' }} />
                </div>
                <div className={`${styles.resolutionInput} mb-3`}>
                  <label className="form-label">Height (px)</label>
                  <input
                    type="number"
                    autoComplete="off"
                    className="form-control"
                    step={1}
                    disabled={!selectedCamera}
                    value={selectedCamera?.resoY || ''}
                    name="resoY"
                    onChange={handleResolutionChange}
                    onBlur={handleResolutionBlur}
                  />
                </div>
              </div>
            )}

            <PreviewRenderForm
              className={styles.previewRenderForm}
              type={popupType}
              onSubmit={handleSubmit}
              loading={loading}
              isSiloRoom={isSiloRoom}
              hasMetadata={hasMetadata}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
