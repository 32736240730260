import {
  API_ROOT_URL,
  TEXTURE_DETAILS_API,
  GET_TEXTURE,
  TEXTURE_API,
  TEXTURE_BULK_DELETE,
  TEXTURE_BULK_UPDATE,
  TEXTURE_BULK_UPLOAD,
  TEXTURE_DOWNLOAD,
} from "../../config/constant/apiConstant";
import api from "../../helpers/axiosHelper";
import { setSearchResult } from "../../redux/slicers/search/searchSlicer";
import {
  addTextureSuccess,
  fetchTextureListFailed,
  fetchTextureListStart,
  fetchTextureListSuccess,
  setTextureData,
  updateTexture,
} from "../../redux/slicers/textures/textureSlicer";
import { errorToast, successToast } from "../../helpers/toastHelper";
import { createListApiRequest } from "../../helpers/apiHelper";
import { uniqueId } from "lodash";
import { paginatedTexturesActions } from "../../redux/slicers/textures/paginatedTexturesSlicer";
import { setFilters } from "../../redux/slicers/admin/sidebarSlicer";
import { objectToSearchParams } from "../../helpers/jsHelper";
import { createDeleteApiAction, createGetListAction, createUpdateApiAction } from "../products";
import { toast } from "react-toastify";
import { LOADING_KEY } from "../../helpers/reduxHelper";
import { texturesActions } from "../../redux/slicers/texturesSlicer";
import { uploadsPageActions } from "../../redux/slicers/uploadsPageSlicer";

export const TexturesSearchApi = (searchVal) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${TEXTURE_DETAILS_API}?search=${searchVal}`)
    .success((data) => {
      dispatch(setSearchResult(data));
    })
    .error((error) => {
      // console.log("texture search data err:", error)
      // dispatch(setSearchResult(error))
    })
    .send();
};

export const TexturesList = (searchVal) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_TEXTURE}`)
    .success((data) => {
      console.log(data);
    })
    .error((error) => {
      // console.log("texture search data err:", error)
      // dispatch(setSearchResult(error))
    })
    .send();
};

/**
 * @method POST
 * @desc Uploads texture to the server
 */
export const TextureAddApi = (formData, cb) => (dispatch) => {
  dispatch(paginatedTexturesActions.setAddLoading(true));

  return api()
    .root(API_ROOT_URL)
    .post(TEXTURE_API)
    .data(formData)
    .success((res) => {
      dispatch(TextureFetchApi());
      dispatch(addTextureSuccess(res.data));
      dispatch(paginatedTexturesActions.addSuccess(res.data));
      successToast("Texture added successfully");
    })
    .error((e) => {
      if (e.message?.name?.[0]) {
        errorToast(e.message?.name?.[0])
      } else {
        errorToast("Some error occured")
      }
      dispatch(paginatedTexturesActions.addStart());
    })
    .finally(() => {
      dispatch(paginatedTexturesActions.setAddLoading(false));
    })
    .send(cb);
};

/**
 * @method GET
 * @desc Fetch list of textures for the user
 */
export const TextureFetchApi =
  ({ search, sort = "-created_at" } = {}) =>
  (dispatch) => {
    let API_URL = TEXTURE_API;

    API_URL = search ? `${API_URL}?search=${search}` : API_URL;
    API_URL = sort ? `${API_URL}?sort=${sort}` : API_URL;

    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => dispatch(setTextureData(data)))
      .error((error) => console.error("Error occured: ", error.message))
      .send();
  };

export const TextureFetchApiV2 = createListApiRequest({
  url: TEXTURE_API,
  startAction: fetchTextureListStart,
  successAction: fetchTextureListSuccess,
  errorAction: fetchTextureListFailed,
});

/**
 * @method PATCH
 * @desc Add/remove texture from user's favourite list
 */
export const TextureEditApi = (formData, id, callback) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .patch(`${TEXTURE_API}${id}/`)
    .data(formData)
    .success(({ status, data }) => {
      dispatch(TextureFetchApi());
      callback({ status: status, isFavourite: data.is_favourite });
    })
    .error((e) => {
      callback({ status: false });
    })
    .send();
};

/**
 * @method POST
 * @desc Upload multiple textures
 */
export const TextureBulkUploadApi = (formData, callback) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .post(TEXTURE_BULK_UPLOAD)
    .data(formData)
    .success(() => {
      callback({ status: "success" });
    })
    .error((e) => {
      errorToast(`Error: ${e.message}`);
      callback({ status: "error" });
    })
    .send();
};

/**
 * @method POST
 * @desc Edit multiple textures and move uploads to textures
 */
export const TextureBulkEditApi = (formData, callback) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .post(TEXTURE_BULK_UPDATE)
    .data(formData)
    .success(() => {
      callback({ status: "success" });
    })
    .error((e) => {
      errorToast(`Error: ${e.message}`);
      callback({ status: "error" });
    })
    .send();
};

/**
 * @method DELETE
 * @desc Delete texture
 */
export const TextureDeleteApi = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .delete(`${TEXTURE_API}${id}`)
    .success(() => {
      successToast("Texture successfully deleted");
      dispatch(TextureFetchApi());
      dispatch(TextureFetchApiV2({ is_map: false }));
    })
    .error((e) => errorToast(e.message))
    .send();
};

window.TextureDeleteApi = TextureDeleteApi

/**
 * @method POST
 * @desc Bulk delete textures
 */
export const TextureBulkDeleteApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .post(TEXTURE_BULK_DELETE)
    .data(formData)
    .success((res) => {
      dispatch(TextureFetchApi());
      dispatch(TextureFetchApiV2({ is_map: false }));
      successToast("Textures deleted");
    })
    .error((e) => {
      errorToast(e.message);
    })
    .send();
};

export const TextureEditV2Api = createUpdateApiAction({
  actions: paginatedTexturesActions,
  endpointUrl: TEXTURE_API,
})


export const TextureAddToFavouriteApi = (formData, id) => (dispatch) => {
  dispatch(paginatedTexturesActions.setItemLoading({
    id,
    key: LOADING_KEY.FAVOURITE,
    value: true,
  }))

  api()
    .root(API_ROOT_URL)
    .patch(`${TEXTURE_API}${id}/`)
    .data(formData)
    .success(({ data }) => {
      dispatch(updateTexture(data));
      dispatch(paginatedTexturesActions.update(data));
      
      if (data.is_favourite) {
        successToast("Added To Favourite");
      } else {
        successToast("Removed From Favourite");
      }
    })
    .error((e) => {
      errorToast(e.message);
    })
    .finally(() => {
      dispatch(paginatedTexturesActions.setItemLoading({
        id,
        key: LOADING_KEY.FAVOURITE,
        value: false,
      }))
    })
    .send();
};

/**
 * @method GET
 * @desc Get File data for download
 */
export const TextureDownloadApi = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${TEXTURE_DOWNLOAD}?ids[]=${id}`)
    .success((data) => {
      // Implement logic to be able to download data
      // ref link:- https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
      console.log(JSON.stringify(data));
      successToast(`Item ${id} downloaded successfully`);
    })
    .error((error) =>
      errorToast("Error occured while downloading item: ", error.message)
    )
    .send();
};



export const getTextureListApi = createGetListAction({
  endpoint: TEXTURE_API,
  actions: paginatedTexturesActions,
})

export const getTexturesPageApi = createGetListAction({
  endpoint: TEXTURE_API,
  skipBrandFilter: true,
  actions: texturesActions,
  setGlobalSidebarFilters: true,
});

export const getUploadsPageApi = createGetListAction({
  endpoint: TEXTURE_API,
  skipBrandFilter: true,
  actions: uploadsPageActions,
  setGlobalSidebarFilters: true,
});

export const updateTextureApi = createUpdateApiAction({
  actions: texturesActions,
  endpointUrl: TEXTURE_API,
})

export const updateUploadApi = createUpdateApiAction({
  actions: uploadsPageActions,
  endpointUrl: TEXTURE_API,
})

export const deleteTextureAPI = createDeleteApiAction({
  actions: texturesActions,
  endpointUrl: TEXTURE_API,
  successMessage: 'Deleted',
})
