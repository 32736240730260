const UserIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2351 11.446H12.2863H12.3055C14.0919 11.3883 15.5005 9.92702 15.5069 8.12609C15.5069 6.29311 14.0151 4.7998 12.1838 4.7998C10.3526 4.7998 8.86077 6.29311 8.86077 8.12609C8.86077 9.92702 10.263 11.3883 12.1198 11.446C12.1582 11.4395 12.2031 11.4395 12.2351 11.446ZM9.8212 8.12609C9.8212 6.82505 10.8841 5.76116 12.1838 5.76116C13.4836 5.76116 14.5465 6.82505 14.5465 8.12609C14.5401 9.39507 13.5541 10.4269 12.2991 10.4846C12.2159 10.4782 12.1262 10.4782 12.0942 10.4846C10.82 10.4397 9.8212 9.40789 9.8212 8.12609ZM8.61493 18.214C9.59605 18.8712 10.8932 19.1998 12.1838 19.1998C13.4744 19.1998 14.765 18.8712 15.7527 18.214C16.668 17.6028 17.1685 16.7681 17.1685 15.8677C17.1685 14.9673 16.6614 14.1392 15.7527 13.5346C13.7905 12.2267 10.5903 12.2267 8.61493 13.5346C7.69966 14.1458 7.19922 14.9804 7.19922 15.8808C7.19922 16.7812 7.69966 17.6093 8.61493 18.214ZM8.18692 15.8874C8.18692 15.3222 8.52933 14.7833 9.16146 14.3627C10.801 13.2717 13.5666 13.2717 15.2062 14.3627C15.8318 14.7767 16.1807 15.3156 16.1807 15.8743C16.1807 16.4395 15.8383 16.9784 15.2062 17.399C13.5666 18.4966 10.801 18.4966 9.16146 17.399C8.53591 16.985 8.18692 16.446 8.18692 15.8874Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserIcon;
