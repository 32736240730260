import { matchPath, useLocation } from 'react-router-dom';
import { CuratorInfoIcon } from '../components/admin/curator/CuratorInfoIcon/CuratorInfoIcon';
import { useUserContext } from '../contexts/UserContext';
import { CURATOR_ROUTES, PREVIEW_ROUTES } from '../routes';

export const PortalInfoIcon = () => {
  const location = useLocation();
  const { currentUser } = useUserContext();

  if (!currentUser?.id) return null;

  const excludeRoutes = [...PREVIEW_ROUTES, ...CURATOR_ROUTES];
  const match = excludeRoutes.find((path) => matchPath({ path }, location.pathname) !== null);
  if (match) return null;

  return <CuratorInfoIcon doHide={'Keyboard shortcuts'} className={'keepOnTop'} />;
};
