import { FilterItem } from "./FilterItem";
import styles from './SideFilter.module.scss';

export const SideFilter = ({ filters, maxVisible = 6, value, onChange }) => {
  return (
    <div className={styles.root}>
      {filters.map((filter, idx) => {
        const { title, items } = filter;
        return (
          <FilterItem
            key={idx}
            title={title}
            items={items}
            value={value}
            onChange={onChange}
            maxVisible={maxVisible}
          />
        );
      })}
    </div>
  );
};
