import { v4 as uuidv4 } from 'uuid';
import { openDB } from 'idb';
import { initialState, selectUuid } from '../redux/slicers/admin/curatorSlicer';
import { getAppState } from '../redux/store';

const FILE_KEY = {
  PROJECT_ZIP: 'projectZip',
};

const TEMP_STORE_NAME = `temp__${uuidv4()}`;

const INDEX = {
  CURATOR_INDEX: 'curatorUuidIndex',
};

const STORE = {
  PROPS: 'props',
  MATERIALS: 'materials',
  MATERIAL_TEXTURES_OLD: 'materialTexturesOld',
  TEXTURES: 'textures',
  LAYOUTS: 'layouts',
  FILES: 'files',
  TEMP_FILES: 'tempFiles',
};
export const idb = {
  curator: openDB('LiveFurnishCurator', 5, {
    blocked: () => {
      // TODO: handle this case when first upgrade will be needed
      console.log(`Please close this app opened in other browser tabs.`);
    },
    blocking: () => {
      // TODO: handle this case when first upgrade will be needed
      console.log(`App is outdated, please close this tab`);
    },
    upgrade: (db, oldVersion, newVersion, transaction) => {
      // TOOD: handle db upgrades when it's needed
      for (let version = oldVersion; version < newVersion; version++) {
        switch (version) {
          case 0:
            upgradeCuratorFromV0toV1();
            break;
          case 1:
            upgradeCuratorFromV1toV2();
            break;
          case 2:
            upgradeCuratorFromV2toV3();
            break;
          case 3:
            // This fixes bug
            upgradeCuratorFromV3toV4();
            break;
          case 4:
            upgradeCuratorFromV4toV5();
            break;
          default:
            console.error('unknown db version');
        }
      }

      function upgradeCuratorFromV0toV1() {
        db.createObjectStore(STORE.LAYOUTS, { keyPath: 'id' });
        db.createObjectStore(STORE.PROPS, { keyPath: 'id' });
        db.createObjectStore(STORE.MATERIALS, { keyPath: 'id' });
        db.createObjectStore(STORE.TEXTURES, { keyPath: 'id' });
      }

      function upgradeCuratorFromV1toV2() {
        db.createObjectStore(STORE.FILES, { keyPath: 'id' });
      }

      function upgradeCuratorFromV2toV3() {
        db.createObjectStore(STORE.MATERIAL_TEXTURES_OLD, { keyPath: 'id' });
      }

      function upgradeCuratorFromV3toV4() {
        // bugfix
        db.objectStoreNames;

        const stores = [
          STORE.PROPS,
          STORE.MATERIALS,
          STORE.LAYOUTS,
          STORE.FILES,
          STORE.MATERIAL_TEXTURES_OLD,
          STORE.TEXTURES,
        ];

        const existingNames = [...db.objectStoreNames];
        stores.forEach((storeName) => {
          const exist = existingNames.includes(storeName);
          if (exist) return;
          db.createObjectStore(storeName, { keyPath: 'id' });
        });
      }

      function upgradeCuratorFromV4toV5() {
        const tempFilesStore = db.createObjectStore(STORE.TEMP_FILES, { keyPath: 'id' });
        tempFilesStore.createIndex(INDEX.CURATOR_INDEX, 'curatorUuid', { unique: false });
      }
    },
  }),
};

export const getCuratorDB = () => idb.curator;

window.getCuratorDB = getCuratorDB;

// export const add
export const updateAsset = async (storeName, data) => {
  const db = await getCuratorDB();
  await db.put(storeName, data);
};

const assetStore = {};

export const getAsset = async (storeName, id) => {
  // if (!assetStore[storeName]) {
  //   assetStore[storeName] = {};
  // }

  // if (assetStore[storeName][id]) {
  //   return assetStore[storeName][id];
  // }

  const db = await getCuratorDB();
  const asset = db.get(storeName, id);
  asset.IDB_ITEM = true;

  // assetStore[storeName][id] = asset;
  return asset;
};

export const deleteAsset = async (storeName, id) => {
  const db = await getCuratorDB();
  db.delete(storeName, id);
};

const objectUrlStore = {}; // key = `{store}_{id}

const normalizeAsset = (asset, storeName) => {
  // TODO: add this url to some store and revoke it later so we don't keep it in memory
  const urlId = `${storeName}_${asset.id}`;
  if (!objectUrlStore[urlId]) {
    // objectUrlStore[urlId] = asset.originalFileUrl // URL.createObjectURL(asset.file);
    objectUrlStore[urlId] = URL.createObjectURL(asset.file);
  }

  asset.file = objectUrlStore[urlId];
  asset.IDB_ITEM = true;
  return asset;
};

export const getOrStoreAsset = async (storeName, data) => {
  const { id, version, file, ...rest } = data;

  if (rest.IDB_ITEM) {
    // it's item which was retrieved from idb, we don't need to store it
    return data;
  }

  try {
    let asset = await getAsset(storeName, id);

    if (!asset || !asset.file || asset.version !== version) {
      if (!file) throw new Error('File is missing. LF_999878');

      const blob = await fetch(file).then((res) => res.blob());
      asset = { ...rest, id, version, file: blob, originalFileUrl: file };
      await updateAsset(storeName, asset);
    }
    return normalizeAsset(asset, storeName);
  } catch (err) {
    console.log('sasha getOrStoreAsset error', err);
  }
};

export const updateDbProp = ({ id, name, version, product_file, ...rest }) => {
  return getOrStoreAsset(STORE.PROPS, { ...rest, id, name, version, product_file, file: product_file });
};

export const updateDbTexture = ({ id, name, version, texture_file, low_texture_file, file, ...rest }) => {
  return getOrStoreAsset(STORE.TEXTURES, {
    ...rest,
    id,
    name,
    version,
    texture_file,
    low_texture_file,
    file: low_texture_file || texture_file || file,
  });
};

export const updateDbLowTexture = (
  { id, name, version, low_texture_file, texture_file, ...rest },
  additionalData
) => {
  return getOrStoreAsset(STORE.TEXTURES, {
    ...rest,
    id,
    name,
    version,
    low_texture_file,
    texture_file,
    file: low_texture_file || texture_file,
    IS_LOW_TEXTURE: true,
    ...additionalData,
  });
};

export const updateDbMaterial = ({ id, name, version, material_image, file, ...rest }) => {
  return getOrStoreAsset(STORE.MATERIALS, {
    ...rest,
    id,
    name,
    version,
    material_image,
    file: material_image || file,
  });
};

export const updateDbMaterialTextures = ({ id, name, version, texture_file, low_texture_file, ...rest }) => {
  return getOrStoreAsset(STORE.MATERIAL_TEXTURES_OLD, {
    ...rest,
    id,
    name,
    version,
    texture_file,
    low_texture_file,
    file: texture_file || low_texture_file,
  });
};

export const updateDbLayout = ({ id, display_name, version, webgl, ...rest }) => {
  return getOrStoreAsset(STORE.LAYOUTS, { ...rest, id, name: display_name, version, webgl, file: webgl });
};

export const getTexture = async (id) => {
  const asset = await getAsset(STORE.TEXTURES, parseInt(id));
  return normalizeAsset(asset, STORE.TEXTURES);
};

export const getMaterial = async (id) => {
  const asset = await getAsset(STORE.MATERIALS, parseInt(id));
  return normalizeAsset(asset, STORE.MATERIALS);
};

export const getProp = async (id) => {
  const asset = await getAsset(STORE.PROPS, parseInt(id));
  return normalizeAsset(asset, STORE.PROPS);
};

export const getProejctZip = () => {
  return getAsset(STORE.FILES, FILE_KEY.PROJECT_ZIP);
};

export const setProjectZip = (file) => {
  return updateAsset(STORE.FILES, {
    id: FILE_KEY.PROJECT_ZIP,
    file,
  });
};

// temp db store
export const addDefaultMaterial = async ({ file, key, textureName, materialId }) => {
  return await updateAsset(STORE.TEMP_FILES, {
    id: key,
    file,
    curatorUuid: selectUuid(getAppState()),
    textureName,
    materialId,
  });
};

export const getDefaultMaterial = (key) => {
  return getAsset(STORE.TEMP_FILES, key);
};

export const deleteDefaultMaterial = (key) => {
  return deleteAsset(STORE.TEMP_FILES, key);
};

export const cleanUpCuratorFiles = async (curatorUuid) => {
  // get all assets related to curator uuid and delete them
  const db = await getCuratorDB();
  const tempFiles = await db.getAllFromIndex(STORE.TEMP_FILES, INDEX.CURATOR_INDEX);

  tempFiles.forEach((file) => {
    if (file.curatorUuid !== curatorUuid) return;

    db.delete(STORE.TEMP_FILES, file.id);
  });
};

const getCuratorTempFiles = async () => {
  const db = await getCuratorDB();
  return db.getAllFromIndex(STORE.TEMP_FILES, INDEX.CURATOR_INDEX);

}

export const cleanUpCuratorFilesExcept = async (curatorList) => {
  const db = await getCuratorDB();
  const tempFiles = await getCuratorTempFiles();
  tempFiles.forEach((file) => {
    if (curatorList.includes(file.curatorUuid)) return;
    db.delete(STORE.TEMP_FILES, file.id);
  });
};



export const getDefaultTextureList = async ({ textureNameList }) => {
  const tempFiles = await getCuratorTempFiles();
  const objectTextures = tempFiles.filter(tempFile => textureNameList.includes(tempFile.textureName));
  return objectTextures;
}

window.LFApp.idb = {
  getAsset,
  STORE,
}