import { DEFAULT_HORIZONTAL_ROTATION_SPEED, DEFAULT_PAN_SPEED, DEFAULT_VERTICAL_ROTATION_SPEED, DEFAULT_ZOOM_SPEED } from "../../constants/validationConstants";
import { CURATOR_DB } from "../constant/projectConstant";

export const CURATOR_CONFIG = {
    name: CURATOR_DB,
    version: 1,
    objectStoresMeta: [
        {
            store: CURATOR_DB,
            storeConfig: { keyPath: "id", autoIncrement: true },
            storeSchema: [
                { name: "product_id", keypath: "product_id", options: { unique: false } },
                { name: "elephants", keypath: "elephants", options: { unique: false } },
                { name: "name", keypath: "name", options: { unique: false } },
                { name: "thumbnail", keypath: "thumbnail", options: { unique: false } },
                { name: "product_file", keypath: "product_file", options: { unique: true } },
                { name: "product_json", keypath: "product_json", options: { unique: false } },
            ],
        },
    ],
};

export const CURATOR_SETTING = {
    metaData: [
        {
            id: 3,
            key: "Design",
            value: "Texture Name",
            seq_no: 2,
            is_render: true,
            display_name: "Design",
        },
        {
            id: 4,
            key: "Part Name",
            value: "Object Name",
            seq_no: 1,
            is_render: true,
            display_name: "Part Name",
        },
        {
            id: 1,
            key: "Reapeat Size",
            value: "Texture Dimention",
            seq_no: 4,
            is_render: true,
            display_name: "Reapeat Size",
        },
        {
            id: 2,
            key: "SKU ID",
            value: "SKU ID",
            seq_no: 3,
            is_render: true,
            display_name: "SKU ID",
        },
        {
            id: 6,
            key: "Rotation",
            value: "Texture Rotation",
            seq_no: 6,
            is_render: true,
            display_name: "Rotation",
        },
        {
            id: 8,
            key: "Test",
            value: "Test",
            seq_no: 8,
            is_render: true,
            display_name: "ABCD",
        },
        {
            id: 7,
            key: "Content",
            value: "Texture Descripton",
            seq_no: 7,
            is_render: false,
            display_name: "Content",
        },
        {
            id: 5,
            key: "Offset",
            value: "Texture Offset",
            seq_no: 5,
            is_render: true,
            display_name: "Offset",
        },
    ],
    measureUnit: [
        {
            id: 1,
            name: "inches",
            isSelected: false,
        },
        {
            id: 2,
            name: "feet",
            isSelected: true,
        },
        {
            id: 3,
            name: "cm",
            isSelected: false,
        },
        {
            id: 4,
            name: "meter",
            isSelected: false,
        },
    ],
    cameraSetting: {
        verticalRotationSpeed: DEFAULT_VERTICAL_ROTATION_SPEED,
        horizontalRotationSpeed: DEFAULT_HORIZONTAL_ROTATION_SPEED,
        zoomSpeed: DEFAULT_ZOOM_SPEED, 
        panSpeed: DEFAULT_PAN_SPEED,
    },
    graphicQuality: [
        {
            id: 1,
            name: "Fantastic",
            isSelected: false,
        },
        {
            id: 2,
            name: "Good",
            isSelected: false,
        },
        {
            id: 3,
            name: "Simple",
            isSelected: false,
        },
        {
            id: 4,
            name: "Performance",
            isSelected: true,
        },
    ],
    resolutionPresent: [
        {
            id: 1,
            name: "Preset1",
            width: 1000,
            height: 1000,
            isSelected: true,
        },
        {
            id: 2,
            name: "Web Reso",
            width: 1200,
            height: 1400,
            isSelected: false,
        },
        {
            id: 3,
            name: "4k",
            width: 1900,
            height: 2200,
            isSelected: false,
        },
    ],
};
