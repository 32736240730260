import cx from 'classnames';
import { rest } from 'lodash';
import { forwardRef } from 'react';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import styles from './InputController.module.scss';

export const InputController = ({
  control,
  name,
  label,
  className,
  multiline,
  noResize,
  style,
  height,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
  });

  const hasError = Boolean(error?.message);

  return (
    <InputUI
      hasError={hasError}
      label={label}
      value={value}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      multiline={multiline}
      style={style}
      error={error}
      className={className}
      noResize={noResize}
      height={height}
      {...rest}
    />
  );
};

export const InputUI = forwardRef(
  (
    {
      hasError,
      label,
      value,
      name,
      onChange,
      onBlur,
      multiline,
      style,
      error,
      className,
      noResize,
      height,
      ...rest
    },
    ref
  ) => {
    return (
      <FormGroup className={cx(className, hasError && styles.error)}>
        {label && <Form.Label className={styles.label}>{label}</Form.Label>}
        <FormControl
          {...rest}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          as={multiline ? 'textarea' : 'input'}
          className={cx(styles.input)}
          style={{
            ...style,
            resize: noResize ? 'none' : 'auto',
            height,
          }}
        />
        <FormControl.Feedback
          type={hasError ? 'invalid' : 'valid'}
          style={{
            display: hasError ? 'block' : 'none', // TODO: work on it, for some reason bootstrap doesn't show invalid feedback
          }}>
          {error?.message}
        </FormControl.Feedback>
      </FormGroup>
    );
  }
);
