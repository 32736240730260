import { memo, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useClickAway, useToggle } from 'react-use';
import {
  selectHighlightSelected,
  selectLightsVisibility,
} from '../../../../redux/slicers/admin/shortcutsModuleSlicer';
import { ChatIcon } from '../../../icons/ChatIcon';
import { CloseIcon } from '../../../icons/CloseIcon';
import { CrownIcon } from '../../../icons/CrownIcon';
import { InfoFilledIcon } from '../../../icons/InfoFilledIcon';
import { KeyboardIcon } from '../../../icons/KeyboardIcon';
import { MailIcon } from '../../../icons/MailIcon';
import { QuestionMarkCircleIcon } from '../../../icons/QuestionMarkCircleIcon';
import { TicketIcon } from '../../../icons/TicketIcon';
import { VisibilityOffIcon } from '../../../icons/VisibilityOffIcon';
import { useUnityShortcuts } from '../CuratorHeader/useUnityShortcuts';
import styles from './CuratorInfoIcon.module.scss';

export const CuratorInfoIcon = memo((props) => {
  const ref = useRef(null);
  const [open, toggleOpen] = useToggle(false);
  const { toggleLightsVisibility, toggleHighlightSelected, toggleShortcuts } = useUnityShortcuts();
  const highlightSelected = useSelector(selectHighlightSelected);
  const lightsVisibility = useSelector(selectLightsVisibility);
  useClickAway(ref, () => toggleOpen(false));

  const menu = [
    {
      label: 'Keyboard shortcuts',
      icon: <KeyboardIcon />,
      onClick: () => toggleShortcuts(),
    },
    {
      label: 'Book an expert',
      icon: <CrownIcon />,
      onClick: () => {
        window.open('https://www.livefurnish.com/#demo', '_blank');
      }
    },
    {
      label: 'Chat support',
      icon: <ChatIcon />,
      onClick: () => alert('TODO'),
    },
    {
      label: 'FAQs',
      icon: <QuestionMarkCircleIcon />,
      onClick: () => {
        window.open('https://www.livefurnish.com/#demo', '_blank');
      }
    },
    {
      label: 'Contact us',
      icon: <MailIcon />,
      onClick: () => {
        window.open('https://www.livefurnish.com/contact-us', '_blank');
      }
    },
    {
      label: 'Raise ticket',
      icon: <TicketIcon />,
      onClick: () => alert('TODO'),
    },
  ];

  return (
    <div className={cx(
      styles.root, 
      props.className ? styles.keepOnTop : ''
      )} ref={ref}>
      <InfoFilledIcon className={styles.infoIcon} onClick={() => toggleOpen()} />

      <div className={styles.popup}>
        <div className={styles.warningList}>
          {!lightsVisibility && (
            <Warning onClose={() => toggleLightsVisibility()}>Scene Lights</Warning>
          )}
          {!highlightSelected && (
            <Warning onClose={() => toggleHighlightSelected()}>Highlight selected</Warning>
          )}
        </div>
        
        {open && (
          <div className={styles.menu} onClick={() => toggleOpen(false)}>
            {menu.filter(a => a.label !== props?.doHide).map(({ icon, label, onClick }) => {
              return (
                <div className={styles.menuItem} onClick={onClick} key={label}>
                  {icon}
                  {label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});

const Warning = ({ children, onClose }) => {
  return (
    <Alert variant="danger" className={styles.warning}>
      <VisibilityOffIcon className={styles.eyeIcon} />
      <CloseIcon className={styles.closeIcon} onClick={onClose} />
      {children}
    </Alert>
  );
};
