import { useDispatch } from 'react-redux';
import { ProductEditApi } from '../api/products';
import { MODEL_TYPES } from '../constants/constants';
import { paginatedProductSelectors } from '../redux/slicers/admin/paginatedProductSlicer';
import { model3dDetailsActions } from '../redux/slicers/model3dDetailsSlicer';
import { propDetailsPopupActions } from '../redux/slicers/propDetailsPopupSlicer';
import { useModelActions } from './useModelActions';

export const useProductActions = ({ item, modelType }) => {
  const dispatch = useDispatch();
  const { favouriteLoading, onFavouriteToggle, onShare, addToCollection } = useModelActions({
    item,
    modelType: MODEL_TYPES.PRODUCT,
    selectors: paginatedProductSelectors,
    updateApi: ProductEditApi,
  });


  const onEdit = () => {
    modelType === MODEL_TYPES.THREEDMODELS 
      ? dispatch(model3dDetailsActions.show(item))
      : dispatch(propDetailsPopupActions.show(item));
  };

  return {
    favouriteLoading,
    onFavouriteToggle,
    onShare,
    addToCollection,
    onEdit,
  };
};
