import { useState } from "react"
import { NAME_SPECIAL_CHARS_V2 } from "../constants/validationConstants";

export const useNameInputValue = (defaultValue = '', { maxLength = Infinity } = {}) => {
  const [value, setValue] = useState(defaultValue);

  const onChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length > maxLength) return;

    const normalizedValue = newValue.replace(NAME_SPECIAL_CHARS_V2, '');
    setValue(normalizedValue);
  }

  return [value, onChange]
}