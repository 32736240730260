import { useEffect, useState } from "react";

export const useApi = ({ api, id, params = {}, skip, onLoad }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setData(null)
    fetchData();
  }, [id, ...Object.values(params), skip])
  
  const fetchData = async () => {
    if (skip) return;
    if (!id && !Object.keys(params).length) return;

    setLoading(true)

    let result;
    if (id) {
      result = await api({ id })
    } else {
      result = await api({ id, ...params })
    }
    
    if (result) {
      const item = result.results ? result.results[0] : result;
      setData(item);
      onLoad?.(item)
    }

    setLoading(false);
    setLoaded(true);
  }

  const update = (newData) => {
    setData(newData)
  }
  
  return {
    data,
    loading,
    refetch: fetchData,
    update,
    loaded,
  }
}