import PubSub from 'pubsub-js';
import { isEqual } from 'lodash';
import { AXIS } from '../../../../config/constant/unityConstants';
import { getProp } from '../../../../helpers/idb';
import { dispatcher } from '../../../../helpers/projectHelper';
import { getPropLightList, getPropMetadata } from '../../../../helpers/unityHelper';
import { UnityEvent, waitForEventOnce } from '../../../../helpers/unityPubSub';
import { selectSelectedAxis } from '../../../../redux/slicers/admin/curatorSlicer';
import {
  selectCopiedObjectProperties,
  selectObjectPositionAlongAxis,
  selectParsedObject,
  selectSelectedObjectList,
  setObjectPositionAlongAxisValue,
  setSelectedObjectList,
} from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { getAppState } from '../../../../redux/store';

export const mapObjectToSelect = (obj) => {
  return {
    objectName: obj.objectName,
    ObjectSelectionMode: obj.objectSelectionMode,
  };
};

export class UndoRedoUnityObject {
  constructor({ undoRedoInstance, unityContext, unityShortcuts }) {
    this.undoRedo = undoRedoInstance;
    this.unityContext = unityContext;
    this.unityShortcuts = unityShortcuts;
    this.ignoreSelectObjectCount = 0;
    this.replacePropInProgress = false;

    this.prevObjectSelectionList = [];

    this.init();

    this.subscribeTokens = [];
    this.subscribe();
  }

  subscribe = () => {
    const token1 = PubSub.subscribe(UnityEvent.OnPropsDuplicate, (_, objectsInfoList) => {
      this.duplicateObject({ objectList: objectsInfoList });
    });

    this.subscribeTokens.push(token1);
  };

  unsubscribe = () => {
    // TODO: refactor it to some parent class
    this.subscribeTokens.forEach((token) => PubSub.unsubscribe(token));
    this.subscribeTokens = [];
  };

  savePrevObjectListForDuplicateUndoSelection = () => {
    // this should happen before data is updated in redux store
    this.prevObjectSelectionList = selectSelectedObjectList(getAppState());
  }

  _isReplacePropInProgress = () => {
    return this.replacePropInProgress;
  };

  _getTransformData = (unityObject, key) => {
    return {
      objectName: unityObject.objectName,
      objectTransformInfo: {
        xPosition: unityObject[key].xPosition,
        yPosition: unityObject[key].yPosition,
        zPosition: unityObject[key].zPosition,
        xRotation: unityObject[key].xRotation,
        yRotation: unityObject[key].yRotation,
        zRotation: unityObject[key].zRotation,
        wRotation: unityObject[key].wRotation,
        xScale: unityObject[key].xScale,
        yScale: unityObject[key].yScale,
        zScale: unityObject[key].zScale,
      },
    };
  };

  _getEnableObjectData = async (unityObject, creationType) => {
    const { type, objectName, newTransformData } = unityObject;
    const id = parseInt(unityObject.id);

    if (type !== 'Prop') {
      return {
        propModel: {
          objectName,
        },
        creationType,
      };
    }

    const prop = await getProp(id);
    const lightList = await getPropLightList(prop);

    const data = {
      id,
      objectName,

      xPosition: newTransformData.xPosition,
      yPosition: newTransformData.yPosition,
      zPosition: newTransformData.zPosition,
      xRotation: newTransformData.xRotation,
      yRotation: newTransformData.yRotation,
      zRotation: newTransformData.zRotation,
      wRotation: newTransformData.wRotation,
      xScale: newTransformData.xScale,
      yScale: newTransformData.yScale,
      zScale: newTransformData.zScale,

      count: 1,
      type: 'CommandProp',
      propName: prop.name,
      locationUrl: prop.file,
      roomLightDatas: lightList,
      ...getPropMetadata(prop),
    };

    return {
      propModel: data,
      creationType,
    };
  };

  _mapObjectToSelect = mapObjectToSelect

  _enableObjectList = async (objectList, creationType = 'Create') => {
    const enableObjectPromises = objectList.map(async (object) => {
      this.ignoreSelectObjectCount += 1;
      const objectData = await this._getEnableObjectData(object, creationType);
      this.unityContext.mainController.EnableGameObject(objectData);
    });

    await Promise.all(enableObjectPromises);
    this.unityContext.metadataModule.GetRoomMetadataListReceiver();
  };

  _disableObjectList = (objectList, deletionType = 'Destroy') => {
    objectList.forEach((object) => {
      this.ignoreSelectObjectCount += 1;
      this.unityContext.mainController.DisableGameObject({
        objectName: object.objectName,
        deletionType,
      });
    });
    this.unityContext.metadataModule.GetRoomMetadataListReceiver();
  };

  _updateObjectPositionAlongAxis = ({ objectPositionAlongAxis, selectedAxis, transformData }) => {
    const tt = objectPositionAlongAxis.transformType;
    const suffix =
      tt === 'Move' ? 'Position' : tt === 'Rotate' ? 'Rotation' : tt === 'Scale' ? 'Scale' : null;

    const prefix = `${selectedAxis}`.toLowerCase();
    const key = `${prefix}${suffix}`;

    if (objectPositionAlongAxis.transformType && selectedAxis !== AXIS.ALL) {
      const value = transformData[key];
      dispatcher(setObjectPositionAlongAxisValue(value));
    }
  };

  init = () => {
    this.trasnform = this.undoRedo.createAction(({ objectList }) => {
      if (!objectList || !objectList.length) return;
      const objectPositionAlongAxis = selectObjectPositionAlongAxis(getAppState());
      const selectedAxis = selectSelectedAxis(getAppState());

      return {
        __ACTION__: 'UNITY_OBJECT__TRANSFORM',
        undo: () => {
          objectList.forEach((unityObject) => {
            const transformData = this._getTransformData(unityObject, 'oldTransformData');
            this.unityContext.mainController.SetTransformation(transformData);

            this._updateObjectPositionAlongAxis({
              objectPositionAlongAxis,
              selectedAxis,
              transformData: transformData.objectTransformInfo,
            });
          });
        },
        redo: () => {
          objectList.forEach((unityObject) => {
            const transformData = this._getTransformData(unityObject, 'newTransformData');
            this.unityContext.mainController.SetTransformation(transformData);

            this._updateObjectPositionAlongAxis({
              objectPositionAlongAxis,
              selectedAxis,
              transformData: transformData.objectTransformInfo,
            });
          });
        },
      };
    });

    this.selectObject = this.undoRedo.createAction(({ objectList }) => {
      // TODO: compare it to `prevObjectList` instead of prev history node
      // if (this.ignoreSelectObjectCount !== 0) {
      //   this.ignoreSelectObjectCount -= 1;
      //   console.log('sasha this.ignoreSelectObjectCount', this.ignoreSelectObjectCount)
      //   return;
      // }

      if (this._isReplacePropInProgress()) {
        return;
      }

      const lastNode = this.undoRedo.getCurrentNode();
      if (lastNode?.value && lastNode.value.type === 'UNITY_OBJECT___SELECT') {
        if (isEqual(lastNode.value.data.objectList, objectList)) {
          // don't add same object selection
          return;
        }
      }

      const prevObjectList = selectSelectedObjectList(getAppState());

      return {
        __ACTION__: 'UNITY_OBJECT__SELECT',
        type: 'UNITY_OBJECT___SELECT',
        data: {
          objectList,
          prevObjectList,
        },
        undo: () => {
          const objectsToSelect = prevObjectList.map(this._mapObjectToSelect);
          this.unityContext.mainController.SelectObjectReceiver(objectsToSelect);
          dispatcher(setSelectedObjectList(prevObjectList));
        },
        redo: () => {
          const objectsToSelect = objectList.map(this._mapObjectToSelect);
          this.unityContext.mainController.SelectObjectReceiver(objectsToSelect);
          dispatcher(setSelectedObjectList(objectList));
        },
      };
    });

    this.delete = this.undoRedo.createAction(({ objectList }) => {
      if (!objectList || !objectList.length) return;
      if (this._isReplacePropInProgress()) return;

      return {
        __ACTION__: 'UNITY_OBJECT__DELETE',
        undo: async () => {
          await this._enableObjectList(objectList, 'Enable');
        },
        redo: () => {
          this._disableObjectList(objectList, 'Inactive');
        },
      };
    });

    this.addProp = this.undoRedo.createAction(({ propData }) => {
      if (this._isReplacePropInProgress()) return;
      return {
        __ACTION__: 'UNITY_OBJECT__ADD_PROP',
        propData,
        undo: async () => {
          this.unityContext.mainController.DisableGameObject({
            objectName: propData.objectName,
            deletionType: 'Destroy',
          });
        },
        redo: async () => {
          const objectData = await this._getEnableObjectData(propData, 'Create');
          this.unityContext.mainController.EnableGameObject(objectData);
        },
      };
    });

    this.duplicateObject = this.undoRedo.createAction(({ objectList }) => {
      const prevObjectList = [...this.prevObjectSelectionList];
      this.prevObjectSelectionList = [];

      return {
        __ACTION__: 'UNITY_OBJECT__DUPLICATE_PROP',
        objectList,
        undo: () => {
          this._disableObjectList(objectList, 'Destroy');
          const objectsToSelect = prevObjectList.map(this._mapObjectToSelect);
          this.unityContext.mainController.SelectObjectReceiver(objectsToSelect);
          dispatcher(setSelectedObjectList(prevObjectList));
        },
        redo: async () => {
          this.unityContext.mainController.SelectObjectReceiver([]);
          dispatcher(setSelectedObjectList([]));

          await Promise.all(objectList.map(async (objectData) => {
            const objectDataTransformed = await this._getEnableObjectData(objectData, 'Create');
            this.unityContext.mainController.EnableGameObject(objectDataTransformed);
          }))
        },
      };
    }, { pause: true });

    this.changeOneAxisPositoin = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      const objectPositionAlongAxis = selectObjectPositionAlongAxis(getAppState());
      let { value, transformType } = objectPositionAlongAxis;

      return {
        __ACTION__: 'UNITY_OBJECT__CHANGE_ONE_AXIS_POSITION',
        prevValue,
        nextValue,
        undo: () => {
          dispatcher(setObjectPositionAlongAxisValue(prevValue));

          if (!Number.isNaN(parseFloat(prevValue))) {
            this.unityContext.shortcutsModule.SetPositionAlongAxis({
              value: parseFloat(prevValue) || 0,
              transformType,
            });
          }
        },
        redo: async () => {
          dispatcher(setObjectPositionAlongAxisValue(nextValue));

          if (!Number.isNaN(parseFloat(nextValue))) {
            this.unityContext.shortcutsModule.SetPositionAlongAxis({
              value: parseFloat(nextValue) || 0,
              transformType,
            });
          }
        },
      };
    });

    // replace prop
    // this.replacePropStart = (newProp, selectedProp) => {
    //   this.replacePropTempData = {
    //     newProp,
    //     selectedProp,
    //   };
    // }

    this.replaceProp = this.undoRedo.createAction(
      async ({ newProp, skipHistory, selectedProp }) => {
        this.replacePropInProgress = true;

        const newObjectList = await waitForEventOnce(UnityEvent.REPLACE_PROP_DONE);

        this.replacePropInProgress = false;

        if (skipHistory) return;

        return {
          __ACTION__: 'UNITY_OBJECT__REPLACE_PROP',
          newProp,
          newObjectList,
          selectedProp,
          undo: () => {
            this._disableObjectList(newObjectList, 'Destroy');
            this._enableObjectList([selectedProp], 'Enable');

            dispatcher(setSelectedObjectList([selectedProp]));
            const objectsToSelect = [selectedProp].map(this._mapObjectToSelect);
            this.unityContext.mainController.SelectObjectReceiver(objectsToSelect);
          },
          redo: () => {
            this.unityShortcuts.doReplaceProp(newProp, { skipHistory: true });
          },
        };
      }
    );
  };
}
