import React, { useState } from 'react';
import { warningToast } from '../helpers/toastHelper.js';
import { ModalButtonArea } from './';
import { icons } from '../assets';
import _, { isEmpty } from 'lodash';
import { apiAddToCollection } from '../helpers/api';
import { COLLECTION_PROPS } from '../constants/constants';
import { apiErrorHandler } from '../helpers/axiosHelper';
import pluralize from 'pluralize';
import { CreateCollectionForm } from './CreateCollectionForm';
import { ItemSelectListUI } from './OrgSection/ItemSelectListUI';
import PlusIcon from '../components/icons/PlusIcon';
import Button from '../components/Button/Button';
import { useCollectionList } from '../hooks/useCollectionList';
import ReactSelect from 'react-select';
import { reactSelectStyles } from '../helpers/reactSelectStyles';
import SearchBar from '../components/admin/curator/SearchBar/SearchBar';
import { useDispatch } from 'react-redux';
import { afterAddToCollection } from '../redux/slicers/admin/sidebarSlicer';
const { CollectionIcon, CollectionNewIcon } = icons;

export default function AddToCollections(props) {
  const dispatch = useDispatch();
  const { selectedList, id, cardType, onSuccess } = props;
  let isPlural = props.isPlural || (selectedList && selectedList.length > 0);
  const [showNew, setShowNew] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCollectionList, setSelectedCollectionList] = useState([]);

  const {
    list: collectionList,
    fetchMoreData,
    loading,
    search,
    onSearchChange,
    hasData,
    hasMore,
  } = useCollectionList({
    skip: !modalOpen,
  });

  let title = showNew ? 'Create collection' : 'Add items to collection';
  let subtitle = showNew
    ? 'Please add a name and description for the collection'
    : 'Select the collection where you want to add the items or create a new one';

  function saveAddCollections() {
    if (!isEmpty(setSelectedCollectionList)) {
      warningToast('Please selection at least one collection in which to add these items');
      return;
    }

    if (!processing) {
      setProcessing(true);
      let payload = new FormData();
      let field = pluralize.singular(
        cardType === 'sharedFiles' ? props?.sharedFile?.cardType : cardType
      );
      field = COLLECTION_PROPS(field);

      if (!field) {
        warningToast('This area does not support adding to collections. Please contact support.');
        setProcessing(false);
        return;
      }

      if (isPlural) {
        _.map(selectedList, (card) => {
          payload.append(field, card.id);
        });
      } else {
        if (cardType === 'sharedFiles') {
          payload.append(field, props?.sharedFile?.id);
        } else {
          payload.append(field, id);
        }
      }
      selectedCollectionList.forEach((collection) => {
        payload.append('collection', collection.id);
      });

      apiAddToCollection(
        payload,
        `Successfully added ${cardType} to collections`,
        `There was an error adding ${cardType} to collections`
      )
        .then((res) => {
          if (res && res.status) {
            const collectionWithitems = selectedCollectionList.map((c) => ({
              ...c,
              item_count: isPlural ? selectedList.length : 1,
            }));
            dispatch(afterAddToCollection(collectionWithitems));
            onSuccess?.();
            onClose();
          }
        })
        .catch(apiErrorHandler)
        .finally(() => setProcessing(false));
    }
  }

  const onCollectionCreateSuccess = (newCollection) => {
    setShowNew(false);
    setSelectedCollectionList([...selectedCollectionList, newCollection]);
  };

  function onClose() {
    setShowNew(false);
    setProcessing(false);
    setModalOpen(false);
    setSelectedCollectionList([]);
  }

  const hasSelectedItems = selectedCollectionList.length > 0;
  const searInputItems = selectedCollectionList.map((c) => ({
    value: c.id,
    label: c.name,
  }));

  const handleSelectChange = (data, eventInfo) => {
    if (eventInfo.action !== 'remove-value') return;
    const id = eventInfo.removedValue.value;
    const updateList = selectedCollectionList.filter((c) => c.id !== id);
    setSelectedCollectionList(updateList);
  };

  return (
    <ModalButtonArea
      button={props.button}
      bigButton={props.bigButton}
      bigBtnClass={props?.bigBtnClass}
      hideButtonText={props.hideButtonText}
      miniButton={props.miniButton}
      footerButton={props.footerButton}
      variant={props.disabled ? 'outline-secondary' : 'secondary'}
      buttonIcon={props.icon || CollectionIcon}
      buttonTitle="Add To Collection"
      disabled={props.disabled || false}
      title={props.title || title}
      subtitle={subtitle}
      onClose={onClose}
      setShowModal={setModalOpen}
      showModal={modalOpen}
      modalClassName="add-to-collection-dialog"
      size="md"
      body={
        <>
          {showNew ? (
            <CreateCollectionForm
              onCancel={() => setShowNew(false)}
              onSuccess={onCollectionCreateSuccess}
            />
          ) : (
            <div>
              {!hasSelectedItems && (
                <SearchBar
                  placeholder="Search collection"
                  className="mb-3 add-to-collection-search"
                  onChange={onSearchChange}
                  value={search}
                />
              )}
              {hasSelectedItems && (
                <ReactSelect
                  className="mb-4"
                  escapeClearsValue={false}
                  value={searInputItems}
                  components={{
                    Menu: () => null,
                  }}
                  isMulti
                  inputValue={search}
                  onInputChange={(e, info) => {
                    if (info.action === 'menu-close') return;
                    if (info.action === 'input-blur') return;

                    console.log('info', info);
                    onSearchChange(e);
                  }}
                  options={[]}
                  onChange={handleSelectChange}
                  isClearable={false}
                  placeholder={false}
                  styles={{
                    ...reactSelectStyles,
                    control: (styles) => ({
                      ...reactSelectStyles?.control?.(styles),
                      border: '1px solid #000000',
                    }),
                    indicatorsContainer: () => ({ display: 'none' }),
                    valueContainer: (styles) => ({
                      ...styles,
                      maxHeight: 90,
                      overflow: 'auto',
                      '::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }),
                  }}
                />
              )}
              <ItemSelectListUI
                dataType="object"
                onButtonClick={() => setShowNew(true)}
                buttonText={
                  <>
                    <PlusIcon />
                    Create collection
                  </>
                }
                className="mb-4"
                formatData={(collection) => {
                  return {
                    id: collection.id,
                    // image: collection.member_profile,
                    title: collection.name,
                  };
                }}
                items={collectionList}
                selected={selectedCollectionList}
                onChange={(list) => setSelectedCollectionList(list)}
                loading={loading}
                hasData={hasData}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                variant="checkbox"
                minHeight={290}
                placeholder
              />

              <Button
                onClick={saveAddCollections}
                disabled={processing || isEmpty(selectedCollectionList)}
                loading={processing}
                size="xlg"
                color="warning"
                fullWidth>
                Save
              </Button>
            </div>
          )}
        </>
      }
      iconSrc={props.iconSrc}
    />
  );
}
