import { createSlice } from "@reduxjs/toolkit";

const DEFAULT_LIGHT = {
  lightName: "",
  lightType: "Point",
  lightDetails: {
    Point: {
      lightColor: "#FFFFFF",
      intensity: 10,
      size: 0.5,
    },
    Spot: {
      lightColor: "#FFFFFF",
      intensity: 10,
      angle: 10,
      blend: 10,
      blurShadow: 10,
      size: 0.5,
    },
    Area: {
      lightColor: "#FFFFFF",
      intensity: 10,
      size: 0.5,
    },
    Direction: {
      lightColor: "#FFFFFF",
      intensity: 0.5,
      blurShadow: 10,
      size: 10,
    },
  },
  sizeWidth: 0.5,
  sizeHeight: 0.5,
  spotAngle: 30,
  blendSpot: 1,
  visibleInReflection: true,
  enableInRendering: true,
  is_portal: false,
  
};

export const HDRI_POPUP = {
  PRESETS: 'PRESETS',
  MY: 'MY',
}

export const ENVIRONMENT_LIGHT = {
  HDRI: 'HDRI',
  COLOR: 'COLOR',
}

export const TAB_NAME = {
  INDOOR: '1',
  ENVIRONMENT: '2',
};

const initialState = {
  createLoading: false,
  addLightFormData: DEFAULT_LIGHT,
  selectedLightId: null,
  hdriPopup: null, 
  uploadDialogOpen: false,
  popupSelectedHDRI: null,
  selectedHDRI: null,
  activeTab: TAB_NAME.INDOOR,
  environmentLightType: null,
  environmentColor: '#f00',
  environmentColorIntensity: 0,
  hdriRotation: 0,
  hdriIntensity: 0,
  previewHDRI: null,
  eyeToolActive: true,
  defaultEnvironmentLightData: null,
  jsonDefaultEnvironmentLightData: null, // this one just to compare if it was changed
  ligthColorPoup: {
    open: false,
    light: null,
    type: null,
    initialColor: null,
  }
}

const curatorLightSlicer = createSlice({
  name: "curatorLight",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload
    },
    setAddLightLoading: (state, { payload }) => {
      state.createLoading = payload;
    },
    addLightStart: (state, { payload }) => {
      state.createLoading = true;
    },
    addLightSuccess: (state, { payload }) => {
      state.createLoading = false;
    },
    addLightFailed: (state, { payload }) => {
      state.createLoading = false;
    },
    updateAddLightFormData: (state, { payload }) => {
      state.addLightFormData = payload;
    },
    setSelectedLightId: (state, { payload }) => {
      state.selectedLightId = payload;
    },
    toggleHdriPopup: (state, { payload }) => {
      state.hdriPopup = !payload ||  state.hdriPopup === payload ? null : payload;  
    },
    setUploadDialogOpen: (state, { payload }) => {
      state.uploadDialogOpen = payload;
    },
    setPopupSelectedHDRI: (state, { payload }) => {
      state.popupSelectedHDRI = payload;
    },
    setSelectedHDRI: (state, { payload }) => {
      state.selectedHDRI = payload;
    },
    setEnvironmentLightType: (state, { payload }) => {
      state.environmentLightType = payload;

      if (!state.selectedHDRI) {
        state.selectedHDRI = state.defaultEnvironmentLightData?.hdri || null;
      }
    },
    setEnvironmentColor: (state, { payload }) => {
      state.environmentColor = payload;
    },
    setEnvironmentColorIntensity: (state, { payload }) => {
      state.environmentColorIntensity = payload;
    },
    setHDRIRotation: (state, { payload }) => {
      state.hdriRotation = payload;
    },
    setHDRIIntensity: (state, { payload }) => {
      state.hdriIntensity = payload;
    },
    setPreviewHDRI: (state, { payload }) => {
      state.previewHDRI = payload;
    },
    openLightColorPopup: (state, { payload }) => {
      state.ligthColorPoup = {
        open: true,
        light: payload.light,
        type: payload.type,
        initialColor: payload.color,
      }
    },
    setDefaultEnvironmentLightData: (state, { payload }) => {
      state.defaultEnvironmentLightData = payload;
    },
    setJsonDefaultEnvironmentLightData:  (state, { payload }) => {
      state.jsonDefaultEnvironmentLightData = payload;
    },

    setEyeToolActive: (state, { payload }) => {
      state.eyeToolActive = payload;
    },
    closeLightColorPopup: (state) => {
      state.ligthColorPoup = {
        open: false,
        light: null,
        type: null,
        initialColor: null,
      }
    }
    
  },
});

export const {
  setAddLightLoading,
  addLightStart,
  addLightSuccess,
  addLightFailed,
  updateAddLightFormData,
  setSelectedLightId,
  toggleHdriPopup,
  setUploadDialogOpen,
  setPopupSelectedHDRI,
  setSelectedHDRI,
  setEnvironmentLightType,
  setEnvironmentColor,
  environmentColorIntensity,
  setHDRIRotation,
  setPreviewHDRI,
  openLightColorPopup,
  closeLightColorPopup,
  setEyeToolActive,
  setDefaultEnvironmentLightData,
  setJsonDefaultEnvironmentLightData,
  setActiveTab,
  setHDRIIntensity,
  setEnvironmentColorIntensity,
} = curatorLightSlicer.actions;
export const curatorLightActions = curatorLightSlicer.actions;

export const curatorLightSelector = (state) => state.curatorLight;
export const curatorLightReducer = curatorLightSlicer.reducer;
export default curatorLightReducer;


export const selectEnvironmentLightType = (state) => state.curatorLight.environmentLightType;
export const selectEnvironmentColor = (state) => state.curatorLight.environmentColor;
export const selectEnvironmentColorIntensity = (state) => state.curatorLight.environmentColorIntensity;
export const selectHDRIIntensity = (state) => state.curatorLight.hdriIntensity
export const selectHDRIRotation = (state) => state.curatorLight.hdriRotation
export const selectSelectedHDRI = (state) => state.curatorLight.selectedHDRI
export const selectPreviewHDRI = (state) => state.curatorLight.previewHDRI
export const selectDefaultEnvironmentLightData = (state) => state.curatorLight.defaultEnvironmentLightData
export const selectActiveTab = (state) => state.curatorLight.activeTab
export const selectSelectedLightId = (state) => state.curatorLight.selectedLightId;
export const selectHdriPopup = (state) => state.curatorLight.hdriPopup
export const selectHdriPopupOpen = (state) => Boolean(state.curatorLight.hdriPopup)
export const selectEyeToolActive = (state) => state.curatorLight.eyeToolActive;
export const selectJsonDefaultEnvironmentLightData = (state) => state.curatorLight.jsonDefaultEnvironmentLightData;