import { useEffect, useState } from "react";

export const TOGGLE_MODE = {
  ID: 'id', 
  ITEM: 'item',
}

export const useToggleListItem = ({ reset, items, mode = TOGGLE_MODE.ID } = {}) => {
  const [selected, setSelected] = useState([]);

  const toggleItem = (item) => {
    if (mode === TOGGLE_MODE.ID) {
      selected.includes(item.id) 
        ? setSelected(selected.filter(id => id !== item.id))
        : setSelected([...selected, item.id]);

    } else {
      selected.find(selectedItem => selectedItem.id === item.id)
        ? setSelected(selected.filter(selectedItem => selectedItem.id !== item.id))
        : setSelected([...selected, item]);
    }
  }

  useEffect(() => {
    if (reset) setSelected([])
  }, [reset])

  const resetSelectedItems = (id) => {
    if (id) {
      setSelected(selected.filter(_id => _id !== id))
    } else {
      setSelected([])
    }
  }

  const toggleAll = () => {
    if (selected.length === items.length) {
      setSelected([])
    } else {
      setSelected(items.map(item => item.id))
    }
  }

  return {
    ids: selected,
    selected,
    toggleItem,
    resetSelectedItems,
    setIds: setSelected,
    setSelected,
    toggleAll,
    allSelected: items?.length ===  selected.length,
  }
}