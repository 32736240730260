import _ from 'lodash';
import { Suspense, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ActivityContainer } from './components/container/activityContainer';
import InfoDialog from './components/InfoDialog/InfoDialog';
import { FilterContext, ThumbnailCardContext } from './contexts';
import { Loading } from './layouts';
import { PREVIEW_ROUTES, Routing, SHOW_DRILLDOWN_MENU_ROUTES } from './routes';
import { useLoadRoomTypes } from './hooks/useRoomTypes';
import { SharePopupController } from './common/SharePopupController';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { Loader } from './common';
import { PortalInfoIcon } from './common/PortalInfoIcon';

export const assessDrillDownSideMenuRoute = (pathname) =>
  _.some(SHOW_DRILLDOWN_MENU_ROUTES, (rte) => {
    return matchPath({ path: rte }, pathname) !== null;
  });

export const assessPreviewRoute = (pathname) =>
  _.some(PREVIEW_ROUTES, (rte) => {
    return matchPath({ path: rte }, pathname) !== null;
  });

export default function App() {
  const location = useLocation();
  const pathname = location?.pathname;

  // TODO: refactor filter context
  // TODO: refactor routing
  // TODO: refactor user context
  const [showDrilldownMenu, setShowDrilldownMenu] = useState(false);
  const [hideSidemenu, setHideSidemenu] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState(null);

  useEffect(() => {
    setShowDrilldownMenu(assessDrillDownSideMenuRoute(pathname));
    setHideSidemenu(assessPreviewRoute(pathname));
  }, [pathname]);

  const filterContext = {
    hideSidemenu: hideSidemenu,
    showDrilldownMenu: showDrilldownMenu,
    breadcrumbs: breadcrumbs,
    setBreadcrumbs: setBreadcrumbs,
  };

  useEffect(() => {
    if (active) {
      setActive(null);
    }
  }, [location]);

  // loads room type options so they are available in the app
  useLoadRoomTypes();

  // TODO: check if we really need context for thumbnail menu click
  const [active, setActive] = useState(null);
  const thumbnailCardInfo = { active, setActive };

  return (
    <ActivityContainer>
      <Suspense fallback={<Loader loading={true} position="relative" className="scroll-loader" />}>
        <ErrorBoundary>
          <FilterContext.Provider value={filterContext}>
            <ThumbnailCardContext.Provider value={thumbnailCardInfo}>
              <Routing />
            </ThumbnailCardContext.Provider>
          </FilterContext.Provider>
          <SharePopupController />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={5}
          />
          <PortalInfoIcon />
          <InfoDialog />
        </ErrorBoundary>
      </Suspense>
    </ActivityContainer>
  );
}