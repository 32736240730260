import { useDispatch, useSelector } from 'react-redux';
import { addToCollectionActions } from '../redux/slicers/addToCollectionPopupSlicer';
import { sharePopupActions } from '../redux/slicers/sharePopupSlicer';
import { useInfoDialogaActions } from './useInfoDialogaActions';

export const useModelActions = ({ selectors, updateApi, item, deleteConfig, modelType }) => {
  const dispatch = useDispatch();
  const { openInfoDialog } = useInfoDialogaActions();
  const favouriteLoading = useSelector((state) =>{
    if (!item?.id) return null;
    return selectors.selectIsFavouriteLoading(state, item.id)
  }
    
  );

  const onFavouriteToggle = (clickedItem) => (event) => {
    const tempItem = clickedItem || item;

    dispatch(
      updateApi({
        id: tempItem.id,
        is_favourite: !tempItem.is_favourite,
      })
    );
  };

  const onDelete = (id) => {
    openInfoDialog({
      title: deleteConfig.title,
      description: deleteConfig.description || 'Your items can be restored from the Trash area.',
      cancelButtonText: 'No',
      buttonText: 'Yes',
      onButtonClick: () => {
        dispatch(deleteConfig.deleteApi({ id }));
      },
    });
  };

  const onShare = () => {
    dispatch(sharePopupActions.share({
      [modelType]: [item],
    }));
  }

  const addToCollection = () => {
    dispatch(addToCollectionActions.add({
      items: [item],
      modelType: modelType,
    }))
  }

  return {
    favouriteLoading,
    onFavouriteToggle,
    onDelete,
    onShare,
    addToCollection,
  };
};
