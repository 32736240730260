import { useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import ReactSelect from 'react-select';
import ArrowDownIcon from '../../components/icons/ArrowDownIcon';
import { reactSelectStyles, modifierSmall } from '../../helpers/reactSelectStyles';
import styles from './Pagination.module.scss';

export const Pagination = ({
  currentPage,
  perPage,
  totalItems,
  onPageChange,
  onPerPageChange,
  perPageOptions,
}) => {
  const pageCount = Math.ceil(totalItems / perPage);

  const normalizedPerPageOptions = useMemo(() => {
    if (!perPageOptions) return;

    return perPageOptions.map((count) => ({
      value: count,
      label: count,
    }));
  }, [perPageOptions]);

  if (!currentPage || !totalItems) return null;

  const perPageValue = normalizedPerPageOptions?.find((o) => o.value === perPage);

  return (
    <div className={styles.container}>
      <ReactPaginate
        containerClassName={`${styles.pagination} pagination`}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        activeClassName="active"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        nextLabel={<ArrowDownIcon className={styles.nextArrow} />}
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={3}
        forcePage={currentPage - 1}
        pageCount={pageCount}
        previousLabel={<ArrowDownIcon className={styles.prevArrow} />}
        renderOnZeroPageCount={null}
      />
      {normalizedPerPageOptions && (
        <div className="ms-3">
        <ReactSelect
          options={normalizedPerPageOptions}
          isSearchable={false}
          value={perPageValue}
          maxMenuHeight={200}
          // menuIsOpen
          onChange={(arg1) => {
            onPerPageChange?.(arg1.value);
          }}
          menuPlacement="auto"
          styles={{
            ...reactSelectStyles,
            ...modifierSmall,
          }}
        />
        </div>
      )}
    </div>
  );
};
