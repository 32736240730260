import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { selectUuid } from "../redux/slicers/admin/curatorSlicer"
import { getAppState } from "../redux/store"

const LF_REFRESH_CURATOR_STATUS = 'LF_REFRESH_CURATOR_STATUS';
const ACTIVE_CURATOR_UUID = 'ACTIVE_CURATOR_UUID';

export const useCheckCuratorActive = () => {

  const isCuratorActive = async () => {

    const counter = parseInt(localStorage.getItem('LF_REFRESH_CURATOR_STATUS')) || 0;
    localStorage.setItem(ACTIVE_CURATOR_UUID, JSON.stringify([]))
    localStorage.setItem(LF_REFRESH_CURATOR_STATUS, counter + 1);
    await new Promise(resolve => {
      // timeout to wait for all curators  adds their ids to localstorage
      setTimeout(resolve, 200)
    })

    const activeCuratorsList = JSON.parse(localStorage.getItem(ACTIVE_CURATOR_UUID));
    return !isEmpty(activeCuratorsList);
  }

  const getCuratorList = () => {
    const activeCuratorsList = JSON.parse(localStorage.getItem(ACTIVE_CURATOR_UUID));
    return activeCuratorsList || [];
  }

  return {
    isCuratorActive,
    refreshCuratorList: isCuratorActive,
    getCuratorList,
  }
}

export const useCuratorActiveStatus = () => {
  useEffect(() => {
    const listener = (event) => {
      if (event.storageArea != localStorage) return;
      if (event.key === 'LF_REFRESH_CURATOR_STATUS') {
        const uuid = selectUuid(getAppState());
        const activeCuratorsList = JSON.parse(localStorage.getItem(ACTIVE_CURATOR_UUID)) || [];
        activeCuratorsList.push(uuid);
        localStorage.setItem(ACTIVE_CURATOR_UUID, JSON.stringify(activeCuratorsList))
      }
     };

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [])
}