import {createSlice} from "@reduxjs/toolkit";

const cameraAngle = createSlice({
    name: 'cameraAngle',
    initialState: {
        getCameraPresetAnglesList: [],
        createCameraPresetStatus: false,
        deleteCameraPresetStatus: false,
        addToPresetsConflict: null,
        addToPresetsLoading: false,
        
        selectedCameraRPList: []
    },
    reducers: {
        setCameraPresetAnglesList: (state, {payload}) => {
            state.getCameraPresetAnglesList = payload
        },
        setCreateCameraPresetStatus: (state, {payload}) => {
            state.createCameraPresetStatus = payload
        },
        setDeleteCameraPresetStatus: (state, {payload}) => {
            state.deleteCameraPresetStatus = payload
        },
        setSelectedCameraRPList: (state, {payload}) => {
            state.deleteCameraPresetStatus = payload
        },
        setAddToPresetsLoading: (state, {payload}) => {
            state.addToPresetsLoading = payload
        },
        addToPresetsSuccess: (state, {payload}) => {
            state.getCameraPresetAnglesList = [payload, ...state.getCameraPresetAnglesList];
        },
        setAddToPresetsConflict: (state, { payload }) => {
            state.addToPresetsConflict = payload;
        }
    }
})

export const {
    getCameraPresetAnglesList, setCameraPresetAnglesList, 
    createCameraPresetStatus, setCreateCameraPresetStatus, 
    deleteCameraPresetStatus, setDeleteCameraPresetStatus,
    selectedCameraRPList, setSelectedCameraRPList,
    setAddToPresetsLoading,
    addToPresetsSuccess,
    setAddToPresetsConflict,
} = cameraAngle.actions;
export const cameraAngleSelector = state => state.cameraAngle;
export const cameraAngleReducer = cameraAngle.reducer;
export default cameraAngleReducer;

export const selectCameraPresetAnglesList = (state) => state.cameraAngle.getCameraPresetAnglesList;
export const selectCameraPresetAnglesNameList = (state) => state.cameraAngle.getCameraPresetAnglesList.map(c => c.cameraName);
export const selectAddToPresetsLoading = (state) => state.cameraAngle.addToPresetsLoading
export const selectAddToPresetsConflict = (state) => state.cameraAngle.addToPresetsConflict
export const selectAddToPresetConflictCamera = (state) => {
    const id = state.cameraAngle.addToPresetsConflict;
    if (!id) return null;

    return state.projectCameraAngle.getProjectCameraAnglesList.find(c => c.id === id);
}