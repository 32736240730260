import { createSlice } from "@reduxjs/toolkit";

const curatorProductSlicer = createSlice({
  name: "curatorProduct",
  initialState: {
    
  },
  reducers: {
    
  },
});

// export const {
//   // toggleFilterOpen
// } = curatorProductSlicer.actions;
export const actions = curatorProductSlicer.actions;

export const curatorProductSelector = (state) => state.curatorProduct;
export const curatorProductReducer = curatorProductSlicer.reducer;
export default curatorProductReducer;
