import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import _ from 'lodash';

export default function Breadcrumbs(props) {
  if (!props || !props.list || props.list.length < 1) {
    return null;
  }

  let breadcrumbColumn = 12;
  if (props.middleArea) {
    breadcrumbColumn = breadcrumbColumn - 6;
  }
  if (props.rightArea) {
    breadcrumbColumn = breadcrumbColumn - 2;
  }

  return (
    <div className={cx('breadcrumbHeader', props.fullWidth && 'breadcrumbHeader--fullWidth')}>
      <Row>
        <div className="w-auto">
          <Breadcrumb>
            {_.map(props.list, (item, index) => {
              if (item.link) {
                return (
                  <Breadcrumb.Item
                    as={Link}
                    to={item.link}
                    linkAs="span"
                    key={`breadcrumbLink${index}`}>
                    {item.label}
                  </Breadcrumb.Item>
                );
              }
              return (
                <Breadcrumb.Item key={`breadcrumbLink${index}`} active>
                  {item.label}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </div>
        {props.middleArea && <div className="w-auto">{props.middleArea}</div>}
        {props.rightArea && <div className="w-auto">{props.rightArea}</div>}
      </Row>
    </div>
  );

  // return (
  //   <div className={cx('breadcrumbHeader', props.fullWidth && 'breadcrumbHeader--fullWidth')}>
  //     <Row className="my-0 ms-0 me-2 p-0 align-items-center justify-content-between">
  //       <div className="w-auto">
  //         <Breadcrumb className="pt-3">
  //           {_.map(props.list, (item, index) => {
  //             if (item.link) {
  //               return (
  //                 <Breadcrumb.Item
  //                   as={Link}
  //                   to={item.link}
  //                   linkAs="span"
  //                   key={`breadcrumbLink${index}`}>
  //                   {item.label}
  //                 </Breadcrumb.Item>
  //               );
  //             }
  //             return (
  //               <Breadcrumb.Item key={`breadcrumbLink${index}`} active>
  //                 {item.label}
  //               </Breadcrumb.Item>
  //             );
  //           })}
  //         </Breadcrumb>
  //       </div>
  //       {props.middleArea && <div className="w-auto">{props.middleArea}</div>}
  //       {props.rightArea && <div className="w-auto">{props.rightArea}</div>}
  //     </Row>
  //   </div>
  // );
}
