import _ from 'lodash';
import { Download, AddToCollections, SharePopup, DeactivatePopup } from '../common';
import {
  EDIT_ICON,
  SHARE_ICON,
  DELETE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  ARCHIVE,
} from '../constants/assetConstants';
import {
  Filter_By_Value,
  Filter_By_Label,
  Sort_By_Value,
  Sort_By_Label,
  Room_Template_Label,
  Lifestyle_Label,
  Silo_Label,
  Vignette_Label,
  Web_Viewer_360_Label,
  Video_Label,
  Configurator_Label,
  Augmented_Reality_Label,
  Recent_First_Value,
  Oldest_First_Value,
  A_Z_Value,
  Z_A_Value,
  Recent_First_Label,
  Oldest_First_Label,
  A_Z_Label,
  Z_A_Label,
  My_Projects_Label,
  My_Projects_Value,
  Previews_Label,
  Previews_Value,
  Renders_Label,
  Renders_Value,
  Models_3d_Label,
  Models_3d_Value,
  Uploads_Label,
  Uploads_Value,
  Collections_Label,
  Collections_Value,
  Textures_Label,
  Textures_Value,
  Props_Label,
  Props_Value,
  Templates_Label,
  Templates_Value,
  Rooms_Label,
  Rooms_Value,
  Requests_Value,
  Requests_Label,
  Shared_Files_Value,
  Shared_Files_Label,
  With_Me_Label,
  With_Organisation_Label,
  By_Me_Label,
  With_Me_Value,
  With_Organisation_Value,
  By_Me_Value,
  Notification_Recent_First_Value,
  Notification_Oldest_First_Value,
  Notification_A_Z_Value,
  Notification_Z_A_Value,
  Notification_From_LF,
} from '../constants/filters';
import {
  MODEL_TYPES,
  MODULE_TYPE_OPTIONS,
  NOTIFICATION_MODEL_TYPE,
  TRASH_MODEL_TYPE,
} from '../constants/constants';
import { useRoomTypeOptions } from '../hooks/useRoomTypes';
import { ROOM_TYPE } from '../constants/projectConstants';
import { COLLECTION_DETAILS_API, GET_PROJECT_API, ROOM_DETAILS_API, TEXTURE_DETAILS_API } from "../config/constant/apiConstant";
import { useUserContext } from '../contexts/UserContext';

// Filter Options
const filterByOption = { label: Filter_By_Label, value: Filter_By_Value };
const noneOption = { label: 'None', value: '' };
const createdFromApp = { label: 'Created from App', key: 'is_custom', apiValue: true, value: '-1' };
const roomFilter = { label: Room_Template_Label, value: ROOM_TYPE.ROOM };
const lifestyleFilter = {
  label: Lifestyle_Label,
  value: ROOM_TYPE.LIFESTYLE,
};
const siloFilter = { label: Silo_Label, value: ROOM_TYPE.SILO };
const vignetteFilter = { label: Vignette_Label, value: ROOM_TYPE.VIGNETTE };
const threeSixtyFilter = {
  label: Web_Viewer_360_Label,
  value: ROOM_TYPE.THREESIXTY,
};
const videoFilter = { label: Video_Label, value: ROOM_TYPE.VIDEO };
const configuratorFilter = {
  label: Configurator_Label,
  value: ROOM_TYPE.CONFIGURATOR,
};
const myProjectsFilter = { label: My_Projects_Label, value: MODULE_TYPE_OPTIONS.PROJECT };
export const previewsFilter = { label: Previews_Label, key: 'is_preview', apiValue: true, value: MODULE_TYPE_OPTIONS.PREVIEWS };
export const rendersFilter = { label: Renders_Label, key: 'is_preview', apiValue: false, value: MODULE_TYPE_OPTIONS.RENDERS };
const models3DFilter = { label: Models_3d_Label, value: MODULE_TYPE_OPTIONS.THREEDMODELS };
const uploadsFilter = { label: Uploads_Label, key: 'is_upload', apiValue: true, value: MODULE_TYPE_OPTIONS.UPLOADS };
const collectionsFilter = {
  label: Collections_Label,
  value: Collections_Value,
};
const texturesFilter = { label: Textures_Label, value: MODULE_TYPE_OPTIONS.TEXTURES };
const propsFilter = { label: Props_Label, value: MODULE_TYPE_OPTIONS.PROPS };
const templatesFilter = { label: Templates_Label, value: MODULE_TYPE_OPTIONS.TEMPLATES };
const roomsFilter = { label: Rooms_Label, value: MODULE_TYPE_OPTIONS.ROOMS };
const requestsFilter = { label: Requests_Label, value: Requests_Value };
const shareFilesFilter = {
  label: Shared_Files_Label,
  value: Shared_Files_Value,
};
const materialFilter = { label: Rooms_Label, value: MODULE_TYPE_OPTIONS.MATERIAL }; // TODO
const servicesFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const newFeatureFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const fromLiveFurnishFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const archivedFilter = { label: 'Archived', value: 'ARCHIVED', key: 'is_archived', apiValue: true, }; 
const createdFromAppFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO

// sort Options
const SortByOption = { label: Sort_By_Label, value: Sort_By_Value };
export const SortRecentFirst = {
  label: Recent_First_Label,
  value: Recent_First_Value,
};
const SortOldestFirst = {
  label: Oldest_First_Label,
  value: Oldest_First_Value,
};
const SortAZ = { label: A_Z_Label, value: A_Z_Value };
const SortZA = { label: Z_A_Label, value: Z_A_Value };
const NotificationSortRecentFirst = {
  label: Recent_First_Label,
  value: Notification_Recent_First_Value,
};
const NotificationSortOldestFirst = {
  label: Oldest_First_Label,
  value: Notification_Oldest_First_Value,
};
const NotificationSortAZ = { label: A_Z_Label, value: Notification_A_Z_Value };
const NotificationSortZA = { label: Z_A_Label, value: Notification_Z_A_Value };
const IncompleteFirst = { label: Z_A_Label, value: Z_A_Value }; // TODO
const GroupedSort = { label: Rooms_Label, value: Rooms_Value }; // TODO
const UngroupedSort = { label: Rooms_Label, value: Rooms_Value }; // TODO
const sharedWithMe = { label: With_Me_Label, value: With_Me_Value };
const sharedWithOrganization = { label: With_Organisation_Label, value: With_Organisation_Value };
export const sharedByMe = { label: By_Me_Label, value: By_Me_Value };

const COMMON_FILTERS = [filterByOption, roomFilter, lifestyleFilter, vignetteFilter, siloFilter];

const nFilterRender = { label: Renders_Label, value: NOTIFICATION_MODEL_TYPE.RenderStatus };
const nFilterPreview = { label: Previews_Label, value: NOTIFICATION_MODEL_TYPE.Preview };
const nFilterRequest = {
  label: Requests_Label,
  value: NOTIFICATION_MODEL_TYPE.RequestStatusAndComments,
};
const nFilterSharedFiles = {
  label: Shared_Files_Label,
  value: NOTIFICATION_MODEL_TYPE.SharedFiles,
};
const nFilterLiveFurnish = {
  label: Notification_From_LF,
  value: NOTIFICATION_MODEL_TYPE.FromLiveFurnish,
};

export const COMMON_SORT_OPTIONS = [SortRecentFirst, SortOldestFirst, SortAZ, SortZA];
export const COMMON_SHARED_OPTIONS = [sharedWithMe, sharedWithOrganization, sharedByMe];

export const NOTIFICATION_SORT_OPTIONS = [
  NotificationSortRecentFirst,
  NotificationSortOldestFirst,
  NotificationSortAZ,
  NotificationSortZA,
];

export const NOTIFICATION_FILTER_OPTIONS = [
  noneOption,
  nFilterRender,
  nFilterPreview,
  nFilterRequest,
  nFilterSharedFiles,
  nFilterLiveFurnish,
];

const supportAll = { value: '', label: 'Filter By' };
const supportOpen = { value: 'open', label: 'Open' };
const supportClose = { value: 'close', label: 'Close' };
export const SUPPORT_FILTER_OPTIONS = [noneOption, supportOpen, supportClose];

// delete the card
// need to refactor api endpoints to be passed dynamically here
const handleCardDelAction = (e, id, endpoint) => {
  e.preventDefault();
  // if (cardType === "rooms") {
  //   // roomDeleteApi(id);
  // } else if (cardType === "projects" || cardType === "props") {
  //   // projectDeleteApi(id);
  // }
};

const onFullScreenClick = () => {};

const SHARED_FILES_FILTER = [
  noneOption,
  { label: Renders_Label,  value: MODEL_TYPES.PROJECTRENDER },
  { label: Previews_Label,  value: MODEL_TYPES.PREVIEWS },
  { label: My_Projects_Label,  value: MODEL_TYPES.PROJECTS },
  { label: Models_3d_Label,  value: MODEL_TYPES.THREEDMODELS },
  { label: Rooms_Label,  value: MODEL_TYPES.ROOM },
  { label: Textures_Label,  value: MODEL_TYPES.TEXTURE },
  { label: Uploads_Label,  value: MODEL_TYPES.UPLOADS },
  { label: Collections_Label,  value: MODEL_TYPES.COLLECTION },
  { label: Templates_Label,  value: MODEL_TYPES.TEMPLATES },
  { label: Props_Label,  value: MODEL_TYPES.PRODUCT },
];

const COLLECTIONS_FILTER = [
  noneOption,
  // fields below are valid only for collection as collection has different model types
  rendersFilter,
  previewsFilter,
  myProjectsFilter,
  models3DFilter,
  roomsFilter,
  texturesFilter,
  uploadsFilter,
  templatesFilter,
  propsFilter,
];

const fromLiveFurnishNFilter = { label: Rooms_Label, value: Rooms_Value }; // TODO
const trashProjectFilter = { label: My_Projects_Label, value: TRASH_MODEL_TYPE.PROJECT };
const trashPreviewFilter = { label: Previews_Label, value: TRASH_MODEL_TYPE.PROJECT_PREVIEW };
const trashTextureFilter = { label: Textures_Label, value: TRASH_MODEL_TYPE.TEXTURE };
const trashUploadsFilter = { label: Uploads_Label, value: TRASH_MODEL_TYPE.UPLOADS };
const trashCollectionFilter = { label: Collections_Label, value: TRASH_MODEL_TYPE.COLLECTION };

export const useScreenSettings = (cardType) => {
  const { canAccessLiveOnApp } = useUserContext();
  const isTexture = cardType === 'texture';
  const isProject = cardType === 'projects';
  const target = isTexture || isProject ? '_self' : '_blank';
  let { roomTypeOptions } = useRoomTypeOptions();
  roomTypeOptions = [noneOption, ...roomTypeOptions];

  /// need to implement these targets where necessary
  const pageSettings = {
    projects: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: roomTypeOptions,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    rooms: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: roomTypeOptions,
      showFavoriteIcon: true,
      showTeamMemberFilter: false,
    },
    // rooms: {
    //   sortOptions: COMMON_SORT_OPTIONS,
    //   filterOptions: roomTypeOptions,
    //   showFavoriteIcon: true,
    //   showTeamMemberFilter: false,
    // },
    templates: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      // ],
      // topButtonList: [
      //   {
      //     element: AddToCollections,
      //     bigButton: true,
      //     title: 'Edit',
      //     iconSrc: DOWNLOAD_ICON,
      //   },
      //   {
      //     element: SharePopup,
      //     bigButton: true,
      //   },
      // ],
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      subSortOptions: [createdFromAppFilter],
      filterOptions: canAccessLiveOnApp() ? [...roomTypeOptions, createdFromApp] : roomTypeOptions, // NEED TO ADD Created From App
      showFavoriteIcon: true,
      showTeamMemberFilter: false,
    },
    props: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: [],
      showFavoriteIcon: true,
      showTeamMemberFilter: false,
    },
    products: {
      // for 3dmodels on my products page
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: [], // hide this
      clickUrl: `/products/3dmodel/product`,
      showFavoriteIcon: true,
      showTeamMemberFilter: false,
    },
    model: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   {
      //     element: MiniButton,
      //     title: 'Edit',
      //     iconSrc: EDIT_ICON,
      //   },
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      // ],
      // topButtonList: [{ button: downloadButton(id, cardType, false) }],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: [], // hide this
      clickUrl: `/products/3dmodel/product`,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    textures: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: [],
      clickUrl: `/products/textures`,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    productroom: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   // TODO: ADD REnder Button here
      //   {
      //     element: MiniButton,
      //     title: 'Edit',
      //     iconSrc: EDIT_ICON,
      //   },
      //   { element: SharePopup, miniButton: true, iconSrc: SHARE_ICON },
      //   {
      //     element: AddToCollections,
      //     miniButton: true,
      //     iconSrc: COLLECTION_ICON,
      //   },
      //   { element: DeactivatePopup, miniButton: true, item: cardType, onSuccess: refreshList },
      // ],
      // topButtonList: [{ button: <Download /> }],
      sortOptions: COMMON_SORT_OPTIONS,
      filterOptions: roomTypeOptions,
      clickUrl: `/products/rooms/preview`,
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    sharedFiles: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: SHARED_FILES_FILTER,
      clickUrl: `/products/rooms/preview`,
      showFavoriteIcon: true,
      showTeamMemberFilter: false,
      showSharedFilter: true,
    },
    [`renders-previews`]: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      subSortOptions: [GroupedSort, UngroupedSort],
      filterOptions: [noneOption, rendersFilter, previewsFilter, uploadsFilter, archivedFilter],
      filterSubOptions: [archivedFilter],
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    uploads: {
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: SHARED_FILES_FILTER,
      clickUrl: '',
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    notifications: {
      // move these to the actual screen file now instead
      // cardButtonList: [],
      // topButtonList: [],
      sortOptions: NOTIFICATION_SORT_OPTIONS,
      defaultSortOption: NotificationSortRecentFirst,
      filterOptions: NOTIFICATION_FILTER_OPTIONS,
      clickUrl: ``, // to do
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    requests: {
      // move these to the actual screen file now instead
      // cardButtonList: [],
      // topButtonList: [],
      sortOptions: _.concat(COMMON_SORT_OPTIONS, [IncompleteFirst]),
      filterOptions: [
        noneOption,
        models3DFilter,
        roomFilter,
        materialFilter,
        servicesFilter,
        newFeatureFilter,
      ],
      clickUrl: ``, // to do
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
    },
    collections: {
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
      defaultSortOption: SortRecentFirst,
    },
    collection: {
      showFavoriteIcon: true,
      showTeamMemberFilter: true,
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: COLLECTIONS_FILTER,
    },
    teams: {
      showTeamMemberFilter: false,
      showFavoriteIcon: false,
    },
    trash: {
      // move these to the actual screen file now instead
      // cardButtonList: [
      //   {
      //     element: Download,
      //     miniButton: true,
      //     title: 'Download',
      //     iconSrc: DOWNLOAD_ICON,
      //   },
      //   { element: DeactivatePopup, miniButton: true, item: cardType, onSuccess: refreshList },
      //   // {
      //   //   button: (
      //   //     <MiniButton
      //   //       miniButton={true}
      //   //       cardType={cardType}
      //   //       title="Remove forever"
      //   //       iconSrc={DELETE_ICON}
      //   //       buttonLabel="Remove forever"
      //   //     />
      //   //   ),
      //   // },
      // ],
      // topButtonList: [
      //   {
      //     element: DeactivatePopup,
      //     bigButton: true,
      //     iconSrc: DELETE_ICON,
      //     title: 'Empty Trash',
      //     item: cardType,
      //   },
      // ],
      sortOptions: COMMON_SORT_OPTIONS,
      defaultSortOption: SortRecentFirst,
      filterOptions: [
        noneOption,
        trashProjectFilter,
        trashPreviewFilter,
        trashTextureFilter,
        trashUploadsFilter,
        trashCollectionFilter,
      ],
      clickUrl: ``, // to do
      showTeamMemberFilter: true,
    },
    support: {
      filterOptions: SUPPORT_FILTER_OPTIONS,
      defaultFilterOption: supportAll,
      showTeamMemberFilter: true,
    },
  };
  return pageSettings[cardType];
};
