import { useMemo } from 'react';
import DatePicker from 'react-datepicker';
import styles from './CalendarDropdownContent.module.scss';

export const CalendarDropdownContent = ({ startDate, endDate, onChange }) => {
  const handleChange = (name) => (value) => {
    onChange({ name, value });
  };

  const startDateMax = Date.now() > endDate ? Date.now() : endDate;

  return (
    <div className={styles.root}>
      <DatePicker
        selected={startDate}
        onChange={handleChange('startDate')}
        inline
        calendarClassName="lf-react-calendar"
        maxDate={startDateMax}
      />

      <DatePicker
        selected={endDate}
        onChange={handleChange('endDate')}
        inline
        minDate={startDate}
        maxDate={Date.now()}
      />
    </div>
  );
};
