import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getFiltersApi } from '../api/commonApi';
import { CloseIcon } from '../components/icons/CloseIcon';
import { TEXTURE_FILTER_TYPE } from '../constants/constants';
import { useFilterDropdownOptions } from '../hooks/useFilterDropdownOptions';
import {
  selectTextureDetailsModalData,
  textureDetailsModalActions,
} from '../redux/slicers/textureDetailsModalSlicer';

export const TextureDetailsModal = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectTextureDetailsModalData);
  const open = Boolean(data);

  const onClose = () => {
    dispatch(textureDetailsModalActions.close());
  };

  useEffect(() => {
    return () => dispatch(textureDetailsModalActions.close());
  }, []);

  if (!open) return null;

  return <TextureDetailsModalUI onClose={onClose} data={data} open={open} />;
};

export const TextureDetailsModalUI = ({ open, onClose, data }) => {
  const dispatch = useDispatch();

  // fetch filter options
  useEffect(() => {
    dispatch(getFiltersApi());
  }, [dispatch]);

  const textureFilters = useFilterDropdownOptions({
    filterType: TEXTURE_FILTER_TYPE,
  });

  let fiterOptionIdList = [];
  if (Array.isArray(data.filteroptions)) {
    // filteroptions might be list of ids or list of filter options
    fiterOptionIdList =
      typeof data.filteroptions[0] === 'object'
        ? data.filteroptions.map((option) => option.id)
        : data.filteroptions;
  }

  return (
    <Offcanvas
      className="texture-details"
      show={open}
      onHide={onClose}
      placement={'end'}
      name={'end'}
      backdrop={false}>
      <Offcanvas.Header>
        <Offcanvas.Title className="fullWidth offcanvas-heading" as="div">
          <h4 className="offcanvas-title fw-bold">{data.name}</h4>
          <p className="text-muted m-0 offcanvas-title" style={{ fontSize: 14 }}>
            Sku ID - {data.sku_id}
          </p>
        </Offcanvas.Title>
        <Button className="modalCloseButton" onClick={onClose}>
          <CloseIcon />
        </Button>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Col>
          <Row>
            <div className="image-part">
              <img width={388} height={330} src={data.thumbnail} alt="texture_image" />
            </div>
          </Row>
          <Row>
            <h6>Texture Detail</h6>
            <p className="d-flex justify-content-between m-0">
              <span>
                Width: {data.width} inches &times; Height: {data.height} inches
              </span>
              <span>Rotation: {parseFloat(data.rotation)}</span>
            </p>
          </Row>
          <hr />
          <Row>
            <h6>Description</h6>
            <p className="m-0">{data.description}</p>
          </Row>
          <hr />

          {!isEmpty(fiterOptionIdList) &&
            textureFilters.map((filter) => {
              const options = filter.options.filter((o) => fiterOptionIdList.includes(o.value));
              if (!options.length) return null;
              return (
                <Row key={filter.id}>
                  <h6>{filter.label_name}</h6>
                  <p className="label-part">
                    {options.map((option) => (
                      <span key={option.value} className="label label-category">
                        {option.label}
                      </span>
                    ))}
                  </p>
                </Row>
              );
            })}
        </Col>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
