import { createSlice } from '@reduxjs/toolkit';

const search = createSlice({
  name: 'search',
  initialState: {
    searchVal: '',
    searchResult: {},
    searchUserResult: null,
    searchOrgResult: null,
    allMyOrg: null,
    projectSearchValue: null,
  },
  reducers: {
    setSearchValue: (state, { payload }) => {
      state.searchVal = payload;
    },
    setSearchResult: (state, { payload }) => {
      state.searchResult = payload;
    },
    setMoreSearchResult: (state, { payload }) => {
      state.searchResult = {
        ...state?.searchResult,
        ...payload,
        results: [...state?.searchResult?.results, ...payload?.results],
      };
    },
    setSearchUserResult: (state, { payload }) => {
      state.searchUserResult = payload;
    },
    setSearchOrgResult: (state, { payload }) => {
      state.searchOrgResult = payload;
    },
    setAllOrgResult: (state, { payload }) => {
      state.allMyOrg = payload;
    },
    setprojectSearchValue: (state, { payload }) => {
      state.projectSearchValue = payload;
    },
  },
});

export const {
  setSearchValue,
  setSearchResult,
  setSearchUserResult,
  setSearchOrgResult,
  setAllOrgResult,
  setprojectSearchValue,
  setMoreSearchResult,
} = search.actions;
export const searchSelector = (state) => state.search;
export const searchReducer = search.reducer;
export default searchReducer;


export const selectSearchValue = (state) => state.search.searchVal;
export const selectSearchActive = (state) => state.search.searchVal.trim() !== '';
