import cx from 'classnames';
import React, { Fragment } from 'react';
import { Image, Offcanvas } from 'react-bootstrap';
import { SearchInput } from '../common';
import { CLOSE_ICON } from '../config/constant/assetConstant';
import UserIcon from './icons/UserIcon';
import InfiniteScroll from './InfiniteScroll/InfiniteScroll';

export const MemberOverlay = ({
  show,
  sections,
  selected,
  onSelectAll,
  onSelect,
  onSearchChange,
  searchValue,
  onClose,
}) => {


  const totalSelected = selected.length;
  // const itemsCount = sections.reduce((sum, section) => sum + section.items.length, 0);
  const itemsCount = sections.find(s => s.name === 'INSIDE_MEMBERS').items.length
  const allSelected = itemsCount === totalSelected && totalSelected !== 0;
  const selectedIds = selected.map(m => m.id);

  return (
    <Offcanvas
      className={`files-shared-with-users-container sidebar ${sections[0]?.hasMore ? '--has-more-items' : '' }`}
      show={show}
      onHide={onClose}
      placement="end"
      backdropClassName="nonVisibleBackdrop">
      <button className="closeFSUsers" onClick={onClose}>
        <Image src={CLOSE_ICON} />
      </button>

      <Offcanvas.Body>
        <div className="fs-container search-members">
          <SearchInput value={searchValue} placeholder="Search" onChange={onSearchChange} />
        </div>

        <div className="fs-container inside members mb-0 pb-0">
          <div className="all-user-select">
            <div className="filter select_all checkbox-selection">
              <input
                type="checkbox"
                id="membersOverlaySelectAll"
                className="selectCard"
                checked={allSelected}
                onChange={onSelectAll}
              />
              <label htmlFor="membersOverlaySelectAll"></label>
            </div>
            <label htmlFor="membersOverlaySelectAll">Select all</label>
            <label className="selectionCount">{totalSelected}</label>
          </div>
        </div>

        {sections.map((section, idx) => {
          const { title, items, noItemsText, loading, hasMore, fetchMoreData } = section;
          return (
            <Fragment key={idx}>
            {idx > 0 && <div style={{ border: '1px dashed #929292' }} className="mt-2"/>}

            <div className={`fs-container  members ${idx > 0 ? 'outside' : 'inside'}`}>
              {title && <div className="fs-container__members-container mb-2"><h5 className="head-h5">{title}</h5></div>}

              {items.length === 0 && !loading && noItemsText}
              <InfiniteScroll loading={loading} hasMore={hasMore} next={fetchMoreData}>
                {items.length > 0 && (
                  <ul className="user-list">
                    {items.map((item) => {
                      const itemSelected = selectedIds.includes(item.id);
                      const { name, avatar } = item;

                      return (
                        <li
                          className={cx(
                            {
                              selected: itemSelected,
                            },
                            'teamMemberFilter'
                          )}
                          key={item.id}
                          onClick={() => onSelect(item)}>
                          {avatar ? (
                            <Image src={avatar} />
                          ) : (
                            <div
                              style={{
                                height: 32,
                                width: 32,
                                paddingLeft: 4,
                                paddingTop: 4,
                                display: 'flex',
                              }}>
                              <UserIcon />
                            </div>
                          )}
                          <div className="user-info">
                            <h5 className="user-name userName">{name}</h5>
                            {item.description && <p className="user-org">{item.description}</p>}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </InfiniteScroll>
            </div>

          </Fragment>
          );
        })}
      </Offcanvas.Body>
    </Offcanvas>
  );
};
