import { getRoomsPageListApi } from '../api/myprojects';
import { useThumbnailCardDataList } from './useDataList';
import {
  paginatedRoomsActions,
  paginatedRoomsSelector,
} from '../redux/slicers/admin/paginatedRooms';
import { MODEL_TYPES } from '../constants/constants';

export const useRooms = ({
  requestParams,
  filterByKey,
  defaultBrands,
  defaultSort = '-created_at',
  defaultFilterBy,
  defaultCreatedBy,
  useGlobalSearch,
  skipBrandFilter,
  modelType = MODEL_TYPES.ROOM,
  api,
} = {}) => {
  const data = useThumbnailCardDataList({
    selector: paginatedRoomsSelector,
    resetAction: paginatedRoomsActions.resetList,
    getAction: api || getRoomsPageListApi,
    itemDeleteText: 'Are you sure you want to delete this temlate?',
    itemsDeleteText: (selectedIds) =>
      `Are you sure you want to delete ${selectedIds.length} templates?`,
    requestParams,
    filterByKey,
    defaultBrands,
    defaultSort,
    defaultFilterBy,
    useGlobalFilter: true,
    hookName: 'useRooms',
    defaultCreatedBy,
    useGlobalSearch,
    skipBrandFilter,
    modelType,
  });

  return data;
};
