import { lazy, useEffect, useState } from 'react';
import cx from 'classnames';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { Link, matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { UserCard, UniversalSearchForm } from '../common';
import Logo from '../assets/images/lf-logo.svg';
import { MAIN_MENU } from '../constants/menu';
import { NOTIFICATIONS_ICON, NOTIFICATIONS_ON_ICON } from '../constants/assetConstants';
import { ADMIN_DASHBOARD, TEMPLATES_ROUTE } from '../constants/routePathConstants';
import { icons } from '../assets/';
const { NotificationsIcon, NotificationsActiveIcon } = icons;

const NotificationsPanel = lazy(() => import('../common/notifications/NotificationsPanel'));

export default function CommonMenu() {
  const location = useLocation();
  const [notification, setNotification] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [cardFlag, setUserCardVisiblity] = useState(false);
  const handleHidePopover = () => {
    setNotification(false);
  };

  const handleHideMenu = () => {
    if (isMenuVisible) {
      document.getElementById('hamburgerMenu').click();
    }
  };

  useEffect(() => {
    if (notification) {
      window.addEventListener('click', handleHidePopover);
    } else {
      window.removeEventListener('click', handleHidePopover);
    }

    return () => {
      window.removeEventListener('click', handleHidePopover);
    };
  }, [notification]);

  useEffect(() => {
    if (!isMenuVisible) return;

    window.addEventListener('click', handleHideMenu);
    return () => {
      window.removeEventListener('click', handleHideMenu);
    };
  }, [isMenuVisible]);

  return (
    <>
      <Navbar expand="xl" className="page-nav common-nav top-nav" collapseOnSelect id="commonMenu">
        <Navbar.Brand>
          <Link to={ADMIN_DASHBOARD}>
            <Image src={Logo} style={{ width: 115, height: 32 }} responsive="true" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle
          area-controls="topNavBarToggle"
          id="hamburgerMenu"
          onClick={(e) => {
            e.stopPropagation();
            setIsMenuVisible(!isMenuVisible);
            notification && setNotification(false);
            cardFlag && setUserCardVisiblity(false);
          }}
        />
        <Navbar.Collapse
          className="justify-content-between topMenuNav"
          id="topNavBarToggle"
        >
          <Nav as="ul" defaultActiveKey="/products" className="menu">
            {MAIN_MENU.map((item, index) => {
              const Icon = item?.icon;
              return (
                <Nav.Item
                  as="li"
                  key={item.path}
                  eventkey={`navLink${index}`}
                  className={cx({
                    active: (matchPath({ path: item.path }, location?.pathname) !== null) 
                            ||
                            (item.path.includes(TEMPLATES_ROUTE) && location?.pathname.includes(TEMPLATES_ROUTE))
                            ||
                            (item.path.includes("render") && location?.pathname.includes("render")),
                  })}>
                  <Link to={item.path} className="nav-link" onClick={handleHideMenu}>
                    <Icon />
                    <span>{item.name}</span>
                  </Link>
                </Nav.Item>
              );
            })}
          </Nav>
        </Navbar.Collapse>

        <div className="header-right-section d-flex flex-row justify-content-between align-items-center">
          {/* form */}
          <UniversalSearchForm />

          {/* notifications */}
          <button
            className={`notification ${notification ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              handleHideMenu();
              setUserCardVisiblity(false);
              setNotification(!notification);
            }}>
            {notification ? <NotificationsActiveIcon /> : <NotificationsIcon />}
          </button>

          {/* user card */}
          <UserCard
            cardFlag={cardFlag}
            setUserCardVisiblity={setUserCardVisiblity}
            handleClosePopUp={() => {
              handleHideMenu();
              setNotification(false);
            }}
          />
        </div>
      </Navbar>
      {notification && <NotificationsPanel />}
    </>
  );
}
