import Button from "../../components/Button/Button";

export const FormButtons = ({ onCancel, loading }) => {
  return (
    <div className="d-flex align-stretch justify-content-between gap-3">
      <Button
        type="button"
        // variant="outline"
        color="light"
        className="w-100"
        onClick={onCancel}
        dispabled={loading}>
        Cancel
      </Button>
      <Button type="submit" className="w-100" loading={loading} color="warning">
        Save
      </Button>
    </div>
  );
};
