import React, { useMemo, useRef } from 'react';
import cx from 'classnames';
import { Col, Row, Card } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';
import CancelIcon from '../assets/images/settings/cancel.svg';
import _, { capitalize } from 'lodash';
import { Image } from './';
import { Item, ScrollableTagList, TagUI } from './ScrollableTagList/ScrollableTagList';
import CardUI from '../assets/scss/screens/_customizeExperience.module.scss';
import { useDataList } from '../hooks/useDataList';
import { useProfilePreferencesList } from '../hooks/useProfilePreferencesList';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../common/ScrollableTagList/ScrollableTagList.module.scss';

export default function YourInterests({
  selectedIndustryList,
  industriesLoaded,
  onProductClick,
  selectedProducts,
  onDelete,
  title,
  error,
  description,
  errorMessage,
}) {
  const sliderRef = useRef(null);
  const requestParams = useMemo(() => {
    const hasCustomIndustry = selectedIndustryList.find((i) => i.user);

    if (hasCustomIndustry) return; // if custom industry is selected we want to see all products

    const ids = selectedIndustryList?.map((i) => i.id);
    return { id: ids };
  }, [selectedIndustryList]);

  console.log('requestParams', requestParams);
  const {
    list: industriesPreference,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,
  } = useProfilePreferencesList({
    requestParams,
    skip: !industriesLoaded || !selectedIndustryList.length,
  });

  const renderList = () => {
    return (
      <Col>
        <div className={CardUI.interestsWrapper}>
          {industriesPreference.map((data, idx) => (
            <Card
              key={data.id}
              className={CardUI.interestCard}
              onClick={() => onProductClick(data.id)}>
              <Image
                className={CardUI.interestImage}
                src={data.image_file}
                placeholderWhileLoading
                ratio={1.259}
                rounded={false}
              />
              <Card.Body
                className={cx(
                  CardUI.interestCardBody,
                  selectedProducts.includes(data.id) ? CardUI.selected : ''
                )}>
                <Card.Text className={CardUI.interestText} as="span">
                  {data.name}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
      </Col>
    );
  };
  const settings = {
    className: 'start',
    infinite: false,
    // slidesToShow: 10,
    swipeToSlide: true,
    // slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: true,
  };

  const renderButton = () => {
    const tagList = selectedProducts.map(id => {
      const item = industriesPreference.find(ip => ip.id === id)
      if (!item) return null;
      return {
        value: item?.id,
        label: item?.name,
      }
    }).filter(Boolean);

    const handleAfterSlide = () => {
      const elem = document.querySelector('.slick-slide:last-of-type')
      if (!elem) return;
      const lElRect = elem.getBoundingClientRect();
      const rOffset = lElRect.x + lElRect.width;
      const wraRect = document.querySelector('.slick-list').getBoundingClientRect();
      if (rOffset < wraRect.x + wraRect.width) {
        document.querySelector('.slick-slider').classList.add('slick-next-disabled')
      } else {
        document.querySelector('.slick-slider').classList.remove('slick-next-disabled')
      }
    };

    const handleSliderInit = () => {
      setTimeout(() => {
        handleAfterSlide()
      }, 50);
    }

    return (
      <div className={cx('mb-4', styles[`theme${capitalize('dark')}`])}>
        <Slider
          ref={sliderRef}
          className="your-intenerst-tag-slider"
          infinite={false}
          swipeToSlide={true}
          slidesToScroll={1}
          variableWidth={true}
          onInit={handleSliderInit}
          onReInit={handleSliderInit}
          // draggable={true}
          // slidesToShow={100}
          afterChange={handleAfterSlide}>
          {tagList.map((tag) => {
            return (
              <div>
                <TagUI
                  size="large"
                  value={tag.value}
                  label={tag.label}
                  key={tag.value}
                  onDelete={onDelete}
                  style={{ margin: 0 }}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  return (
    <div className="interests-cont" id="product-interests-container">
      <h2 className="mb-2">{title}</h2>
      {error ? (
        <p className="title-error-color mb-4">
          <BsExclamationTriangle style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }} />
          {errorMessage}
        </p>
      ) : (
        <p className="mb-4">{description}</p>
      )}
      {renderButton()}
      <InfiniteScroll loading={loading} dataLength={total} next={fetchMoreData} hasMore={hasMore}>
        <Row>{renderList()}</Row>
      </InfiniteScroll>
    </div>
  );
}
