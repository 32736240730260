import { createPaginatedSlice } from "../../../helpers/reduxHelper";

const myRenderPreview = createPaginatedSlice('myRenderPreview', {
  defaultState: {
    myRenderPreviewData: {},
  },
  reducers: {
    setMyRenderPreviewData: (state, { payload }) => {
      state.myRenderPreviewData = payload;
    },
  }
});

export const { setMyRenderPreviewData } = myRenderPreview.actions;
export const myRenderPreviewActions = myRenderPreview.actions
export const myRenderPreviewSelector = state => state.myRenderPreview;
export const myRenderPreviewReducer = myRenderPreview.reducer;
export default myRenderPreviewReducer

export const myRenderPreviewSelectors = myRenderPreview.LF.selectors