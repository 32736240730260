import './switch.scss';

function Switch(props) {
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        ref={props.selectRef}
        checked={props.checked}
        onChange={props.onToggle}
        id={props.toggleId}
        name={props.name}
        className="form-check-input"
        disabled={props.disabled}
      />
      <span className="switch" />
    </label>
  );
}

export default Switch;
