import { openSidebarSection, selectSettingsResolutionPreset } from '../../../../helpers/curatorActions';
import { dispatcher } from '../../../../helpers/projectHelper';
import { CURATOR_MENU_SECTION } from '../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import {
  openSettingsSection,
  setCuratorSettingData,
  setMetadataIsRender,
  setSelectedGraphicQuality,
  setSelectedMeasureUnit,
  SETTINGS_SECTION,
  updateCameraSettings,
  updateResolutionPreset,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { setGlobalHeight, setGlobalWidth } from '../../../../redux/slicers/camera/cameraSettings';

export class UndoRedoCuratorSettings {
  constructor({ undoRedoInstance }) {
    this.undoRedo = undoRedoInstance;

    this.init();
  }

  _openSettingsSection = (sectionName) => {
    // update ui state so undo changes are visible
    const sectionOpened = openSidebarSection(CURATOR_MENU_SECTION.SETTINGS);

    if (!sectionOpened) {
      // TODO: handle this case -> sometimes we might prevent opening other sections if for example camera is not valid
    }
    dispatcher(openSettingsSection(sectionName));
  };

  _updateCameraSettings = (settingsKey, value) => {
    this._openSettingsSection(SETTINGS_SECTION.CAMERA_SETTINGS);

    const floatValue = parseFloat(value);
    dispatcher(updateCameraSettings({ settingsKey, value: floatValue }));

    switch (settingsKey) {
      case 'verticalRotationSpeed':
        this.undoRedo.unityContext.handleVerticalRotationSpeed(floatValue);
        return;
      case 'horizontalRotationSpeed':
        this.undoRedo.unityContext.handleHorizontalRotationSpeed(floatValue);
        return;
      case 'zoomSpeed':
        this.undoRedo.unityContext.cameraModule.OnUpdateCameraZoomSpeed(floatValue);
        return;
      case 'panSpeed':
        this.undoRedo.unityContext.cameraModule.OnUpdateCameraMoveSpeed(floatValue);
        return;
      default:
        throw new Error('Unable to update camera settings');
    }
  };

  _updateResolutionPreset = (data) =>{
    this._openSettingsSection(SETTINGS_SECTION.RESOLUTION_PRESETS);
    dispatcher(updateResolutionPreset(data));
  }

  init = () => {
    // resolution presets
    this.updateResolutionPresetName = this.undoRedo.createAction(({ prevValue, nextValue, data }) => {
      return {
        prevValue, nextValue, data,
        undo: () => {
          this._updateResolutionPreset({
            id: data.id,
            name: prevValue,
          })
        },
        redo: () => {
          this._updateResolutionPreset({
              id: data.id,
              name: nextValue,
            })
        },
      };
    })

    // toggle lock
    this.toggleResolutionPresetLock = this.undoRedo.createAction(({ prevValue, nextValue, id }) => {
      return {
        prevValue, nextValue, id,
        undo: () => {
          this._updateResolutionPreset({ id, lock: prevValue, })
        },
        redo: () => {
          this._updateResolutionPreset({ id, lock: nextValue, })
        },
      };
    })

    this.selectResolutionPreset = this.undoRedo.createAction(({ selectedItem, nextSelectedItem }) => {
      return {
        selectedItem, nextSelectedItem,
        undo: () => {
          this._openSettingsSection(SETTINGS_SECTION.RESOLUTION_PRESETS);
          selectSettingsResolutionPreset(selectedItem)
        },
        redo: () => {
          this._openSettingsSection(SETTINGS_SECTION.RESOLUTION_PRESETS);
          selectSettingsResolutionPreset(nextSelectedItem)
        },
      };
    })

    this.updateResolution = this.undoRedo.createAction(({ id, prevValue, nextValue }) => {
      return {
        id, prevValue, nextValue,
        undo: () => {
          this._updateResolutionPreset({
            id,
            ...prevValue,
          })
          dispatcher(setGlobalWidth(prevValue.width));
          dispatcher(setGlobalHeight(prevValue.height));
        },
        redo: () => {
          this._updateResolutionPreset({
            id,
            ...nextValue,
          })
          dispatcher(setGlobalWidth(nextValue.width));
          dispatcher(setGlobalHeight(nextValue.height));
        },
      };
    })
    
    // camera settings
    this.updateCameraSettings = this.undoRedo.createAction(({ name, prevValue, nextValue }) => {
      return {
        name,
        prevValue,
        nextValue,
        undo: () => {
          this._updateCameraSettings(name, prevValue);
        },
        redo: () => {
          this._updateCameraSettings(name, nextValue);
        },
      };
    });

    this.selectGraphicQuality = this.undoRedo.createAction(({ prevId, nextId }) => {
      return {
        prevId,
        nextId,
        undo: () => {
          this._openSettingsSection(SETTINGS_SECTION.GRAPHIC_QUALITY);
          dispatcher(setSelectedGraphicQuality({ id: prevId }));
        },
        redo: () => {
          this._openSettingsSection(SETTINGS_SECTION.GRAPHIC_QUALITY);
          dispatcher(setSelectedGraphicQuality({ id: nextId }));
        },
      };
    });

    this.selectMeasureUnit = this.undoRedo.createAction(({ prevId, nextId }) => {
      return {
        prevId,
        nextId,
        undo: () => {
          this._openSettingsSection(SETTINGS_SECTION.DEFAULT_MEASURE_UNITS);
          dispatcher(setSelectedMeasureUnit({ id: prevId }));
        },
        redo: () => {
          this._openSettingsSection(SETTINGS_SECTION.DEFAULT_MEASURE_UNITS);
          dispatcher(setSelectedMeasureUnit({ id: nextId }));
        },
      };
    });


    // metadata
    this.toggleMetadata = this.undoRedo.createAction(({ prevValue, nextValue, data }) => {
      return {
        prevValue, nextValue, data,
        undo: () => {
          this._openSettingsSection(SETTINGS_SECTION.METADATA_SETTINGS);
          dispatcher(setMetadataIsRender({ id: data.id, isRender: prevValue }))
        },
        redo: () => {
          this._openSettingsSection(SETTINGS_SECTION.METADATA_SETTINGS);
          dispatcher(setMetadataIsRender({ id: data.id, isRender: nextValue }))
        },
      };
    })

    // settings 
    this.setMetadataSettings = this.undoRedo.createSimpleAction({
      changeStateAction: (value) => {
        this._openSettingsSection(SETTINGS_SECTION.METADATA_SETTINGS);
        dispatcher(setCuratorSettingData(value))
      },
    })
  };
}
