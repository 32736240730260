import { useState } from "react";
import { EditIcon } from "../../components/icons/EditIcon";
import { EditForm } from "./EditForm";
import { FormButtons } from "./FormButtons";
import styles from "./EditSection.module.scss";

export const EditSection = ({ title, label, onSubmit, schema, fields, previewMode }) => {
  const [editMode, setEditMode] = useState(false);

  const handleSubmit = async (values) => {
    await onSubmit(values, () => setEditMode(false))
  }

  return (
    <section className={styles.root}>
      <div className={styles.title}>
        {title}

        {!editMode && (
          <button
            className={styles.editButton}
            onClick={() => setEditMode(true)}
          >
            <EditIcon />
          </button>
        )}
      </div>

      <div>
        {(editMode || previewMode === 'fields') && (
          <EditForm
            onCancel={() => setEditMode(false)}
            onSubmit={handleSubmit}
            schema={schema}
            fields={fields}
            disabled={!editMode}
          />
        )}

        {!editMode && previewMode !== 'fields' && label}
        
      </div>
    </section>
  );
};

EditSection.FormButtons = FormButtons;