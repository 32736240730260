import { createSlice } from '@reduxjs/toolkit';

const textureDetailsModal = createSlice({
  name: 'textureDetailsModal',
  initialState: {
    data: null,
  },
  reducers: {
    show: (state, { payload }) => {
      state.data = payload
    },
    close: (state) => {
      state.data = null;
    },
  },
});

export const textureDetailsModalActions = textureDetailsModal.actions;
export const textureDetailsModalReducer = textureDetailsModal.reducer;
export default textureDetailsModalReducer;

export const selectTextureDetailsModalData = (state) => state.textureDetailsModal.data