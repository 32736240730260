import { Outlet, useNavigate } from "react-router-dom";
import { usePermissions } from "../../hooks/usePermissions";

export const PermissionsGuard = ({ permissions, redirectUrl }) => {
  const navigate = useNavigate();
  const { isAllowed } = usePermissions({ 
    permissions,
    onDenied: () => {
      redirectUrl && navigate(redirectUrl);
    }
  })

  if (!isAllowed) {
    return null;
  }

  return <Outlet />
}