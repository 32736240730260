import { useDispatch } from 'react-redux';
import { apiEditProjectV2 } from '../../helpers/api';
import {
  getCategoryFields,
  getNameFields,
  getProjectTypeFileds,
  nameSchema,
} from './fieldsConfig';
import { useFilterDropdownOptions } from '../../hooks/useFilterDropdownOptions';
import { PROJECT_FILTER_TYPE } from '../../constants/constants';
import { EditSection } from '../EditSection/EditSection';
import { useEffect } from 'react';
import { getFiltersApi } from '../../api/commonApi';
import { EditPopupUI } from '../EditPopupUI/EditPopupUI';
import { getProjectTypeLabel } from '../../helpers/dataHelper';
import { useRoomTypeOptions } from '../../hooks/useRoomTypes';
import ReactTimeAgo from 'react-time-ago';

export const EditProjectPopup = ({ data, show, onClose, loading, onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const { roomTypeOptions } = useRoomTypeOptions();
  const { name, project_type, updated_at } = data || {};

  useEffect(() => {
    dispatch(getFiltersApi());
  }, [dispatch]);

  const productFilters = useFilterDropdownOptions({
    filterType: PROJECT_FILTER_TYPE,
  });

  const getFilterValue = (filterId) => {
    if (!data?.filteroptions || !productFilters) return [];

    const ids = data.filteroptions.map((o) => o.id);
    const filter = productFilters.find((filter) => filter.id === filterId);
    const value = filter.options.filter((category) => ids.includes(category.value));
    return value;
  };

  const handleSubmit = async (values, close) => {
    const updatedData = {};

    Object.keys(values).forEach((key) => {
      if (key.startsWith('filter_')) {
        const [_, id] = key.split('_');
        const filterValue = getFilterValue(parseInt(id));
        let filterOptions = data.filteroptions
          .map((opt) => opt.id)
          .filter((id) => !filterValue.find((c) => c.value === id)); // filter previous categories -> so other filter options are still present

        filterOptions.push(...values[key].map((c) => c.value));
        updatedData.filteroptions = filterOptions;
      } else {
        updatedData[key] = values[key];
      }
    });

    const response = await apiEditProjectV2({
      id: data.id,
      payload: updatedData,
    });

    if (response) {
      onUpdateSuccess?.(response);
    }

    close();
  };

  const projectTypeLabel = getProjectTypeLabel(project_type);
  const subtitle = updated_at && <ReactTimeAgo date={Date.parse(updated_at)} />

  return (
    <EditPopupUI
      show={show}
      onClose={onClose}
      loading={loading}
      title={data?.name}
      subtitle={subtitle}
      thumbnail={data?.thumbnail}>
      {!loading && data && (
        <>
          {/* name */}
          <EditSection
            title="Product name"
            onSubmit={handleSubmit}
            schema={nameSchema}
            fields={getNameFields(data)}
            label={name || '-'}
          />

          {/* project type */}
          <EditSection
            title="Type of project"
            onSubmit={handleSubmit}
            schema={null}
            fields={getProjectTypeFileds(data, roomTypeOptions)}
            label={projectTypeLabel || '-'}
          />

          {/* filters */}
          {productFilters.map((filter) => {
            const { fieldsConfig, schema } = getCategoryFields(filter, getFilterValue(filter.id));
            return (
              <EditSection
                key={filter.id}
                title={filter.label_name}
                onSubmit={handleSubmit}
                schema={schema}
                fields={fieldsConfig}
                previewMode="fields"
              />
            );
          })}
        </>
      )}
    </EditPopupUI>
  );
};
