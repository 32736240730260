import React, { memo, useEffect, useMemo, useState } from 'react';
import {
  Download,
  FooterButton,
  Preview,
  SharePopup,
  DeactivatePopup,
} from '../common';
import { RENDERS_PREVIEWS_ROUTE } from '../constants/routePathConstants';
import { getCuratorRenderPreviewPath } from '../helpers/urlHelper';
import { useParams, useNavigate } from 'react-router-dom';
import {
  apiGetPreviewAndMainRender,
  apiToggleFavorite,
  apiArchiveRenders,
  apiGetRenderBasedOnProjectRenderId,
  apiDownloadOldRenderProjectZipFile,
  downloadFile,
} from '../helpers/api';
import _ from 'lodash';
import {
  Form,
} from 'react-bootstrap';
import {
  DOWNLOAD_ICON,
} from '../constants/assetConstants';
import { icons } from '../assets';
import { MODEL_TYPES, MODULE_TYPES } from '../constants/constants';
import constants from '../constants/constants';
const {
  CollectionIcon,
  EditIcon,
  FullScreenIcon,
  HeartLineIcon,
  HeadsetIcon,
  ShareIcon,
  HeartIcon,
  BinIcon,
} = icons;
import pluralize from 'pluralize';
import { errorToast, successToast, warningToast } from '../helpers/toastHelper';
import { RenderPageDialog } from '../common';
import { getLocalStorage, setTitle, windowOpen } from '../helpers/jsHelper';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from '../../node_modules/jszip-utils/dist/jszip-utils.js';
import { FinalRenderIcon } from '../components/icons/FinalRenderIcon';
import { useOpenProjectFromFile } from '../components/admin/curator/CuratorHeader/useOpenProjectFromFile';
import { useUserContext } from '../contexts/UserContext';
import { useDispatch } from 'react-redux';
import { addToCollection } from '../redux/slicers/addToCollectionPopupSlicer';
import AddToCollectionPopup from '../common/AddToCollectionPopup';
const { RENDER_STATUS } = constants;


const PASS = {
  STANDARD: '1',
  FILMIC: '2',
  COLOR: '3',
}

const RenderDetails = memo(({ 
  id,
  onClose,
  theme,
  sidebarProps,
  previewProps,
  hideAutoEraseWarning,
  onDataFetched,
  onDeleteSuccess,
  onDataUpdated,
  onRenderSuccess,
}) => {
  const navigate = useNavigate();
  let cardType = 'render-details';
  const params = useParams();
  id = id || params.id
  const dispatch = useDispatch();
  const userCtx = useUserContext();
  const currentUser = userCtx?.currentUser?.user;
  const [data, setData] = useState(null);
  const [cameraList, setCameraList] = useState([]);
  const [sidebarCards, setSidebarCards] = useState([]);
  const [slides, setSlides] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeCameraId, setActiveCameraId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [pass, setPass] = useState('1');
  const [showDownload, setShowDownload] = useState(false);
  const [showRender, setShowRender] = useState(false);
  const [downloadXPos, setDownloadXPos] = useState(0);
  const [downloadYPos, setDownloadYPos] = useState(0);
  const [includePasses, setIncludePasses] = useState(false);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [favouriteLoading, setFavouriteLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [renderedData, setRenderedData] = useState(null);
  const [imageUrl, setImageUrl] =  useState([]);
  const { openProjectFromFile } = useOpenProjectFromFile();
  const [openCuratorInProgress, setOPenCuratorInProgress] = useState(false);

  const [memberDetails, setMemberDetails] = useState({});

  const isUpload = data?.is_upload;
  let passOptions = [];

  if (data?.images && data.images.length > 0 && !isUpload) {
    const image = data?.images?.[activeIndex];
    const isFilmic = image.is_filmic;
    if (image?.file) passOptions.push({ name: isFilmic ? 'Standard' : 'Standard *', value: PASS.STANDARD });
    if (image.filmic_file) passOptions.push({ name: isFilmic ? 'Filmic *' : 'Filmic', value: PASS.FILMIC });
    if (image.color_pass_file)
      passOptions.push({ name: 'Color', value: PASS.COLOR });
  }

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (data?.images) {
      setSidebarCards(
        _.map(data.images, (i) => ({
          ...i,
          name: i.angle_name,
        }))
      );
      setSlidesByPass();
    }
    // if (data?.room && (!cameraOptions || cameraOptions.length < 1)) {
    //   getCameras();
    // }
  }, [data]);

  useEffect(setSlidesByPass, [pass]);

  useEffect(() => {
    const isFilmic = data?.images?.[activeIndex].is_filmic;
    setPass(isFilmic ? PASS.FILMIC : PASS.STANDARD)
  }, [activeIndex])

  // useEffect(async () => {
  //   if (data.member) {
  //     const res = await apiGetMemberDetails(data.member);
  //     setMemberDetails(res);
  //   }
  // }, [data]);

  // function handleSelect(camera, value) {
  //   const newCameraOpts = [...cameraOptions];
  //   const match = _.find(newCameraOpts, (c) => c.id === camera.id);
  //   const filtered = _.filter(newCameraOpts, (c) => c.id !== camera.id);
  //   match.isSelected = value;
  //   setCameraOptions(_.orderBy([...filtered, match], ['cameraName']));
  // }

  // function handleSelectAll() {
  //   const newCameraOpts = [...cameraOptions];
  //   const allSelected = _.every(newCameraOpts, (c) => c.isSelected);
  //   newCameraOpts.forEach((c) => (c.isSelected = allSelected ? false : true));
  //   setCameraOptions(_.orderBy(newCameraOpts, ['cameraName']));
  // }

  function setSlidesByPass() {
    if (pass === PASS.STANDARD) {
      setSlides(_.map(data?.images, (i) => i.file));
    } else if (pass === PASS.FILMIC) {
      setSlides(_.map(data?.images, (i) => i.filmic_file));
    } else if (pass === PASS.COLOR) {
      setSlides(_.map(data?.images, (i) => i.color_pass_file));
    }
  }

  function handleDownloadClick(e) {
    setDownloadXPos(e.clientX);
    setDownloadYPos(e.clientY);
    setShowDownload(!showDownload);
  }

  useEffect(() => {
    if(!showDownload) {
      setImageUrl([]);
    }
  }, [showDownload])


  var zip = new JSZip();
  var zipName = data?.name;

  async function setAngleImageUrl (arr) {
    arr?.map((a, i) => {

      if(a?.file !== null){

        const u = a?.file.split('/');
        const f = u[u.length - 1].split("?");
        const filename = f[0];

        setImageUrl(imageUrl => [
          ...imageUrl,
          {
            id: a?.id,
            angle_name: a?.angle_name,
            imageType: 'file',
            filename: filename,
            url: a?.file
          }
        ])
      }
      if(a?.filmic_file !== null){

        const u = a?.filmic_file.split('/');
        const f = u[u.length - 1].split("?");
        const filename = f[0];

        setImageUrl(imageUrl => [
          ...imageUrl,
          {
            id: a?.id,
            angle_name: a?.angle_name,
            imageType: 'filmic',
            filename: filename,
            url: a?.file
          }
        ])
      }
      if(a?.color_pass_file !== null){

        const u = a?.color_pass_file.split('/');
        const f = u[u.length - 1].split("?");
        const filename = f[0];

        setImageUrl(imageUrl => [
          ...imageUrl,
          {
            id: a?.id,
            angle_name: a?.angle_name,
            imageType: 'color_pass',
            filename: filename,
            url: a?.color_pass_file
          }
        ])
      }

    });
  }

  const handleCurrentImageClick = async () => {

    setDownloadLoading(true);

    setAngleImageUrl(_.filter(data?.images, a => a.id === activeCameraId));

  }

  useEffect(() => {
    // console.log("imageUrl: ", imageUrl);

    if(imageUrl?.length !== 0){
      let count = 0;

      imageUrl.forEach(function (imgObj, i) {

        // if(Number(imgObj.id) === Number(activeCameraId)) {
          // console.log("imageurl url:", imgObj, i);
          // console.log("imageurl url:", imgObj.id, activeCameraId);
          
          let url = imgObj.url;
          let angle_name = imgObj.angle_name;
          let filename = imgObj.filename;
          let imageType = imgObj.imageType;

          JSZipUtils.getBinaryContent(url, function (err, data) {
            if (err) {
              throw err; // or handle the error
            }
            if(includePasses){
              if(imageType === "file"){
                zip.folder(angle_name).file(`${imageType}_${filename}`, data, { binary: true });
              }
              if(imageType === "filmic"){
                zip.folder(angle_name).file(`${imageType}_${filename}`, data, { binary: true });
              }
              if(imageType === "color_pass"){
                zip.folder(angle_name).file(`${imageType}_${filename}`, data, { binary: true });
              }
            }else{
              if(imageType === "file" && Number(pass) === 1){
                zip.folder(angle_name).file(`${imageType}_${filename}`, data, { binary: true });
              }
              if(imageType === "filmic" && Number(pass) === 2){
                zip.folder(angle_name).file(`${imageType}_${filename}`, data, { binary: true });
              }
              if(imageType === "color_pass" && Number(pass) === 3){
                zip.folder(angle_name).file(`${imageType}_${filename}`, data, { binary: true });
              }
            }
            count++;
            if (count === imageUrl.length) {
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, zipName);
                setImageUrl([]);
                setDownloadLoading(false);
                count = 0;
              });
            }
          });

          // setDownloadLoading(false);
          // setTimeout(() => {
          //   setShowDownload(false);
          // }, 1500)

        // }
      });

    }

  }, [imageUrl]);

  const handleAllCameraRendersDownloadClick = async () =>  {
    setDownloadLoading(true);

    await setAngleImageUrl(data?.images);

    // const url = `${API_ROOT_URL}${DOWNLOAD_RENDER_IMAGE}?ids=${data?.id}&is_color_pass=${includePasses}`

    // const token = getLocalStorage('token')
    // const headers = {
    //   'Authorization': `Bearer ${token}`,
    //   'Content-Type': 'application/zip'
    // }

    // axios({
    //   url: url,
    //   method: 'GET',
    //   responseType: 'blob',
    //   headers: headers
    // })
    // .then((response) => {
    //   console.log("response: ", response.data);
    //   const u = window.URL.createObjectURL(new Blob([response.data]));
    //   saveAs(u, `${data?.name}.zip`);
    //   setDownloadLoading(false);
    // })

  }

  async function refreshData() {
    if (loading) return
    setLoading(true);
    const res = await apiGetPreviewAndMainRender(id);
    // const res = await apiGetRenderBasedOnProjectRenderId(id);
    // console.log("id: " + id + " rres: "+ res)
    if (res) {
      setData(res);
      setActiveCameraId(res?.images[0].id);
      setPass(res?.images[0]?.is_filmic ? PASS.FILMIC : PASS.STANDARD);
      setMemberDetails(res?.member_details)
      setLoading(false);
      onDataFetched?.(res)
    }
    
    const resCam = await apiGetRenderBasedOnProjectRenderId(id);
    if(resCam.status){
      // console.log("resCam", resCam.data.result.CameraData);
      setTitle(resCam?.data?.result?.render_name);
      setRenderedData(resCam?.data?.result);
    }
  }

  // async function getCameras() {
  //   if (!loading && data) {
  //     setLoading(true);
  //     const res = await apiGetRenderBasedOnProjectRenderId(data.id);
  //     // setCameraOptions(_.orderBy(res.data.result.room_angle_data, ['cameraName']));
  //   }
  // }

  function handleFavorite() {
    setFavouriteLoading(true);
    const favorite = !data?.is_favourite;
    apiToggleFavorite(id, favorite, MODULE_TYPES.RENDER).then((res) => {
      if (res.status) {
        const updatedData = {
          ...data,
          is_favourite: favorite,
        }
        setData(updatedData);
        successToast(
          `This ${pluralize.singular(MODULE_TYPES.RENDER)} has been ${
            favorite ? 'favorited' : 'unfavorited'
          }.`
        );
        setFavouriteLoading(false);
        onDataUpdated?.(updatedData)
      }
    });
  }

  function toggleRenderArea(e) {
    // return <RenderPreviewDialog />;
    // setDownloadXPos(e.clientX);
    // setDownloadYPos(e.clientY);

    if(typeof(renderedData) === "string"){
      errorToast(renderedData);
    }else {
      setCameraList(renderedData?.CameraData);
      setShowRender(true);
    }

  }

  const handleEditClick = async () => {
    if (data.is_old_obj) {
      setOPenCuratorInProgress(true);
      const result = await apiDownloadOldRenderProjectZipFile({ renderId: data.id })
      if (!result?.old_zip_file) {
        errorToast('Unable to get render data: CODE: LF_2432423')
        return;
      }

      const file = await downloadFile({ fileUrl: result.old_zip_file })
      file.name = 'renderZip.zip';
      openProjectFromFile(file);
      setOPenCuratorInProgress(false);
    } else {
      const image = data.images.find(image => image.id === activeCameraId)
      const angleName = image.angle_name;
      const cameraData = renderedData.CameraData.find(camera => camera.cameraName === angleName);
      windowOpen(getCuratorRenderPreviewPath(data?.id, cameraData?.cameraName), "_blank");
    }

  }
  
  const renderNotReady = () => warningToast("Your Render is not ready yet. You can download it when it is ready.")

  const deactivateButton = {
    element: DeactivatePopup,
    footerButton: data?.status === RENDER_STATUS.STATUS_DONE,
    icon: BinIcon,
    isPlural: false,
    item: MODULE_TYPES.RENDER,
    title: data?.is_preview ? 'Delete' : 'Archive',
    deleteAction: apiArchiveRenders,
    actionWord: data?.is_preview ? 'delete' : 'archive',
    id: id,
    onSuccess: () => {
      if (onDeleteSuccess) {
        onDeleteSuccess()
      } else {
        navigate(RENDERS_PREVIEWS_ROUTE)
      }
    },
  };

  const showRenderOrDownload = data?.is_preview ? {
    element: FooterButton,
    footerButton: data?.status === RENDER_STATUS.STATUS_DONE,
    title: 'Render',
    icon: FinalRenderIcon,
    clickFunction: (e) => toggleRenderArea(e),
    seq: 1,
  } : {
    element: Download,
    footerButton: data?.status === RENDER_STATUS.STATUS_DONE,
    isDisabled: data?.status !== RENDER_STATUS.STATUS_DONE,
    isDisabledCallback: renderNotReady,
    title: 'Download',
    iconSrc: DOWNLOAD_ICON,
    loading: downloadLoading,
    clickFunction: (e) => handleDownloadClick(e),
    seq: 1,
  };

  const actions = [
    {
      ...showRenderOrDownload
    },
    !isUpload && {
      element: FooterButton,
      title: 'Edit File',
      footerButton: true,
      icon: EditIcon,
      clickFunction: handleEditClick,
      seq: 2,
      loading: openCuratorInProgress,
    },
    {
      element: SharePopup,
      footerButton: data?.status === RENDER_STATUS.STATUS_DONE,
      icon: ShareIcon,
      title: 'Share',
      seq: 3,
      modelType: 6
    },
    {
      element: FooterButton,
      footerButton: data?.status === RENDER_STATUS.STATUS_DONE,
      icon: CollectionIcon,
      title: 'Add to Collection',
      // onSuccess: () => console.log('successfully added to collections'),
      // cardType,
      clickFunction: () => addToCollection({
        modelType: MODEL_TYPES.PROJECTRENDER,
        items: [data],
      }),
      // id: id,
      seq: 4,
    },
    {
      element: FooterButton,
      footerButton: data?.status === RENDER_STATUS.STATUS_DONE,
      title: 'Favorite',
      icon: data?.is_favourite ? HeartIcon : HeartLineIcon,
      clickFunction: handleFavorite,
      is_favourite: data?.is_favourite,
      loading: favouriteLoading,
      seq: 5,
    },
    {
      element: FooterButton,
      footerButton: data?.status === RENDER_STATUS.STATUS_DONE,
      icon: FullScreenIcon,
      title: 'Full Screen',
      seq: 6,
    },
    {
      ...deactivateButton,
      seq: 7,
    },
    {
      element: FooterButton,
      footerButton: false,
      title: 'Help',
      icon: HeadsetIcon,
      cardType,
      seq: 8,
    },
  ].filter(Boolean);

  // console.log("actions: ", actions);

  const updatedAt = useMemo(() => {
    if (!data?.updated_at) return null;
    return new Date(data?.updated_at).getTime();
  }, [data?.updated_at]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(RENDERS_PREVIEWS_ROUTE)
    }
  }

  // useEffect(() => {
  //   console.log("pass activeIndex: ", activeIndex);
  //   console.log("pass pass: ", pass);
  //   console.log("pass activeCameraId: ", activeCameraId);
  // }, [activeIndex, pass, activeCameraId])

  const hasMetadata = renderedData?.roomMetadata ? Object.values(renderedData?.roomMetadata || {}).length > 0 : false

  console.log("datadata", data)
  return (
    <>
      <Preview
        id={data?.id}
        title={data?.name}
        renderStatus={data?.status === RENDER_STATUS.STATUS_DONE}
        actions={data ? actions : []}
        onClose={handleClose}
        loading={loading}
        updatedAt={updatedAt}
        created_at={data?.created_at}
        userName={currentUser?.name || null}
        sidebarCards={sidebarCards}
        slides={_.map(slides, (s) => (s ? s : ''))}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        activeCameraId={activeCameraId}
        setActiveCameraId={setActiveCameraId}
        passOptions={passOptions}
        pass={pass}
        theme={theme}
        setPass={setPass}
        memberDetails={memberDetails}
        sidebarProps={sidebarProps}
        previewProps={previewProps}
        hideAutoEraseWarning={hideAutoEraseWarning}
      />
      {showDownload && (
        <div
          className="download-popup text-light"
          style={{ top: downloadYPos - 180, left: downloadXPos - 64 }}>
          <div role="button" onClick={handleCurrentImageClick} className="">
            Current Image
          </div>
          <div role="button" className={`${sidebarCards?.length === 1 ? 'disabled' : ''}`} onClick={() => sidebarCards?.length === 1 ? {} : handleAllCameraRendersDownloadClick()}>
            All Camera Renders
          </div>
          <hr className="m-0 p-0" />
          <div className="d-flex justify-content-between">
            <div className="">
              <a>Include Passes</a>
            </div>
            <Form.Check
              type="switch"
              id="custom-switch"
              value={includePasses}
              onChange={(e) => setIncludePasses(e.target.checked)}
            />
          </div>
        </div>
      )}
      {
        showRender 
        && 
          ((data !== undefined && data !== null && data?.images?.length > 0) 
            &&
          (cameraList !== undefined && cameraList !== null && cameraList?.length > 0))
          &&
          <RenderPageDialog 
            render_id = {id}
            cameraData={cameraList}
            showRender={showRender}
            setShowRender={setShowRender}
            hasMetadata={hasMetadata}
            onRenderSuccess={onRenderSuccess}
          />
      }
      <AddToCollectionPopup />
    </>
  );
});

export default RenderDetails;
