import { TRANSACTION_HISTORY, TRANSACTION_HISTORY_EMAIL_LIST } from "../helpers/api";
import { transactionHistoryEmailListActions } from "../redux/slicers/transactionHistoryEmailListSlicer";
import { transactionHistoryActions } from "../redux/slicers/transactionHistorySicer";
import { createGetListAction } from "./products";

export const getTransactionListApi = createGetListAction({
  endpoint: TRANSACTION_HISTORY,
  actions: transactionHistoryActions,
  defaultSort: '-created_at'
})

export const getTransactionListFilterEmailApi = createGetListAction({
  endpoint: TRANSACTION_HISTORY_EMAIL_LIST,
  actions: transactionHistoryEmailListActions,
  defaultSort: '-email'
})