import { useEffect, useRef } from "react"

export const useElementRef = (selector) => {
  const ref = useRef(null)

  useEffect(() => {
    ref.current = document.querySelector(selector);
  }, [])

  return ref;
}