import cx from 'classnames';
import { Form, FormControl, Image } from 'react-bootstrap';
import { icons } from '../assets';
import { CloseIcon } from '../components/icons/CloseIcon';
import CloseCircleIcon from '../components/icons/closeCircleIcon';
const { SearchBarIcon } = icons;

export default function SearchInput({ onSubmit, value, placeholder, onChange, className, inputClassName, onClear  }) {
  function handleSearchQuery(e) {
    e.preventDefault();
    onSubmit?.(e);
  }

  const customClearButton = Boolean(onClear)
  const inputType = customClearButton ? 'text' : 'search';

  return (
    <Form
      className={cx(className, 'd-block search-form')}
      onSubmit={handleSearchQuery}
      >
      <Form.Group className="search-form-group">
        <Form.Label className="search-icon">
          <SearchBarIcon />
        </Form.Label>
        <FormControl
          type={inputType}
          className={cx(inputClassName, 'search-form-input', customClearButton && 'search-form-input-custom-close' )}
          value={value}
          placeholder={placeholder || 'Search'}
          onChange={onChange}
        />
        {customClearButton && value && <CloseCircleIcon className="search-form-clear-icon" onClick={onClear} />}
      </Form.Group>
    </Form>
  );
}
