import cx from 'classnames';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { SIDEBAR_MENU, SIDEBAR_MENU_2 } from '../constants/menu';
import { FilterContext } from '../contexts';
import { OrganizationSwitcher } from '../common/OrganizationSwitcher';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeSideFilter,
  resetFilters,
} from '../redux/slicers/admin/sidebarSlicer';
import { SideFilter } from '../components/SideFilter/SideFilter';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import { selectSearchActive } from '../redux/slicers/search/searchSlicer';

export default function Sidemenu() {
  const dispatch = useDispatch();
  const filterCtx = useContext(FilterContext);
  const location = useLocation();
  const searchActive = useSelector(selectSearchActive);


  useEffect(() => {
    dispatch(resetFilters());
  }, [location.pathname, dispatch]);

  const { filters, filterValues } = useGlobalFilters();

  if (filterCtx?.hideSidemenu && !searchActive) return null;
  const showFilter = filterCtx?.showDrilldownMenu || searchActive;

  const handleFilterChange = (value) => {
    dispatch(changeSideFilter(value));
  };

  return (
    <aside className="sidebar">
      <div className="sidebar-wrapper filter-sidebar-wrapper">
        <div className="sidebar-filter fs-container filter_container">
          <OrganizationSwitcher />
          {showFilter ? (
            <SideFilter
              filters={filters}
              onChange={handleFilterChange}
              value={filterValues}
              maxVisible={3}
            />
          ) : (
            <div className="feature-nav">
              <CommonFeatureMenu navList={SIDEBAR_MENU} />
              <CommonFeatureMenu navList={SIDEBAR_MENU_2} />
            </div>
          )}
        </div>
      </div>
    </aside>
  );
}

function CommonFeatureMenu({ navList }) {
  const location = useLocation();
  return (
    <Navbar expand="xs" className="sidemenu page-nav flex-column align-items-start">
      <Nav as="ul" className="menu">
        {navList.map((item, i) => {
          const Icon = item?.icon;

          return (
            <Nav.Item
              as="li"
              key={item.path}
              className={cx({
                active: matchPath({ path: item.path }, location?.pathname) !== null,
              })}>
              <Link to={item.path} className="nav-link">
                <Icon />
                <span>{item.name}</span>
              </Link>
            </Nav.Item>
          );
        })}
      </Nav>
    </Navbar>
  );
}
