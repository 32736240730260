// import { type } from 'os';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {API_ROOT_URL, PROJECT_CAMERA_ANGLE, GET_PROJECT_CAMERA_ANGLE, CREATE_PROJECT_CAMERA_ANGLE, UPDATE_PROJECT_CAMERA_ANGLE, DELETE_PROJECT_CAMERA_ANGLE} from '../../config/constant/apiConstant';
import { CONVERT_IN_INCHES } from '../../config/constant/projectConstant';
import api from "../../helpers/axiosHelper";
import { decimals } from '../../helpers/jsHelper';
import { errorToast, successToast } from '../../helpers/toastHelper';
import { addCameraSuccess, getProjectCameraAnglesList, projectCameraAngleSelector, setAddCameraLoading, setProjectCameraAnglesList, updateCameraSuccess} from '../../redux/slicers/camera/projectCameraAngleSlicer';

export const parseCamera = (camera) => {
    const parsedCamera =  {
        ...camera,
        id: camera.id,
        cameraName: camera.cameraName,
        fov: parseInt(camera.fov),
        isCameraCorrection: camera.isCameraCorrection,
        cameraHeight: decimals(camera.cameraHeight, 2),
        isdepthOfField: camera.isdepthOfField,
        selectedObjectName: camera.selectedObjectName,
        blurIntensity: decimals(camera.blurIntensity, 4),
        distanceToSet: decimals(camera.distanceToSet, 4),
    
        resoX: Number.parseInt(camera.resoX),
        resoY: Number.parseInt(camera.resoY),
    
        focalLength: decimals(camera.focalLength, 4),
        xTransRot: decimals(camera.xTransRot, 4),
        yTransRot: decimals(camera.yTransRot, 4),
        zTransRot: decimals(camera.zTransRot, 4),
        xPos: decimals(camera.xPos, 4),
        yPos: decimals(camera.yPos, 4),
        zPos: decimals(camera.zPos, 4),
        centerObjectxPos: decimals(camera.centerObjectxPos, 4),
        centerObjectyPos: decimals(camera.centerObjectyPos, 4),
        centerObjectzPos: decimals(camera.centerObjectzPos, 4),
        cameraRotX: decimals(camera.cameraRotX, 4),
        cameraRotZ: decimals(camera.cameraRotZ, 4),
        lensShiftY: decimals(camera.lensShiftY, 4),
    
        project: camera.project,
        room: camera.room
    }

    if(camera.project == null){
        delete parsedCamera.project
    }else if(camera.room == null){
        delete parsedCamera.room
    }

    return parsedCamera;
}

export const getProjectCameraAngleAPI = (type, id) => (dispatch) => {

    let QUERY;

    if(type === "project"){
        QUERY = `${PROJECT_CAMERA_ANGLE}?project_id=${id}`;
    }else if(type === "room"){
        QUERY = `${PROJECT_CAMERA_ANGLE}?room_id=${id}`;
    }

    api().root(API_ROOT_URL).get(QUERY).success((data)=>{
        
        console.log("get project camera angle api success: ", data.results)
        console.log("get project camera angle api success: ", typeof(data.results))

        const res = []
        data.results.forEach(function(a) {
            const parsedCamera = parseCamera(a)
            res.push(parsedCamera);
        })

        console.log("res: ", res)
        if(data.results !== 0) dispatch(setProjectCameraAnglesList(res))

    }).error((error)=>{
        
        console.log("get project camera angle api error: ", error)

    }).send()
        
}


export const createProjectCameraAngleAPI = (formData, { onSuccess } = {} ) => (dispatch) => {

    const headers = { 'Content-Type': 'multipart/form-data' }

    dispatch(setAddCameraLoading(true))
    api().root(API_ROOT_URL).post(PROJECT_CAMERA_ANGLE).data(formData).setHeaders(headers).success((data)=>{
        const parsedCamera = parseCamera(data.data)
        console.log("create project camera angle api success: ", parsedCamera)
        dispatch(addCameraSuccess(parsedCamera))
        dispatch(setAddCameraLoading(false))
        successToast('Your camera has been succesfully added.', 'Camera Added');
        onSuccess?.()
    }).error((error)=>{
        errorToast('Error happened while creating project camera angle')
        console.error("create project camera angle api error: ", error)
    })
    .finally(() => {
        dispatch(setAddCameraLoading(false))
    })
    .send()
        
}

export const deleteProjectCameraAngleAPI = (id) => {

    api().root(API_ROOT_URL).delete(`${PROJECT_CAMERA_ANGLE}${id}/`).success((data)=>{
        
        console.log("delete project camera angle api success: ", data)
        // dispatch(setProjectCameraAnglesList(data.data))

        // return data.results

    }).error((error)=>{
        
        console.log("delete project camera angle api error: ", error)

    }).send()
        
}

export const updateProjectCameraAngleAPI = (id, formData, { onSuccess, disableToast } = {}) => (dispatch) => {

    // console.log("formData api: ", id, formData)

    // for (const [key, value] of Object.entries(formData)) {
            
    //     console.log(key+ ', ' + value); 
    //     // formData.append(key, value);
    // }

    const headers = { 'Content-Type': 'multipart/form-data' }

    api().root(API_ROOT_URL).patch(`${PROJECT_CAMERA_ANGLE}${id}/`).data(formData).setHeaders(headers).success((data)=>{
        const parsedCamera = parseCamera(data.data)
        dispatch(updateCameraSuccess(parsedCamera))

        if (!disableToast) {
            successToast('Camera updated successfully!', { toastId: `CAMERA_DELETE_SUCCESS_${id}` });
        }
        onSuccess?.()
        
        console.log("update project camera angle api success: ", data)

    }).error((error)=>{
        
        console.log("update project camera angle api error: ", error)

    }).send()
        
}


