import { createPaginatedSlice } from "../../../helpers/reduxHelper";

const myprojects = createPaginatedSlice('myprojects');

export const { setMyProjectsData } = myprojects.actions;
export const myprojectsActions = myprojects.actions
export const myprojectsSelector = state => state.myprojects;
export const myprojectsReducer = myprojects.reducer;
export default myprojectsReducer

export const myprojectsSelectors = myprojects.LF.selectors