import cx from 'classnames';
import React, { useState, useEffect } from 'react';
import { ROOM1 } from '../config/constant/assetConstant';
import isEmpty from 'lodash/isEmpty';
import { ButtonGroup, Carousel, ToggleButton } from 'react-bootstrap';
import styles from '../assets/scss/modules/_previewUI.module.scss';
import { CloseIcon } from '../components/icons/CloseIcon';
import _ from 'lodash';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

export default function PreviewUIDisplay(props) {
  const {
    id,
    theme,
    className,
    isFullscreen,
    onClose,
    title,
    actions,
    toggleFullscreen,
    slides,
    cards,
    activeIndex,
    setActiveIndex,
    activeCameraId,
    setActiveCameraId,
    classNameFullscreen,
    passOptions,
    pass,
    setPass,
  } = props;

  const previewThumbnail = props.roomImage === null ? ROOM1 : props.roomImage;

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
    setActiveCameraId(cards[selectedIndex].id);
  };

  return (
    <div
      style={isFullscreen ? { width: '100vw', height: '100vh', left: 0 } : null}
      className={cx(
        styles.previewUI,
        isFullscreen && styles.fullscreen,
        isFullscreen && classNameFullscreen,
        className,
        styles[theme]
      )}>
      {!isFullscreen && (
        <button className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </button>
      )}
      <div className={cx(styles.carouselContainer, 'd-flex justify-content-center carousel slide')}>
        {slides?.length > 1 ? (
          <Carousel
            className={cx(styles.carousel, {fullScreenMode: isFullscreen === true})}
            activeIndex={activeIndex}
            onSelect={handleSelect}
            interval={null}>
            {slides.map((s, i) => (
              <Carousel.Item>
                <ZoomInImage src={s ? s : previewThumbnail} isFullscreen={isFullscreen} />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <ZoomInImage src={slides?.length === 1 ? slides[0] : previewThumbnail} isFullscreen={isFullscreen} />
        )}
      </div>
      {/* {!isFullscreen && ( */}
        <>
          {
            props.renderStatus
            &&
            <div
              className={cx(styles.passBtnContainer, ' d-flex justify-content-end')}
              style={{ position: 'absolute', bottom: 67, right: 10, zIndex: 999 }}>
              <ButtonGroup>
                {passOptions &&
                  passOptions.map((p, i) => (
                    <ToggleButton
                      key={i}
                      id={`radio-${i}`}
                      type="radio"
                      variant="outline-primary"
                      name="pass"
                      value={p.value}
                      checked={pass === p.value}
                      onChange={(e) => setPass(e.currentTarget.value)}
                      className={pass === p.value ? 'pass-btn active' : 'pass-btn'}>
                      {p.name}
                    </ToggleButton>
                  ))}
              </ButtonGroup>
            </div>
          }

          <footer className={cx(styles.previewFooter, 'preview-display-footer')}>
            <h1 className="title">{title}</h1>
            {!isEmpty(actions) && (
              <ul className="footer-action-list ced-action">
                {actions.map((a, index) => {
                  const { element, ...rest } = a;
                  const Component = element;
                  return <Component shareId={id} id={`action${index}}`} key={`action${index}}`} {...rest} />;
                })}
              </ul>
            )}
          </footer>
        </>
      {/* )} */}
    </div>
  );
}

const ZoomInImage = ({ src, isFullscreen = false }) => {
  
  return (
    <TransformWrapper centerOnInit>
      <TransformComponent
        contentStyle={{ 
          maxWidth: '100%', 
          maxHeight: '100%', 
          width: isFullscreen ? '100%' : 'calc(100vw - 225px)', 
          height: '100%' 
        }}
        wrapperStyle={{ width: '100%', height: '100%' }}>
        <div style={{ width: '100%', height: '100%' }}>
          <img
            src={src}
            alt="..."
            style={{
              display: 'initial',
              width: '100%',
              height: '100%',
              objectFit: 'scale-down',
            }}
          />
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};
