import { getCollectionPageListApi } from '../api/collections';
import { collectionsActions, collectionsSelector } from '../redux/slicers/collectionsSlicer';
import { useThumbnailCardDataList } from './useDataList';

export const useCollections = ({ requestParams, filterByKey, defaultSort, useGlobalSearch } = {}) => {
  const data = useThumbnailCardDataList({
    selector: collectionsSelector,
    resetAction: collectionsActions.resetList,
    getAction: getCollectionPageListApi,
    requestParams,
    filterByKey,
    defaultSort,
    useGlobalFilter: true,
    useGlobalSearch,
  });

  return data;
};
