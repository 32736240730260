import { useState } from 'react';
import { downloadFile } from '../api/curator';
import { deleteProjectAPI, projectUpdateApi } from '../api/myprojects';
import { MODEL_TYPES } from '../constants/constants';
import { apiGetDownloadProject } from '../helpers/api';
import { handleDownloadURL } from "../helpers/jsHelper";
import { errorToast } from '../helpers/toastHelper';
import { myprojectsSelectors } from '../redux/slicers/my-projects/myProjectsSlicer';
import { useModelActions } from './useModelActions';

export const useProjectActions = ({ item }) => {
  const { downloadProject, downloadLoading } = useDownloadProject()

  const { favouriteLoading, onFavouriteToggle, onDelete, onShare, addToCollection } = useModelActions({
    item,
    modelType: MODEL_TYPES.PROJECTS,
    selectors: myprojectsSelectors,
    updateApi: projectUpdateApi,
    deleteConfig: {
      title: 'Are you sure you want to delete this project?',
      deleteApi: deleteProjectAPI,
    },
  });

  return {
    onDelete,
    onFavouriteToggle,
    favouriteLoading,
    downloadProject,
    downloadLoading,
    onShare,
    addToCollection,
  };
};



export const useDownloadProject = () => {
  const [loading, setLoading] = useState(false);

  const downloadProject = async (idOrList) => {
    if (loading) return;

    setLoading(true);
    await downloadProjectFile(idOrList);
    setLoading(false);
  };

  return { downloadProject, downloadLoading: loading };
};



const downloadProjectFile = async (idOrList) => {
  const formData = new FormData();

  if (Array.isArray(idOrList)) {
    idOrList.forEach((id) => {
      formData.append('id', id);
    });
  } else {
    formData.append('id', idOrList);
  }
  const res = await apiGetDownloadProject(formData);
  if (res.status) {
    res?.data?.results?.map(async (item) => {
      await handleDownloadURL(item?.zip_url, item?.name);
    });
  } else {
    errorToast(res?.message);
  }
};
