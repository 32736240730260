import { ROOM_TYPE, TEMPLATE_TYPE } from './projectConstants';
import { getProjectTypeLabel } from '../helpers/dataHelper';
import { getCuratorPath } from '../helpers/urlHelper';
import { RENDER_DETAILS } from './routePathConstants';

const PROJECT_TYPE_OPTIONS = [
  {
    label: getProjectTypeLabel(ROOM_TYPE.LIFESTYLE),
    value: ROOM_TYPE.LIFESTYLE,
  },
  {
    label: getProjectTypeLabel(ROOM_TYPE.SILO),
    value: ROOM_TYPE.SILO,
  },
  {
    label: getProjectTypeLabel(ROOM_TYPE.VIGNETTE),
    value: ROOM_TYPE.VIGNETTE,
  },
];

const TEMPLATE_TYPE_OPTIONS = [
  {
    label: getProjectTypeLabel(TEMPLATE_TYPE.LIFESTYLE),
    value: TEMPLATE_TYPE.LIFESTYLE,
  },
  {
    label: getProjectTypeLabel(TEMPLATE_TYPE.VIGNETTE),
    value: TEMPLATE_TYPE.VIGNETTE,
  },
  {
    label: getProjectTypeLabel(TEMPLATE_TYPE.SILO),
    value: TEMPLATE_TYPE.SILO,
  },
];

export const RENDER_STATUS = {
  STATUS_NOT_DONE: 0,
  STATUS_DONE: 1,
  STATUS_PROCESSING: 2,
  STATUS_FAILED: 3,
  STATUS_DISABLED: 4,
};
// STATUS_UNDER_REVIEW: 5, // yet not included as this is not checked from the backend

const STATUS_NOT_DONE = 'Not Done';
const STATUS_DONE = 'Done';
const STATUS_PROCESSING = 'Processing';
const STATUS_FAILED = 'Failed';
const STATUS_DISABLED = 'Disabled';

const RENDER_STATUS_OPTIONS = [
  {
    value: RENDER_STATUS.STATUS_NOT_DONE,
    label: STATUS_NOT_DONE,
  },
  {
    value: RENDER_STATUS.STATUS_DONE,
    label: STATUS_DONE,
  },
  {
    value: RENDER_STATUS.STATUS_PROCESSING,
    label: STATUS_PROCESSING,
  },
  {
    value: RENDER_STATUS.STATUS_FAILED,
    label: STATUS_FAILED,
  },
  {
    value: RENDER_STATUS.STATUS_DISABLED,
    label: STATUS_DISABLED,
  },
];
export const USER_ROLE_IDS = {
  // This is still somewhat tenative, per Aakash 04/28/2022
  // He said that role #5 is not in use anywhere on the API side yet
  ADMIN: 5,
  MEMBER: 6,
};

export const INTERNAL_USER_ROLES = [USER_ROLE_IDS.MEMBER];
export const USER_ROLES = [
  { label: 'Member', value: USER_ROLE_IDS.MEMBER },
  { label: 'Admin', value: USER_ROLE_IDS.ADMIN },
];

export const MODULE_TYPE_OPTIONS = { // -> this represents collection module types
  PROPS: 1,
  THREEDMODELS: 2,
  TEXTURES: 3,
  UPLOADS: 4,
  ROOMS: 5,
  TEMPLATES: 6,
  RENDERS: 7,
  PREVIEWS: 8,
  PROJECT: 9,
  MATERIAL: 10,
};

const COLLECTION_MODEL_TYPE = MODULE_TYPE_OPTIONS; // TODO: replace variable MODULE_TYPE_OPTIONS with  COLLECTION_MODEL_TYPE

export const GET_MODULE_KEY_BY_ID = (id) => {
  id = parseInt(id, 10);
  switch (id) {
    case MODULE_TYPE_OPTIONS.PROPS:
      return {
        type: MODULE_TYPES.PROPS,
        clickUrl: (id) => {}
      };

    case MODULE_TYPE_OPTIONS.ROOMS:
      return {
        type: MODULE_TYPES.ROOMS,
        clickUrl: (id) => `/templates/${id}`
      };

    case MODULE_TYPE_OPTIONS.TEMPLATES:
      return {
        type: MODULE_TYPES.TEMPLATES,
        clickUrl: (id) => `/templates/${id}`
      };

    case MODULE_TYPE_OPTIONS.PROJECT:
      return {
        type: MODULE_TYPES.PROJECTS,
        clickUrl: (id) => getCuratorPath(id, 'projects'),
      };

    case MODULE_TYPE_OPTIONS.RENDERS:
      return {
        type: MODULE_TYPES.RENDER_PREVIEW,
        clickUrl: (id) => RENDER_DETAILS.replace(':id', id)
      };

    case MODULE_TYPE_OPTIONS.PREVIEWS:
      return {
        type: MODULE_TYPES.RENDER_PREVIEW,
        clickUrl: (id) => RENDER_DETAILS.replace(':id', id)
      };

    // not sure about these, so need to test
    case MODULE_TYPE_OPTIONS.TEXTURES:
      return {
        type: MODULE_TYPES.TEXTURES,
        clickUrl: (id) => {}
      };

    case MODULE_TYPE_OPTIONS.UPLOADS:
      return {
        type: MODULE_TYPES.UPLOADS,
        clickUrl: (id) => {}
      };
      

    // case MODULE_TYPE_OPTIONS.MATERIAL:
    //   return MODULE_TYPES.RENDER_PREVIEW;

    case MODULE_TYPE_OPTIONS.THREEDMODELS:
      return {
        type:MODULE_TYPES.THREEDMODEL,
        clickUrl: (id) => `/products/3dmodel/${id}`
      }

    default:
      // if you have gotten here, then we may have a missing or new module type
      // that we need to know the value from the API team
      return null;
  }
};

/// these are the keys that come back on the sidefilter "order" field
// and other common filter keys
export const FILTER_TYPES = {
  BRANDS: 'brands',
  PRODUCTS: 'products',
  COLLECTIONS: 'collections',
  FILTER_OPTIONS: 'filteroptions',
  FILTER_LABELS: 'filterlabels',
  FAVOURITE: 'is_favourite',
  PAGE: 'page',
  CREATED_BY: 'created_by',
  ORDERED_FILTERS: 'orderedFilters',
};

export const collectionFriendlyTitle = (model_type) => {
  switch (parseInt(model_type, 10)) {
    case MODULE_TYPE_OPTIONS.PROPS:
      return 'Common Prop';
    case MODULE_TYPE_OPTIONS.THREEDMODELS:
      return 'My product';
    case MODULE_TYPE_OPTIONS.TEXTURES:
      return 'Texture';
    case MODULE_TYPE_OPTIONS.UPLOADS:
      return 'Upload';
    case MODULE_TYPE_OPTIONS.ROOMS:
      return 'My Room';
    case MODULE_TYPE_OPTIONS.TEMPLATES:
      return 'Common Room';
    case MODULE_TYPE_OPTIONS.RENDERS:
      return 'Render';
    case MODULE_TYPE_OPTIONS.PREVIEWS:
      return 'Preview';
    case MODULE_TYPE_OPTIONS.PROJECT:
      return 'My Project';
    case MODULE_TYPE_OPTIONS.MATERIAL:
      return 'Material';
  }
};

export const MODULE_TYPES = {
  PRODUCT: 'product',
  PRODUCTS: 'products',
  PROP: 'prop',
  PROPS: 'props',
  TEXTURE: 'texture',
  TEXTURES: 'textures',
  ROOM: 'room',
  ROOMS: 'rooms',
  TEMPLATE: 'template',
  TEMPLATES: 'templates',
  PROJECT: 'project',
  PROJECTS: 'projects',
  PROJECT_RENDER: 'project_render',
  RENDER: 'render',
  RENDERS: 'renders',
  RENDER_DETAIL: 'render-detail',
  RENDER_PREVIEW: 'renders-previews',
  PREVIEW: 'preview',
  PREVIEWS: 'previews',
  COLLECTION: 'collection',
  COLLECTIONS: 'collections',
  MODEL: 'model',
  MODELS: 'models',
  THREEDMODELS: '3dmodels',
  THREEDMODEL: '3dmodel',
  UPLOAD: 'upload',
  UPLOADS: 'uploads',
};

// per Aakash 04-May-2022
export const COLLECTION_PROPS = (itemType) => {
  console.log('item type >>> ', itemType);
  switch (itemType) {
    case MODULE_TYPES.PRODUCT:
    case MODULE_TYPES.PROP:
      return MODULE_TYPES.PROP;
    case MODULE_TYPES.ROOM:
    case MODULE_TYPES.TEMPLATE:
      return MODULE_TYPES.ROOM;
    case MODULE_TYPES.PROJECT:
      return MODULE_TYPES.PROJECT;
    case MODULE_TYPES.RENDER:
    case MODULE_TYPES.RENDER_DETAIL:
    case MODULE_TYPES.RENDER_PREVIEW:
    case 'renders-preview':
      return MODULE_TYPES.PROJECT_RENDER;
    case MODULE_TYPES.PREVIEW:
      return MODULE_TYPES.PROJECT_RENDER;
    case MODULE_TYPES.TEXTURE:
    case MODULE_TYPES.UPLOAD:
      return MODULE_TYPES.TEXTURE;
    default:
      throw new Error('item type is not supported');
  }
};


export const MODEL_TYPES = {
  PRODUCT: 1,
  TEXTURE: 2,
  ROOM: 3,
  PROJECTS: 4,
  COLLECTION: 5,
  PROJECTRENDER: 6,
  THREESIXTY: 7,
  THREEDMODELS: 8,
  UPLOADS: 9,
  TEMPLATES: 10,
  PREVIEWS: 11,
  WEBEXPERIENCE: 12,
};

export const MODEL_TYPE__COLLECTION_KEY = {
  [MODEL_TYPES.PRODUCT]: MODULE_TYPES.PROP,
  [MODEL_TYPES.TEXTURE]: MODULE_TYPES.TEXTURE,
  [MODEL_TYPES.ROOM]: MODULE_TYPES.ROOM,
  [MODEL_TYPES.PROJECTS]: MODULE_TYPES.PROJECT,
  // [MODEL_TYPES.COLLECTION]: ''
  [MODEL_TYPES.PROJECTRENDER]: MODULE_TYPES.PROJECT_RENDER,
  // [MODEL_TYPES.THREESIXTY]: ''
  [MODEL_TYPES.THREEDMODELS]: MODULE_TYPES.PROP,
  [MODEL_TYPES.UPLOADS]: MODULE_TYPES.TEXTURE,
  [MODEL_TYPES.TEMPLATES]: MODULE_TYPES.ROOM,
  [MODEL_TYPES.PREVIEWS]: MODULE_TYPES.PROJECT_RENDER
  // [MODEL_TYPES.WEBEXPERIENCE]: ''
}

export const COLLECTION_MODEL_TYPE__MODEL_TYPE__MAP = {
  [COLLECTION_MODEL_TYPE.PROPS]: MODEL_TYPES.PRODUCT,
  [COLLECTION_MODEL_TYPE.THREEDMODELS]: MODEL_TYPES.THREEDMODELS,
  [COLLECTION_MODEL_TYPE.TEXTURES]: MODEL_TYPES.TEXTURE,
  [COLLECTION_MODEL_TYPE.UPLOADS]: MODEL_TYPES.UPLOADS,
  [COLLECTION_MODEL_TYPE.ROOMS]: MODEL_TYPES.ROOM,
  [COLLECTION_MODEL_TYPE.TEMPLATES]: MODEL_TYPES.TEMPLATES,
  [COLLECTION_MODEL_TYPE.RENDERS]: MODEL_TYPES.PROJECTRENDER,
  [COLLECTION_MODEL_TYPE.PREVIEWS]: MODEL_TYPES.PREVIEWS,
  [COLLECTION_MODEL_TYPE.PROJECT]: MODEL_TYPES.PROJECTS,
  [COLLECTION_MODEL_TYPE.MATERIAL]: null,
}


export const TRASH_MODEL_TYPE = {
  ROOM: 1,
  TEXTURE: 2,
  PROJECT: 3,
  COLLECTION: 4,
  MATERIAL: 5,
  PROJECT_PREVIEW: 6,
  PRODUCT: 7,
  FIXED_PRODUCT: 8,
  UPLOADS: 9,
};

export const FILTER_MODEL_TYPE = {
  PROPS: 0,
  ROOM: 1, // for ROOM/PROJECT we have same category model type
  TEXTURE: 2,
  FIXED_PROPS: 3,
  PROJECT_RENDERS: 4,
  MATERIAL: 5,
  TEXTURE_UPLOADS: 6,
  COLLECTION: 7,
};

// notification filter: below are the status for notification
// RenderStatus = render_status
// Preview = preview
// RequestStatusAndComments = request_status_and_comments
// SharedFiles = shared_files
// FromLiveFurnish= from_live_furnish
// UpdateOrAnnouncementFromLiveFurnishApp = update_or_announcement_from_live_furnish
// PAYMENT_AND_TRANSACTION = payment_and_transaction
export const NOTIFICATION_MODEL_TYPE = {
  RenderStatus: 'render_status',
  Preview: 'preview',
  RequestStatusAndComments: 'request_status_and_comments',
  SharedFiles: 'shared_files',
  FromLiveFurnish: 'from_live_furnish',
};

export const CARD_TYPE = {
  PROJECT: 'projects',
}

const SHARED_FILES_CARD_BANNER = {
  [MODEL_TYPES.PRODUCT]: {
    title: 'Product',
    cardType: 'props',
    button: [2, 3, 4, 5],
    clickUrl: (id) => getCuratorPath(id, 'product'),
  },
  [MODEL_TYPES.TEXTURE]: {
    title: 'Texture',
    cardType: 'textures',
    button: [1, 2, 3, 4, 5],
    clickUrl: () => {},
  },
  [MODEL_TYPES.ROOM]: {
    title: 'Room',
    cardType: 'templates',
    button: [2, 3, 4, 5],
    clickUrl: (id) => `/templates/${id}`,
  },
  [MODEL_TYPES.PROJECTS]: {
    title: 'Project',
    cardType: 'project',
    button: [1, 2, 3, 4, 5],
    clickUrl: (id) => getCuratorPath(id, 'projects'),
  },

  [MODEL_TYPES.COLLECTION]: {
    title: 'Collection',
    cardType: 'collection',
    button: [3, 5],
    clickUrl: (id) => `/collection/${id}`,
  },
  [MODEL_TYPES.PROJECTRENDER]: {
    title: 'Render&Preview',
    cardType: 'renders-previews',
    button: [1, 2, 3, 4, 5],
    clickUrl: (id) => RENDER_DETAILS.replace(':id', id),
  },
};

export const SETINGS_TAB = {
  DETAILS: 'details',
  NOTIFICATIONS: 'notifications',
  INTERESTS: 'interests',
  TRANSACTIONS: 'transactions',
}

export const ROOM_FILTER_TYPE = FILTER_MODEL_TYPE.ROOM;
export const CATEGORY_FILTER_NAME = 'category';
export const CATEGORY_FILTER_TYPE = FILTER_MODEL_TYPE.ROOM;
export const CATEGORY_FILTER_ID = 9;

export const STYLE_FILTER_NAME = 'Style';
export const STYLE_FILTER_TYPE = FILTER_MODEL_TYPE.ROOM;
export const STYLE_FILTER_ID = 12;

export const PRODUCT_CATEGORY_FILTER_NAME = 'category';
export const PRODUCT_FILTER_TYPE = FILTER_MODEL_TYPE.PROPS;

export const PROJECT_FILTER_TYPE = FILTER_MODEL_TYPE.ROOM;
export const TEXTURE_FILTER_TYPE = FILTER_MODEL_TYPE.TEXTURE;

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;

// My team constants
export const ROLE_ORG_OWNER = 3;
export const ROLE_ORG_ADMIN = 6;
export const ROLE_ORG_INTERNAL_USER = 5;
export const ROLE_ORG_EXTERNAL_USER = 7;

export const ALLOWED_MIME_TYPES = ["image/png", "image/jpg", "image/jpeg"];

const constants = {
  TRASH_MODEL_TYPE,
  PROJECT_TYPE_OPTIONS,
  TEMPLATE_TYPE_OPTIONS,
  FILTER_MODEL_TYPE,
  RENDER_STATUS,
  RENDER_STATUS_OPTIONS,
  INTERNAL_USER_ROLES,
  USER_ROLES,
  MODULE_TYPES,
  COLLECTION_PROPS,
  FILTER_TYPES,
  SHARED_FILES_CARD_BANNER,
  collectionFriendlyTitle,
};

export default constants;
