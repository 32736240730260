/* eslint-disable no-debugger */
import { isEqual } from 'lodash';
import { openSidebarSection } from '../../../../helpers/curatorActions';
import { dispatcher } from '../../../../helpers/projectHelper';
import { selectSelectedLightId } from '../../../../redux/slicers/admin/curatorLightSlicer';
import { CURATOR_MENU_SECTION } from '../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import { selectLightById, updateLight } from '../../../../redux/slicers/admin/curatorSlicer';
import { setCameraData, updateCameraData } from '../../../../redux/slicers/camera/cameraEditSlicer';
import { setGlobalHeight, setGlobalWidth } from '../../../../redux/slicers/camera/cameraSettings';
import { selectCamera, selectExpandedCamera, selectExpandedCameraId, setExpandedCameraId, undoRedoDeleteCamera, undoRedoDeleteRevertCamera } from '../../../../redux/slicers/camera/projectCameraAngleSlicer';
import { getAppState } from '../../../../redux/store';

export class UndoRedoCamera {
  constructor({ undoRedoInstance, unityCameraFields, unityContext }) {
    this.undoRedo = undoRedoInstance;
    this.unityCameraFields = unityCameraFields;
    this.unityContext = unityContext

    this.init();
  }

  _openEditCamera = (id) => {
    openSidebarSection(CURATOR_MENU_SECTION.CAMERAS);
    const currentEditId = selectExpandedCameraId(getAppState());
    if (currentEditId !== id) {
      dispatcher(setExpandedCameraId(id));
      const camera = selectCamera(getAppState(), id);
      dispatcher(setCameraData(camera));
    }
  }


  init = () => {
    // TODO: create queue of updated cameras ->>> save those which were changed

    // camera name
    this.update = this.undoRedo.createAction(({ name, prevValue, nextValue }) => {
      if (isEqual(prevValue, nextValue)) return;
      const expandedId = selectExpandedCameraId(getAppState());

      return {
        undo: () => {
          this._openEditCamera(expandedId);
          dispatcher(updateCameraData({ [name]: prevValue }))

          // const isDepthOfFieldSelected = name === 'isdepthOfField' && prevValue;

          // if (!isDepthOfFieldSelected) {
            this.unityCameraFields.handlePropertyChange({
              name, 
              value: prevValue,
              checked: prevValue,
              waitForEscEvent: () => {
                // escSubscriptionRef.current = PubSub.subscribe(KEYBOARD_EVENT.ESC_PRESSED, () => {
                //   updateCamera({ isdepthOfField: false });
                //   cameraModule.CloseFocusObjectReceiver();
                // });
              },
              numberValue: prevValue, // TODO: work on this one
            })
          // }
        },
        redo: () => {
          this._openEditCamera(expandedId);
          dispatcher(updateCameraData({ [name]: nextValue }))

          this.unityCameraFields.handlePropertyChange({
            name, 
            value: nextValue,
            checked: nextValue,
            waitForEscEvent: () => {},
            numberValue: nextValue, // TODO: work on this one
          })
        },
      };
    });

    this.updateResolution = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      if (isEqual(prevValue, nextValue)) return;

      const expandedId = selectExpandedCameraId(getAppState());

      return {
        undo: () => {
          this._openEditCamera(expandedId);
          dispatcher(updateCameraData(prevValue))
          dispatcher(setGlobalWidth(prevValue.resoX));
          dispatcher(setGlobalHeight(prevValue.resoY));
        },
        redo: () => {
          this._openEditCamera(expandedId);
          dispatcher(updateCameraData(nextValue))
          dispatcher(setGlobalWidth(nextValue.resoX));
          dispatcher(setGlobalHeight(nextValue.resoY));
        },
      }
    })

   
    this.focusObjectSelected = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      if (isEqual(prevValue, nextValue)) return;

      return {
        undo: () => {
          dispatcher(updateCameraData(prevValue));

          this.unityCameraFields.handlePropertyChange({
            name: 'isdepthOfField', 
            checked: true,
            waitForEscEvent: () => {
              // TODO: work on it
              // escSubscriptionRef.current = PubSub.subscribe(KEYBOARD_EVENT.ESC_PRESSED, () => {
              //   updateCamera({ isdepthOfField: false });
              //   cameraModule.CloseFocusObjectReceiver();
              // });
            },
          })
        },
        redo: () => {
          dispatcher(updateCameraData(nextValue));
          this.unityCameraFields.closeFocusReceiver();
          this.unityContext.cameraModule.SetFocusObjectReceiver({ 
            selectedObjectName: nextValue.selectedObjectName,
            blurIntensity: nextValue.blurIntensity,
          })
        }
      }
    })

    this.clearFocusedObject = this.undoRedo.createAction(({ prevValue, nextValue }) => {
      if (isEqual(prevValue, nextValue)) return;

      return {
        undo: () => {
          dispatcher(updateCameraData(prevValue));
          // get camera 
          this.unityContext.cameraModule.CloseFocusObjectReceiver();
          this.unityContext.cameraModule.SetFocusObjectReceiver({ 
            selectedObjectName: prevValue.selectedObjectName,
            blurIntensity: prevValue.blurIntensity,
          })


          // this.unityCameraFields.handlePropertyChange({
          //   name: 'isdepthOfField', 
          //   checked: true,
          //   waitForEscEvent: () => {
          //     // TODO: work on it
          //     // escSubscriptionRef.current = PubSub.subscribe(KEYBOARD_EVENT.ESC_PRESSED, () => {
          //     //   updateCamera({ isdepthOfField: false });
          //     //   cameraModule.CloseFocusObjectReceiver();
          //     // });
          //   },
          // })
        },
        redo: () => {
          dispatcher(updateCameraData(nextValue));
          this.unityCameraFields.clearSelectedObject();
        }
      }
    })


    this.deleteCamera = this.undoRedo.createAction(({ camera }) => {
      // TODO: create map of id to uuid -> and do actions based on uuid instead of id as id will change later on
      return {
        undo: () => {
          dispatcher(undoRedoDeleteRevertCamera({ id: camera.id }))
        },
        redo: () => {
          dispatcher(undoRedoDeleteCamera({ id: camera.id }))
        },
      }

    })
    
  };
}
