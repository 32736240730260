export const ToastErrorIcon = (props) => {
  return (
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77212 20.3757C6.34432 21.3394 7.46614 21.8739 8.9343 21.8739H18.0594C19.5276 21.8739 20.6494 21.3394 21.2216 20.3832C21.7938 19.4119 21.711 18.1772 20.9957 16.8897L16.4332 8.6756C15.6803 7.32038 14.6413 6.57501 13.4969 6.57501C12.3525 6.57501 11.3135 7.32038 10.5606 8.6756L5.99799 16.8822C5.28273 18.1697 5.20744 19.4119 5.77212 20.3757ZM11.5469 9.22522C12.0965 8.24645 12.7891 7.70436 13.4969 7.70436C14.2046 7.70436 14.8973 8.24645 15.4469 9.22522L20.017 17.4393C20.5289 18.3654 20.6193 19.2011 20.2579 19.811C19.8965 20.4208 19.121 20.7521 18.067 20.7521H8.94182C7.88777 20.7521 7.10475 20.4208 6.75089 19.811C6.39703 19.2011 6.47984 18.3579 6.99182 17.4393L11.5469 9.22522ZM13.4965 16.2951C13.1878 16.2951 12.9318 16.0391 12.9318 15.7304V11.9659C12.9318 11.6572 13.1878 11.4012 13.4965 11.4012C13.8052 11.4012 14.0612 11.6572 14.0612 11.9659V15.7304C14.0612 16.0391 13.8052 16.2951 13.4965 16.2951ZM13.4965 18.742C13.4513 18.742 13.3986 18.7345 13.3459 18.727C13.3008 18.7194 13.2556 18.7044 13.2104 18.6818C13.1652 18.6667 13.1201 18.6441 13.0749 18.614L12.962 18.5237C12.8264 18.3806 12.7436 18.1849 12.7436 17.9891C12.7436 17.7934 12.8264 17.5976 12.962 17.4546L13.0749 17.3642C13.1201 17.3341 13.1652 17.3115 13.2104 17.2965C13.2556 17.2739 13.3008 17.2588 13.3459 17.2513C13.4438 17.2287 13.5492 17.2287 13.6396 17.2513C13.6923 17.2588 13.7374 17.2739 13.7826 17.2965C13.8278 17.3115 13.873 17.3341 13.9181 17.3642L14.0311 17.4546C14.1666 17.5976 14.2494 17.7934 14.2494 17.9891C14.2494 18.1849 14.1666 18.3806 14.0311 18.5237L13.9181 18.614C13.873 18.6441 13.8278 18.6667 13.7826 18.6818C13.7374 18.7044 13.6923 18.7194 13.6396 18.727C13.5944 18.7345 13.5417 18.742 13.4965 18.742Z"
        fill="#E4291D"
      />
    </svg>
  );
};
