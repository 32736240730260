import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataListActions } from './useDataListActions';
import { resetFilters, sidebarSelector } from '../redux/slicers/admin/sidebarSlicer';
import { filterSelector } from '../redux/slicers/filters/filterSlicer';
import { FILTER } from '../components/admin/curator/CuratorFilter/CuratorFilter';
import { useUserContext } from '../contexts/UserContext';
import { isEmpty } from 'lodash';
import { useMountedState } from 'react-use';
import { selectSearchValue } from '../redux/slicers/search/searchSlicer';
import { addToCollectionActions } from '../redux/slicers/addToCollectionPopupSlicer';

export const useDataList = ({
  selector,
  resetAction,
  getAction,
  itemDeleteText,
  itemsDeleteText,
  requestParams,
  disableFilters,
  filterByKey,
  defaultSort,
  useGlobalFilter,
  useGlobalSearch,
  defaultBrands,
  defaultCreatedBy,
  defaultFilterBy,
  skipBrandFilter,
  hookName,
  createdByKey = 'created_by',
}) => {
  const dispatch = useDispatch();
  const fetchedOnceRef = useRef(false);
  const fetchedOnce = fetchedOnceRef.current;
  const [mounted, setMounted] = useState(false)

  const { data, perPage, page, list, loading, hasData, filterValue, dataLoadedOnce, filter } =
    useSelector(selector);
  const globalSearch = useSelector(selectSearchValue);
  const selectedMembers = useSelector((state) => filterSelector(state).members);
  const { filterValues } = useSelector(sidebarSelector);

  const {
    selected,
    sortBy,
    filterBy,
    search,
    hasSelected,
    deleteDisabled,
    allSelected,
    anySelected,

    onDelete,
    toggleAll,
    resetSelection,
    toggleItem,
    onSortChange,
    onFilterByChange,
    onSearchChange,
  } = useDataListActions({
    items: list,
    itemDeleteText,
    itemsDeleteText,
    defaultSort,
    defaultFilterBy,
    useGlobalFilter,
  });
  const hasMore = data.current_page_number < data.total_pages;
  const { currentUser } = useUserContext();
  const organisationId = currentUser.organization;

  const _filterValue = filterValue && !useGlobalFilter ? filterValue : filterValues;

  const hasFilters = Boolean(filter?.filteroptions?.length);
  const searchActive = search && !_filterValue.length && hasFilters;

  const fetchData = (page) => {
    if (!mounted) return;
    fetchedOnceRef.current = true;

    const filterParams = filterToParams(_filterValue, { organisationId, userId: currentUser.id });
    if (!filterParams.brands && defaultBrands) {
      filterParams.brands = defaultBrands;
    }

    // created_by
    let created_by = defaultCreatedBy;
      if (selectedMembers.length) {
        created_by = selectedMembers.map(m => m.id);
      } else if (requestParams?.created_by) {
        created_by = requestParams?.created_by;
      }

    // filter by
    let filterByValue;
    if (filterBy?.key) {
      filterByValue = {
        [filterBy.key]: typeof filterBy.apiValue === 'boolean' ? filterBy.apiValue : filterBy.value,
      };
    } else if (filterByKey) {
      filterByValue = { [filterByKey]: typeof filterBy === 'object' ? filterBy.value : filterBy };
    }

    const requestParamsCopy = { ...requestParams }
    delete requestParamsCopy.created_by;
    const searchStr = useGlobalSearch ? globalSearch : search

    dispatch(
      getAction({
        ...(disableFilters ? {} : filterParams),
        page,
        per_page: perPage,
        search: searchStr,
        sort: typeof sortBy === 'object' ? sortBy.value : sortBy,
        skipBrandFilter,
        ...filterByValue,
        ...requestParamsCopy,
        ...(created_by ? { [createdByKey]: created_by } : {}),
      })
    );
  };

  // useEffect(() => {
  //   if (!fetchedOnce) return;
  //   dispatch(resetFilters());
  // }, [filterBy]);

  useEffect(() => {
    if (!fetchedOnce) return;

    if (useGlobalFilter) {
      dispatch(resetFilters());
    }
  }, [organisationId]);

  useEffect(() => {
    dispatch(resetAction());

    fetchData(1);
  }, [
    _filterValue,
    sortBy,
    filterBy,
    search,
    selectedMembers,
    requestParams,
    getAction,
    organisationId,
    mounted,
    useGlobalSearch && globalSearch,
  ]);

  useEffect(() => {
    setMounted(true)
  }, [])

  const fetchMoreData = () => {
    fetchData(page + 1);
  };

  const fetchFirstPage = () => {
    dispatch(resetAction());
    fetchData(1);
  };

  return {
    data,
    list,
    loading: loading || !fetchedOnce,
    fetchMoreData,
    hasMore,
    total: data.count,
    hasData,
    anySelected,
    filterBy,
    sortBy,
    dataLoadedOnce,

    selected,
    onDelete,
    toggleAll,
    toggleItem,
    hasSelected,
    deleteDisabled,
    allSelected,
    onSortChange,
    onFilterByChange,
    onSearchChange,
    fetchFirstPage,
    refetchList: fetchFirstPage,
    resetSelection,
  };
};

export const useSimpleDataList = ({
  selector,
  resetAction,
  getAction,
  itemDeleteText,
  itemsDeleteText,
  requestParams,
  filterSelector,
  skip,
  actions,
}) => {
  const dispatch = useDispatch();

  const { data, perPage, page, list, prevList, loading, hasData, dataLoadedOnce, refetchCounter } =
    useSelector(selector);
  const { currentUser } = useUserContext();
  const organisationId = currentUser?.organization;
  const {
    selected,
    sortBy,
    filterBy,
    search,
    hasSelected,
    deleteDisabled,
    allSelected,

    onDelete,
    toggleAll,
    toggleItem,
    onSortChange,
    onSearchChange,
    onFilterByChange,
  } = useDataListActions({
    items: list,
    itemDeleteText,
    itemsDeleteText,
  });
  const hasMore = data.current_page_number < data.total_pages;

  const fetchData = async (page) => {
    return await dispatch(
      getAction({ page, perPage, search, sort: sortBy, project_type: filterBy, ...requestParams })
    );
  };

  useEffect(() => {
    if (skip) return;

    dispatch(resetAction());
    fetchData(1);
  }, [sortBy, filterBy, search, requestParams, skip, organisationId]);

  const fetchMoreData = () => {
    return fetchData(page + 1);
  };

  useEffect(() => {
    if (refetchCounter > 0) {
      // at each counter change we refresh the whole list
      dispatch(resetAction());
      fetchData(1);
    }
  }, [refetchCounter]);

  async function loadAllPages(currentPage = page) {
    if (currentPage >= data.total_pages) return;
    const nextPage = currentPage + 1;
    await fetchData(nextPage);
    loadAllPages(nextPage)
  }

  const onPageChange = (data) => {
    const page = typeof data === 'number' ? data : data.selected + 1;

    fetchData(page);
  };

  const onPerPageChange = (perPage) => {
    dispatch(actions.setPerPage(perPage));
  };

  return {
    data,
    list,
    prevList,
    loading,
    fetchMoreData,
    hasMore,
    search,
    total: data.count,
    totalPages: data.total_pages,
    hasData,
    dataLoadedOnce,
    page,
    perPage,

    selected,
    onDelete,
    toggleAll,
    toggleItem,
    hasSelected,
    deleteDisabled,
    allSelected,
    onSortChange,
    onFilterByChange,
    onSearchChange,
    onPageChange,
    fetchData,
    loadAllPages,
    onPerPageChange,
  };
};

export const getFilterOrder = (values) => {
  const findValue = (type) => {
    const index = values.findIndex(v => v.startsWith(type));
    return index === -1 ? Infinity : index;
  }

  const baseOrder = [
    { name: 'collections', order: findValue('collection')},
    { name: 'brands', order: findValue('brand')},
    { name: 'options', order: findValue('option')},
  ]
  
  const order = baseOrder.sort((a, b) => a.order - b.order )
  .map(a => a.name)


  return { order, baseOrder};
};

export const filterToParams = (filterValues, { organisationId, userId }) => {
  let filterParams = {};
  const options = {};
  let collections = ''
  let brands = '';

  filterValues.forEach((value) => {
    if (value === 'favourite') {
      filterParams.is_favourite = true;
      return;
    }

    if (value.startsWith('option_')) {
      const [, optionId, labelId] = value.split('_');
      const optionKey = `fl_${optionId}`;

      if (!options[optionKey]) {
        options[optionKey] = labelId;
        return;
      }

      options[optionKey] += `,${labelId}`;
      return;
    }

    if (value.startsWith('brand_')) {
      const [, brandId] = value.split('_');

      if (!brands) {
        brands = brandId;
        return;
      }

      brands += `,${brandId}`;
      return;
    }

    if (value.startsWith('collection_')) {
      const [, collectionId] = value.split('_');

      if (!collections) {
        collections = collectionId;
        return;
      }

      collections += `,${collectionId}`;
      return;
    }

    if (value === FILTER.MY_3D_MODELS) {
      filterParams.brands = filterParams.brands
        ? `${filterParams.brands},${organisationId}`
        : organisationId;
    }

    if (value === FILTER.MY_TEXTURES) {
      filterParams.is_map = false;
      filterParams.created_by = userId;
      return;
    }

    if (value === FILTER.ALL_TEXTURES) {
      filterParams.is_map = false;
      return;
    }

    if (value === FILTER.MY_UPLOADS) {
      filterParams.created_by = userId;
      filterParams.is_map = true;
      return;
    }

    if (value === FILTER.ALL_UPLOADS) {
      filterParams.is_map = true;
      return;
    }
  });

  // we want to keep selection order of filters in api query :(
  const { order } = getFilterOrder(filterValues);
  const tempParams = { collections, options, brands};
  order.reverse().forEach(name => {
    if (!tempParams[name]) return;

    if (name === 'options') {
      filterParams = {
        ...tempParams[name],
        ...filterParams,
      }
    } else {
      filterParams = {
        [name]: tempParams[name],
        ...filterParams,
      }
    }
  })
  


  return filterParams;
};

export const useThumbnailCardDataList = (config) => {
  const dispatch = useDispatch();
  const data = useDataList(config);

  const selectedList = data.list.filter((item) => data.selected.includes(item.id));
  const list = data.list.map((item) => ({ ...item, selected: data.selected.includes(item.id) }));

  const addToCollection = () => {
    dispatch(addToCollectionActions.add({
      items: selectedList,
      modelType: config.modelType,
    }))
  }

  return {
    ...data,
    addToCollection,
    list,
    selectedList,
  };
};
