
import { getCollectionListApi } from "../api/collections";
import { collectionListActions, collectionListSelector } from "../redux/slicers/collectionListSlicer";
import { useSimpleDataList } from "./useDataList";

export const useCollectionList = ({ requestParams, skip } = {}) => {
  const data = useSimpleDataList({
    selector: collectionListSelector,
    resetAction: collectionListActions.resetList,
    actions: collectionListActions,
    getAction: getCollectionListApi,
    requestParams,
    skip,
  });

  return data;
};
