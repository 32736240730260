export const PERMISSIONS = {
  PURCHASE_CREDIT: {
    codename: 'lf_invite_internal_can_purchase_credits',
    warningMessage: 'You do not have permission to add credits.',
  },
  CAN_INVITE_MEMBERS: {
    codename: 'lf_invite_internal_can_invite_members',
    warningMessage: 'You do not have permission to invite members.',
  },
  UPLOAD_TEXTURE: {
    codename: 'lf_invite_internal_can_upload_textures',
    warningMessage: 'You do not have permission to uplaod texture.',
  },
};

export const PERMISSION = Object.keys(PERMISSIONS).reduce((acc, key) => {
  acc[key] = key
  return acc
}, {});