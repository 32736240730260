import { useUserContext } from "../contexts/UserContext";
import { apiSwitchOrg } from "../helpers/api";
import { setLocalStorage } from "../helpers/jsHelper";
import { errorToast } from "../helpers/toastHelper";
import { useCheckCuratorActive } from "./useCuratorActive";

export const useSwitchToOrganization = () => {
  const userCtx = useUserContext()
  const { isCuratorActive } = useCheckCuratorActive();

  const switchToOrganization = async (orgId, { onSuccess } = {}) => {
    if(!orgId) return;

    const curatorActive = await isCuratorActive();
    if (curatorActive) {
      errorToast("You can not switch an organization while working on an ongoing project. Please close or save the project to change the organization");
      return;
    }

    const res = await apiSwitchOrg(orgId);
    setLocalStorage('token', res.access);
    setLocalStorage('refresh_token', res.refresh);
    setLocalStorage('current_user', res.info);
    userCtx.setToken(res.access);
    userCtx.setRefreshToken(res.refresh);
    userCtx.setCurrentUser(res.info);
    
    onSuccess?.();
  }

  return { switchToOrganization }
}