import { createSlice } from '@reduxjs/toolkit';

const propDetailsPopup = createSlice({
  name: 'propDetailsPopup',
  initialState: {
    data: null,
  },
  reducers: {
    show: (state, { payload }) => {
      state.data = payload
    },
    close: (state) => {
      state.data = null;
    },
  },
});

export const propDetailsPopupActions = propDetailsPopup.actions;
export const propDetailsPopupReducer = propDetailsPopup.reducer;
export default propDetailsPopupReducer;

export const selectPropDetailsPopupData = (state) => state.propDetailsPopup.data