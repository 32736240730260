import {
  API_ROOT_URL,
  GET_PROJECT_API,
  GET_PROPS_API,
  GET_ROOMS_API,
  PROJECT_UPDATE,
} from '../../config/constant/apiConstant';
import { GET_INSPIRATION } from '../../helpers/api';
import { createListApiRequest } from '../../helpers/apiHelper';
import api from '../../helpers/axiosHelper';
import { objectToSearchParams, uniqueId } from '../../helpers/jsHelper';
import { setPropsData } from '../../redux/slicers/admin/curatorSlicer';
import { paginatedRoomsActions } from '../../redux/slicers/admin/paginatedRooms';
import { setProps } from '../../redux/slicers/admin/propsSlicer';
import { roomActions, setRooms } from '../../redux/slicers/admin/roomsSlicer';
import { setLables } from '../../redux/slicers/admin/sidebarSlicer';
import {
  myprojectsActions,
  myProjectsActions,
  setMyProjectsData,
} from '../../redux/slicers/my-projects/myProjectsSlicer';
import {
  fetchMyProjectDetailsFailed,
  fetchMyProjectDetailsStart,
  fetchMyProjectDetailsSuccess,
  updateProject,
} from '../../redux/slicers/myProjectDetails/myProjectDetailsSlicer';
import { setMoreSearchResult, setSearchResult } from '../../redux/slicers/search/searchSlicer';
import { createDeleteApiAction, createGetListAction, createUpdateApiAction } from '../products';

export const ProjectsSearchApi =
  (searchVal, page = 1, limit = 20) =>
  (dispatch) => {
    api()
      .root(API_ROOT_URL)
      .get(`${GET_PROJECT_API}?search=${searchVal}&page=${page}&per_page=${limit}`)
      .success((data) => {
        if (page === 1) {
          dispatch(setSearchResult(data));
        } else {
          dispatch(setMoreSearchResult(data));
        }
      })
      .error((error) => {
        // console.log("texture search data err:", error)
        // dispatch(setSearchResult(error))
      })
      .send();
  };

export const getProjectsApi = (arr, type) => (dispatch) => {
  let sort = type ? arr : 'sort=-created_at';

  api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}?&${arr}&${sort}`)
    .success((data) => {
      dispatch(setMyProjectsData(data));
      dispatch(setLables(data?.sidefilter));
    })
    .error((error) => {})
    .send();
};

export const getProjectsApiV2 = createGetListAction({
  endpoint: GET_PROJECT_API,
  actions: myprojectsActions,
  setGlobalSidebarFilters: true,
});

export const projectUpdateApi = createUpdateApiAction({
  actions: myprojectsActions,
  endpointUrl: GET_PROJECT_API,
})

export const deleteProjectAPI = createDeleteApiAction({
  actions: myprojectsActions,
  endpointUrl: GET_PROJECT_API,
  successMessage: 'The File is deleted successfully',
})


export const getProjectApi = (id) => (dispatch) => {
  dispatch(fetchMyProjectDetailsStart());

  api()
    .root(API_ROOT_URL)
    .get(`${GET_PROJECT_API}${id}/`)
    .success((data) => {
      dispatch(fetchMyProjectDetailsSuccess(data));
    })
    .error((error) => {
      dispatch(fetchMyProjectDetailsFailed(error));
    })
    .send();
};

export const deleteProjectsApi = (id) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .delete(`${GET_PROJECT_API}${id}`)
    .success((data) => {
      dispatch(getProjectsApi());
    })
    .error((error) => {})
    .send();
};
export const editProjectsApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .patch(GET_PROJECT_API)
    .data(formData)
    .success((res) => {
      dispatch(getProjectsApi());
    })
    .error((error) => {})
    .send();
};

export const getRoomsApi = (arr) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_ROOMS_API}?&${arr}&sort=-created_at`)
    .success((res) => {
      // console.log("get rooms api")

      dispatch(setRooms(res));
      dispatch(setLables(res?.sidefilter));
    })
    .error(() => {})
    .send();
};

export const getRoomsPageListApi = createGetListAction({
  endpoint: GET_ROOMS_API,
  actions: paginatedRoomsActions,
  setGlobalSidebarFilters: true,
});

export const getInspirationsPageListApi = createGetListAction({
  endpoint: GET_INSPIRATION,
  actions: paginatedRoomsActions,
  setGlobalSidebarFilters: true,
});

export const roomUpdateApi = createUpdateApiAction({
  actions: paginatedRoomsActions,
  endpointUrl: GET_ROOMS_API,
})


export const getPropsApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_PROPS_API}?sort=-created_at&per_page=1000`)
    .success((res) => {
      dispatch(setProps(res));
    })
    .error(() => {})
    .send();
};

export const updateProjectApi =
  ({ id, formData, onSuccess, onError }) =>
  (dispatch) => {
    const API_URL = PROJECT_UPDATE.replace(':projectId', id);

    api()
      .root(API_ROOT_URL)
      .patch(API_URL)
      .data(formData)
      .success((data) => {
        dispatch(updateProject(data));
        onSuccess(data);
      })
      .error((error) => {
        onError(error);
      })
      .send();
  };
