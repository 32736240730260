import { dispatcher } from '../../../../helpers/projectHelper';
import { updateMetadataTitle } from '../../../../redux/slicers/admin/curatorMetadataSlicer';
import { getAppState } from '../../../../redux/store';

export class UndoRedoMetadata {
  constructor({ undoRedoInstance, unityMetadata }) {
    this.unityMetadata = unityMetadata;
    this.undoRedo = undoRedoInstance;

    this.init();
  }

  init = () => {

    this.addMetadata = this.undoRedo.createAction(({ title, objectName }) => {
      return {
        __ACTION__: 'UNITY_METADATA__ADD',
        title,
        objectName,
        undo: async () => {
          await this.unityMetadata.forceDeleteMetadata(title)
        },
        redo: () => {
          this.undoRedo.unityContext.metadataModule.AddMetadataReceiver({
            metadataName: title,
            objectName,
          });
        },
      };
    }, { pause: true });

    this.deleteMetadata = this.undoRedo.createAction(({ metadata }) => {
      return {
        __ACTION__: 'UNITY_METADATA__DELETE',
        metadata,
        undo: () => {
          this.undoRedo.unityContext.metadataModule.AddMetadataObjectReceiver(metadata)
          this.undoRedo.unityContext.metadataModule.GetRoomMetadataListReceiver();
        },
        redo: async () => {
          await this.unityMetadata.forceDeleteMetadata(metadata)
        },
      };
    }, { pause: true });

    this.changeOrder = this.undoRedo.createAction(({ title, newIndex, oldIndex }) => {
      return {
        __ACTION__: 'UNITY_METADATA__CHANGE_ORDER',
        title,
        newIndex,
        oldIndex,
        undo: () => {
          this.undoRedo.unityContext.metadataModule.UpdateSequenceReceiver(
            title,
            oldIndex,
            newIndex
          );
        },
        redo: () => {
          this.undoRedo.unityContext.metadataModule.UpdateSequenceReceiver(
            title,
            newIndex,
            oldIndex
          );
        },
      };
    });

    this.toggleStatus = this.undoRedo.createAction(({ title, newStatus, oldStatus }) => {
      return {
        __ACTION__: 'UNITY_METADATA__TOGGLE_STATUS',
        title,
        newStatus,
        oldStatus,
        undo: () => {
          this.undoRedo.unityContext.metadataModule.UpdateMetadataStatusReceiver(title, oldStatus);
        },
        redo: () => {
          this.undoRedo.unityContext.metadataModule.UpdateMetadataStatusReceiver(title, newStatus);
        },
      };
    });

    this.changeTitle = this.undoRedo.createAction(({ metadata, newTitle }) => {
      return {
        __ACTION__: 'UNITY_METADATA__CHANGE_TITLE',
        undo: () => {
          this.undoRedo.pause();
          this.undoRedo.unityContext.metadataModule.EditMetadataReceiver(newTitle, metadata.title);
          dispatcher(updateMetadataTitle({ title: newTitle, newTitle: metadata.title }));
          this.undoRedo.resume();
        },
        redo: () => {
          this.undoRedo.pause();
          this.undoRedo.unityContext.metadataModule.EditMetadataReceiver(metadata.title, newTitle);
          dispatcher(updateMetadataTitle({ title: metadata.title, newTitle }));
          this.undoRedo.resume();
        },
      };
    });
  };
}
