/* eslint-disable no-debugger */
import api, { apiErrorHandler } from './axiosHelper';
import { getPerPageCount, objectToFormData, objectToSearchParams, uniqueId } from './jsHelper';
import { successToast, errorToast } from './toastHelper';
import _, { shuffle } from 'lodash';
import { MODULE_TYPES } from '../constants/constants';
import {
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_ROOMS_DETAILS,
  COLLECTION_ID,
  INVITE_ROUTE,
  RENDER_DETAILS,
} from '../constants/routePathConstants';

export const API_ROOT_URL = process.env.REACT_APP_API_BASE_URL;

//auth
export const LOGIN_API = '/users/api/v3/login/';
export const GOOGLE_LOGIN_API = '/users/api/v3/login/social/';
export const CAROUSEL_API = '/users/api/v3/images-carrousel/';
export const REGISTER_API = '/users/api/v3/register/';
export const VERIFY_OTP_API = '/users/api/v3/verify-email-otp/';
export const RESEND_EMAIL_API = '/users/api/v3/resend-email-for-verification/';
export const FORGOT_API = '/users/api/v3/forgot-password/';
export const PROFESSION_API = '/users/api/v3/profession/';
export const INDUSTRY_API = '/users/api/v3/industry/';
export const PLATFORM_API = '/users/api/v3/usecase/';
export const TERMS_API = '/tnc/latest/';
export const TERMS_ACCEPT_API = '/tnc/accept/';
export const USER_PREFERENCE_API = '/users/api/v3/user-preferences/';
export const ACCOUNT_STATUS_API = '/users/api/v3/check-account-status/';
export const LINK_VERIFY_API = '/users/api/v3/verify-email-with-link/';
export const CREATE_PASSWORD_API = '/users/api/v3/reset-password-confirmation/';
export const UPDATE_USER_PROFILE_API = '/users/api/v3/user-details/';
export const GET_ACCESS_REFRESH_TOKEN = '/users/v3/api/login/refresh/';
export const GLOBAL_SEARCH_API = '/organization/api/v3/search-api/';
export const AFTER_REGISTER_API = '/users/api/v3/after-register/';
export const REGISTER_INVITE_API = '/organization/api/v3/register-invite/';
export const LOGOUT_API = '/users/api/v3/logout/';

//curator
export const ROOM_UPDATE = '/rooms/api/v3/room-detail/:roomId/';
export const ROOM_LAYOUT_API = '/rooms/api/v3/layout-detail/';
export const PROPS_API = '/assetbundles/api/v3/products/detail/';
export const PROPS_REPLACE_API = '/assetbundles/api/v3/products/sort/';
export const UPDATE_PROPS_API = '/assetbundles/api/v3/products/detail/:productId';
export const PROP_RENDER_IMAGE_API = '/organization/api/v3/product-renders/';
export const ROOM_DETAILS_API = '/rooms/api/v3/room-detail/';
export const CREATE_ROOM = '/rooms/api/v3/room-detail/';
export const TEXTURE_DETAILS_API = '/texture/api/v3/texture/detail/';
export const DEFAULT_TEXTURE_DETAILS_API = '/texture/api/v3/texture/lf_default/';
export const GET_SETTING_API = '/users/api/v3/get-user-curator-settings/';
export const EDIT_SETTING_API = '/users/api/v3/user-curator-settings-detail/';
export const SIDE_PANEL_OPTIONS = '/organization/api/v3/side-panel-options/';
export const GET_SIDE_PANEL_OPTIONS = '/organization/api/v3/get-side-panel-options/';
export const EXPORT_PROJECT = '/organization/api/v3/save-on-zip-file-on-local/';

export const ORGANISATION_DETAILS_API = '/organization/api/v3/get-all-organization/';

//projects
export const GET_PROJECT_API = '/organization/api/v3/projects/details/';
export const GET_ROOMS_API = '/rooms/api/v3/room-detail/';
export const GET_PROPS_API = '/assetbundles/api/v3/products/detail/';
export const PRODUCT_META_INFO_API = '/assetbundles/api/v3/products-meta-info/detail/';
export const PROJECT_UPDATE = '/organization/api/v3/projects/details/:projectId/';
export const PROJECT_UPLOAD = '/organization/api/v3/project-upload/';

//webexperience

export const WEB_EXPERIENCE_API = '/organization/api/v3/webexperience/';
export const WEB_EXPERIENCE_ROOM_TYPE_API = '/organization/api/v3/webexperience/room-type/';
export const GET_WEB_EXPERIENCE_API = '/organization/api/v3/webexperience/';
export const GET_WEB_EXPERIENCE_PUBLIC_API = '/organization/api/v3/public-webexperience/';
export const WEB_EXPERIENCE_PRODUCT_DETAILS_API =
  '/organization/api/v3/webexperience/product-details/';

//teamsPage
export const GET_MEMBERS_API = '/organization/api/v3/members/details/';
export const SEND_INVITE_API = '/organization/api/v3/send-invite/';
export const GET_PENDING_LIST = '/organization/api/v3/invitation-pending-list/';
export const DELETE_PENDING_INVITATION = '/organization/api/v3/delete-invitation-pending-list/';

//settings
export const GET_USER_DETAILS = '/users/api/v3/user-details/';
export const CHECK_ORG_NAME = '/organization/api/v3/check_org/';
export const CREATE_ORGANIZATION = '/organization/api/v3/details/';

export const PERMISSIONS_API = '/organization/api/v3/permissions/';

// favourite
export const ADD_FAVOURITE_PROJECTS = '/organization/api/v3/my-projects/details/';
export const ADD_FAVOURITE_ROOM = '/rooms/api/v3/room-detail/';

// delete
export const PROJECT_DELETE = '/organization/api/v3/my-projects/details/';
export const PROJECT_MULTI_DELETE = '/organization/api/v3/delete-projects/';
export const ROOM_DELETE = '/organization/api/v3/room-detail/';

// material
// texture
export const GET_MATERIAL = '/organization/api/v3/material/detail/';
export const MATERIAL_API = '/material/api/v3/material/detail/';
export const MATERIAL_TEXTURES_API = '/material/api/v3/get-material-textures/';

// texture
export const GET_TEXTURE = '/organization/api/v3/texture/detail/';
export const TEXTURE_API = '/texture/api/v3/texture/detail/';
export const TEXTURE_BULK_UPLOAD = '/texture/api/v3/texture/bulk-create/';
export const TEXTURE_UPDATE = '/texture/api/v3/texture/detail/';
export const TEXTURE_BULK_UPDATE = '/texture/api/v3/texture/update/';
export const TEXTURE_BULK_DELETE = '/texture/api/v3/delete-textures/';
export const TEXTURE_CHECK_VERSION = '/texture/api/v3/texture/version_check/';
export const DOWNLOAD_TEXTURE = '/texture/api/v3/texture/download/';
export const TEXTURE_IS_MAP_CHECK = '/texture/api/v3/texture/is_map_texture_check/';
export const TEXTURE_MAP_DETAIL = '/texture/api/v3/texture/map/detail/';

// texture admin

export const ADMIN_TEXTURE_API = '/assetbundles/api/v3/texture/products/detail/';

// preset camera (curator)
export const PRESET_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/';
export const PRESET_CAMERA_IMPORT = '/organization/api/v3/import_camera_angle/';
// export const GET_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const CREATE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const UPDATE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'
// export const DELETE_CAMERA_ANGLE = '/rooms/api/v3/render-camera-angle/'

// project camera (curator)
export const PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const GET_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const CREATE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const UPDATE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';
export const DELETE_PROJECT_CAMERA_ANGLE = '/organization/api/v3/projects_camera_angle/details/';

// images (curator) - render

// rendering-system/api/v3/images-render-detail/?parent_id=148
export const DOWNLOAD_RENDER = '/rendering-system/api/v3/download-renders/';
export const GET_RENDER_PREVIEW_DATA = '/rendering-system/api/v3/project-render-images/';
export const UPDATE_RENDER_PREVIEW = '/rendering-system/api/v3/project-render-images/';
// rendering-system/api/v3/images-render-detail/?parent_id=148
export const GET_CAMERA_INFO = '/rendering-system/api/v3/images-render-detail/';
export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/';
export const DELETE_RENDER = '/rendering-system/api/v3/images-render-detail/';
export const DOWNLOAD_RENDER_IMAGE = '/rendering-system/api/v3/download-renders/';
export const DOWNLOAD_OLD_RENDER_PROJECT_ZIP =
  '/rendering-system/api/v3/get-old-zip-file-from-project-render/:renderId';
// export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/'
// export const CREATE_RENDER = '/rendering-system/api/v3/render-detail/'
export const GET_RENDER_BY_PROJ_RENDER_ID =
  '/rendering-system/api/v3/get-render-based-on-project-render-id/';
export const UPLOAD_RENDER_IMAGES = '/rendering-system/api/v3/upload-images/';

// render & preview//
export const GET_RENDER_PREVIEW = '/rendering-system/api/v3/project-render-images/';
export const GET_DOWNLOAD_RENDER_IMAGES = '/rendering-system/api/v3/download-renders/';
export const GET_RENDER_DATA = '/rendering-system/api/v3/get-render/';
// Filters
export const GET_FILTERS = '/users/api/v3/filters/';
export const CREATE_FILTER_OPTION = '/users/api/v3/filters/';
export const GET_FILTERS_OPTIONS = '/users/api/v3/filters-option/';

export const GET_GLOBAL_TAGS = '/organization/api/v3/global-tags/';

// share
export const CREATE_SHARE = '/share/api/v3/sharingmodels/';

//Transaction
export const GET_USER_BALANCE = '/images/api/organization-balance/';
export const PAYMENT_INTENT = '/images/api/create-payment-intent/';
export const TRANSACTION_HISTORY = '/images/api/organization-transaction-history/';
export const TRANSACTION_HISTORY_DOWNLOAD = '/images/api/download-organization-transaction-history/';
export const TRANSACTION_HISTORY_EMAIL_LIST = '/images/api/transaction-history-member-list/';
export const CHANGE_PASSWORD = '/users/api/v3/change-password/';

//Notification Settings
export const NOTIFICATION_SETTINGS = '/notifications/settings/';

// Notifications
export const GET_NOTIFICATIONS = '/notifications/';
export const GET_UNREAD_NOTIFICATIONS = '/notifications/unread/';
export const MARK_ALL_AS_READ_NOTIFICATIONS = '/notifications/mark-all-as-read/';
export const MARK_UNREAD_NOTIFICATION = '/notifications/mark-as-unread/';
export const MARK_AS_READ_NOTIFICATION = '/notifications/mark-as-read/';
export const DELETE_NOTIFICATION = '/notifications/delete/';
export const DELETE_ALL_NOTIFICATIONS = '/notifications/delete-all/';
export const READ_SPECIFIC_NOTIFICATION = '/notifications/read-specific/?ids=';

// collection
export const COLLECTION_DETAILS_API = '/organization/api/v3/collections/details/';
export const ADD_TO_COLLECTIONS = '/organization/api/v3/multiple/collections/models/';
export const COLLECTION_UPDATE = '/organization/api/v3/collections/details/:collectionId/';
export const COLLECTION_DATA_API = '/organization/api/v3/collections/models/';
export const COLLECTION_MULTI_DELETE = '/organization/api/v3/delete-collections/';

// organization
export const GET_MY_ORGANIZATIONS = '/organization/api/v3/my-orgs/';
export const SWITCH_ORG = '/users/api/v3/change-org/';
export const CHECK_MEMBER_PERMISSIONS = '/organization/api/v3/check-member-permisions/';

// trash
export const TRASH_LIST = '/organization/api/v3/trash-list/';
export const RESTORE_ROOM = '/organization/api/v3/restore/rooms/';
export const RESTORE_TEXTURE = '/organization/api/v3/restore/textures/';
export const RESTORE_UPLOADS = '/organization/api/v3/restore/uploads/';
export const RESTORE_PROJECT = '/organization/api/v3/restore/projects/';
export const RESTORE_PROJECT_PREVIEW = '/organization/api/v3/restore/project_previews/';
export const RESTORE_COLLECTION = '/organization/api/v3/restore/collection/';
export const FOREVER_ROOM_DELETE = '/organization/api/v3/hard-delete/rooms/';
export const FOREVER_TEXTURE_DELETE = '/organization/api/v3/hard-delete/textures/';
export const FOREVER_UPLOADS_DELETE = '/organization/api/v3/hard-delete/uploads/';
export const FOREVER_PROJECT_DELETE = '/organization/api/v3/hard-delete/projects/';
export const FOREVER_PROJECT_PREVIEW_DELETE = '/organization/api/v3/hard-delete/project_previews/';
export const FOREVER_COLLECTION_DELETE = '/organization/api/v3/hard-delete/collection/';
export const EMPTY_TRASH = '/organization/api/v3/hard-delete/empty-trash/';

// light
export const LIGHT_API = '/organization/api/v3/environment-light-detail/';

// inspiration
export const GET_INSPIRATION = '/organization/api/v3/inspiration/';
// Shared Files
export const GET_SHARED_FILES = '/share/api/v3/sharingmodels/';
export const GET_MEMBERS_LIST_API = '/share/api/v3/all-sharing-member-details/';
export const DELETE_SHARED_MODEL = (id) => `/share/api/v3/sharingmodels/${id}/`;
export const DELETE_SHARED_DETAILS = (id) => `/share/api/v3/sharingdetails/${id}/`;
export const GET_ORG_LIST = '/share/api/v3/search-organization-details/';
export const SHARE_MODEL = '/share/api/v3/sharingmodels/';
export const SHARE_MULTIPLE_MODEL_TYPE = '/share/api/v3/multiple-sharingmodels/';
export const GET_PERMISSION_FOR_SHARE = '/share/api/v3/sharing-owner-permissions/';
export const GET_SHARED_FILES_TO_MEMBER_LIST = '/share/api/v3/sharing-member-details/';
export const REMOVE_USER_FROM_SHARED_FILE = '/share/api/v3/remove-user-to-sharing/';

export const GET_SEARCH_MEMBER_DETAIL = '/share/api/v3/search-member-details/';
export const GET_SEARCH_MEMBER_ORG_DETAIL = '/share/api/v3/search-organization-details/';
export const getDataList = async (apiCallback) => {
  let response = await apiCallback();
  return response;
};


function apiPatch(
  URL,
  data,
  label = null,
  successMessage = null,
  errorMessage = null,
  isFormData = true
) {
  let formData = data;
  if (isFormData) {
    formData = objectToFormData(data);
  }
  return api()
    .root(API_ROOT_URL)
    .patch(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiDelete(URL, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .delete(URL)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiMultiDelete(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .delete(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiMultiRestore(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .patch(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiMultiPost(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .post(URL)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiPut(URL, formData, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .put(URL)
    .data(formData)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiGet(URL, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .get(URL)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiGetBlob(URL, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .getBlob(URL)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiPostBlob(URL, payload, label = null, successMessage = null, errorMessage = null) {
  return api()
    .root(API_ROOT_URL)
    .postBlob(URL, payload)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

function apiPost(
  URL,
  payload,
  label = null,
  successMessage = null,
  errorMessage = null,
  isFormData = true,
  callback
) {
  let formData = payload;
  const isPayloadFormData = formData instanceof FormData;
  if (isFormData && !isPayloadFormData) {
    formData = objectToFormData(payload);
  }
  return api()
    .root(API_ROOT_URL)
    .post(URL)
    .data(formData)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      if (callback) {
        callback(data);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      // apiErrorHandler(error, label);
      if (callback) {
        callback(error);
      }
      return error;
    })
    .authFail401((res) => {})
    .send();
}

// commenting because require some testing
// .send((...res) => {
//   const message = res[0].data?.message;
//   errorToast(typeof(message) === "string" ? message : message?.detail);
// });

export function apiFilterLabels(query = null, successMessage = null, errorMessage = null) {
  const URL = query !== null ? `${GET_FILTERS}?${query}` : GET_FILTERS;
  return apiGet(URL, 'apiFilterLabels', successMessage, errorMessage);
}

export function apiFilterOptions(query = null, successMessage = null, errorMessage = null) {
  const URL = query !== null ? `${GET_FILTERS_OPTIONS}?${query}` : GET_FILTERS;
  return apiGet(URL, 'apiFilterOptions', successMessage, errorMessage);
}

export function apiTeamMembers(payload, search) {
  const searchParams = objectToSearchParams(payload);
  const API_URL = `${GET_MEMBERS_API}?${searchParams}&search=${search || ''}`;
  return apiGet(API_URL, 'apiTeamMembers');
}

export const apiEditTeamMember = async (
  id,
  payload,
  successMessage = 'Member details updated successfully',
  errorMessage = 'There was an error while updating details'
) => {
  return await apiPatch(
    `${GET_MEMBERS_API}${id}/`,
    payload,
    'apiEditTeamMember',
    successMessage,
    errorMessage,
    false
  );
};

export const apiSendInvite = async (
  payload,
  successMessage = 'Member invited successfully',
  errorMessage = null
) => {
  return await apiPost(
    SEND_INVITE_API,
    payload,
    'apiSendInvite',
    successMessage,
    errorMessage,
    false
  );
};

export const apiDeleteInvite = async (
  id,
  successMessage = 'Invitation deleted successfully',
  errorMessage = 'something went wrong'
) => {
  return await apiDelete(
    `${DELETE_PENDING_INVITATION}${id}/`,
    'apiDeleteInvite',
    successMessage,
    errorMessage
  );
};

export function apiGetPendingInviteTeamMember(query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${GET_PENDING_LIST}?${query}`,
    'apiGetPendingInviteTeamMember',
    successMessage,
    errorMessage
  );
}

export async function apiGetPermissionsList(params) {
  return await apiGet(PERMISSIONS_API + `?${params}`);
}

export function apiGetTeamMembers(search, userFilter, page = 1) {
  let API_URL = GET_MEMBERS_API + `?${userFilter}=true&page=${page}`;
  API_URL = API_URL + `&search=${search}`;
  return apiGet(API_URL, 'apiTeamMembers');
}

export function apiGetTeamMembersNew(query, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_MEMBERS_API}?${query}`, 'apiGetTeamMembers', successMessage, errorMessage);
}

export async function apiGetGlobalTags({
  search = '',
  page = 1,
  perPage = 20,
  sort = 'name',
} = {}) {
  return await apiGet(
    `${GET_GLOBAL_TAGS}?search=${search}&page=${page}&per_page=${perPage}&sort=${sort}`
  );
}

export function apiCreateGlobalTag({ name }) {
  const payload = new FormData();
  payload.append('name', name);
  return apiPost(GET_GLOBAL_TAGS, payload, 'apiCreateGlobalTag');
}

export async function apiGetOrganisationList({
  search = '',
  page = 1,
  perPage = 20,
  sort = 'name',
} = {}) {
  return await apiGet(
    `${ORGANISATION_DETAILS_API}?search=${search}&page=${page}&per_page=${perPage}&sort=${sort}`
  );
}

export function apiEditProject(
  id,
  payload,
  successMessage = 'Project updated successfully.',
  errorMessage = 'There was an issue updating this project.'
) {
  return apiPatch(
    PROJECT_UPDATE.replace(':projectId', id),
    payload,
    'apiEditProject',
    successMessage,
    errorMessage
  );
}

export function apiEditProjectV2({
  id,
  payload,
  successMessage = 'Project updated successfully.',
  errorMessage = 'There was an issue updating this project.',
}) {
  return apiPatch(
    PROJECT_UPDATE.replace(':projectId', id),
    payload,
    'apiEditProject',
    successMessage,
    errorMessage
  );
}

export function apiEditRenderPreview(
  id,
  payload,
  successMessage = 'Render/Preview updated successfully.',
  errorMessage = 'There was an issue updating this Render/Preview.'
) {
  return apiPatch(
    `${UPDATE_RENDER_PREVIEW}${id}/`,
    payload,
    'apiEditRenderPreview',
    successMessage,
    errorMessage
  );
}

export function apiEditCollection(
  id,
  payload,
  successMessage = 'Collection updated successfully.',
  errorMessage = 'There was an issue updating this Collection.'
) {
  return apiPatch(
    COLLECTION_UPDATE.replace(':collectionId', id),
    payload,
    'apiEditCollection',
    successMessage,
    errorMessage
  );
}

export function apiEditRoom(
  id,
  payload,
  successMessage = 'Room updated successfully.',
  errorMessage = 'There was an issue updating this room.'
) {
  return apiPatch(
    ROOM_UPDATE.replace(':roomId', id),
    payload,
    'apiEditRoom',
    successMessage,
    errorMessage
  );
}

export function apiEditProduct(
  id,
  payload,
  successMessage = 'Item updated successfully.',
  errorMessage = 'There was an issue updating this item.'
) {
  return apiPatch(
    UPDATE_PROPS_API.replace(':productId', `${id}/`),
    payload,
    'apiEditRoom',
    successMessage,
    errorMessage
  );
}

export function apiToggleFavorite(id, is_favourite, path) {
  let payload = {
    is_favourite: is_favourite,
  };
  let functionCallback;
  switch (path) {
    case MODULE_TYPES.PROJECT:
    case MODULE_TYPES.PROJECTS:
      functionCallback = apiEditProject;
      break;
    case MODULE_TYPES.TEMPLATE:
    case MODULE_TYPES.TEMPLATES:
    case MODULE_TYPES.ROOM:
    case MODULE_TYPES.ROOMS:
      functionCallback = apiEditRoom;
      break;
    case MODULE_TYPES.PROP:
    case MODULE_TYPES.PROPS:
    case MODULE_TYPES.PRODUCT:
    case MODULE_TYPES.PRODUCTS:
      functionCallback = apiEditProduct;
      break;
    case MODULE_TYPES.COLLECTION:
    case MODULE_TYPES.COLLECTIONS:
      functionCallback = apiEditCollection;
      break;
    case MODULE_TYPES.PROJECT_RENDER:
    case MODULE_TYPES.RENDER:
    case MODULE_TYPES.RENDERS:
    case MODULE_TYPES.RENDER_DETAIL:
    case MODULE_TYPES.RENDER_PREVIEW:
      functionCallback = apiEditRenderPreview;
      break;
    case MODULE_TYPES.MODEL:
    case MODULE_TYPES.MODELS:
    case MODULE_TYPES.THREEDMODEL:
    case MODULE_TYPES.THREEDMODELS:
      functionCallback = apiEditProduct;
      break;
    case MODULE_TYPES.TEXTURE:
    case MODULE_TYPES.TEXTURES:
    case MODULE_TYPES.UPLOAD:
    case MODULE_TYPES.UPLOADS:
      functionCallback = apiEditTexture;
      break;

    case 'inspirations':
      functionCallback = apiEditInspiration;
      break;

    default:
      break;
  }
  if (_.isFunction(functionCallback)) {
    return functionCallback(id, payload, null, null);
  }
}

export async function apiCreateCollection(payload, successMessage = null, errorMessage = null) {
  return apiPost(
    COLLECTION_DETAILS_API,
    payload,
    'apiCreateCollection',
    successMessage,
    errorMessage,
    true,
    ({ status, message }) => {
      if (status) return;
      const errorKeys = Object.keys(message);
      const key = errorKeys[0];
      if (key) {
        errorToast(message[key][0]);
      }
    }
  );
}

export function apiAddToCollection(formData, successMessage = null, errorMessage = null) {
  return apiMultiPost(
    ADD_TO_COLLECTIONS,
    formData,
    'apiAddToCollection',
    successMessage,
    errorMessage
  );
}

export function apiGetRooms(query, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_ROOMS_API}?${query}`, 'apiGetRooms', successMessage, errorMessage);
}

// export function apiSaveRooms(
//   id,
//   successMessage = null,
//   errorMessage = null
//   ) {
//   return apiGet(`${GET_ROOMS_API}?${query}`, 'apiSaveRooms', successMessage, errorMessage);
// }

export function apiGetCollectionItems(id, query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${COLLECTION_DATA_API}${id}/?${query}`,
    'apiGetCollectionItems',
    successMessage,
    errorMessage
  );
}

export function apiGetCollectionDetail({ id, successMessage = null, errorMessage = null }) {
  return apiGet(
    `${COLLECTION_DETAILS_API}${id}/`,
    'apiGetCollectionDetail',
    successMessage,
    errorMessage
  );
}

export function apiGetCollections(query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${COLLECTION_DETAILS_API}${query ? `?${query}` : ''}`,
    'apiGetCollections',
    successMessage,
    errorMessage
  );
}

// home - inspiration
export function apiGetInspiration(query, successMessage = null, errorMessage = null) {
  if (query !== null && query !== undefined && query !== '') {
    query = `?${query}`;
  } else {
    query = '';
  }
  return apiGet(`${GET_INSPIRATION}${query}`, 'apiGetInspiration', successMessage, errorMessage);
}

export function apiEditInspiration(id, payload, successMessage = null, errorMessage = null) {
  return apiPatch(
    `${GET_INSPIRATION}${id}/`,
    payload,
    'apiEditInspiration',
    successMessage,
    errorMessage
  );
}

// may remove this one altogether in favor of getProjectsApiV2
export function apiGetProjects(query, successMessage = null, errorMessage = null) {
  if (query !== null && query !== undefined && query !== '') {
    query = `?${query}`;
  } else {
    query = '';
  }
  return apiGet(`${GET_PROJECT_API}${query}`, 'apiGetProjects', successMessage, errorMessage);
}

export function apiDeleteProjects(
  payload,
  successMessage = 'These projects have been successfully deleted',
  errorMessage = 'There was an error deleting these projects.'
) {
  return apiMultiDelete(
    PROJECT_MULTI_DELETE,
    payload,
    'apiDeleteProjects',
    successMessage,
    errorMessage
  );
}

export function apiDeleteCollections(
  payload,
  successMessage = 'These collections have been successfully deleted',
  errorMessage = 'There was an error deleting these collections.'
) {
  return apiMultiDelete(
    COLLECTION_MULTI_DELETE,
    payload,
    'apiDeleteCollections',
    successMessage,
    errorMessage
  );
}

export function apiRemoveFromCollections(
  id,
  payload,
  successMessage = 'These items have been successfully removed from this collection',
  errorMessage = 'There was an error removing these items.'
) {
  return apiMultiDelete(
    `${COLLECTION_DATA_API}${id}/`,
    payload,
    'apiRemoveFromCollections',
    successMessage,
    errorMessage
  );
}

export function apiGetMaterials(query, successMessage = null, errorMessage = null) {
  return apiGet(`${MATERIAL_API}?${query}`, 'apiGetMaterials', successMessage, errorMessage);
}

export function apiGetMaterialTextures(query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${MATERIAL_TEXTURES_API}?${query}`,
    'apiGetMaterialTextures',
    successMessage,
    errorMessage
  );
}

export const apiCheckTexture = ({ textureId, version }) => {
  let query = `texture_id=${textureId}`;

  if (version) {
    query += `&texture_ver=${version}`;
  }

  return api()
    .root(API_ROOT_URL)
    .get(`${TEXTURE_CHECK_VERSION}?${query}`)
    .success((data) => {
      return data;
    })
    .notFound404(() => {})
    .send();
};

export const apiTextureIsMapCheck = ({ textureId }) => {
  const payload = new FormData();
  payload.append('is_map', true);
  payload.append('filteroptions', 82);
  payload.append('original_texture_id', textureId);

  return api()
    .root(API_ROOT_URL)
    .post(TEXTURE_IS_MAP_CHECK)
    .data(payload)
    .success((data) => {
      return data;
    })
    .notFound404(() => {})
    .send();
};

export const apiUploadTextureMap = ({ name, project, originalTextureId, filterOptions, file }) => {
  const payload = new FormData();
  payload.append('is_map', true);
  payload.append('name', name);
  payload.append('original_texture_id', originalTextureId);
  payload.append('filteroptions', filterOptions);
  payload.append('low_texture_file', file);

  if (project) {
    payload.append('project', project);
  }
  return apiPost(TEXTURE_MAP_DETAIL, payload, 'apiUploadTextureMap', null, null, false);
};

export const apiUploadTexture = (payload) => {
  return apiPost(TEXTURE_DETAILS_API, payload, 'apiUploadTexture');
};

export const apiGetDefaultTexture = ({ name }) => {
  return apiGet(`${DEFAULT_TEXTURE_DETAILS_API}?name=${name}`, 'apiGetDefaultTexture');
};

export const apiAddDefaultTexture = ({ name, file }) => {
  const payload = objectToFormData({
    name,
    texture_file: file,
  });
  return apiPost(DEFAULT_TEXTURE_DETAILS_API, payload, 'apiAddDefaultTexture');
};

export function apiRestoreRenders(
  payload,
  successMessage = 'These renders/previews have been successfully restored',
  errorMessage = 'There was an error restoring these renders/previews.'
) {
  return apiMultiRestore(
    UPDATE_RENDER_PREVIEW,
    payload,
    'apiRestoreRenders',
    successMessage,
    errorMessage
  );
}

export function apiArchiveRenders(
  payload,
  successMessage = 'These renders have been successfully archived',
  errorMessage = 'There was an error archiving these renders.'
) {
  return apiMultiDelete(
    UPDATE_RENDER_PREVIEW,
    payload,
    'apiArchiveRenders',
    successMessage,
    errorMessage
  );
}

export function apiGetRoomDetails(paramsObj, successMessage = null, errorMessage = null) {
  const params = objectToSearchParams(paramsObj);
  return apiGet(`${ROOM_DETAILS_API}?${params}`, 'apiGetRoomDetails', successMessage, errorMessage);
}

export function apiGetCarouselImages(paramsObj, successMessage = null, errorMessage = null) {
  const params = objectToSearchParams(paramsObj);
  return apiGet(`${CAROUSEL_API}?${params}`, 'apiGetCarouselImages', successMessage, errorMessage);
}

export function apiGetPreviewAndMainRenders(params, successMessage = null, errorMessage = null) {
  return apiGet(
    `${GET_RENDER_PREVIEW_DATA}?${params}`,
    'apiGetPreviewAndMainRenders',
    successMessage,
    errorMessage
  );
}

export function apiGetPreviewAndMainRender(id, successMessage = null, errorMessage = null) {
  return apiGet(
    `${GET_RENDER_PREVIEW_DATA}${id}/`,
    'apiGetPreviewAndMainRender',
    successMessage,
    errorMessage
  );
}

export function apiGetPresetCameraAngle(roomId) {
  return apiGet(`${PRESET_CAMERA_ANGLE}?room_id=${roomId}`);
}

export function apiGetProjectRoomCameraAngle(query = null) {
  query = query !== null ? `?${query}` : '';
  return apiGet(`${PROJECT_CAMERA_ANGLE}${query}`);
}

export function apiGetDownloadRendersImages(query, includePasses, pass) {
  query += includePasses ? `&is_color_pass=${includePasses}` : '';
  // if (includePasses) {
  //   query += '&is_color_pass=true';
  // }
  return apiGetBlob(`${DOWNLOAD_RENDER}?${query}`);
}

export function apiDownloadOldRenderProjectZipFile({ renderId }) {
  return apiGet(DOWNLOAD_OLD_RENDER_PROJECT_ZIP.replace(':renderId', renderId));
}

export function apiGetAcceptTermsAndConditions() {
  return apiGet(TERMS_ACCEPT_API);
}

export function apiGetVerifyEmailLink(token) {
  return apiGet(`${LINK_VERIFY_API}${token}`);
}

export function apiPostRegisterInvite(
  payload,
  token,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    `${REGISTER_INVITE_API}${token}/`,
    payload,
    'apiPostRegisterInvite',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiGetProductMetaInfo({ id }) {
  return apiGet(`${PRODUCT_META_INFO_API}?id=${id}`, 'apiGetProductMetaInfo');
}

export function apiGetProps(params, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_PROPS_API}?${params}`, 'apiGetProps', successMessage, errorMessage);
}

export function apiGetPropsV2({ search, page = 1, brands = [] }) {
  return apiGet(
    `${GET_PROPS_API}?search=${search}&page=${page}&brands=${brands.join(',')}`,
    'apiGetPropsV2'
  );
}

export function apiGetProp(id, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_PROPS_API}${id}/`, 'apiGetProp', successMessage, errorMessage);
}
export function apiGetProductWebExperience({ productId }) {
  return apiGet(`${GET_WEB_EXPERIENCE_API}?product=${productId}`, 'apiGetProductWebExperience');
}

export function apiGetWebExperienceList() {
  return apiGet(`${GET_WEB_EXPERIENCE_API}`, 'apiGetWebExperienceList');
}

export function apiGetWebExperience({ id }) {
  return apiGet(`${GET_WEB_EXPERIENCE_API}${id}/`, 'apiGetWebExperience');
}

export function apiCreateWebExperience({ productId, organizationId, renderIdList, score }) {
  const formData = new FormData();
  formData.append('product', productId);
  formData.append('organization', organizationId);
  formData.append('score', score);
  renderIdList.forEach((id) => formData.append('renders', id));

  return apiPost(
    WEB_EXPERIENCE_API,
    formData,
    'apiCreateWebExperience',
    'Web experience created',
    'Unable to create web experience'
  );
}

export function apiUpdateWebExperience({
  id,
  productId,
  organizationId,
  renderIdList,
  score,
  isPublic,
}) {
  const formData = objectToFormData(
    {
      product: productId,
      organization: organizationId,
      score,
      renders: renderIdList,
      is_public: isPublic,
    },
    {
      separateArrayItems: true,
      ignoreUndefined: true,
    }
  );

  return apiPatch(
    `${WEB_EXPERIENCE_API}${id}/`,
    formData,
    'apiUpdateWebExperience',
    'Web experience updated',
    'Unable to update web experience',
    false
  );
}

export function apiGetWebExperienceRoomType() {
  return apiGet(WEB_EXPERIENCE_ROOM_TYPE_API, 'apiGetWebExperienceRoomType');
}

export function apiGetWebExperiencePublic({ id }) {
  return apiGet(`${GET_WEB_EXPERIENCE_PUBLIC_API}${id}/`, 'apiGetWebExperiencePublic');
}

export function apiGetWebExperienceProductDetails({ id }) {
  return apiGet(`${WEB_EXPERIENCE_PRODUCT_DETAILS_API}${id}/`, 'apiGetWebExperienceProductDetails');
}

export function apiUpdateWebExperienceProductDetails({
  id,
  name,
  sku_id,
  price,
  description,
  dimensions,
  filteroptions,
}) {
  const payload = { name, sku_id, description, price, dimensions, filteroptions };
  const formData = objectToFormData(payload, { ignoreUndefined: true, separateArrayItems: true });

  return apiPatch(
    `${WEB_EXPERIENCE_PRODUCT_DETAILS_API}${id}/`,
    formData,
    'apiGetWebExperienceProductDetails',
    null,
    null,
    false
  );
}

export const apiGetPropRenderImageList = async ({
  props,
  roomType,
  orgId,
  page = 1,
  perPage = 20,
}) => {
  const params = {
    // main_render__main_render__props: props,
    // main_render__type: roomType,
    product: props,
    project_type: roomType,
    project_organization: orgId,
    page,
    per_page: perPage,
  };
  const paramsStr = objectToSearchParams(params);
  // const result = await apiGet(
  //   `/rendering-system/api/v3/project-render-images/?${paramsStr}`,
  //   'apiGetPropRenderImageList'
  // );

  const result = await apiGet(
    `/organization/api/v3/product-renders/?${paramsStr}`,
    'apiGetPropRenderImageList'
  );

  // if (!result?.results) return;

  return result;

  // let imageList = result.results.reduce((images, render) => {
  //   images.push(...render.images);
  //   return images;
  // }, []);

  // const randomOrderImageList = shuffle(imageList);
  // const maxIndex = Math.random() > 0.5 ? Math.floor(Math.random() * imageList.length) : 1;
  // imageList = randomOrderImageList.slice(0, maxIndex);

  // imageList = imageList.map((item) => ({
  //   ...item,
  //   roomType,
  // }));

  // return {
  //   results: imageList,
  //   total_pages: 1,
  //   count: imageList.length,
  //   current_page_number: 1,
  //   next_link: null,
  //   previous_link: null,
  // };
};

// TODO: remove it when api is fixed
export const apiMock1 = async ({ props, roomType, orgId, page = 1, perPage = 10 }) => {
  const params = {
    // project__props: props,
    product: props,
    // project__project_type: roomType,
    room_type: roomType,
    project__owner_organization: orgId,
    page,
    per_page: perPage,
  };
  const paramsStr = objectToSearchParams(params);
  return await apiGet(
    `/rendering-system/api/v3/project-render-images/?${paramsStr}`,
    'apiGetPropRenderImageList'
  );
};

export function apiGetRenderImage({ id }) {
  return apiGet(`${id}/`, 'apiGetRenderImage');
}

// handling google login and manual login differently because server error happens when email is not verified rather than handling the error
export function apiPostGoogleLogin(
  payload,
  isFormData = false,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    GOOGLE_LOGIN_API,
    payload,
    'apiPostGoogleLogin',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostLogin(
  payload,
  isFormData = false,
  successMessage = null,
  errorMessage = null
) {
  let formData = payload;
  try {
    return api()
      .root(API_ROOT_URL)
      .post(LOGIN_API)
      .data(formData)
      .success((data) => {
        // if (successMessage) {
        //   successToast(successMessage);
        // }
        return data;
      })
      .error((error) => {
        console.error('error: ', errorMessage);
        if (errorMessage) {
          errorToast(errorMessage);
        }
        // apiErrorHandler(error, null);
        throw error;
      })
      .badRequest400(() => {})
      .authFail401((res) => {
        console.error('authFail401 res: ', res);
        apiErrorHandler(res, null);
      })
      .send();
  } catch (error) {
    console.error('error: ', error);
    throw error;
  }
}

export function apiPostLogout(successMessage = null, errorMessage = null) {
  return apiPost(LOGOUT_API, null, 'apiPostLogout', successMessage, errorMessage, false);
}

export function apiPostRegister(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    REGISTER_API,
    payload,
    'apiPostRegister',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostVerifyOtp(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    VERIFY_OTP_API,
    payload,
    'apiPostVerifyOtp',
    successMessage,
    errorMessage,
    isFormData
  );
}

// export const LinkVerifyApi = () => (dispatch) => {
//   api().root(API_ROOT_URL).get(LINK_VERIFY_API).success(({status, data, message})=>{
//       setLocalStorage(AUTH_TOKEN, data.access)
//       setLocalStorage(REFRESH_TOKEN, data.refresh)
//       if(status){
//           navigate(CUSTOMIZE_EXPERIENCE)
//           successToast(message)
//       }
//   }).error(()=>{

//   }).send()
// }

export function apiPostResendOtpEmail(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    RESEND_EMAIL_API,
    payload,
    'apiPostVerifyOtp',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostForgotPassword(
  payload,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    FORGOT_API,
    payload,
    'apiPostForgotPassword',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiPostResetPassword(
  payload,
  token,
  isFormData = true,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    CREATE_PASSWORD_API + token + '/',
    payload,
    'apiPostForgotPassword',
    successMessage,
    errorMessage,
    isFormData
  );
}

export function apiGetTNCLatest(params, successMessage = null, errorMessage = null) {
  // const params = objectToSearchParams(paramsObj);
  return apiGet(TERMS_API, 'apiGetTNCLatest', successMessage, errorMessage);
}

export function apiPatchUpdateUserProfile(payload, successMessage = null, errorMessage = null) {
  return apiPatch(
    UPDATE_USER_PROFILE_API,
    payload,
    'apiPatchUpdateUserProfile',
    successMessage,
    errorMessage
  );
}

export function apiPatchAfterRegister(payload, successMessage = null, errorMessage = null) {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    if (_.isArray(value)) {
      value.forEach((element) => formData.append(key, element));
    } else {
      formData.append(key, value);
    }
  });

  return api()
    .root(API_ROOT_URL)
    .patch(AFTER_REGISTER_API)
    .data(formData)
    .success((res) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return res;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      }
      apiErrorHandler(error, 'apiPatchAfterRegister');
    })
    .badRequest400(() => {})
    .send();
}

export function apiGetOrganizationBalance(successMessage = null, errorMessage = null) {
  return apiGet(GET_USER_BALANCE, 'apiGetOrganizationBalance', successMessage, errorMessage);
}

export function apiGetTransactionHistory(successMessage = null, errorMessage = null) {
  return apiGet(TRANSACTION_HISTORY, 'apiGetTransactionHistory', successMessage, errorMessage);
}

export function apiDownloadTransactionHistory({ trans_type, created_at__gte, created_at__lte, created_by_email }, successMessage = null, errorMessage = null) {
  const query = objectToSearchParams({
    trans_type,
    created_at__gte,
    created_at__lte,
    created_by_email,
  })
  return apiGet(`${TRANSACTION_HISTORY_DOWNLOAD}?${query}`, 'apiDownloadTransactionHistory', successMessage, errorMessage);
}

export function apiPostPaymentIntentApi(
  amount,
  paymentType,
  isFormData = false,
  successMessage = null,
  errorMessage = null
) {
  return apiPost(
    PAYMENT_INTENT + `?amount=${amount}&payment_method_types=${paymentType}`,
    {},
    'apiPostPaymentIntentApi',
    successMessage,
    errorMessage,
    false
  );

  // return api()
  //   .root(API_ROOT_URL)
  //   .post(PAYMENT_INTENT + `?amount=${amount}&payment_method_types=${paymentType}`)
  //   .success((data) => {
  //     if (successMessage) {
  //       successToast(successMessage);
  //     }
  //     return data;
  //   })
  //   .error((error) => {
  //     if (errorMessage) {
  //       errorToast(errorMessage);
  //     }
  //     apiErrorHandler(error, 'apiPostPaymentIntent');
  //     return error;
  //   })
  //   .send();
}

export function apiGetIndustryPreferences(
  selectedIndustries,
  successMessage = null,
  errorMessage = null
) {
  return apiGet(
    USER_PREFERENCE_API + `?id=${selectedIndustries.toString()}`,
    'apiGetIndustryPreferences',
    successMessage,
    errorMessage
  );
}

export function apiGetLight(paramsObj, successMessage = null, errorMessage = null) {
  const { id, ...rest } = paramsObj;
  const params = objectToSearchParams(rest);
  return apiGet(
    `${LIGHT_API}${id ? `${id}/` : ''}?${params}`,
    'apiGetLight',
    successMessage,
    errorMessage
  );
}

export async function getProjectsApiV2(params, successMessage = null, errorMessage = null) {
  const searchParams = objectToSearchParams(params);
  const API_URL = `${GET_PROJECT_API}?${searchParams}`;
  const requestId = uniqueId();

  // need to replace this concept so no overloading the server
  // but do it with axios transaction handling instead of reducer
  // dispatch(myProjectsActions.fetchMyProjectsListStart({ requestId }))

  let results = await getDataList(
    apiGet(API_URL, 'getProjectsApiV2', successMessage, errorMessage)
  );
  return results;
}

export const parseBasePathname = (location) => {
  // /rooms/room-templates returns ['', 'rooms', 'room-templates']
  // value should return 'rooms'
  return _.find(location?.pathname.split('/'), (str) => str && str.length > 0);
};

export const parseProductTypePathname = (location) => {
  // /products/3dmodel returns ['', 'products', '3dmodel']
  // value should return '3dmodel'
  return _.chain(location?.pathname.split('/'))
    .filter((str) => str && str.length > 0)
    .last()
    .value();
};
export const createListApiRequest =
  ({ url, onStart, onSuccess, onError, startAction, successAction, errorAction }) =>
  ({ search, sort = '-created_at', perPage, page, ...rest } = {}) =>
  (dispatch) => {
    const searchParams = objectToSearchParams({
      search,
      sort,
      per_page: perPage,
      page,
      ...rest,
    });
    const API_URL = `${url}?${searchParams}`;

    onStart?.(dispatch);
    startAction && dispatch(startAction());

    api()
      .root(API_ROOT_URL)
      .get(API_URL)
      .success((data) => {
        onSuccess?.({
          data,
          dispatch,
          args: {
            search,
            sort,
            perPage,
            page,
          },
        });

        successAction &&
          dispatch(
            successAction({
              page,
              data,
            })
          );
      })
      .error((error) => {
        // TODO: add logger
        console.error(error);
        onError?.(error, dispatch);
        errorAction && dispatch(errorAction(error));
      })
      .send();
  };

export function apiGetIndustries({ search = '', page = 1, ids } = {}) {
  let query = `search=${search}&page=${page}`;

  if (ids) {
    query += `&id=${ids.join(',')}`;
  }

  return apiGet(`${INDUSTRY_API}?${query}`);
}

export function apiCreateIndustry({ name }) {
  return apiPost(INDUSTRY_API, { name }, 'apiCreateIndustry');
}

export function apiGetPlatforms() {
  return apiGet(PLATFORM_API);
}

export function apiGetProfessions() {
  return apiGet(`${PROFESSION_API}?per_page=50`);
}

export function apiCreateProfession({ name }) {
  return apiPost(PROFESSION_API, { name }, 'apiCreateProfession');
}

export function apiCreatePlatform({ name }) {
  return apiPost(`${PLATFORM_API}?per_page=50`, { name }, 'apiCreatePlatform');
}

export function apiGetIndustriesPreferences(selectedIndustries) {
  return apiGet(`${USER_PREFERENCE_API}?id=${selectedIndustries.toString()}`);
}

export function apiGetUserDetails() {
  return apiGet(GET_USER_DETAILS);
}

export function apiUpdateUserDetails(formData) {
  return api()
    .root(API_ROOT_URL)
    .patch(GET_USER_DETAILS)
    .data(formData)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
    })
    .send();
}

export function apiNotificationSettings() {
  return apiGet(NOTIFICATION_SETTINGS);
}

export function apiUpdateNotificationSettings(formData) {
  return api()
    .root(API_ROOT_URL)
    .put(NOTIFICATION_SETTINGS)
    .data(formData)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
    })
    .send();
}

export function apiUpdateOrganization(formData) {
  return api()
    .root(API_ROOT_URL)
    .patch(CREATE_ORGANIZATION)
    .data(formData)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
    })
    .send();
}

export function apiGetOrganizationDetails() {
  return apiGet(CREATE_ORGANIZATION);
}

export function apiGetMembers({ search = '' }) {
  return apiGet(`${GET_MEMBERS_API}?${search}`);
}
export function apiGetPermissions() {
  return apiGet(`${PERMISSIONS_API}?per_page=10000`);
}

export function apiChangePassword(formData) {
  return api()
    .root(API_ROOT_URL)
    .put(CHANGE_PASSWORD)
    .data(formData)
    .success((res) => {
      if (res && res.success) {
        successToast(
          'Your password has been updated. Feel free to change them anytime',
          'Correctly saved'
        );
      }
      return res;
    })
    .error((error) => {
      Object.values(error).forEach((value) => {
        value.forEach((error) => errorToast(error));
      });
    })
    .badRequest400((error) => {
      console.log('error', error);
    })
    .send();
}

export function apiGetRenderBasedOnProjectRenderId(projectRenderId) {
  return apiGet(`${GET_RENDER_BY_PROJ_RENDER_ID}?project_render_id=${projectRenderId}`);
}

export function apiGetAllMyOrgs() {
  return apiGet(GET_MY_ORGANIZATIONS);
}

export function apiSwitchOrg(orgId) {
  return apiPost(`${SWITCH_ORG}?org=${orgId}`, null, '', null, null, false);
}

export function apiCreateOrganization(name, createdBy) {
  return apiPost(CREATE_ORGANIZATION, { name: name, created_by: createdBy }, '', null, null, true);
}

// notifications panel
export function apiGetNotifications(URL, query = null) {
  const finalUrl = query === null ? URL : URL + query;

  return api()
    .root(API_ROOT_URL)
    .get(finalUrl)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiGetAllNotifications(query = null) {
  return apiGetNotifications(GET_NOTIFICATIONS, query);
}
export function apiGetUnreadNotifications(query = null) {
  return apiGetNotifications(GET_UNREAD_NOTIFICATIONS, query);
}
export function apiGetReadSpecificNotifications(query = null) {
  return apiGetNotifications(READ_SPECIFIC_NOTIFICATION, query);
}

export function apiMarkAsReadNotification(query = null) {
  return api()
    .root(API_ROOT_URL)
    .put(`${MARK_AS_READ_NOTIFICATION}${query}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}

export function apiMarkAllAsReadNotifications() {
  return api()
    .root(API_ROOT_URL)
    .put(MARK_ALL_AS_READ_NOTIFICATIONS)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiDeleteAllNotifications() {
  return api()
    .root(API_ROOT_URL)
    .delete(DELETE_ALL_NOTIFICATIONS)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}

export async function apiGetSharedFiles(page, filter = '', sort = '', sharedTo, selectedMember) {
  const response = await apiGet(
    GET_SHARED_FILES +
      `?page=${page}&per_page=${getPerPageCount()}&sort=${sort}&type=${filter}&${sharedTo}&shared_by=${selectedMember.join(
        ','
      )}`
  );
  return response;
}

export function apiGetMembersListV2({ page, isMyOrg, search, perPage }) {
  const query = objectToSearchParams({ page, is_my_org: isMyOrg, search, per_page: perPage });
  return apiGet(`${GET_MEMBERS_LIST_API}?${query}`);
}

export async function apiGetMembersList(page, is_my_org, search) {
  const response = await apiGet(
    GET_MEMBERS_LIST_API + `?page=${page}&is_my_org=${is_my_org}&search=${search}`
  );
  return response;
}
export async function apiGetSearchMembersList(page, search) {
  const response = await apiGet(GET_SEARCH_MEMBER_DETAIL + `?page=${page}&search=${search}`);
  return response;
}
export async function apiGetSearchOrgMembersList(page, search) {
  const response = await apiGet(GET_SEARCH_MEMBER_ORG_DETAIL + `?page=${page}&search=${search}`);
  return response;
}

export function apiDeleteSharedModel(formData, successMsg, errorMsg) {
  let data = {};
  formData.forEach((value, key) => {
    data[key] = value;
  });
  console.log('formData', formData);
  const response = apiMultiDelete(
    DELETE_SHARED_MODEL(data?.id),
    null,
    'Delete',
    successMsg,
    errorMsg
  );
  console.log('delete', response);
  return response;
}

export function apiDeleteSharedDetails(id, successMsg, errorMsg) {
  console.log('deleted', id);
  const response = apiDelete(DELETE_SHARED_DETAILS(id.get('id')), 'Delete', successMsg, errorMsg);
  console.log('delete', response);
  return response;
}
// trash - listing
export function apiGetTrashList(query, label = null, successMessage = null, errorMessage = null) {
  if (query !== '') {
    query = `?${query}`;
  }
  return api()
    .root(API_ROOT_URL)
    .get(`${TRASH_LIST}${query}`)
    .success((data) => {
      if (successMessage) {
        successToast(successMessage);
      }
      return data;
    })
    .error((error) => {
      if (errorMessage) {
        errorToast(errorMessage);
      } else {
        errorToast(error);
      }
      apiErrorHandler(error, label);
    })
    .send();
}

// trash - restore
export function apiTrashRestoreRoom(id) {
  return api()
    .root(API_ROOT_URL)
    .post(`${RESTORE_ROOM}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashRestoreTexture(id) {
  return api()
    .root(API_ROOT_URL)
    .post(`${RESTORE_TEXTURE}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashRestoreUploads(id) {
  return api()
    .root(API_ROOT_URL)
    .post(`${RESTORE_UPLOADS}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashRestoreProject(id) {
  return api()
    .root(API_ROOT_URL)
    .post(`${RESTORE_PROJECT}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashRestoreProjectPreview(id) {
  return api()
    .root(API_ROOT_URL)
    .post(`${RESTORE_PROJECT_PREVIEW}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashRestoreCollection(id) {
  return api()
    .root(API_ROOT_URL)
    .post(`${RESTORE_COLLECTION}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}

// trash - delete
export function apiTrashForeverRoomDelete(id) {
  return api()
    .root(API_ROOT_URL)
    .delete(`${FOREVER_ROOM_DELETE}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashForeverTextureDelete(id) {
  return api()
    .root(API_ROOT_URL)
    .delete(`${FOREVER_TEXTURE_DELETE}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashForeverUploadsDelete(id) {
  return api()
    .root(API_ROOT_URL)
    .delete(`${FOREVER_UPLOADS_DELETE}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashForeverProjectDelete(id) {
  return api()
    .root(API_ROOT_URL)
    .delete(`${FOREVER_PROJECT_DELETE}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashForeverProjectPreviewDelete(id) {
  return api()
    .root(API_ROOT_URL)
    .delete(`${FOREVER_PROJECT_PREVIEW_DELETE}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}
export function apiTrashForeverCollectionDelete(id) {
  return api()
    .root(API_ROOT_URL)
    .delete(`${FOREVER_COLLECTION_DELETE}${id}/`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}

// trash - empty
export function apiTrashEmpty() {
  return api()
    .root(API_ROOT_URL)
    .delete(`${EMPTY_TRASH}`)
    .success((res) => {
      return res;
    })
    .error((error) => {
      apiErrorHandler(error);
      return error;
    })
    .send();
}

export function apiCreateRender(payload) {
  return apiPost(CREATE_RENDER, payload, '', null, null, true);
}

export function apiUploadRenderImages(formData) {
  return api()
    .root(API_ROOT_URL)
    .post(UPLOAD_RENDER_IMAGES)
    .data(formData)
    .success((data) => {
      successToast('Image uplaod successful');
    })
    .error((error) => {
      errorToast('Error uploading images');
      apiErrorHandler(error, 'apiUploadRenderImages');
      return error;
    })
    .send();
}

export function apiGetMemberDetails(id) {
  return apiGet(GET_MEMBERS_API + id);
}

export const apiGetOrganization = async (page, per_page, search) => {
  const response = await apiGet(
    GET_ORG_LIST + `?page=${page}&per_page=${per_page}&search=${search}`
  );
  return response;
};

export const apiShareModel = async (formData, callback) => {
  try {
    const response = await api()
      .root(API_ROOT_URL)
      .post(SHARE_MODEL)
      .data(formData)
      .success((data) => {
        successToast('Your file has been shared sucessfully');
        callback(data);
      })
      .error((error) => {
        callback(error);
      })
      .authFail401((res) => {})
      .send();

    return response;
  } catch (e) {
    console.log('asdasd', e);
  }
};

export const apiShareMultipleModelType = async (formData, callback) => {
  try {
    const response = await api()
      .root(API_ROOT_URL)
      .post(SHARE_MULTIPLE_MODEL_TYPE)
      .data(formData)
      .success((data) => {
        successToast('Your file has been shared sucessfully');
        callback(data);
      })
      .error((error) => {
        callback(error);
      })
      .authFail401((res) => {})
      .send();

    return response;
  } catch (e) {
    console.log('asdasd', e);
  }
};

export const apiGetPermissionForShare = async (payload) => {
  return await apiPost(
    GET_PERMISSION_FOR_SHARE,
    payload,
    'Permission',
    'Request sent successfully',
    'Not able to sent request',
    false
  );
};

export function apiGetTextureList({ query, successMessage = null, errorMessage = null }) {
  return apiGet(
    `${TEXTURE_DETAILS_API}?${query && `&${query}`}`,
    'apiGetTextureList',
    successMessage,
    errorMessage
  );
}

export function apiGetTextures(query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${TEXTURE_DETAILS_API}?is_map=false${query && `&${query}`}`,
    'apiGetTextures',
    successMessage,
    errorMessage
  );
}

export function apiGetUploads(query, successMessage = null, errorMessage = null) {
  return apiGet(
    `${TEXTURE_DETAILS_API}?is_map=true${query && `&${query}`}`,
    'apiGetUploads',
    successMessage,
    errorMessage
  );
}

export function apiGetTexture({ id, successMessage = null, errorMessage = null }) {
  return apiGet(`${TEXTURE_DETAILS_API}${id}/`, 'apiGetTexture', successMessage, errorMessage);
}

export function apiGetDownloadTextures(query) {
  return apiGetBlob(`${DOWNLOAD_TEXTURE}?${query}`);
}

export function apiGetTextureProducts(successMessage = null, errorMessage = null) {
  return apiGet(
    `/assetbundles/api/v3/texture/products/detail/`,
    'apiGetTextureProducts',
    successMessage,
    errorMessage
  );
}

export function apiEditTexture(
  id,
  payload,
  successMessage = 'Texture updated successfully',
  errorMessage = 'There was an issue updating the texture'
) {
  return apiPatch(
    `${TEXTURE_UPDATE}${id}/`,
    payload,
    'apiEditTexture',
    successMessage,
    errorMessage
  );
}

export function apiBulkUploadTextures(
  payload,
  successMessage = 'Textures uploaded successfully',
  errorMessage = 'There was an issue uploading the textures'
) {
  return apiPost(
    TEXTURE_BULK_UPLOAD,
    payload,
    'apiBulkEditTextures',
    successMessage,
    errorMessage,
    false
  );
}

export function apiBulkEditTextures(payload, successMessage = null, errorMessage = null) {
  return apiPost(
    TEXTURE_BULK_UPDATE,
    payload,
    'apiBulkEditTextures',
    successMessage,
    errorMessage,
    false
  );
}

export function apiBulkDeleteTextures(payload) {
  return apiPost(
    TEXTURE_BULK_DELETE,
    payload,
    'apiBulkDeleteTextures',
    'Textures have been deleted',
    'There was an error deleting textures',
    false
  );
}

// Filters API start
export function apiGetFilters(query, successMessage = null, errorMessage = null) {
  return apiGet(`${GET_FILTERS}?${query}`, 'apiGetFilters', successMessage, errorMessage);
}

export function apiCreateFilterAndOption(
  payload,
  successMessage = null,
  errorMessage = 'There was a problem while creating option'
) {
  return apiPost(
    CREATE_FILTER_OPTION,
    payload,
    'apiCreateFilterAndOption',
    successMessage,
    errorMessage,
    false
  );
}

export function apiCreateFilterOption({ filterType, filterName, optionName }) {
  const formData = new FormData();
  formData.append('type', filterType);
  formData.append('options', optionName);
  formData.append('label_name', filterName);
  return apiCreateFilterAndOption(formData);
}
// Filters API end
export function apiGetDownloadProjectData(query) {
  return apiGetBlob(`${PROJECT_UPLOAD}?${query}&is_generate=true`);
}

export async function apiGetDownloadProject(payload) {
  return apiPost(PROJECT_UPLOAD, payload, '', '', '', false);
}

export const downloadFile = ({ fileUrl }) => {
  // this one is temporary until we prepare download progress component

  return new Promise((resolve, reject) => {
    var x = new XMLHttpRequest();
    x.open('GET', fileUrl, true);
    x.responseType = 'blob';
    x.onload = function (e) {
      resolve(e.target.response);
    };
    x.send();
  });
};

export const getSharedFileToMemberList = async (model_type, model_id) => {
  return await apiGet(
    GET_SHARED_FILES_TO_MEMBER_LIST + `?model_type=${model_type}&model_id=${model_id}`
  );
};

export const removeUserFromSharedFile = async (formData) => {
  return await apiMultiDelete(REMOVE_USER_FROM_SHARED_FILE, formData);
};

export function apiCheckMemberPermissions(permission_name) {
  const payload = new FormData();
  payload.append('permission_name', permission_name);
  try {
    return api()
      .root(API_ROOT_URL)
      .post(CHECK_MEMBER_PERMISSIONS)
      .data(payload)
      .success((data) => {
        return data;
      })
      .error((error) => {
        throw error;
      })
      .accessDenied403((res) => {
        console.error('accessDenied403 res: ', res);
      })
      .send();
  } catch (error) {
    console.error('error: ', error);
    throw error;
  }
  // return apiPost(CHECK_MEMBER_PERMISSIONS, payload);
}
