import _ from 'lodash';
import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { icons } from '../assets';
const { BinIcon } = icons;

export default function EmptyTrash(props) {
  
  const handleEmptyTrash = async () => {
    if (_.isFunction(props.deleteAction)) {
      props.deleteAction()
    }
  }

  return (
    <Button
      // variant={'outline-dark'}
      size={props.size || 'lg'}
      onClick={handleEmptyTrash}
      type="button"
      className="bigButton font16 lf-btn-3"
      title={props.actionWord}
    >
      <BinIcon />
      {props.actionWord}
    </Button>

  );
}
