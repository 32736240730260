/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { errorToast, successToast } from '../helpers/toastHelper';
import { apiCreateRender } from '../helpers/api';
import styles from '../components/admin/curator/RenderPreviewsDialog/RenderPreviewDialog.module.scss';
import { RenderPreviewDialogUI } from '../components/admin/curator/RenderPreviewsDialog/RenderPreviewDialogUI';
import { RP_TYPE } from '../config/constant/unityConstants';
import { useSelector } from 'react-redux';
import { selectIsSiloRoom } from '../redux/slicers/admin/curatorSlicer';

export default function RenderPageDialog({
  render_id,
  cameraData,
  showRender,
  setShowRender,
  hasMetadata,
  onRenderSuccess,
}) {
  const [cameraList, setCameraList] = useState(cameraData);
  const [loading, setLoading] = useState(false);
  const isSiloRoom = useSelector(selectIsSiloRoom);

  useEffect(() => {
    console.log('cameraList: ', cameraList);
    // setCameraList(cameraData);
  }, [cameraList]);

  const handleSaveRenderPreview = async (values, selectedCameraIds, resetValues) => {
    setLoading(true);
    console.log('values: ', values);
    console.log('resetValues: ', resetValues);
    const selectedCameraList = cameraList.filter((c) => selectedCameraIds?.includes(c.id));

    if (!values.name) {
      errorToast('Please provide a name');
      setLoading(false);
      return;
    }

    if (!selectedCameraList.length) {
      errorToast('Please add at least one camera');
      setLoading(false);
      return;
    }

    console.log('selectedCameraList: ', selectedCameraList);

    const renderCamList = selectedCameraList?.map((a) => {
      return {
        id: a.id,
        resoX: a.resoX,
        resoY: a.resoY,
      };
    });

    const payload = {
      count: selectedCameraList.length,
      render_name: values.name,
      render_extension: values.imageType,
      render_camera: JSON.stringify(renderCamList),
      render_id: render_id,
      is_meta_data: values.metadata,
      is_water_mark: values.watermark,
      is_shadow_on_floor: values.shadowOnFloor,
      is_transparent_bg: values.transparentBg,
    };

    const res = await apiCreateRender(payload);
    if (res) {
      setLoading(false);
      if (res.status) {
        successToast(res?.message !== '' ? res?.message : 'File sent for rendering.');
        setShowRender(false);
        onRenderSuccess(res.data)
      } else {
        errorToast(res?.message);
      }
    }
  };

  const handleResolutionChange = (name, value, cameraId) => {
    console.log(name + '  ' + value + '  ' + cameraId);

    const updatedCameraList = cameraList.map((camera) => {
      if (camera.id === cameraId) {
        return {
          ...camera,
          [name]: value,
          // camera updates here
        };
      }
      return camera;
    });

    setCameraList(updatedCameraList);
  };

  const handleCameraClick = (camera) => {
    console.log('camera: ', camera);
  };

  const title = 'Render settings';

  return (
    <RenderPreviewDialogUI
      dialogClassName={styles.renderPopupBottom}
      show={showRender}
      title={title}
      popupType={RP_TYPE.RENDER}
      onClose={() => setShowRender(false)}
      cameraList={cameraList}
      onCameraResolutionChange={handleResolutionChange}
      onSubmit={handleSaveRenderPreview}
      onCameraClick={handleCameraClick}
      loading={loading}
      isSiloRoom={isSiloRoom}
      hasMetadata={hasMetadata}
    />
  );
}
