/* eslint-disable no-unreachable, no-debugger */
import PubSub from 'pubsub-js';
import download from 'downloadjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createProjectApi, exportProjectApi, saveProjectApi } from '../../../../api/curator';
import { CURATOR_TYPE, ROOM_TYPE } from '../../../../constants/projectConstants';
import { errorToast, infoToast, successToast } from '../../../../helpers/toastHelper';
import { getCuratorPath, getProjectsPath } from '../../../../helpers/urlHelper';
import { useInfoDialogaActions } from '../../../../hooks/useInfoDialogaActions';
import { getAppState, getStore } from '../../../../redux/store';
import {
  curatorSelector,
  saveAsNew,
  SAVE_PROJECT_MODE,
  selectCalculatedRoomId,
  selectCuratorInitialized,
  selectMetadataSettings,
  selectSaveProjectLoading,
  selectSaveProjectMode,
  selectUnityData,
  setRoomData,
  setSaveProjectDialogOpen,
  setSaveProjectMode,
  unblockProjectInitialization,
} from '../../../../redux/slicers/admin/curatorSlicer';
import { useUnityContext } from '../../../container/unityContainer';
import {
  ENVIRONMENT_LIGHT,
  selectEnvironmentColor,
  selectEnvironmentColorIntensity,
  selectEnvironmentLightType,
  selectHDRIIntensity,
  selectHDRIRotation,
  selectJsonDefaultEnvironmentLightData,
  selectSelectedHDRI,
} from '../../../../redux/slicers/admin/curatorLightSlicer';
import { useCuratorData } from '../../../../hooks/useCuratorData';
import { selectProjectCameraList } from '../../../../redux/slicers/camera/projectCameraAngleSlicer';
import {
  selectExportProjectLoading,
  setExportProjectLoading,
} from '../../../../redux/slicers/admin/curatorLoaderSlicer';
import { duplicateArrayRemove } from '../../../../helpers/jsHelper';
import { UnityEvent } from '../../../../helpers/unityPubSub';
import {
  selectCreatedPreviewList,
  selectCreatedRenderList,
} from '../../../../redux/slicers/admin/paginatedRenderPreviewsSlicer';
import {
  selectCanvasFrameHeight,
  selectCanvasFrameWidth,
} from '../../../../redux/slicers/camera/cameraSettings';
import { getTexture } from '../../../../helpers/idb';
import { useUserContext } from '../../../../contexts/UserContext';

export const addIdsToRequestData = ({
  data,
  roomInfo,
  includedIds = {
    texture: false,
    textures: true,
    layout: true,
    materials: true,
    props: true,
    products: false,
  },
}) => {
  const unityData = selectUnityData(getAppState());
  const isFormData = data instanceof FormData;

  // layout
  if (includedIds.layout) {
    if (isFormData) {
      data.append('layout', unityData.layoutId);
    } else {
      data.layout = unityData.layoutId;
    }
  }

  // props
  if (includedIds.props || includedIds.products) {
    const key = includedIds.props ? 'props' : 'products';
    const propIds = roomInfo.blenderAssetBundleDatas.map((item) => item.id);
    if (isFormData) {
      propIds.forEach((id) => {
        data.append(key, id);
      });
    } else {
      data[key] = propIds;
    }
  }

  // textures and materials
  const textureIds = [];
  const materialIds = [];
  roomInfo.materialDetails.map((md) => {
    if (md.loadedMaterialId) {
      materialIds.push(md.loadedMaterialId);
    }

    ['diffuseId', 'AOId', 'metallicId', 'normalId', 'roughnessId', 'transparencyId'].forEach(
      (key) => {
        if (md[key]) {
          textureIds.push(md[key]);
        }
      }
    );
  });

  if (includedIds.textures) {
    const uniqueTextureIds = duplicateArrayRemove(textureIds);
    if (isFormData) {
      uniqueTextureIds.forEach((id) => {
        data.append('textures', id);
      });
    } else {
      data.textures = uniqueTextureIds;
    }
  }

  if (includedIds.texture) {
    const uniqueTextureIds = duplicateArrayRemove(textureIds);
    if (isFormData) {
      uniqueTextureIds.forEach((id) => {
        data.append('texture', id);
      });
    } else {
      data.texture = uniqueTextureIds;
    }
  }

  if (includedIds.materials) {
    const uniqueMaterialIds = duplicateArrayRemove(materialIds);
    if (isFormData) {
      uniqueMaterialIds.forEach((id) => {
        data.append('materials', id);
      });
    } else {
      data.materials = uniqueMaterialIds;
    }
  }
};

export const useSaveProject = () => {
  const { type, roomId, isOpenProjectFlow, isRoom } = useCuratorData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const curatorInitialized = useSelector(selectCuratorInitialized);
  const { openInfoDialog } = useInfoDialogaActions();
  const saveProjectMode = useSelector(selectSaveProjectMode);
  const { individualProjectData = {}, roomData } = useSelector(curatorSelector);
  const { roomModule, cameraModule } = useUnityContext();
  const loading = useSelector(selectSaveProjectLoading);
  const { currentUser } = useUserContext();

  const getRoomInfo = () => {
    // this will trigger get info which will be provided in the callback and set later on to localstorage
    // TODO: refactor it to use async/await with unityContext.on -> and wait for GetRoomInfo event triggered
    roomModule.GetRoomInfo();
    console.log('SAVE_PROJECT_FLOW START');
    const roomInfo = JSON.parse(localStorage.getItem('roomInfo'));
    console.log('SAVE_PROJECT_FLOW ROOM_INFO', roomInfo);
    return roomInfo;
  };

  // TODO: move to seprate file
  const parseCulpSkuData = async (culpSkuUsedData, roomMetadataMap) => {
    const result = {};
    const culpObjectsDatas = Object.values(culpSkuUsedData);
    const roomMetadata = Object.values(roomMetadataMap);
    const metadataSettings = selectMetadataSettings(getAppState());

    await Promise.all(
      roomMetadata.map(async (metadataItem) => {
        if (!metadataItem.status) return;

        const skuItem = culpObjectsDatas.find((item) => item.matName === metadataItem.materialName);
        if (!skuItem) return;

        const jsonObject = {
          objectName: skuItem.objectName,
          matName: skuItem.matName,
          toShowName: metadataItem.objectName,
          skuId: skuItem.skuId.trim(),
          index: skuItem.index,
          repeatL: skuItem.repeatL,
          repeatW: skuItem.repeatW,
          offsetL: skuItem.offsetL,
          offsetW: skuItem.offsetW,
          rotation90Count: skuItem.rotation90Count,
          diffuseId: skuItem.diffuseId,
          diffuseVersion: skuItem.diffuseVersion,
        };

        const skuidToShow = skuItem.skuId.trim();
        let textValue = ''; // metadataItem.title + ':         ';

        const data = {
          objectName: {
            value: metadataItem.title,
            key: 'Part Name',
          },
          sku: {
            value: 'SKU - ' + skuidToShow,
            key: 'SKU ID',
          },
          textureName: {
            value: skuItem.textureName,
            key: 'Design',
          },
          content: {
            value: skuItem.content,
            key: 'Content',
          },
          repeat: {
            value: '',
            key: 'Reapeat Size',
          },
          offset: {
            value: '',
            key: 'Offset',
          },
          rotation: {
            value: '',
            key: 'Rotation',
          },
        };

        if (skuItem.diffuseId) {
          const textureData = await getTexture(parseInt(skuItem.diffuseId));
          data.content.value = textureData?.description && data.content.value;
          data.textureName.value = textureData?.name || data.textureName.value;
        }

        if (skuItem.rotation90Count > 0) {
          if (skuItem.offsetW != '0.00' || skuItem.offsetL != '0.00') {
            data.repeat.value = '(W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL;
            data.offset.value = '(W) ' + skuItem.offsetW + ' * (L) ' + skuItem.offsetL;
            data.rotation.value = skuItem.rotation90Count * 90 + ' degrees';
          } else {
            data.repeat.value = '(W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL;
            data.rotation.value = skuItem.rotation90Count * 90 + ' degrees';
          }
        } else {
          if (skuItem.offsetW != '0.00' || skuItem.offsetL != '0.00') {
            data.repeat.value = '(W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL;
            data.offset.value = '(W) ' + skuItem.offsetW + ' * (L) ' + skuItem.offsetL;
          } else {
            data.repeat.value = '(W) ' + skuItem.repeatW + ' * (L) ' + skuItem.repeatL;
          }
        }

        const textValueDetails = Object.values(data)
          .map(({ value, key }) => {
            const settings = metadataSettings.find(
              (md) => md.key.toLowerCase() === key.toLowerCase()
            );
            if (!settings) {
              throw new Error('Metadata configuration is incorrect');
            }

            return {
              value,
              enabled: settings.is_render,
              key,
              displayName: settings.display_name,
              label: `${settings.display_name} - ${value}`,
              sequenceNo: settings.seq_no,
            };
          })
          .filter((md) => md.value !== '' && md.value !== null && md.value !== undefined)
          .filter((md) => md.enabled)
          .sort((a, b) => a.sequenceNo - b.sequenceNo);

        const textValueParts = textValueDetails.map((d) => d.label);
        const formattedTextValueDetails = textValueDetails.map(item => ({
          displayName: item.displayName,
          value: item.value,
        }))

        textValue += textValueParts.join(', ');

        jsonObject.textValue = textValue;
        jsonObject.textValueDetails = formattedTextValueDetails;
        result[skuItem.matName] = jsonObject;
      })
    );

    return result;
  };

  const wasEnvironmentLightChanged = (envLight, defaultEnvLight) => {
    if (!defaultEnvLight) return true;

    if (!envLight.hdri.ishdriEnabled !== defaultEnvLight.hdri.ishdriEnabled) {
      // compare if different was enabled
      return true;
    }

    if (envLight.hdri.ishdriEnabled) {
      return Boolean(envLight.hdri.hdriId);
    }

    if (envLight.color.isEnvironmentColorEnabled) {
      return (
        envLight.color.environmentIntensity !== defaultEnvLight.color.environmentIntensity ||
        envLight.color.environmentColor !== defaultEnvLight.color.environmentColor
      );
    }

    return true;
  };

  const getRoomJsonFile = async () => {
    const store = getStore();
    const state = store.getState();
    const jsonDefaultEnvironmentLightData = selectJsonDefaultEnvironmentLightData(state);

    const roomInfo = getRoomInfo();

    // remove generated ids required only for old zip files
    roomInfo.materialDetails = roomInfo.materialDetails.map((md) => {
      delete md.objectUniqueIdentifier;
      return md;
    });

    // add selected environment light
    const lightType = selectEnvironmentLightType(state);
    const hdrId = selectSelectedHDRI(state)?.id;
    roomInfo.SelectedEnvironmentLight = {
      color: {
        isEnvironmentColorEnabled: lightType === ENVIRONMENT_LIGHT.COLOR,
        environmentIntensity: selectEnvironmentColorIntensity(state),
        environmentColor: selectEnvironmentColor(state),
        actualIntensity: jsonDefaultEnvironmentLightData?.color?.actualIntensity,
      },
      hdri: {
        ishdriEnabled: lightType === ENVIRONMENT_LIGHT.HDRI,
        hdriRotation: selectHDRIRotation(state),
        hdriIntensity: selectHDRIIntensity(state),
        hdriId: `${hdrId}`.toUpperCase() !== 'DEFAULT' ? hdrId : null,
      },
    };

    // check if env light was changed

    roomInfo.environmentLightWasChanged = wasEnvironmentLightChanged(
      roomInfo.SelectedEnvironmentLight,
      jsonDefaultEnvironmentLightData
    );

    // save cameras in project json
    roomInfo.cameraList = selectProjectCameraList(getAppState()) || [];
    roomInfo.roomId = selectCalculatedRoomId(getAppState());

    // update culp json data
    roomInfo.culpSkuUsedData = await parseCulpSkuData(
      roomInfo.culpSkuUsedData,
      roomInfo.roomMetadata
    );

    // screen data
    const canvasWidth = selectCanvasFrameWidth(getAppState());
    const canvasHeight = selectCanvasFrameHeight(getAppState());
    roomInfo.screenWidth = canvasWidth;
    roomInfo.screenHeight = canvasHeight;
    roomInfo.cameraAspect = canvasWidth / canvasHeight;

    // user info
    roomInfo.infoData = {
      userId: currentUser.user.id,
    };

    // datetime
    (roomInfo.datetime = new Date().toISOString()), console.log('SAVE_PROJECT_FLOW JSON', roomInfo);

    const json_data = JSON.stringify(roomInfo);

    const blob = new Blob([json_data], { type: 'application/json' });
    return {
      blob,
      json: json_data,
      raw: roomInfo,
    };
  };

  const downloadProjectJson = (projectData, jsonData) => {
    const project = {
      name: projectData.name,
      projectType: projectData.projectType,
      category: projectData.category,
      style: projectData.style,

      jsonData,
    };

    download(JSON.stringify(project), `${projectData.name}.json`, 'application/json');
  };

  const getProjectThumbnail = async () => {
    return new Promise((resolve) => {
      PubSub.subscribeOnce(UnityEvent.OnReceieveCurrentCameraScreenshot, (_, imageData) => {
        // TODO: refactor this code
        const byteCharacters = window.atob(imageData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: `image/jpeg` });
        resolve(blob);
      });
      cameraModule.GetCurrentCameraScreenshotReceiver();
    });
  };

  const saveProject = async (data) => {
    if (loading) return;

    const roomJsonFile = await getRoomJsonFile();

    var formObj = new FormData();
    formObj.append('file', roomJsonFile.blob, 'newproject.json');

    if (data) {
      formObj.append('name', data.name);
      formObj.append('project_type', data.projectType);

      if (data.projectType !== ROOM_TYPE.SILO) {
        const filterOptions = [];
        Object.keys(data).forEach((key) => {
          if (key.startsWith('filterLabel__')) {
            filterOptions.push(...data[key].map((d) => d.value));
          }
        });
        const filterOptionsStr = filterOptions.join(',');
        formObj.append('filteroptions', filterOptionsStr);
      }
    } else {
      formObj.append('name', individualProjectData?.name);
      formObj.append('project_type', individualProjectData?.project_type);
    }

    addIdsToRequestData({ data: formObj, roomInfo: roomJsonFile.raw });

    // thumbnail
    const thumbnail = await getProjectThumbnail(formObj);
    formObj.append('thumbnail', thumbnail, 'project-thumbnail.jpg');

    console.log('SAVE_PROJECT_FLOW REQUEST_DATA', formObj);
    console.log('SAVE_PROJECT_FLOW ROOM_JSON', roomJsonFile.raw);

    // connect new renders and previews to current/new project
    const createdPreviewList = selectCreatedPreviewList(getAppState());
    const createdRenderList = selectCreatedRenderList(getAppState());
    const renderPreviewList = [...createdPreviewList, ...createdRenderList];
    renderPreviewList.forEach((renderPreviewItem) => {
      formObj.append('project_render', renderPreviewItem.id);
    });

    switch (saveProjectMode) {
      case SAVE_PROJECT_MODE.CLOSE_CURATOR: {
        const onSuccess = () => {
          dispatch(setSaveProjectDialogOpen(false));
          navigate(getProjectsPath());
        };

        if (isRoom) {
          dispatch(createProjectApi(formObj, { onSuccess }));
        } else {
          dispatch(saveProjectApi(formObj, roomId, { onSuccess }));
        }
        break;
      }
      case SAVE_PROJECT_MODE.SAVE:
        dispatch(
          saveProjectApi(formObj, roomId, {
            onSuccess: () => {
              dispatch(setSaveProjectDialogOpen(false));
            },
            onError: () => {
              errorToast('Unknown error happened while saving project');
            },
          })
        );
        break;
      case SAVE_PROJECT_MODE.NEW:
        dispatch(
          createProjectApi(formObj, {
            onSuccess: (data) => {
              dispatch(saveAsNew({ project: data.data }));
              dispatch(setSaveProjectDialogOpen(false));
              navigate(getCuratorPath(data.data.id, 'project'));

              setTimeout(() => {
                dispatch(unblockProjectInitialization());
              }, 200);
            },
          })
        );
        break;
      case SAVE_PROJECT_MODE.SAVE_ON_LOCAL:
        downloadProjectJson(data, roomJsonFile.raw);
        dispatch(setSaveProjectDialogOpen(false));
        break;
      default:
        throw new Error('Save project mode not supported');
    }
  };

  const openSaveProjectDialog = () => {
    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.SAVE));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const openSaveProjectDialogAsNew = () => {
    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.NEW));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const exportProject = async () => {
    const loading = selectExportProjectLoading(getAppState());

    if (loading) {
      infoToast('Project export is still in progress');
      return;
    }

    setExportProjectLoading(true);
    // TODO: use info toast here
    infoToast('Please wait... Project is being exported');

    const roomJsonFile = await getRoomJsonFile();
    const formData = new FormData();
    console.log('roomJsonFile', roomJsonFile);
    formData.append('json_file', roomJsonFile.blob, 'project.json');
    addIdsToRequestData({
      data: formData,
      roomInfo: roomJsonFile.raw,
      includedIds: {
        texture: true,
      },
    });

    const fileName = individualProjectData?.name || roomData?.name || 'project';
    const result = await dispatch(exportProjectApi(formData, { fileName: `${fileName}.zip` }));

    setExportProjectLoading(false);
  };

  return {
    getRoomJsonFile,
    exportProject,
    saveProject,
    openSaveProjectDialog,
    openSaveProjectDialogAsNew,
    addIdsToRequestData,
  };
};
