
import { useSimpleDataList } from "./useDataList";
import { profilePreferencesActions, profilePreferencesSelector } from "../redux/slicers/profilePreferencesSlicer";
import { getPreferencesListApi } from "../api/settings";

export const useProfilePreferencesList = ({ requestParams, skip } = {}) => {
  const data = useSimpleDataList({
    selector: profilePreferencesSelector,
    resetAction: profilePreferencesActions.resetList,
    actions: profilePreferencesActions,
    getAction: getPreferencesListApi,
    requestParams,
    skip,
  });

  return data;
};
