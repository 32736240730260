import { createSlice } from "@reduxjs/toolkit";

const curatorProductsSlicer = createSlice({
  name: "curatorProducts",
  initialState: {
    draggableProductImage: null,
    draggableInitialPosition: { x: 0, y: 0 },
  },
  reducers: {
    setDraggableProductImage: (state, { payload }) => {
      
      state.draggableProductImage = payload.image;
      state.draggableInitialPosition = {
        x: payload.x,
        y: payload.y,
      }
    }
  },
});

export const {
  setDraggableProductImage
} = curatorProductsSlicer.actions;
export const actions = curatorProductsSlicer.actions;

export const curatorProductsSelector = (state) => state.curatorProducts;
export const curatorProductsReducer = curatorProductsSlicer.reducer;
export default curatorProductsReducer;

export const selectDraggableProductImage = (state) => state.curatorProducts.draggableProductImage
export const selectDraggableProductInitialPosition = (state) => state.curatorProducts.draggableInitialPosition