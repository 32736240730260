import React, { useState } from "react";
import styles from './FilterItem.module.scss';

// type TItem = {
//   label: string;
//   value: String;
// }

// type TProps = {
//   title: React.ReactNode;
//   items: TItem[];
//   maxVisible: number;
//   valuse: string[];
//   onChange: (value: string) => void;
// }

export const FilterItem = ({ title, items, maxVisible, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const moreCount = items.length - maxVisible;
  const visibleItems = moreCount > 0 && !open ? items.slice(0, maxVisible) : items;

  return (
    <div className={styles.root}>
      {title && (
        <div>
          <h5>{title}</h5>
        </div>
      )}

      {visibleItems.map((item) => {
        return (
          <div className="form-check custom-checkbox" key={item.value}>
            <input
              className="form-check-input"
              type="checkbox"
              value={item.value}
              checked={value.includes(item.value)}
              onChange={()=> onChange(item.value)}
              id={`sideFilter-${item.value}`}
            />
            <label className="form-check-label" htmlFor={`sideFilter-${item.value}`}>
              {item.label}
            </label>
          </div>
        );
      })}

      {moreCount > 0 && (
        <div className={styles.more}>
          <span onClick={() => setOpen(!open)}>{moreCount} {open ? 'less' : 'more'}</span>
        </div>
      )}
    </div>
  );
};