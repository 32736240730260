import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { ALLOWED_MIME_TYPES } from '../../constants/constants';
import { checkImageTypes } from '../../helpers/jsHelper';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 150,
  padding: '20px',
  border: '2px dashed #A3A4A6',
  borderRadius: 16,
  background: '#f9f9f9',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const acceptStyle = {
  border: '2px dashed #0059F1',
};

const rejectStyle = {
  border: '2px dashed #ff1744',
};

const MyDropzone = (props) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/png, image/jpg, image/jpeg',
    onDrop: (acceptedFiles) => {
      if (checkImageTypes(acceptedFiles, ALLOWED_MIME_TYPES, 'Only jpg/png images are allowed')) {
        props.onDrop(acceptedFiles);
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject, isFocused]
  );

  return (
    <div className="dropzone" {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <p className="dz-title">Select file</p>
      <p className="dz-sub-title">or drop file here</p>
    </div>
  );
};

export default MyDropzone;
