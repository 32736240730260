import { useDispatch } from 'react-redux';
import { API_ROOT_URL, COLLECTION_DETAILS_API } from '../../config/constant/apiConstant';
import { AUTH_TOKEN, REFRESH_TOKEN } from '../../config/constant/projectConstant';
import { COLLECTION_DATA_API } from '../../helpers/api';
import { createListApiRequest } from '../../helpers/apiHelper';
import api from '../../helpers/axiosHelper';
import { errorToast, successToast } from '../../helpers/toastHelper';
import { collectionItemsActions } from '../../redux/slicers/collectionItemsSlicer';
import { collectionListActions } from '../../redux/slicers/collectionListSlicer';
import { collectionsActions } from '../../redux/slicers/collectionsSlicer';
// import { navigate } from '../../helpers/projectHelper';
// import { successToast } from '../../helpers/toastHelper';
import { setSearchValue, setSearchResult, setMoreSearchResult } from '../../redux/slicers/search/searchSlicer';
import { createDeleteApiAction, createGetListAction, createUpdateApiAction } from '../products';

export const CollectionsSearchApi = (searchVal, page = 1, limit = 20) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${COLLECTION_DETAILS_API}?search=${searchVal}&page=${page}&per_page=${limit}`)
    .success((data) => {
      if (page === 1) {
        dispatch(setSearchResult(data));
      } else {
        dispatch(setMoreSearchResult(data))
      }
    })
    .error((error) => {
      // console.log("texture search data err:", error)
      // dispatch(setSearchResult(error))
    })
    .send();
};

export const createCollectionApi = (formData) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .post(COLLECTION_DETAILS_API)
    .data(formData)
    .success((data) => {
      console.log('createCollectionApi success:', data);
      successToast('Added to Collection successfully!');
    })
    .error((error) => {
      console.log('createCollectionApi err:', error);
      errorToast('Unable to add to Collection!');
    })
    .send();
};

export const getCollectionsApiV2 = createListApiRequest({
  url: COLLECTION_DETAILS_API,
  startAction: null,
  successAction: null,
  errorAction: null,
});

export const getCollectionListApi = createGetListAction({
  endpoint: COLLECTION_DETAILS_API,
  actions: collectionListActions,
  defaultSort: 'name',
});

export const getCollectionPageListApi = createGetListAction({
  endpoint: COLLECTION_DETAILS_API,
  actions: collectionsActions,
  setGlobalSidebarFilters: true,
});

export const updateCollectionApi = createUpdateApiAction({
  actions: collectionsActions,
  endpointUrl: COLLECTION_DETAILS_API,
})

export const deleteCollectionAPI = createDeleteApiAction({
  actions: collectionsActions,
  endpointUrl: COLLECTION_DETAILS_API,
  successMessage: 'Deleted',
})



export const getCollectionPageItemsListApi = createGetListAction({
  endpoint: COLLECTION_DATA_API,
  actions: collectionItemsActions,
  setGlobalSidebarFilters: true,
});
