import cx from 'classnames';
import {
  DARK_TOAST_OPTIONS,
  LIGHT_TOAST_OPTIONS,
  THEME_LIGHT,
} from '../config/constant/projectConstant';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getTheme } from './projectHelper';
import { BsCheck } from 'react-icons/bs';
import { BiError } from 'react-icons/bi';
import { MdOutlineInfo } from 'react-icons/md';
import { ToastErrorIcon } from '../components/icons/ToastErrorIcon';

const commonProps = (msg, config) => {
  // making the toast container larger if longer message
  const tempConfig = {
    ...config,
    ...noIcon,
  };

  if (msg.length > 35) {
    tempConfig.className = 'toast-container largeToastify';
  }
  return tempConfig;
};

const noIcon = {
  icon: false,
};

function messageArea(message, title, IconItem) {
  const hasMessage = Boolean(message && title);
  return (
    <>
      <div className="d-flex align-items-center">
        {IconItem}
        <h5 className={cx('toast-title fontSize85 align-self-center mb-0 float-left', !hasMessage && 'toast-only-title')}>
          {title || message}
        </h5>
      </div>
      {title && <div className="toast-text">{message}</div>}
    </>
  );
}

export const successToast = (msg, title, customConfig) => {
  if (typeof title === 'object') {
    customConfig = title;
    title = undefined;
  }

  const config = getTheme() === THEME_LIGHT ? LIGHT_TOAST_OPTIONS : DARK_TOAST_OPTIONS;
  toast.success(messageArea(msg, title, <BsCheck color="#1bb75e" size="1.5em" className="lf-toast-icon" />), {
    ...commonProps(msg, config),
    ...customConfig,
  });
};

export const errorToast = (msg, title, customConfig) => {
  if (typeof title === 'object') {
    customConfig = title;
    title = undefined;
  }
  const config = getTheme() === THEME_LIGHT ? LIGHT_TOAST_OPTIONS : DARK_TOAST_OPTIONS;
  toast.error(messageArea(msg, title, <ToastErrorIcon className="lf-toast-icon" />), {
    ...commonProps(msg, config),
    ...customConfig,
  });
};

export const infoToast = (msg, title, customConfig) => {
  if (typeof title === 'object') {
    customConfig = title;
    title = undefined;
  }
  const config = getTheme() === THEME_LIGHT ? LIGHT_TOAST_OPTIONS : DARK_TOAST_OPTIONS;
  toast.info(messageArea(msg, title, <MdOutlineInfo color="#5795ff" size="1.5em" className="lf-toast-icon" />), {
    ...commonProps(msg, config),
    ...customConfig,
  });
};

export const warningToast = (msg,  title, customConfig) => {
  if (typeof title === 'object') {
    customConfig = title;
    title = undefined;
  }
  const config = getTheme() === THEME_LIGHT ? LIGHT_TOAST_OPTIONS : DARK_TOAST_OPTIONS;
  toast.warning(messageArea(msg, title, <BiError color="#da8622" size="1.5em" className="lf-toast-icon" />), {
    ...commonProps(msg, config),
    ...customConfig,
  });
};
