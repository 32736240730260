import { createSlice } from "@reduxjs/toolkit";
import { CURATOR_MENU_SECTION } from "./curatorMenuPreferencesSlicer";

export const DEFAULT_COLUMNS = 2;
export const MIN_COLUMNS = 2;

const curatorLayoutSlicer = createSlice({
  name: "curatorLayout",
  initialState: {
    columns: {
      [CURATOR_MENU_SECTION.PRODUCTS]: DEFAULT_COLUMNS,
      [CURATOR_MENU_SECTION.STYLE]: DEFAULT_COLUMNS,
    }
  },
  reducers: {
    setColumnCount: (state, { payload }) => {
      state.columns[payload.sectionName] = payload.columnsCount;
    }
  },
});

export const {
  setColumnCount
} = curatorLayoutSlicer.actions;
export const actions = curatorLayoutSlicer.actions;

export const curatorLayoutSelector = (state) => state.curatorLayout;
export const curatorLayoutReducer = curatorLayoutSlicer.reducer;
export default curatorLayoutReducer;

export const selectColumnsCount = (state, sectionName) => state.curatorLayout.columns[sectionName] || DEFAULT_COLUMNS;