import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filterSelector } from '../redux/slicers/filters/filterSlicer';

export const useFilterDropdownOptions = ({ filterName, filterType, filterId }) => {
  const { filterData } = useSelector(filterSelector);

  const mapOption = (option) => ({
    value: option.id,
    label: option.name,
  })

  const dropdownOptions = useMemo(() => {
    const filters = filterData?.results || [];

    if (typeof filterType === 'number' && !filterName && !filterId) {
      const typeFilters = filters.filter(f => f.type === filterType);
      return typeFilters.map(f => {
        return {
          ...f,
          options: f.options.map(mapOption)
        }
      })
    }

    
    let filter = [];
    if (filterName && typeof filterType === 'number') {
      filter = filters.find(
        (f) => f.type === filterType && f.label_name.toLowerCase() === filterName.toLowerCase()
      );
    } else if (filterId) {
      filter = filters.find((f) => f.id === filterId);
    }
  
    if (!filter?.options) {
      return [];
    }
  
    return filter.options.map(mapOption);

  }, [filterData, filterName, filterType, filterId])
  
  return dropdownOptions;
};
