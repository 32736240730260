import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Checkbox as PrettyCheckbox } from 'pretty-checkbox-react';
import { BsCheck } from 'react-icons/bs';
import _ from 'lodash';

export default function Checkbox({
  successValue = true,
  label = '',
  name = null,
  className = '',
  defaultData = {},
  onChange = null,
  selected,
  preview,
  disabled,
}) {
  const [checked, setChecked] = useState(selected || false);

  useEffect(() => {
    if (defaultData?.[name] !== undefined) {
      setChecked(defaultData[name]);
    }
  }, [defaultData]);

  useEffect(() => {
    if (_.isBoolean(selected)) {
      setChecked(selected);
    }
  }, [selected]);

  const handleChange = () => {
    let check = !checked;
    setChecked(check);
    if (_.isFunction(onChange)) {
      onChange({ name, value: check && successValue });
    }
  };

  return (
    <>
      <div onClick={handleChange} className="checkbox-wrapper" disabled={disabled}>
        <PrettyCheckbox
          shape="curve"
          onChange={handleChange}
          // checked={(e) => e.preventPropagation()}
          name={name}
          state={checked}
          setState={setChecked}
          className={`styled-checkbox ${className} ${checked && 'checked'}`}
          variant="fill"
          icon={checked ? <BsCheck size="1em" /> : null}
          disabled={disabled}
        />
        {preview && <img src={preview} alt={label} />}
        <label onClick={handleChange} disabled={disabled}>
          {label.toLowerCase()}
        </label>
      </div>
      {/* <div className="count">
        <label>0</label>
      </div> */}
    </>
  );
}

export const StatelessCheckbox = ({
  label,
  name,
  className,
  onChange,
  checked,
  preview,
  containerClassName,
}) => {
  return (
    <div className={cx('d-flex align-items-center', containerClassName)}>
      <PrettyCheckbox
        shape="curve"
        onChange={onChange}
        checked={checked}
        name={name}
        className={`lf-stateless-checkbox ${className} ${checked && 'checked'}`}
        variant="fill"
        icon={checked ? <BsCheck size="1.5em" /> : null}
      />
      {preview && <img src={preview} alt={label} />}
      {label && <label className="pt-2 d-inline-block">{label}</label>}
    </div>
  );
};
