import {
  MY_PRODUCTS_ICON,
  MY_PROJECTS_ICON,
  RENDERS_PREVIEWS_ICON,
  ROOMS_ICON,
  PROPS_ICON,
  TEXTURES_ICON,
  COLLECTION_ICON,
  MY_TEAM_ICON,
  SHARED_FILES_ICON,
  CALENDAR_ICON,
  COMMUNITY_ICON,
  SETTINGS_ICON,
  TRASH_ICON,
  PLAY_TO_LEARN_ICON,
  REQUESTS_ICON,
  MY_ACCOUNT_ICON,
  ADD_CREDITS_ICON,
  LOGOUT_ICON,
  QUIT_ICON,
  SUPPORT_ICON,
} from './assetConstants';
import {
  MY_PRODUCTS_ROUTE,
  MY_PROJECTS_ROUTE,
  RENDERS_PREVIEWS_ROUTE,
  TEMPLATES_ROUTE,
  PROPS_ROUTE,
  TEXTURES_ROUTE,
  COLLECTIONS_ROUTE,
  MY_TEAM_ROUTE,
  SHARED_FILES_ROUTE,
  CALENDAR_ROUTE,
  COMMUNITY_ROUTE,
  SETTINGS_ROUTE,
  TRASH_ROUTE,
  PLAY_TO_LEARN_ROUTE,
  REQUESTS_ROUTE,
  MY_ACCOUNT_ROUTE,
  ADD_CREDITS_ROUTE,
  LOGOUT_ROUTE,
  QUIT_ROUTE,
  SUPPORT_ROUTE,
  TUTORIALS_ROUTE,
} from '../constants/routePathConstants';
import { icons } from '../assets';
const {
  MyTeamIcon,
  SharedFilesIcon,
  CalendarIcon,
  CommunityIcon,
  MySettingsIcon,
  TrashIcon,
  TutorialsIcon,
  SupportsIcon,
  RequestsIcon,
  MyProductsIcon,
  MyProjectsIcon,
  RendersPreviewsIcon,
  TemplatesIcon,
  PropsIcon,
  CollectionsIcon,
  MyAccountIcon,
  AddCreditsIcon,
  LogoutIcon
} = icons;

export const SIDEBAR_MENU = [
  {
    name: 'My team',
    icon: MyTeamIcon,
    path: MY_TEAM_ROUTE,
  },
  {
    name: 'Shared files',
    icon: SharedFilesIcon,
    path: SHARED_FILES_ROUTE,
  },
  // {
  //   name: 'Calendar',
  //   icon: CalendarIcon,
  //   path: CALENDAR_ROUTE,
  // },
  // {
  //   name: 'Community',
  //   icon: CommunityIcon,
  //   path: COMMUNITY_ROUTE,
  // },
  {
    name: 'Settings',
    icon: MySettingsIcon,
    path: SETTINGS_ROUTE,
  },
  {
    name: 'Trash',
    icon: TrashIcon,
    path: TRASH_ROUTE,
  },
  // {
  //   name: 'Tutorials',
  //   icon: TutorialsIcon,
  //   path: TUTORIALS_ROUTE,
  // },
  // {
  //   name: 'Play to learn',
  //   icon: PLAY_TO_LEARN_ICON,
  //   path: PLAY_TO_LEARN_ROUTE,
  // },
];

export const SIDEBAR_MENU_2 = [
  {
    name: 'Support',
    icon: SupportsIcon,
    path: SUPPORT_ROUTE,
  },
  // {
  //   name: 'Requests',
  //   icon: RequestsIcon,
  //   path: REQUESTS_ROUTE,
  // },
];

export const MAIN_MENU = [
  {
    name: 'My Products',
    icon: MyProductsIcon,
    path: MY_PRODUCTS_ROUTE,
  },
  {
    name: 'My Projects',
    icon: MyProjectsIcon,
    path: MY_PROJECTS_ROUTE,
  },
  {
    name: 'Renders & Previews',
    icon: RendersPreviewsIcon,
    path: RENDERS_PREVIEWS_ROUTE,
  },
  {
    name: 'Templates',
    icon: TemplatesIcon,
    path: TEMPLATES_ROUTE,
  },
  {
    name: 'Props',
    icon: PropsIcon,
    path: PROPS_ROUTE,
  },
  {
    name: 'Collections',
    icon: CollectionsIcon,
    path: COLLECTIONS_ROUTE,
  },
];

export const USER_MENU = [
  {
    name: 'My account',
    icon: MyAccountIcon,
    path: SETTINGS_ROUTE,
  },
  {
    name: 'Add credits',
    icon: AddCreditsIcon,
    path: ADD_CREDITS_ROUTE,
  },
  {
    name: 'Logout',
    icon: LogoutIcon,
    path: LOGOUT_ROUTE,
  },
  // {
  //     name: "Quit",   // commenting because of no use in lfv2
  //     icon: QUIT_ICON,
  //     path: QUIT_ROUTE
  // }
];
