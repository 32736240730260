/* eslint-disable no-debugger */
import { dispatcher } from '../../../../helpers/projectHelper';
import {
  ENVIRONMENT_LIGHT,
  selectDefaultEnvironmentLightData,
  selectEnvironmentColor,
  selectEnvironmentColorIntensity,
  selectEnvironmentLightType,
  selectHDRIIntensity,
  selectHDRIRotation,
  selectSelectedHDRI,
  selectSelectedLightId,
  setEnvironmentColor,
  setEnvironmentColorIntensity,
  setEnvironmentLightType,
  setHDRIIntensity,
  setHDRIRotation,
  setSelectedHDRI,
} from '../../../../redux/slicers/admin/curatorLightSlicer';
import { getAppState } from '../../../../redux/store';

export class UndoRedoEnvironmentLight {
  constructor({ undoRedoInstance, unityHDRI }) {
    this.unityHDRI = unityHDRI;
    this.undoRedo = undoRedoInstance;

    this.init();
  }

  init = () => {
    this.toggleLightType = this.undoRedo.createAction(({ nextType }) => {
      const prevType =
        nextType === ENVIRONMENT_LIGHT.COLOR ? ENVIRONMENT_LIGHT.HDRI : ENVIRONMENT_LIGHT.COLOR;

      return {
        undo: () => {
          this.unityHDRI.toggleEnvironmentLightType(prevType, { skipHistory: true });
        },
        redo: () => {
          this.unityHDRI.toggleEnvironmentLightType(nextType, { skipHistory: true });
        },
      };
    });

    // env light color
    this.updateColor = this.undoRedo.createSimpleAction({
      changeStateAction: this.unityHDRI.updateColor,
    });

    this.updateColorIntesity = this.undoRedo.createSimpleAction({
      changeStateAction: this.unityHDRI.updateColorIntesity,
    });

    this.updateHdriIntensity = this.undoRedo.createSimpleAction({
      changeStateAction: this.unityHDRI.updateHDRIIntesity,
    });

    this.updateHdriRotation = this.undoRedo.createSimpleAction({
      changeStateAction: this.unityHDRI.updateRotation,
    });

    this.toggleEyeTool = this.undoRedo.createSimpleAction({
      changeStateAction: this.unityHDRI.toggleHDRIEyeTool,
    });

    this.applyHdri = this.undoRedo.createAction(({ nextHDRI }) => {
      const prevSelectedHDRI = selectSelectedHDRI(getAppState());
      const defaultEnvironmentLightData = selectDefaultEnvironmentLightData(getAppState()) || {};

      return {
        undo: () => {
          // if default hdri was applied apply default hdri -> check reset to default
          // if different hdri was applied -> just selectHDRI

          if (prevSelectedHDRI.id) {
            this.unityHDRI.selectHDRI(prevSelectedHDRI, { skipHistory: true });
          } else {
            // apply default HDRI
            const { hdri } = defaultEnvironmentLightData || {};
            if (!hdri) return;
            dispatcher(setSelectedHDRI(hdri.data));
            this.unityHDRI.updateHDRIIntesity(hdri.intensity);
            this.unityHDRI.updateRotation(hdri.rotation);

            if (hdri.data?.hdriFile) {
              this.undoRedoInstance.unityContext.lightModule.UpdateEnvironmentSkyboxReceiver({
                url: hdri.data.hdriFile,
                rotation: hdri.rotation,
                intensity: hdri.intensity,
              });
            }
          }
        },
        redo: () => {
          this.unityHDRI.selectHDRI(nextHDRI, { skipHistory: true });
        },
      };
    });

    this.resetLight = this.undoRedo.createAction(() => {
      const state = getAppState();
      const prevSelectedHDRI = selectSelectedHDRI(state);
      const envColor = selectEnvironmentColor(state);
      const envColorIntensity = selectEnvironmentColorIntensity(state);
      const hdriRotation = selectHDRIRotation(state);
      const hdriIntensity = selectHDRIIntensity(state);
      const envLightType = selectEnvironmentLightType(state);

      return {
        undo: () => {
          // dispatcher(setEnvironmentColor(envColor))
          dispatcher(setEnvironmentColorIntensity(envColorIntensity));
          dispatcher(setHDRIRotation(hdriRotation));
          dispatcher(setHDRIIntensity(hdriIntensity));
          dispatcher(setSelectedHDRI(prevSelectedHDRI));
          dispatcher(setEnvironmentLightType(envLightType));

          if (envLightType === ENVIRONMENT_LIGHT.COLOR) {
            this.unityHDRI.updateColor(envColor);
          } else {
            this.unityHDRI.unitySelectHDRI(prevSelectedHDRI);
          }
        },
        redo: () => {
          this.unityHDRI.resetLight({ skipHistory: true });
        },
      };
    });
  };
}
