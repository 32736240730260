import _ from 'lodash';

export default function TopButtonArea(props) {
  const { topButtonList } = props;
  // console.log("props TopButtonArea: ", props);
  const disabled = !props.anySelected || props.loading;
  return (
    <div className="top-buttons align-self-center d-flex">
      {props.hasData ? (
        <>
          {topButtonList && topButtonList.length > 0
            ? _.map(topButtonList, (item, index) => {
                const { element: Component, cardId, ...cardProps } = item;
                return (
                  <Component
                    key={`topActionButton${_.startCase(props.item)}${cardId}${index}}`}
                    cardType={props.item}
                    {...cardProps}
                    selectedList={props.selectedList}
                    disabled={disabled}
                  />
                );
              })
            : null}
        </>
      ) : null}
      {props.addNewButtonArea}
    </div>
  );
}
