import React, { memo } from 'react';
import cx from 'classnames';
import { useState, useEffect, useRef } from 'react';
import {
  apiGetAllNotifications,
  apiMarkAllAsReadNotifications,
  apiDeleteAllNotifications,
  apiMarkAsReadNotification,
} from '../../helpers/api';
import _ from 'lodash';
import ActionFilters from '../ActionFilters';
import { useScreenSettings } from '../../helpers/configureHelper';
import styles from './NotificationsPanel.module.scss';
import { getCommonMetadata } from '../../helpers/jsHelper';
import InfiniteScroll from '../InfiniteScroll';
import { errorToast, successToast, warningToast } from '../../helpers/toastHelper';
import { Loader } from '../';
import NotificationsPanelCard from './NotificationsPanelCard';
import { icons } from '../../assets';
import { useUserContext } from '../../contexts/UserContext';
const {
  VisibilityOffIcon,
  VisibilityOnIcon,
  TickIcon,
  DeleteIcon,
  ThreeDotIcon
} = icons;

const NotificationsPanel = memo(() => {
  const [notifyOption, setNotifyOption] = useState(false);

  const { currentUser } = useUserContext()
  const [notifications, setNotifications] = useState([]);
  const [unreadFlag, setUnreadFlag] = useState(false);
  const [mutedFlag, setMutedFlag] = useState(false);
  const [markAllAsRead, setMarkAllAsRead] = useState(false);
  const [clearAll, setClearAll] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filterField, setFilterField] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [total, setTotal] = useState(0);
  const [notificationTitle, setNotificationTitle] = useState('All');

  const cardType = 'notifications';
  const pageSettings = useScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption } = pageSettings;

  const [sortField, setSortField] = useState(defaultSortOption);

  const { hasData } = getCommonMetadata(notifications, pageNumber, totalPages);

  const hasMore = pageNumber < totalPages;

  async function loadNotifications(param = null) {
    const res = await apiGetAllNotifications(param);
    if (res?.total_pages) {
      setTotalPages(res.total_pages);
    }
    if (res?.count) {
      setTotal(res.count);
    }
    if (
      notifications?.length === 0 ||
      (notifications?.length !== 0 && pageNumber === 1) ||
      pageNumber === 1
    )
      setNotifications(res?.results);
    else {
      setNotifications([...notifications, ...res?.results]);
    }
    if (param === null) setNotificationTitle('All');
    setLoading(false);
  }

  useEffect(() => {
    let q = '';

    if (sortField !== '' && sortField !== null && sortField !== undefined) {
      if (q !== '') q = `${q}&`;
      q += `ordering=${sortField?.value}`;
    }
    if (unreadFlag) {
      if (q !== '') q = `${q}&`;
      q += `unread=${unreadFlag}`;
    }
    if (mutedFlag) {
      if (q !== '') q = `${q}&`;
      q += `is_muted=${mutedFlag}`;
    }
    if (filterField !== '' && filterField !== null && filterField !== undefined) {
      if (q !== '') q = `${q}&`;
      q += `type=${filterField}`;
    }

    if (q !== '') {
      q = pageNumber === 1 ? `?${q}&page=1` : `?${q}&page=${pageNumber}`;
    } else {
      q = pageNumber === 1 ? `?page=1` : `?page=${pageNumber}`;
    }

    setQuery(q);
  }, [sortField, filterField, unreadFlag, mutedFlag, pageNumber]);

  function fetchMoreData() {
    if (pageNumber < totalPages) {
      setPageNumber((prev) => Number(prev) + 1);
    }
  }
  function handleFilter(field, selection) {
    setPageNumber(1);
    setFilterField(field?.value);
  }
  function handleSort(e) {
    setPageNumber(1);
    setSortField(e);
  }

  useEffect(() => {
    if (query !== '' || query !== null || query !== undefined) loadNotifications(query);
  }, [query, currentUser]);

  useEffect(() => {
    if (unreadFlag && !mutedFlag) {
      setNotificationTitle('Unread');
    } else if (!unreadFlag && mutedFlag) {
      setNotificationTitle('Muted');
    } else if (unreadFlag && mutedFlag) {
      setNotificationTitle('Unread & Muted');
    } else {
      setUnreadFlag(false);
      setMutedFlag(false);
      setNotificationTitle('All');
      setLoading(true);
    }
  }, [unreadFlag, mutedFlag]);

  const markAsRead = async (id) => {
    const res = await apiMarkAsReadNotification(id);
    console.log("res: ", res);
    if (res && res.status) {
      successToast(res?.message);
      const updatedNotifications = notifications?.map(a => {
        if(a?.id === id) return res?.data;
        else return a
      });
      
      setNotifications(updatedNotifications);
    } else {
      errorToast(res?.message);
    }
  };
  const markAllRead = async () => {
    setLoading(true);
    if (!markAllAsRead) {
      const res = await apiMarkAllAsReadNotifications();
      if (res && res.status) {
        successToast(res?.message);
        setMarkAllAsRead(res.status);
      } else {
        errorToast(res?.message);
      }
      loadNotifications(query);
    } else {
      warningToast('No unread notifications.');
      loadNotifications(query);
    }
  };
  const clearAllNotifications = async () => {
    setLoading(true);
    if (!clearAll) {
      const res = await apiDeleteAllNotifications();
      if (res && res.status) {
        successToast(res?.message);
        setClearAll(res.status);
      } else {
        errorToast(res?.message);
      }
      loadNotifications(query);
    } else {
      warningToast('No notifications to clear.');
      setLoading(false);
    }
    setUnreadFlag(false);
    setMutedFlag(false);
    setMarkAllAsRead(false);
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <aside className={cx(styles.notificationWrapper)}>
        <div className={cx(styles.notificationHeader)}>
          <div>
            <h5 className={styles.title}>Notifications</h5>
            <button className={styles.button} onClick={() => setNotifyOption(!notifyOption)}>
              <ThreeDotIcon />
            </button>

            {notifyOption && (
              <div className={styles.notificationAction}>
                <ul>
                  <li>
                    <button
                      className={unreadFlag ? 'active' : ''}
                      onClick={(e) => {
                        setPageNumber(1);
                        setUnreadFlag(!unreadFlag);
                      }}>
                      {unreadFlag ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                      <span>Show unread only</span>
                    </button>
                  </li>
                  <li>
                    <button
                      className={mutedFlag ? 'active' : ''}
                      onClick={(e) => {
                        setPageNumber(1);
                        setMutedFlag(!mutedFlag);
                      }}>
                      {mutedFlag ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                      <span>Show muted</span>
                    </button>
                  </li>
                  <li>
                    <button onClick={(e) => markAllRead()}>
                      <TickIcon />
                      <span>Mark all as read</span>
                    </button>
                  </li>
                  <li>
                    <button onClick={(e) => clearAllNotifications()}>
                      <DeleteIcon />
                      <span>Clear all notifications</span>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className={cx(styles.notificationHeader, styles.filters)}>
          <ActionFilters
            filterProps={{
              value: filters?.selection,
              data: filterOptions,
              onChange: (e) => handleFilter(e),
            }}
            sortProps={{
              value: sortField,
              data: sortOptions,
              onChange: (e) => handleSort(e),
            }}
          />
          <h5 className={styles.notificationTitle}>
            {notificationTitle}
            {notificationTitle.toLowerCase() !== 'all' && <span>{notifications?.length}</span>}
          </h5>
        </div>
        <div id="infiniteScroll" className={styles.notificationsContainer}>
          <InfiniteScroll
            dataLength={notifications.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loading={pageNumber !== 1 ? loading : false}
            hasChildren={hasData}
            scrollableTarget="infiniteScroll">
            {loading === false ? (
              notifications?.length > 0 ? (
                notifications
                  ?.filter((a) => a.verb !== '')
                  .map((a) => <NotificationsPanelCard data={a} onClick={markAsRead} />)
              ) : (
                <h6>No Notifications Found!!!</h6>
              )
            ) : (
              pageNumber === 1 && (
                <Loader
                  loading={loading}
                  position="relative"
                  backdrop={false}
                  className="scroll-loader"
                />
              )
            )}
          </InfiniteScroll>
        </div>
      </aside>
    </span>
  );
});

export default NotificationsPanel;
