import cx from 'classnames';
import { Form, FormControl } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from './SelectController.module.scss';


export const SelectController = ({ control, name, isMulti, label, selectStyles, creatable, containerClassName, ...rest }) => {
  // TODO: handle multiple values, currently only single value is supported

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { touchedFields, dirtyFields }, // TODO: work on it
  } = useController({
    name,
    control,
    // rules: { required: true },
    defaultValue: '',
  });

  const selectValue = isMulti ? value : rest.options.find((o) => o.value === value);

  const handleChange = (value) => {
    onChange(isMulti ? value : value.value);
  };

  const hasError = Boolean(error?.message);

  const Select = creatable ? CreatableSelect : ReactSelect;

  return (
    <Form.Group className={containerClassName}>
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        {...rest}
        value={selectValue}
        name={name}
        onChange={handleChange}
        onBlur={onBlur}
        ref={ref}
        isMulti={isMulti}
        styles={selectStyles}
      />
      <FormControl.Feedback
        type={hasError ? 'invalid' : 'valid'}
        style={{
          display: hasError ? 'block' : 'none', // TODO: work on it, for some reason bootstrap doesn't show invalid feedback
        }}>
        {error?.message}
      </FormControl.Feedback>
    </Form.Group>
  );
};
