import * as yup from 'yup';
import cx from 'classnames';
import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Button from '../../../../Button/Button';
import {
  MAX_PREVIEW_NAME_LENGTH,
  MIN_PREVIEW_NAME_LENGTH,
} from '../../../../../config/constant/validationConstant';
import { useSaveRenderPreview } from './useSaveRenderPreview';
import styles from './PreviewRenderForm.module.scss';
import { RP_TYPE } from '../../../../../config/constant/unityConstants';
import { errorToast } from '../../../../../helpers/toastHelper';

export const PreviewRenderForm = ({
  type,
  onSubmit,
  className,
  loading,
  isSiloRoom,
  hasMetadata,
}) => {
  const imageTypesDefault = ['jpg', 'png', 'tiff'];
  const imageTypesWithTransparency = ['png', 'tiff'];

  const schema = useMemo(() => {
    return yup.object({
      // name: yup
      //   .string()
      //   .min(MIN_PREVIEW_NAME_LENGTH, 'Please provide name')
      //   .max(
      //     MAX_PREVIEW_NAME_LENGTH,
      //     `Name length should not be longer than ${MAX_PREVIEW_NAME_LENGTH}`
      //   )
      //   .required('Name is missing'),
    });
  }, []);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      metadata: false,
      watermark: false,
      transparentBg: false,
      shadowOnFloor: true,
      imageType: 'jpg',
    },
  });

  const isTransparent = watch('transparentBg');
  const isRender = type === RP_TYPE.RENDER;

  useEffect(() => {
    setValue('imageType', isTransparent ? 'png' : 'jpg');
  }, [isTransparent]);

  const nameRegister = register('name');
  const handleNameChange = (e) => {
    const { value } = e.target;
    const specialChars = /[\\\/:\.?'<>|]/;
    if (specialChars.test(value)) {
      setValue('name', getValues('name'));
      errorToast('Special characters are not allowed', null, { toastId: 'NAME_SPECIAL_CHARS' });
      return;
    }

    nameRegister.onChange(e);
  };

  const imageTypes = isTransparent ? imageTypesWithTransparency : imageTypesDefault;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cx(styles[className], styles.form)}
      autoComplete="off">
      <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
      <div className="drop-input">
        <label className="form-label">File name</label>
        <div className="input-group d-flex flex-wrap preview">
          <input
            type="text"
            className={`form-control ${styles.nameInput} ${isRender ? styles.renderNameInput : ''}`}
            name="name"
            {...nameRegister}
            onChange={handleNameChange}
            placeholder={isRender ? 'Render Name' : 'Preview Name'}
          />

          {isRender && (
            <div className="input-group-append">
              <select className="rp-type" {...register('imageType')} name="imageType">
                {imageTypes?.map((a) => (
                  <option key={a} value={a}>
                    {a.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>

        <div
          className="invalid-feedback"
          style={{ display: errors.name?.message ? 'block' : 'none' }}>
          {errors.name?.message}
        </div>
      </div>

      <div className={cx(`d-flex flex-wrap`, styles.checkboxContainer, isRender ? 'pt-3' : 'pt-4')}>
        {isRender && hasMetadata && (
          <div className="form-check custom-checkbox d-flex align-items-center ">
            <input
              className="form-check-input"
              type="checkbox"
              name="metadata"
              {...register('metadata')}
            />
            <span>Metadata</span>
          </div>
        )}

        <div className="form-check custom-checkbox d-flex align-items-center ">
          <input
            className="form-check-input"
            type="checkbox"
            name="transparentBg"
            {...register('transparentBg')}
          />
          <span>Transparent Bg</span>
        </div>

        {/* {isRender && (
            <div className="form-check custom-checkbox d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                name="watermark"
                {...register('watermark')}
              />
              <span>Watermark</span>
            </div>
          )} */}
        {isSiloRoom && (
          <div className="form-check custom-checkbox d-flex align-items-center">
            <input
              className="form-check-input"
              type="checkbox"
              name="shadowOnFloor"
              {...register('shadowOnFloor')}
            />
            <span>Shadow on floor</span>
          </div>
        )}
      </div>

      <Button
        type="submit"
        color="warning"
        className={cx(isRender ? 'mt-3' : 'mt-4', 'w-100')}
        size="xlg"
        loading={loading}>
        {isRender ? 'Send for Rendering' : 'Send for Preview'}
      </Button>
    </form>
  );
};
