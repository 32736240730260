export const Placeholder = ({ width, height, maxWidth, backgroundColor = 'var(--neutral10-color)', className }) => {
  return (
    <div 
      className={className}
      style={{
        width: width || '100%',
        maxWidth,
        height: height || 24,
        backgroundColor,
        borderRadius: 4,
      }}
    />
  )
}