
import { useSimpleDataList } from "./useDataList";
import { transactionHistoryActions, transactionHistorySelector } from "../redux/slicers/transactionHistorySicer";
import { getTransactionListApi } from "../api/transactionHistoryApi";

export const useTransactionsHistory = ({ requestParams }) => {
  const data = useSimpleDataList({
    selector: transactionHistorySelector,
    resetAction: transactionHistoryActions.resetList,
    actions: transactionHistoryActions,
    getAction: getTransactionListApi,
    requestParams,
  });

  return data;
};
