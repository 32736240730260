import { useEffect, useMemo, useState } from 'react';

let state = {
  loading: false,
  imported: false,
  module: null,
};

export const useCountryStateCity = () => {
  const [loading, setLoading] = useState(false);
  const [module, setModule] = useState(state.module);

  const importNpmModule = async () => {
    setLoading(true)
    const module = await import('country-state-city');
    setModule(module);
    state.module = module;
    setLoading(false)
  };

  useEffect(() => {
    if (state.module) {
      return;
    }

    importNpmModule();
  }, []);

  const countryStateCityWrapper = useMemo(() => {
    if (module) return module;
    
    return {
      Country: {
        getCountryByCode: (...args) => {
          return module ? module.Country.getCountryByCode(...args) : [];
        },
        getAllCountries: (...args) => {
          return module ? module.getAllCountries(...args) : [];
        },
      },
      State: {
        getAllStates: (...args) => (module ? module.State.getAllStates(...args) : []),
        getStatesOfCountry: (...args) => (module ? module.State.getStatesOfCountry(...args) : []),
        getStateByCodeAndCountry: (...args) =>
          module ? module.State.getStateByCodeAndCountry(...args) : undefined,
        getStateByCode: (...args) => (module ? module.State.getStateByCode(...args) : undefined),
      },
      City: {
        getAllCities: (...args) => (module ? module.State.getAllCities(...args) : []),
        getCitiesOfState: (...args) => (module ? module.State.getCitiesOfState(...args) : []),
        getCitiesOfCountry: (...args) =>
          module ? module.State.getCitiesOfCountry(...args) : [],
      },
    };
  }, [module]);

  return {
    loading,
    Country: countryStateCityWrapper.Country,
    State: countryStateCityWrapper.State,
    City: countryStateCityWrapper.City,
  }
};
