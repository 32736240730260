import { Link } from 'react-router-dom';
import { MODEL_TYPES } from '../constants/constants';
import { ADMIN_MY_PRODUCTS_ROOMS_DETAILS, TEMPLATES_DETAILS } from '../constants/routePathConstants';
import { useUserContext } from '../contexts/UserContext';
import { getCuratorRoomPath } from '../helpers/urlHelper';
import { useTemplateActions } from '../hooks/useTemplateActions';
import ThumbnailCardUI from './ThumbnailCardUI';

export const ThumbnailCardTemplate = ({
  data,
  size,
  fullWidth,
  onSelect,
  selected,
  to = TEMPLATES_DETAILS,
  hideFavourite,
  onEdit,
  modelType = MODEL_TYPES.TEMPLATES,
  ...rest
}) => {
  const { favouriteLoading, onFavouriteToggle, onShare, addToCollection } = useTemplateActions({
    item: data,
  });

  const { canAccessLiveOnApp, currentUser } = useUserContext();
  const isTemplate = modelType === MODEL_TYPES.TEMPLATES

  const url = getCuratorRoomPath(data.id);
  const secondLine = data?.owner_organization_details?.name || "";

  return (
    <ThumbnailCardUI
      key={data.id}
      id={data.id}
      onEdit={onEdit || (() => window.open(url, '_blank'))}
      to={to}
      thumbnail={data?.thumbnail}
      images={data?.images}
      name={data.name}
      isFavourite={data.is_favourite}
      favouriteLoading={favouriteLoading}
      date={(isTemplate && canAccessLiveOnApp() && data?.is_custom) ? data?.created_at : undefined}
      // date={(isTemplate && isStaffUser && isLiveOnAppAccess && data?.is_custom) ? data?.created_at : undefined}
      onShare={onShare}
      onFavouriteChange={onFavouriteToggle(data)}
      size={size}
      titleProps={{
        as: Link,
        target: '_blank',
        to: url,
      }}
      fullWidth={fullWidth}
      onSelect={onSelect}
      selected={selected}
      onAddToCollection={addToCollection}
      hideFavourite={hideFavourite}
      data={data}
      secondLine={(canAccessLiveOnApp() && data?.is_custom) ? '' : secondLine}
      {...rest}
    />
  );
};

export const ThumbnailCardRoom = (props) => {
  return <ThumbnailCardTemplate {...props} to={props.to || ADMIN_MY_PRODUCTS_ROOMS_DETAILS} modelType={MODEL_TYPES.ROOM} />
}