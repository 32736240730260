import { useThumbnailCardDataList } from './useDataList';
import { texturesActions, texturesSelector } from '../redux/slicers/texturesSlicer';
import { getTexturesPageApi, getUploadsPageApi } from '../api/textures';
import { useMemo } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { uploadsPageActions, uploadsPageSelector } from '../redux/slicers/uploadsPageSlicer';

export const useTextures = ({
  requestParams,
  filterByKey,
  defaultBrands,
  defaultSort = '-created_at',
  useGlobalSearch,
  modelType,
} = {}) => {
  const data = useThumbnailCardDataList({
    selector: texturesSelector,
    resetAction: texturesActions.resetList,
    getAction: getTexturesPageApi,
    requestParams,
    filterByKey,
    defaultBrands,
    defaultSort,
    useGlobalFilter: true,
    useGlobalSearch,
    modelType,
  });

  return data;
};

export const useUploads = ({ filterByKey, defaultSort = '-created_at', useGlobalSearch, modelType } = {}) => {
  const { currentUser } = useUserContext();
  const { organization, id } = currentUser;

  const requestParams = useMemo(
    () => ({
      created_by: id,
      is_map: true,
    }),
    [id]
  );

  return useThumbnailCardDataList({
    selector: uploadsPageSelector,
    resetAction: uploadsPageActions.resetList,
    getAction: getUploadsPageApi,
    requestParams,
    filterByKey,
    defaultBrands: organization,
    defaultSort,
    useGlobalFilter: true,
    useGlobalSearch,
    modelType,
  });
  
  
};
