import * as yup from 'yup';
import { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  MAX_PROJECT_NAME_LENGTH,
  MIN_PROJECT_NAME_LENGTH,
} from '../config/constant/validationConstant';
import Button from '../components/Button/Button';
import { apiCreateCollection } from '../helpers/api';
import { apiErrorHandler } from '../helpers/axiosHelper';
import { useUserContext } from '../contexts/UserContext';
import { InputController } from '../components/hookForm/InputController';
import { collectionListActions } from '../redux/slicers/collectionListSlicer';
import { useDispatch } from 'react-redux';

export const CreateCollectionForm = ({ onCancel, onSuccess }) => {
  const dispatch = useDispatch();
  const { currentUser } = useUserContext();

  const schema = useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .min(MIN_PROJECT_NAME_LENGTH, 'Collection name is missing')
        .max(
          MAX_PROJECT_NAME_LENGTH,
          `Collection length should not be longer than ${MAX_PROJECT_NAME_LENGTH}`
        )
        .required('Collection name is missing'),
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const createCollection = async (values) => {
    const payload = { ...values };
    payload.owner = currentUser.id;

    try {
      const result = await apiCreateCollection(payload);

      if (result && result.status) {
        if (result.data) {
          dispatch(collectionListActions.addSuccess(result.data));
          onSuccess?.(result.data);
          return;
        }
      }

      throw new Error('Unable to create collection. LF_928');
    } catch (error) {
      apiErrorHandler(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(createCollection)} autoComplete="off">
      <InputController
        label="Name"
        placeholder="Type a name"
        name="name"
        className="mb-3"
        control={control}
      />

      <InputController
        label="Description"
        placeholder="Add description"
        name="description"
        className="mb-4"
        multiline
        control={control}
        noResize
        height={172}
      />

      <div className="d-flex gap-3">
        <Button
          type="button"
          size="xlg"
          color="dark"
          variant="outline"
          fullWidth
          onClick={onCancel}
          disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="xlg"
          color="warning"
          fullWidth
          disabled={isSubmitting}
          loading={isSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
};
