export const LinkIcon = (props) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.5 7H14.5C13.95 7 13.5 7.45 13.5 8C13.5 8.55 13.95 9 14.5 9H17.5C19.15 9 20.5 10.35 20.5 12C20.5 13.65 19.15 15 17.5 15H14.5C13.95 15 13.5 15.45 13.5 16C13.5 16.55 13.95 17 14.5 17H17.5C20.26 17 22.5 14.76 22.5 12C22.5 9.24 20.26 7 17.5 7ZM8.5 12C8.5 12.55 8.95 13 9.5 13H15.5C16.05 13 16.5 12.55 16.5 12C16.5 11.45 16.05 11 15.5 11H9.5C8.95 11 8.5 11.45 8.5 12ZM10.5 15H7.5C5.85 15 4.5 13.65 4.5 12C4.5 10.35 5.85 9 7.5 9H10.5C11.05 9 11.5 8.55 11.5 8C11.5 7.45 11.05 7 10.5 7H7.5C4.74 7 2.5 9.24 2.5 12C2.5 14.76 4.74 17 7.5 17H10.5C11.05 17 11.5 16.55 11.5 16C11.5 15.45 11.05 15 10.5 15Z"
        fill="currentColor"
      />
    </svg>
  );
};
