import { createPaginatedSlice } from "../../../helpers/reduxHelper";

export const TAB_NAME = {
  PREVIEWS: '1',
  RENDERS: '2',
};

const paginatedRenderPreviews = createPaginatedSlice('paginatedRenderPreviews', {
  defaultState: {
    activeTab: TAB_NAME.PREVIEWS,
    createdRenderList: [], // we keep this list to have this data between swtiching from renders to previews and vice verca
    createdPreviewList: [], // it's never shown in UI, instead we show list from paginatedRenderPreviewsState
    
  },
  reducers: {
    resetCreatedLists: (state) => {
      state.createdRenderList = [];
      state.createdPreviewList = [];
    },
    addRender: (state, { payload }) => {
      const { data, activeTab } = payload;
      state.createdRenderList = [data, ...state.createdRenderList]

      if (activeTab == TAB_NAME.RENDERS) {
        state.list = [data, ...state.list];
        state.dataLoadedOnce = true;
        state.hasData = true;
      }
    },
    addPreview: (state, { payload }) => {
      const { data, activeTab } = payload;
      state.createdPreviewList = [data, ...state.createdPreviewList]

      if (activeTab == TAB_NAME.PREVIEWS) {
        state.list = [data, ...state.list];
        state.dataLoadedOnce = true;
        state.hasData = true;
      }
    },
    setRenderList: (state, { payload }) => {
      state.createdRenderList = payload;
    },
    setPreviewList: (state, { payload }) => {
      state.createdPreviewList = payload;
    },
    setRenderPreviewList: (state, { payload }) => {
      const newList = [...payload, ...state.list];
      state.list = newList;
      state.dataLoadedOnce = true;
      state.hasData = newList.length > 0;
    },
    setActiveTab: (state, { payload }) => {

      // copy up to date data of newly created items from current list
      const items = state.activeTab === TAB_NAME.PREVIEWS ? state.createdPreviewList : state.createdRenderList;
      const idList = items.map(item => item.id);
      const upToDateItems = state.list.filter(item => idList.includes(item.id));

      if (state.activeTab === TAB_NAME.PREVIEWS) {
        state.createdPreviewList = upToDateItems;
      } else {
        state.createdRenderList = upToDateItems;
      }

      // update active tab
      state.activeTab = payload;
    },
  }
});

export  const {
  addRender,
  addPreview,
  setRenderList,
  setPreviewList,
  setRenderPreviewList,
  resetCreatedLists,
} = paginatedRenderPreviews.actions

export const paginatedRenderPreviewsActions = paginatedRenderPreviews.actions
export const paginatedRenderPreviewsSelector = state => state.paginatedRenderPreviews;
export const paginatedRenderPreviewsReducer = paginatedRenderPreviews.reducer;
export default paginatedRenderPreviewsReducer

export const paginatedRenderPreviewsSelectors = paginatedRenderPreviews.LF.selectors

export const selectCreatedPreviewList = (state) => state.paginatedRenderPreviews.createdPreviewList
export const selectCreatedRenderList = (state) => state.paginatedRenderPreviews.createdRenderList
export const selectActiveTab = (state) => state.paginatedRenderPreviews.activeTab