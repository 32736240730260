import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: discuss implementing sentry for errors tracking
    // You can also log the error to an error reporting service
    // captureException(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div>
          Uh-Oh... An unexpected error occurred, don&apos;t worry, we are on it. In the mean time, please
          try again...
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
