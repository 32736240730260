import ReactInfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from './';

export default function InfiniteScroll({ loading, ...rest }) {
  const hasData = rest.dataLength !== 0;
  const loader = rest.hasMore && loading ? (
    null
  ) : null;

  return (
    <Loader loading={rest.hasData ? false : loading} position="relative" backdrop={false} className="scroll-container fixed">
      <ReactInfiniteScroll loader={loader} {...rest} />
    </Loader>
  );
}
