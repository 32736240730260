import { createSlice } from '@reduxjs/toolkit';

const appConfig = createSlice({
  name: 'appConfig',
  initialState: {
    roomTypes: null,
  },
  reducers: {
    setRoomTypes: (state, { payload }) => {
      state.roomTypes = payload;
    },
  },
});

export const { setRoomTypes } = appConfig.actions;
export const appConfigSelector = (state) => state.appConfig;
export const appConfigReducer = appConfig.reducer;
export default appConfigReducer;

export const selectRoomTypes = (state) => state.appConfig.roomTypes