import _ from 'lodash';
import { useMemo } from 'react';
import { CommonGrid, TopContainer } from '../layouts';
import {
  TopButtonArea,
  ActionFilters,
  GlobalFilter,
  SharePopup,
  DeactivatePopup,
} from '../common';

import { apiDeleteCollections } from '../helpers/api';
import { useScreenSettings } from '../helpers/configureHelper';
import { DELETE_ICON } from '../constants/assetConstants';
import { usePageTitle } from '../hooks/usePageTitle';
import { useUserContext } from '../contexts/UserContext';
import { useCollections } from '../hooks/useCollections';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import { ThumbnailContainerV2 } from '../common/ThumbnailContainerV2';
import { MODEL_TYPES } from '../constants/constants';

let title = 'Collections';

export default function Collections({ useGlobalSearch, noDataText, cardType = 'collections', cardProps }) {
  usePageTitle(title);
  const { currentUser } = useUserContext();
  const currentMemberId = currentUser.id;
  const { showTeamMemberFilter, defaultSortOption } = useScreenSettings(cardType);

  const requestParams = useMemo(() => ({ created_by: currentMemberId }), [currentMemberId]);

  const {
    list,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,

    selected,
    anySelected,
    allSelected,
    selectedList,
    toggleAll,
    toggleItem,
    refetchList,
  } = useCollections({ requestParams, defaultSort: defaultSortOption, useGlobalSearch });

  return (
    <CommonGrid
      noDataText={noDataText}
      topContainer={
        !useGlobalSearch && (
          <TopContainer
            title={
              <>
                {title} {total > 1 && <small>({total})</small>}
              </>
            }
            item={cardType}
            hasData={hasData}
            buttonArea={
              !useGlobalSearch && (
                <TopButtonArea
                  hasData={hasData}
                  item={cardType}
                  topButtonList={[
                    {
                      element: DeactivatePopup,
                      bigButton: true,
                      bigBtnClass: 'lf-btn-3',
                      isPlural: true,
                      iconSrc: DELETE_ICON,
                      deleteAction: apiDeleteCollections,
                      item: cardType,
                      onSuccess: refetchList,
                      actionWord: 'delete',
                    },
                    {
                      element: SharePopup,
                      bigButton: true,
                      bigBtnClass: 'lf-btn-2',
                      onSuccess: refetchList,
                      modelType: 5,
                      selectedList,
                    },
                  ]}
                  addNewButtonArea={null}
                  anySelected={anySelected}
                  selectedList={selectedList}
                />
              )
            }
          />
        )
      }
      item={cardType}
      hasData={list.length > 0 || loading}
      list={list}
      filterArea={
        !useGlobalSearch && (
          <>
            <ActionFilters
              selectedProps={{
                allSelected: allSelected,
                onSelectAll: () => toggleAll(),
              }}
              showTeamMemberFilter={showTeamMemberFilter}
            />
            <GlobalFilter />
          </>
        )
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainerV2
          items={list}
          modelType={MODEL_TYPES.COLLECTION}
          selected={selected}
          cardProps={{
            fullWidth: true,
            onSelect: toggleItem,
            ...cardProps,
          }}
        />
      </InfiniteScroll>
    </CommonGrid>
  );
}
