//login session
export const LOGIN_HOME = '/sign-in'
export const SIGN_IN_ROUTE = '/sign-in'
export const FORGET_ROUTE = '/forget-password'
export const SIGNUP_ROUTE = '/register'
export const TERMS_CONDITION_ROUTE = '/terms-condition'
export const RESET_PASSWORD_ROUTE = '/reset-password/:token'
export const OTP_VERIFY_ROUTE = '/verify'
export const CUSTOMIZE_EXPERIENCE = '/customize-experience'
export const INVITE_ROUTE = '/invite/:token'
export const LINK_VERIFY_ROUTE = '/verify-email-with-link/:token'

//admin routes
export const ADMIN_HOME = '/dashboard'
export const ADMIN_HOME_PATH = '/home'
export const ADMIN_DASHBOARD = '/dashboard'
export const ADMIN_SETTINGS = '/settings'
export const ADMIN_TEAMS = '/teams'
export const SEARCH = '/s'
export const SEARCH_TEXT = '/s/:text'
export const COMMON_HOME = '/'

// user routes
export const MY_ACCOUNT_ROUTE = '/my-account'
export const ADD_CREDITS_ROUTE = '/add-credits'
export const LOGOUT_ROUTE = '/sign-in'
export const QUIT_ROUTE = '/quit'

export const MY_PRODUCTS_ROUTE = '/products'
export const MY_PROJECTS_ROUTE = '/projects'
export const RENDERS_PREVIEWS_ROUTE = '/renders-previews'
export const ROOMS_ROUTE = '/rooms'
export const TEMPLATES_ROUTE = '/templates'
export const PROPS_ROUTE = '/my-props'
export const COLLECTIONS_ROUTE = '/collections'
export const COLLECTION_ID = '/collection/:id'
export const RENDER_DETAILS = '/render-details/:id'
export const ROOM_RENDER = '/room-details'
export const PROJECT_RENDER = `${MY_PROJECTS_ROUTE}/:projectId`;
export const PRODUCT_RENDER = '/products/rooms/preview/:id'

// exporting sidebar menu routes
export const MY_TEAM_ROUTE = '/teams'
export const SHARED_FILES_ROUTE = '/shared-files'
export const CALENDAR_ROUTE = '/calendar'
export const COMMUNITY_ROUTE = '/community'
export const SETTINGS_ROUTE = '/settings'
export const TRASH_ROUTE = '/trash'
export const PLAY_TO_LEARN_ROUTE = '/play-to-learn'
export const REQUESTS_ROUTE = '/requests'
export const SUPPORT_ROUTE = '/support'

export const ADMIN_CURATOR = '/curator'
export const ADMIN_CURATOR_TYPE_ID = '/curator/:type/:id'
export const ADMIN_CURATOR_ROOM_ID = '/curator/:type/:room_id'
// export const ADMIN_CURATOR_ROOM_ID_MODE = '/curator/:type/:room_id/:mode/:modeId'
export const ADMIN_MY_PROJECTS = '/projects'
export const ADMIN_LIST_MEMBERS = '/my-list-members'
export const ADMIN_NEW_PROJECT = '/projects'
export const ADMIN_MY_ROOMS = '/rooms'
export const ADMIN_MY_ROOMS_PARAMS = '/rooms/:id'
export const ADMIN_MY_PROPS = '/my-props'
export const ADMIN_MY_PRODUCTS_3DMODAL = '/products/3dmodel'
export const ADMIN_MY_PRODUCTS_ROOMS = '/products/rooms'
export const ADMIN_MY_PRODUCTS_TEXTURE = '/products/textures'
export const ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD = '/products/textures/upload'
export const ADMIN_MY_PRODUCTS_TEXTURE_EDIT = '/products/textures/edit'
export const ADMIN_MY_PRODUCTS_UPLOADS = '/products/uploads'
export const ADMIN_MY_PRODUCTS_UPLOADS_MOVE_TO_TEXTURE = '/products/uploads/move-to-texture'
export const ADMIN_MY_PRODUCTS_3DMODAL_EACH = '/products/3dmodel/:id'

export const INSPIRATION_ROUTE = '/inspiration'
export const RECENT_DESIGNS_ROUTE = '/recent-designs'

//Detail routes
export const DETAIL_PAGE = '/detail-page'
