import {createSlice} from "@reduxjs/toolkit";
import { getFilterOrder } from "../../../hooks/useDataList";


const sidebar = createSlice({
    name: 'sidebar',
    initialState: {
       labels: [],
       query:[],
       labelCheck : [],
       filtersData: null,
       filterValues: [],
       filtersByKey: {},
    },
    reducers: {
        setLables: (state, {payload}) => {
            state.labels = payload; // seems like we need to remove it
            state.filtersData = payload;
        },
        setFilters: (state, { payload }) => {
            state.filtersByKey[payload.key] = payload.filters;
        },
        setQueryFilter:(state,{payload}) => {
            state.query = payload
        },
        setLabelCheck:(state,{payload}) => {
            state.labelCheck = payload
        },
        changeSideFilter: (state, { payload }) => {
            const { baseOrder } = getFilterOrder(state.filterValues);

            const objOrder = baseOrder.reduce((acc, item) => {
                acc[item.name] = item.order;
                return acc;
            }, {});

            // debugger;
            let newFilters = state.filterValues.includes(payload)
                ? state.filterValues.filter((x) => x !== payload)
                : [...state.filterValues, payload];

            const orderedFilters = newFilters.sort((a, b) => {
                const [aName] = a.split('_');
                const [bName] = b.split('_');
                const aOrder = objOrder[`${aName}s`]
                const bOrder = objOrder[`${bName}s`]

                return aOrder - bOrder 
            })
                
            state.filterValues = orderedFilters
        },
        resetFilters: (state) => {
            state.filtersData = null;
            state.filterValues = [];
        },
        afterAddToCollection: (state, { payload }) => {
            const filteredCollections = payload.filter((c) => {
                const exist = state.filtersData?.collections?.find((collection) => collection.id === c.id);
                return !exist;
              });

            state.filtersData?.collections.push(
                ...filteredCollections.map(c => ({
                    id: c.id,
                    name: c.name,
                    description: c.description,
                }))
            )
        }
       
    }
})

export const {setLables,setQueryFilter,setLabelCheck, changeSideFilter, resetFilters, setFilters, afterAddToCollection} = sidebar.actions;
export const sidebarSelector = state => state.sidebar;
export const sidebarReducer = sidebar.reducer;
export default sidebarReducer


