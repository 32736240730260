import PubSub from 'pubsub-js';
import {
  GENERATE_MAP_TYPE,
  REVERSE_PROPERTY_MAP,
  TEXTURE_MAP,
  TEXTURE_MAP__TEXTURE_NAME_KEY,
  TILING_MAP,
  TILING_NAME_MAP,
  TILING_TEXTURE_MAP,
  TILING_TYPE,
} from '../../../../config/constant/unityConstants';
import { toggleExpertMode } from '../../../../helpers/curatorActions';
import { getMaterial, getTexture } from '../../../../helpers/idb';
import { wait } from '../../../../helpers/jsHelper';
import { dispatcher } from '../../../../helpers/projectHelper';
import { inchesToUnitySize } from '../../../../helpers/unityHelper';
import { AppEvent, UnityEvent, waitForEventOnce } from '../../../../helpers/unityPubSub';
import { setPasteMaterialLoading } from '../../../../redux/slicers/admin/curatorLoaderSlicer';
import {
  selectCopiedObjectProperties,
  selectDataByTilingType,
  selectParsedObject,
  selectSelectedObjectList,
  selectSelectedObjectName,
  selectUnitySelectedObjectInfoRaw,
  updateUnityObject,
} from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { getAppState } from '../../../../redux/store';
import { getDefaultTextureImageUrl } from '../stylesSection/PropertiesTab/useGetDefaultObjectMaps';
import { initialTextures } from '../stylesSection/useUnityRedux';
import { mapObjectToSelect } from './UndoRedoUnityObject';

export class UndoRedoObjectProperties {
  constructor({
    undoRedoInstance,
    unityObjectProperties,
    unityColor,
    unityContext,
    unityMaterial,
  }) {
    this.undoRedo = undoRedoInstance;
    this.unityObjectProperties = unityObjectProperties;
    this.unityColor = unityColor;
    this.unityContext = unityContext;
    this.unityMaterial = unityMaterial;
    this.skipChangeTilingPropertiesEventOnce = false;

    this.textureMaterialApplyHistory = {
      // example: ['texture', 'material', 'texture', 'color', 'color']
    };

    this.copyPasteData = {
      materialList: [],
      objectsBeforePaste: [],
      pasteParams: null,
      materialConfig: null,
    };

    this.subscribeTokens = [];

    this.init();
    this.subscribe();
  }

  subscribe = () => {
    const token = PubSub.subscribe(UnityEvent.PasteMaterialPropertiesFinishedReceive, () => {
      this.copyPasteMaterialFinished();
    });

    const token1 = PubSub.subscribe(
      UnityEvent.ReceiveObjectInfoBeforePasteMaterial,
      (_, objectData) => {
        this.copyPasteData.objectsBeforePaste.push(objectData);
      }
    );

    const token2 = PubSub.subscribe(
      UnityEvent.OnReceiveUpdatedPasteMaterialData,
      (_, materialConfig) => {
        this.copyPasteData.materialConfig = materialConfig;
      }
    );

    this.subscribeTokens.push(token);
    this.subscribeTokens.push(token1);
    this.subscribeTokens.push(token2);
  };

  unsubscribe = () => {
    this.subscribeTokens.forEach((token) => PubSub.unsubscribe(token));
    this.subscribeTokens = [];
  };

  addCopyPasteData = ({ materialId, objectName }) => {
    // when user paste material we will get target/targets material data which we store to make it possible to undo later
    this.copyPasteData.materialList.push({
      materialId,
      objectName,
    });
  };

  addPasteMaterialParams = (textureListOrNothing) => {
    this.copyPasteData.pasteParams = textureListOrNothing;
  };

  resetCopyPasteData = () => {
    this.copyPasteData = {
      materialList: [],
      objectsBeforePaste: [],
      pasteParams: null,
      materialConfig: null,
    };
  };

  _update = (tilingOptionType, data) => {
    dispatcher(
      updateUnityObject({
        [TILING_NAME_MAP[tilingOptionType]]: data,
      })
    );
  };

  _getKey = (objectName, mapType) => {
    return `${objectName}__${mapType}`;
  };

  _getLastApplied = (objectName, mapType) => {
    const key = this._getKey(objectName, mapType);
    const item = this.textureMaterialApplyHistory[key];
    return item[item.length - 1];
  };

  _addApplyTextureToHistory = (objectName, mapType, type) => {
    const key = this._getKey(objectName, mapType);
    if (!this.textureMaterialApplyHistory[key]) {
      this.textureMaterialApplyHistory[key] = [];
    }

    this.textureMaterialApplyHistory[key].push(type);
  };

  _removeLastApplied = (objectName, mapType) => {
    const key = this._getKey(objectName, mapType);
    const item = this.textureMaterialApplyHistory[key];
    item.pop();
  };

  _resetTilingValues = (tiling) => {
    this.unityContext.materialModule.OnUpdateRotationReceiver({
      ...tiling,
      tilingOptionType: tiling.tilingType,
    });

    this._update(tiling.tilingType, tiling);
  };

  _resetTilingAfterTextureApplied = (tiling) => {
    PubSub.subscribeOnce(AppEvent.ReceiveSelectedObjectInfoHandled, () => {
      this._resetTilingValues(tiling);
    });
  };

  _applyTiling = async (tiling) => {
    const texture = await getTexture(tiling.materialId);
    const mapType = TILING_TEXTURE_MAP[tiling.tilingType];
    this._skipChangeTilingPropertiesEventOnce = true;

    // wait for texture to be applied before reset tiling values
    this._resetTilingAfterTextureApplied(tiling);

    // apply texture
    await this.unityObjectProperties.applyTexture({
      mapType,
      texture,
      switchToProperties: true,
      skipHistory: true,
    });
  };

  _applyDefaultMaterialOrColor = async ({
    tilingType,
    objectName,
    mapType,
    materialId,
    color,
    rawObject,
    parsedObject,
  }) => {
    const textureMap = TILING_TEXTURE_MAP[tilingType];
    const textureNameKey = TEXTURE_MAP__TEXTURE_NAME_KEY[textureMap];
    const textureName = rawObject[textureNameKey];
    const textureUrl = await getDefaultTextureImageUrl({ textureName, materialId });

    if (textureUrl) {
      // when we reset to intial texture -> we reset to initial material so we need to change tiling type as there is not texture initially
      // const tiling = {
      //   ...prevTiling,
      //   tilingType: TILING_TYPE.MATERIAL,
      // }

      // wait for texture to be applied before reset tiling values -> for some reason seems that we don't need to do it
      // this._resetTilingAfterTextureApplied(tiling)

      // TODO: when user applies texture for the first time ->>> we might not have initial texture image so we need to request it
      // apply initial texture

      // we can not use this as it doesn't remove previous texture
      // this.unityContext.materialModule.OnSelectDefaultTextureReceiver({
      //   textureUrl,
      //   mapType,
      // });

      //   "texture": {
      //     "hideUI": true,
      //     "mapImage": "blob:http://localhost:3100/5faa88c7-be57-469d-93ba-d0296bf7f0d8",
      //     "hasMapImage": true,
      //     "tilingType": 0,
      //     "width": 36.47,
      //     "height": 27.03,
      //     "x": 0,
      //     "y": 0,
      //     "rotation": 0,
      //     "lock": false
      // },

      // _MainTex

      // material

      rawObject = {
        ...rawObject,
        tilingOffsetValue: {
          ...rawObject.tilingOffsetValue,
        }
      }

      const syncTilingValues = (key, tiling) => {
        
        if (!tiling) return;

        rawObject.tilingOffsetValue[key] = {
          ...rawObject.tilingOffsetValue[key],
          xMatTiling: inchesToUnitySize(tiling.width),
          yMatTiling: inchesToUnitySize(tiling.height),
          xMatOffset: tiling.x,
          yMatOffset: tiling.y,
          rotation: tiling.rotation,
        };
      }

      // raw object is out of date so we need to sync it with latest data
      Object.keys(rawObject.tilingOffsetValue).forEach((key) => {
        const parsedObjectKey = REVERSE_PROPERTY_MAP[key];
        const tiling = parsedObject[parsedObjectKey];
        syncTilingValues(key, tiling)
      });

      if (rawObject.materialTilingKeys[0]) {
        // add material data
        const materialKey = rawObject.materialTilingKeys[0];
        syncTilingValues(materialKey, parsedObject.material)
      }

      await this.unityContext.materialModule.SetMaterialPropertiesOnObjectReceiver(rawObject);
      const object = { ...rawObject, TRIGGERED_FROM_REACT: true };
      PubSub.publish(UnityEvent.ReceiveSelectedObjectInfo, JSON.stringify(object)); // simulate unity trigger to go through all flow of selecting styleable object
    } else {
      this.unityContext.materialModule.OnUpdateColorReceiver(color);
    }
  };

  _undoMaterial = async (rawObject, parsedObject) => {
    await this.unityContext.materialModule.SetMaterialPropertiesOnObjectReceiver(rawObject);
    const object = { ...rawObject, TRIGGERED_FROM_REACT: true };
    PubSub.publish(UnityEvent.ReceiveSelectedObjectInfo, JSON.stringify(object)); // simulate unity trigger to go through all flow of selecting styleable object

    // // TODO: remove this when we'll have possibility to remove selected material
    // await waitForEventOnce(AppEvent.ReceiveSelectedObjectInfoHandled, {
    //   triggerFunc: async () => {
    //     const randomMaterial = await getMaterial(1);
    //     this.unityMaterial.applyMaterial({
    //       material: randomMaterial,
    //       switchToProperties: true,
    //       skipHistory: true,
    //     });
    //   }
    // })

    // await waitForEventOnce(AppEvent.ReceiveSelectedObjectInfoHandled, {
    //   triggerFunc: async () => {
    //     if (material?.materialId) {
    //       const prevMaterial = await getMaterial(material.materialId);
    //       // apply material

    //       this.unityMaterial.applyMaterial({
    //         material: prevMaterial,
    //         switchToProperties: true,
    //         skipHistory: true,
    //       });
    //     } else {
    //       this._applyDefaultMaterialOrColor({
    //         tilingType: TILING_TYPE.TEXTURE,
    //         mapType: mapType,
    //         objectName: objectName,
    //         color: colorValue,
    //         materialId: loadedMaterialId,
    //       })
    //     }
    //   }
    // })

    // if (lastApplied === 'color') {
    //   this.unityContext.materialModule.OnUpdateColorReceiver(colorValue);
    // } else if (lastApplied === 'texture') {
    //   await this._applyTiling(parsedObject.texture);
    // } else if (lastApplied === 'material') {
    //   // we've already applied material above skip this case
    // }

    // // go throught all tilings and apply values
    // Object.values(TILING_TYPE).forEach((tilingType) => {
    //   const key = TILING_NAME_MAP[tilingType];
    //   const tiling = parsedObject[key]

    //   if (tilingType === TILING_TYPE.MATERIAL) {
    //     this._resetTilingValues(tiling)
    //     return;
    //   }

    //   if (tiling.materialId && tiling.useSeparateTiling) {
    //     this._applyTiling(tiling)
    //   }
    // })
  };

  _undoColorMaterialTexture = async ({
    prevTiling,
    objectName,
    mapType,
    color,
    rawObject,
    parsedObject,
  }) => {
    // TODO: when we revert last material apply -> we need to check if there was a different material applied before that, if there was we need to apply it first and then apply previous texture, color
    this._removeLastApplied(objectName, mapType);
    const lastApplied = this._getLastApplied(objectName, mapType);

    if (lastApplied === 'color') {
      // this.unityContext.materialModule.OnUpdateColorReceiver(color);
      this.unityColor.changeColor(color);
    } else if (lastApplied === 'texture') {
      await this._applyTiling(prevTiling);
      // const texture = await getTexture(prevTiling.materialId);
      // this._skipChangeTilingPropertiesEventOnce = true;

      // // wait for texture to be applied before reset tiling values
      // this._resetTilingAfterTextureApplied(prevTiling);

      // // apply texture
      // await this.unityObjectProperties.applyTexture({
      //   mapType,
      //   texture,
      //   switchToProperties: true,
      //   skipHistory: true,
      // });
    } else if (lastApplied === 'material') {
      this._undoMaterial(rawObject, parsedObject);
    } else {
      if (prevTiling.materialId) {
        // texture was applied to this project already (saved)
        this._applyTiling(prevTiling);
      } else {
        await this._applyDefaultMaterialOrColor({
          tilingType: prevTiling.tilingType,
          mapType,
          objectName,
          color,
          materialId: rawObject.loadedMaterialId,
          rawObject,
          parsedObject,
        });
      }
    }
  };

  init = () => {
    this.toggleExpertMode = this.undoRedo.createAction(({ prevValue }) => {
      const nextValue = !prevValue;
      const objectName = selectSelectedObjectName(getAppState());

      return {
        undo: () => {
          toggleExpertMode({ enabled: prevValue, objectName });
        },
        redo: () => {
          toggleExpertMode({ enabled: nextValue, objectName });
        },
      };
    });

    this.updateObject = this.undoRedo.createAction(({ name, prevValue, nextValue, data }) => {
      const { tilingOptionType } = data;

      return {
        name,
        prevValue,
        nextValue,
        data,
        undo: () => {
          this.unityObjectProperties.modifyTiling(tilingOptionType, name, prevValue, {
            skipHistory: true,
          });
        },
        redo: () => {
          this.unityObjectProperties.modifyTiling(tilingOptionType, name, nextValue, {
            skipHistory: true,
          });
        },
      };
    });

    this.changeSize = this.undoRedo.createAction(({ prevValue, nextValue, data }) => {
      const { tilingOptionType } = data;

      return {
        prevValue,
        nextValue,
        data,
        undo: () => {
          this.unityObjectProperties.changeSize({
            tilingOptionType,
            width: prevValue.width,
            height: prevValue.height,
          });
        },
        redo: () => {
          this.unityObjectProperties.changeSize({
            tilingOptionType,
            width: nextValue.width,
            height: nextValue.height,
          });
        },
      };
    });

    this.changePosition = this.undoRedo.createAction(({ prevValue, nextValue, data }) => {
      const { tilingOptionType } = data;

      return {
        prevValue,
        nextValue,
        data,
        undo: () => {
          this.unityObjectProperties.changePosition({
            tilingOptionType,
            x: prevValue.x,
            y: prevValue.y,
          });
        },
        redo: () => {
          this.unityObjectProperties.changePosition({
            tilingOptionType,
            x: nextValue.x,
            y: nextValue.y,
          });
        },
      };
    });

    this.changeColor = this.undoRedo.createAction(({ prevTiling, prevValue, nextValue }) => {
      const objectName = selectSelectedObjectName(getAppState());
      this._addApplyTextureToHistory(objectName, TEXTURE_MAP.DIFFUSE, 'color');
      const parsedObject = selectParsedObject(getAppState());
      const rawObject = selectUnitySelectedObjectInfoRaw(getAppState());

      return {
        prevValue,
        nextValue,
        undo: () => {
          this._undoColorMaterialTexture({
            prevTiling,
            objectName,
            color: prevValue,
            mapType: TEXTURE_MAP.DIFFUSE,
            parsedObject,
            rawObject,
          });
        },
        redo: () => {
          this._addApplyTextureToHistory(objectName, TEXTURE_MAP.DIFFUSE, 'color');
          this.unityColor.changeColor(nextValue);
        },
      };
    });

    this.changeTilingProperties = this.undoRedo.createAction(({ prevValue, nextValue, data }) => {
      const { tilingOptionType } = data;

      // TODO: work on this logic -> skip it until texture is applied
      if (this._skipChangeTilingPropertiesEventOnce) {
        this._skipChangeTilingPropertiesEventOnce = false;
        return;
      }

      return {
        prevValue,
        nextValue,
        data,
        undo: () => {
          this.unityContext.materialModule.OnUpdateRotationReceiver({
            ...prevValue,
            tilingOptionType,
          });

          // this._update(tilingOptionType, prevValue);
        },
        redo: () => {
          this.unityContext.materialModule.OnUpdateRotationReceiver({
            ...nextValue,
            tilingOptionType,
          });

          this._update(tilingOptionType, nextValue);
        },
      };
    });

    // apply texture/custom map
    this.applyTexture = this.undoRedo.createAction(({ prevTiling, nextTexture, mapType }) => {
      if (parseInt(prevTiling?.materialId) === parseInt(nextTexture.id)) return false;

      this._skipChangeTilingPropertiesEventOnce = true;
      const objectName = selectSelectedObjectName(getAppState());
      const parsedObject = selectParsedObject(getAppState());
      const rawObject = selectUnitySelectedObjectInfoRaw(getAppState());
      const color = parsedObject.colorValue;

      this._addApplyTextureToHistory(objectName, mapType, 'texture');

      return {
        prevTiling,
        nextTexture,
        mapType,
        undo: async () => {
          if (mapType !== TEXTURE_MAP.DIFFUSE) {
            this.unityObjectProperties.toggleCustomMap(prevTiling.tilingType, false, {
              skipHistory: true,
            });
            return;
          }

          this._undoColorMaterialTexture({
            prevTiling,
            objectName,
            color,
            mapType,
            rawObject,
            parsedObject,
          });
        },
        redo: async () => {
          this._addApplyTextureToHistory(objectName, mapType, 'texture');
          this._skipChangeTilingPropertiesEventOnce = true;
          await this.unityObjectProperties.applyTexture({
            mapType,
            texture: nextTexture,
            switchToProperties: true,
            skipHistory: true,
          });
        },
      };
    });

    this.disableCustomMap = this.undoRedo.createAction(
      ({ prevValue, nextValue, tilingOptionType }) => {
        const prevTiling = selectDataByTilingType(getAppState(), tilingOptionType);

        return {
          prevValue,
          nextValue,
          tilingOptionType,
          undo: async () => {
            await this._applyTiling(prevTiling);
          },
          redo: () => {
            this.unityObjectProperties.toggleCustomMap(tilingOptionType, nextValue, {
              skipHistory: true,
            });
          },
        };
      }
    );

    this.applyMaterial = this.undoRedo.createAction(({ material }) => {
      const parsedObject = selectParsedObject(getAppState());
      const rawObject = selectUnitySelectedObjectInfoRaw(getAppState());
      const { objectName } = rawObject;
      const mapType = TEXTURE_MAP.DIFFUSE;
      this._addApplyTextureToHistory(objectName, mapType, 'material');
      // when we want to undo apply material we need to apply default material
      // if previous material was default than do nothing ???
      // if previous material is not default
      // if previously material was applied -> apply previous material
      // if there was a texture applied -> check if it has material -> if it has material than apply material first and then texture
      // apply color if needed
      // apply all custom maps
      // after that we need to apply all tilings and other options (kill me)

      return {
        parsedObject,
        material,
        undo: async () => {
          this._removeLastApplied(objectName, mapType);
          await this._undoMaterial(rawObject, parsedObject);
        },
        redo: () => {
          this._addApplyTextureToHistory(objectName, mapType, 'material');
          this.unityMaterial.applyMaterial({
            material,
            switchToProperties: true,
            skipHistory: true,
          });
        },
      };
    });

    this.copyPasteMaterialFinished = this.undoRedo.createAction(() => {
      // TODO: get object which was duplicated
      const copiedMaterial = selectCopiedObjectProperties(getAppState());
      const selectedObjectList = selectSelectedObjectList(getAppState());
      const copyPasteData = { ...this.copyPasteData };
      this.resetCopyPasteData();

      const mapType = TEXTURE_MAP.DIFFUSE;
      copyPasteData.objectsBeforePaste.forEach((objectData) => {
        this._addApplyTextureToHistory(objectData.objectName, mapType, 'material');
      });

      return {
        __ACTION__: 'UNITY_OBJECT__COPY_PASTE_MATERIAL',
        copyPasteData,
        copiedMaterial,
        undo: async () => {
          this.undoRedo.pause();
          const syncAsyncLoop = async (arr, callback, index = 0) => {
            if (!arr[index]) return;
            await callback(arr[index]);
            await syncAsyncLoop(arr, callback, index + 1);
          };

          await syncAsyncLoop(copyPasteData.objectsBeforePaste.reverse(), async (objectData) => {
            this._removeLastApplied(objectData.objectName, mapType);

            // undo material
            await waitForEventOnce(UnityEvent.ApplyMaterialPropertiesFinishedReceive, {
              triggerFunc: () => {
                const selectedObjectData = selectedObjectList.find(
                  (d) => d.objectName === objectData.objectName
                );
                this.unityContext.mainController.SelectObjectReceiver([
                  mapObjectToSelect(selectedObjectData),
                ]);
                this._undoMaterial(objectData);
              },
            });
          });

          // select all objects
          const objectsToSelect = selectedObjectList.map(mapObjectToSelect);
          this.unityContext.mainController.SelectObjectReceiver(objectsToSelect);
          this.undoRedo.resume();
        },
        redo: async () => {
          copyPasteData.objectsBeforePaste.forEach((objectData) => {
            this._addApplyTextureToHistory(objectData.objectName, mapType, 'material');
          });

          this.undoRedo.pause();
          const materialConfig = copyPasteData.materialConfig;
          await this.unityContext.materialModule.SetMaterialPropertiesOnObjectReceiver(
            materialConfig
          );
          this.undoRedo.resume();
        },
      };
    });
  };
}
