import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createRenderAPI } from '../../../../../api/camera/renders/renderCamera';
import { RP_TYPE } from '../../../../../config/constant/unityConstants';
import { getLocalStorage } from '../../../../../helpers/jsHelper';
import { errorToast, successToast } from '../../../../../helpers/toastHelper';
import { useCuratorData } from '../../../../../hooks/useCuratorData';
import {
  selectCalculatedRoomId,
  selectIndividualProjectData,
  selectIsLoading,
  selectProjectType,
  selectRoomData,
  setCuratorLoader,
} from '../../../../../redux/slicers/admin/curatorSlicer';
import {
  addPreview,
  addRender,
  paginatedRenderPreviewsActions,
  selectActiveTab,
} from '../../../../../redux/slicers/admin/paginatedRenderPreviewsSlicer';
import { getAppState } from '../../../../../redux/store';
import { useUnityContext } from '../../../../container/unityContainer';
import { addIdsToRequestData, useSaveProject } from '../../CuratorHeader/useSaveProject';

export const useSaveRenderPreview = () => {
  const loading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const { unityContext, roomModule, handleCameraScreenshot } = useUnityContext();
  const { getRoomJsonFile } = useSaveProject();

  const parseCamera = (camera) => {
    return {
      cameraName: camera.cameraName,
      distanceToSet: camera.distanceToSet,
      fov: camera.fov,
      horizontalFov: camera.horizontalFov,
      xTransRot: camera.xTransRot,
      yTransRot: camera.yTransRot,
      zTransRot: camera.zTransRot,
      centerObjectxPos: camera.centerObjectxPos,
      centerObjectyPos: camera.centerObjectyPos,
      centerObjectzPos: camera.centerObjectzPos,
      isCameraCorrection: camera.isCameraCorrection,
      lensShiftY: camera.lensShiftY,
      cameraHeight: camera.cameraHeight,
      cameraRotX: camera.cameraRotX,
      cameraRotZ: camera.cameraRotZ,
      focalLength: camera.focalLength,
      isdepthOfField: camera.isdepthOfField,
      selectedObjectName: camera.selectedObjectName,
      blurIntensity: camera.blurIntensity,
      resoX: camera.resoX,
      resoY: camera.resoY,
      xPos: camera.xPos,
      zPos: camera.zPos,
      yPos: camera.yPos,
      // tags: camera.tags, // not needed
      isPredefinedCamera: camera.isPredefinedCamera,
      is_rendered: camera.is_rendered, // TODO: do we need it
    };
  };

  const saveRender = ({ data, cameraList, onSuccess }) => {
    runRenderPreview({ data, type: RP_TYPE.RENDER, cameraList, onSuccess });
  };

  const savePreview = ({ data, cameraList, onSuccess }) => {
    runRenderPreview({ data, type: RP_TYPE.PREVIEW, cameraList, onSuccess });
  };

  // call this function when you want to run render & preview
  const runRenderPreview = ({ data, type, cameraList, onSuccess }) => {
    if (loading) {
      return;
    }

    if (!data.name || !data.name.trim()) {
      errorToast('Please provide a name');
      return;
    }

    if (!cameraList.length) {
      errorToast('Please select at least one camera');
      return;
    }

    dispatch(setCuratorLoader(false));
    const isPreview = type === RP_TYPE.PREVIEW;
    const isRender = type === RP_TYPE.RENDER;

    // roomModule.GetRoomInfo();

    // selectedCameraRPList
    const cameras = cameraList.map((item) => parseCamera(item));

    console.log('useSaveRenderPreview', cameras);

    handleCameraScreenshot(cameras);

    const camScreenshot = [];

    unityContext.on('GetScreenShotForCamera', (camName, imageInfo) => {
      console.log(`camName: ${camName} &  imageInfo: ${imageInfo}`);

      const byteCharacters = window.atob(imageInfo);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const mimeType = data.imageType === 'jpg' ? 'jpeg' : data.imageType;
      const blob = new Blob([byteArray], { type: `image/${mimeType}` });

      camScreenshot.push({
        camera_angle_name: camName,
        camera_angle_file: blob,
      });
    });

    unityContext.on('OnAllScreenShotProcessed', async () => {
      // remove 2 listeners we've added so they will not be triggered again by other camera render
      unityContext.removeEventListener('GetScreenShotForCamera');
      unityContext.removeEventListener('OnAllScreenShotProcessed');

      console.log('OnAllScreenShotProcessed', camScreenshot);

      const roomJson = (await getRoomJsonFile()).raw;

      roomJson.cameraInfo = [...cameras];

      console.log('camera info: ', cameras, roomJson.cameraInfo);
      console.log('sasha this is room json', roomJson);

      let camera_json_data = JSON.stringify(roomJson);

      const formObj = new FormData();
      const blob = new Blob([camera_json_data], { type: 'application/json' });
      formObj.append('is_preview', isPreview);
      formObj.append('is_main_render', isRender);
      formObj.append('render_name', data.name);
      formObj.append('count', cameras.length);
      formObj.append('json_file', blob, 'camera_info.json');

      formObj.append('is_meta_data', data.metadata);
      formObj.append('is_water_mark', data.watermark);
      formObj.append('is_transparent_bg', data.transparentBg);
      formObj.append('is_shadow_on_floor', data.shadowOnFloor);
      formObj.append('render_extension', data.imageType);

      const projectType = selectProjectType(getAppState());
      formObj.append('type', projectType);

      // add connections to room and project
      const calculatedRoomId = selectCalculatedRoomId(getAppState());
      formObj.append('room', calculatedRoomId);
      // formObj.append('project', 451);

      addIdsToRequestData({
        data: formObj,
        roomInfo: roomJson,
        includedIds: { products: true, textures: true },
      });

      camScreenshot?.map((a) => {
        formObj.append('camera_angle_name', a.camera_angle_name);
        formObj.append(
          'camera_angle_file',
          a.camera_angle_file,
          `${a.camera_angle_name}.${data.imageType}`
        );
      });

      console.log('camera formObj: ', formObj);
      console.log('sasha form data', data);

      createRenderAPI(formObj, {
        onSuccess: (data) => {
          successToast(`Camera sent for ${type.toLowerCase()} successfully!`);
          onSuccess?.();
          // dispatch(paginatedRenderPreviewsActions.triggerRefetch());

          const addAction = isPreview ? addPreview : addRender;
          const activeTab = selectActiveTab(getAppState());
          dispatch(addAction({ data, activeTab }));
        },
        // onError: () => {
        //   errorToast('Something went wrong! Please check.');
        // },
        afterAll: () => {
          dispatch(setCuratorLoader(true));
        },
      });
    });
  };

  return {
    savePreview,
    saveRender,
  };
};
