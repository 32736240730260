import {createSlice} from "@reduxjs/toolkit";
import { FOV_MIN_VALUE } from "../../../config/constant/projectConstant";

const cameraSettings = createSlice({
    name: 'cameraSettings',
    initialState: {
        globalFov: FOV_MIN_VALUE,
        globalWidth: 0,
        globalHeight: 0,
        globalResolution: [],
        globalSafeCameraFlag: false,
        canvasFrameWidth: 100,
        canvasFrameHeight: 100
    },
    reducers: {
        setGlobalFov: (state, {payload}) => {
            state.globalFov = payload
        },
        setGlobalWidth: (state, {payload}) => {
            state.globalWidth = payload
        },
        setGlobalHeight: (state, {payload}) => {
            state.globalHeight = payload
        },
        setGlobalResolution: (state, {payload}) => {
            state.globalResolution = payload
        },
        setGlobalSafeCameraFlag: (state, {payload}) => {
            state.globalSafeCameraFlag = payload
        },
        setCanvasFrameWidth: (state, {payload}) => {
            state.canvasFrameWidth = payload
        },
        setCanvasFrameHeight: (state, {payload}) => {
            state.canvasFrameHeight = payload
        }
    }
})

export const {
    globalFov, setGlobalFov,
    globalWidth, setGlobalWidth,
    globalHeight, setGlobalHeight,
    globalResolution, setGlobalResolution,
    globalSafeCameraFlag, setGlobalSafeCameraFlag,
    canvasFrameWidth, setCanvasFrameWidth,
    canvasFrameHeight, setCanvasFrameHeight
} = cameraSettings.actions;
export const cameraSettingsSelector = state => state.cameraSettings;
export const cameraSettingsReducer = cameraSettings.reducer;
export default cameraSettingsReducer;


export const selectGlobalWidth = (state) => state.cameraSettings.globalWidth
export const selectGlobalHeight = (state) => state.cameraSettings.globalHeight
export const selectCanvasFrameWidth = (state) => state.cameraSettings.canvasFrameWidth
export const selectCanvasFrameHeight = (state) => state.cameraSettings.canvasFrameHeight
export const selectCameraSafeModeEnabled = (state) => state.cameraSettings.globalSafeCameraFlag
