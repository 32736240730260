import { createPaginatedSlice } from "../../helpers/reduxHelper";

const collectionItems = createPaginatedSlice('collectionItems', {
  reducers: {
    updateItemInCollection: (state, {payload}) => {
      const { data } = payload;
      
      state.list = state.list.map((collectionItem) => {
        return collectionItem?.data?.id === data.id
          ? {
              ...collectionItem,
              data: {
                ...collectionItem.data,
                ...data,
              },
            }
          : collectionItem;
      });
    },
  },
});

export const collectionItemsActions = collectionItems.actions
export const collectionItemsSelector = state => state.collectionItems;
export const collectionItemsReducer = collectionItems.reducer;
export default collectionItemsReducer

export const collectionItemsSelectors = collectionItems.LF.selectors