import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { LOGIN_HOME } from '../constants/routePathConstants';
import { useUserContext } from '../contexts/UserContext';
import { NotFound } from '../screens';

const AdminGuard = () => {
  const userCtx = useUserContext();
  if (userCtx.token && userCtx.refreshToken && userCtx.emailVerified && userCtx.currentUser) {
    return <Outlet />;
  } else {
    return <Navigate to={LOGIN_HOME} />;
  }
};

export default AdminGuard;
