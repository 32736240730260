import { createSlice } from '@reduxjs/toolkit';
import { dispatcher } from '../../../helpers/projectHelper';

const curatorLoaderSlicer = createSlice({
  name: 'curatorLoader',
  initialState: {
    exportProjectLoading: false,
    generateFromDesignLoading: false,
    unityObjectImagesProcessing: false,
    createRoomLoading: false,
    pasteMaterialLoading: false,
  },
  reducers: {
    setExportProjectLoading: (state, { payload }) => {
      state.exportProjectLoading = payload;
    },
    setGenerateFromDesignLoading: (state, { payload }) => {
      state.generateFromDesignLoading = payload;
    },
    setUnityObjectImagesProcessing: (state, { payload }) => {
      state.unityObjectImagesProcessing = payload;
    },
    setCreateRoomLoading: (state, { payload }) => {
      state.createRoomLoading = payload;
    },
    setPasteMaterialLoading: (state, { payload }) => {
      state.pasteMaterialLoading = payload;
    },
  },
});

export const actions = curatorLoaderSlicer.actions;

export const curatorLoaderSelector = (state) => state.curatorLoader;
export const curatorLoaderReducer = curatorLoaderSlicer.reducer;
export default curatorLoaderReducer;

const ca = (action) => (loading) => dispatcher(action(loading));

export const setExportProjectLoading = ca(actions.setExportProjectLoading)
export const setGenerateFromDesignLoading = ca(actions.setGenerateFromDesignLoading)
export const setUnityObjectImagesProcessing = ca(actions.setUnityObjectImagesProcessing)
export const setCreateRoomLoading = ca(actions.setCreateRoomLoading)
export const setPasteMaterialLoading = ca(actions.setPasteMaterialLoading)

export const selectExportProjectLoading = (state) => state.curatorLoader.exportProjectLoading;
export const selectGenerateFromDesignLoading = (state) =>
  state.curatorLoader.generateFromDesignLoading;
export const selectUnityObjectImagesProcessing = (state) =>
  state.curatorLoader.unityObjectImagesProcessing;
export const selectCreateRoomLoading = (state) => state.curatorLoader.createRoomLoading;
export const selectPasteMaterialLoading = (state) => state.curatorLoader.pasteMaterialLoading;
