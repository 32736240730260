import { ThumbnailCard } from '.';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import _, { throttle } from 'lodash';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Loader from '../components/Loader/Loader';
import { useCallback, useEffect, useRef } from 'react';
import { CARD_COMPONENT_MAP, ThumbnailContainerV2 } from './ThumbnailContainerV2';

export default function HorizontalHomeCarousel({ loading, hasMore, fetchMoreData, modelType, onEdit, data, capitalize, customActions = [] }) {
  const sliderRef = useRef(null);
  const containerRef = useRef(null);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: fetchMoreData,
    rootMargin: '0px 0px 120px 0px',
  });

  var settings = {
    dots: false,
    infinite: false,
    swipe: true,
    variableWidth: true,
    speed: 500,
    slidesToScroll: 1,
    centerPadding: '24px'
    // slidesToShow: 4,
  };

  const scrollSlides = useCallback(throttle(deltaX => {
    if (!sliderRef.current) return;

    if (deltaX > 1) {
      sliderRef.current.slickNext();
    } else if (deltaX < -1) {
      sliderRef.current.slickPrev();
    }
  }, 240, { leading: true, trailing: false }), [])

  useEffect(() => {
    const onWheelSlider = (e) => {
      if (!sliderRef.current) return;
  
      if (e.deltaX !== 0) {
        e.preventDefault();
        scrollSlides(e.deltaX)
      }
    };

    containerRef.current.addEventListener('wheel', onWheelSlider)
    return () => {
      if (!containerRef.current) return;
      containerRef.current.removeEventListener('wheel', onWheelSlider)
    }
  }, [])

  const Component = CARD_COMPONENT_MAP[modelType]

  return (
    <div ref={containerRef}>
      <Slider {...settings} ref={sliderRef}>
        {/* Some components sending object here -> remove array check when it's fixed */}
        {Array.isArray(data) && data.map(item => {
          if (!Component) {
            // throw new Error('component is not supported')
            return null;
          }

          return (
            <div key={item.id}>
              <Component
                data={item}
                onEdit={onEdit}
                capitalize={capitalize}
                customActions={customActions}
              />
            </div>
          )
        })}
        {hasMore && (
          <div className="horizontal-home-carousel-placeholder" ref={sentryRef}>
            <Loader loading />
          </div>
        )}
      </Slider>
    </div>
  );
}
