export const LABEL_CATEGORY = 'categories';
export const TYPE_TEXTURE = 2;

export const MODE_EDIT = 'edit';
export const MODE_UPLOAD = 'upload';
export const MODE_MOVE_TO_TEXTURES = 'move-to-textures';

export const UNITY_REF = {
  loaderUrl: '/Build/texture/TexturePreviewBuild.loader.js',
  dataUrl: '/Build/texture/TexturePreviewBuild.data',
  frameworkUrl: '/Build/texture/TexturePreviewBuild.framework.js',
  codeUrl: '/Build/texture/TexturePreviewBuild.wasm',
};

export const MAX_UPLOAD_WIDTH_PIXELS = 16000;
export const MAX_UPLOAD_HEIGHT_PIXELS = 16000;
export const DEFAULT_DPI = 300;
