import invert from 'lodash/invert';
import { API_ROOT_URL, ROOM_DETAILS_API, ROOM_TYPE_API } from '../../config/constant/apiConstant';
import api from '../../helpers/axiosHelper';
import { setRoomTypes } from '../../redux/slicers/appConfigSlicer';
import { setSearchValue, setSearchResult, setMoreSearchResult } from '../../redux/slicers/search/searchSlicer';

export const RoomsSearchApi =
  (searchVal, page = 1, limit = 20) =>
  (dispatch) => {
    api()
      .root(API_ROOT_URL)
      .get(`${ROOM_DETAILS_API}?search=${searchVal}&page=${page}&per_page=${limit}`)
      .success((data) => {
        if (page === 1) {
          dispatch(setSearchResult(data));
        } else {
          dispatch(setMoreSearchResult(data))
        }
      })
      .error((error) => {
        // dispatch(setSearchResult(error))
      })
      .send();
  };

export const GetRoomTypeApi = () => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(ROOM_TYPE_API)
    .success((data) => {
      dispatch(setRoomTypes(invert(data)));
    })
    .error((error) => {
      // TODO: add error handling after decision of the team
    })
    .send();
};
