import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import useToggle from 'react-use/lib/useToggle';
import { Card } from 'react-bootstrap';
import styles from './ThumbnailCardUI.module.scss';
import stackedCards from '../assets/images/stackedCards.png';

import {
  ACTION_DOTS_ICON,
  DOWNLOAD_ICON,
  FAVOURITE_ICON,
  NOT_FAVOURITE_ICON,
} from '../config/constant/assetConstant';
import CircularProgress from '../components/CircularProgress/CircularProgress';
import Image from './Image';
import { Link } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import { MOVE_TO_TEXTURE_ICON } from '../constants/assetConstants';
import { EditIcon } from '../components/icons/EditIcon';
import ShareIcon from '../components/icons/ShareIcon';
import CollectionIcon from '../components/icons/CollectionIcon';
import BinIcon2 from '../components/icons/BinIcon2';
import { isEmpty, merge, startCase, toLower } from 'lodash';
import { icons } from '../assets';
const { DownloadIcon, MoveToTextureIcon } = icons;

export const ACTION = {
  RETRY: 'RETRY',
  RAISE_TICKET: 'RAISE_TICKET',
  UNDER_REVIEW: 'UNDER_REVIEW',
  RESTORE: 'RESTORE',
  DOWNLOAD: 'DOWNLOAD',
  EDIT: 'EDIT',
  MOVE_TO_TEXTURE: 'MOVE_TO_TEXTURE',
  SHARE: 'SHARE',
  ADD_TO_COLLECTION: 'ADD_TO_COLLECTION',
  DELETE: 'DELETE',
};

const ThumbnailCardUI = ({
  id,
  name,
  date,
  isFavourite,
  hideFavourite,
  favouriteLoading,
  thumbnail,
  images,
  selected,
  fullWidth,
  secondLine,
  onAddToCollection,
  imageText,
  showActionsText,
  actionsListClassName,
  customActions = {}, // { [ACTION.TEST]: {}}
  additionalData,
  originalData, // for collections/shared files it's represent collection or shared file
  data,
  capitalize = false,
  highlight,
  bottomRightText,
  imageProps,


  as,
  className,

  onDownload,
  downloadLoading,
  onEdit,
  onMoveToTextures,
  onShare,
  onDelete,
  onSelect,
  onFavouriteChange,
  onCardImageClick,
  imageFit,

  onLinkClick,
  to,
  titleProps,
  target,
  size,
  modelType,

  ...rest
}) => {
  const ref = useRef(null);
  const [actionsOpen, toggleActionsOpen] = useToggle(false);
  const [userListOpen, toggleUserListOpen] = useToggle(false);

  const closeActionsOnOutsideClick = useCallback(
    (event) => {
      if (!ref.current || !ref.current.contains(event.target)) {
        toggleActionsOpen(false);
      }
    },
    [toggleActionsOpen]
  );
  useEffect(() => {
    if (!actionsOpen) return;
    document.addEventListener('mousedown', closeActionsOnOutsideClick);
    return () => document.removeEventListener('mousedown', closeActionsOnOutsideClick);
  }, [actionsOpen]);

  const handleActionsToggle = () => {
    toggleActionsOpen();
  };

  const preventPropagation = (e) => {
    e.stopPropagation();
  };

  const hasActions = Boolean(
    onDownload ||
      onEdit ||
      onShare ||
      onAddToCollection ||
      onDelete ||
      onMoveToTextures ||
      Object.keys(customActions).length
  );

  // actions
  const defaultActions = {
    [ACTION.DOWNLOAD]: {
      label: 'Download',
      loading: downloadLoading,
      onClick: onDownload,
      icon: <DownloadIcon />,
    },
    [ACTION.EDIT]: {
      label: 'Edit',
      onClick: onEdit,
      icon: <EditIcon />,
    },
    [ACTION.MOVE_TO_TEXTURE]: {
      label: 'Move to Texture',
      onClick: onMoveToTextures,
      icon: <MoveToTextureIcon />,
    },
    [ACTION.SHARE]: {
      label: 'Share',
      onClick: onShare,
      icon: <ShareIcon />,
    },
    [ACTION.ADD_TO_COLLECTION]: {
      label: 'Add to Collection',
      onClick: onAddToCollection,
      icon: <CollectionIcon />,
    },
    [ACTION.DELETE]: {
      label: 'Delete',
      onClick: onDelete,
      icon: <BinIcon2 />,
    },
  };

  // TODO: handle case with no image
  const thumbnailSrc = thumbnail || images?.[0]?.thumbnail || '';
  let Component = as;
  const imageClickable = to || onLinkClick || onCardImageClick;
  if (!Component) {
    Component = to ? Link : 'div';
  }

  to = to ? to.replace(':id', id) : undefined;
  const dateToShow = additionalData?.date || date;
  const combinedTitleProps = titleProps || { to, as: to ? Link : undefined, target };
  const TitleComponent = combinedTitleProps?.as || 'h2';
  const titleClickable = Boolean(titleProps?.to || titleProps?.onClick);

  const actions = useMemo(() => {
    if (!actionsOpen) return {};
    return merge({}, defaultActions, customActions);
  }, [actionsOpen, downloadLoading]);

  return (
    <Card
      ref={(r) => {
        ref.current = r;
      }}
      className={cx(
        'thumbnail-card mb-0',
        fullWidth && styles.fullWidth,
        className,
        size === 'small' && styles.smallSize
      )}
      id={`thumbnail-card-${id}`}
      {...rest}>
      <Component
        to={to}
        onClick={onLinkClick}
        target={target}
        className={cx(imageClickable && styles.clickable)}>
        <div className="thumbnail-img" onClick={onCardImageClick}>
          <div
            className={cx(
              'thumbnail-action',
              selected && 'thumbnail-action--selected',
              isFavourite && 'thumbnail-action--favourite',
              styles.thumbnailActionBar
            )}>
            <ul className="action-list">
              <li className="thumbnail-action-checkbox">
                {onSelect && (
                  <>
                    <input
                      type="checkbox"
                      className="selectCard"
                      checked={selected}
                      onChange={() => onSelect(id, data, originalData)}
                    />
                    <label
                      onClick={(e) => {
                        e.preventDefault();
                        preventPropagation(e);
                        onSelect(id, data, originalData);
                      }}></label>
                  </>
                )}
              </li>
              {onFavouriteChange && !hideFavourite && (
                <li
                  className={cx(
                    'thumbnail-action-favourite',
                    favouriteLoading && styles.favouriteLoading
                  )}>
                  <input
                    type="checkbox"
                    name="favouriteCard"
                    id={`favouriteCard-${id}`}
                    className="favouriteCard"
                    onChange={onFavouriteChange}
                    checked={isFavourite}
                    onClick={preventPropagation}
                  />
                  <label htmlFor={`favouriteCard-${id}`} onClick={preventPropagation}>
                    {!favouriteLoading && (
                      <img
                        src={isFavourite ? FAVOURITE_ICON : NOT_FAVOURITE_ICON}
                        width={13}
                        height={13}
                        alt="favourite"
                      />
                    )}
                    {favouriteLoading && <CircularProgress size={14} thickness={1} />}
                  </label>
                </li>
              )}
            </ul>
          </div>
          <Image
            src={thumbnailSrc}
            placeholderWhileLoading
            hideWithNoSrc
            ratio={1.259}
            style={{ objectFit: imageFit || 'cover' }}
            {...imageProps}
          />
          {imageText && (
            <div className="itemCount" style={{ pointerEvents: 'none' }}>
              {imageText}
            </div>
          )}

          <div className="main-item-toggel">
            {userListOpen && <UsersList users={originalData?.user_details} />}
          </div>
        </div>
      </Component>

      <Card.Body
        className={cx('thumbnail-info', styles.cardBody, {
          [styles.selected]: selected || highlight === 'info',
          [styles.success]: highlight === 'success',
          [styles.error]: highlight === 'error',
        })}>
        <Card.Title className="inner-info">
          <TitleComponent
            {...combinedTitleProps}
            as={undefined}
            className={cx('thumbnail-title', titleClickable && styles.clickable)}>
            {capitalize ? startCase(toLower(name)) : name}
          </TitleComponent>

          <div className="d-flex gap-1 align-items-center">
            <UserThumbnailList
              users={originalData?.user_details}
              onClick={() => toggleUserListOpen()}
            />
            {!originalData?.user_details && (
              <MemberThumbnail
                user={
                  data?.member_details ||
                  data?.owner_detail?.user ||
                  originalData?.created_by_details?.user
                }
              />
            )}
            {dateToShow && (
              <h5 className="thumbnail-etime">
                <ReactTimeAgo date={Date.parse(dateToShow)} />
              </h5>
            )}
            {secondLine && <div className={styles.secondLine}>{secondLine}</div>}
            {bottomRightText && <span className={styles.stepData}>{bottomRightText}</span>}
          </div>
        </Card.Title>
        {hasActions && (
          <div className="inner-action">
            <div className="card-action" onClick={handleActionsToggle}>
              <img src={ACTION_DOTS_ICON} width={5} height={16} alt="more" />
            </div>

            {actionsOpen && (
              <ul
                className={cx(
                  'action-list ced-action',
                  styles.actionsList,
                  showActionsText && styles.withText,
                  actionsListClassName
                )}>
                {Object.keys(actions).map((actionName) => {
                  const config = actions[actionName];

                  if (!config.onClick) return null;
                  const { onClick, loading, label, icon } = config;

                  return (
                    <li key={actionName}>
                      <div onClick={() => onClick(id, data, originalData)} title={label}>
                        {loading ? <CircularProgress size={14} thickness={1} /> : icon}
                        <span className={styles.actionItemText}>{label}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

const UsersList = memo(({ users }) => {
  if (!users) return null;

  return users.map((item) => (
    <div className="inner-item-part" key={item.id}>
      <div className="item-image-text">
        <img src={item?.shared_to_detail?.user?.profile_image || stackedCards} />
        <span>{item?.shared_to_detail?.organization_detail?.name}</span>
      </div>
      {item?.updated_at && (
        <div className="item-hours-part">
          <ReactTimeAgo date={Date.parse(item.updated_at)} />
        </div>
      )}
    </div>
  ));
});

const UserThumbnailList = ({ users, onClick }) => {
  if (isEmpty(users)) return null;

  const usersImages = users.map((user) => ({
    image: user?.shared_to_detail?.user?.profile_image,
  }));

  return <UserThumbnailListUI users={usersImages} onClick={onClick} />;
};

const MemberThumbnail = ({ user }) => {
  if (!user && user === undefined) return null;
  const users = user?.profile_image ? [{ image: user?.profile_image }] : [];
  return <UserThumbnailListUI users={users} />;
};

const UserThumbnailListUI = ({ onClick, users }) => {
  if (users?.length === 0) return null;
  return (
    <div className="custom-imges">
      <span style={{ cursor: 'pointer', display: 'flex' }} onClick={onClick}>
        {users?.map((item, idx) =>
          idx < 2 ? (
            <span className="avatar" key={idx}>
              <img src={item?.image || stackedCards} />
            </span>
          ) : null
        )}
        {users.length > 2 && <span className="avatar avtar-count-part ">+{users?.length - 2}</span>}
      </span>
    </div>
  );
};

export default ThumbnailCardUI;
