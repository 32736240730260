/* eslint-disable no-unreachable */
import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { apiFetch } from '../../helpers/axiosHelper';
import {
  CHECK_ORG_NAME,
  apiUpdateOrganization,
  apiGetOrganizationDetails,
  apiGetMembers,
} from '../../helpers/api';
import { icons } from '../../assets';
import CancelIcon from '../../assets/images/settings/cancel.svg';
import { ViewEditItem } from '..';
import _ from 'lodash';
import { AvatarRow } from '../AvatarRow';
import { useUserContext } from '../../contexts/UserContext';
import Button from '../../components/Button/Button';
import { useInfoDialogaActions } from '../../hooks/useInfoDialogaActions';
import InfoDialogUI from '../../components/InfoDialog/InfoDialogUI';
import styles from './OrgSection.module.scss';
import CloseCircleIcon from '../../components/icons/closeCircleIcon';
import { useSearchMemberList } from '../../hooks/useSearchMemberList';
import { ItemSelectListUI } from './ItemSelectListUI';
import { errorToast, successToast } from '../../helpers/toastHelper';
import { useDispatch } from 'react-redux';
import { getAllMyOrgs } from '../../api/settings';
import { UnionExclamation } from '../../constants/assetConstants';
import { getUserDisplayName } from '../../helpers/userHelper';

const { ChangeProfileIcon, EditIcon, EyeIcon, FigureIcon, ProfileIcon } = icons;

function OrgOwnerModal(props) {
  const { isOwner, updateCurrentUser, currentUser } = useUserContext();
  const [showModal, setShowModal] = useState(false);
  const { openInfoDialog } = useInfoDialogaActions();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [ownershiChangeLoading, setOwnershiChangeLoading] = useState(false);

  const {
    list: memberList,
    fetchMoreData,
    loading,
    search,
    onSearchChange,
    hasData,
    hasMore,
  } = useSearchMemberList();

  const submitMakeOwner = async () => {
    if (!selectedUserId) {
      errorToast('Please select a new owner');
      return;
    }

    setOwnershiChangeLoading(true);
    let formData = new FormData();
    formData.append('owner', selectedUserId);
    const result = await apiUpdateOrganization(formData);

    if (result?.id) {
      updateCurrentUser({ role: { is_owner: false } });
      successToast('Ownership transferred successfully');
      closeDialog();
    }
    setOwnershiChangeLoading(false);
    // TODO: refetch current user data and organization data after refactoring context --> waiting for info from backend
  };

  const handleEditClick = () => {
    openInfoDialog({
      title: 'You will loose ownership & your role will be switched from owner to admin.',
      description: 'Are you sure you want to change the owner?',
      cancelButtonText: 'Cancel',
      buttonText: 'Yes',
      dialogClassName: 'ownership-modal-dialog ownership-modal-confirm-dialog',
      bodyClassName: 'ownership-modal-body',
      onButtonClick: () => setShowModal(true),
    });
  };

  const closeDialog = () => {
    if (ownershiChangeLoading) {
      return;
    }

    setShowModal(false);
    setSelectedUserId(null);
  };

  const formattedMemberList = useMemo(() => {
    return memberList
      .filter((m) => m.id !== currentUser.id)
      .map((m) => ({
        id: m.id,
        image: m.user.profile_image,
        title: getUserDisplayName(m.user),
        description: m.user.email,
      }));
  }, [memberList]);

  return (
    <>
      <div className="input-border d-flex justify-content-between gap-3">
        <div>
          <label className="detail-label">Organization Owner</label>
          <span className="detail-info">{props?.orgOwner?.email}</span>
        </div>
        {isOwner && (
          <Button
            onClick={handleEditClick}
            color="light"
            size="xlg"
            className="so-icon-button"
            iconBefore={<EditIcon />}>
            Edit
          </Button>
        )}
      </div>

      <InfoDialogUI
        show={showModal}
        preventClose={ownershiChangeLoading}
        title="Select organization owner"
        onClose={closeDialog}
        dialogClassName="ownership-modal-dialog">
        <div className={styles.searchInputContainer}>
          <Form.Control
            className={styles.searchInput}
            type={'text'}
            placeholder="Search..."
            maxLength={20}
            value={search}
            onChange={onSearchChange}
          />
          {search && (
            <CloseCircleIcon
              className={styles.clearInputButton}
              onClick={() => onSearchChange('')}
            />
          )}
        </div>
        <ItemSelectListUI
          className="mb-4"
          items={formattedMemberList}
          selected={selectedUserId}
          onChange={setSelectedUserId}
          loading={loading}
          hasData={hasData}
          fetchMoreData={fetchMoreData}
          hasMore={hasMore}
        />
        <Button
          color="warning"
          onClick={submitMakeOwner}
          fullWidth
          size="xlg"
          loading={ownershiChangeLoading}>
          Make owner
        </Button>
      </InfoDialogUI>
    </>
  );
}

export default function OrgSection(props) {
  const dispatch = new useDispatch();
  const userCtx = useUserContext();
  const { isAdmin, isOwner } = useUserContext();
  const [selectedOrgPhoto, setSelectedOrgPhoto] = useState('images/orgPic.svg');
  const [orgError, setOrgError] = useState(false);
  const [orgErrorMsg, setOrgErrorMsg] = useState('');
  const [saving, setSaving] = useState(false);
  const [savingImg, setSavingImg] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [orgInfo, setOrgInfo] = useState({});
  const [orgId, setOrgId] = useState();
  const [orgOwnerId, setOrgOwnerId] = useState();
  const [orgOwner, setOrgOwner] = useState({});
  const [orgMembers, setOrgMembers] = useState([]);
  const [editingOrgName, seon] = useState(false);

  const { userDetails } = props;
  const setEditingOrgName = (active) => {
    seon(active);
    if (!active) {
      setOrgError(false);
      setOrgErrorMsg('');
    }
  };

  useEffect(() => {
    if (!editingOrgName) return;
    setOrgName(userDetails?.organization_details?.name || '');
  }, [editingOrgName]);

  useEffect(() => {
    getOrgInfo();
  }, [userDetails]);

  useEffect(() => {
    getOrgOwner();
  }, [orgId]);

  function getOrgInfo() {
    apiGetOrganizationDetails().then((response) => {
      if (response) {
        setOrgInfo(response);
        setOrgOwnerId(response.owner);
        setOrgId(response.id);
      }
    });
  }

  function getOrgOwner() {
    if (!orgOwnerId) return;

    apiGetMembers({ search: `id=${orgOwnerId}` }).then((response) => {
      setOrgMembers(formatOrgMembers(response.results));
      let owner = _.find(response.results, (x) => x.id === orgOwnerId);
      setOrgOwner(owner?.user);
    });
  }

  function formatOrgMembers(arr) {
    let newArray = _.map(arr, (item) => {
      let newItem = {};
      newItem.label = item.user.name ? item.user.name : '';
      newItem.value = item.id;
      newItem.email = item.user.email ? item.user.email : '';
      newItem.profile_image = item.user.profile_image;
      return newItem;
    });
    return newArray;
  }

  const handleOrganizationImage = (file) => {
    let formData = new FormData();

    formData.append('thumbnail', file);
    setSavingImg(true);
    apiUpdateOrganization(formData).then((response) => {
        getOrgInfo();
      dispatch(getAllMyOrgs(setSavingImg));
      userCtx.changeOrgProfilePic(response?.thumbnail);
    })
    .finally(() => setSavingImg(false));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOrgSave = () => {
    setIsSubmitting(true);
    let formData = new FormData();
    formData.append('name', orgName);

    apiUpdateOrganization(formData)
      .then((response) => {
        if (response) {
          props.getUserDetails(() => setEditingOrgName(false));
        }
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleOrgNameChange = async (e) => {
    let name = e.target.value;

    if (name?.length === 0) setOrgErrorMsg("Organization name can't be empty");
    else if (name?.length > 50)
      setOrgErrorMsg('Organization name should be less then 50 character');
    else setOrgErrorMsg('');
    setOrgName(name);

    if (name.length > 0) {
      const data = await apiFetch(`${CHECK_ORG_NAME}?name=${name}`).send();
      setOrgError(!data?.status);
      if (!data?.status) setOrgErrorMsg(data?.message);

      if (name.length > 50) {
        setOrgError(true);
      }
    } else {
      setOrgError(true);
    }
  };

  return (
    <div>
      <div className="right-section">
        <div className="org-details">
          <AvatarRow
            src={orgInfo.thumbnail}
            label="Organization Picture"
            onFileSelect={handleOrganizationImage}
            loading={savingImg}
            disableEdit={!isOwner}
          />

          <ViewEditItem
            label={orgErrorMsg !== '' ? orgErrorMsg : 'Organization Name'}
            // label={
            //   !(orgName.length > 50) || !editingOrgName
            //   ? "Organization Name"
            //   : <>
            //       <img
            //         src={UnionExclamation}
            //         style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
            //       />
            //       Organization name should be less then 50 character
            //     </>
            // }
            disabled={orgError || isSubmitting}
            onUpdate={handleOrgSave}
            value={userDetails?.organization_details?.name}
            editing={editingOrgName}
            error={orgError}
            orgErrorMsg={orgErrorMsg}
            placeholder="Organisation Name"
            setEditing={setEditingOrgName}
            disableEdit={!isOwner}
            isSubmitting={isSubmitting}>
            <div className="w-100">
              <Form.Control
                className={`w-100 my-details-input ${
                  (orgName.length > 50 && editingOrgName) || orgError ? 'input-error' : ''
                } `}
                value={orgName}
                onChange={handleOrgNameChange}
                style={{ border: orgError ? 'var(--error-color) 1px solid' : '' }}
                placeholder="Organization Name"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !orgError) {
                    if (orgName.length > 50) return;
                    handleOrgSave();
                    // setEditingOrgName(false);
                  }
                }}
              />
            </div>
          </ViewEditItem>
          <OrgOwnerModal orgOwner={orgOwner} orgMembers={orgMembers} />
        </div>
      </div>
    </div>
  );
}
