import { icons } from '../assets';

const { CloseCircleIcon } = icons;

// TODO clean up the classes on this component
export default function ClickablePill(props) {
  return (
    <div
      className="filter"
      style={{
        width: 'max-content',
      }}>
      <label>
        <span>{props?.label.toLowerCase()}</span>
        <span className="deleteButton" onClick={props.onClick}>
          <CloseCircleIcon />
        </span>
      </label>
    </div>
  );
}
