import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MY_PRODUCTS_ROUTE } from '../constants/routePathConstants';
import { sidebarSelector } from '../redux/slicers/admin/sidebarSlicer';

export const useGlobalFilters = () => {
  const location = useLocation();
  const { filtersData, filterValues } = useSelector(sidebarSelector);

  const filters = [
    {
      items: [
        {
          label: 'My favorites',
          value: 'favourite',
        },
      ],
    },
  ];

  // add api filters
  const apiFilters = useMemo(() => {
    if (!filtersData || !Object.keys(filtersData).length) return [];
    if (location.pathname === MY_PRODUCTS_ROUTE) return [];

    let result =
      filtersData.filterlabels?.map((label) => {
        return {
          title: label.label_name,
          items: filtersData.filteroptions
            .filter((item) => item.filter_label === label.id)
            .map((item) => ({
              label: item.name,
              value: `option_${item.filter_label}_${item.id}`,
            })),
        };
      }) || [];

    if (filtersData.brands?.length) {
      const brands = {
        title: 'Brands',
        items: filtersData.brands.map((brand) => ({
          label: brand.name,
          value: `brand_${brand.id}`,
        })),
      };

      result = [brands, ...result];
    }

    if (filtersData.collections?.length) {
      const collections = {
        title: 'Collections',
        items: filtersData.collections.map((collection) => ({
          label: collection.name,
          value: `collection_${collection.id}`,
        })),
      };

      result = [collections, ...result];
    }

    return result;
  }, [filtersData]);

  filters.push(...apiFilters);

  return { filters, filterValues };
};
