import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectShareData, sharePopupActions } from '../redux/slicers/sharePopupSlicer';
import { SharePopupONLY } from './SharePopup';

export const SharePopupController = memo(() => {
  const dispatch = useDispatch();
  const shareData = useSelector(selectShareData);
  const hasData = Object.keys(shareData).length > 0;

  const handleClose = () => {
    dispatch(sharePopupActions.clear());
  };

  let modelType;
  let shareItems = [];

  if (hasData) {
    modelType = Object.keys(shareData)[0];
    shareItems = shareData[modelType]
  }

  return (
    <SharePopupONLY
      show={hasData}
      animation
      footerButton
      modelType={modelType}
      selectedModels={shareItems}
      title="Share files with"
      shareData={shareData}
      toggleModal={handleClose}
      centered
    />
  );
});
      