import { useMemo, useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { Form } from 'react-bootstrap';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { UnionExclamation } from '../constants/assetConstants';
import makeAnimated from 'react-select/animated';
import { EDIT_ICON } from '../config/constant/assetConstant';
import { withAsyncPaginate, AsyncPaginate } from 'react-select-async-paginate';
import { reactSelectStyles } from '../helpers/reactSelectStyles';
import { useElementRef } from '../hooks/useElementRef';
import Image from './Image';

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

export default function SelectFormGroup(props) {
  const [options, setOptions] = useState(props.options);
  // const [editing, setEditing] = useState(false);
  const [editing, setEditing] = useState(false);

  // console.log("props select: ", props);

  const selectBoxStyles = {
    container: (provided, state) => ({
      ...provided,
      height: 'fit-content',
      minHeight: 'fit-content',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: props.error ? '#FCEAE8' : '#e9e9e9',
      border: props.error ? '1px solid #e4291d' : '1px solid #ced4da',
      borderRadius: '8px',
      padding: '0.4rem 0.4rem',
      width: '100%',
      height: 'fit-content',
      minHeight: 'fit-content',
    }),
    valueContainer: (styles) => ({
      ...styles,
      maxHeight: '2.4rem',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        //backgroundColor: isDisabled ? 'red' : 'blue',
        //color: 'green',
        //cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  const onEdit = (e) => {
    e.preventDefault();
    setEditing(!editing);
  };

  function handleCreate(e) {
    let newList = Object.assign([], options);
    let newSelections = Object.assign([], props.value);
    let selection = {
      label: e,
      value: e,
    };
    newList.unshift(selection);
    newSelections.push(selection);
    setOptions(newList);
    props.onChangeCallback(newSelections);
  }
  let commonProps = {
    id: props.id,
    name: props.name,
    isLoading: props.isLoading,
    label: props.selectLabel,
    value: props.value,
    options: options,
    className: `${props.error ? 'input_error_style' : ''}`,
    placeholder: props.placeholder || '',
    isMulti: props.isMulti,
    makeAnimated: makeAnimated,
    menuPlacement: props.menuPlacement || 'auto',
    noOptionsMessage: props.noOptionsMessage,
  };
  let multiOnlyControl = null;
  // let multiOnlyControl = props.isMulti
  //   ? {
  //       Control: ({ children, ...props }) => (
  //         <div className="lfSelectMulti__control" {...props}>
  //           {children}
  //         </div>
  //       ),
  //     }
  //   : {};

  const selectComponents = useMemo(
    () =>
      editing
        ? {}
        : {
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            MultiValue: ({ props, data }) => (
              <span
                style={{
                  border: '1px solid black',
                  padding: '0px 5px 0px 5px',
                  borderRadius: '6px',
                }}>
                {data.label}
              </span>
            ),
          },
    [editing]
  );

  return (
    <Form.Group
      className={
        props.groupClassName ||
        cx(
          {
            fullWidth: props.fullWidth,
          },
          'form-outline mb-2 py-3 col-md-6'
        )
      }>
      {props.error ? (
        <Form.Label className="title-error-color float-left">
          <img
            src={UnionExclamation}
            style={{ verticalAlign: 'text-top', marginRight: '0.25rem' }}
          />
          {props.errorMessage || 'One option must be chosen'}
        </Form.Label>
      ) : (
        <Form.Label
          className="title-color float-left"
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          {props.label}
          {!editing && props?.allowEdit ? (
            <button
              onClick={onEdit}
              style={{
                border: 'none',
                background: 'transparent',
              }}
              type="button">
              <Image src={EDIT_ICON} alt="" />
            </button>
          ) : null}
        </Form.Label>
      )}
      {props.allowCreatable ? (
        <div className="custon-dis">
          <CreatableSelect
            onCreateOption={handleCreate}
            onChange={props.onChangeCallback}
            {...commonProps}
            onBlur={(e) => {
              if (_.isFunction(props.onBlurCallback)) {
                props.onBlurCallback(e.target.value);
              } else {
                console.log(
                  `CreatableSelect: no onBlur function enabled for this item id: ${props.id}`
                );
              }
            }}
            onFocus={(e) => {
              if (_.isFunction(props.onFocusCallback)) {
                props.onFocusCallback(e.target.value);
                console.log(
                  `CreatableSelect: no onFocus function enabled for this item id: ${props.id}`
                );
              } else {
                console.log(
                  `CreatableSelect: no onFocus function enabled for this item id: ${props.id}`
                );
              }
            }}
            components={{
              ...selectComponents,
            }}
          />
        </div>
      ) : (
        <Select
          {...commonProps}
          styles={selectBoxStyles}
          options={props.options}
          onChange={props.onChangeCallback}
          classNamePrefix={commonProps.isMulti ? 'lfSelectMulti' : ''}
          onBlur={(e) => {
            if (_.isFunction(props.onBlurCallback)) {
              props.onBlurCallback(e.target.value);
            } else {
              console.log(`no onBlur function enabled for this item id: ${props.id}`);
            }
          }}
          onFocus={(e) => {
            if (_.isFunction(props.onFocusCallback)) {
              props.onFocusCallback(e.target.value);
            } else {
              console.log(`no onFocus function enabled for this item id: ${props.id}`);
            }
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            ...multiOnlyControl,
          }}
        />
      )}
    </Form.Group>
  );
}

export const SelectFormGroupV2 = ({
  containerClassName,
  label,
  error,
  loadOptions,
  onCreateOption,
  onBlur,
  onFocus,
  isMulti,
  menuZIndex = 100,
  theme,
  components,
  customStyle,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const bodyRef = useElementRef('body');
  const creatable = Boolean(onCreateOption);
  let SelectComponent = creatable ? CreatableSelect : Select;
  if (loadOptions) {
    SelectComponent = creatable ? CreatableAsyncPaginate : AsyncPaginate;
  }

  const errorContent = (
    <Form.Label className="title-error-color float-left" style={{ fontSize: '0.875rem', fontWeight: '400' }}>
      <img src={UnionExclamation} style={{ verticalAlign: 'text-top', marginRight: '0.25rem' }} />
      {error || 'One option must be chosen'}
    </Form.Label>
  );

  const singleProps = !isMulti && {
    controlShouldRenderValue: !focused,
    blurInputOnSelect: true,
  };

  const isWhiteTheme = theme === 'white';

  const styles = useMemo(() => ({
    ...reactSelectStyles,
    ...(isMulti && {
      valueContainer: (styles, { hasValue }) => {
        return {
          ...(reactSelectStyles.valueContainer
            ? reactSelectStyles.valueContainer(styles)
            : styles),
          maxHeight: 80,
          overflowY: 'auto',

          '::-webkit-scrollbar': {
            display: 'none',
          },
        };
      },
    }),
    control: (styles, { hasValue }) => {
      return {
        ...reactSelectStyles.control(styles),
        backgroundColor: error
          ? 'var(--error-background-color)'
          : hasValue || isWhiteTheme
          ? 'var(--white-color)'
          : 'var(--neutral20-color)',
        border: error
          ? '1px solid var(--error-color)'
          : hasValue || isWhiteTheme
          ? '1px solid var(--neutral20-color)'
          : 'transparent',
      };
    },
    menuPortal: (styles) => ({
      ...styles,
      zIndex: menuZIndex,
    }),
    menu: (styles, data) => {
      return {
        ...reactSelectStyles.menu(styles, data),
        ...customStyle?.menu,
      };
    },
  }), [error])

  return (
    <Form.Group className={containerClassName}>
      {error && errorContent}
      {!error && label && (
        <Form.Label className="title-color" style={{ fontSize: '0.875rem' }}>
          {label}
        </Form.Label>
      )}
      <SelectComponent
        styles={styles}
        loadOptions={loadOptions}
        onCreateOption={onCreateOption}
        menuPlacement="auto"
        menuPortalTarget={bodyRef.current}
        isMulti={isMulti}
        allowCreateWhileLoading={false}
        components={{
          ClearIndicator: () => null,
          ...components,
        }}
        {...singleProps}
        {...rest}
        onFocus={(...args) => {
          setFocused(true);
          onFocus?.(...args);
        }}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
      />
    </Form.Group>
  );
};

export const OptionImage = (props) => {
  return (
    <components.Option {...props}>
      <Image
        src={props.data.thumbnail}
        placeholderWhileLoading
        ratio={1}
        containerProps={{ style: { width: 32, marginRight: 8 } }}
      />
      {props.children}
    </components.Option>
  );
};
