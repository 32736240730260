export const MAX_PROJECT_NAME_LENGTH = 255;
export const MIN_PROJECT_NAME_LENGTH = 1;

export const MAX_PRODUCT_NAME_LENGTH = 255;
export const MIN_PRODUCT_NAME_LENGTH = 1;

export const MIN_PRODUCT_PRICE = 0;
export const MAX_PRODUCT_PRICE = 9999;

export const MIN_PRODUCT_DIMENSION = 0;
export const MAX_PRODUCT_DIMENSION = 9999;

export const MIN_PRODUCT_DESCRIPTION_LENGTH = 0;
export const MAX_PRODUCT_DESCRIPTION_LENGTH = 1024;

export const MIN_ZOOM_SPEED = 0.1;
export const MAX_ZOOM_SPEED = 10;
export const DEFAULT_ZOOM_SPEED = 1;

export const MIN_PAN_SPEED = 0.1;
export const MAX_PAN_SPEED = 10;
export const DEFAULT_PAN_SPEED = 2;

export const MAX_FIRST_NAME_LENGTH = 255;
export const MAX_LAST_NAME_LENGTH = 255;

export const DEFAULT_VERTICAL_ROTATION_SPEED = 79;
export const DEFAULT_HORIZONTAL_ROTATION_SPEED = 50;

export const NAME_SPECIAL_CHARS = /[\\\/:\.?'<>|]/g
export const NAME_SPECIAL_CHARS_V2 = /[^a-zA-Z \-0-9]/g
export const NAME_ALLOWED_CHARS = /[a-zA-Z \-0-9]/g


const ONE_MB = 1048576;
export const MAX_RENDER_IMAGE_FILE_SIZE = 50 * ONE_MB; 