import { useState, useEffect, useContext, memo } from 'react';
import { Offcanvas, Image, Form, Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import ReactTimeAgo from 'react-time-ago';
import { CLOSE_ICON, EDIT_ICON } from '../constants/assetConstants';
import { addValueLabelToList } from '../helpers/jsHelper';
import Input from './Input';
import styles from './RightSidebarN.module.scss';
import SelectFormGroup from './SelectFormGroup';
import { apiGetCachedList } from '../helpers/axiosHelper';
import { apiFilterOptions } from '../helpers/api';
import Button from '../components/Button/Button';
import Loader from './Loader';
import { warningToast } from '../helpers/toastHelper';
import { CHANGED_MAP_PATHS_MAP } from '../config/constant/unityConstants';
import { useUserContext } from '../contexts/UserContext';

const RightSidebarN = memo((props) => {
  const [sidebarOpen, setSidebarOpen] = useState(props.initialShowOpen ? true : false);

  const userCtx = useUserContext();
  const currentUser = userCtx && userCtx.currentUser;
  const userName = currentUser && `${currentUser.user.first_name} ${currentUser.user.last_name}`;
  const userCurrentOrgName = currentUser && currentUser.organization_detail.name;
  const { id, name, thumbnail, updated_at, filteroptions, tags } = props.title;
  const {
    TEMPLATE_TYPE_OPTIONS,
    FILTER_TYPE,
    onChangeCallback,
    setEditItem,
    setLoading,
    loading,
    saveInfo,
  } = props.body;
  const [item, setItem] = useState(props.title);
  const [isChange, setIsChange] = useState(false);
  let template_type = '';
  let template_type_value = '';

  if ('room_type' in props.title) {
    template_type = 'room_type';
    template_type_value = props?.title?.room_type;
  }

  if ('project_type' in props.title) {
    template_type = 'project_type';
    template_type_value = props?.title?.project_type;
  }

  const [templateObj, setTemplateObj] = useState({});
  const [filtersList, setFiltersList] = useState([]);
  const [allFilterList, setAllFilterList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [globalTagsList, setGlobalTagsList] = useState([]);

  useEffect(() => {
    if (!props.initialShowOpen) {
      setSidebarOpen(false);
    } else if (props.initialShowOpen && !sidebarOpen) {
      setSidebarOpen(true);
    }
  }, [props]);

  useEffect(async () => {
    const tgList = await apiGetCachedList('global_tags');
    if (tgList && tgList.length > 0) {
      setGlobalTagsList(tgList);
    }
    const allFList = await apiFilterOptions(`type=${FILTER_TYPE}`);
    if (allFList && allFList.results.length > 0) {
      setAllFilterList(addValueLabelToList(allFList.results));
    }
    setFiltersList(addValueLabelToList(filteroptions));
    if (template_type === 'room_type') {
      const tl =
        tags && tags.length > 0
          ? _.map(tags, (t) => {
              return { label: t, value: t };
            })
          : [];
      setTagsList(tl);
      setTemplateObj(_.find(TEMPLATE_TYPE_OPTIONS, (t) => t.value === template_type_value));
    }

    if (template_type === 'project_type') {
      setTagsList(tags);
      setTemplateObj(template_type_value);
    }
  }, []);

  useEffect(() => {
    setItem({
      ...item,
      [template_type]: templateObj,
      filteroptions: filtersList,
      tags: tagsList,
    });
  }, [templateObj, filtersList, tagsList]);

  const onClose = () => {
    setSidebarOpen(false);
    props.onClose();
  };

  const onChange = (field, value) => {
    setIsChange(true);
    console.log(field, value);
    const d = { ...item };
    d[field] = value;
    console.log('d onchange', d);
    setItem(d);
  };
  const saveDetails = async () => {
    if (isChange === false) {
      warningToast('No changes are made to save.');
      return;
    }
    setLoading(true);
    setIsChange(false);
    await saveInfo(item);
  };
  const onCancel = () => {
    setEditItem(null);
  };

  useEffect(() => {
    console.log('isChange', isChange);
    console.log('item', item);
    // console.log('globalTagsList', globalTagsList);
  }, [isChange, item, globalTagsList]);

  return (
    <div className="rightSidebar">
      <Offcanvas
        show={sidebarOpen}
        onHide={onClose}
        placement={props.placement || 'end'}
        className={props.className || ''}>
        <Offcanvas.Header className={`align-items-top ${styles.sidebarHeader}`}>
          <Offcanvas.Title className="fullWidth">
            <header className={styles.header}>
              {thumbnail && <Image src={thumbnail} alt="Avatar" />}
              <div className={styles.info}>
                <h5>{name}</h5>
                <p>
                  {template_type === 'room_type' ? (
                    userCurrentOrgName
                  ) : (
                    <>
                      {userName ? <span>{userName}&nbsp; &#x25CF; &nbsp;</span> : ''}
                      <ReactTimeAgo date={Date.parse(updated_at)} />
                    </>
                  )}
                </p>
              </div>
            </header>
          </Offcanvas.Title>
          <button className={styles.sidebarCloseButton} onClick={onClose}>
            <Image src={CLOSE_ICON} alt="" />
          </button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form className={`d-block ${styles.modelForm}`}>
            <Form.Group className="fullWidth form-outline py-3">
              <Input
                label={`${
                  template_type.charAt(0).toUpperCase() +
                  template_type.slice(1).replace('_type', '')
                } Name`}
                name="name"
                onChange={(e) => onChange(e.name, e.value)}
                value={item?.name}
                placeholder={`${
                  template_type.charAt(0).toUpperCase() +
                  template_type.slice(1).replace('_type', '')
                } Name`}
              />
            </Form.Group>
            {/* {template_type && ( */}
            <SelectFormGroup
              // error // TO DO
              label="Type of project"
              id={template_type}
              name={template_type}
              onChangeCallback={(e) => onChange(template_type, e)}
              value={item[template_type]}
              options={TEMPLATE_TYPE_OPTIONS}
              selectLabel={`${template_type.replace('_type', '')} type`}
              fullWidth
              allowCreatable
            />
            {/* )} */}
            {allFilterList?.length && (
              <>
                <SelectFormGroup
                  label="Categories"
                  id="filteroptions"
                  name="filteroptions"
                  onChangeCallback={(e) => onChange('filteroptions', e)}
                  value={item?.filteroptions}
                  options={allFilterList}
                  selectLabel="category"
                  allowCreatable
                  isMulti
                  fullWidth
                  allowEdit
                />
              </>
            )}
            {globalTagsList?.length && (
              <SelectFormGroup
                label="Style"
                id="tags"
                name="tags"
                onChangeCallback={(e) => onChange('tags', e)}
                value={item?.tags}
                options={globalTagsList}
                selectLabel="style"
                allowCreatable
                isMulti
                fullWidth
                allowEdit
              />
            )}
            <Row>
              <Col>
                <Button color="secondary" className="w-100" size="lg" onClick={onCancel}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="btn-lg w-100"
                  variant="primary"
                  color="warning"
                  size="lg"
                  onClick={saveDetails}>
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>
        <Loader position="absolute" loading={loading} backdrop />
      </Offcanvas>
    </div>
  );
});

export default RightSidebarN;
