import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFullscreen } from '../hooks/useFullscreen';
import Portal from 'react-overlays/esm/Portal';
import { PreviewSidebar, PreviewDisplay } from './';

function Preview(props) {

  const {
    id,
    title,
    actions,
    onClose,
    loading,
    updatedAt,
    created_at,
    userName,
    sidebarCards,
    theme,
    sidebarProps,
    previewProps,
    slides,
    activeIndex,
    setActiveIndex,
    activeCameraId,
    setActiveCameraId,
    passOptions,
    pass,
    setPass,
    memberDetails,
    hideAutoEraseWarning,
    ...rest
  } = props;

  const { projectId } = useParams();
  const [portalRef, setPortalRef] = useState();
  const { toggle, ref, isFullscreen } = useFullscreen();

  useEffect(() => {
    setPortalRef(document.querySelector('body'));
  }, []);

  const newActions = actions?.map(action => {
    return action.title === 'Full Screen'  // -> it's bad practice -> that's why earlier we used `name` or we can add some boolean instead of checking text
      ? { ...action, clickFunction: toggle }
      : action
  })?.sort(
    (a, b) => a.seq - b.seq
  );
  
  return (
    <Portal container={portalRef}>
      <div style={{ backgroundColor: '#f9f9f9' }} ref={ref} >
        <PreviewDisplay
          {...previewProps}
          id={id}
          theme={theme}
          actions={newActions}
          isFullscreen={isFullscreen}
          onClose={onClose}
          title={title}
          toggleFullscreen={toggle}
          slides={slides}
          cards={sidebarCards}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          activeCameraId={activeCameraId}
          setActiveCameraId={setActiveCameraId}
          passOptions={passOptions}
          pass={pass}
          setPass={setPass}
          {...rest}
        />

        {!isFullscreen && (
          <PreviewSidebar
            {...sidebarProps}
            theme={theme}
            title={title}
            updatedAt={updatedAt}
            created_at={created_at}
            userName={userName}
            cards={sidebarCards}
            isFullscreen={isFullscreen}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            activeCameraId={activeCameraId}
            setActiveCameraId={setActiveCameraId}
            memberDetails={memberDetails}
            loading={loading}
            hideAutoEraseWarning={hideAutoEraseWarning}
            {...rest}
          />
        )}
      </div>
    </Portal>
  );
}

export default Preview;
