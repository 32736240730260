import { createSlice } from '@reduxjs/toolkit';
import { dispatcher } from '../../helpers/projectHelper';

const _addToCollection = createSlice({
  name: 'addToCollection',
  initialState: {
    items: [],
    modelType: null,
  },
  reducers: {
    add: (state, { payload: { modelType, items } }) => {
      state.items.push(...items);
      state.modelType = modelType;
    },
    clear: (state) => {
      state.items = [];
      state.modelType = null;
    },
  },
});

export const addToCollectionActions = _addToCollection.actions;
export const addToCollectionSelector = (state) => state.addToCollection;
export const addToCollectionReducer = _addToCollection.reducer;
export default addToCollectionReducer;

export const selectAddToCollectionItems = (state) => state.addToCollection.items
export const selectAddToCollectionModelType = (state) => state.addToCollection.modelType


export const addToCollection = ({ items, modelType }) => {
  dispatcher(addToCollectionActions.add({
    items,
    modelType,
  }))
}