import PubSub from 'pubsub-js';
import { throttle } from 'lodash';
import { undoRedo } from '../components/admin/curator/UndoRedo/UndoRedo';
import { FOV_MIN_VALUE } from '../config/constant/projectConstant';
import { setPasteMaterialLoading, setUnityObjectImagesProcessing } from '../redux/slicers/admin/curatorLoaderSlicer';
import { selectActiveMeasureUnitsName, selectCursorMode, setAllPropsLoaded, setRoomLoadedCompletely, toggleSelectedAxis } from '../redux/slicers/admin/curatorSlicer';
import { resetObjectPositionAlongAxis, setObjectPositionAlongAxis } from '../redux/slicers/admin/curatorUnityObjectSlicer';
import { setGlobalFov } from '../redux/slicers/camera/cameraSettings';
import { dispatcher } from './projectHelper';
import { errorToast, successToast } from './toastHelper';
import { getAppState } from '../redux/store';
import { convertMetersToUnits } from './jsHelper';
import { CURSOR_MODE } from '../config/constant/unityConstants';

export const UnityEvent = {
  // unity events
  FocusObjectNameReceiver: 'FocusObjectNameReceiver',
  SwitchTransformTypeReceiver: 'SwitchTransformTypeReceiver',
  AllPropsLoaded: 'AllPropsLoaded',
  GetRoomInfo: 'GetRoomInfo',
  GetSelectedObjectInfoOnSelection: 'GetSelectedObjectInfoOnSelection',
  PredefinedCameraDetailsReceiver: 'PredefinedCameraDetailsReceiver',
  GetLightData: 'GetLightData',
  CameraFocalLengthReceiver: 'CameraFocalLengthReceiver',
  UpdatedFovReceiver: 'UpdatedFovReceiver',
  OnLibraryPropInstantiated: 'OnLibraryPropInstantiated',
  OnReceiveObjectPositionAlongAxis: 'OnReceiveObjectPositionAlongAxis',
  OnReceieveCurrentCameraScreenshot: 'OnReceieveCurrentCameraScreenshot',
  ReceiveBase64StringGenerateMap: 'ReceiveBase64StringGenerateMap',
  OnDeleteOperationCompleted: 'OnDeleteOperationCompleted',
  OnPropInstantiation: 'OnPropInstantiation',
  AllImagesOfObjectProcessed: 'AllImagesOfObjectProcessed',
  ReceiveSelectedObjectInfo: 'ReceiveSelectedObjectInfo',
  PasteMaterialPropertiesFinishedReceive: 'PasteMaterialPropertiesFinishedReceive',
  OnReceiveUpdatedCameraDetailsForOldZipFile: 'OnReceiveUpdatedCameraDetailsForOldZipFile',
  CameraDetailsReceiver: 'CameraDetailsReceiver',
  SelectedLightReceiver: 'SelectedLightReceiver',
  OnRoomLoadedCompletely: 'OnRoomLoadedCompletely',
  ReceiveObjectInfoBeforePasteMaterial: 'ReceiveObjectInfoBeforePasteMaterial',
  OnReceiveUpdatedPasteMaterialData: 'OnReceiveUpdatedPasteMaterialData',
  ApplyMaterialPropertiesFinishedReceive: 'ApplyMaterialPropertiesFinishedReceive',
  OnDeleteRoomMetadata: 'OnDeleteRoomMetadata',
  OnEditMetadataComplete: 'OnEditMetadataComplete',
  OnPropsDuplicate: 'OnPropsDuplicate', // callback fired when prop is placed somewhere -> provides placement information about object
  UnselectTheAxisReceiver: 'UnselectTheAxisReceiver',
  OnReceiveSpaceState: 'OnReceiveSpaceState',
  CurrentViewCameraDetailsReceiver: 'CurrentViewCameraDetailsReceiver',
  UNITY_LOADED: 'UNITY_LOADED',
  REPLACE_PROP_DONE: 'REPLACE_PROP_DONE',
};

export const AppEvent = {
  ResolveTextureConflicts: 'ResolveTextureConflicts',
  ReceiveSelectedObjectInfoHandled: 'ReceiveSelectedObjectInfoHandled',
  RENDER_IMAGE_UPLOADED: 'RENDER_IMAGE_UPLOADED',
};

export const KEYBOARD_EVENT = {
  ESC_PRESSED: 'ESC_PRESSED',
};

window.FocusObjectNameReceiver = (objectName, displayObjectName) => {
  PubSub.publish(UnityEvent.FocusObjectNameReceiver, { objectName, displayObjectName });
};

window.SwitchTransformTypeReceiver = (message) => {
  PubSub.publish(UnityEvent.SwitchTransformTypeReceiver, message);
};

window.AllPropsLoaded = () => {
  const data = {
    method: 'props-loaded',
    data: { id: 'loaded' },
  };
  // TODO: this is some old implementation -> refactor it later
  window.postMessage(data);
  dispatcher(setAllPropsLoaded(true));
  PubSub.publish(UnityEvent.AllPropsLoaded, {});
};

window.GetRoomInfo = (roomInfo) => {
  localStorage.setItem('roomInfo', roomInfo);
  console.log('roomInfo', JSON.parse(roomInfo));
  PubSub.publish(UnityEvent.GetRoomInfo, JSON.parse(roomInfo));
};

export const pubSubState = {
  isDuplicate: false, // changes to true to when we duplicating an object -> so in the next `GetSelectedObjectInfoOnSelection` we know that it's not selection but duplication of an object. It would be great if unity informs react about it
};

window.GetSelectedObjectInfoOnSelection = (objectListJson) => {
  const { isDuplicate } = pubSubState;
  pubSubState.isDuplicate = false;

  const objectList = JSON.parse(objectListJson) || [];
  const isTransformAction = objectList && objectList.length > 0 && objectList[0].isTransformChanged;
  console.log('GetSelectedObjectInfoOnSelection', objectList);

  if (isTransformAction) {
    // transformation
    undoRedo.unityObject.trasnform({ objectList });
    return;
  }

  if (undoRedo.unityObject.replacePropInProgress) {
    // replace prop flow. It's triggered few times we're interested in new object only and ignore old objects
    if (objectList.length > 0) {
      PubSub.publish(UnityEvent.REPLACE_PROP_DONE, objectList)
    }
  } else if (isDuplicate) {
    undoRedo.unityObject.savePrevObjectListForDuplicateUndoSelection();
  } else {
    // object selection
    undoRedo.unityObject.selectObject({ objectList });
  }

  PubSub.publish(UnityEvent.GetSelectedObjectInfoOnSelection, objectList);
};

window.PredefinedCameraDetailsReceiver = (cameraPresetsJson) => {
  const cameraPresetsList = JSON.parse(cameraPresetsJson);
  PubSub.publish(UnityEvent.PredefinedCameraDetailsReceiver, cameraPresetsList);
};

window.GetLightData = (roomLightDataJson) => {
  const roomLightData = JSON.parse(roomLightDataJson);
  PubSub.publish(UnityEvent.GetLightData, roomLightData);
};

window.CameraFocalLengthReceiver = (focalLengthString) => {
  const focalLength = parseFloat(focalLengthString);
  PubSub.publish(UnityEvent.CameraFocalLengthReceiver, focalLength);
};

window.UpdatedFovReceiver = (fovValue) => {
  dispatcher(setGlobalFov(parseInt(fovValue) || FOV_MIN_VALUE));
  PubSub.publish(UnityEvent.UpdatedFovReceiver, fovValue);
};

window.OnLibraryPropInstantiated = () => {
  PubSub.publish(UnityEvent.OnLibraryPropInstantiated);
};

const updateFunc = throttle((value, transformType) => {
  const cursorMode = selectCursorMode(getAppState())
  if (cursorMode === CURSOR_MODE.MOVE) {
    const unitsName = selectActiveMeasureUnitsName(getAppState());
    value = convertMetersToUnits(value, unitsName);
  }
  dispatcher(
    setObjectPositionAlongAxis({
      value,
      transformType,
    })
  );
}, 49);
window.OnReceiveObjectPositionAlongAxis = (value, transformType) => {
  console.log('OnReceiveObjectPositionAlongAxis', value, transformType);
  updateFunc(value, transformType);
};

window.OnReceieveCurrentCameraScreenshot = (imageData) => {
  PubSub.publish(UnityEvent.OnReceieveCurrentCameraScreenshot, imageData);
};

window.AllImagesOfObjectProcessed = () => {
  console.log('AllImagesOfObjectProcessed');
};

window.ReceiveBase64StringGenerateMap = (objectName, mapType, imageInfo) => {
  PubSub.publish(UnityEvent.ReceiveBase64StringGenerateMap, {
    objectName,
    mapType,
    imageInfo,
  });
};

window.OnDeleteOperationCompleted = (objectListJson) => {
  const objectList = JSON.parse(objectListJson);
  undoRedo.unityObject.delete({ objectList });
  console.log('OnDeleteOperationCompleted', objectList);
  PubSub.publish(UnityEvent.OnDeleteOperationCompleted, { objectList });
};

window.AllImagesOfObjectProcessed = () => {
  setUnityObjectImagesProcessing(false);
};

window.CameraDetailsReceiver = (cameraJson) => {
  const cameraData = JSON.parse(cameraJson);
  PubSub.publish(UnityEvent.CameraDetailsReceiver, { cameraData });
};

window.OnPropInstantiation = (propDataJson) => {
  console.log('OnPropInstantiation', propDataJson)
  const propData = JSON.parse(propDataJson);
  // TODO: move everything related to undoredo to oundoredo class
  undoRedo.unityObject.addProp({ propData });
  PubSub.publish(UnityEvent.OnPropInstantiation, propData)
};

window.ReceiveSelectedObjectInfo = (data) => {
  PubSub.publish(UnityEvent.ReceiveSelectedObjectInfo, data);
};

window.SelectedLightReceiver = (lightJson) => {
  PubSub.publish(UnityEvent.SelectedLightReceiver, JSON.parse(lightJson));
};

export const waitForEventOnce = (eventName, funcOrData) => {
  let triggerFunc

  if (typeof funcOrData === 'function') {
    triggerFunc = funcOrData;
  } else if (funcOrData?.triggerFunc) {
    triggerFunc = funcOrData.triggerFunc
  }

  return new Promise((resolve) => {
    PubSub.subscribeOnce(eventName, (_, data) => {
      resolve(data);
    });

    triggerFunc?.();
  });
};

window.PasteMaterialPropertiesFinishedReceive = (...args) => {
  setPasteMaterialLoading(false)
  console.log('PasteMaterialPropertiesFinishedReceive', ...args)
  PubSub.publish(UnityEvent.PasteMaterialPropertiesFinishedReceive);
};

window.OnReceiveUpdatedCameraDetailsForOldZipFile = (cameraListJson) => {
  const cameraList = JSON.parse(cameraListJson);
  PubSub.publish(UnityEvent.OnReceiveUpdatedCameraDetailsForOldZipFile, cameraList);
};

window.ReceiveObjectInfoBeforePasteMaterial = (objectDataJson) => {
  // console.log('ReceiveObjectInfoBeforePasteMaterial', )
  PubSub.publish(UnityEvent.ReceiveObjectInfoBeforePasteMaterial, JSON.parse(objectDataJson));
}

window.OnRoomLoadedCompletely = () => {
  dispatcher(setRoomLoadedCompletely(true));
  PubSub.publish(UnityEvent.OnRoomLoadedCompletely);
}

window.OnReceiveUpdatedPasteMaterialData = (dataJson) => {
  const data = JSON.parse(dataJson)
  PubSub.publish(UnityEvent.OnReceiveUpdatedPasteMaterialData, data)
}

window.ApplyMaterialPropertiesFinishedReceive = () => {
  console.log('UNITY_OBJECT__COPY_PASTE_MATERIAL pub sub ApplyMaterialPropertiesFinishedReceive')
  PubSub.publish(UnityEvent.ApplyMaterialPropertiesFinishedReceive)
}

window.OnDeleteRoomMetadata = (status) => {
  PubSub.publish(UnityEvent.OnDeleteRoomMetadata, status)
}

window.OnEditMetadataComplete = (status) => {
  PubSub.publish(UnityEvent.OnEditMetadataComplete, status)
}

window.OnPropsDuplicate = (dataJson) => {
  const data = JSON.parse(dataJson);
  console.log('OnPropsDuplicate', data)
  PubSub.publish(UnityEvent.OnPropsDuplicate, data)
}

window.UnselectTheAxisReceiver = (axis) => {
  // TODO: add validation
  dispatcher(toggleSelectedAxis(axis))
  dispatcher(resetObjectPositionAlongAxis());
}

window.CurrentViewCameraDetailsReceiver = (cameraJson) => {
  PubSub.publish(UnityEvent.CurrentViewCameraDetailsReceiver, JSON.parse(cameraJson))
}

window.OnReceiveSpaceState = (globalActive) => {
  PubSub.publish(UnityEvent.OnReceiveSpaceState, parseInt(globalActive));
}

window.OnMessageReceivedFromUnity = (messageJson) => {
  const message = JSON.parse(messageJson);
  const toast = message.isError ? errorToast : successToast;
  toast(message.message)
};