export const CameraIcon2 = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32911 19.1998H15.3483C17.2437 19.1998 18.4024 18.1148 18.523 16.2193L18.8712 10.6871C18.9248 9.84985 18.6368 9.05283 18.0608 8.43664C17.4915 7.82715 16.6878 7.47887 15.8573 7.47887C15.6363 7.47887 15.4353 7.35162 15.3349 7.16408L14.8526 6.18622C14.4575 5.40929 13.4729 4.7998 12.6089 4.7998H11.0752C10.2112 4.7998 9.2199 5.40929 8.82473 6.19292L8.3425 7.15069C8.24204 7.35162 8.03441 7.47887 7.82008 7.47887C6.98957 7.47887 6.18585 7.82715 5.61655 8.43664C5.04055 9.04613 4.75255 9.84985 4.80613 10.6871L5.15441 16.2126C5.26157 18.0813 6.45376 19.1998 8.32911 19.1998ZM9.72222 6.64167C9.94994 6.19292 10.5795 5.80446 11.0752 5.80446V5.81115H12.6022C13.0978 5.81115 13.7274 6.19962 13.9485 6.64167L14.4374 7.61953C14.7187 8.14864 15.2612 8.48353 15.8573 8.48353C16.4199 8.48353 16.9423 8.71125 17.3308 9.1198C17.7125 9.52836 17.9001 10.0642 17.8666 10.6201L17.5183 16.1524C17.4312 17.5053 16.7012 18.1952 15.3483 18.1952H8.32911C6.96948 18.1952 6.23943 17.5053 6.15906 16.1524L5.81078 10.6201C5.77059 10.0642 5.96483 9.52836 6.34659 9.1198C6.73506 8.71125 7.25748 8.48353 7.82008 8.48353C8.41617 8.48353 8.95869 8.14864 9.23999 7.60613L9.72222 6.64167ZM9.15963 13.8417C9.15963 15.3219 10.3585 16.5207 11.8387 16.5207C13.3189 16.5207 14.5178 15.3219 14.5178 13.8417C14.5178 12.3615 13.3189 11.1626 11.8387 11.1626C10.3585 11.1626 9.15963 12.3615 9.15963 13.8417ZM10.1643 13.8417C10.1643 12.9174 10.9144 12.1672 11.8387 12.1672C12.763 12.1672 13.5131 12.9174 13.5131 13.8417C13.5131 14.7659 12.763 15.5161 11.8387 15.5161C10.9144 15.5161 10.1643 14.7659 10.1643 13.8417Z"
        fill="currentColor"
      />
    </svg>
  );
};
