import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  AXIS,
  CHANGED_MAP_IDS__TEXTURE_MAP,
  CURSOR_MODE,
  REVERSE_TILING_MAP,
  TEXTURE_MAP_TO_TILING_TYPE,
  TEXTURE_NAME_KEY,
  TEXTURE_NAME_KEY__TEXTURE_MAP,
  TILING_MAP,
  TILING_TEXTURE_MAP,
  TRANSFORM_SPACE_MODE,
  UNITY_2D_VIEW_MODE,
} from '../../../../config/constant/unityConstants';
import { useUserContext } from '../../../../contexts/UserContext';
import { apiAddDefaultTexture, apiGetDefaultTexture } from '../../../../helpers/api';
import {
  canChangeSelectedAxis,
  clearUI,
  toggleSidebarSection,
  validateStyleable,
} from '../../../../helpers/curatorActions';
import { getDefaultTextureList, updateDbProp, updateDbTexture } from '../../../../helpers/idb';
import { errorToast, successToast, warningToast } from '../../../../helpers/toastHelper';
import { getPropLightList } from '../../../../helpers/unityHelper';
import { getProjectsPath } from '../../../../helpers/urlHelper';
import { useInfoDialogaActions } from '../../../../hooks/useInfoDialogaActions';
import { useRenderPreviewPopup } from '../../../../hooks/useRenderPreviewPopup';
import * as curatorLight from '../../../../redux/slicers/admin/curatorLightSlicer';
import { setPasteMaterialLoading } from '../../../../redux/slicers/admin/curatorLoaderSlicer';
import { CURATOR_MENU_SECTION } from '../../../../redux/slicers/admin/curatorMenuPreferencesSlicer';
import {
  curatorActions,
  curatorSelector,
  SAVE_PROJECT_MODE,
  selectAxisAvailability,
  selectCuratorInitialized,
  selectCursorMode,
  selectIs2DMode,
  selectIs3DMode,
  selectIsStyleOnlyMode,
  selectMeasureUnit,
  selectProjectOwner,
  selectSelectedAxis,
  selectSidebarSection,
  selectTransformSpaceMode,
  setCuratorLoader,
  setCursorMode,
  setSaveProjectDialogOpen,
  setSaveProjectMode,
  setSidebarSection,
  setUnity2DViewMode,
  toggleDimension,
} from '../../../../redux/slicers/admin/curatorSlicer';
import {
  curatorStylesSelector,
  selectApplyTextureInProgress,
  setActiveTab,
  TAB_NAME,
} from '../../../../redux/slicers/admin/curatorStylesSlicer';
import {
  resetObjectPositionAlongAxis,
  selectCanReplaceProp,
  selectCopiedObjectProperties,
  selectHasSelectedObjectInfo,
  selectIsAnyObjectSelected,
  selectIsOneObjectSelected,
  selectIsSingleLightObjectSelected,
  selectIsStyleableObject,
  selectReplaceProp,
  selectSelectedObjectList,
  selectSelectedLightObject,
  selectSelectedObjectName,
  selectUnitySelectedObjectInfoRaw,
  setCopiedObjectProperties,
  setSelectedObjectList,
} from '../../../../redux/slicers/admin/curatorUnityObjectSlicer';
import { paginatedProductActions } from '../../../../redux/slicers/admin/paginatedProductSlicer';
import {
  selectHighlightSelected,
  selectIsMaterialCopied,
  selectLightsVisibility,
  selectPopupOpen,
  selectShowDimensions,
  setHighlightSelected,
  setLightsVisibility,
  setMaterialCopied,
  setPopupOpen,
  setReplacePropActive,
  setShowDimensions,
} from '../../../../redux/slicers/admin/shortcutsModuleSlicer';
import {
  selectCameraSafeModeEnabled,
  setGlobalSafeCameraFlag,
} from '../../../../redux/slicers/camera/cameraSettings';
import { getAppState } from '../../../../redux/store';
import { useUnityContext } from '../../../container/unityContainer';
import { getDefaultTexture, getDefaultTextureFromIdb, useLoadDefaultTextures } from '../stylesSection/PropertiesTab/useGetDefaultObjectMaps';
import { undoRedo } from '../UndoRedo/UndoRedo';

export const useUnityShortcuts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const unityContext = useUnityContext();
  const { openInfoDialog } = useInfoDialogaActions();
  const renderPreviewPopup = useRenderPreviewPopup();
  const loadDefaultTextures = useLoadDefaultTextures();
  const { currentUser } = useUserContext();

  const getRequiredState = () => {
    const appState = getAppState();
    const anyObjectSelected = selectIsAnyObjectSelected(appState);
    const isOnlyOneObjectSelected = selectIsOneObjectSelected(appState);
    const hasSelectedObjectInfo = selectHasSelectedObjectInfo(appState);
    const isStyleableObject = selectIsStyleableObject(appState);

    return {
      anyObjectSelected,
      isOnlyOneObjectSelected,
      hasSelectedObjectInfo,
      isStyleableObject,
    };
  };

  const checkIsStyleable = () => {
    const { isStyleableObject } = getRequiredState();
    if (!isStyleableObject) {
      errorToast('You can not style this object', { toastId: 'NOT_STYLEABLE_OBJECT'});
      return false;
    }

    return true;
  };

  const resetData = () => {
    dispatch(curatorActions.toggleSelectedAxis(AXIS.ALL));
    // dispatch(curatorActions.setTransformSpaceMode(TRANSFORM_SPACE_MODE.GLOBAL));
    dispatch(resetObjectPositionAlongAxis());
  };

  const changeCursorMode = (cursorMode) => {
    const currentCursorMode = selectCursorMode(getAppState());

    if (currentCursorMode === CURSOR_MODE.STYLE_ONLY) {
      unityContext.shortcutsModule.StyleOnlyReceiver(false);
    }

    switch (cursorMode) {
      case CURSOR_MODE.MOVE:
        unityContext.shortcutsModule.TransformTypeMoveReceiver();
        break;
      case CURSOR_MODE.ROTATE:
        unityContext.shortcutsModule.TransformTypeRotateReceiver();
        break;
      case CURSOR_MODE.SCALE:
        unityContext.shortcutsModule.TransformTypeScaleReceiver();
        break;
      case CURSOR_MODE.STYLE_ONLY:
        unityContext.shortcutsModule.StyleOnlyReceiver(true);
        break;
      default:
        throw new Error('Cursor mode not supported ' + cursorMode);
    }

    dispatch(setCursorMode(cursorMode));

    resetData();
  };

  const toggleTransformSpaceMode = (newMode) => {
    if (typeof newMode === 'undefined') {
      const transformSpaceMode = selectTransformSpaceMode(getAppState());
      newMode =
        transformSpaceMode === TRANSFORM_SPACE_MODE.GLOBAL
          ? TRANSFORM_SPACE_MODE.LOCAL
          : TRANSFORM_SPACE_MODE.GLOBAL;
    }

    unityContext.shortcutsModule.TransformSpaceReceiver(newMode);
    // unity will trigger changing of state so we don't need to update it
    // dispatch(curatorActions.setTransformSpaceMode(newMode));
  };

  const toggleSelectedAxis = (axis) => {
    if (!canChangeSelectedAxis(undefined, { showWarnings: true })) {
      return;
    }

    const axisAvailability = selectAxisAvailability(getAppState());

    if (!axisAvailability[axis]) {
      // errorToast('It is not possible to switch to `${axis}` when 2d is activated')
      return;
    }
    dispatch(resetObjectPositionAlongAxis());
    unityContext.shortcutsModule.OnClickSelectAxisButtonReceiver(axis);
    dispatch(curatorActions.toggleSelectedAxis(axis));
  };

  const disableSelectedAxis = () => {
    const selectedAxis = selectSelectedAxis(getAppState());

    if (selectedAxis !== AXIS.ALL) {
      dispatch(resetObjectPositionAlongAxis());
      unityContext.shortcutsModule.OnClickSelectAxisButtonReceiver(selectedAxis);
      dispatch(curatorActions.toggleSelectedAxis(AXIS.ALL));
    }
  };

  const changeDimension = () => {
    const is3DMode = selectIs3DMode(getAppState());
    const sidebarSection = selectSidebarSection(getAppState());
    const switchingTo2DMode = is3DMode;

    if (switchingTo2DMode) {
      dispatch(resetObjectPositionAlongAxis());
      // reset view state when we switch to 2D mode
      dispatch(setUnity2DViewMode(UNITY_2D_VIEW_MODE.TOP));
      disableSelectedAxis();
    }

    unityContext.shortcutsModule.Switch3d2DViewReceiver();
    dispatch(toggleDimension());

    if (switchingTo2DMode && sidebarSection === CURATOR_MENU_SECTION.CAMERAS) {
      dispatch(setSidebarSection(null));
    }
  };

  const snapObject = (snap) => {
    unityContext.shortcutsModule.snapObject(snap);
  };

  const removeSelection = (keyDown) => {
    unityContext.shortcutsModule.removeSelection(keyDown);
  };

  const switchToMove = () => {
    changeCursorMode(CURSOR_MODE.MOVE);
  };

  const switchToRotate = () => {
    changeCursorMode(CURSOR_MODE.ROTATE);
  };

  const switchToScale = () => {
    changeCursorMode(CURSOR_MODE.SCALE);
  };

  const switchToStyleOnly = () => {
    changeCursorMode(CURSOR_MODE.STYLE_ONLY);
  };

  const duplicateObject = () => {
    const { anyObjectSelected } = getRequiredState();
    // const isStyleOnlyMode = selectIsStyleOnlyMode(getAppState());

    // if (isStyleOnlyMode) {
    //   errorToast("It's not possible to duplicate object in style only mode", { toastId: 'DUPLICATE_NOT_ALLOWED_STYLE_ONLY_MODE' });
    //   return;
    // }

    if (!anyObjectSelected) {
      errorToast('Please select an object', { toastId: 'DUPLICATE_NOT_ALLOWED_OBJECT_NOT_SELECTED' });
      return;
    }

    clearUI();
    unityContext.shortcutsModule.DuplicatePropReceiver();
  };

  const deleteObject = () => {
    const selectedObjectList = selectSelectedObjectList(getAppState())
    const hasNotDeletableObject = selectedObjectList.find(obj => !obj.isDeleteAble);

    if (hasNotDeletableObject) {
      warningToast(`You can not delete roofs, wall, and floor`, { toastId: 'CAN_NOT_DELETE_LAYOUT'})
    }

    unityContext.shortcutsModule.DeleteObjectReceiver();
  };

  const replaceProp = () => {
    const canReplaceProp = selectCanReplaceProp(getAppState());
    if (!canReplaceProp) {
      errorToast('Please select replaceable object');
      return;
    }

    const isLightSelected = selectIsSingleLightObjectSelected(getAppState());
    if (isLightSelected) {
      openEditSelectedLight();
      return;
    }

    dispatch(paginatedProductActions.fullResetList());
    dispatch(setReplacePropActive(true));
    dispatch(setSidebarSection(CURATOR_MENU_SECTION.PRODUCTS));
  };

  const doReplaceProp = async (newProp, { skipHistory = false } = {}) => {
    const selectedProp = selectReplaceProp(getAppState());

    if (!selectedProp) {
      errorToast('Prop to replace is missing');
      cancelReplaceProp();
      return;
    }

    dispatch(setCuratorLoader(false));
    const productDbData = await updateDbProp(newProp);
    dispatch(setCuratorLoader(true));

    const lightList = await getPropLightList(newProp);

    unityContext.roomModule.ReplaceProp({
      propId: selectedProp.id,
      objectName: selectedProp.objectName,
      newPropId: newProp.id,
      newPropUrl: productDbData.file || newProp.product_file,
      isMatch: !newProp.is_scale,
      roomLightDatas: lightList,
      newPropName: newProp.name,
      preDefinedMetadata: newProp.product_meta_info,
    });

    cancelReplaceProp();
    // setSelectedObjectList({});

    undoRedo.unityObject.replaceProp({ newProp, skipHistory, selectedProp })
  }

  const replacePropConfirm = (newProp) => {
    openInfoDialog({
      title: `Replace prop with "${newProp.name}"?`,
      buttonText: 'Yes',
      cancelButtonText: 'No',
      onButtonClick: () => doReplaceProp(newProp),
    });
  };

  const is2DView = () => {
    return selectIs2DMode(getAppState());
  };

  const cancelReplaceProp = () => {
    dispatch(setSidebarSection(null));
    dispatch(setReplacePropActive(false));
  };

  const softCancelReplaceProp = () => {
    dispatch(setReplacePropActive(false));
  };

  const copyMaterial = () => {
    if (!checkIsStyleable()) return;
    const selectedObject = selectUnitySelectedObjectInfoRaw(getAppState());
    loadDefaultTextures({
      object: selectedObject,
      // objectName: selectedObject.objectName,
      materialId: selectedObject.loadedMaterialId,
    });
    unityContext.shortcutsModule.copyMaterial();
    dispatch(setMaterialCopied(true));
    const objectProperties = selectUnitySelectedObjectInfoRaw(getAppState());
    dispatch(setCopiedObjectProperties(objectProperties));
    successToast('Material copied');
  };

  const pasteMaterial = async () => {
    const isMaterialCopied = selectIsMaterialCopied(getAppState());
    if (!isMaterialCopied) {
      errorToast('Please copy a material first');
      return;
    }

    // check if all objects are styleable
    const selectedObjectList = selectSelectedObjectList(getAppState());
    const nonStyleableObject = selectedObjectList.find(obj => obj.isStyleAble === false);
    
    if (nonStyleableObject) {
      errorToast(`It's not possible to style current objects selection`);
      return;
    }

    setPasteMaterialLoading(true)

    const copiedObject = selectCopiedObjectProperties(getAppState());

    if (copiedObject.loadedMaterialId) {
      // paste material from collection
      setTimeout(() => unityContext.shortcutsModule.pasteMaterial(), 20) // timeout to let react update ui with loader;
    }  else {
      // paste default material
      const { objectName } = copiedObject

      // TODO: go through all default texture names here instead of object name
      // const defaultTextureList = await getDefaultTextureList({ objectName });

      const defaultTextureNameKeys = Object.values(TEXTURE_NAME_KEY);

      // TODO: make sure that uniqueness is handled correctly -> if one default texture applied to 2 custom maps we will try to upload both of them at the same time
      const textureList = await Promise.all(defaultTextureNameKeys.map(async (textureNameKey) => {
        const textureName = copiedObject[textureNameKey];
        if (!textureName) return;
        
        const textureType = TEXTURE_NAME_KEY__TEXTURE_MAP[textureNameKey]
        const tilingType = TEXTURE_MAP_TO_TILING_TYPE[textureType];
        const mapType = TILING_MAP[tilingType]
        const idKey = CHANGED_MAP_IDS__TEXTURE_MAP[textureType]

        if (copiedObject.changedMapsPath[idKey]) return;
        // { file, textureName,  }
        const defaultTexture = await getDefaultTextureFromIdb({ textureName });
        const { file } = defaultTexture

        const defaultTextureDataList = await apiGetDefaultTexture({ name: textureName });
        const defaultTextureData = defaultTextureDataList?.results?.[0];

        let texture;
        if (defaultTextureData) {
          texture = await updateDbTexture(defaultTextureData);
        } else {
          const uploadedTexture = await apiAddDefaultTexture({ name: textureName, file })

          if (!uploadedTexture?.data?.id) {
            setPasteMaterialLoading(false);
            return;
          }

          texture = await updateDbTexture(uploadedTexture.data);
        }

        return {
          mapType,
          textureId: texture.id,
          textureName: texture.name,
          textureVersion: texture.version,
        };
      }))

      const filteredTextureList = textureList.filter(Boolean);

      setTimeout(() => unityContext.shortcutsModule.pasteMaterial(filteredTextureList), 20) // timeout to let react update ui with loader;
    }
  };

  const update2DViewMode = (viewMode) => {
    if (!is2DView()) {
      changeDimension();
    }

    // switch between 'bottom' | 'left' | 'right' | 'top'  in 2d mode
    const selectedAxis = selectSelectedAxis(getAppState());
    // toggleSelectedAxis(selectedAxis);
    disableSelectedAxis();
    dispatch(setUnity2DViewMode(viewMode));
    unityContext.shortcutsModule.OnSetup2DViewsReceiver(viewMode);
  };

  const closeProject = () => {
    const curatorInitialized = selectCuratorInitialized(getAppState());

    if (!curatorInitialized) {
      // curator still loading no need for save popup
      navigate(getProjectsPath());
      return;
    }

    openInfoDialog({
      title: 'Quit Project',
      description: `Do you want to save your changes?`,
      cancelButtonText: 'No',
      buttonText: 'Yes',
      onCancelClick: () => {
        navigate(getProjectsPath());
      },
      onButtonClick: () => {
        dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.CLOSE_CURATOR));
        dispatch(setSaveProjectDialogOpen(true));
      },
    });
  };

  const saveProject = () => {
    const projectOwner = selectProjectOwner(getAppState());
    const isOwner = projectOwner?.id === currentUser.user.id
    if (!isOwner) {
      saveProjectAsNew()
      return;
    } 

    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.SAVE));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const saveProjectAsNew = () => {
    dispatch(setSaveProjectMode(SAVE_PROJECT_MODE.NEW));
    dispatch(setSaveProjectDialogOpen(true));
  };

  const focusObject = () => {
    unityContext.shortcutsModule.focusObject();
  };

  const toggleStyleSection = (name) => {
    const valid = validateStyleable();
    if (!valid) return;

    const loading = selectApplyTextureInProgress(getAppState());
    if (loading) {
      errorToast('Please wait, previous texture is still in progress', {
        toastId: 'TEXTURE_APPLY_IN_PROGRESS_TAB_SWITCH_HOTKEY',
      });
      return;
    }

    const { activeTab } = curatorStylesSelector(getAppState());
    const sidebarActiveSection = curatorSelector(getAppState()).sidebarSection;

    if (activeTab !== name && sidebarActiveSection === CURATOR_MENU_SECTION.STYLE) {
      // in this case we just need to switch tabs
      dispatch(setActiveTab(name));
      return;
    }

    toggleSidebarSection(CURATOR_MENU_SECTION.STYLE);
    dispatch(setActiveTab(name));
  };

  const openEditSelectedLight = () => {
    const selectedLightObject = selectSelectedLightObject(getAppState());
    if (!selectedLightObject) {
      errorToast('Please select a light');
      return;
    }

    dispatch(setSidebarSection(CURATOR_MENU_SECTION.LIGHT));
    dispatch(curatorLight.setSelectedLightId(selectedLightObject.id));
    dispatch(curatorLight.setActiveTab(curatorLight.TAB_NAME.INDOOR));
  };

  const toggleProperties = () => {
    const isLightSelected = selectIsSingleLightObjectSelected(getAppState());
    const selectedObjectList = selectSelectedObjectList(getAppState())

    if (selectedObjectList.length > 1) {
      errorToast(`It's not possible to open object details for multiple selected items`)
      return;
    }

    if (isLightSelected) {
      openEditSelectedLight();
    } else {
      toggleStyleSection(TAB_NAME.PROPERTIES);
    }
  };

  const toggleTexture = () => {
    toggleStyleSection(TAB_NAME.TEXTURES);
  };

  const toggleMaterial = () => {
    toggleStyleSection(TAB_NAME.MATERIALS);
  };

  const toggleColor = () => {
    toggleStyleSection(TAB_NAME.COLORS);
  };

  const toggleImages = () => {
    toggleSidebarSection(CURATOR_MENU_SECTION.RENDERS_AND_PREVIEWS);
  };

  const toggleHighlightSelected = () => {
    const highlightSelected = selectHighlightSelected(getAppState());
    const newValue = !highlightSelected;
    unityContext.shortcutsModule.toggleHighlightSelected(newValue);
    dispatch(setHighlightSelected(newValue));
  };

  const toggleDimensions = () => {
    const dimensions = selectShowDimensions(getAppState());
    const measureUnit = selectMeasureUnit(getAppState());
    const newValue = !dimensions;

    unityContext.shortcutsModule.toggleDimensions(measureUnit.id - 1);
    dispatch(setShowDimensions(newValue));
  };

  const toggleLightsVisibility = () => {
    const lightsVisibility = selectLightsVisibility(getAppState());
    const newValue = !lightsVisibility;
    unityContext.shortcutsModule.toggleLightsVisibility(newValue);
    dispatch(setLightsVisibility(newValue));
  };

  const toggleCameraSafeMode = () => {
    const cameraSafeMode = selectCameraSafeModeEnabled(getAppState());
    dispatch(setGlobalSafeCameraFlag(!cameraSafeMode));
  };

  const focusEverything = () => {
    unityContext.shortcutsModule.focusEverything();
  };

  const toggleShortcuts = () => {
    const open = selectPopupOpen(getAppState());
    dispatch(setPopupOpen(!open));
    dispatch(setSidebarSection(null));
  };

  return {
    changeCursorMode,
    toggleTransformSpaceMode,
    toggleSelectedAxis,
    changeDimension,
    switchToMove,
    switchToRotate,
    switchToScale,
    switchToStyleOnly,
    duplicateObject,
    deleteObject,
    copyMaterial,
    pasteMaterial,
    update2DViewMode,
    closeProject,
    saveProject,
    saveProjectAsNew,
    focusObject,
    toggleProperties,
    toggleTexture,
    toggleMaterial,
    toggleColor,
    toggleImages,
    renderPreviewPopup,
    disableSelectedAxis,
    openEditSelectedLight,
    doReplaceProp,
    toggleHighlightSelected,
    toggleDimensions,
    toggleLightsVisibility,
    toggleCameraSafeMode,
    focusEverything,
    toggleShortcuts,
    replaceProp,
    replacePropConfirm,
    cancelReplaceProp,
    softCancelReplaceProp,
    snapObject,
    removeSelection,
    undo: undoRedo.undo,
    redo: undoRedo.redo,
  };
};
