import React, { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import {
  apiCreateIndustry,
  apiGetIndustries,
  apiGetIndustriesPreferences,
  apiGetUserDetails,
  apiUpdateUserDetails,
} from '../helpers/api';
import { errorToast } from '../helpers/toastHelper';
import makeAnimated from 'react-select/animated';
import { YourInterests } from './';
import { addValueLabelToList } from '../helpers/jsHelper';
import _ from 'lodash';
import { reactSelectStyles } from '../helpers/reactSelectStyles';
import CreatableSelect from 'react-select/creatable';
import { withAsyncPaginate } from 'react-select-async-paginate';
import { useSelectInputValue } from '../hooks/useSelectInputValue';
import InfoDialogUI from '../components/InfoDialog/InfoDialogUI';
import Button from '../components/Button/Button';
import { MODE, useApiList } from '../hooks/useApiList';
import { ItemSelectListUI } from './OrgSection/ItemSelectListUI';

const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect);

export default function Interests() {
  const animatedComponents = makeAnimated();
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [industriesLoaded, setIndustriesLoaded] = useState(false);
  const [industryDialogOpen, setIndustryDialogOpen] = useState(false);

  const [industryInputValue, onIndustryInputValueChange] = useSelectInputValue();

  useEffect(() => {
    getUserDetailsAndIndustries();
  }, []);

  const getSelectedIndustries = async (ids) => {
    const response = await apiGetIndustries({ ids });
    if (response?.results) {
      setSelectedIndustry(addValueLabelToList(response.results));
      setIndustriesLoaded(true);
    }
  };

  const fetchIndustries = async (search, loadedOptions, { page }) => {
    const response = await apiGetIndustries({ search, page });

    return {
      options: addValueLabelToList(response.results),
      hasMore: Boolean(response.next_link),
      additional: {
        page: page + 1,
      },
    };
  };

  async function getUserDetailsAndIndustries() {
    const response = await apiGetUserDetails();
    if (!response) return;
    setSelectedProducts(response.user_preferences);
    getSelectedIndustries(response.industry);
  }

  const updateUserPreferences = async (selectedProductIds) => {
    selectedProductIds = selectedProductIds || selectedProducts;
    const formData = new FormData();

    selectedProductIds.forEach((id) => {
      formData.append('user_preferences', id);
    });

    await apiUpdateUserDetails(formData);
  };

  const handleIndustryChange = async (newIndustries) => {
    if (!newIndustries.length) {
      setIndustryDialogOpen(true);
      return;
    }

    setSelectedIndustry(newIndustries);
    const industries = newIndustries.map((x) => x.value);
    const hasCustomIndustry = Boolean(newIndustries.find((i) => i.user));

    let formData = new FormData();

    industries.forEach((item) => {
      formData.append('industry', item);
    });

    const validProductIds = newIndustries
      .reduce((productIds, industry) => {
        productIds.push(...industry.industry_preferences)
        return productIds;
      }, [])
      .map((p) => p.id);

    const updatedProductIds = hasCustomIndustry
      ? selectedProducts
      : selectedProducts.filter((id) => validProductIds.includes(id));
    updatedProductIds.forEach((id) => {
      formData.append('user_preferences', id);
    });

    if (!updatedProductIds.length) {
      // fetch interests and select first one
      const preferences = await apiGetIndustriesPreferences(hasCustomIndustry ? [] : industries)

      if (!preferences?.results?.[0]?.id) {
        errorToast('Error happened while updating industry, please try again');
        return;
      }

      formData.append('user_preferences', preferences.results[0].id);
    }

    apiUpdateUserDetails(formData).then((response) => {
      if (!response) return;
      setSelectedProducts(response.user_preferences);
    });
  };

  const handleProductToggle = (id) => {
    const exist = selectedProducts.includes(id);
    const deletesLastProduct = exist && selectedProducts.length === 1;
    if (deletesLastProduct) {
      errorToast('You should have at least 1 interest');
      return;
    }

    const updatedSelectedProducts = exist
      ? selectedProducts.filter((k) => k !== id)
      : [...selectedProducts, id];
    setSelectedProducts(updatedSelectedProducts);
    updateUserPreferences(updatedSelectedProducts);
  };

  const handleCreateIndustry = async (inputValue) => {
    const val = inputValue.trim();
    const result = await apiCreateIndustry({ name: val });
    if (!result?.data?.id) return;
    const newIndustryList = addValueLabelToList([result.data]);
    setSelectedIndustry([...selectedIndustry, ...newIndustryList]);
  };

  const closeDialog = () => {
    setIndustryDialogOpen(false);
  };

  return (
    <div className="pt-4">
      <div className="interests-cont mb-45">
        <h2 className="mb-3">Your industry</h2>
        <div className="interest-section">
          <CreatableAsyncPaginate
            closeMenuOnSelect={false}
            components={animatedComponents}
            value={selectedIndustry}
            isMulti
            loadOptions={fetchIndustries}
            additional={{
              page: 1,
            }}
            // options={industriesOptions}
            onChange={handleIndustryChange}
            isClearable={false}
            onCreateOption={handleCreateIndustry}
            inputValue={industryInputValue}
            onInputChange={onIndustryInputValueChange}
            styles={{
              ...reactSelectStyles,
              indicatorsContainer: () => ({ display: 'none' }),
              control: (styles) => ({
                ...styles,
                ...reactSelectStyles?.control(styles),
                minHeight: '62px',
                padding: '10px 6px',
                textTransform: 'capitalize',
              }),
              multiValue: (styles, details) => ({
                ...styles,
                ...reactSelectStyles?.multiValue(styles, details),
                margin: '2px 4px',
              }),
              menu: (styles, data) => {
                return {
                  ...reactSelectStyles.menu(styles, data),
                  textTransform: 'capitalize',
                };
              },
            }}
          />
        </div>
      </div>
      <YourInterests
        selectedIndustryList={selectedIndustry}
        selectedProducts={selectedProducts}
        onProductClick={handleProductToggle}
        onDelete={handleProductToggle}
        title="What products are you interested in?"
        description="Please select one or more for personalized experience."
        industriesLoaded={industriesLoaded}
      />

      <IndustryDialog
        show={industryDialogOpen}
        onClose={closeDialog}
        onSave={handleIndustryChange}
      />
    </div>
  );
}

export const IndustryDialog = ({ show, onClose, onSave }) => {
  const [selected, setSelected] = useState([]);
  const { list, fetchNextPage, hasMore, loading, refetch } = useApiList({
    api: apiGetIndustries,
    mode: MODE.PARAMS,
  });

  const formattedList = useMemo(() => {
    return list.map((i) => ({ title: i.name, id: i.id }));
  }, [list]);

  const handleSave = () => {
    onClose();
    const selectedItems = list.filter(i => selected.includes(i.id));
    onSave(addValueLabelToList(selectedItems));
  }

  useEffect(() => {
    if (!show) {
      setSelected([])
    } else {
      refetch();
    }
  }, [show])

  return (
    <InfoDialogUI
      show={show}
      title="Select industry"
      description="Industry is required, please select at least one industry"
      onClose={onClose}>
      <ItemSelectListUI
        className="mb-4"
        items={formattedList}
        variant="checkbox"
        selected={selected}
        onChange={setSelected}
        loading={loading}
        hasData={true}
        fetchMoreData={fetchNextPage}
        hasMore={hasMore}
        itemClassName="lf-capitalize"
      />
      <Button
        color="warning"
        onClick={handleSave}
        fullWidth
        disabled={selected.length === 0}
        size="xlg"
      >
        Save
      </Button>
    </InfoDialogUI>
  );
};
