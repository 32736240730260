
import closeIcon from '../assets/images/curator/dropdown-delete-icon.svg';
import dropdownArrow from '../assets/images/curator/dropdown-icon.svg';

export const reactSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    minHeight: '48px',
    borderRadius: '8px',
    border: '1px solid var(--neutral20-color)',
    // padding: 12,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      padding: 16,
      fontSize: '1rem',
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? 'var(--neutral10-color)'
        : isFocused
        ? '#E5EEFE'
        : undefined,
      color: isDisabled ? '#ccc' : isSelected ? 'var(--neutral60-color)' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      height: 56,
      display: 'flex',
      alignItems: 'center',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? data.color : '#E5EEFE') : undefined,
      },

      ':first-child': {
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
      },
      ':last-child': {
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
      }
    };
  },
  menu: (styles) => ({
    ...styles,
    borderRadius: 8,
    border: '1px solid var(--black-color)',
    boxShadow: 'none',
    width: 'fit-content',
    overflow: 'hidden',
    minWidth: '100%',
    zIndex: 100,
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 100,
  }),
  // input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ 
    ...styles,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),

  indicatorsContainer: (styles) => ({
    ...styles,

    '& div:last-child': {
      background: `url(${dropdownArrow}) no-repeat center center`,
      width: 24,
      marginRight: 12,

      '& svg': {
        display: 'none',
      },
    },
  }),

  multiValue: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: 'var(--white-color)',
    borderRadius: 8,
    border: '1px solid var(--neutral80-color)',
    height: 32,
    padding: isDisabled ? '4px 8px 4px 8px' : '4px 26px 4px 8px',
    fontSize: '0.875rem',
    lineHeight: '22px',
    position: 'relative',

    '& div:first-child': {
      padding: 0,
    },

    '& [role="button"]': {
      width: 24,
      height: 24,
      right: 0,
      top: 3,
      background: `url(${closeIcon}) no-repeat center center`,
      position: 'absolute',
      ...(isDisabled && { display: 'none' }),

      '& svg': {
        display: 'none',
      },

      ':hover': {
        backgroundColor: 'transparent',
        transform: 'scale(1.1)',
        // color: 'inherit',
      },
    },
  }),
};


export const modifierSmall = {
  control: (styles) => ({
    ...reactSelectStyles.control(styles),
    minHeight: '40px',
    // padding: 12,
  }),
  option: (...args) => ({
    ...reactSelectStyles.option(...args),
    height: 40,
    padding: 8,
  })
}