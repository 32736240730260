import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetRoomTypeApi } from '../api/rooms';
import { projectTypeDropdownOptions } from '../constants/projectConstants';
import { useUserContext } from '../contexts/UserContext';
import { selectRoomTypes } from '../redux/slicers/appConfigSlicer';

export const useLoadRoomTypes = () => {
  const { currentUser } = useUserContext();
  const dispatch = useDispatch();
  const roomTypes = useSelector(selectRoomTypes);
  const calledOnceRef = useRef(false)

  useEffect(() => {
    if (roomTypes) return;
    if (calledOnceRef.current) return;
    if (!currentUser?.id) return;

    dispatch(GetRoomTypeApi());
    calledOnceRef.current = true;
  }, [currentUser?.id]);
};

export const useRoomTypeOptions = () => {
  // useLoadRoomTypes();
  const roomTypes = useSelector(selectRoomTypes);

  const roomTypeOptions = useMemo(() => {
    if (!roomTypes) return [];

    const roomTypesIds = Object.values(roomTypes);

    return projectTypeDropdownOptions.filter(option => {
      return roomTypesIds.includes(option.value)
    })

  }, [roomTypes])


  return {
    roomTypeOptions,
  };
}
