import Button from "../components/Button/Button";
import FileInput from "../components/FileInput/FileInput";
import { CameraIcon2 } from "../components/icons/CameraIcon2";
import Image from "./Image";

export const AvatarRow = ({ src, label, onFileSelect, loading, disableEdit }) => {
  return (
    <div className="input-border">
      <label className="detail-label">{label}</label>
      <figure className="d-flex align-items-center justify-content-between">
        <div className="so-avatar">
          <Image
            src={src}
            ratio={1}
            placeholderWhileLoading
            hideWithNoSrc
            rounded
          />
        </div>

        {!disableEdit && (
          <FileInput
            Component={Button}
            componentProps={{
              as: 'label',
            }}
            size="xlg"
            color="light"
            className="so-icon-button"
            onFileSelect={onFileSelect}
            accept="image/png, image/jpeg"
            iconBefore={<CameraIcon2 />}
            loading={loading}
          >
            Change picture
          </FileInput>
        )}
      </figure>
    </div>
  );
};
