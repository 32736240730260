import cx from 'classnames';
import { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { icons } from '../assets';
import Button from '../components/Button/Button';
import { UnionExclamation } from '../constants/assetConstants';
const { EditIcon } = icons;
export default function ViewEditItem({
  label,
  children,
  onUpdate,
  value,
  editing,
  setEditing,
  disableEdit,
  error,
  isSubmitting,
}) {
  const editButton = !disableEdit ? (
    <Button
      onClick={() => setEditing(true)}
      color="light"
      size="xlg"
      className="so-icon-button"
      iconBefore={<EditIcon />}>
      Edit
    </Button>
  ) : null;

  return (
    <div className={cx('input-border view-edit-container', editing && '--editing')}>
      <div>
        <label
          className="detail-label"
          style={error && editing ? { color: 'var(--error-color)' } : {}}>
          {error && editing && (
            <img
              src={UnionExclamation}
              style={{ verticalAlign: 'text-top', marginRight: '0.2rem' }}
            />
          )}
          {label}
        </label>
        {!editing && <div className="detail-info">{value || '-'}</div>}
      </div>

      {editing ? (
        <div className="d-flex gap-3 w-100">
          {children}
          <Button color="light" className="cancel-btn" onClick={() => setEditing(false)} size="xlg">
            Cancel{' '}
          </Button>
          <Button
            variant="primary"
            color="warning"
            size="xlg"
            onClick={() => {
              // setEditing(false);
              onUpdate();
            }}
            loading={isSubmitting}
            disabled={error || isSubmitting}>
            Save
          </Button>
        </div>
      ) : (
        editButton
      )}
    </div>
  );
}
