import cx from 'classnames';
import React, { useState, useEffect, useContext } from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
  Image,
  Spinner,
} from 'react-bootstrap';
import ResponsiveImage from '../Image';
import { apiUpdateUserDetails, apiGetUserDetails, apiChangePassword } from '../../helpers/api';
import makeAnimated from 'react-select/animated';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import { icons } from '../../assets';
import { Input } from '../../components/common/input';
import { navigate } from '../../helpers/projectHelper';
import { FORGET_ROUTE_SETTINGS } from '../../constants/routePathConstants';
import { UserInfo, ViewEditItem, OrgSection, Loader } from '..';
import _ from 'lodash';
import { setLocalStorage } from '../../helpers/jsHelper';
import NormalButton from '../../components/Button/Button';
import { CameraIcon2 } from '../../components/icons/CameraIcon2';
import FileInput from '../../components/FileInput/FileInput';
import { AvatarRow } from '../AvatarRow';
import { InfoIcon } from '../../components/icons/InfoIcon';
import { useUserContext } from '../../contexts/UserContext';
import { CloseIcon } from '../../components/icons/CloseIcon';
import { MAX_FIRST_NAME_LENGTH, MAX_LAST_NAME_LENGTH } from '../../constants/validationConstants';

const { ChangeProfileIcon, EyeIcon, ProfileIcon } = icons;

export default function MyDetails(props) {
  const userCtx = useUserContext();
  const animatedComponents = makeAnimated();
  const [userDetails, setUserDetails] = useState(null);
  const [savePassword, setSavePassword] = useState(false);
  const [data, setData] = useState();
  const [profileImg, setProfileImg] = useState();
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [passwordShown3, setPasswordShown3] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [confirmErrorText, setConfirmErrorText] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPassError, setCurrentPasswordError] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('images/profilepic.svg');
  const [countryMessage, setCountryMessage] = useState('');
  const [cityMessage, setCityMessage] = useState('');
  const [contactNumberMessage, setContactNumberMessage] = useState('');
  const [professionMessage, setProfessionMessage] = useState('');
  const [industryMessage, setIndustryMessage] = useState('');
  const [platformMessage, setPlatformMessage] = useState('');
  const [userData, setUserData] = useState(userDetails);
  const [saving, setSaving] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [editingDesignation, setEditingDesignation] = useState(false);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  useEffect(() => {
    getUserDetails();
  }, [userCtx.currentUser.organization]);

  useEffect(() => {
    if (userDetails?.profile_image) {
      setProfileImg(userDetails?.profile_image);
    }
  }, [userDetails]);

  let formData = { ...data };

  const handleShow = () => setShow(true);

  function getUserDetails(callback) {
    apiGetUserDetails()
      .then((response) => {
        setUserDetails(response);
        const newUser = { ...userCtx.currentUser };
        newUser.organization_detail.name = response.organization_details.name;
        userCtx.setCurrentUser(newUser);
        setLocalStorage('current_user', newUser);
      })
      .finally(callback);
  }

  function changeUserDetails(data) {
    if (data) {
      let formData = new FormData();

      let userData = Object.entries(data);

      userData.forEach((item) => {
        let key = item[0];
        let value = item[1];

        if (key === 'country') {
          value = value?.label;
        } else if (key === 'state') {
          value = value?.label;
        } else if (key === 'profession') {
          value = value?.value;
        } else if (key === 'city') {
          value = value?.label;
        } else if (key === 'usecase') {
          value = value;
        } else if (key === 'mobile_number') {
          value = value;
        } else {
          key = null;
          value = null;
        }

        if (userDetails) {
          let currentUserData = Object.entries(userDetails);

          currentUserData.forEach((property) => {
            let userKey = property[0];
            let userValue = property[1];

            if (userKey === key && userValue !== value) {
              if (value) {
                if (key === 'usecase') {
                  value.forEach((x) => formData.append('usecase', x?.value));
                } else {
                  formData.append(key, value);
                }
              }
            }
          });
        }
      });
      apiUpdateUserDetails(formData).then((response) => {
        setUserData(response);
      });
    }
  }

  const handleChange = ({ name, value }) => {
    formData[name] = value;
    setData(formData);
  };

  const handledesignationChange = ({ name, value }) => {
    formData[name] = value;
    setData(formData);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleUpdate = () => {
    setIsSubmitting(true);

    var form_data = new FormData();
    for (var key in formData) {
      form_data.append(key, formData[key]);
    }
    apiUpdateUserDetails(form_data)
      .then((response) => {
        console.log(response);
        setUserDetails(response);

        // Close any editing instance opened for name and/or designation
        setEditingName(false);
        setEditingDesignation(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePpChange = (file) => {
    setSaving(true);
    let formData = new FormData();
    formData.append('profile_image', file);
    apiUpdateUserDetails(formData)
      .then((response) => {
        if (response && response.profile_image) {
          setProfileImg(response.profile_image);
          userCtx.changeProfilePic?.(response.profile_image);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length < 8) {
      setNewPasswordError(true);
      setErrorText('Password cannot be less than 8 characters');
    } else {
      setNewPasswordError(false);
    }
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value.length < 8) {
      setConfirmPasswordError(true);
      setConfirmErrorText('Confirm password cannot be less than 8 characters');
    } else setConfirmPasswordError(false);
  };

  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  const togglePassword2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const togglePassword3 = () => {
    setPasswordShown3(!passwordShown3);
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    navigate(FORGET_ROUTE_SETTINGS);
  };

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };

  const submitPassword = () => {
    if (newPassword !== confirmPassword) {
      setNewPasswordError(true);
      setErrorText('New password and confirm password do not match');
    } else if (currentPassword === newPassword) {
      setNewPasswordError(true);
      setErrorText('New Password and current password cannot be same');
    } else if (currentPassword.length === 0) {
      setCurrentPasswordError(true);
    } else {
      let formData = new FormData();
      formData.append('old_password', currentPassword);
      formData.append('new_password', newPassword);
      setNewPasswordError(false);
      setShow(false);

      apiChangePassword(formData).then((response) => {
        setSavePassword(true);
      });

      setTimeout(function () {
        setSavePassword(false);
      }, 3000);
    }
  };

  const handeFirstNameChange = ({ name, value }) => {
    if (!value.trim()) {
      setFirstNameError('Please provide first name')
    } else if (value.length > MAX_FIRST_NAME_LENGTH) {
      setFirstNameError(`First name should be less then ${MAX_FIRST_NAME_LENGTH} characters`);
    } else {
      setFirstNameError('')
    }

    handleChange({ name, value })
  }

  const handleLastNameChange = ({ name, value }) => {
    if (!value.trim()) {
      setLastNameError('Please provide last name')
    } else if (value.length > MAX_LAST_NAME_LENGTH) {
      setLastNameError(`Last name should be less then ${MAX_LAST_NAME_LENGTH} characters`);
    } else {
      setLastNameError('')
    }

    handleChange({ name, value })
  }

  const nameError = firstNameError || lastNameError

  return (
    <>
      <div className="d-flex my-details-grid">
        <div>
          <div className="left-section details-page">
            <AvatarRow
              src={profileImg}
              label="Profile Picture"
              onFileSelect={handlePpChange}
              loading={saving}
            />

            <ViewEditItem
              label={nameError || 'Name'}
              onUpdate={handleUpdate}
              value={userDetails ? `${userDetails.first_name} ${userDetails.last_name}` : ''}
              error={nameError}
              editing={editingName}
              setEditing={setEditingName}
              isSubmitting={isSubmitting}>
              <div className="w-100">
                <Input
                  className={cx('w-100 my-details-input mb-2', firstNameError && 'input-error')}
                  name="first_name"
                  onChange={handeFirstNameChange}
                  maxLength={MAX_FIRST_NAME_LENGTH}
                  required
                  defaultData={userDetails}
                  placeholder="First Name"
                />
                <Input
                  className={cx('w-100 my-details-input', lastNameError && 'input-error')}
                  name="last_name"
                  maxLength={MAX_LAST_NAME_LENGTH}
                  onChange={handleLastNameChange}
                  required
                  defaultData={userDetails}
                  placeholder="Last Name"
                  onKeyDown={(e) => {
                    if (e.key !== 'Enter') return;
                    handleUpdate();
                  }}
                />
              </div>
            </ViewEditItem>
            <div className="input-border">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <label className="detail-label">Email</label>
                  <span className="detail-info">{userDetails?.email}</span>
                </div>
                <div className="contact-support">
                  <OverlayTrigger
                    placement="bottom"
                    delay={150}
                    overlay={<Tooltip id="button-tooltip-2">To change your email</Tooltip>}>
                    {({ ref, ...triggerHandler }) => (
                      <div
                        ref={ref}
                        {...triggerHandler}
                        className="d-flex align-items-center gap-2 contact-support-text">
                        <InfoIcon style={{ color: '#292D32' }} />
                        Contact Support
                      </div>
                    )}
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className="input-border">
              <span className="detail-links" onClick={handleShow}>
                Edit password
              </span>
              <Modal show={show} onHide={handleClose} animation={false} className="edit-pw-modal">
                <Modal.Header className="commonPopupHeader justify-content-end pb-0">
                  <Button onClick={handleClose} className="modalCloseButton">
                    <CloseIcon />
                  </Button>
                </Modal.Header>

                <Modal.Body className="px-5">
                  <div className="text-center mb-4">
                    <h2 className="fw-bold">Edit Password</h2>
                  </div>
                  <Form className="settings-modal">
                    <Form.Group className="mb-4 password-field" controlId="formBasicEmail">
                      <Form.Label>
                        <small className="text-muted">Current Password</small>
                      </Form.Label>
                      <Form.Control
                        className="password-input"
                        type={passwordShown1 ? 'text' : 'password'}
                        placeholder="Type here your current password"
                        maxLength={20}
                        onChange={handleCurrentPassword}
                        style={{
                          border: currentPassError ? 'red 2px solid' : '',
                        }}
                      />
                      {passwordShown1 ? (
                        <RiEyeLine className="eye-icon" onClick={togglePassword1} />
                      ) : (
                        <RiEyeOffLine className="eye-icon" onClick={togglePassword1} />
                      )}
                    </Form.Group>

                    <div className="text-primary text-end mb-4 cursorPointer">
                      <span role="button" onClick={handleForgotPasswordClick}>
                        I forgot my password
                      </span>
                    </div>
                    <Form.Group
                      className="mb-3 new-password password-field"
                      controlId="formBasicPassword">
                      <Form.Label style={{ color: newPasswordError ? 'red' : '' }}>
                        <small className="text-muted">
                          {newPasswordError ? errorText : 'New Password'}
                        </small>
                      </Form.Label>
                      <Form.Control
                        className="password-input"
                        placeholder="Type here your new password"
                        type={passwordShown2 ? 'text' : 'password'}
                        onBlur={handleNewPassword}
                        style={{
                          border: newPasswordError ? 'red 2px solid' : '',
                        }}
                        maxLength={20}
                      />
                      {passwordShown2 ? (
                        <RiEyeLine className="eye-icon" onClick={togglePassword2} />
                      ) : (
                        <RiEyeOffLine className="eye-icon" onClick={togglePassword2} />
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3 confirm-password password-field"
                      controlId="formBasicPassword">
                      <Form.Label style={{ color: confirmPasswordError ? 'red' : '' }}>
                        <small className="text-muted">
                          {confirmPasswordError ? confirmErrorText : 'Confirm New Password'}
                        </small>
                      </Form.Label>
                      <Form.Control
                        className="password-input"
                        type={passwordShown3 ? 'text' : 'password'}
                        placeholder="Repeat your new password"
                        onBlur={handleConfirmPassword}
                        style={{
                          border: confirmPasswordError ? ' red 2px solid' : '',
                        }}
                        maxLength={20}
                      />
                      {passwordShown3 ? (
                        <RiEyeLine className="eye-icon" onClick={togglePassword3} />
                      ) : (
                        <RiEyeOffLine className="eye-icon" onClick={togglePassword3} />
                      )}
                    </Form.Group>
                  </Form>
                  <div className="d-grid py-3 font16">
                    <Button
                      className="submit-btn"
                      variant="warning"
                      onClick={submitPassword}
                      disabled={newPasswordError || confirmPasswordError}>
                      <span className="font16">Save</span>
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <ViewEditItem
              label="Designation"
              onUpdate={handleUpdate}
              value={userDetails ? userDetails.designation : ''}
              editing={editingDesignation}
              setEditing={setEditingDesignation}
              isSubmitting={isSubmitting}>
              <Input
                label={false}
                className="w-100 my-details-input"
                name="designation"
                placeholder="Designation"
                onChange={handledesignationChange}
                required
                defaultData={userDetails}
                onKeyDown={(e) => {
                  if (e.key !== 'Enter') return;
                  handleUpdate();
                }}
              />
            </ViewEditItem>
          </div>
        </div>
        <OrgSection userDetails={userDetails} getUserDetails={getUserDetails} />
      </div>

      <div className="d-flex my-details-grid">
        <div>
          <UserInfo
            // hideBanner,
            countryMessage={countryMessage}
            setCountryMessage={setCountryMessage}
            cityMessage={cityMessage}
            setCityMessage={setCityMessage}
            contactNumberMessage={contactNumberMessage}
            setContactNumberMessage={setContactNumberMessage}
            professionMessage={professionMessage}
            setProfessionMessage={setProfessionMessage}
            industryMessage={industryMessage}
            setIndustryMessage={setIndustryMessage}
            platformMessage={platformMessage}
            setPlatformMessage={setPlatformMessage}
            onUserPreferenceDataChange={changeUserDetails}
            inSettings={true}
          />
        </div>
      </div>
      {/* {showSavePassword ? <SavedDetails /> : ""} */}
    </>
  );
}
