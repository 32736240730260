import cx from 'classnames';
import { Form, InputGroup } from "react-bootstrap";
import { useCopyToClipboard } from "react-use";
import Button from "../../components/Button/Button";
import { LinkIcon } from "../../components/icons/LinkIcon";
import { successToast } from "../../helpers/toastHelper";
import styles from './ShareLinkInput.module.scss';

export const ShareLinkInput = ({ link, className }) => {
  const [state, copy] = useCopyToClipboard();

  const handleCopy = () => {
    copy(link);
    successToast('Link Copied', { toastId: 'LINK_COPIED' });
  };

  return (
    <>
      <InputGroup onClick={handleCopy} className={cx(className, styles.container)}>
        <InputGroup.Text className={styles.inputGroupText}>
          <LinkIcon />
        </InputGroup.Text>
        <Form.Control
          value={link}
          className="form-control form-control-md placeholder-active input-box"
          readOnly
        />
        <button type="button" className={styles.button}>
          Copy Link
        </button>
      </InputGroup>

      {state.error && <p>Unable to copy value: {state.error.message}</p>}
    </>
  );
};
