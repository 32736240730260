import React from 'react';
import cx from 'classnames';
import {
  SILO_TEMPLATE_DICON,
  ROOM_TEMPLATE_DICON,
  AUGMENTED_REALITY_DICON,
  VIDEO_DICON,
  VIGNETTE_DICON,
  WEB_VIEWER_DICON,
  CONFIGURATOR_DICON,
  LIFESTYLE_TEMPLATE_DICON,
} from '../constants/assetConstants';
import {
  SILO_TEMPLATE_BG,
  ROOM_TEMPLATE_BG,
  AUGMENTED_REALITY_BG,
  VIDEO_BG,
  VIGNETTE_BG,
  WEB_VIEWER_BG,
  CONFIGURATOR_BG,
  LIFESTYLE_TEMPLATE_BG,
} from '../constants/assetConstants';
// import '../assets/scss/_designTemplates.scss';
import { Link } from 'react-router-dom';
import { ROOM_OPTIONS } from '../constants/filters';
import { propsReadApi } from '../api/curator';
import { NEW_DESIGN_ROUTE } from '../constants/routePathConstants';

export default function DesignTemplates(props) {
  // const path = '/rooms';

  const Content = {
    ['Room Template']: "",
    Lifestyle: "Images in life-like environments",
    Silo: "Images against a white background",
    Vignette: "Product-focused imagery",
    ['360 Web Viewer']: "",
    Video: "",
    Configurator: "",
    ['Augmented Reality']: "",
  };

  const Background = {
    ['Room Template']: ROOM_TEMPLATE_BG,
    Lifestyle: LIFESTYLE_TEMPLATE_BG,
    Silo: SILO_TEMPLATE_BG,
    Vignette: VIGNETTE_BG,
    ['360 Web Viewer']: WEB_VIEWER_BG,
    Video: VIDEO_BG,
    Configurator: CONFIGURATOR_BG,
    ['Augmented Reality']: AUGMENTED_REALITY_BG,
  };

  const siloTemplateBG = {
    backgroundImage: `url(${SILO_TEMPLATE_BG})`,
  };
  const roomTemplateBG = {
    backgroundImage: `url(${ROOM_TEMPLATE_BG})`,
  };
  const augmentedRealityBG = {
    backgroundImage: `url(${AUGMENTED_REALITY_BG})`,
  };
  const lifestyleTemplateBG = {
    backgroundImage: `url(${LIFESTYLE_TEMPLATE_BG})`,
  };
  const videoBG = {
    backgroundImage: `url(${VIDEO_BG})`,
  };
  const vignetteBG = {
    backgroundImage: `url(${VIGNETTE_BG})`,
  };
  const webViewerBG = {
    backgroundImage: `url(${WEB_VIEWER_BG})`,
  };
  const configuratorBG = {
    backgroundImage: `url(${CONFIGURATOR_BG})`,
  };

  const Icons = {
    ['Room Template']: ROOM_TEMPLATE_DICON,
    Lifestyle: LIFESTYLE_TEMPLATE_DICON,
    Silo: SILO_TEMPLATE_DICON,
    Vignette: VIGNETTE_DICON,
    ['360 Web Viewer']: WEB_VIEWER_DICON,
    Video: VIDEO_DICON,
    Configurator: CONFIGURATOR_DICON,
    ['Augmented Reality']: AUGMENTED_REALITY_DICON,
  };

  return (
    <div
      className={cx(
        {
          center: props.center,
        },
        'designTemplates 2'
      )}>
      {ROOM_OPTIONS?.length > 0 ? (
        ROOM_OPTIONS?.map(({ label, value }, index) => {

          return (
            <Link to={`/newdesigns/${value}`} key={`templateLink${label}`}>
              <div
                className={`template silo`}
                style={{ backgroundImage: `url(${Background[label]})` }}
                key={index + 1}>
                <img src={Icons[label]} />
                <h5>{label}</h5>
                <p>{Content[label]}</p>
              </div>
            </Link>
          )
        })
      ) : (
        <>
          <div className="template lifestyle 3" style={lifestyleTemplateBG}>
            <img src={SILO_TEMPLATE_DICON} />
            <h5>Lifestyle</h5>
            <p>Images in life-like environments</p>
          </div>
          <div className="template silo" style={siloTemplateBG}>
            <img src={SILO_TEMPLATE_DICON} />
            <h5>Silo</h5>
            <p>Images against a white background</p>
          </div>
          <div className="template vignette" style={vignetteBG}>
            <img src={VIGNETTE_DICON} />
            <h5>Vignette</h5>
            <p>Product-focused imagery</p>
          </div>
          <div className="template droom" style={roomTemplateBG}>
            <img src={ROOM_TEMPLATE_DICON} />
            <h5>Room Template</h5>
            <p>Explaination goes here</p>
          </div>
          <div className="template augmented-reality" style={augmentedRealityBG}>
            <img src={AUGMENTED_REALITY_DICON} />
            <h5>Augmented Reality</h5>
            <p>Explaination goes here</p>
          </div>
          <div className="template video" style={videoBG}>
            <img src={VIDEO_DICON} />
            <h5>Video</h5>
            <p>Explaination goes here</p>
          </div>
          <div className="template web-viewer" style={webViewerBG}>
            <img src={WEB_VIEWER_DICON} />
            <h5>360 Web Viewer</h5>
            <p>Explaination goes here</p>
          </div>
          <div className="template configurator" style={configuratorBG}>
            <img src={CONFIGURATOR_DICON} />
            <h5>Configurator</h5>
            <p>Explaination goes here</p>
          </div>
        </>
      )}
    </div>
  );
}
