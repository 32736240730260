// this component is handling the user card for login user
import cx from 'classnames';
import { useState, useEffect, memo } from 'react';
import { Card, Nav, Image } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { icons } from '../assets';
import { ADD_CREDITS_ROUTE } from '../config/constant/routePathConstant';
import { USER_MENU } from '../constants/menu';
import { PERMISSION } from '../constants/permissionConstants';
import { useUserContext } from '../contexts/UserContext';
import { usePermissionsHandler } from '../hooks/usePermissions';

const { UserIcon } = icons;

const UserCard = memo(({ cardFlag, setUserCardVisiblity, handleClosePopUp }) => {
  const location = useLocation();
  const userCtx = useUserContext();
  const currentUser = userCtx?.currentUser?.user;
  const { handlePermissionEvent } = usePermissionsHandler();

  // console.log("currentUser in usercard: ", currentUser)

  const navigate = useNavigate();
  const [themeMode, setThemeMode] = useState(false);

  const handleUserCard = (e) => {
    e.stopPropagation();
    handleClosePopUp();
    setUserCardVisiblity(!cardFlag);
  };

  const onToggle = (e) => {
    setThemeMode(!themeMode);
  };

  function handleLink(path) {
    if (path === ADD_CREDITS_ROUTE) {
      handlePermissionEvent(PERMISSION.PURCHASE_CREDIT, () => navigateAndCloseMenu(path))();
    } else {
      navigateAndCloseMenu(path)
    }
  }

  function navigateAndCloseMenu(path) {
    navigate(path);
    setUserCardVisiblity(false);
  }

  function handleLogout() {
    userCtx.logout();
    setUserCardVisiblity(false);
  }

  const handleHidePopover = () => {
    setUserCardVisiblity(false);
  };

  useEffect(() => {
    if (cardFlag) {
      window.addEventListener('click', handleHidePopover);
    } 

    return () => {
      window.removeEventListener('click', handleHidePopover);
    };
  }, [cardFlag]);

  return (
    <div className="user-card">
      <button className="user-card-btn" onClick={handleUserCard}>
        {currentUser && currentUser.profile_image ? (
          <Image src={currentUser.profile_image} />
        ) : (
          <UserIcon />
        )}
      </button>
      <Card className={cardFlag ? '' : 'hide'}>
        <Card.Body>
          <Nav as="ul" className="menu card-arrow">
            {USER_MENU.map((item, i) => {
              const Icon = item?.icon;

              return (
                <Nav.Item
                  role="button"
                  as="li"
                  key={item.path}
                  className={cx({
                    active: matchPath({ path: item.path }, location?.pathname) !== null,
                  })}>
                  <a
                    className="nav-link"
                    onClick={() => {
                      console.log('item', item);
                      item.name === 'Logout' ? handleLogout() : handleLink(item.path);
                    }}>
                    {/* <Image src={item.icon} /> */}
                    <Icon />
                    <span>{item.name}</span>
                  </a>
                </Nav.Item>
              );
            })}
          </Nav>
        </Card.Body>
        <Card.Body className="theme-mode">
          <Nav as="ul" className="menu">
            <Nav.Item as="li" className="theme-mode-switcher" onClick={(e) => e.stopPropagation()}>
              <span className="theme-label">{themeMode ? 'Dark' : 'Light'} Mode </span>
              <Toggle
                onClick={onToggle}
                on={<span className="text">ON</span>}
                off={<span className="text">OFF</span>}
                width="40px"
                height="20px"
                size="xs"
                offstyle="danger"
                active={themeMode}
              />
            </Nav.Item>
          </Nav>
        </Card.Body>
      </Card>
    </div>
  );
});

export default UserCard;
