import download from 'downloadjs';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { icons } from '../assets';
import {
  ActionFilters,
  AddToCollections,
  BigButton,
  DeactivatePopup,
  Download,
  EditButton,
  GlobalFilter,
  SharePopup,
  ThumbnailContainer,
  TopButtonArea,
} from '../common';
import AddToCollectionPopup from '../common/AddToCollectionPopup';
import { ThumbnailContainerV2 } from '../common/ThumbnailContainerV2';
import { UploadImagesDialog } from '../common/UploadImagesDialog/UploadImagesDialog';
import NormalButton from '../components/Button/Button';
// import CollectionIcon from '../components/icons/CollectionIcon';
import InfiniteScroll from '../components/InfiniteScroll/InfiniteScroll';
import {
  ARCHIVE_ICON,
  COLLECTION_ICON,
  DOWNLOAD_ICON,
  EDIT_ICON,
  SHARE_ICON,
} from '../constants/assetConstants';
import { MODEL_TYPES, MODULE_TYPE_OPTIONS } from '../constants/constants';
import { RENDER_DETAILS } from '../constants/routePathConstants';
import { useUserContext } from '../contexts/UserContext';
import { apiArchiveRenders, apiGetDownloadRendersImages } from '../helpers/api';
import { useScreenSettings } from '../helpers/configureHelper';
import { setTitle } from '../helpers/jsHelper';
import { CommonGrid, TopContainer } from '../layouts';
import { useRendersPreviews } from '../pages/admin/renders-previews/useRendersPreviews';
import { paginatedRenderPreviewsActions } from '../redux/slicers/admin/paginatedRenderPreviewsSlicer';
import { myRenderPreviewActions } from '../redux/slicers/render_preview/myRenderPreviewSlice';

const { FileUploadIcon, UploadIcon, CollectionIcon, DownloadIcon } = icons;

let title = 'Renders & Previews';

export default function RendersPreviews({
  productId = '',
  cardType = 'renders-previews',
  useGlobalSearch,
  noDataText,
  defaultFilterBy,
  cardProps,
}) {
  const dispatch = useDispatch();
  const { currentUser } = useUserContext();
  const currentMemberId = currentUser.id;

  const [showUploadDialog, toggleShowUploadDialog] = useToggle(false);
  const pageSettings = useScreenSettings(cardType);
  const { sortOptions, filterOptions, defaultSortOption, showFavoriteIcon, showTeamMemberFilter } =
    pageSettings;

  useEffect(() => {
    setTitle(title);
  }, []);

  const requestParams = useMemo(
    () => ({
      created_by: currentMemberId,
      ...(productId && { product: productId }),
    }),
    [currentMemberId, productId]
  );

  const {
    list,
    loading,
    fetchMoreData,
    hasMore,
    total,
    hasData,

    selected,
    anySelected,
    allSelected,
    selectedList,
    sortBy,
    filterBy,
    onSortChange,
    onFilterByChange,
    toggleAll,
    toggleItem,
    resetSelection,
    addToCollection,
    refetchList,
  } = useRendersPreviews({
    requestParams,
    defaultFilterBy,
    defaultSort: defaultSortOption,
    useGlobalSearch,
    modelType: MODEL_TYPES.PROJECTRENDER,
  });

  const [isDownloading, setIsDownloading] = useState(false)
  const handleUploadSuccess = (renderData) => {
    if (
      !filterBy ||
      filterBy.value === MODULE_TYPE_OPTIONS.RENDERS ||
      filterBy.value === MODULE_TYPE_OPTIONS.UPLOADS
    ) {
      dispatch(myRenderPreviewActions.addSuccess(renderData));
    }
    toggleShowUploadDialog();
  };

  async function handleDownloadClick() {
    if (selectedList.length < 1) return;
    setIsDownloading(true);
    const query = `ids=${selected.join(',')}`;
    const res = await apiGetDownloadRendersImages(query).finally(() => setIsDownloading(false));
    download(new Blob([res]), 'render.zip');
  }

  return (
    <CommonGrid
      noDataText={noDataText}
      topContainer={
        <TopContainer
          title={
            !useGlobalSearch && (
              <>Renders &amp; Previews {total >= 0 ? <small>({total})</small> : null}</>
            )
          }
          item={cardType}
          hasData={hasData}
          buttonArea={
            !useGlobalSearch && (
              <TopButtonArea
                hasData={hasData}
                item={cardType}
                addNewButtonArea={
                  <BigButton
                    className="lf-btn-1"
                    buttonIcon={UploadIcon}
                    onClick={() => toggleShowUploadDialog()}
                    buttonTitle="Upload"
                  />
                }
                topButtonList={
                  !useGlobalSearch && [
                    {
                      element: DeactivatePopup,
                      title: 'Are you sure you want to archive this render?',
                      subtitle: 'You can view archived renders through the filter panel.',
                      bigButton: true,
                      bigBtnClass: 'lf-btn-3',
                      isPlural: true,
                      item: cardType,
                      onSuccess: refetchList,
                      actionWord: 'archive',
                      deleteAction: apiArchiveRenders,
                    },
                    {
                      element: BigButton,
                      bigBtnClass: 'lf-btn-2',
                      iconBefore:  <CollectionIcon />,
                      buttonTitle: 'Add To Collection',
                      onClick: addToCollection,
                    },
                    {
                      element: SharePopup,
                      bigButton: true,
                      bigBtnClass: 'lf-btn-2',
                      selectedList,
                      modelType: 6,
                    },
                    {
                      element: BigButton,
                      bigBtnClass: 'lf-btn-1',
                      isLoading: isDownloading,
                      buttonTitle: 'Download',
                      iconBefore: <DownloadIcon />,
                      onClick: handleDownloadClick,
                    },
                  ]
                }
                anySelected={anySelected}
                selectedList={selectedList}
              />
            )
          }
        />
      }
      item={cardType}
      hasData={list.length > 0 || loading}
      list={list}
      filterArea={
        !useGlobalSearch && (
          <>
            <ActionFilters
              filterProps={{
                value: filterBy,
                data: filterOptions,
                onChange: onFilterByChange,
              }}
              sortProps={{
                value: sortBy,
                data: sortOptions,
                onChange: onSortChange,
              }}
              selectedProps={{
                allSelected: allSelected,
                onSelectAll: () => toggleAll(),
              }}
              showTeamMemberFilter={showTeamMemberFilter}
            />
            <GlobalFilter />
          </>
        )
      }>
      <InfiniteScroll
        dataLength={list.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
        hasChildren={hasData}
        scrollableTarget="infiniteScroll">
        <ThumbnailContainerV2
          items={list}
          modelType={MODEL_TYPES.PROJECTRENDER}
          selected={selected}
          cardProps={{
            fullWidth: true,
            onSelect: toggleItem,
            ...cardProps,
          }}
        />
        {hasMore && <div id="whiteShadow" />}
      </InfiniteScroll>

      <UploadImagesDialog
        show={showUploadDialog}
        onClose={() => toggleShowUploadDialog()}
        onSucces={handleUploadSuccess}
      />

      <AddToCollectionPopup onSuccess={refetchList} />
    </CommonGrid>
  );
}
