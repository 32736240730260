
import { useSimpleDataList } from "./useDataList";
import { transactionHistoryEmailListActions, transactionHistoryEmailListSelector } from "../redux/slicers/transactionHistoryEmailListSlicer";
import { getTransactionListFilterEmailApi } from "../api/transactionHistoryApi";

export const useTransactionsHistoryEmailList = ({ requestParams } = {}) => {
  const data = useSimpleDataList({
    selector: transactionHistoryEmailListSelector,
    resetAction: transactionHistoryEmailListActions.resetList,
    actions: transactionHistoryEmailListActions,
    getAction: getTransactionListFilterEmailApi,
    requestParams,
  });

  return data;
};
