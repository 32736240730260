import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  getProjectCameraAnglesList: [],
  saveToCamera: {},
  addCameraLoading: false,
  expandedCameraId: null,
  importPresetsLoading: false,
  undoRedoDeleted: {},
  importPresets: {
    active: false,
    invalid: [],
    valid: [],
  },
};

const projectCameraAngle = createSlice({
  name: 'projectCameraAngle',
  initialState,
  reducers: {
    setProjectCameraAnglesList: (state, { payload }) => {
      state.getProjectCameraAnglesList = payload;
    },
    setAddCameraLoading: (state, { payload }) => {
      state.addCameraLoading = payload;
    },
    addCameraSuccess: (state, { payload }) => {
      state.getProjectCameraAnglesList = [payload, ...state.getProjectCameraAnglesList];
      state.addCameraLoading = false;
    },
    updateCameraSuccess: (state, { payload }) => {
      state.getProjectCameraAnglesList = state.getProjectCameraAnglesList.map((camera) => {
        return camera.id === payload.id ? payload : camera;
      });
    },
    setSaveToCamera: (state, { payload }) => {
      state.saveToCamera = payload;
    },
    setImportPresetsLoading: (state, { payload }) => {
      state.importPresetsLoading = payload;
    },
    resetImportPressets: (state) => {
      state.importPresets = initialState.importPresets;
    },
    setImportPressets: (state, { payload }) => {
      const { invalid, valid } = payload;
      state.importPresets = {
        invalid,
        valid,
        active: true,
      };
    },
    makePresetValid: (state, { payload }) => {
      const { cameraName, id } = payload;
      const preset = state.importPresets.invalid.find((p) => p.id === id);
      state.importPresets = {
        ...state.importPresets,
        invalid: state.importPresets.invalid.filter((p) => p.id !== id),
        valid: [...state.importPresets.valid, { ...preset, cameraName }],
      };
    },
    importCameraPresetsSuccess: (state, { payload }) => {
      state.getProjectCameraAnglesList = [...payload, ...state.getProjectCameraAnglesList];

      state.importPresetsLoading = false;
    },
    updateCamera: (state, { payload }) => {
      state.getProjectCameraAnglesList = state.getProjectCameraAnglesList.map((camera) => {
        return camera.id === payload.id ? { ...camera, ...payload } : camera;
      });
    },
    setExpandedCameraId: (state, { payload }) => {
      state.expandedCameraId = payload;
    },
    undoRedoDeleteCamera: (state, { payload }) => {
      state.undoRedoDeleted[payload.id] = true;
    },
    undoRedoDeleteRevertCamera: (state, { payload }) => {
      delete state.undoRedoDeleted[payload.id];
    }
  },
});

export const {
  getProjectCameraAnglesList,
  setProjectCameraAnglesList,
  saveToCamera,
  setSaveToCamera,
  importCameraPresetsSuccess,
  setAddCameraLoading,
  addCameraSuccess,
  updateCameraSuccess,
  updateCamera,
  setExpandedCameraId,
  setImportPresetsLoading,
  setImportPressets,
  makePresetValid,
  resetImportPressets,
  undoRedoDeleteCamera,
  undoRedoDeleteRevertCamera,
} = projectCameraAngle.actions;
export const projectCameraAngleSelector = (state) => state.projectCameraAngle;
export const selectCamera = (state, id) => selectProjectCameraList(state).find(c => c.id === state.projectCameraAngle.expandedCameraId)
export const projectCameraAngleReducer = projectCameraAngle.reducer;
export default projectCameraAngleReducer;

export const selectAddCameraLoading = (state) => state.projectCameraAngle.addCameraLoading;
export const selectExpandedCameraId = (state) => state.projectCameraAngle.expandedCameraId;
export const selectIsCameraEditing = (state) => Boolean(state.projectCameraAngle.expandedCameraId);
export const selectProjectCameraList = (state) =>
  state.projectCameraAngle.getProjectCameraAnglesList;
export const selectImportPresestsLoading = (state) => state.projectCameraAngle.importPresetsLoading;
export const selectImportPresets = (state) => state.projectCameraAngle.importPresets;
export const selectPresetsConflictsFormVisible = (state) => {
  const { invalid, active } = selectImportPresets(state)
  return active && invalid.length !== 0
}
export const selectImportPresetsActive = (state) => state.projectCameraAngle.importPresets.active;
