import { Button, Spinner } from 'react-bootstrap';
import _ from 'lodash';
import cx from 'classnames';

export default function NoDataFound({ title }) {
  return (
    <div
      style={{
        height: '285px',
      }}>
      <h6>No {title} are available.</h6>
    </div>
  );
}
