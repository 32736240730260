import { useDispatch, useSelector } from 'react-redux';
import {
  apiGetWebExperienceProductDetails,
  apiUpdateWebExperienceProductDetails,
} from '../../helpers/api';
import {
  descriptionSchema,
  dimensionsSchema,
  getCategoryFields,
  getDescriptionFields,
  getDimensionsFields,
  getNameFields,
  getPriceFields,
  getSkuFields,
  nameSchema,
  priceSchema,
  skuSchema,
} from './fieldsConfig';
import { useFilterDropdownOptions } from '../../hooks/useFilterDropdownOptions';
import { PRODUCT_FILTER_TYPE } from '../../constants/constants';
import { EditSection } from '../EditSection/EditSection';
import { useEffect } from 'react';
import { getFiltersApi } from '../../api/commonApi';
import { EditPopupUI } from '../EditPopupUI/EditPopupUI';
import { useApi } from '../../hooks/useApi';
import {
  model3dDetailsActions,
  selectModel3dDetailsData,
} from '../../redux/slicers/model3dDetailsSlicer';

export const My3dModelDetailsPopupController = ({ onUpdateSuccess }) => {
  const dispatch = useDispatch();
  const product = useSelector(selectModel3dDetailsData);
  const thumbnail = product?.thumbnail
  const productId = product?.id
  const show = Boolean(productId);

  const {
    data,
    loading,
    update: updateProductDetails,
  } = useApi({
    api: apiGetWebExperienceProductDetails,
    id: productId,
    skip: !show,
  });

  const onClose = () => {
    dispatch(model3dDetailsActions.close());
  };

  const handleUpdateSuccess = (data) => {
    updateProductDetails(data);
    onUpdateSuccess(productId, data);
  };

  if (!show) return null;

  return (
    <My3dModelDetailsPopup
      productId={productId}
      data={data}
      show={show}
      onClose={onClose}
      loading={loading}
      thumbnail={thumbnail}
      onUpdateSuccess={handleUpdateSuccess}
    />
  );
};

export const My3dModelDetailsPopup = ({
  productId,
  data,
  show,
  onClose,
  loading,
  thumbnail,
  onUpdateSuccess,
}) => {
  const dispatch = useDispatch();
  const { name, price, sku_id, description, dimensions } = data || {};

  useEffect(() => {
    dispatch(getFiltersApi());
  }, [dispatch]);

  const productFilters = useFilterDropdownOptions({
    filterType: PRODUCT_FILTER_TYPE,
  });

  const dimensionsLabel = dimensions || '-';

  const getFilterValue = (filterId) => {
    if (!data?.filteroptions || !productFilters) return [];

    const ids = data.filteroptions.map((o) => o.id);
    const filter = productFilters.find((filter) => filter.id === filterId);
    const value = filter.options.filter((category) => ids.includes(category.value));
    return value;
  };

  const handleSubmit = async (values, close) => {
    const updatedData = {};

    Object.keys(values).forEach((key) => {
      if (key.startsWith('filter_')) {
        const [_, id] = key.split('_');
        const filterValue = getFilterValue(parseInt(id));
        let filterOptions = data.filteroptions
          .map((opt) => opt.id)
          .filter((id) => !filterValue.find((c) => c.value === id)); // filter previous categories -> so other filter options are still preset

        filterOptions.push(...values[key].map((c) => c.value));
        updatedData.filteroptions = filterOptions;
      } else {
        updatedData[key] = values[key];
      }
    });

    const response = await apiUpdateWebExperienceProductDetails({
      id: productId,
      ...updatedData,
    });

    if (response) {
      onUpdateSuccess?.(response);
    }

    close();
  };

  return (
    <EditPopupUI
      show={show}
      onClose={onClose}
      loading={loading}
      title={data?.name}
      subtitle={sku_id}
      thumbnail={thumbnail}>
      {!loading && data && (
        <>
          {/* name */}
          <EditSection
            title="Product name"
            onSubmit={handleSubmit}
            schema={nameSchema}
            fields={getNameFields(data)}
            label={name || '-'}
          />

          {/* sku */}
          <EditSection
            title="Product Sku ID"
            onSubmit={handleSubmit}
            schema={skuSchema}
            fields={getSkuFields(data)}
            label={sku_id || '-'}
          />

          {/* price */}
          <EditSection
            title="Price"
            onSubmit={handleSubmit}
            schema={priceSchema}
            fields={getPriceFields(data)}
            label={price || '-'}
          />

          {/* Description */}
          <EditSection
            title="Description"
            onSubmit={handleSubmit}
            schema={descriptionSchema}
            fields={getDescriptionFields(data)}
            label={description || '-'}
          />

          {/* Dimensions */}
          <EditSection
            title="Dimensions"
            onSubmit={handleSubmit}
            schema={dimensionsSchema}
            fields={getDimensionsFields(data)}
            label={dimensionsLabel}
          />

          {/* filters */}
          {productFilters.map((filter) => {
            const { fieldsConfig, schema } = getCategoryFields(
              data,
              filter,
              getFilterValue(filter.id)
            );
            return (
              <EditSection
                key={filter.id}
                title={filter.label_name}
                onSubmit={handleSubmit}
                schema={schema}
                fields={fieldsConfig}
                previewMode="fields"
              />
            );
          })}
        </>
      )}
    </EditPopupUI>
  );
};
