import { getMy3dModelListApi, getProductListApi } from '../api/products';
import { MODEL_TYPES } from '../constants/constants';
import {
  paginatedProductActions,
  paginatedProductSelector,
} from '../redux/slicers/admin/paginatedProductSlicer';
import { useThumbnailCardDataList } from './useDataList';

export const useProps = ({
  requestParams,
  filterByKey,
  defaultBrands,
  defaultSort,
  useGlobalSearch,
  defaultCreatedBy,
  modelType,
} = {}) => {
  const data = useThumbnailCardDataList({
    selector: paginatedProductSelector,
    resetAction: paginatedProductActions.resetList,
    getAction: modelType === MODEL_TYPES.THREEDMODELS ? getMy3dModelListApi : getProductListApi,
    requestParams,
    filterByKey,
    defaultSort,
    useGlobalFilter: true,
    defaultBrands,
    useGlobalSearch,
    defaultCreatedBy,
    modelType
  });

  return data;
};
