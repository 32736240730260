import { Component, lazy, useState } from 'react';
import { Route, Routes, useLocation, matchPath } from 'react-router-dom';
import _ from 'lodash';
import {
  // public login routes
  CUSTOMIZE_EXPERIENCE,
  FORGET_ROUTE,
  FORGET_ROUTE_SETTINGS,
  INVITE_ROUTE,
  LOGIN_HOME,
  OTP_VERIFY_ROUTE,
  LINK_VERIFY_ROUTE,
  RESET_PASSWORD_ROUTE,
  SIGN_IN_ROUTE,
  SIGNUP_ROUTE,
  TERMS_CONDITION_ROUTE,
  COMMON_HOME,
  // session only routes
  MY_PRODUCTS_ROUTE,
  ADMIN_CURATOR,
  ADMIN_CURATOR_ROOM_ID,
  ADMIN_CURATOR_TYPE_ID,
  ADMIN_DASHBOARD,
  ADMIN_HOME,
  ADMIN_HOME_PATH,
  ADMIN_MY_PROJECTS,
  RENDERS_PREVIEWS_ROUTE,
  ADMIN_MY_PROPS,
  ADMIN_MY_ROOMS,
  ADMIN_SETTINGS,
  ADMIN_TEAMS,
  COLLECTIONS_ROUTE,
  SHARED_FILES_ROUTE,
  CALENDAR_ROUTE,
  COMMUNITY_ROUTE,
  TRASH_ROUTE,
  PLAY_TO_LEARN_ROUTE,
  REQUESTS_ROUTE,
  SUPPORT_ROUTE,
  MY_ACCOUNT_ROUTE,
  ADD_CREDITS_ROUTE,
  QUIT_ROUTE,
  SEARCH,
  RENDER_DETAILS,
  SEARCH_TEXT,
  COLLECTION_ID,
  ADMIN_MY_PRODUCTS_3DMODAL,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_ROOMS,
  ADMIN_MY_PRODUCTS_TEXTURE,
  ADMIN_MY_PRODUCTS_UPLOADS,
  ROOM_RENDER,
  PROJECT_RENDER,
  PRODUCT_RENDER,
  ADMIN_MY_ROOMS_PARAMS,
  TEMPLATES_ROUTE,
  NEW_PROJECTS_ROUTE,
  MY_PROJECTS_ROUTE,
  TEMPLATES_DETAILS,
  ADMIN_MY_PRODUCTS_TEXTURE_EDIT,
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
  ADMIN_MY_PRODUCTS_ROOMS_DETAILS,
  ADMIN_MY_PRODUCTS_UPLOADS_MOVE_TO_TEXTURE,
  INVITE_INTERNAL_USER,
  INVITE_EXTERNAL_USER,
  MY_TEAM_TEXTURE,
  ADMIN_CURATOR_PROJECT_ID,
  NEW_DESIGN_ROUTE,
  PRODUCT_WEB_EXPERIENCE_PUBLIC,
} from './constants/routePathConstants';
import { LayoutWrapper } from './layouts';

import { CuratorGuard } from './common/Guards/CuratorGuard';
import { useUserContext } from './contexts/UserContext';
import AuthGuard from './layouts/AuthGuard';
import AdminGuard from './layouts/AdminGuard';
import { PermissionsGuard } from './common/Guards/PermissionsGuard';
import { PERMISSION } from './constants/permissionConstants';
import { getTransactionsPath } from './helpers/urlHelper';

const NotFound = lazy(() => import('./screens/NotFound'));
const TermsAndPrivacyPolicy = lazy(() => import('./screens/TermsAndPrivacyPolicy'));

// public routes
const SignIn = lazy(() => import('./screens/Login'));
const SignUp = lazy(() => import('./screens/Register'));
const Forget = lazy(() => import('./screens/ForgotPassword'));
const CreatePassword = lazy(() => import('./screens/ResetPassword'));
const OtpVerify = lazy(() => import('./screens/Verify'));
const CustomizeExperience = lazy(() => import('./screens/CustomizeExperience'));
const InvitePage = lazy(() => import('./screens/Invitation'));
//const InvitePage = lazy(() => import('./pages/invitation'));

// admin routes
const AddCredits = lazy(() => import('./screens/AddCredits'));
const AdminDashboard = lazy(() => import('./screens/AdminDashboard'));
const AdminTeams = lazy(() => import('./screens/AdminTeams'));
const Calendar = lazy(() => import('./screens/Calendar'));
const Collection = lazy(() => import('./screens/Collection'));
const Collections = lazy(() => import('./screens/Collections'));
const Community = lazy(() => import('./screens/Community'));
const Curator = lazy(() => import('./screens/Curator'));
const MyAccount = lazy(() => import('./screens/MyAccount'));
const MyProducts = lazy(() => import('./screens/MyProducts'));
const MyProducts3dModel = lazy(() => import('./screens/MyProducts3dModel.js'));
const MyProductsRooms = lazy(() => import('./screens/MyProductsRooms'));
const MyProductsTextures = lazy(() => import('./screens/MyProductsTextures'));
const MyProductsTextureEdit = lazy(() => import('./screens/MyProductsTextureEdit'));
const MyProductsUploads = lazy(() => import('./screens/MyProductsUploads.js'));
const MyProductsWebView = lazy(() => import('./screens/MyProductsWebView.js'));
const MyProjects = lazy(() => import('./screens/MyProjects'));
const MyProps = lazy(() => import('./screens/MyProps'));
const NewDesign = lazy(() => import('./screens/NewDesign'));
const MyRooms = lazy(() => import('./screens/MyRooms'));
const PlayToLearn = lazy(() => import('./screens/PlayToLearn'));
const ProductRender = lazy(() => import('./screens/ProductRender'));
const ProjectRender = lazy(() => import('./screens/ProjectRender'));
const Quit = lazy(() => import('./screens/Quit'));
const RenderDetails = lazy(() => import('./screens/RenderDetails'));
const RendersPreviews = lazy(() => import('./screens/RendersPreviews'));
const Requests = lazy(() => import('./screens/Requests'));
const RoomRender = lazy(() => import('./screens/RoomRender'));
const Search = lazy(() => import('./screens/Search'));
const Settings = lazy(() => import('./screens/Settings'));
const SharedFiles = lazy(() => import('./screens/SharedFiles'));
const Support = lazy(() => import('./screens/Support'));
const Trash = lazy(() => import('./screens/Trash'));
// const AppleMerchant = lazy(() => import('./screens/AppleMerchant'));
const RendersPreviewsDashboard = lazy(() => import('./screens/RendersPreviews'));
const TemplatesDetails = lazy(() => import('./screens/TemplatesDetails'));
const TemplatesDetailsRoom = lazy(() => import('./screens/TemplatesDetailsRoom'));
const InviteInternalUser = lazy(() => import('./screens/InviteInternalUser'));
const InviteExternalUser = lazy(() => import('./screens/InviteExternalUser'));
const MyTeamTexture = lazy(() => import('./screens/MyTeamTexture'));
const PublicWebExperience = lazy(() => import('./screens/PublicWebExperience'));

//public routes
const LOGIN_ROUTES = [
  {
    Component: AuthGuard,
    routes: [
      { path: LOGIN_HOME, Component: SignIn, key: 'login' },
      { path: SIGN_IN_ROUTE, Component: SignIn, key: 'sign-in' },
      { path: SIGNUP_ROUTE, Component: SignUp, key: 'register' },
      { path: FORGET_ROUTE_SETTINGS, Component: Forget, key: 'forget' }, // this one is slightly different so we can redirect back to MyDetails instead of Sign-in on success
      { path: FORGET_ROUTE, Component: Forget, key: 'forget' },
      { path: RESET_PASSWORD_ROUTE, Component: CreatePassword, key: 'reset' },
      { path: OTP_VERIFY_ROUTE, Component: OtpVerify, key: 'otp-verify' },
      { path: LINK_VERIFY_ROUTE, Component: OtpVerify, key: 'otp-verify' }, // click on link in email to verify route
      { path: CUSTOMIZE_EXPERIENCE, Component: CustomizeExperience, key: 'customize-experience' },
      // { path: TERMS_CONDITION_ROUTE, Component: TermsAndConditionsPage, key: 'terms-privacy' },
      { path: INVITE_ROUTE, Component: InvitePage, key: 'invite' },
    ],
  },
];

export const ADMIN_NO_SIDEBAR_ROUTES = [
  {
    Component: AdminGuard,
    routes: [
      {
        Component: PermissionsGuard,
        componentProps: {
          permissions: [PERMISSION.CAN_INVITE_MEMBERS],
          redirectUrl: ADMIN_TEAMS,
        },
        routes: [
          {
            path: INVITE_INTERNAL_USER,
            Component: InviteInternalUser,
            key: 'invite_internal_user',
          },
          {
            path: INVITE_EXTERNAL_USER,
            Component: InviteExternalUser,
            key: 'invite_external_user',
          },
        ],
      },
    ],
  },
];

export const ADMIN_ROUTES = [
  {
    Component: AdminGuard,
    routes: [
      { path: '', Component: AdminDashboard, key: 'home' },
      { path: ADMIN_HOME_PATH, Component: AdminDashboard, key: 'home_path' },
      { path: ADMIN_DASHBOARD, Component: AdminDashboard, key: 'dashboard' },
      { path: MY_PRODUCTS_ROUTE, Component: MyProducts, key: 'products' },
      { path: ADMIN_SETTINGS, Component: Settings, key: 'settings' },
      { path: ADMIN_TEAMS, Component: AdminTeams, key: 'teams' },
      // this path needs to be before Render because it is a special /project/createNew/:projectId so it catches the dynamic :projectId path first
      { path: NEW_PROJECTS_ROUTE, Component: MyProjects, key: 'project' },
      // this path needs to be before MyProjects so it catches the dynamic :projectId path first
      { path: PROJECT_RENDER, Component: ProjectRender, key: 'ProjectRender' },
      { path: ADMIN_MY_PROJECTS, Component: MyProjects, key: 'project' },
      { path: ADMIN_MY_PRODUCTS_3DMODAL, Component: MyProducts3dModel, key: 'products/3dmodel' },
      { path: ADMIN_MY_PRODUCTS_ROOMS, Component: MyProductsRooms, key: 'products/rooms' },
      {
        path: ADMIN_MY_PRODUCTS_ROOMS_DETAILS,
        Component: TemplatesDetailsRoom,
        key: 'products-rooms-details',
      },
      {
        path: ADMIN_MY_PRODUCTS_TEXTURE,
        Component: MyProductsTextures,
        key: 'products/textures',
      },
      {
        path: ADMIN_MY_PRODUCTS_UPLOADS,
        Component: MyProductsUploads,
        key: 'products/uploads',
      },
      {
        path: ADMIN_MY_PRODUCTS_3DMODAL_EACH,
        Component: MyProductsWebView,
        key: 'products/each-model',
      },
      { path: RENDERS_PREVIEWS_ROUTE, Component: RendersPreviews, key: 'renders-previews' },
      { path: ADMIN_MY_PROPS, Component: MyProps, key: 'list-members' },
      { path: ADMIN_MY_ROOMS, Component: MyRooms, key: 'rooms' },
      { path: TEMPLATES_ROUTE, Component: MyRooms, key: 'templates' },
      { path: COLLECTIONS_ROUTE, Component: Collections, key: 'collections' },
      { path: SHARED_FILES_ROUTE, Component: SharedFiles, key: 'shared-files' },
      { path: CALENDAR_ROUTE, Component: Calendar, key: 'calendar' },
      { path: COMMUNITY_ROUTE, Component: Community, key: 'community' },
      { path: COLLECTION_ID, Component: Collection, key: 'collectionsId' },
      { path: TRASH_ROUTE, Component: Trash, key: 'trash' },
      { path: PLAY_TO_LEARN_ROUTE, Component: PlayToLearn, key: 'play-to-learn' },
      { path: REQUESTS_ROUTE, Component: Requests, key: 'requests' },
      { path: SUPPORT_ROUTE, Component: Support, key: 'support' },
      { path: MY_ACCOUNT_ROUTE, Component: MyAccount, key: 'my-account' },
      { path: QUIT_ROUTE, Component: Quit, key: 'quit' },
      { path: SEARCH, Component: Search, key: 'search' },
      { path: SEARCH_TEXT, Component: Search, key: 'search_text' },
      { path: RENDERS_PREVIEWS_ROUTE, Component: RendersPreviewsDashboard, key: 'search_text' },
      { path: RENDER_DETAILS, Component: RenderDetails, key: 'RenderDetails' },
      { path: ROOM_RENDER, Component: RoomRender, key: 'RoomRender' },
      { path: PRODUCT_RENDER, Component: ProductRender, key: 'ProductRender' },
      { path: TEMPLATES_DETAILS, Component: TemplatesDetails, key: 'TemplatesDetails' },
      { path: TEMPLATES_DETAILS, Component: TemplatesDetailsRoom, key: 'TemplatesDetailsRoom' },

      { path: NEW_DESIGN_ROUTE, Component: NewDesign, key: 'new_design' },
      { path: ADMIN_MY_ROOMS_PARAMS, Component: MyRooms, key: 'rooms_param' },
      { path: MY_TEAM_TEXTURE, Component: MyTeamTexture, key: 'my_team-texture' },
    ],
  },
];

export const NO_LAYOUT_ROUTES = [
  {
    Component: AdminGuard,
    routes: [
      {
        Component: CuratorGuard,
        routes: [
          {
            path: ADMIN_CURATOR_ROOM_ID,
            Component: Curator,
            key: 'curator_room',
          },
          {
            path: ADMIN_CURATOR_PROJECT_ID,
            Component: Curator,
            key: 'curator_project',
          },
        ],
      },
    ],
  },
  {
    Component: AuthGuard,
    routes: [
      { path: FORGET_ROUTE, Component: Forget, key: 'forget' },
      { path: FORGET_ROUTE_SETTINGS, Component: Forget, key: 'forget' }, // this one is slightly different so we can redirect back to MyDetails instead of Sign-in on success
      { path: INVITE_ROUTE, Component: InvitePage, key: 'invite' },
    ],
  },
  {
    Component: AdminGuard,
    routes: [
      {
        Component: PermissionsGuard,
        componentProps: {
          permissions: [PERMISSION.UPLOAD_TEXTURE],
          redirectUrl: MY_PRODUCTS_ROUTE,
        },
        routes: [
          {
            path: ADMIN_MY_PRODUCTS_TEXTURE_EDIT,
            Component: MyProductsTextureEdit,
            key: 'texture_edit',
          },
          {
            path: ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
            Component: MyProductsTextureEdit,
            key: 'texture_upload',
          },
          {
            path: ADMIN_MY_PRODUCTS_UPLOADS_MOVE_TO_TEXTURE,
            Component: MyProductsTextureEdit,
            key: 'texture_upload',
          },
        ]
      },
      {
        Component: PermissionsGuard,
        componentProps: {
          permissions: [PERMISSION.PURCHASE_CREDIT],
          redirectUrl: getTransactionsPath(),
        },
        routes: [
          { path: ADD_CREDITS_ROUTE, Component: AddCredits, key: 'add-credits' },
        ]
      }
    ]
    
  }
];

export const CURATOR_ROUTES = [ADMIN_CURATOR_ROOM_ID, ADMIN_CURATOR_PROJECT_ID];

export const SHOW_DRILLDOWN_MENU_ROUTES = [
  RENDERS_PREVIEWS_ROUTE,
  ADMIN_MY_PROPS,
  ADMIN_MY_ROOMS,
  ADMIN_MY_ROOMS_PARAMS,
  MY_PRODUCTS_ROUTE,
  ADMIN_MY_PROJECTS,
  MY_PROJECTS_ROUTE,
  COLLECTIONS_ROUTE,
  TEMPLATES_ROUTE,
  NEW_PROJECTS_ROUTE,
  ADMIN_MY_PRODUCTS_3DMODAL,
  ADMIN_MY_PRODUCTS_ROOMS,
  ADMIN_MY_PRODUCTS_TEXTURE,
  ADMIN_MY_PRODUCTS_UPLOADS,
  COLLECTION_ID,
  NEW_DESIGN_ROUTE,
];

export const FULLSCREEN_PRODUCT_VIEWS = [
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
];

export const PREVIEW_ROUTES = [
  RENDER_DETAILS,
  PRODUCT_RENDER,
  PROJECT_RENDER,
  TEMPLATES_DETAILS,
  ADMIN_MY_PRODUCTS_3DMODAL_EACH,
  ADMIN_MY_PRODUCTS_TEXTURE_UPLOAD,
  ADMIN_MY_PRODUCTS_ROOMS_DETAILS,
];

const pathsRequiringLogin = _.chain(_.map(_.concat(NO_LAYOUT_ROUTES, ADMIN_ROUTES), (p) => p.path))
  .concat(SHOW_DRILLDOWN_MENU_ROUTES, PREVIEW_ROUTES)
  .filter((p) => p?.key !== 'forget')
  .value();

export function onAuthPathOnly(currentPathname) {
  return _.includes(pathsRequiringLogin, (rte) => {
    return matchPath({ path: rte }, currentPathname) !== null;
  });
}

export function Routing() {
  const userCtx = useUserContext();
  const { token } = userCtx;
  const location = useLocation();
  const currentPathname = location?.pathname;

  const [isOnTermsAndConditions, setIsOnTermsAndConditions] = useState(true);
  const isTNCModelVisible =
    userCtx.token &&
    userCtx.refreshToken &&
    userCtx.emailVerified &&
    userCtx.currentUser &&
    (userCtx.latestTNCAccepted === false || userCtx.latestTNCAccepted === 'false');

  const routeMapFunc = ({ exact = true, path, Component, componentProps, routes }, idx) => {
    return (
      <Route path={path} exact={exact} element={<Component {...componentProps} />} key={idx}>
        {routes?.map(routeMapFunc)}
      </Route>
    );
  };

  const userAccessConfirmed =
    userCtx.token && userCtx.refreshToken && userCtx.emailVerified && userCtx.currentUser;

  const displayFullScreen =
    userAccessConfirmed &&
    _.some(FULLSCREEN_PRODUCT_VIEWS, (rte) => {
      return matchPath({ path: rte }, currentPathname) !== null;
    });

  // TODO: refactor routes -> just use AuthGuard instead of this "routing"
  return (
    <>
      {isTNCModelVisible && (
        <TermsAndPrivacyPolicy
          isOnTermsAndConditions={isOnTermsAndConditions}
          setIsOnTermsAndConditions={setIsOnTermsAndConditions}
          isModalVersion={true}
        />
      )}
      <Routes>
        <Route
          element={
            <LayoutWrapper
              sidenav={false}
              token={token}
              refreshToken={userCtx.refreshToken}
              isEmailVerified={userCtx.emailVerified}
              currentUser={userCtx.currentUser}
              fullScreen={displayFullScreen}
            />
          }>
          {ADMIN_NO_SIDEBAR_ROUTES.map(routeMapFunc)}
        </Route>
        <Route
          element={
            <LayoutWrapper
              token={token}
              refreshToken={userCtx.refreshToken}
              isEmailVerified={userCtx.emailVerified}
              currentUser={userCtx.currentUser}
              fullScreen={displayFullScreen}
            />
          }>
          {ADMIN_ROUTES.map(routeMapFunc)}
        </Route>
        {NO_LAYOUT_ROUTES.map(routeMapFunc)}

        <Route path={PRODUCT_WEB_EXPERIENCE_PUBLIC} element={<PublicWebExperience />} />
        <Route path={'not-found'} element={<NotFound />} />
        <Route
          element={
            <LayoutWrapper
              token={userCtx.token}
              refreshToken={userCtx.refreshToken}
              isEmailVerified={userCtx.emailVerified}
              currentUser={userCtx.currentUser}
              fullScreen={displayFullScreen}
            />
          }>
          {LOGIN_ROUTES.map(routeMapFunc)}
        </Route>
        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </>
  );
}
