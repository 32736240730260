import { Button } from 'react-bootstrap';
import { icons } from '../assets';
import FooterButton from './FooterButton';
import { getCuratorPath, getCuratorRenderPreviewPath } from '../helpers/urlHelper';

const { EditIcon } = icons;

function EditButton(props) {

  console.log('props', props)
  function handleClick() {
    const url = props.cardType === 'renders-previews'
      ? getCuratorRenderPreviewPath(props.id)
      : getCuratorPath(props.id, props.cardType)
    window.open(url, '_blank');
  }

  if (props.footerButton) return <FooterButton icon={EditIcon} {...props} />;

  if (props.miniButton)
    return (
      <li title={props.buttonTitle}>
        <div onClick={handleClick} style={{ width: '16px' }}>
          <img className="img-fluid" src={props.iconSrc} alt={props.buttonTitle} />
        </div>
      </li>
    );

  return (
    <Button
      variant={props.disabled ? 'outline-secondary' : 'outline-dark'}
      size={props.size || 'lg'}
      onClick={handleClick}
      type="button"
      disabled={props.disabled}>
      <EditIcon />
      Edit
    </Button>
  );
}

export default EditButton;
