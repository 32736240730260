import _ from 'lodash';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { StyledSelect } from '.';
import { useUserContext } from '../contexts/UserContext';
import { COMMON_SHARED_OPTIONS, sharedByMe } from '../helpers/configureHelper';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedMembersBy,
  selectSelectedMembersTo,
  selectSharedTo,
  setSelectedMembersBy,
  setSelectedMembersTo,
  setSharedTo,
} from '../redux/slicers/filters/filterSlicer';
import { isAdminOrOwner } from '../helpers/userHelper';
import UserIcon from '../components/icons/UserIcon';
import { TeamMembersFilterOverlayUI } from '../components/TeamMembersFilter/TeamMembersFilter';
import { MODE, useApiList } from '../hooks/useApiList';
import { apiGetMembersListV2 } from '../helpers/api';

export const TeamMembersSharedFilesFilter = () => {
  const dispatch = useDispatch();
  const { currentUser } = useUserContext();
  const selectedMembersBy = useSelector(selectSelectedMembersBy);
  const selectedMembersTo = useSelector(selectSelectedMembersTo);
  const sharedTo = useSelector(selectSharedTo);

  const [isMemberFilterVisible, toggleMemberFilter] = useState(false);
  const [selectedMember, setSelectedMember] = useState('');
  const handleAdminSharedToggle = (value) => {
    setSelectedMember(value);
    toggleMemberFilter(!isMemberFilterVisible);
  };

  const selectedOption = COMMON_SHARED_OPTIONS.find((o) => o.value === sharedTo);

  const handleShareDropdownChange = (option) => {
    dispatch(setSharedTo(option.value));
  };

  return (
    <div className="d-flex align-items-center gap-3">
      {!isAdminOrOwner(currentUser) && (
        <>
          <span>Shared</span>
          <div className="minWidth135">
            <StyledSelect
              value={selectedOption}
              onChange={handleShareDropdownChange}
              options={COMMON_SHARED_OPTIONS}
              placeholder="Select"
            />
          </div>
          <span>{sharedTo === sharedByMe ? 'With' : 'By'}</span>
          <SharedByButton
            members={selectedMembersBy}
            onClick={() => toggleMemberFilter(!isMemberFilterVisible)}
          />
          <TeamMembersFilterOverlayUIController
            show={isMemberFilterVisible}
            onClose={() => toggleMemberFilter(!isMemberFilterVisible)}
            onChange={(m) => dispatch(setSelectedMembersBy(m))}
            selectedMembers={selectedMembersBy}
          />
        </>
      )}

      {isAdminOrOwner(currentUser) && (
        <>
          <span>Shared by</span>
          <SharedByButton
            members={selectedMembersBy}
            onClick={() => handleAdminSharedToggle('by')}
          />
          <span>to</span>
          <SharedByButton
            members={selectedMembersTo}
            onClick={() => handleAdminSharedToggle('to')}
          />
          <TeamMembersFilterOverlayUIController
            show={isMemberFilterVisible}
            onClose={() => toggleMemberFilter(!isMemberFilterVisible)}
            onChange={
              selectedMember === 'to'
                ? (m) => dispatch(setSelectedMembersTo(m))
                : (m) => dispatch(setSelectedMembersBy(m))
            }
            selectedMembers={selectedMember === 'to' ? selectedMembersTo : selectedMembersBy}
          />
        </>
      )}
    </div>
  );
};

export const SharedByButton = ({ members, onClick }) => {
  return (
    <>
      {members.length > 0 ? (
        <>
          <div className="custom-imges user-icon-top ">
            <span style={{ cursor: 'pointer', display: 'flex' }} onClick={onClick}>
              {members.map((item, index) =>
                index < 2 ? (
                  <span className="avatar" key={index}>
                    {item.profile_image ? <img src={item.profile_image} /> : <UserIcon />}
                  </span>
                ) : null
              )}
              {members.length > 2 && (
                <span className="avatar avtar-count-part ">+{members.length - 2}</span>
              )}
            </span>
          </div>
        </>
      ) : (
        <Button className="allUsers" variant={''} onClick={onClick}>
          All
        </Button>
      )}
    </>
  );
};


const TeamMembersFilterOverlayUIController = (props) => {
  const [search, setSearch] = useState('')

  const insideList = useApiList({
    api: apiGetMembersListV2,
    mode: MODE.PARAMS,
    defaultPerPage: 100,
    params: {
      isMyOrg: true,
      search: search,
    }
  })

  const outsideList = useApiList({
    api: apiGetMembersListV2,
    mode: MODE.PARAMS,
    defaultPerPage: 100,
    params: {
      isMyOrg: false,
    }
  })

  const sections = [
    {
      noItemsText: 'No user found!',
      fetchMoreData: insideList.fetchNextPage,
      loading: insideList.loading,
      name: 'INSIDE_MEMBERS',
      hasMore: insideList.hasMore,
      items: insideList.list.map((member) => {
        const name = member.member_name || member.member_email

        return {
          id: member.id,
          name: name,
          avatar: member.member_profile,
        };
      }),
    },
    {
      noItemsText: 'No user found!',
      fetchMoreData: outsideList.fetchNextPage,
      loading: outsideList.loading,
      title: 'Outside members',
      name: 'OUTSIDE_MEMBERS',
      hasMore: outsideList.hasMore,
      items: outsideList.list.map((member) => {
        const name = member.member_name || member.member_email

        return {
          id: member.id,
          name: name,
          avatar: member.member_profile,
        };
      }),
    },
  ];

  return (
    <TeamMembersFilterOverlayUI
      {...props}
      sections={sections}
      search={search}
      onSearchChange={(e) => setSearch(e.target.value)}
    />
  )
}