export const RestoreIcon = (props) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6917 19.2H10.6731C7.03628 19.2 5.48242 17.6462 5.48242 14.0094V9.99075C5.48242 6.35391 7.03628 4.80005 10.6731 4.80005H14.022C14.1385 4.80005 14.2466 4.84107 14.3324 4.90928C14.3478 4.92109 14.3627 4.9338 14.3769 4.94739L19.7351 10.3055C19.7732 10.3437 19.8044 10.3877 19.8281 10.4354C19.8628 10.5033 19.8824 10.5799 19.8824 10.6605V14.0094C19.8824 17.6462 18.3286 19.2 14.6917 19.2ZM18.8778 11.1628V14.0094C18.8778 17.097 17.7794 18.1954 14.6917 18.1954H10.6731C7.58549 18.1954 6.48707 17.097 6.48707 14.0094V9.99075C6.48707 6.90312 7.58549 5.8047 10.6731 5.8047H13.5196V7.98144C13.5196 10.272 14.4104 11.1628 16.701 11.1628H18.8778ZM12.0127 15.8512C11.8854 15.8512 11.7581 15.8043 11.6577 15.7038C11.4634 15.5096 11.4634 15.1881 11.6577 14.9939L12.1399 14.5116H9.33358C9.05898 14.5116 8.83126 14.2839 8.83126 14.0093C8.83126 13.7347 9.05898 13.507 9.33358 13.507H12.1399L11.6577 13.0248C11.5572 12.9243 11.5103 12.797 11.5103 12.6698C11.5103 12.5425 11.5572 12.4153 11.6577 12.3148C11.8519 12.1206 12.1734 12.1206 12.3676 12.3148L13.7072 13.6543C13.7942 13.7414 13.8411 13.8553 13.8478 13.9624V14.0696C13.8344 14.1634 13.7942 14.2504 13.7273 14.3308L13.7005 14.3576L12.3609 15.6971C12.2672 15.8043 12.1399 15.8512 12.0127 15.8512ZM14.5243 7.98144V6.51465L18.1678 10.1582H16.701C14.973 10.1582 14.5243 9.70944 14.5243 7.98144Z"
        fill="currentColor"
      />
    </svg>
  );
};
