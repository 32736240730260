import { useState } from 'react';
import downloadjs from 'downloadjs'
import { apiGetDownloadRendersImages } from '../helpers/api';

export const useDownloadEntity = () => {
  const [loading, setLoading] = useState(false)
  
  async function download({ id, type }) {
    let res;
    setLoading(true);

    if (type === 'renders-previews') {
      res = await apiGetDownloadRendersImages(`ids=${id}`);
      downloadjs(res, 'downloaded-images.zip')
    }

    setLoading(false)
  }

  return { download, loading }
}