import cx from 'classnames';
import { useState } from 'react';
import { Modal, Button, ModalTitle, Row, Col } from 'react-bootstrap';
import { BigButton, MiniButton } from './';
import { icons } from '../assets';
import _ from 'lodash';
import FooterButton from './FooterButton';
import { usePermissionsHandler } from '../hooks/usePermissions';
const { CloseIconSmall } = icons;

export const ModalArea = (props) => {
  function handleClose() {
    if (props.ownerModal) {
      props.handleOwnerClose();
    }

    props.setShowModal(false);
  }
  return (
    <Modal
      id="pop-up-body"
      show={props.showModal}
      animation={props.animation || false}
      onHide={handleClose}
      size={props?.size || 'md'}
      centered={props.centered}
      dialogClassName={cx('lf-modal-default', props.className || 'new-item-modal')}>
      <Button onClick={handleClose} className="modalCloseButton lf-modal-close-button">
        <CloseIconSmall />
      </Button>
      {props.title && <ModalTitle className="text-center">{props.title}</ModalTitle>}
      {props.subtitle && <span className="lf-modal-subtitle">{props.subtitle}</span>}
      {props.body && <Modal.Body>{props.body}</Modal.Body>}
      {props.includeCancelButton || props.promptButton ? (
        <Modal.Footer className="commonPopupFooter">
          {props.includeCancelButton && (
            <BigButton
              className="lf-btn-1"
              buttonTitle={props.cancelTitle || 'Cancel'}
              onClick={handleClose}
            />
          )}
          {props.promptButton}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

const ModalButtonArea = (props) => {
  const { handlePermissionEvent } = usePermissionsHandler();
  const [showModal, setShowModal] = useState(
    _.isBoolean(props.startOpen) ? props.startOpen : false
  );
  // need to get styling working properly here

  function toggleModal(overrideValue) {
    if (_.isBoolean(props.showModal)) {
      props.setShowModal(overrideValue);
    } else {
      setShowModal(overrideValue);
    }
    if (_.isFunction(props.onClose) && !overrideValue) {
      props.onClose();
    }
  }

  const openModal = () => {
    toggleModal(true);
  };

  return (
    <>
      {/* {props.iconOnly && <img src={props.iconSrc} />} */}
      {props.iconOnly && <props.buttonIcon className="me-2" />}
      {props.bigButton && (
        <BigButton
          {...props}
          onClick={props.checkForPermission
            ? handlePermissionEvent(props.checkForPermission, openModal)
            : openModal}
          type="button"
        />
      )}
      {props.miniButton && (
        <MiniButton
          title={props.buttonTitle}
          clickFunction={() => toggleModal(true)}
          iconSrc={props.iconSrc}
          buttonLabel={props.buttonLabel}
        />
      )}
      {props.footerButton && (
        <FooterButton icon={props.buttonIcon} {...props} clickFunction={() => toggleModal(true)} />
      )}
      {props.button}
      <ModalArea
        handleOwnerClose={props.handleOwnerClose}
        ownerModal={props.ownerModal}
        showModal={_.isBoolean(props.showModal) ? props.showModal : showModal}
        setShowModal={toggleModal}
        className={cx(props.className, props.modalClassName)}
        title={props.title}
        subtitle={props.subtitle}
        body={props.body}
        animation={props.animation}
        includeCancelButton={props.includeCancelButton}
        promptButton={props.promptButton}
        size={props?.size}
        centered={props.centered}
      />
    </>
  );
};

ModalButtonArea.defaultProps = {
  centered: true,
};

export default ModalButtonArea;
