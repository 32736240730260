import * as yup from 'yup';
import {
  MAX_PRODUCT_NAME_LENGTH,
  MIN_PRODUCT_NAME_LENGTH,
} from '../../constants/validationConstants';

export const nameSchema = yup.object({
  name: yup.string().min(MIN_PRODUCT_NAME_LENGTH).max(MAX_PRODUCT_NAME_LENGTH).required(),
});

export const getNameFields = (data) => [
  {
    name: 'name',
    placeholder: 'Product name',
    value: data.name,
  },
];

export const getProjectTypeFileds = (data, options) => [
  {
    name: 'project_type',
    placeholder: 'Type of project',
    value: data.project_type,
    type: 'select',
    selectProps: { options },
  },
];


export const getCategoryFields = (filter, categoryValue) => {
  const name = `filter_${filter.id}`;

  const schema = yup.object({
    [name]: yup
      .array()
      .typeError(`${filter.label_name} is required`)
      .test('missing filter', `${filter.label_name} is required`, (arr) => arr && arr.length !== 0),
  });

  return {
    schema,
    fieldsConfig: [
      {
        name: name,
        placeholder: filter.label_name,
        type: 'select',
        value: categoryValue,

        selectProps: {
          options: filter.options,
          isSearchable: true,
          isMulti: true,
          creatable: true,
          components: {
            DropdownIndicator: null,
          },
        },
      },
    ],
  };
};
