import { useDispatch } from 'react-redux';
import { API_ROOT_URL, CREATE_SHARE } from '../../config/constant/apiConstant';
import { GET_SEARCH_MEMBER_DETAIL, GET_SEARCH_MEMBER_ORG_DETAIL, GET_SHARED_FILES } from '../../helpers/api';
import api from '../../helpers/axiosHelper';
import { errorToast, successToast } from '../../helpers/toastHelper';
import { setSearchOrgResult, setSearchUserResult } from '../../redux/slicers/search/searchSlicer';
import { sharedFilesPageActions } from '../../redux/slicers/sharedFilesPageSlicer';
import { createGetListAction } from '../products';

export const createShareApi = (formData) => (dispatch) => {
  // console.log(search);

  api()
    .root(API_ROOT_URL)
    .post(`${CREATE_SHARE}`)
    .data(formData)
    .success((data) => {
      successToast('Shared successfully!');
    })
    .error((error) => {
      errorToast('Unable to share!');
    })
    .send();
};

export const GetSearchMembersList = (searchVal) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_SEARCH_MEMBER_DETAIL}?search=${searchVal}`)
    .success((data) => {
      dispatch(setSearchUserResult(data));
    })
    .error((error) => {
      // console.log("texture search data err:", error)
    })
    .send();
};
export const GetOrgMembersList = (searchVal) => (dispatch) => {
  api()
    .root(API_ROOT_URL)
    .get(`${GET_SEARCH_MEMBER_ORG_DETAIL}?search=${searchVal}`)
    .success((data) => {
      dispatch(setSearchOrgResult(data));
    })
    .error((error) => {})
    .send();
};

export const getSharedFilesPageListApi = createGetListAction({
  endpoint: GET_SHARED_FILES,
  actions: sharedFilesPageActions,
  setGlobalSidebarFilters: true,
  dataFormattter: (item) => ({
    ...item,
    created_at: item?.data?.created_at,
  })
});