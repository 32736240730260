import { InputUI } from '../../components/hookForm/InputController';
import InfoDialogUI from '../../components/InfoDialog/InfoDialogUI';
import styles from './TransactionNoteDialog.module.scss';

export const TransactionNoteDialog = ({ onClose, note }) => {
  return (
    <InfoDialogUI
      show
      title="Transaction type"
      onClose={onClose}
      cancelButtonText="Close"
      cancelBtnColor="warning"
      onCancelClick={onClose}
      dialogClassName={styles.dialog}
    >
      <InputUI value={note} readonly multiline label="Description" height={172} noResize />
    </InfoDialogUI>
  );
};
