import { createSlice } from '@reduxjs/toolkit';

const model3dDetails = createSlice({
  name: 'model3dDetails',
  initialState: {
    data: null,
  },
  reducers: {
    show: (state, { payload }) => {
      state.data = payload;
    },
    close: (state) => {
      state.data = null;
    },
  },
});

export const model3dDetailsActions = model3dDetails.actions;
export const model3dDetailsReducer = model3dDetails.reducer;
export default model3dDetailsReducer;

export const selectModel3dDetailsData = (state) => state.model3dDetails.data